import React from 'react'
import { translate } from 'react-switch-lang'

class ViewNoResult extends React.Component {

    render() {
        const { t, message } = this.props
        let setMessage = t("common.noData")
        if (message) {
            setMessage = message
        }
        try {
            return (
                <div className="module viewNoResult">
                    <div className="vnr-wrapper">
                        <h3>{setMessage}</h3>
                    </div>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}


ViewNoResult.defaultProps = {
    message: ""
}

export default translate(ViewNoResult)