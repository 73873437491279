
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getResourceGroupInfo, getResourceGroupUserList } from '../../actions/resourceGroupActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import ResourceGroupManager from '../../business/ResourceGroupManager'
import ResourceGroupView from '../../views/resourceGroup/ResourceGroupView'
import { getUserList } from '../../actions/userActions'
import { getLocationList } from '../../actions/locationActions'
import { getSensorRuleList } from '../../actions/sensorRuleActions'

class ViewResourceGroupController extends MyController {
	constructor(props) {
		super(props)

		this.resourceGroupInfo = null
		this.bResourceGroup = new ResourceGroupManager(this)
	}

	getPropsResetStatus() {
		return ["userList", "resourceGroupInfo"]
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}

		if (nextProps.resourceGroupInfo.requestFinished()) {
			tempState.resourceGroupInfo = nextProps.resourceGroupInfo.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.resourceGroupInfo && !!this.state.resourceGroupInfo) {
			this.onSearch(this.state.resourceGroupInfo)
		}
	}

	onSearch = (model) => {
		if (!!model) {
			let querys = [
				{
					name: "resourceGroupIds",
					value: model.id,
				}
			]
			this.props.getUserList(utils.generateSearchQuery(querys))
		}
	}

	testSyncPlatformResourceGroup = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bResourceGroup.resourceGroupTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { resourceGroupInfo, userList, locationList, sensorRuleList, resourceGroupUserList } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const resourceGroup = resourceGroupInfo.getData()
			, userData = userList.getDatas()
			, optionsLocationList = locationList.getDatasOption()
			, optionsSensorRuleList = sensorRuleList.getDatasOption()
			, optionsUserList = resourceGroupUserList.getDatasOption()

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<ResourceGroupView
							model={resourceGroup}
							userList={userData}
							optionsLocationList={optionsLocationList}
							optionsSensorRuleList={optionsSensorRuleList}
							optionsUserList={optionsUserList}
							testSyncPlatformResourceGroup={this.testSyncPlatformResourceGroup}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			resourceGroupInfo: state.resourceGroupInfo,
			userList: state.userList,
			locationList: state.locationList,
			sensorRuleList: state.sensorRuleList,
			resourceGroupUserList: state.resourceGroupUserList
		}),
		(dispatch, props) => ({
			getResourceGroupInfo() {
				dispatch(getResourceGroupInfo(utils.getParamFromProps(props, "id")))
			},
			getUserList(query) {
				dispatch(getUserList(query))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getSensorRuleList() {
				dispatch(getSensorRuleList())
			},
			getResourceGroupUserList() {
				dispatch(getResourceGroupUserList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getResourceGroupInfo()
			this.props.getLocationList()
			this.props.getSensorRuleList()
			this.props.getResourceGroupUserList()
		}
	}),
)


export default enhance(ViewResourceGroupController) 