
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
// import { CORE_CURRENT_ENDPOINT } from '../lib'
// import utils from '../core/utility/utils'

const Schema = mongoose.Schema

const ForgotPasswordSchema = new Schema({
	textSearch: { type: String, required: [true, "validate.require"], default: "" },
	url: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(ForgotPasswordSchema, "forgot_password")

ForgotPasswordSchema.methods.serializeAPI = function () {
	try {
		let result = {
			textSearch: this.textSearch,
			url: this.url
		}
		return result
	} catch (error) {
		return null
	}
}

const ForgotPasswordModel = mongoose.model("forgot_password", ForgotPasswordSchema, "forgot_password")

export default ForgotPasswordModel