
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const SMTPDataSchema = new Schema({
	senderEmail: { type: String, required: [true, "validate.require"], default: "" },
	senderName: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(SMTPDataSchema, "notification_smtp")

const SMTPDataModel = mongoose.model("notification_smtp", SMTPDataSchema, "notification_smtp")

export default SMTPDataModel