
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LocationTypeModel from '../../models/LocationTypeModel'
import LabelInput from '../common/LabelInput'
import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'

class FormLocationType extends MyForm {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { t, model, errors, optionsTreeLocationType } = this.props

        let codeValidate = this.getErrorInput("code", errors)
            , nameValidate = this.getErrorInput("name", errors)
            , parentIdValidate = this.getErrorInput("parentId", errors)

        try {
            return (
                <React.Fragment>
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.code")} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.name")} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        fluid
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("locationType.parent")} isRequire={false} />
                                    <ViewTreeSelect
                                        label={t("locationType.parent")}
                                        name="parentId"
                                        datas={optionsTreeLocationType}
                                        value={model.parentId !== "" ? model.parentId : "Not Select"}
                                        onChange={this.handleChange}
                                        placeholder={t("common.pleaseSelect")}
                                        error={parentIdValidate.isError ? parentIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                    <Form.TextArea
                                        rows={5}
                                        value={model.description}
                                        onChange={this.handleChange}
                                        name="description"
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormLocationType.defaultProps = {
    model: new LocationTypeModel(),
    errors: [],
    optionsTreeLocationType: [],
    handleChange: () => console.error("Please provide \"Handle Change\" action")
}

export default (translate(FormLocationType))