
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class SettingManager extends MyManager {
	async upsertSetting(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/setting`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getLoginInfo() {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/setting/login/info`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async testForgotPassword(body) {
		try {
			const option = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/setting/test/forgotpassword`,
				data: body,
				isHandleError: false
			}
			let res = await this.requestAction(option)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}

	async testResetPassword(body) {
		try {
			const option = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/setting/test/resetpassword`,
				data: body,
				isHandleError: false
			}
			let res = await this.requestAction(option)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}