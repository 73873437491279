
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ModalCreateSensorRuleAction from '../../views/sensorRuleStep/ModalCreateSensorRuleAction'
import DoorManager from '../../business/DoorManager'
import RoomManager from '../../business/RoomManager'
import DeskManager from '../../business/DeskManager'
import LocationManager from '../../business/LocationManager'
import { resourceTypeCode, sensorRuleLocationType } from '../../../constant'

class ModalCreateSensorRuleActionController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.state.sceneList = []

		this.bDoor = new DoorManager(this)
		this.bRoom = new RoomManager(this)
		this.bDesk = new DeskManager(this)
		this.bLocation = new LocationManager(this)
	}
	componentDidMount() {
		this.startGetScene(this.props.model)
	}

	startGetScene = (model) => {
		if (!!model && !!model.data && !!model.data.sceneId) {
			const { sensorRuleResourceList, locationList } = this.props
			let type = model.data.type
				, id = model.data.id
				, code = null
			if (type === sensorRuleLocationType.LOCATION) {
				if (Array.isArray(locationList)) {
					let data = locationList.find(l => l.id === id)
					if (!!data) {
						code = type
					}
				}
			} else if (type === sensorRuleLocationType.RESOURCE) {
				if (Array.isArray(sensorRuleResourceList)) {
					let data = sensorRuleResourceList.find(d => d.id === id)
					if (!!data) {
						code = data.resourceTypeCode
					}
				}
			}
			if (!!code && !!id) {
				this.getScene(code, id)
			}
		}
	}

	getDeskScene = (id) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			let tempState = {
				isLoadingComponent: false
			}, action = () => { }
			tempState.sceneList = await this.bDesk.getSceneById(id)
			this.setState(tempState, action)
		})
	}
	getRoomScene = (id) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			let tempState = {
				isLoadingComponent: false
			}, action = () => { }
			tempState.sceneList = await this.bRoom.getSceneById(id)
			this.setState(tempState, action)
		})
	}
	getDoorScene = (id) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			let tempState = {
				isLoadingComponent: false
			}, action = () => { }
			tempState.sceneList = await this.bDoor.getSceneById(id)
			this.setState(tempState, action)
		})
	}
	getLocationScene = (id) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			let tempState = {
				isLoadingComponent: false
			}, action = () => { }
			tempState.sceneList = await this.bLocation.getSceneById(id)
			this.setState(tempState, action)
		})
	}
	getScene = (code, id) => {
		if (code === resourceTypeCode.DESK) {
			this.getDeskScene(id)
		} else if (code === resourceTypeCode.ROOM) {
			this.getRoomScene(id)
		} else if (code === resourceTypeCode.DOOR) {
			this.getDoorScene(id)
		} else if (code === sensorRuleLocationType.LOCATION) {
			this.getLocationScene(id)
		}
	}
	render() {
		try {
			const { onClose, onAddAction, optionsSensorRuleActionType, optionsSensorRuleLocationType,
				sensorRuleResourceList, locationList, optionsNotification, optionsDataType, optionsSensorType, model, sensorRuleActionResult, chosenType } = this.props
			const { sceneList } = this.state

			return (
				<React.Fragment>
					{super.render()}
					{/* {isLoadingComponent && this.getLoadingPage()} */}
					<ModalCreateSensorRuleAction
						model={model}
						chosenType={chosenType}
						optionsSensorRuleActionType={optionsSensorRuleActionType}
						// optionsSensorRuleActionResult={optionsSensorRuleActionResult}
						optionsSensorRuleLocationType={optionsSensorRuleLocationType}
						optionsNotification={optionsNotification}
						optionsDataType={optionsDataType}
						optionsSensorType={optionsSensorType}
						sensorRuleResourceList={sensorRuleResourceList}
						sensorRuleActionResult={sensorRuleActionResult}
						locationList={locationList}
						onClose={onClose}
						onAddAction={onAddAction}

						sceneList={sceneList}
						getScene={this.getScene}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

ModalCreateSensorRuleActionController.defaultProps = {
	optionsSensorRuleActionType: [],
	optionsSensorRuleActionResult: [],
	optionsSensorRuleLocationType: [],
	optionsNotification: [],
	optionsDataType: [],
	optionsSensorType: [],
	sensorList: [],
	sensorRuleResourceList: [],
	locationList: [],
	onAddAction: () => console.error("Please provide \"Add\" action."),
	onClose: () => console.error("Please provide \"Close\" action."),
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			// sensorRuleList: state.sensorRuleList
		}),
		(dispatch, props) => ({
			// getSensorRuleList(query) {
			// 	dispatch(getSensorRuleList(query))
			// }
		}),
	),
	lifecycle({
		// componentDidMount() {
		// 	this.props.getSensorRuleList()
		// }
	})
)


export default enhance(ModalCreateSensorRuleActionController)