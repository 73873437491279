
import React from 'react'
import { eventControlRuleStep } from '../../../../constant'
import Step from '../../../core/business/step/Step'

import EventInformationView from '../../../views/eventControlRuleStep/view_step/EventInformationView'
import EventControlRuleInfoModel from '../../../models/eventControlRuleStep/EventControlRuleInfoModel'

export default class EventControlRuleInfoStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "common.information"
		this.key = eventControlRuleStep.EVENT_INFORMATION
		this.data = new EventControlRuleInfoModel()
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data && data[this.key] instanceof EventControlRuleInfoModel) {
			this.data = new EventControlRuleInfoModel(data[this.key])
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		if (!!data && data instanceof EventControlRuleInfoModel) {
			allResult[this.key] = data
		}
		return allResult
	}

	nextStep(data) {
		this.data = new EventControlRuleInfoModel(data)
		super.nextStep()
	}

	prevStep(data) {
		this.data = new EventControlRuleInfoModel(data)
		super.prevStep()
	}

	render(dataConfig) {
		return <EventInformationView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
		/>
	}
}