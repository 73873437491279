
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

/* const resetSettingList = () => {
	return {
		type: type.RESET_SETTING_LIST
	}
} */

const getSettingInfo = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_INFO_REQUEST,
				{
					type: type.GET_SETTING_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_INFO_FAILURE
			]
		}
	}
}

const getSettingSystemList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting/login/system`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_SYSTEM_LIST_REQUEST,
				{
					type: type.GET_SETTING_SYSTEM_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_SYSTEM_LIST_FAILURE
			]
		}
	}
}

const getSettingNotificationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting/notification/system`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_NOTIFICATION_LIST_REQUEST,
				{
					type: type.GET_SETTING_NOTIFICATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_NOTIFICATION_LIST_FAILURE
			]
		}
	}
}


const getAuthLoginInfo = () => {
	return {
		[CALL_API]: {
			useToken: false,
			endpoint: `${CORE_API_ENDPOINT}/auth/login/info`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_AUTH_LOGIN_INFO_REQUEST,
				{
					type: type.GET_AUTH_LOGIN_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_AUTH_LOGIN_INFO_FAILURE
			]
		}
	}
}

export {
	getSettingInfo,
	getSettingSystemList,
	getAuthLoginInfo,
	getSettingNotificationList
}