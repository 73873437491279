
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getRFIDCardInfo } from '../../actions/rfidCardActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import RFIDCardManager from '../../business/RFIDCardManager'
import RFIDCardView from '../../views/rfidCard/RFIDCardView'

class ViewRFIDCardController extends MyController {
	constructor(props) {
		super(props)

		this.bRFIDCard = new RFIDCardManager(this)
		this.state.isLoadingComponent = false
	}

	getPropsResetStatus() {
		return ["rfidCardInfo"]
	}

	onDeleteAreaRFIDCard = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRFIDCard.deleteAreaRFIDCard(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getRFIDCardInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaRFIDCard = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRFIDCard.upsertAreaRFIDCard(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }
				if (!!res && !!res.result) {
					action = () => this.props.getRFIDCardInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationRFIDCard = (model, serviceInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRFIDCard.unintegrateRFIDCard(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getRFIDCardInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	testSyncPlatformRFIDCard = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bRFIDCard.rfidCardTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { rfidCardInfo, locationList, isLoadingComponent } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const rfidCard = rfidCardInfo.getData()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let locationInfo = null

			if (!!rfidCard) {
				if (!!rfidCard.locationId) {
					locationInfo = locationList.findDataByKey("id", rfidCard.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<RFIDCardView
							model={rfidCard}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaRFIDCard={this.onDeleteAreaRFIDCard}
							onUpsertAreaRFIDCard={this.onUpsertAreaRFIDCard}
							onUnintegtationRFIDCard={this.onUnintegtationRFIDCard}
							locationList={locationDatas}
							testSyncPlatformRFIDCard={this.testSyncPlatformRFIDCard}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			rfidCardInfo: state.rfidCardInfo,
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getRFIDCardInfo() {
				dispatch(getRFIDCardInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getRFIDCardInfo()
		}
	}),
)


export default enhance(ViewRFIDCardController)