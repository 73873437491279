
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetAdminList = () => {
	return {
		type: type.RESET_ADMIN_LIST
	}
}

const getAdminInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/admin/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_INFO_REQUEST,
				{
					type: type.GET_ADMIN_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_INFO_FAILURE
			]
		}
	}
}

const getAdminList = (query = "") => {
	let url = `${CORE_API_ENDPOINT}/admin${query}`

	return {
		[CALL_API]: {
			endpoint: url,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_LIST_REQUEST,
				{
					type: type.GET_ADMIN_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_LIST_FAILURE
			]
		}
	}
}

const getAdminStatusList = () => {
	return {
		
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/admin/status`,
			method: `GET`,
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_STATUS_LIST_REQUEST,
				{
					type: type.GET_ADMIN_STATUS_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_STATUS_LIST_FAILURE
			]
		}
	}
}

const getAdminIntegrationList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/admin/integration/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_INTEGRATION_LIST_REQUEST,
				{
					type: type.GET_ADMIN_INTEGRATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_INTEGRATION_LIST_FAILURE
			]
		}
	}
}

const getAdminADList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/admin/ad/user/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_ACTIVE_DIRECTORY_LIST_REQUEST,
				{
					type: type.GET_ADMIN_ACTIVE_DIRECTORY_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_ACTIVE_DIRECTORY_LIST_FAILURE
			]
		}
	}
}

const getAdminExchangeList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/admin/exchange/user/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ADMIN_EXCHANGE_LIST_REQUEST,
				{
					type: type.GET_ADMIN_EXCHANGE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ADMIN_EXCHANGE_LIST_FAILURE
			]
		}
	}
}

export {
	getAdminInfo,
	getAdminList,
	resetAdminList,
	getAdminStatusList,
	getAdminIntegrationList,
	getAdminADList,
	getAdminExchangeList
}