
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSystemIntegrationServiceList } from '../../actions/systemIntegrationActions'
import SystemIntegrationManager from '../../business/SystemIntegrationManager'
import SystemIntegrationCreate from '../../views/systemIntegration/SystemIntegrationCreate'

class CreateSystemIntegrationController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bSystemIntegration = new SystemIntegrationManager(this)
    }

    getPropsResetStatus() {
        return ["systemIntegrationServiceList"]
    }

    onCreateSystemIntegration = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bSystemIntegration.createSystemIntegration(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {

                    action = this.props.history.push(`/systemintegration/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/systemintegration`)
    }

    onTestSystemIntegration = async (model) => {
        try {
            return this.bSystemIntegration.testSystemIntegration(model.serializeAPI())
        } catch (error) {
            return false
        }
    }

    render() {
        try {
            const { t, systemIntegrationServiceList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            let optionsSystemIntegrationService = systemIntegrationServiceList.getDatasOption()
            optionsSystemIntegrationService = optionsSystemIntegrationService.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.systemIntegration.${d.value}`)
                }
            })
            optionsSystemIntegrationService = optionsSystemIntegrationService.sort((a, b) => { return a.text.localeCompare(b.text) })

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <SystemIntegrationCreate
                        isLoadingControl={isLoadingComponent}
                        optionsSystemIntegrationService={optionsSystemIntegrationService}
                        onCreate={this.onCreateSystemIntegration}
                        onTestSystemIntegration={this.onTestSystemIntegration}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            systemIntegrationServiceList: state.systemIntegrationServiceList
        }),
        (dispatch, props) => ({
            getSystemIntegrationServiceList() {
                dispatch(getSystemIntegrationServiceList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getSystemIntegrationServiceList()
        }
    }),
)


export default enhance(CreateSystemIntegrationController)