
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'

const optionsCurtain = [
    {
        key: "UP",
        value: "UP",
        text: "UP"
    },
    {
        key: "DOWN",
        value: "DOWN",
        text: "DOWN"
    },
    {
        key: "STOP",
        value: "STOP",
        text: "STOP"
    }
]

class CurtainDropdown extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true
    }


    handleChangeValue = (e, { name, value }) => {
        this.props.onChange(null, { name: this.props.name, value: value })
    }

    render() {
        try {
            const { disabled, value } = this.props

            return (
                <React.Fragment>
                    <Form.Dropdown
                        selection
                        selectOnBlur={false}
                        // placeholder={t("common.areaType")}
                        name="curtain"
                        value={(value === "UP" || value === "STOP" || value === "DOWN") ? value : ""}
                        options={optionsCurtain}
                        onChange={this.handleChangeValue}
                        disabled={disabled}
                    />
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

CurtainDropdown.defaultProps = {
    name: "",
    value: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate
)

export default enhance(CurtainDropdown)