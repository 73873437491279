
import PrivateRoute from '../policy/PrivateRoute'
import PublicRoute from '../policy/PublicRoute'

import { cookiesKey } from '../../constant'
import CookiesManager from '../core/business/CookiesManager'

import LoginController from '../controllers/auth/LoginController'
import LoginBypassController from '../controllers/auth/LoginBypassController'
import HomeController from '../controllers/home/HomeController'
// import HomeController2 from '../controllers/home/HomeController2'
import ForgotPasswordController from '../controllers/auth/ForgotPasswordController'
import ResetPasswordController from '../controllers/auth/ResetPasswordController'

//Account
import UpdateAccountController from '../controllers/account/UpdateAccountController'
import ViewAccountController from '../controllers/account/ViewAccountController'
import PasswordAccountController from '../controllers/account/PasswordAccountController'

//Admin
import AdminListController from '../controllers/admin/AdminListController'
import CreateAdminController from '../controllers/admin/CreateAdminController'
import UpdateAdminController from '../controllers/admin/UpdateAdminController'
import ViewAdminController from '../controllers/admin/ViewAdminController'
import ImportAdminExternalUserController from '../controllers/admin/ImportExternalUserController'

// Department
import DepartmentController from '../controllers/department/DepartmentController'
import CreateDepartmentController from '../controllers/department/CreateDepartmentController'
import UpdateDepartmentController from '../controllers/department/UpdateDepartmentController'
import ViewDepartmentController from '../controllers/department/ViewDepartmentController'

//Resource Group
import ResourceGroupController from '../controllers/resourceGroup/ResourceGroupController'
import CreateResourceGroupController from '../controllers/resourceGroup/CreateResourceGroupController'
import UpdateResourceGroupController from '../controllers/resourceGroup/UpdateResourceGroupController'
import ViewResourceGroupController from '../controllers/resourceGroup/ViewResourceGroupController'

// Location
import LocationController from '../controllers/location/LocationController'
import CreateLocationController from '../controllers/location/CreateLocationController'
import UpdateLocationController from '../controllers/location/UpdateLocationController'
import ViewLocationController from '../controllers/location/ViewLocationController'

// Location Type
import LocationTypeController from '../controllers/locationType/LocationTypeController'
import CreateLocationTypeController from '../controllers/locationType/CreateLocationTypeController'
import UpdateLocationTypeController from '../controllers/locationType/UpdateLocationTypeController'
import ViewLocationTypeController from '../controllers/locationType/ViewLocationTypeController'

//Desk 
import DeskController from '../controllers/desk/DeskController'
import CreateDeskController from '../controllers/desk/CreateDeskController'
import UpdateDeskController from '../controllers/desk/UpdateDeskController'
import ViewDeskController from '../controllers/desk/ViewDeskController'

//Door 
import DoorController from '../controllers/door/DoorController'
import CreateDoorController from '../controllers/door/CreateDoorController'
import UpdateDoorController from '../controllers/door/UpdateDoorController'
import ViewDoorController from '../controllers/door/ViewDoorController'

//Room
import RoomController from '../controllers/room/RoomController'
import CreateRoomController from '../controllers/room/CreateRoomController'
import UpdateRoomController from '../controllers/room/UpdateRoomController'
import ViewRoomController from '../controllers/room/ViewRoomController'

//Role
import RoleController from '../controllers/role/RoleController'
import CreateRoleController from '../controllers/role/CreateRoleController'
import UpdateRoleController from '../controllers/role/UpdateRoleController'
import ViewRoleController from '../controllers/role/ViewRoleController'

//Setting
import UpdateSettingController from '../controllers/setting/UpdateSettingController'
import ViewSettingController from '../controllers/setting/ViewSettingController'

//Controller
import ControllerController from '../controllers/controller/ControllerController'
import ViewControllerController from '../controllers/controller/ViewControllerController'

//Gateway
import GatewayController from '../controllers/gateway/GatewayController'
import ViewGatewayController from '../controllers/gateway/ViewGatewayController'

// IOT Config 
import IOTConfigController from '../controllers/iotConfig/IOTConfigController'
import CreateIOTConfigController from '../controllers/iotConfig/CreateIOTConfigController'
import UpdateIOTConfigController from '../controllers/iotConfig/UpdateIOTConfigController'
import ViewIOTConfigController from '../controllers/iotConfig/ViewIOTConfigController'

//Holiday
import HolidayController from '../controllers/holiday/HolidayController'
import CreateHolidayController from '../controllers/holiday/CreateHolidayController'
import UpdateHolidayController from '../controllers/holiday/UpdateHolidayController'
import ViewHolidayController from '../controllers/holiday/ViewHolidayController'

// Schedule Control
import ScheduleControlController from '../controllers/scheduleControl/ScheduleControlController'
import CreateScheduleControlController from '../controllers/scheduleControl/CreateScheduleControlController'
import UpdateScheduleControlController from '../controllers/scheduleControl/UpdateScheduleControlController'
import ViewScheduleControlController from '../controllers/scheduleControl/ViewScheduleControlController'

//User
import UserListController from '../controllers/user/UserListController'
import CreateUserController from '../controllers/user/CreateUserController'
import UpdateUserController from '../controllers/user/UpdateUserController'
import ViewUserController from '../controllers/user/ViewUserController'
import ImportExternalUserController from '../controllers/user/ImportExternalUserController'
import ImportExcelUserController from '../controllers/user/ImportExcelUserController'

// Integration Services
import SystemIntegrationController from '../controllers/systemIntegration/SystemIntegrationController'
import CreateSystemIntegrationController from '../controllers/systemIntegration/CreateSystemIntegrationController'
import UpdateSystemIntegrationController from '../controllers/systemIntegration/UpdateSystemIntegrationController'
import ViewSystemIntegrationController from '../controllers/systemIntegration/ViewSystemIntegrationController'

// Sensor Rule
import ViewSensorRuleController from '../controllers/sensorRule/ViewSensorRuleController'
/* import UpdateSensorRuleController from '../controllers/sensorRule/UpdateSensorRuleController'
import CreateSensorRuleController from '../controllers/sensorRule/CreateSensorRuleController' */
import SensorRuleController from '../controllers/sensorRule/SensorRuleController'

// Sensor Rule Step
import CreateSensorRuleControllerStep from '../controllers/sensorRuleStep/CreateSensorRuleController'
import UpdateSensorRuleControllerStep from '../controllers/sensorRuleStep/UpdateSensorRuleController'

// Event Control Rule
import EventControlRuleController from '../controllers/eventControlRule/EventControlRuleController'

// Event Control Rule Step
import CreateEventControlRuleControllerStep from '../controllers/eventControlRuleStep/CreateEventControlRuleController'
import UpdateEventControlRuleControllerStep from '../controllers/eventControlRuleStep/UpdateEventControlRuleController'
import ViewEventControlRuleController from '../controllers/eventControlRuleStep/ViewEventControlRuleController'

// Event Control Status
import EventControlStatusController from '../controllers/eventControlStatus/EventControlStatusController'

// Notification
import NotificationController from '../controllers/notification/NotificationController'
import CreateNotificationController from '../controllers/notification/CreateNotificationController'
import UpdateNotificationController from '../controllers/notification/UpdateNotificationController'
import ViewNotificationController from '../controllers/notification/ViewNotificationController'

// Sensor 
import SensorController from '../controllers/sensor/SensorController'
import CreateSensorController from '../controllers/sensor/CreateSensorController'
import UpdateSensorController from '../controllers/sensor/UpdateSensorController'
import ViewSensorController from '../controllers/sensor/ViewSensorController'

// Setting Sensor
import UpdateSettingSensorController from '../controllers/settingSensor/UpdateSettingSensorController'
import ViewSettingSensorController from '../controllers/settingSensor/ViewSettingSensorController'

//Report
import ReportAirQualityDailyController from '../controllers/report/ReportAirQualityDailyController'
import ReportAirQualityWeeklyController from '../controllers/report/ReportAirQualityWeeklyController'
import ReportAirQualityMonthlyController from '../controllers/report/ReportAirQualityMonthlyController'

//Visitor Group
import VisitorGroupController from '../controllers/visitorGroup/VisitorGroupController'
import CreateVisitorGroupController from '../controllers/visitorGroup/CreateVisitorGroupController'
import UpdateVisitorGroupController from '../controllers/visitorGroup/UpdateVisitorGroupController'
import ViewVisitorGroupController from '../controllers/visitorGroup/ViewVisitorGroupController'

// Visitor
import VisitorController from '../controllers/visitor/VisitorController'
//import CreateVisitorController from '../controllers/visitor/CreateVisitorController'
import UpdateVisitorController from '../controllers/visitor/UpdateVisitorController'
import ViewVisitorController from '../controllers/visitor/ViewVisitorController'

// RFID Card
import RFIDCardController from '../controllers/rfidCard/RFIDCardController'
import CreateRFIDCardController from '../controllers/rfidCard/CreateRFIDCardController'
import UpdateRFIDCardController from '../controllers/rfidCard/UpdateRFIDCardController'
import ViewRFIDCardController from '../controllers/rfidCard/ViewRFIDCardController'
import ImportExcelRFIDCardController from '../controllers/rfidCard/ImportExcelRFIDCardController'

let routeConfigs = [
    { route: PrivateRoute, path: "/rfidcard/view/:id", component: ViewRFIDCardController },
    { route: PrivateRoute, path: "/rfidcard/update/:id", component: UpdateRFIDCardController },
    { route: PrivateRoute, path: "/rfidcard/create", component: CreateRFIDCardController },
    { route: PrivateRoute, path: "/rfidcard/importexcel", component: ImportExcelRFIDCardController },
    { route: PrivateRoute, path: "/rfidcard", component: RFIDCardController },
    
    { route: PrivateRoute, path: "/visitor/view/:id", component: ViewVisitorController },
    { route: PrivateRoute, path: "/visitor/update/:id", component: UpdateVisitorController },
    { route: PrivateRoute, path: "/visitor", component: VisitorController },

    { route: PrivateRoute, path: "/visitorgroup/view/:id", component: ViewVisitorGroupController },
    { route: PrivateRoute, path: "/visitorgroup/update/:id", component: UpdateVisitorGroupController },
    { route: PrivateRoute, path: "/visitorgroup/create", component: CreateVisitorGroupController },
    { route: PrivateRoute, path: "/visitorgroup", component: VisitorGroupController },

    { route: PrivateRoute, path: "/report/airqualitydaily", component: ReportAirQualityDailyController },
    { route: PrivateRoute, path: "/report/airqualityweekly", component: ReportAirQualityWeeklyController },
    { route: PrivateRoute, path: "/report/airqualitymonthly", component: ReportAirQualityMonthlyController },

    { route: PrivateRoute, path: "/resourcegroup/view/:id", component: ViewResourceGroupController },
    { route: PrivateRoute, path: "/resourcegroup/update/:id", component: UpdateResourceGroupController },
    { route: PrivateRoute, path: "/resourcegroup/create", component: CreateResourceGroupController },
    { route: PrivateRoute, path: "/resourcegroup", component: ResourceGroupController },

    { route: PrivateRoute, path: "/notification/view/:id", component: ViewNotificationController },
    { route: PrivateRoute, path: "/notification/update/:id", component: UpdateNotificationController },
    { route: PrivateRoute, path: "/notification/create", component: CreateNotificationController },
    { route: PrivateRoute, path: "/notification", component: NotificationController },

    { route: PrivateRoute, path: "/systemintegration/view/:id", component: ViewSystemIntegrationController },
    { route: PrivateRoute, path: "/systemintegration/update/:id", component: UpdateSystemIntegrationController },
    { route: PrivateRoute, path: "/systemintegration/create", component: CreateSystemIntegrationController },
    { route: PrivateRoute, path: "/systemintegration", component: SystemIntegrationController },

    { route: PrivateRoute, path: "/user/view/:id", component: ViewUserController },
    { route: PrivateRoute, path: "/user/update/:id", component: UpdateUserController },
    { route: PrivateRoute, path: "/user/create", component: CreateUserController },
    { route: PrivateRoute, path: "/user/importexternal", component: ImportExternalUserController },
    { route: PrivateRoute, path: "/user/importexcel", component: ImportExcelUserController },
    { route: PrivateRoute, path: "/user", component: UserListController },

    { route: PrivateRoute, path: "/holiday/view/:id", component: ViewHolidayController },
    { route: PrivateRoute, path: "/holiday/update/:id", component: UpdateHolidayController },
    { route: PrivateRoute, path: "/holiday/create", component: CreateHolidayController },
    { route: PrivateRoute, path: "/holiday", component: HolidayController },

    { route: PrivateRoute, path: "/role/view/:id", component: ViewRoleController },
    { route: PrivateRoute, path: "/role/update/:id", component: UpdateRoleController },
    { route: PrivateRoute, path: "/role/create", component: CreateRoleController },
    { route: PrivateRoute, path: "/role", component: RoleController },

    { route: PrivateRoute, path: "/locationtype/view/:id", component: ViewLocationTypeController },
    { route: PrivateRoute, path: "/locationtype/update/:id", component: UpdateLocationTypeController },
    { route: PrivateRoute, path: "/locationtype/create", component: CreateLocationTypeController },
    { route: PrivateRoute, path: "/locationtype", component: LocationTypeController },

    { route: PrivateRoute, path: "/location/view/:id", component: ViewLocationController },
    { route: PrivateRoute, path: "/location/update/:id", component: UpdateLocationController },
    { route: PrivateRoute, path: "/location/create", component: CreateLocationController },
    { route: PrivateRoute, path: "/location", component: LocationController },

    { route: PrivateRoute, path: "/department/view/:id", component: ViewDepartmentController },
    { route: PrivateRoute, path: "/department/update/:id", component: UpdateDepartmentController },
    { route: PrivateRoute, path: "/department/create", component: CreateDepartmentController },
    { route: PrivateRoute, path: "/department", component: DepartmentController },

    { route: PrivateRoute, path: "/room/view/:id", component: ViewRoomController },
    { route: PrivateRoute, path: "/room/update/:id", component: UpdateRoomController },
    { route: PrivateRoute, path: "/room/create", component: CreateRoomController },
    { route: PrivateRoute, path: "/room", component: RoomController },

    { route: PrivateRoute, path: "/door/view/:id", component: ViewDoorController },
    { route: PrivateRoute, path: "/door/update/:id", component: UpdateDoorController },
    { route: PrivateRoute, path: "/door/create", component: CreateDoorController },
    { route: PrivateRoute, path: "/door", component: DoorController },

    { route: PrivateRoute, path: "/desk/view/:id", component: ViewDeskController },
    { route: PrivateRoute, path: "/desk/update/:id", component: UpdateDeskController },
    { route: PrivateRoute, path: "/desk/create", component: CreateDeskController },
    { route: PrivateRoute, path: "/desk", component: DeskController },

    { route: PrivateRoute, path: "/admin/view/:id", component: ViewAdminController },
    { route: PrivateRoute, path: "/admin/update/:id", component: UpdateAdminController },
    { route: PrivateRoute, path: "/admin/create", component: CreateAdminController },
    { route: PrivateRoute, path: "/admin/importexternal", component: ImportAdminExternalUserController },
    { route: PrivateRoute, path: "/admin", component: AdminListController },

    { route: PrivateRoute, path: "/account/changepassword", component: PasswordAccountController },
    { route: PrivateRoute, path: "/account/update", component: UpdateAccountController },
    { route: PrivateRoute, path: "/account", component: ViewAccountController },

    { route: PrivateRoute, path: "/setting/sensor/update", component: UpdateSettingSensorController },
    { route: PrivateRoute, path: "/setting/sensor", component: ViewSettingSensorController },

    { route: PrivateRoute, path: "/setting/authorization/update/:tabNum", component: UpdateSettingController },
    { route: PrivateRoute, path: "/setting/authorization/:tabNum", component: ViewSettingController },

    { route: PublicRoute, path: "/resetpassword", component: ResetPasswordController },
    { route: PublicRoute, path: "/forgotpassword", component: ForgotPasswordController },
    { route: PublicRoute, path: "/bypass", component: LoginBypassController },
    { route: PublicRoute, path: "/login", component: LoginController },
    { route: PrivateRoute, path: "/", component: HomeController },
    // { route: PrivateRoute, component: HomeController2 },
]

let bCookie = new CookiesManager()

if (bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === true) {
    let gatewaySetRouteInfo = [
        { route: PrivateRoute, path: "/iotconfig/view/:id", component: ViewIOTConfigController },
        { route: PrivateRoute, path: "/iotconfig/update/:id", component: UpdateIOTConfigController },
        { route: PrivateRoute, path: "/iotconfig/create", component: CreateIOTConfigController },
        { route: PrivateRoute, path: "/iotconfig", component: IOTConfigController },

        { route: PrivateRoute, path: "/gateway/view/:id", component: ViewGatewayController },
        { route: PrivateRoute, path: "/gateway", component: GatewayController },

        { route: PrivateRoute, path: "/controller/view/:id", component: ViewControllerController },
        { route: PrivateRoute, path: "/controller", component: ControllerController },
    ]

    routeConfigs = gatewaySetRouteInfo.concat(routeConfigs)
}

if (bCookie.getData(cookiesKey.IS_ENABLE_SCHEDULE_CONTROL_SERVICE) === true) {
    let scheduleControlRouteInfo = [
        { route: PrivateRoute, path: "/schedulecontrol/view/:id", component: ViewScheduleControlController },
        { route: PrivateRoute, path: "/schedulecontrol/update/:id", component: UpdateScheduleControlController },
        { route: PrivateRoute, path: "/schedulecontrol/create", component: CreateScheduleControlController },
        { route: PrivateRoute, path: "/schedulecontrol", component: ScheduleControlController },
    ]

    routeConfigs = scheduleControlRouteInfo.concat(routeConfigs)
}

if (bCookie.getData(cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE) === true) {
    let sensorRuleRouteInfo = [
        { route: PrivateRoute, path: "/sensorrule/update/:id", component: UpdateSensorRuleControllerStep },
        { route: PrivateRoute, path: "/sensorrule/create", component: CreateSensorRuleControllerStep },

        { route: PrivateRoute, path: "/sensorrule/view/:id", component: ViewSensorRuleController },
        /*        { route: PrivateRoute, path: "/sensorruleold/update/:id", component: UpdateSensorRuleController },
               { route: PrivateRoute, path: "/sensorruleold/create", component: CreateSensorRuleController }, */
        { route: PrivateRoute, path: "/sensorrule", component: SensorRuleController },

        { route: PrivateRoute, path: "/sensor/view/:id", component: ViewSensorController },
        { route: PrivateRoute, path: "/sensor/update/:id", component: UpdateSensorController },
        { route: PrivateRoute, path: "/sensor/create", component: CreateSensorController },
        { route: PrivateRoute, path: "/sensor", component: SensorController },
    ]

    routeConfigs = sensorRuleRouteInfo.concat(routeConfigs)
}

if (bCookie.getData(cookiesKey.IS_ENABLE_EVENT_CONTROL_SERVICE) === true) {
    let eventControlRouteInfo = [
        { route: PrivateRoute, path: "/eventcontrolrule/view/:id", component: ViewEventControlRuleController },
        { route: PrivateRoute, path: "/eventcontrolrule/update/:id", component: UpdateEventControlRuleControllerStep },
        { route: PrivateRoute, path: "/eventcontrolrule/create", component: CreateEventControlRuleControllerStep },
        { route: PrivateRoute, path: "/eventcontrolrule", component: EventControlRuleController },

        { route: PrivateRoute, path: "/eventcontrolstatus", component: EventControlStatusController },
    ]

    routeConfigs = eventControlRouteInfo.concat(routeConfigs)
}

const RouteConfig = {
    module: "",
    routeConfigs,
}

export default RouteConfig