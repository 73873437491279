
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
// import MyController from '../../core/components/MyController'
import { getRoomInfo, getRoomWithIOTConfigInfo, getRoomSceneInfo } from '../../actions/roomActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import RoomManager from '../../business/RoomManager'
import RoomView from '../../views/room/RoomView'
import ControlManager from '../../business/ControlManager'
import SocketController from '../SocketController'
import { eventSocket } from '../../../constant'

class ViewRoomController extends SocketController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false

		this.state.model = null
		this.state.isRegisSubscribe = false
		this.state.roomIOTInfoData = null

		this.bRoom = new RoomManager(this)
		this.bControl = new ControlManager(this)
	}

	getPropsResetStatus() {
		return ["roomInfo", "roomWithIOTConfigInfo"]
	}

	componentDidMount() {
		this.onConnectSocket((socket) => {
			console.log(this.isConnectSocket())
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		if (nextProps.roomWithIOTConfigInfo.requestFinishedAfterRequest()) {
			tempState.roomIOTInfoData = nextProps.roomWithIOTConfigInfo.getData()
		}
		if (nextProps.roomInfo.requestFinishedAfterRequest()) {
			tempState.model = nextProps.roomInfo.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		const { isRegisSubscribe, model, roomIOTInfoData } = this.state
		if (!isRegisSubscribe && !!model && !!roomIOTInfoData) {
			// console.log("Start Socket", model, roomIOTInfoData)
			this.setState({
				isRegisSubscribe: true
			}, () => { this.onRegisterSubscribeIOTConfig(model, roomIOTInfoData) })
		}
	}

	componentWillUnmount() {
		this.onDisconnectSocket()
	}

	//For socket
	onRegisterSubscribeIOTConfig = (room, roomIOTInfoData) => {
		let roomId = room.id
			, iotConfigIds = []
		if (Array.isArray(roomIOTInfoData.iotConfigs) && roomIOTInfoData.iotConfigs.length > 0) {
			iotConfigIds = roomIOTInfoData.iotConfigs.map(config => config.id)
		}
		if (!!roomId && iotConfigIds.length > 0 && this.isConnectSocket()) {
			this.state.socket.emit(eventSocket.REGISTER, { id: roomId, iotConfigIds: iotConfigIds })
			this.state.socket.on(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, this.onReceiveResponseStatusChange)
		}
	}

	onReceiveResponseStatusChange = (res) => {
		this.afterReceiveSocket(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, res, this.onStatusChange)
	}

	onStatusChange = (event, res) => {
		if (!!res && !!res.data) {
			const { model, roomIOTInfoData } = this.state
			let data = res.data
			if (data.id === model.id && Array.isArray(data.result) && data.result.length > 0) {
				let tempRoomIOTInfoData = roomIOTInfoData
					, iotConfigs = tempRoomIOTInfoData.iotConfigs
				if (Array.isArray(iotConfigs) && iotConfigs.length > 0) {
					let isChange = false
					data.result.forEach(change => {
						let index = iotConfigs.findIndex(iot => iot.id === change.id)
						if (index > -1) {
							isChange = true
							let oldData = iotConfigs[index]
							oldData.status = change.status
							iotConfigs[index] = oldData
						}
					})
					if (isChange) {
						tempRoomIOTInfoData.iotConfigs = iotConfigs
						this.setState({
							roomIOTInfoData: tempRoomIOTInfoData
						})
					}
				}
			}
		}
	}

	onDeleteAreaRoom = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.deleteAreaRoom(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getRoomInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaRoom = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.upsertAreaRoom(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }
				if (!!res && res.result) {
					action = () => this.props.getRoomInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationRoom = (model, serviceInfo) => {
		// console.log("Model ID: ", model.id)
		// console.log("Service: ", serviceInfo.service)

		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.unintegrateRoom(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getRoomInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onDeleteSceneRoom = (roomId, sceneId) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.deleteRoomScene(roomId, sceneId, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getRoomSceneInfo(roomId) }

				this.setState(tempState, action)
			})
		})
	}

	onCreateSceneRoom = (id, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.createRoomScene(id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getRoomSceneInfo(id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUpdateSceneRoom = (roomId, sceneId, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bRoom.updateRoomScene(roomId, sceneId, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getRoomSceneInfo(roomId)
				}

				this.setState(tempState, action)
			})
		})
	}

	onControlStatus = (roomId, iotId, status) => {
		//console.log("status: ", status)
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.controlStatus(iotId, status, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getRoomWithIOTConfigInfo(roomId)
				}

				this.setState(tempState, action)
			})
		})
	}

	turnOnSceneRoom = (sceneID) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.turnOnSceneResource(sceneID, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDeskWithIOTConfigInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	testSyncPlatformRoom = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bRoom.roomTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { roomInfo, roomSceneInfo, locationList } = this.props // roomIOTInfoData
			const { roomIOTInfoData, isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const room = roomInfo.getData()
				// , roomIOTInfoData = roomWithIOTConfigInfo.getData()
				, roomSceneInfoData = roomSceneInfo.getDatas()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let sortedRoomSceneInfoData = roomSceneInfoData.sort((a, b) => { return a.code.localeCompare(b.code) })

			let locationInfo = null

			if (!!room) {
				if (!!room.locationId) {
					locationInfo = locationList.findDataByKey("id", room.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<RoomView
							model={room}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaRoom={this.onDeleteAreaRoom}
							onUpsertAreaRoom={this.onUpsertAreaRoom}
							onUnintegtationRoom={this.onUnintegtationRoom}
							locationList={locationDatas}
							onCreateSceneRoom={this.onCreateSceneRoom}
							onUpdateSceneRoom={this.onUpdateSceneRoom}
							onDeleteSceneRoom={this.onDeleteSceneRoom}
							onControlStatus={this.onControlStatus}
							turnOnSceneRoom={this.turnOnSceneRoom}
							roomIOTInfo={roomIOTInfoData}
							roomSceneInfo={sortedRoomSceneInfoData}
							testSyncPlatformRoom={this.testSyncPlatformRoom}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			roomInfo: state.roomInfo,
			locationList: state.locationList,
			roomWithIOTConfigInfo: state.roomWithIOTConfigInfo,
			roomSceneInfo: state.roomSceneInfo
		}),
		(dispatch, props) => ({
			getRoomInfo() {
				dispatch(getRoomInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getRoomWithIOTConfigInfo() {
				dispatch(getRoomWithIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getRoomSceneInfo() {
				dispatch(getRoomSceneInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getRoomInfo()
			this.props.getRoomWithIOTConfigInfo()
			this.props.getRoomSceneInfo()
		}
	}),
)


export default enhance(ViewRoomController)