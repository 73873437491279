
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetLocationList = () => {
	return {
		type: type.CORE_RESET_LOCATION_LIST
	}
}

const getLocationInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/location/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_REQUEST,
				{
					type: type.CORE_GET_LOCATION_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_FAILURE
			]
		}
	}
}

const getLocationWithIOTConfigInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/location/withstatus/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_REQUEST,
				{
					type: type.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_FAILURE
			]
		}
	}
}

const getLocationSceneInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/location/scene/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_SCENE_INFO_REQUEST,
				{
					type: type.CORE_GET_LOCATION_SCENE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_SCENE_INFO_FAILURE
			]
		}
	}
}

const getLocationList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/location${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_LIST_REQUEST,
				{
					type: type.CORE_GET_LOCATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_LIST_FAILURE
			]
		}
	}
}

export {
	getLocationInfo,
	getLocationList,
	resetLocationList,
	getLocationWithIOTConfigInfo,
	getLocationSceneInfo
}