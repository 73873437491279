
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getScheduleControlInfo, getScheduleControlHolidayTypeList } from '../../actions/scheduleControlActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import ScheduleControlManager from '../../business/ScheduleControlManager'
import ScheduleControlView from '../../views/scheduleControl/ScheduleControlView'

class ViewScheduleControlController extends MyController {
	constructor(props) {
		super(props)

		this.bScheduleControl = new ScheduleControlManager(this)
	}

	getPropsResetStatus() {
		return ["scheduleControlInfo"]
	}

	render() {
		try {
			const { scheduleControlInfo } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const scheduleControl = scheduleControlInfo.getData()

			// console.log("Data", ScheduleControlInfo, organization, faceComparisonGroup)

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<ScheduleControlView
							model={scheduleControl}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			scheduleControlInfo: state.scheduleControlInfo,
			scheduleHolidayTypeList: state.scheduleHolidayTypeList
		}),
		(dispatch, props) => ({
			getScheduleControlInfo() {
				dispatch(getScheduleControlInfo(utils.getParamFromProps(props, "id")))
			},
			getScheduleControlHolidayTypeList() {
				dispatch(getScheduleControlHolidayTypeList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getScheduleControlInfo()
			this.props.getScheduleControlHolidayTypeList()
		}
	}),
)


export default enhance(ViewScheduleControlController) 