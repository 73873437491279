
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Header } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewTable from '../../core/view/common/table/ViewTable'

import ButtonModel from '../../core/model/ButtonModel'
//import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
//import FormSearchEventControlStatus from './FormSearchEventControlStatus'
import EventControlStatusModel from '../../models/EventControlStatusModel'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import ViewButton from '../common/ViewButton'

class EventControlStatusList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.modelSearch = new EventControlStatusModel()
        this.state.model = null
    }

    onClickDelete = (model) => {
        const { t, onDeleteEventControlStatus } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteEventControlStatus(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickForceTurnOffResource = (model) => {
        const { t, onForceTurnOffResource } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.forceTurnOffResource"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onForceTurnOffResource(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnResourceConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: t("common.count"),
                selector: (row) => row.count ? row.count : 0,
                sortable: false,
                width: '150px'
            },
            {
                name: '',
                minWidth: 'fit-content',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("eventControlStatus.forceTurnOff"),
                            color: "red",
                            onClick: () => { this.onClickForceTurnOffResource(row) },
                            iconClass: "power off",
                            size: "mini"
                        },
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickForceTurnOffLocation = (model) => {
        const { t, onForceTurnOffLocation } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.forceTurnOffLocation"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onForceTurnOffLocation(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnLocationConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: t("common.count"),
                selector: (row) => row.count ? row.count : 0,
                sortable: false,
                width: '150px'
            },
            {
                name: '',
                minWidth: 'fit-content',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("eventControlStatus.forceTurnOff"),
                            color: "red",
                            onClick: () => { this.onClickForceTurnOffLocation(row) },
                            iconClass: "power off",
                            size: "mini"
                        },
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let gatewayId = ""
                if (!!model.gatewayId && model.gatewayId !== defaultValue.SELECT_ALL) {
                    gatewayId = model.gatewayId
                }

                let querys = [
                    {
                        name: "mac",
                        value: model.mac,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "gatewayId",
                        value: gatewayId
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    render() {
        try {
            const { t, eventControlStatusList, isLoadingTable } = this.props
            const { isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('eventControlStatus.title')}>
                        <Container>
                            <div className="body">
                                {
                                    !!eventControlStatusList &&
                                    <React.Fragment>
                                        <Grid divided='vertically'>
                                            <Grid.Row columns={2} className="remove-bottom-padding">
                                                <Grid.Column width={16} textAlign="left" verticalAlign="bottom">
                                                    <Header as='h1'>{t("eventControlRule.resource")}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewTable
                                                        columns={this.getColumnResourceConfig()}
                                                        datas={eventControlStatusList.resources}
                                                        isLoading={isLoadingTable}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        <br></br>
                                        <br></br>
                                        <Grid divided='vertically'>
                                            <Grid.Row columns={2} className="remove-bottom-padding">
                                                <Grid.Column width={16} textAlign="left" verticalAlign="bottom">
                                                    <Header as='h1'>{t("location.title")}</Header>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewTable
                                                        columns={this.getColumnLocationConfig()}
                                                        datas={eventControlStatusList.locations}
                                                        isLoading={isLoadingTable}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </React.Fragment>
                                }

                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

EventControlStatusList.defaultProps = {
    controllerList: [],
    optionLocationList: [],
    optionsStatusList: [],
    optionsGatewayList: [],
    isLoadingTable: false,
    onDeletecontroller: () => console.error("Please provide \"Delete EventControlStatus\" action."),
    onReloadData: () => console.error("Please provide Load Data action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(EventControlStatusList)