
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import { getRoomInfo } from '../../actions/roomActions'
import RoomManager from '../../business/RoomManager'
import utils from '../../core/utility/utils'
import RoomUpdate from '../../views/room/RoomUpdate'

class UpdateRoomController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRoom = new RoomManager(this)
	}

	getPropsResetStatus() {
		return ["roomInfo"]
	}

	onUpdateRoom = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.updateRoom(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/room/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { roomInfo, locationList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const room = roomInfo.getData()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			return (
				<React.Fragment>
					{super.render()}
					<RoomUpdate
						isLoadingControl={isLoadingComponent}
						model={room}
						optionsTreeLocation={optionsTreeLocation}
						onUpdate={this.onUpdateRoom}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationList: state.locationList,
			roomInfo: state.roomInfo
		}),
		(dispatch, props) => ({
			getLocationList() {
				dispatch(getLocationList())
			},
			getRoomInfo() {
				dispatch(getRoomInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getRoomInfo()
		}
	}),
)


export default enhance(UpdateRoomController)