
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const getNotificationServiceList = () => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/service`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_NOTIFICATION_SERVICE_LIST_REQUEST,
                {
                    type: type.GET_NOTIFICATION_SERVICE_LIST_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_NOTIFICATION_SERVICE_LIST_FAILURE
            ]
        }
    }
}

const getNotificationSystemList = () => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/notification/system`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_NOTIFICATION_SYSTEM_LIST_REQUEST,
                {
                    type: type.GET_NOTIFICATION_SYSTEM_LIST_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_NOTIFICATION_SYSTEM_LIST_FAILURE
            ]
        }
    }
}

const getNotificationList = (query = "") => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/notification${query}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_NOTIFICATION_LIST_REQUEST,
                {
                    type: type.GET_NOTIFICATION_LIST_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_NOTIFICATION_LIST_FAILURE
            ]
        }
    }
}

const getNotificationInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/notification/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_NOTIFICATION_INFO_REQUEST,
                {
                    type: type.GET_NOTIFICATION_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_NOTIFICATION_INFO_FAILURE
            ]
        }
    }
}



export {
    getNotificationServiceList,
    getNotificationSystemList,
    getNotificationList,
    getNotificationInfo
}