import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetControllerList = () => {
	return {
		type: type.RESET_CONTROLLER_LIST
	}
}

const getControllerInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/controller/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_CONTROLLER_INFO_REQUEST,
				{
					type: type.GET_CONTROLLER_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_CONTROLLER_INFO_FAILURE
			]
		}
	}
}

const getControllerConfigInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/controller/config/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_CONTROLLER_CONFIG_INFO_REQUEST,
				{
					type: type.GET_CONTROLLER_CONFIG_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_CONTROLLER_CONFIG_INFO_FAILURE
			]
		}
	}
}

const getControllerList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/controller${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_CONTROLLER_LIST_REQUEST,
				{
					type: type.GET_CONTROLLER_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_CONTROLLER_LIST_FAILURE
			]
		}
	}
}

export {
    getControllerInfo,
    getControllerList,
	resetControllerList,
	getControllerConfigInfo
}