
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const LocationMasterSchema = new Schema({
	id: { type: String, default: null },
	name: { type: String, default: null },
	code: { type: String, default: null },
}, { _id: false })

const ParentSchema = new Schema({
	id: { type: String, default: null },
	name: { type: String, default: null },
	code: { type: String, default: null },
	parentId: { type: String, default: null },
}, { _id: false })


const LocationSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, default: null },
	name: { type: String, default: null },
	description: { type: String, default: null },
	// isDisable: { type: Boolean, default: false },

	locationType: { type: LocationMasterSchema, default: null },
	locationTypeId: { type: String, default: null },

	parentId: { type: String, default: null },
	parent: { type: ParentSchema, default: null },
	fileUpload: { type: FileSchema, default: null },
	areas: { type: Object, default: null },
	childs: { type: Object, default: null },
	iotConfigs: { type: Object, default: null },
	locationId: { type: String, default: null },

	formType: { type: String, enum: ["CREATE_AREA", "EDIT_AREA", "CREATE_LOCATION", "UPDATE_LOCATION"], default: "CREATE_LOCATION" }

}, { _id: false })

setBaseSchema(LocationSchema, "02")

LocationSchema.path("code").validate(async function (code) {
	if (this.formType === "CREATE_AREA") {
		return true
	} else if (this.formType === "EDIT_AREA") {
		return true
	} else if (this.formType === "CREATE_LOCATION") {
		return !!code
	} else if (this.formType === "UPDATE_LOCATION") {
		return !!code
	}
	return true
}, "validate.require")

LocationSchema.path("name").validate(async function (name) {
	if (this.formType === "CREATE_AREA") {
		return true
	} else if (this.formType === "EDIT_AREA") {
		return true
	} else if (this.formType === "CREATE_LOCATION") {
		return !!name
	} else if (this.formType === "UPDATE_LOCATION") {
		return !!name
	}
	return true
}, "validate.require")

LocationSchema.path("locationTypeId").validate(async function (locationTypeId) {
	if (this.formType === "CREATE_AREA") {
		return true
	} else if (this.formType === "EDIT_AREA") {
		return true
	} else if (this.formType === "CREATE_LOCATION") {
		return !!locationTypeId
	} else if (this.formType === "UPDATE_LOCATION") {
		return !!locationTypeId
	}
	return true
}, "validate.require")

LocationSchema.path("locationId").validate(async function (locationId) {
	if (this.formType === "CREATE_AREA") {
		return !!locationId
	} else if (this.formType === "EDIT_AREA") {
		return !!locationId
	} else if (this.formType === "CREATE_LOCATION") {
		return true
	} else if (this.formType === "UPDATE_LOCATION") {
		return true
	}
	return true
}, "validate.require")

LocationSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
			locationTypeId: this.locationTypeId,
			parentId: this.parentId
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		return result
		
	} catch (error) {
		return null
	}
}


const LocationModel = mongoose.model("location", LocationSchema, "location")

export default LocationModel