
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header, Label, Button } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ModalTimeDate from './ModalTimeDate'
import { dateTimeFormat } from '../../../constant'

class HolidayForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isOpenModalDate = false

        this.state.modelDateTime = null
        this.state.dateTimeIndex = null
        this.state.dateTimeStatus = null
    }

    onOpenModalDate = (status, model, index) => {
        this.setState({
            isOpenModalDate: true,
            modelDateTime: model,
            dateTimeIndex: index,
            dateTimeStatus: status
        })
    }

    onCloseModalDate = () => {
        this.setState({
            isOpenModalDate: false
        })
    }

    onSubmitDate = (obj) => {
        const { dateTimeIndex, dateTimeStatus } = this.state
        this.setState({
            isOpenModalDate: false
        }, () => {
            this.props.onSubmitDate(obj, dateTimeStatus, dateTimeIndex)
        })
    }

    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.type ? t(`constantTranslate.holidayDateType.${row.type}`) : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.date"),
                selector: (row, i) => {
                    if (row.type === "ALL_YEAR") {
                        let weekdayDisplay = row.weekday.map((d, i) => {
                            if (i === 0) {
                                return t(`constantTranslate.dayOfWeek.${d}`)
                            } else {
                                return ', ' + t(`constantTranslate.dayOfWeek.${d}`)
                            }
                        })

                        return weekdayDisplay
                    } else if (row.type === "RANGE") {
                        return moment(row.startDateTime).format(dateTimeFormat.displayDate) + ' - ' + moment(row.endDateTime).format(dateTimeFormat.displayDate)
                    } else if (row.type === "DAY") {
                        return moment(row.startDateTime).format(dateTimeFormat.displayDate)
                    }
                },
                sortable: false
            },
            {
                name: '',
                width: '250px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModalDate("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.props.onDeleteDate(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t, model, errors, optionsYear, optionsWeekday, optionsDateType } = this.props
            const { modelDateTime, isOpenModalDate } = this.state

            let yearValidate = this.getErrorInput("year", errors)
                , subjectValidate = this.getErrorInput("subject", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , dateInfoValidate = this.getErrorInput("dateInfo", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalDate &&
                        <ModalTimeDate
                            model={modelDateTime}
                            optionsWeekday={optionsWeekday}
                            optionsDateType={optionsDateType}
                            onSubmit={this.onSubmitDate}
                            onClose={this.onCloseModalDate}
                            handleChange={this.handleChange}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.year')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="year"
                                        value={model.year}
                                        options={optionsYear}
                                        onChange={this.handleChange}
                                        error={yearValidate.isError ? yearValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.subject')} isRequire={true} />
                                    <Form.Input
                                        name="subject"
                                        value={model.subject}
                                        onChange={this.handleChange}
                                        error={subjectValidate.isError ? subjectValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                        <Button
                            content={t('common.add')}
                            floated="right"
                            color="green"
                            onClick={() => this.onOpenModalDate("CREATE", null, null)}
                        />
                        <Header as='h3' className="form header view-small-divider">
                            <div className="view-h3-header-button">{t("common.date")}<b style={{ color: "#f00", fontSize: "1.75rem" }}>*</b>

                            </div>
                        </Header>
                        <Grid>
                            {/*                        <Grid.Row>
                                <Grid.Column width={8}><LabelInput text={t('common.date')} isRequire={true} /></Grid.Column>
                                <Grid.Column width={8} textAlign={"right"}>
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.add'),
                                            color: "green",
                                            onClick: this.onOpenModalDate
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row> */}
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnConfig()}
                                        datas={model.dateInfo}
                                        pagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (dateInfoValidate.isError &&
                                    dateInfoValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {dateInfoValidate.content}
                                    </Label>
                                </Form.Field>
                            }

                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

HolidayForm.defaultProps = {
    model: null,
    errors: [],
    optionsYear: [], 
    optionsWeekday: [], 
    optionsDateType: [],
    handleChange: () => console.error("Please provide \"Change Resource Group\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(HolidayForm)