
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import DoorManager from '../../business/DoorManager'
import DoorCreate from '../../views/door/DoorCreate'
import utils from '../../core/utility/utils'

class CreateDoorController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bDoor = new DoorManager(this)
    }

    onCreateDoor = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bDoor.createDoor(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {

                    action = this.props.history.push(`/door/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/door`)
    }

    render() {
        try {
            const { locationList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            const locationDatas = locationList.getDatas()
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

            return (
                <React.Fragment>
                    {super.render()}
                    <DoorCreate
                        isLoadingControl={isLoadingComponent}
                        optionsTreeLocation={optionsTreeLocation}
                        onCreate={this.onCreateDoor} />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            locationList: state.locationList
        }),
        (dispatch, props) => ({
            getLocationList() {
                dispatch(getLocationList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getLocationList()
        }
    }),
)


export default enhance(CreateDoorController)