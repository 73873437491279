
const types = {
    GET_ACCOUNT_INFO_REQUEST: 'GET_ACCOUNT_INFO_REQUEST',
    GET_ACCOUNT_INFO_SUCCESS: 'GET_ACCOUNT_INFO_SUCCESS',
    GET_ACCOUNT_INFO_FAILURE: 'GET_ACCOUNT_INFO_FAILURE',

    GET_ADMIN_INFO_REQUEST: 'GET_ADMIN_INFO_REQUEST',
    GET_ADMIN_INFO_SUCCESS: 'GET_ADMIN_INFO_SUCCESS',
    GET_ADMIN_INFO_FAILURE: 'GET_ADMIN_INFO_FAILURE',

    GET_ADMIN_LIST_REQUEST: 'GET_ADMIN_LIST_REQUEST',
    GET_ADMIN_LIST_SUCCESS: 'GET_ADMIN_LIST_SUCCESS',
    GET_ADMIN_LIST_FAILURE: 'GET_ADMIN_LIST_FAILURE',
    RESET_ADMIN_LIST: 'RESET_ADMIN_LIST',

    GET_ADMIN_STATUS_LIST_REQUEST: 'GET_ADMIN_STATUS_LIST_REQUEST',
    GET_ADMIN_STATUS_LIST_SUCCESS: 'GET_ADMIN_STATUS_LIST_SUCCESS',
    GET_ADMIN_STATUS_LIST_FAILURE: 'GET_ADMIN_STATUS_LIST_FAILURE',

    CORE_GET_LOCATION_REQUEST: 'CORE_GET_LOCATION_REQUEST',
    CORE_GET_LOCATION_SUCCESS: 'CORE_GET_LOCATION_SUCCESS',
    CORE_GET_LOCATION_FAILURE: 'CORE_GET_LOCATION_FAILURE',

    CORE_GET_LOCATION_LIST_REQUEST: 'CORE_GET_LOCATION_LIST_REQUEST',
    CORE_GET_LOCATION_LIST_SUCCESS: 'CORE_GET_LOCATION_LIST_SUCCESS',
    CORE_GET_LOCATION_LIST_FAILURE: 'CORE_GET_LOCATION_LIST_FAILURE',
    CORE_RESET_LOCATION_LIST: 'CORE_RESET_LOCATION_LIST',

    CORE_GET_LOCATION_SCENE_INFO_REQUEST: 'CORE_GET_LOCATION_SCENE_INFO_REQUEST',
    CORE_GET_LOCATION_SCENE_INFO_SUCCESS: 'CORE_GET_LOCATION_SCENE_INFO_SUCCESS',
    CORE_GET_LOCATION_SCENE_INFO_FAILURE: 'CORE_GET_LOCATION_SCENE_INFO_FAILURE',

    CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_REQUEST: 'CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_REQUEST',
    CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_SUCCESS: 'CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_SUCCESS',
    CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_FAILURE: 'CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_FAILURE',

    CORE_GET_LOCATION_TYPE_REQUEST: 'CORE_GET_LOCATION_TYPE_REQUEST',
    CORE_GET_LOCATION_TYPE_SUCCESS: 'CORE_GET_LOCATION_TYPE_SUCCESS',
    CORE_GET_LOCATION_TYPE_FAILURE: 'CORE_GET_LOCATION_TYPE_FAILURE',

    CORE_GET_LOCATION_TYPE_LIST_REQUEST: 'CORE_GET_LOCATION_TYPE_LIST_REQUEST',
    CORE_GET_LOCATION_TYPE_LIST_SUCCESS: 'CORE_GET_LOCATION_TYPE_LIST_SUCCESS',
    CORE_GET_LOCATION_TYPE_LIST_FAILURE: 'CORE_GET_LOCATION_TYPE_LIST_FAILURE',
    CORE_RESET_LOCATION_TYPE_LIST: 'CORE_RESET_LOCATION_TYPE_LIST',

    CORE_GET_DEPARTMENT_REQUEST: 'CORE_GET_DEPARTMENT_REQUEST',
    CORE_GET_DEPARTMENT_SUCCESS: 'CORE_GET_DEPARTMENT_SUCCESS',
    CORE_GET_DEPARTMENT_FAILURE: 'CORE_GET_DEPARTMENT_FAILURE',

    CORE_GET_DEPARTMENT_LIST_REQUEST: 'CORE_GET_DEPARTMENT_LIST_REQUEST',
    CORE_GET_DEPARTMENT_LIST_SUCCESS: 'CORE_GET_DEPARTMENT_LIST_SUCCESS',
    CORE_GET_DEPARTMENT_LIST_FAILURE: 'CORE_GET_DEPARTMENT_LIST_FAILURE',
    CORE_RESET_DEPARTMENT_LIST: 'CORE_RESET_DEPARTMENT_LIST',

    CORE_GET_RESOURCE_GROUP_INFO_REQUEST: 'CORE_GET_RESOURCE_GROUP_INFO_REQUEST',
    CORE_GET_RESOURCE_GROUP_INFO_SUCCESS: 'CORE_GET_RESOURCE_GROUP_INFO_SUCCESS',
    CORE_GET_RESOURCE_GROUP_INFO_FAILURE: 'CORE_GET_RESOURCE_GROUP_INFO_FAILURE',

    CORE_GET_RESOURCE_GROUP_LIST_REQUEST: 'CORE_GET_RESOURCE_GROUP_LIST_REQUEST',
    CORE_GET_RESOURCE_GROUP_LIST_SUCCESS: 'CORE_GET_RESOURCE_GROUP_LIST_SUCCESS',
    CORE_GET_RESOURCE_GROUP_LIST_FAILURE: 'CORE_GET_RESOURCE_GROUP_LIST_FAILURE',
    CORE_RESET_RESOURCE_GROUP_LIST: 'CORE_RESET_RESOURCE_GROUP_LIST',

    CORE_GET_RESOURCE_GROUP_USER_LIST_REQUEST: 'CORE_GET_RESOURCE_GROUP_USER_LIST_REQUEST',
    CORE_GET_RESOURCE_GROUP_USER_LIST_SUCCESS: 'CORE_GET_RESOURCE_GROUP_USER_LIST_SUCCESS',
    CORE_GET_RESOURCE_GROUP_USER_LIST_FAILURE: 'CORE_GET_RESOURCE_GROUP_USER_LIST_FAILURE',

    GET_USER_INFO_REQUEST: 'GET_USER_INFO_REQUEST',
    GET_USER_INFO_SUCCESS: 'GET_USER_INFO_SUCCESS',
    GET_USER_INFO_FAILURE: 'GET_USER_INFO_FAILURE',

    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',
    RESET_USER_LIST: 'RESET_USER_LIST',

    GET_USER_STATUS_LIST_REQUEST: 'GET_USER_STATUS_LIST_REQUEST',
    GET_USER_STATUS_LIST_SUCCESS: 'GET_USER_STATUS_LIST_SUCCESS',
    GET_USER_STATUS_LIST_FAILURE: 'GET_USER_STATUS_LIST_FAILURE',

    GET_DESK_INFO_REQUEST: 'GET_DESK_INFO_REQUEST',
    GET_DESK_INFO_SUCCESS: 'GET_DESK_INFO_SUCCESS',
    GET_DESK_INFO_FAILURE: 'GET_DESK_INFO_FAILURE',

    GET_DESK_LIST_REQUEST: 'GET_DESK_LIST_REQUEST',
    GET_DESK_LIST_SUCCESS: 'GET_DESK_LIST_SUCCESS',
    GET_DESK_LIST_FAILURE: 'GET_DESK_LIST_FAILURE',
    RESET_DESK_LIST: 'RESET_DESK_LIST',

    GET_DESK_SCENE_INFO_REQUEST: 'GET_DESK_SCENE_INFO_REQUEST',
    GET_DESK_SCENE_INFO_SUCCESS: 'GET_DESK_SCENE_INFO_SUCCESS',
    GET_DESK_SCENE_INFO_FAILURE: 'GET_DESK_SCENE_INFO_FAILURE',

    GET_DESK_WITH_IOT_CONFIG_INFO_REQUEST: 'GET_DESK_WITH_IOT_CONFIG_INFO_REQUEST',
    GET_DESK_WITH_IOT_CONFIG_INFO_SUCCESS: 'GET_DESK_WITH_IOT_CONFIG_INFO_SUCCESS',
    GET_DESK_WITH_IOT_CONFIG_INFO_FAILURE: 'GET_DESK_WITH_IOT_CONFIG_INFO_FAILURE',

    GET_ROOM_INFO_REQUEST: 'GET_ROOM_INFO_REQUEST',
    GET_ROOM_INFO_SUCCESS: 'GET_ROOM_INFO_SUCCESS',
    GET_ROOM_INFO_FAILURE: 'GET_ROOM_INFO_FAILURE',

    GET_ROOM_LIST_REQUEST: 'GET_ROOM_LIST_REQUEST',
    GET_ROOM_LIST_SUCCESS: 'GET_ROOM_LIST_SUCCESS',
    GET_ROOM_LIST_FAILURE: 'GET_ROOM_LIST_FAILURE',
    RESET_ROOM_LIST: 'RESET_ROOM_LIST',

    GET_ROOM_SCENE_INFO_REQUEST: 'GET_ROOM_SCENE_INFO_REQUEST',
    GET_ROOM_SCENE_INFO_SUCCESS: 'GET_ROOM_SCENE_INFO_SUCCESS',
    GET_ROOM_SCENE_INFO_FAILURE: 'GET_ROOM_SCENE_INFO_FAILURE',

    GET_ROOM_WITH_IOT_CONFIG_INFO_REQUEST: 'GET_ROOM_WITH_IOT_CONFIG_INFO_REQUEST',
    GET_ROOM_WITH_IOT_CONFIG_INFO_SUCCESS: 'GET_ROOM_WITH_IOT_CONFIG_INFO_SUCCESS',
    GET_ROOM_WITH_IOT_CONFIG_INFO_FAILURE: 'GET_ROOM_WITH_IOT_CONFIG_INFO_FAILURE',

    GET_ROLE_INFO_REQUEST: 'GET_ROLE_INFO_REQUEST',
    GET_ROLE_INFO_SUCCESS: 'GET_ROLE_INFO_SUCCESS',
    GET_ROLE_INFO_FAILURE: 'GET_ROLE_INFO_FAILURE',

    GET_ROLE_LIST_REQUEST: 'GET_ROLE_LIST_REQUEST',
    GET_ROLE_LIST_SUCCESS: 'GET_ROLE_LIST_SUCCESS',
    GET_ROLE_LIST_FAILURE: 'GET_ROLE_LIST_FAILURE',
    RESET_ROLE_LIST: 'RESET_ROLE_LIST',

    GET_SETTING_INFO_REQUEST: 'GET_SETTING_INFO_REQUEST',
    GET_SETTING_INFO_SUCCESS: 'GET_SETTING_INFO_SUCCESS',
    GET_SETTING_INFO_FAILURE: 'GET_SETTING_INFO_FAILURE',

    GET_AUTH_LOGIN_INFO_REQUEST: 'GET_AUTH_LOGIN_INFO_REQUEST',
    GET_AUTH_LOGIN_INFO_SUCCESS: 'GET_AUTH_LOGIN_INFO_SUCCESS',
    GET_AUTH_LOGIN_INFO_FAILURE: 'GET_AUTH_LOGIN_INFO_FAILURE',

    GET_SETTING_SYSTEM_LIST_REQUEST: 'GET_SETTING_SYSTEM_LIST_REQUEST',
    GET_SETTING_SYSTEM_LIST_SUCCESS: 'GET_SETTING_SYSTEM_LIST_SUCCESS',
    GET_SETTING_SYSTEM_LIST_FAILURE: 'GET_SETTING_SYSTEM_LIST_FAILURE',

    GET_CONTROLLER_INFO_REQUEST: 'GET_CONTROLLER_INFO_REQUEST',
    GET_CONTROLLER_INFO_SUCCESS: 'GET_CONTROLLER_INFO_SUCCESS',
    GET_CONTROLLER_INFO_FAILURE: 'GET_CONTROLLER_INFO_FAILURE',

    GET_CONTROLLER_LIST_REQUEST: 'GET_CONTROLLER_LIST_REQUEST',
    GET_CONTROLLER_LIST_SUCCESS: 'GET_CONTROLLER_LIST_SUCCESS',
    GET_CONTROLLER_LIST_FAILURE: 'GET_CONTROLLER_LIST_FAILURE',
    RESET_CONTROLLER_LIST: 'RESET_CONTROLLER_LIST',

    GET_CONTROLLER_CONFIG_INFO_REQUEST: 'GET_CONTROLLER_CONFIG_INFO_REQUEST',
    GET_CONTROLLER_CONFIG_INFO_SUCCESS: 'GET_CONTROLLER_CONFIG_INFO_SUCCESS',
    GET_CONTROLLER_CONFIG_INFO_FAILURE: 'GET_CONTROLLER_CONFIG_INFO_FAILURE',

    GET_GATEWAY_INFO_REQUEST: 'GET_GATEWAY_INFO_REQUEST',
    GET_GATEWAY_INFO_SUCCESS: 'GET_GATEWAY_INFO_SUCCESS',
    GET_GATEWAY_INFO_FAILURE: 'GET_GATEWAY_INFO_FAILURE',

    GET_GATEWAY_LIST_REQUEST: 'GET_GATEWAY_LIST_REQUEST',
    GET_GATEWAY_LIST_SUCCESS: 'GET_GATEWAY_LIST_SUCCESS',
    GET_GATEWAY_LIST_FAILURE: 'GET_GATEWAY_LIST_FAILURE',
    RESET_GATEWAY_LIST: 'RESET_GATEWAY_LIST',

    GET_IOT_CONFIG_INFO_REQUEST: 'GET_IOT_CONFIG_INFO_REQUEST',
    GET_IOT_CONFIG_INFO_SUCCESS: 'GET_IOT_CONFIG_INFO_SUCCESS',
    GET_IOT_CONFIG_INFO_FAILURE: 'GET_IOT_CONFIG_INFO_FAILURE',

    GET_IOT_CONFIG_LIST_REQUEST: 'GET_IOT_CONFIG_LIST_REQUEST',
    GET_IOT_CONFIG_LIST_SUCCESS: 'GET_IOT_CONFIG_LIST_SUCCESS',
    GET_IOT_CONFIG_LIST_FAILURE: 'GET_IOT_CONFIG_LIST_FAILURE',
    RESET_IOT_CONFIG_LIST: 'RESET_IOT_CONFIG_LIST',

    GET_IOT_CONFIG_LOCATION_LIST_REQUEST: 'GET_IOT_CONFIG_LOCATION_LIST_REQUEST',
    GET_IOT_CONFIG_LOCATION_LIST_SUCCESS: 'GET_IOT_CONFIG_LOCATION_LIST_SUCCESS',
    GET_IOT_CONFIG_LOCATION_LIST_FAILURE: 'GET_IOT_CONFIG_LOCATION_LIST_FAILURE',

    GET_IOT_CONFIG_RESOURCE_LIST_REQUEST: 'GET_IOT_CONFIG_RESOURCE_LIST_REQUEST',
    GET_IOT_CONFIG_RESOURCE_LIST_SUCCESS: 'GET_IOT_CONFIG_RESOURCE_LIST_SUCCESS',
    GET_IOT_CONFIG_RESOURCE_LIST_FAILURE: 'GET_IOT_CONFIG_RESOURCE_LIST_FAILURE',

    GET_IOT_CONFIG_IOT_LIST_REQUEST: 'GET_IOT_CONFIG_IOT_LIST_REQUEST',
    GET_IOT_CONFIG_IOT_LIST_SUCCESS: 'GET_IOT_CONFIG_IOT_LIST_SUCCESS',
    GET_IOT_CONFIG_IOT_LIST_FAILURE: 'GET_IOT_CONFIG_IOT_LIST_FAILURE',

    GET_IOT_CONFIG_CONTROLLER_LIST_REQUEST: 'GET_IOT_CONFIG_CONTROLLER_LIST_REQUEST',
    GET_IOT_CONFIG_CONTROLLER_LIST_SUCCESS: 'GET_IOT_CONFIG_CONTROLLER_LIST_SUCCESS',
    GET_IOT_CONFIG_CONTROLLER_LIST_FAILURE: 'GET_IOT_CONFIG_CONTROLLER_LIST_FAILURE',

    GET_HOLIDAY_INFO_REQUEST: 'GET_HOLIDAY_INFO_REQUEST',
    GET_HOLIDAY_INFO_SUCCESS: 'GET_HOLIDAY_INFO_SUCCESS',
    GET_HOLIDAY_INFO_FAILURE: 'GET_HOLIDAY_INFO_FAILURE',

    GET_HOLIDAY_LIST_REQUEST: 'GET_HOLIDAY_LIST_REQUEST',
    GET_HOLIDAY_LIST_SUCCESS: 'GET_HOLIDAY_LIST_SUCCESS',
    GET_HOLIDAY_LIST_FAILURE: 'GET_HOLIDAY_LIST_FAILURE',
    RESET_HOLIDAY_LIST: 'RESET_HOLIDAY_LIST',

    GET_SCHEDULE_CONTROL_INFO_REQUEST: 'GET_SCHEDULE_CONTROL_INFO_REQUEST',
    GET_SCHEDULE_CONTROL_INFO_SUCCESS: 'GET_SCHEDULE_CONTROL_INFO_SUCCESS',
    GET_SCHEDULE_CONTROL_INFO_FAILURE: 'GET_SCHEDULE_CONTROL_INFO_FAILURE',

    GET_SCHEDULE_CONTROL_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_LIST_FAILURE',
    RESET_SCHEDULE_CONTROL_LIST: 'RESET_SCHEDULE_CONTROL_LIST',

    GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_FAILURE',

    GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_FAILURE',

    GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_FAILURE',

    GET_SCHEDULE_CONTROL_RESOURCE_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_RESOURCE_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_RESOURCE_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_RESOURCE_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_RESOURCE_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_RESOURCE_LIST_FAILURE',

    GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_REQUEST: 'GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_REQUEST',
    GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_SUCCESS: 'GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_SUCCESS',
    GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_FAILURE: 'GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_FAILURE',

    GET_DOOR_INFO_REQUEST: 'GET_DOOR_INFO_REQUEST',
    GET_DOOR_INFO_SUCCESS: 'GET_DOOR_INFO_SUCCESS',
    GET_DOOR_INFO_FAILURE: 'GET_DOOR_INFO_FAILURE',

    GET_DOOR_LIST_REQUEST: 'GET_DOOR_LIST_REQUEST',
    GET_DOOR_LIST_SUCCESS: 'GET_DOOR_LIST_SUCCESS',
    GET_DOOR_LIST_FAILURE: 'GET_DOOR_LIST_FAILURE',
    RESET_DOOR_LIST: 'RESET_DOOR_LIST',

    GET_DOOR_SCENE_INFO_REQUEST: 'GET_DOOR_SCENE_INFO_REQUEST',
    GET_DOOR_SCENE_INFO_SUCCESS: 'GET_DOOR_SCENE_INFO_SUCCESS',
    GET_DOOR_SCENE_INFO_FAILURE: 'GET_DOOR_SCENE_INFO_FAILURE',

    GET_DOOR_WITH_IOT_CONFIG_INFO_REQUEST: 'GET_DOOR_WITH_IOT_CONFIG_INFO_REQUEST',
    GET_DOOR_WITH_IOT_CONFIG_INFO_SUCCESS: 'GET_DOOR_WITH_IOT_CONFIG_INFO_SUCCESS',
    GET_DOOR_WITH_IOT_CONFIG_INFO_FAILURE: 'GET_DOOR_WITH_IOT_CONFIG_INFO_FAILURE',

    GET_SYSTEM_INTEGRATION_SERVICE_LIST_REQUEST: 'GET_SYSTEM_INTEGRATION_SERVICE_LIST_REQUEST',
    GET_SYSTEM_INTEGRATION_SERVICE_LIST_SUCCESS: 'GET_SYSTEM_INTEGRATION_SERVICE_LIST_SUCCESS',
    GET_SYSTEM_INTEGRATION_SERVICE_LIST_FAILURE: 'GET_SYSTEM_INTEGRATION_SERVICE_LIST_FAILURE',

    GET_O365_INFO_REQUEST: 'GET_O365_INFO_REQUEST',
    GET_O365_INFO_SUCCESS: 'GET_O365_INFO_SUCCESS',
    GET_O365_INFO_FAILURE: 'GET_O365_INFO_FAILURE',

    GET_GOOGLE_AUTHEN_INFO_REQUEST: 'GET_GOOGLE_AUTHEN_INFO_REQUEST',
    GET_GOOGLE_AUTHEN_INFO_SUCCESS: 'GET_GOOGLE_AUTHEN_INFO_SUCCESS',
    GET_GOOGLE_AUTHEN_INFO_FAILURE: 'GET_GOOGLE_AUTHEN_INFO_FAILURE',

    GET_GOOGLE_SERVICE_ACCOUNT_INFO_REQUEST: 'GET_GOOGLE_SERVICE_ACCOUNT_INFO_REQUEST',
    GET_GOOGLE_SERVICE_ACCOUNT_INFO_SUCCESS: 'GET_GOOGLE_SERVICE_ACCOUNT_INFO_SUCCESS',
    GET_GOOGLE_SERVICE_ACCOUNT_INFO_FAILURE: 'GET_GOOGLE_SERVICE_ACCOUNT_INFO_FAILURE',

    GET_EXCHANGE_INFO_REQUEST: 'GET_EXCHANGE_INFO_REQUEST',
    GET_EXCHANGE_INFO_SUCCESS: 'GET_EXCHANGE_INFO_SUCCESS',
    GET_EXCHANGE_INFO_FAILURE: 'GET_EXCHANGE_INFO_FAILURE',

    GET_ACTIVE_DIRECTORY_INFO_REQUEST: 'GET_ACTIVE_DIRECTORY_INFO_REQUEST',
    GET_ACTIVE_DIRECTORY_INFO_SUCCESS: 'GET_ACTIVE_DIRECTORY_INFO_SUCCESS',
    GET_ACTIVE_DIRECTORY_INFO_FAILURE: 'GET_ACTIVE_DIRECTORY_INFO_FAILURE',

    GET_LINE_INFO_REQUEST: 'GET_LINE_INFO_REQUEST',
    GET_LINE_INFO_SUCCESS: 'GET_LINE_INFO_SUCCESS',
    GET_LINE_INFO_FAILURE: 'GET_LINE_INFO_FAILURE',

    GET_ZOOM_INFO_REQUEST: 'GET_ZOOM_INFO_REQUEST',
    GET_ZOOM_INFO_SUCCESS: 'GET_ZOOM_INFO_SUCCESS',
    GET_ZOOM_INFO_FAILURE: 'GET_ZOOM_INFO_FAILURE',

    GET_SEND_GRID_INFO_REQUEST: 'GET_SEND_GRID_INFO_REQUEST',
    GET_SEND_GRID_INFO_SUCCESS: 'GET_SEND_GRID_INFO_SUCCESS',
    GET_SEND_GRID_INFO_FAILURE: 'GET_SEND_GRID_INFO_FAILURE',

    GET_SMTP_INFO_REQUEST: 'GET_SMTP_INFO_REQUEST',
    GET_SMTP_INFO_SUCCESS: 'GET_SMTP_INFO_SUCCESS',
    GET_SMTP_INFO_FAILURE: 'GET_SMTP_INFO_FAILURE',

    GET_PROFILE_CENTER_INFO_REQUEST: 'GET_PROFILE_CENTER_INFO_REQUEST',
    GET_PROFILE_CENTER_INFO_SUCCESS: 'GET_PROFILE_CENTER_INFO_SUCCESS',
    GET_PROFILE_CENTER_INFO_FAILURE: 'GET_PROFILE_CENTER_INFO_FAILURE',

    GET_SYSTEM_INTEGRATION_LIST_REQUEST: 'GET_SYSTEM_INTEGRATION_LIST_REQUEST',
    GET_SYSTEM_INTEGRATION_LIST_SUCCESS: 'GET_SYSTEM_INTEGRATION_LIST_SUCCESS',
    GET_SYSTEM_INTEGRATION_LIST_FAILURE: 'GET_SYSTEM_INTEGRATION_LIST_FAILURE',

    GET_SYSTEM_INTEGRATION_INFO_REQUEST: 'GET_SYSTEM_INTEGRATION_INFO_REQUEST',
    GET_SYSTEM_INTEGRATION_INFO_SUCCESS: 'GET_SYSTEM_INTEGRATION_INFO_SUCCESS',
    GET_SYSTEM_INTEGRATION_INFO_FAILURE: 'GET_SYSTEM_INTEGRATION_INFO_FAILURE',

    GET_SENSOR_RULE_INFO_REQUEST: 'GET_SENSOR_RULE_INFO_REQUEST',
    GET_SENSOR_RULE_INFO_SUCCESS: 'GET_SENSOR_RULE_INFO_SUCCESS',
    GET_SENSOR_RULE_INFO_FAILURE: 'GET_SENSOR_RULE_INFO_FAILURE',

    GET_SENSOR_RULE_LIST_REQUEST: 'GET_SENSOR_RULE_LIST_REQUEST',
    GET_SENSOR_RULE_LIST_SUCCESS: 'GET_SENSOR_RULE_LIST_SUCCESS',
    GET_SENSOR_RULE_LIST_FAILURE: 'GET_SENSOR_RULE_LIST_FAILURE',

    GET_CONDITION_HOLIDAY_TYPE_LIST_REQUEST: "GET_CONDITION_HOLIDAY_TYPE_LIST_REQUEST",
    GET_CONDITION_HOLIDAY_TYPE_LIST_SUCCESS: "GET_CONDITION_HOLIDAY_TYPE_LIST_SUCCESS",
    GET_CONDITION_HOLIDAY_TYPE_LIST_FAILURE: "GET_CONDITION_HOLIDAY_TYPE_LIST_FAILURE",

    GET_SENSOR_RULE_TYPE_LIST_REQUEST: 'GET_SENSOR_RULE_TYPE_LIST_REQUEST',
    GET_SENSOR_RULE_TYPE_LIST_SUCCESS: 'GET_SENSOR_RULE_TYPE_LIST_SUCCESS',
    GET_SENSOR_RULE_TYPE_LIST_FAILURE: 'GET_SENSOR_RULE_TYPE_LIST_FAILURE',

    GET_SENSOR_RULE_ACTION_TYPE_LIST_REQUEST: 'GET_SENSOR_RULE_ACTION_TYPE_LIST_REQUEST',
    GET_SENSOR_RULE_ACTION_TYPE_LIST_SUCCESS: 'GET_SENSOR_RULE_ACTION_TYPE_LIST_SUCCESS',
    GET_SENSOR_RULE_ACTION_TYPE_LIST_FAILURE: 'GET_SENSOR_RULE_ACTION_TYPE_LIST_FAILURE',

    GET_SENSOR_RULE_ACTION_RESULT_LIST_REQUEST: 'GET_SENSOR_RULE_ACTION_RESULT_LIST_REQUEST',
    GET_SENSOR_RULE_ACTION_RESULT_LIST_SUCCESS: 'GET_SENSOR_RULE_ACTION_RESULT_LIST_SUCCESS',
    GET_SENSOR_RULE_ACTION_RESULT_LIST_FAILURE: 'GET_SENSOR_RULE_ACTION_RESULT_LIST_FAILURE',

    GET_SENSOR_RULE_LOCATION_TYPE_LIST_REQUEST: 'GET_SENSOR_RULE_LOCATION_TYPE_LIST_REQUEST',
    GET_SENSOR_RULE_LOCATION_TYPE_LIST_SUCCESS: 'GET_SENSOR_RULE_LOCATION_TYPE_LIST_SUCCESS',
    GET_SENSOR_RULE_LOCATION_TYPE_LIST_FAILURE: 'GET_SENSOR_RULE_LOCATION_TYPE_LIST_FAILURE',

    GET_SENSOR_RULE_RESOURCE_LIST_REQUEST: 'GET_SENSOR_RULE_RESOURCE_LIST_REQUEST',
    GET_SENSOR_RULE_RESOURCE_LIST_SUCCESS: 'GET_SENSOR_RULE_RESOURCE_LIST_SUCCESS',
    GET_SENSOR_RULE_RESOURCE_LIST_FAILURE: 'GET_SENSOR_RULE_RESOURCE_LIST_FAILURE',

    GET_SENSOR_RULE_DATA_TYPE_LIST_REQUEST: 'GET_SENSOR_RULE_DATA_TYPE_LIST_REQUEST',
    GET_SENSOR_RULE_DATA_TYPE_LIST_SUCCESS: 'GET_SENSOR_RULE_DATA_TYPE_LIST_SUCCESS',
    GET_SENSOR_RULE_DATA_TYPE_LIST_FAILURE: 'GET_SENSOR_RULE_DATA_TYPE_LIST_FAILURE',

    GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_REQUEST: 'GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_REQUEST',
    GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_SUCCESS: 'GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_SUCCESS',
    GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_FAILURE: 'GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_FAILURE',

    GET_SENSOR_RULE_DAY_OF_WEEK_LIST_REQUEST: 'GET_SENSOR_RULE_DAY_OF_WEEK_LIST_REQUEST',
    GET_SENSOR_RULE_DAY_OF_WEEK_LIST_SUCCESS: 'GET_SENSOR_RULE_DAY_OF_WEEK_LIST_SUCCESS',
    GET_SENSOR_RULE_DAY_OF_WEEK_LIST_FAILURE: 'GET_SENSOR_RULE_DAY_OF_WEEK_LIST_FAILURE',

    GET_NOTIFICATION_LIST_REQUEST: 'GET_NOTIFICATION_LIST_REQUEST',
    GET_NOTIFICATION_LIST_SUCCESS: 'GET_NOTIFICATION_LIST_SUCCESS',
    GET_NOTIFICATION_LIST_FAILURE: 'GET_NOTIFICATION_LIST_FAILURE',

    GET_NOTIFICATION_INFO_REQUEST: 'GET_NOTIFICATION_INFO_REQUEST',
    GET_NOTIFICATION_INFO_SUCCESS: 'GET_NOTIFICATION_INFO_SUCCESS',
    GET_NOTIFICATION_INFO_FAILURE: 'GET_NOTIFICATION_INFO_FAILURE',

    GET_NOTIFICATION_SERVICE_LIST_REQUEST: 'GET_NOTIFICATION_SERVICE_LIST_REQUEST',
    GET_NOTIFICATION_SERVICE_LIST_SUCCESS: 'GET_NOTIFICATION_SERVICE_LIST_SUCCESS',
    GET_NOTIFICATION_SERVICE_LIST_FAILURE: 'GET_NOTIFICATION_SERVICE_LIST_FAILURE',

    GET_NOTIFICATION_SYSTEM_LIST_REQUEST: 'GET_NOTIFICATION_SYSTEM_LIST_REQUEST',
    GET_NOTIFICATION_SYSTEM_LIST_SUCCESS: 'GET_NOTIFICATION_SYSTEM_LIST_SUCCESS',
    GET_NOTIFICATION_SYSTEM_LIST_FAILURE: 'GET_NOTIFICATION_SYSTEM_LIST_FAILURE',

    GET_SENSOR_INFO_REQUEST: 'GET_SENSOR_INFO_REQUEST',
    GET_SENSOR_INFO_SUCCESS: 'GET_SENSOR_INFO_SUCCESS',
    GET_SENSOR_INFO_FAILURE: 'GET_SENSOR_INFO_FAILURE',

    GET_SENSOR_LIST_REQUEST: 'GET_SENSOR_LIST_REQUEST',
    GET_SENSOR_LIST_SUCCESS: 'GET_SENSOR_LIST_SUCCESS',
    GET_SENSOR_LIST_FAILURE: 'GET_SENSOR_LIST_FAILURE',
    RESET_SENSOR_LIST: 'RESET_SENSOR_LIST',

    GET_SENSOR_LOCATION_LIST_REQUEST: 'GET_SENSOR_LOCATION_LIST_REQUEST',
    GET_SENSOR_LOCATION_LIST_SUCCESS: 'GET_SENSOR_LOCATION_LIST_SUCCESS',
    GET_SENSOR_LOCATION_LIST_FAILURE: 'GET_SENSOR_LOCATION_LIST_FAILURE',

    GET_SENSOR_RESOURCE_LIST_REQUEST: 'GET_SENSOR_RESOURCE_LIST_REQUEST',
    GET_SENSOR_RESOURCE_LIST_SUCCESS: 'GET_SENSOR_RESOURCE_LIST_SUCCESS',
    GET_SENSOR_RESOURCE_LIST_FAILURE: 'GET_SENSOR_RESOURCE_LIST_FAILURE',

    GET_SENSOR_TYPE_LIST_REQUEST: 'GET_SENSOR_TYPE_LIST_REQUEST',
    GET_SENSOR_TYPE_LIST_SUCCESS: 'GET_SENSOR_TYPE_LIST_SUCCESS',
    GET_SENSOR_TYPE_LIST_FAILURE: 'GET_SENSOR_TYPE_LIST_FAILURE',

    GET_SENSOR_MODEL_LIST_REQUEST: 'GET_SENSOR_MODEL_LIST_REQUEST',
    GET_SENSOR_MODEL_LIST_SUCCESS: 'GET_SENSOR_MODEL_LIST_SUCCESS',
    GET_SENSOR_MODEL_LIST_FAILURE: 'GET_SENSOR_MODEL_LIST_FAILURE',

    GET_SENSOR_MODULE_LIST_REQUEST: 'GET_SENSOR_MODULE_LIST_REQUEST',
    GET_SENSOR_MODULE_LIST_SUCCESS: 'GET_SENSOR_MODULE_LIST_SUCCESS',
    GET_SENSOR_MODULE_LIST_FAILURE: 'GET_SENSOR_MODULE_LIST_FAILURE',

    GET_SENSOR_LOCATION_TYPE_LIST_REQUEST: 'GET_SENSOR_LOCATION_TYPE_LIST_REQUEST',
    GET_SENSOR_LOCATION_TYPE_LIST_SUCCESS: 'GET_SENSOR_LOCATION_TYPE_LIST_SUCCESS',
    GET_SENSOR_LOCATION_TYPE_LIST_FAILURE: 'GET_SENSOR_LOCATION_TYPE_LIST_FAILURE',

    GET_SENSOR_CONTROLLER_LIST_REQUEST: 'GET_SENSOR_CONTROLLER_LIST_REQUEST',
    GET_SENSOR_CONTROLLER_LIST_SUCCESS: 'GET_SENSOR_CONTROLLER_LIST_SUCCESS',
    GET_SENSOR_CONTROLLER_LIST_FAILURE: 'GET_SENSOR_CONTROLLER_LIST_FAILURE',

    GET_SETTING_NOTIFICATION_LIST_REQUEST: 'GET_SETTING_NOTIFICATION_LIST_REQUEST',
    GET_SETTING_NOTIFICATION_LIST_SUCCESS: 'GET_SETTING_NOTIFICATION_LIST_SUCCESS',
    GET_SETTING_NOTIFICATION_LIST_FAILURE: 'GET_SETTING_NOTIFICATION_LIST_FAILURE',

    GET_SETTING_SENSOR_INFO_REQUEST: 'GET_SETTING_SENSOR_INFO_REQUEST',
    GET_SETTING_SENSOR_INFO_SUCCESS: 'GET_SETTING_SENSOR_INFO_SUCCESS',
    GET_SETTING_SENSOR_INFO_FAILURE: 'GET_SETTING_SENSOR_INFO_FAILURE',

    GET_SETTING_SENSOR_TYPE_LIST_REQUEST: 'GET_SETTING_SENSOR_TYPE_LIST_REQUEST',
    GET_SETTING_SENSOR_TYPE_LIST_SUCCESS: 'GET_SETTING_SENSOR_TYPE_LIST_SUCCESS',
    GET_SETTING_SENSOR_TYPE_LIST_FAILURE: 'GET_SETTING_SENSOR_TYPE_LIST_FAILURE',

    GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_REQUEST: 'GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_REQUEST',
    GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_SUCCESS: 'GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_SUCCESS',
    GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_FAILURE: 'GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_FAILURE',

    GET_ACCOUNT_INTEGRATION_LIST_REQUEST: 'GET_ACCOUNT_INTEGRATION_LIST_REQUEST',
    GET_ACCOUNT_INTEGRATION_LIST_SUCCESS: 'GET_ACCOUNT_INTEGRATION_LIST_SUCCESS',
    GET_ACCOUNT_INTEGRATION_LIST_FAILURE: 'GET_ACCOUNT_INTEGRATION_LIST_FAILURE',

    GET_ADMIN_INTEGRATION_LIST_REQUEST: 'GET_ADMIN_INTEGRATION_LIST_REQUEST',
    GET_ADMIN_INTEGRATION_LIST_SUCCESS: 'GET_ADMIN_INTEGRATION_LIST_SUCCESS',
    GET_ADMIN_INTEGRATION_LIST_FAILURE: 'GET_ADMIN_INTEGRATION_LIST_FAILURE',

    GET_USER_INTEGRATION_LIST_REQUEST: 'GET_USER_INTEGRATION_LIST_REQUEST',
    GET_USER_INTEGRATION_LIST_SUCCESS: 'GET_USER_INTEGRATION_LIST_SUCCESS',
    GET_USER_INTEGRATION_LIST_FAILURE: 'GET_USER_INTEGRATION_LIST_FAILURE',

    GET_ADMIN_ACTIVE_DIRECTORY_LIST_REQUEST: 'GET_ADMIN_ACTIVE_DIRECTORY_LIST_REQUEST',
    GET_ADMIN_ACTIVE_DIRECTORY_LIST_SUCCESS: 'GET_ADMIN_ACTIVE_DIRECTORY_LIST_SUCCESS',
    GET_ADMIN_ACTIVE_DIRECTORY_LIST_FAILURE: 'GET_ADMIN_ACTIVE_DIRECTORY_LIST_FAILURE',

    GET_ADMIN_EXCHANGE_LIST_REQUEST: 'GET_ADMIN_EXCHANGE_LIST_REQUEST',
    GET_ADMIN_EXCHANGE_LIST_SUCCESS: 'GET_ADMIN_EXCHANGE_LIST_SUCCESS',
    GET_ADMIN_EXCHANGE_LIST_FAILURE: 'GET_ADMIN_EXCHANGE_LIST_FAILURE',

    GET_USER_ACTIVE_DIRECTORY_LIST_REQUEST: 'GET_USER_ACTIVE_DIRECTORY_LIST_REQUEST',
    GET_USER_ACTIVE_DIRECTORY_LIST_SUCCESS: 'GET_USER_ACTIVE_DIRECTORY_LIST_SUCCESS',
    GET_USER_ACTIVE_DIRECTORY_LIST_FAILURE: 'GET_USER_ACTIVE_DIRECTORY_LIST_FAILURE',

    GET_USER_EXCHANGE_LIST_REQUEST: 'GET_USER_EXCHANGE_LIST_REQUEST',
    GET_USER_EXCHANGE_LIST_SUCCESS: 'GET_USER_EXCHANGE_LIST_SUCCESS',
    GET_USER_EXCHANGE_LIST_FAILURE: 'GET_USER_EXCHANGE_LIST_FAILURE',

    GET_VISITOR_GROUP_INFO_REQUEST: 'GET_VISITOR_GROUP_INFO_REQUEST',
    GET_VISITOR_GROUP_INFO_SUCCESS: 'GET_VISITOR_GROUP_INFO_SUCCESS',
    GET_VISITOR_GROUP_INFO_FAILURE: 'GET_VISITOR_GROUP_INFO_FAILURE',

    GET_VISITOR_GROUP_LIST_REQUEST: 'GET_VISITOR_GROUP_LIST_REQUEST',
    GET_VISITOR_GROUP_LIST_SUCCESS: 'GET_VISITOR_GROUP_LIST_SUCCESS',
    GET_VISITOR_GROUP_LIST_FAILURE: 'GET_VISITOR_GROUP_LIST_FAILURE',
    RESET_VISITOR_GROUP_LIST: 'RESET_VISITOR_GROUP_LIST',

    GET_VISITOR_INFO_REQUEST: 'GET_VISITOR_INFO_REQUEST',
    GET_VISITOR_INFO_SUCCESS: 'GET_VISITOR_INFO_SUCCESS',
    GET_VISITOR_INFO_FAILURE: 'GET_VISITOR_INFO_FAILURE',

    GET_VISITOR_LIST_REQUEST: 'GET_VISITOR_LIST_REQUEST',
    GET_VISITOR_LIST_SUCCESS: 'GET_VISITOR_LIST_SUCCESS',
    GET_VISITOR_LIST_FAILURE: 'GET_VISITOR_LIST_FAILURE',
    RESET_VISITOR_LIST: 'RESET_VISITOR_LIST',

    GET_VISITOR_STATUS_LIST_REQUEST: 'GET_VISITOR_STATUS_LIST_REQUEST',
    GET_VISITOR_STATUS_LIST_SUCCESS: 'GET_VISITOR_STATUS_LIST_SUCCESS',
    GET_VISITOR_STATUS_LIST_FAILURE: 'GET_VISITOR_STATUS_LIST_FAILURE',

    GET_EVENT_CONTROL_RULE_INFO_REQUEST: 'GET_EVENT_CONTROL_RULE_INFO_REQUEST',
    GET_EVENT_CONTROL_RULE_INFO_SUCCESS: 'GET_EVENT_CONTROL_RULE_INFO_SUCCESS',
    GET_EVENT_CONTROL_RULE_INFO_FAILURE: 'GET_EVENT_CONTROL_RULE_INFO_FAILURE',

    GET_EVENT_CONTROL_RULE_LIST_REQUEST: 'GET_EVENT_CONTROL_RULE_LIST_REQUEST',
    GET_EVENT_CONTROL_RULE_LIST_SUCCESS: 'GET_EVENT_CONTROL_RULE_LIST_SUCCESS',
    RESET_EVENT_CONTROL_RULE_LIST: 'RESET_EVENT_CONTROL_RULE_LIST',
    GET_EVENT_CONTROL_RULE_LIST_FAILURE: 'GET_EVENT_CONTROL_RULE_LIST_FAILURE',

    GET_ECR_HOLIDAY_TYPE_LIST_REQUEST: 'GET_ECR_HOLIDAY_TYPE_LIST_REQUEST',
    GET_ECR_HOLIDAY_TYPE_LIST_SUCCESS: 'GET_ECR_HOLIDAY_TYPE_LIST_SUCCESS',
    GET_ECR_HOLIDAY_TYPE_LIST_FAILURE: 'GET_ECR_HOLIDAY_TYPE_LIST_FAILURE',

    GET_ECR_ACTION_TYPE_LIST_REQUEST: 'GET_ECR_ACTION_TYPE_LIST_REQUEST',
    GET_ECR_ACTION_TYPE_LIST_SUCCESS: 'GET_ECR_ACTION_TYPE_LIST_SUCCESS',
    GET_ECR_ACTION_TYPE_LIST_FAILURE: 'GET_ECR_ACTION_TYPE_LIST_FAILURE',

    GET_ECR_ACTION_STATUS_LIST_REQUEST: 'GET_ECR_ACTION_STATUS_LIST_REQUEST',
    GET_ECR_ACTION_STATUS_LIST_SUCCESS: 'GET_ECR_ACTION_STATUS_LIST_SUCCESS',
    GET_ECR_ACTION_STATUS_LIST_FAILURE: 'GET_ECR_ACTION_STATUS_LIST_FAILURE',

    GET_ECR_RECURRENCE_TYPE_LIST_REQUEST: 'GET_ECR_RECURRENCE_TYPE_LIST_REQUEST',
    GET_ECR_RECURRENCE_TYPE_LIST_SUCCESS: 'GET_ECR_RECURRENCE_TYPE_LIST_SUCCESS',
    GET_ECR_RECURRENCE_TYPE_LIST_FAILURE: 'GET_ECR_RECURRENCE_TYPE_LIST_FAILURE',

    GET_ECR_DAY_OF_WEEK_LIST_REQUEST: 'GET_ECR_DAY_OF_WEEK_LIST_REQUEST',
    GET_ECR_DAY_OF_WEEK_LIST_SUCCESS: 'GET_ECR_DAY_OF_WEEK_LIST_SUCCESS',
    GET_ECR_DAY_OF_WEEK_LIST_FAILURE: 'GET_ECR_DAY_OF_WEEK_LIST_FAILURE',

    GET_ECR_EVENT_LIST_REQUEST: 'GET_ECR_EVENT_LIST_REQUEST',
    GET_ECR_EVENT_LIST_SUCCESS: 'GET_ECR_EVENT_LIST_SUCCESS',
    GET_ECR_EVENT_LIST_FAILURE: 'GET_ECR_EVENT_LIST_FAILURE',

    GET_EVENT_CONTROL_STATUS_LIST_REQUEST: 'GET_EVENT_CONTROL_STATUS_LIST_REQUEST',
    GET_EVENT_CONTROL_STATUS_LIST_SUCCESS: 'GET_EVENT_CONTROL_STATUS_LIST_SUCCESS',
    RESET_EVENT_CONTROL_STATUS_LIST: 'RESET_EVENT_CONTROL_STATUS_LIST',
    GET_EVENT_CONTROL_STATUS_LIST_FAILURE: 'GET_EVENT_CONTROL_STATUS_LIST_FAILURE',

    GET_RFID_CARD_INFO_REQUEST: 'GET_RFID_CARD_INFO_REQUEST',
    GET_RFID_CARD_INFO_SUCCESS: 'GET_RFID_CARD_INFO_SUCCESS',
    GET_RFID_CARD_INFO_FAILURE: 'GET_RFID_CARD_INFO_FAILURE',

    GET_RFID_CARD_LIST_REQUEST: 'GET_RFID_CARD_LIST_REQUEST',
    GET_RFID_CARD_LIST_SUCCESS: 'GET_RFID_CARD_LIST_SUCCESS',
    RESET_RFID_CARD_LIST: 'RESET_RFID_CARD_LIST',
    GET_RFID_CARD_LIST_FAILURE: 'GET_RFID_CARD_LIST_FAILURE',

    GET_RFID_CARD_TYPE_LIST_REQUEST: 'GET_RFID_CARD_TYPE_LIST_REQUEST',
    GET_RFID_CARD_TYPE_LIST_SUCCESS: 'GET_RFID_CARD_TYPE_LIST_SUCCESS',
    GET_RFID_CARD_TYPE_LIST_FAILURE: 'GET_RFID_CARD_TYPE_LIST_FAILURE',

    GET_RFID_CARD_DATA_TYPE_LIST_REQUEST: 'GET_RFID_CARD_DATA_TYPE_LIST_REQUEST',
    GET_RFID_CARD_DATA_TYPE_LIST_SUCCESS: 'GET_RFID_CARD_DATA_TYPE_LIST_SUCCESS',
    GET_RFID_CARD_DATA_TYPE_LIST_FAILURE: 'GET_RFID_CARD_DATA_TYPE_LIST_FAILURE',
}

export default types
