
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ParentSchema = new Schema({
	name: { type: String, default: null },
	code: { type: String, default: null },
	parentId: { type: String, default: null },
}, { _id: false })

const DepartmentSchema = new Schema({
	code: { type: String, required: [true, "validate.require"], default: null },
	name: { type: String, required: [true, "validate.require"], default: null },
	description: { type: String, default: null },

	// isDisable: { type: Boolean, default: false },

	parentId: { type: String, default: null },
	parent: { type: ParentSchema, default: null },


	id: { type: String, default: null },

}, { _id: false })

setBaseSchema(DepartmentSchema, "01")


DepartmentSchema.methods.setData = function (data) {
	try {
		this.code = data.code
	} catch (error) {
		return null
	}
}

const DepartmentModel = mongoose.model("department", DepartmentSchema, "department")

export default DepartmentModel