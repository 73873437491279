
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Table, Grid, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import SystemIntegrationModel from '../../models/SystemIntegrationModel'
import LabelInput from '../common/LabelInput'

import ActiveDirectoryDataView from './systemIntegrationDataView/ActiveDirectoryDataView'
import ExchangeDataView from './systemIntegrationDataView/ExchangeDataView'
import GoogleAuthenDataView from './systemIntegrationDataView/GoogleAuthenDataView'
import GoogleServiceAccountDataView from './systemIntegrationDataView/GoogleServiceAccountDataView'
import O365DataView from './systemIntegrationDataView/O365DataView'
import ProfileCenterDataView from './systemIntegrationDataView/ProfileCenterDataView'
import SendGridDataView from './systemIntegrationDataView/SendGridDataView'
import SMTPDataView from './systemIntegrationDataView/SMTPDataView'
import LineDataView from './systemIntegrationDataView/LineDataView'
import SMS8X8DataView from './systemIntegrationDataView/SMS8X8DataView'
import { serviceCode } from '../../../constant'
import ViewNoResult from '../common/ViewNoResult'
import ViewButton from '../common/ViewButton'

class SystemIntegrationView extends MyPage {
    constructor(props) {
        super(props)

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/systemintegration/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/systemintegration/update/${model.id}`)
    }

    onCancel = () => {
        this.props.history.replace("/systemintegration")
    }

    getDataIntegrationData = (service) => {
        let ComponentView = null
        switch (service) {
            case serviceCode.ACTIVE_DIRECTORY:
                ComponentView = ActiveDirectoryDataView
                break;
            case serviceCode.EXCHANGE:
                ComponentView = ExchangeDataView
                break;
            case serviceCode.GOOGLE_AUTHEN:
                ComponentView = GoogleAuthenDataView
                break;
            case serviceCode.GOOGLE_SERVICE_ACCOUNT:
                ComponentView = GoogleServiceAccountDataView
                break;
            case serviceCode.O365:
                ComponentView = O365DataView
                break;
            case serviceCode.PROFILE_CENTER:
                ComponentView = ProfileCenterDataView
                break;
            case serviceCode.SEND_GRID:
                ComponentView = SendGridDataView
                break;
            case serviceCode.SMTP:
                ComponentView = SMTPDataView
                break;
            case serviceCode.LINE:
                ComponentView = LineDataView
                break;
            case serviceCode.SMS_8X8:
                ComponentView = SMS8X8DataView
                break;
            // case serviceCode.ZOOM:
            //     model = new ZoomDataModel()
            //     break;
            default:
                break;
        }
        return ComponentView
    }

    onTestSystemIntegration = (model) => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onTestSystemIntegration(model)
            let tempState = {
                isLoading: false,
                isTest: null
            }
            if (!!res && res.result === true) {
                tempState.isTest = true
            } else {
                tempState.isTest = false
            }
            this.setState(tempState)
        })
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformSystemIntegration(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, model } = this.props
            const { isLoading, isTest, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t("common.back"),
                    color: "grey",
                    onClick: this.onCancel
                })
            )

            let ComponentView = null
            if (!!model.service && !!model.data) {
                ComponentView = this.getDataIntegrationData(model.service)
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {
                        (isTest !== null && isTest !== undefined) &&
                        this.getModalContent(this.getInfoModalOption({
                            onClose: () => { },
                            headerText: t("systemIntegration.testConnection"),
                            size: "mini",
                            content: <div style={{ textAlign: "center" }}>
                                {
                                    isTest === true ?
                                        <h3 style={{ color: "#0f0" }}>{t("systemIntegration.connectionSuccess")}</h3>
                                        : <h3 style={{ color: "#f00" }}>{t("systemIntegration.connectionFail")}</h3>
                                }
                            </div>,
                            buttons: [
                                new ButtonModel({
                                    text: t('common.close'),
                                    color: "grey",
                                    onClick: () => this.setState({ isTest: null })
                                })
                            ]
                        }))
                    }
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('systemIntegration.viewSystemIntegration')} buttons={buttons}>
                        <Container text className="view-container">
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.code")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.name")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.description")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.service")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.service ? t(`constantTranslate.systemIntegration.${model.service}`) : '-'}</p></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        {
                                            (!!model.service && !!model.data && !!ComponentView) &&
                                            <React.Fragment>
                                                <br></br>
                                                <br></br>
                                                <ComponentView model={model.data} />
                                            </React.Fragment>
                                        }
                                        {
                                            !!model.service && !!model.data &&
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: `${t('systemIntegration.testConnection')}`,
                                                    color: "blue",
                                                    onClick: () => this.onTestSystemIntegration(model)
                                                })}
                                            />
                                        }
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SystemIntegrationView.defaultProps = {
    model: new SystemIntegrationModel()
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(SystemIntegrationView)