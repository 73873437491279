
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../../core/components/MyController'
import ViewSelectInformation from '../../../views/user/importExternalUser/ViewSelectInformation'

class SelectInformationController extends MyController {
    onBack = () => {
        this.props.onBack()
    }

    onNext = (model) => {
        this.props.onNext(model)
    }

    onCancel = () => {
        this.props.onCancel()
    }
    render() {
        try {
            const { dataConfig, dataStep } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <ViewSelectInformation
                        dataStep={dataStep}
                        resourceGroupList={dataConfig.resourceGroupList}
                        onBack={this.onBack}
                        onNext={this.onNext}
                        onCancel={this.onCancel}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

SelectInformationController.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {

        }
    })
)


export default enhance(SelectInformationController)