
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ScheduleControlManager from '../../business/ScheduleControlManager'
import ScheduleControlList from '../../views/scheduleControl/ScheduleControlList'
import ButtonModel from '../../core/model/ButtonModel'

class ScheduleControlController extends MyController {
	constructor(props) {
		super(props)
		
		this.state.isLoadingComponent = false
		this.bScheduleControl = new ScheduleControlManager(this)
	}

	getPropsChangeStatusSuccess() {
        return ["scheduleControlList"]
    }

	onReloadData = () => {
		this.props.getScheduleControlList()
	}

	onDeleteScheduleControl = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bScheduleControl.deleteScheduleControl(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
				, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") 
					{
                        action = callBack
                    }
				}
				this.setState(body, action)
			})
		})

	}

	onChangeEnableScheduleControl = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bScheduleControl.enableScheduleControlStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableScheduleControl = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bScheduleControl.disableScheduleControlStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getScheduleControlListPaging = (query) => {
		return this.bScheduleControl.getScheduleControlListPaging(query)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<ScheduleControlList
						onDeleteScheduleControl={this.onDeleteScheduleControl}
						onChangeEnableScheduleControl={this.onChangeEnableScheduleControl}
						onChangeDisableScheduleControl={this.onChangeDisableScheduleControl}
						onGetDataPaging={this.getScheduleControlListPaging}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
/* 	connect(
		(state, props) => ({
	
		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
			
		}
	}), */
)


export default enhance(ScheduleControlController) 