
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import GoogleServiceAccountDataModel from '../../../models/systemIntegrationData/GoogleServiceAccountDataModel'

class GoogleServiceAccountDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let typeValidate = this.getErrorInput("type", errors)
                , projectIdValidate = this.getErrorInput("projectId", errors)
                , privateKeyIdValidate = this.getErrorInput("privateKeyId", errors)
                , privateKeyValidate = this.getErrorInput("privateKey", errors)
                , clientEmailValidate = this.getErrorInput("clientEmail", errors)
                , clientIdValidate = this.getErrorInput("clientId", errors)
                , authUriValidate = this.getErrorInput("authUri", errors)
                , tokenUriValidate = this.getErrorInput("tokenUri", errors)
                , authProviderX509CertUrlValidate = this.getErrorInput("authProviderX509CertUrl", errors)
                , clientX509CertUrlValidate = this.getErrorInput("clientX509CertUrl", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.googleServiceAccount")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.googleServiceAccountType")} isRequire={true} />
                                <Form.Input
                                    name="type"
                                    value={model.type}
                                    onChange={this.handleChange}
                                    error={typeValidate.isError ? typeValidate.content : null}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.projectId")} isRequire={true} />
                                <Form.Input
                                    name="projectId"
                                    value={model.projectId}
                                    onChange={this.handleChange}
                                    error={projectIdValidate.isError ? projectIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.privateKeyId")} isRequire={true} />
                                <Form.Input
                                    name="privateKeyId"
                                    value={model.privateKeyId}
                                    onChange={this.handleChange}
                                    error={privateKeyIdValidate.isError ? privateKeyIdValidate.content : null}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.privateKey")} isRequire={true} />
                                <Form.TextArea
                                    name="privateKey"
                                    value={model.privateKey}
                                    onChange={this.handleChange}
                                    error={privateKeyValidate.isError ? privateKeyValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientEmail")} isRequire={true} />
                                <Form.Input
                                    name="clientEmail"
                                    value={model.clientEmail}
                                    onChange={this.handleChange}
                                    error={clientEmailValidate.isError ? clientEmailValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientId")} isRequire={true} />
                                <Form.Input
                                    name="clientId"
                                    value={model.clientId}
                                    onChange={this.handleChange}
                                    error={clientIdValidate.isError ? clientIdValidate.content : null}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authUri")} isRequire={true} />
                                <Form.Input
                                    name="authUri"
                                    value={model.authUri}
                                    onChange={this.handleChange}
                                    error={authUriValidate.isError ? authUriValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.tokenUri")} isRequire={true} />
                                <Form.Input
                                    name="tokenUri"
                                    value={model.tokenUri}
                                    onChange={this.handleChange}
                                    error={tokenUriValidate.isError ? tokenUriValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authProviderX509CertUrl")} isRequire={true} />
                                <Form.Input
                                    name="authProviderX509CertUrl"
                                    value={model.authProviderX509CertUrl}
                                    onChange={this.handleChange}
                                    error={authProviderX509CertUrlValidate.isError ? authProviderX509CertUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientX509CertUrl")} isRequire={true} />
                                <Form.TextArea
                                    name="clientX509CertUrl"
                                    value={model.clientX509CertUrl}
                                    onChange={this.handleChange}
                                    error={clientX509CertUrlValidate.isError ? clientX509CertUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

GoogleServiceAccountDataForm.defaultProps = {
    model: new GoogleServiceAccountDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(GoogleServiceAccountDataForm)