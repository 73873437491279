
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import VisitorModel from '../../models/VisitorModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchVisitor extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new VisitorModel()
        this.state.model.status = defaultValue.SELECT_ALL
        this.state.model.visitorGroupId = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new VisitorModel({ code: "", displayName: "", username: "", email: "", status: defaultValue.SELECT_ALL, visitorGroupId: defaultValue.SELECT_ALL, pinCode: "" })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    handleChangeVisitorGroup = (e, { name, value, checked }) => {
        const { optionVisitorGroupList } = this.props
        let model = this.state.model
            , tempState = {}
        if (name === "select") {
            let visitorGroupIds = model.visitorGroupIds
                , findIndex = visitorGroupIds.findIndex(id => id === value)
            if (findIndex === -1) {
                visitorGroupIds.push(value)
            } else {
                if (checked) {
                    visitorGroupIds.push(value)
                } else {
                    visitorGroupIds.splice(findIndex, 1)
                }
            }
            model.visitorGroupIds = visitorGroupIds
            tempState.model = model
        } else if (name === "selectAll") {
            let visitorGroupIds = []
            if (checked) {
                visitorGroupIds = (Array.isArray(optionVisitorGroupList) ? optionVisitorGroupList.map(rg => rg.value) : [])
            }
            model.visitorGroupIds = visitorGroupIds
            tempState.model = model
        }
        this.setState(tempState)
    }

    visitorGroupCheckBoxList = (optionVisitorGroup, visitorGroupIds) => {

        let component = null

        if (Array.isArray(optionVisitorGroup) && optionVisitorGroup.length > 0) {
            component = optionVisitorGroup.map((d, i) => {
                return <Form.Checkbox
                    key={i}
                    label={d.text}
                    name="select"
                    value={d.value}
                    onChange={this.handleChangeVisitorGroup}
                    checked={!!visitorGroupIds.find(id => id === d.value)}
                />
            })
        }

        return component
    }

    isSearch = (model) => {
        try {
            let isSearch = false
            if (!!model) {
                let status = ""
                if (!!model.status && model.status !== defaultValue.SELECT_ALL) {
                    status = model.status
                }

                if (!!model.visitorGroupId && model.visitorGroupId !== defaultValue.SELECT_ALL) {
                    model.visitorGroupIds[0] = model.visitorGroupId
                }
                if (!!status || model.visitorGroupIds.length > 0 || !!model.code || !!model.displayName || !!model.username || !!model.email || !!model.pinCode) {
                    isSearch = true
                }
            }
            return isSearch
        } catch (error) {
            return false
        }
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionVisitorStatusList, optionVisitorGroupList } = this.props
            //let isSearch = this.isSearch(model)
            return (
                <div className="module formSearchVisitor">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("common.code")} isRequire={false} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <LabelInput text={t("visitor.fullName")} isRequire={false} />
                                    <Form.Input
                                        name="displayName"
                                        value={model.displayName}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <LabelInput text={t("visitor.username")} isRequire={false} />
                                    <Form.Input
                                        name="username"
                                        value={model.username}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            {/* <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("common.email")} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>


                                <Grid.Column>
                                    <LabelInput text={t("visitor.status")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionVisitorStatusList}
                                        onChange={this.handleChange}
                                        name="status"
                                        value={model.status}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>


                                <Grid.Column>
                                    <LabelInput text={t("visitor.visitorGroups")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionVisitorGroupList}
                                        onChange={this.handleChange}
                                        name="visitorGroupIds"
                                        value={model.visitorGroupIds}
                                        placeholder={t("common.pleaseSelect")}
                                        multiple={true}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("visitor.pinCode")} isRequire={false} />
                                    <Form.Input
                                        name="pinCode"
                                        value={model.pinCode}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row> */}

                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("common.email")} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("visitor.pinCode")} isRequire={false} />
                                    <Form.Input
                                        name="pinCode"
                                        value={model.pinCode}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("visitorGroup.title")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionVisitorGroupList}
                                        onChange={this.handleChange}
                                        name="visitorGroupId"
                                        value={model.visitorGroupId}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("visitor.status")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionVisitorStatusList}
                                        onChange={this.handleChange}
                                        name="status"
                                        value={model.status}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                {/*                                <Grid.Column>
                                    <LabelInput text={t("visitor.visitorGroups")} isRequire={false} />
                                    <div className="module multipleCheckboxContainer">
                                        <div className="checkbox-wrapper search">
                                            {
                                                Array.isArray(optionVisitorGroupList) &&
                                                <React.Fragment>
                                                    <Form.Checkbox
                                                        label={t("common.selectAll")}
                                                        name={`selectAll`}
                                                        onChange={this.handleChangeVisitorGroup}
                                                        checked={optionVisitorGroupList.length === model.visitorGroupIds.length}
                                                    />
                                                    {this.visitorGroupCheckBoxList(optionVisitorGroupList, model.visitorGroupIds)}
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                     <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionVisitorGroupList}
                                        onChange={this.handleChange}
                                        name="visitorGroupIds"
                                        value={model.visitorGroupIds}
                                        placeholder={t("common.pleaseSelect")}
                                        multiple={true}
                                    /> 
                                </Grid.Column> */}



                            </Grid.Row>


                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            //disabled: !isSearch,
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form >
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchVisitor.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new VisitorModel(),
}

export default (translate(FormSearchVisitor))