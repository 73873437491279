
import types from '../types'
import { requestStatus } from '../../constant'
import VisitorGroupResource from '../selectors/visitorGroupResource'

const visitorGroupReducer = {
	visitorGroupInfo: (state = null, action) => {
		if (!state || !(state instanceof VisitorGroupResource)) {
			state = new VisitorGroupResource()
		}

		switch (action.type) {
			case types.GET_VISITOR_GROUP_INFO_REQUEST:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_VISITOR_GROUP_INFO_SUCCESS:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_VISITOR_GROUP_INFO_FAILURE:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	visitorGroupList: (state = null, action) => {
		if (!state || !(state instanceof VisitorGroupResource)) {
			state = new VisitorGroupResource()
		}

		switch (action.type) {
			case types.GET_VISITOR_GROUP_LIST_REQUEST:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_VISITOR_GROUP_LIST_SUCCESS:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_VISITOR_GROUP_LIST:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_VISITOR_GROUP_LIST_FAILURE:
				state = new VisitorGroupResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default visitorGroupReducer