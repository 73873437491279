
import types from '../types'
import { requestStatus } from '../../constant'
import NotificationResource from '../selectors/notificationResource'
import NotificationServiceResource from '../selectors/notificationServiceResource'
import NotificationSystemResource from '../selectors/notificationSystemResource'

const notificationReducer = {
	notificationInfo: (state = null, action) => {
		if (!state || !(state instanceof NotificationResource)) {
			state = new NotificationResource()
		}

		switch (action.type) {
			case types.GET_NOTIFICATION_INFO_REQUEST:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_NOTIFICATION_INFO_SUCCESS:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_NOTIFICATION_INFO_FAILURE:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	notificationList: (state = null, action) => {
		if (!state || !(state instanceof NotificationResource)) {
			state = new NotificationResource()
		}

		switch (action.type) {
			case types.GET_NOTIFICATION_LIST_REQUEST:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_NOTIFICATION_LIST_SUCCESS:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_NOTIFICATION_LIST:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_NOTIFICATION_LIST_FAILURE:
				state = new NotificationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	notificationServiceList: (state = null, action) => {
		if (!state || !(state instanceof NotificationServiceResource)) {
			state = new NotificationServiceResource()
		}

		switch (action.type) {
			case types.GET_NOTIFICATION_SERVICE_LIST_REQUEST:
				state = new NotificationServiceResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_NOTIFICATION_SERVICE_LIST_SUCCESS:
				state = new NotificationServiceResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_NOTIFICATION_SERVICE_LIST_FAILURE:
				state = new NotificationServiceResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	notificationSystemList: (state = null, action) => {
		if (!state || !(state instanceof NotificationSystemResource)) {
			state = new NotificationSystemResource()
		}

		switch (action.type) {
			case types.GET_NOTIFICATION_SYSTEM_LIST_REQUEST:
				state = new NotificationSystemResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_NOTIFICATION_SYSTEM_LIST_SUCCESS:
				state = new NotificationSystemResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_NOTIFICATION_SYSTEM_LIST_FAILURE:
				state = new NotificationSystemResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default notificationReducer