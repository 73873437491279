
import { CORE_SERVICE_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class ReportManager extends MyManager {

	async exportAQDailyReportExcel(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/dailyreportexcel`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async exportAQDailyReportPDF(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/dailyreportpdf`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async exportAQWeeklyReportExcel(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/weeklyreportexcel`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async exportAQWeeklyReportPDF(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/weeklyreportpdf`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async exportAQMonthlyReportExcel(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/monthlyreportexcel`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async exportAQMonthlyReportPDF(data) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/sensorrule/report/monthlyreportpdf`,
				responseType: 'arraybuffer',
				data: data
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

}