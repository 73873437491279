
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
//import { resourceStatus } from '../../constant'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const VisitorGroupSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

}, { _id: false })

const ResourceSchema = new Schema({
	visitorGroupIds: [{ type: String, default: null }],
	visitorGroupId: { type: String, default: "" },
	username: { type: String, default: null },
	password: { type: String, default: null },
	confirmPassword: { type: String, default: null },
	visitorGroups: [{ type: VisitorGroupSchema, default: null }],
	code: { type: String, required: [true, "validate.require"], default: null },
	givenName: { type: String, required: [true, "validate.require"], default: null },
	displayName: { type: String, default: null },
	sn: { type: String, default: null },
	email: { type: String, default: null },
	mobile: { type: String, default: null },
	pinCode: { type: String, default: null },
	rfId: { type: String, default: null },
	status: { type: String, default: null },
	statusStr: { type: String, default: null },
	integrationInfo: { type: Object, default: null },

	fileUpload: { type: FileSchema, default: null },
	id: { type: String, default: null },
	inviteInfo: { type: Object, default: null },
	proveOfApplicationInfo: { type: Object, default: null },
	expiryInfo: { type: Object, default: null }
}, { _id: false })


ResourceSchema.path("password").validate(async function (password) {
	if (!!this.id) {
		return true
	}
	return !!password
}, "validate.require")
ResourceSchema.path("confirmPassword").validate(async function (confirmPassword) {
	if (!!this.id) {
		return true
	}
	let isValid = true
	if (!!this.password) {
		isValid = (confirmPassword === this.password)
	}
	return isValid
}, "validate.invalid")

setBaseSchema(ResourceSchema, "77")

ResourceSchema.path("email").validate(async function (email) {
	var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

	let isValid = true
	if(email === null) {
		isValid = true
	} else if(email === "") {
		isValid = true
	}
	else if (!pattern.test(email)) 
	{
		isValid = false
	} 
	
	return isValid
}, "validate.wrongEmailFormat")

ResourceSchema.path("visitorGroupIds").validate(async function (visitorGroupIds) {
	return (Array.isArray(visitorGroupIds) && visitorGroupIds.length > 0)
}, "validate.require")

ResourceSchema.methods.serializeAPI = function () {
	try {
		let data = {
			visitorGroupIds: this.visitorGroupIds.map(id => id),
			username: this.username,
			password: this.password,
			code: this.code,
			givenName: this.givenName,
			sn: this.sn,
			displayName: this.displayName,
			email: this.email,
			mobile: this.mobile,
			pinCode: this.pinCode,
			rfId: this.rfId
		}
		if (!!this.fileUpload) {
			data.fileUpload = this.fileUpload
		}
		return data

	} catch (error) {
		console.log(error)
		return null
	}
}


const VisitorModel = mongoose.model("resource", ResourceSchema, "resource")

export default VisitorModel