
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getIOTConfigInfo } from '../../actions/iotConfigActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import IOTConfigManager from '../../business/IOTConfigManager'
import IOTConfigView from '../../views/iotConfig/IOTConfigView'

class ViewIOTConfigController extends MyController {
	constructor(props) {
		super(props)

		this.bIOTConfig = new IOTConfigManager(this)
		this.state.isLoadingComponent = false
	}

	getPropsResetStatus() {
		return ["iotConfigInfo"]
	}

	onDeleteAreaIOTConfig = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bIOTConfig.deleteAreaIOTConfig(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getIOTConfigInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaIOTConfig = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bIOTConfig.upsertAreaIOTConfig(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getIOTConfigInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationIOTConfig = (model, serviceInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bIOTConfig.unintegrateIOTConfig(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getIOTConfigInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}


	render() {
		try {
			const { iotConfigInfo, locationList, isLoadingComponent } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const iotConfig = iotConfigInfo.getData()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let locationInfo = null

			if (!!iotConfig) {
				if (!!iotConfig.locationId) {
					locationInfo = locationList.findDataByKey("id", iotConfig.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<IOTConfigView
							model={iotConfig}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaIOTConfig={this.onDeleteAreaIOTConfig}
							onUpsertAreaIOTConfig={this.onUpsertAreaIOTConfig}
							onUnintegtationIOTConfig={this.onUnintegtationIOTConfig}
							locationList={locationDatas}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			iotConfigInfo: state.iotConfigInfo,
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getIOTConfigInfo() {
				dispatch(getIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getIOTConfigInfo()
		}
	}),
)


export default enhance(ViewIOTConfigController)