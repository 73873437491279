
import ActionResourceObject from '../core/redux/ActionResourceObject'
import EventControlRuleModel from '../models/EventControlRuleModel'

export default class EventControlRuleResource extends ActionResourceObject {
    setResource(data) {
        try {
            if (!data) {
                throw new Error("Data not found")
            }

            let result = null
            if (Array.isArray(data)) {
				result = data.map(d => new EventControlRuleModel(d))
			} else {
				result = new EventControlRuleModel(data)
			}

            return result
        }
        catch (error) {
            return null
        }
    }

    setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.name
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.name
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}