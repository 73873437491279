
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Label } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import ModalCardData from './ModalCardData'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ViewTable from '../../core/view/common/table/ViewTable'

class RFIDCardForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.openCardDataModal = false
        this.state.cardDataModal = null
        this.state.cardDataIndex = null
        this.state.cardDataModalStatus = null
    }

    onOpenModelCardData = (status, model, index) => {
        this.setState({
            openCardDataModal: true,
            cardDataModal: model,
            cardDataModalStatus: status,
            cardDataIndex: index
        })
    }

    onCloseModelCardData = () => {
        this.setState({
            openCardDataModal: false
        })
    }

    onSubmitModelCardData = (cardDataInfo, cardDataList) => {
        const { cardDataModalStatus, cardDataIndex } = this.state
        let currentCardDataList = []

        if (cardDataModalStatus === "CREATE") {
            currentCardDataList = cardDataList.map(cardData => cardData)
            currentCardDataList.push(cardDataInfo)
        } else if (cardDataModalStatus === "UPDATE") {
            currentCardDataList = cardDataList.map(cardData => cardData)
            currentCardDataList[cardDataIndex] = cardDataInfo
        }

        this.setState({
            openCardDataModal: false
        }, () => {
            this.handleChange(null, { name: "datas", value: currentCardDataList })
        })
    }

    onRemoveModelCardData = (index) => {
        const { model } = this.props

        if (Array.isArray(model.datas) && model.datas.length > 0) {
            let currentCardDataList = model.datas.map(t => t)

            if (index > -1) {
                currentCardDataList.splice(index, 1)
            }
            this.props.handleChange(null, { name: "datas", value: currentCardDataList })
        }
    }


    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.type ? row.type : '-',
                sortable: false,
                maxWidth: '250px',
            },
            {
                name: t("rfidCard.value"),
                selector: (row) => row.value ? row.value : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModelCardData("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelCardData(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    render() {
        try {
            const { t, model, optionsRFIDCardType, optionsRFIDCardDataType, errors } = this.props
            const { openCardDataModal, cardDataModal } = this.state

            let cardNoValidate = this.getErrorInput("cardNo", errors)
                , typeValidate = this.getErrorInput("type", errors)
                , cardDatasValidate = this.getErrorInput("datas", errors)

            let cardDatasList = Array.isArray(model.datas) && model.datas.length > 0 ? model.datas : []

            return (
                <React.Fragment>
                    {super.render()}
                    {openCardDataModal &&
                        <ModalCardData
                            model={cardDataModal}
                            optionsRFIDCardDataType={optionsRFIDCardDataType}
                            onSubmit={(cardDataInfo) => this.onSubmitModelCardData(cardDataInfo, cardDatasList)}
                            onClose={this.onCloseModelCardData}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('rfidCard.cardNo')} isRequire={true} />
                                    <Form.Input
                                        name="cardNo"
                                        value={model.cardNo}
                                        onChange={this.handleChange}
                                        error={cardNoValidate.isError ? cardNoValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <LabelInput text={t("common.type")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsRFIDCardType}
                                        onChange={this.handleChange}
                                        name="type"
                                        value={model.type}
                                        placeholder={t("common.pleaseSelect")}
                                        error={typeValidate.isError ? typeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("rfidCard.cardData")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => { this.onOpenModelCardData("CREATE", null, null) },
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnConfig()}
                                        datas={model.datas}
                                        pagination={false}
                                        isShowPagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (cardDatasValidate.isError &&
                                    cardDatasValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {cardDatasValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

RFIDCardForm.defaultProps = {
    model: null,
    errors: [],
    optionsRFIDCardType: [],
    optionsRFIDCardDataType: [],
    handleChange: () => console.error("Please provide \"Change RFIDCard\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(RFIDCardForm)