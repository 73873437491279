
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import ButtonModel from '../../../core/model/ButtonModel'
import ActionModel from '../../../models/sensorRuleData/sensorRuleStep/SensorRuleActionModel'
import LabelInput from '../../common/LabelInput'
import ViewButton from '../../common/ViewButton'
import ViewTable from '../../../core/view/common/table/ViewTable'
import ModalCreateSensorRuleActionController from '../../../controllers/sensorRuleStep/ModalCreateSensorRuleActionController'
import { sensorRuleActionType } from '../../../../constant'

class ActionInformationForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.isOpenModalAction = false
        this.state.modelAction = null
        this.state.actionIndex = null
        this.state.actionStatus = null


        // this.state.modelErrors = []
        this.state.isOpenModalAction = false
        this.state.modelUpdate = null
        this.state.indexUpdate = -1
    }

    onOpenModelAction = (status, model, index) => {
        this.setState({
            isOpenModalAction: true,
            modelAction: model,
            actionStatus: status,
            actionIndex: index
        })
    }
    onCloseModelAction = () => {
        this.setState({
            isOpenModalAction: false
        })
    }
    onAddAction = (action) => {
        const { actionStatus, actionIndex } = this.state

        let actions = Array.isArray(this.props.models.actions) ? this.props.models.actions : []

        if (!!action) {
            if (actionStatus === "CREATE") {
                actions.push(action)
                this.setState({
                    isOpenModalAction: false
                }, () => this.handleChange(null, { name: "models", value: actions }))
            } else if (actionStatus === "UPDATE") {
                actions[actionIndex] = action
                this.setState({
                    isOpenModalAction: false
                }, () => this.handleChange(null, { name: "models", value: actions }))
            }

        }
    }
    onRemoveAction = (actions, index) => {
        /* let index = actions.indexOf(model) */
        let currentActions = actions.map(t => t)
        if (index > -1) {
            currentActions.splice(index, 1)
        }
        this.handleChange(null, { name: "models", value: currentActions })
    }




    openModalCreate = () => {
        this.setState({
            isOpenModalAction: true,
            modelUpdate: null
        })
    }

    openModalUpdate = (model, index) => {
        this.setState({
            isOpenModalAction: true,
            modelUpdate: new ActionModel(model),
            indexUpdate: index,
        })
    }

    closeModal = () => {
        this.setState({
            isOpenModalAction: false,
            modelUpdate: null,
            indexUpdate: -1
        })
    }

    onSubmitModal = (tempModel) => {
        const { models } = this.props
        const { modelUpdate } = this.state
        let tempModels = models.map(s => s)
        if (!!modelUpdate) {
            let index = tempModels.findIndex(s => s.id === modelUpdate.id)
            if (index > -1) {
                tempModels[index] = tempModel
            }
        } else {
            tempModels.push(tempModel)
        }
        this.setState({
            isOpenModalAction: false,
            modelUpdate: null,
            indexUpdate: -1
        }, () => this.handleChange(null, { name: "models", value: tempModels }))
    }

    onDelete = (index) => {
        const { models } = this.props
        let tempModels = models.map(s => s)
        if (index > -1) {
            tempModels.splice(index, 1)
            this.handleChange(null, { name: "models", value: tempModels })
        }
    }

    getColumnConfigAction(actions) {
        const { t } = this.props

        const columns = [
            {
                name: t("sensorRule.actionType"),
                selector: (row) => row.type ? row.type : '-',
                width: '200px',
            },
            {
                name: t("sensorRule.actionResult"),
                selector: (row) => row.result ? row.result : '-',
                width: '150px'
            },
            {
                name: t("sensorRule.detail"),
                selector: (row) => {
                    let detail = '-'
                    if (row.type === sensorRuleActionType.ALERT_DASHBOARD) {
                        if (row.data) {
                            if (row.data.color) {
                                // detail = `R:${row.data.color.r} G:${row.data.color.g} B:${row.data.color.b}`
                                return <div style={{
                                    borderStyle: "solid",
                                    borderWidth: `1px`,
                                    width: "2em",
                                    height: "1em",
                                    backgroundColor: `rgba(${row.data.color.r}, ${row.data.color.g}, ${row.data.color.b}, 1)`
                                }}>
                                </div>
                            }
                        }
                    } else if (row.type === sensorRuleActionType.ALERT) {
                        if (row.data) {
                            detail = row.data.notificationName
                        }
                    } else if (row.type === sensorRuleActionType.CONTROL) {
                        if (row.data) {
                            detail = `${row.data.actionMode} ${row.data.type} ${row.data.name}`
                        }

                    } else if (row.type === sensorRuleActionType.DATABASE) {
                        if (row.data) {
                            detail = `${row.data.sensorType} ${row.data.collectionName}`
                        }
                    }

                    return detail
                }
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModelAction("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            iconClass: "trash",
                            size: "mini",
                            onClick: () => this.onRemoveAction(actions, i)
                        }
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }


    render() {
        try {
            const { t, models, optionsSensorRuleActionType, optionsSensorRuleActionResult, optionsSensorRuleLocationType, optionsNotification, optionsDataType, optionsSensorType, sensorRuleResourceList, locationList, sensorRuleActionResult, chosenType } = this.props
            const { isOpenModalAction, modelAction } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalAction &&
                        <ModalCreateSensorRuleActionController
                            model={modelAction}
                            chosenType={chosenType}
                            sensorRuleActionResult={sensorRuleActionResult}
                            optionsSensorRuleActionType={optionsSensorRuleActionType}
                            optionsSensorRuleActionResult={optionsSensorRuleActionResult}
                            optionsSensorRuleLocationType={optionsSensorRuleLocationType}
                            optionsNotification={optionsNotification}
                            optionsDataType={optionsDataType}
                            optionsSensorType={optionsSensorType}
                            sensorRuleResourceList={sensorRuleResourceList}
                            locationList={locationList}
                            onClose={this.onCloseModelAction}
                            onAddAction={this.onAddAction}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("sensorRule.action")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => this.onOpenModelAction("CREATE", null, null),
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnConfigAction()}
                                        datas={models}
                                        pagination={false}
                                        isShowPagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ActionInformationForm.defaultProps = {
    models: [],
    optionsSensorRuleActionType: [], 
    optionsSensorRuleActionResult: [], 
    optionsSensorRuleLocationType: [], 
    optionsNotification: [], 
    optionsDataType: [], 
    optionsSensorType: [], 
    sensorRuleResourceList: [], 
    locationList: [], 
    sensorRuleActionResult: [],
    chosenType: "",
}

export default (translate(ActionInformationForm))