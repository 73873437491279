
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form, Label, Segment } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import utils from '../../core/utility/utils'
import ViewNoResult from './ViewNoResult'

class MultipleCheckboxList extends MyForm {
    constructor(props) {
        super(props)

        this.state = {
            optionsDataList: props.optionsDataList,
            selectedDataList: Array.isArray(props.value) ? props.value : []
        }

        this.selfHandleForm = true
    }

    componentDidUpdate(prevProps) {
        const { value, optionsDataList } = this.props

        if (prevProps.value !== value) {
            this.setState({
                selectedDataList: Array.isArray(value) ? value : []
            })
        } else if (Array.isArray(optionsDataList) && Array.isArray(prevProps.optionsDataList)) {
            if (!!optionsDataList[0] && !!prevProps.optionsDataList[0] && prevProps.optionsDataList[0].text !== optionsDataList[0].text) {
                this.setState({
                    optionsDataList: optionsDataList
                })
            } else if (optionsDataList.length !== 0 && prevProps.optionsDataList.length === 0) {
                this.setState({
                    optionsDataList: optionsDataList
                })
            } else if (optionsDataList.length === 0 && prevProps.optionsDataList.length !== 0) {
                this.setState({
                    optionsDataList: []
                })
            }
        }
    }

    handleChangeDataList = (e, { name, value, checked }) => {
        const { optionsDataList } = this.state

        let selectedDataListTemp = this.state.selectedDataList

        if (name === "select") {
            if (checked) {
                if (!optionsDataList.includes(value) && selectedDataListTemp.includes(value)) {
                    return
                }

                selectedDataListTemp.push(value)
            } else {
                selectedDataListTemp = utils.arrayDiff(selectedDataListTemp, [value])
            }
        } else if (name === "selectAll") {
            if (checked) {
                selectedDataListTemp = optionsDataList.map(dataList => {
                    return dataList.value
                })
            } else {
                selectedDataListTemp = []
            }
        }

        this.setState({
            selectedDataList: selectedDataListTemp
        })

        this.props.onChange(null, { name: this.props.name, value: selectedDataListTemp })
    }

    multipleCheckBoxList = (optionsDataList) => {
        const { selectedDataList } = this.state

        let component = null

        if (!!optionsDataList && Array.isArray(optionsDataList) && optionsDataList.length > 0) {
            component = optionsDataList.map((data, i) => {
                return <Form.Checkbox
                    label={data.text}
                    name="select"
                    value={data.value}
                    onChange={this.handleChangeDataList}
                    checked={selectedDataList.includes(data.value)}
                />
            })
        }

        return component
    }

    render() {
        try {
            const { optionsDataList, selectedDataList, isLoadingSegment } = this.state
            const { t, error } = this.props

            return (
                <React.Fragment>
                    {
                        Array.isArray(optionsDataList) && optionsDataList.length > 0 ?
                            <div className="module multipleCheckboxContainer">
                                <Segment
                                    loading={isLoadingSegment}
                                    className="group-segment"
                                >
                                    <div className="checkbox-wrapper">
                                        <Form.Checkbox
                                            label={t("common.selectAll")}
                                            name={`selectAll`}
                                            onChange={this.handleChangeDataList}
                                            checked={optionsDataList.length === selectedDataList.length}
                                        />
                                        {this.multipleCheckBoxList(optionsDataList)}
                                    </div>
                                </Segment>
                            </div>
                            : <div className="module multipleCheckboxContainer">
                                <Segment
                                    loading={isLoadingSegment}
                                    className="group-segment"
                                >
                                    <div className="checkbox-wrapper">
                                        <ViewNoResult message={t("common.noData")} />
                                    </div>
                                </Segment>
                            </div>
                    }
                    {
                        (error.isError &&
                            error.content) &&
                        <Form.Field>
                            <Label pointing prompt>
                                {error.content}
                            </Label>
                        </Form.Field>
                    }
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

MultipleCheckboxList.defaultProps = {
    name: "",
    optionsDataList: [],
    value: [],
    error: [],
    isLoadingSegment: false,
    onChange: () => console.error("Please provide \"Change Sensor\" action.")
}

const enhance = compose(
    translate
)

export default enhance(MultipleCheckboxList)