
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getHolidayInfo } from '../../actions/holidayActions'
import HolidayManager from '../../business/HolidayManager'
import utils from '../../core/utility/utils'
import HolidayUpdate from '../../views/holiday/HolidayUpdate'

class UpdateHolidayController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bHoliday = new HolidayManager(this)
	}

	getPropsResetStatus() {
		return ["holidayInfo"]
	}

	onUpdateHoliday = async (model) => {
		this.setState({
			isLoading: true
		}, () => {
			this.bHoliday.updateHoliday(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoading: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/holiday/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { t, holidayInfo } = this.props
			const { isLoading } = this.state

			let currentYear = parseInt(moment().format("YYYY"))

			const optionsYearRaw = [
				currentYear,
				currentYear + 1,
				currentYear + 2,
				currentYear + 3,
				currentYear + 4,
				currentYear + 5,
				currentYear + 6,
				currentYear + 7,
				currentYear + 8,
				currentYear + 9,
				currentYear + 10
			]

			const optionsYear = optionsYearRaw.map((d, i) => {
				return {
					key: "" + d,
					value: "" + d,
					text: "" + d
				}
			})

			const optionsWeekday = [
				{
					key: "MON",
					value: "MON",
					text: t(`constantTranslate.dayOfWeek.MON`),
				},
				{
					key: "TUE",
					value: "TUE",
					text: t(`constantTranslate.dayOfWeek.TUE`),
				},
				{
					key: "WED",
					value: "WED",
					text: t(`constantTranslate.dayOfWeek.WED`),
				},
				{
					key: "THU",
					value: "THU",
					text: t(`constantTranslate.dayOfWeek.THU`),
				},
				{
					key: "FRI",
					value: "FRI",
					text: t(`constantTranslate.dayOfWeek.FRI`),
				},
				{
					key: "SAT",
					value: "SAT",
					text: t(`constantTranslate.dayOfWeek.SAT`),
				},
				{
					key: "SUN",
					value: "SUN",
					text: t(`constantTranslate.dayOfWeek.SUN`),
				}
			]

			const optionsDateType = [
				{
					key: "ALL_YEAR",
					value: "ALL_YEAR",
					text: t(`constantTranslate.holidayDateType.ALL_YEAR`)
				},
				{
					key: "RANGE",
					value: "RANGE",
					text: t(`constantTranslate.holidayDateType.RANGE`)
				},
				{
					key: "DAY",
					value: "DAY",
					text: t(`constantTranslate.holidayDateType.DAY`)
				},

			]

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const holiday = holidayInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<HolidayUpdate
						isLoadingControl={isLoading}
						model={holiday}
						optionsYear={optionsYear}
						optionsWeekday={optionsWeekday}
						optionsDateType={optionsDateType}
						onUpdate={this.onUpdateHoliday}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			holidayInfo: state.holidayInfo
		}),
		(dispatch, props) => ({
			getHolidayInfo() {
				dispatch(getHolidayInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getHolidayInfo()
		}
	}),
)


export default enhance(UpdateHolidayController)