
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import { Container, Grid } from 'semantic-ui-react'
import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchLocation from './FormSearchLocation'
import LocationModel from '../../models/LocationModel'
import { cookiesKey, defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class ViewLocation extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.isLoadingTable = false
        this.state.modelSearch = new LocationModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null
        this.state.optionParentList = Array.isArray(props.optionLocationList) && props.optionLocationList.length > 0 ? props.optionLocationList : []
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (Array.isArray(nextProps.optionLocationList) && nextProps.optionLocationList.length > 0 && prevState.optionParentList.length === 0) {
            tempState.optionParentList = nextProps.optionLocationList
        }
        return tempState
    }

    onClickDelete = (model) => {
        const { t } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    this.props.onDelete(model, () => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("locationType.title"),
                selector: (row) => row.locationType ? row.locationType.name : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.parent"),
                selector: (row) => row.parent ? row.parent.name : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                wrap: true,
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/location/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/location/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        },
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div >
                },
                right: true
            },
        ]

        return columns
    }

    //Add 
    onClickAdd = () => {
        this.props.history.push(`/location/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    /*    onSearch = (model) => {
           this.setState({
               modelSearch: model
           }, () => {
               if (!!model) {
                   let parentId = ""
                   if (!!model.parentId && model.parentId !== defaultValue.SELECT_ALL) {
                       parentId = model.parentId
                   }
   
                   let locationTypeId = ""
                   if (!!model.locationTypeId && model.locationTypeId !== defaultValue.SELECT_ALL) {
                       locationTypeId = model.locationTypeId
                   }
   
                   let querys = [
                       {
                           name: "code",
                           value: model.code,
                           queryType: searchQueryType.LIKE
                       },
                       {
                           name: "name",
                           value: model.name,
                           queryType: searchQueryType.LIKE
                       },
                       {
                           name: "description",
                           value: model.description,
                           queryType: searchQueryType.LIKE
                       },
                       {
                           name: "parentId",
                           value: parentId,
                       },
                       {
                           name: "locationTypeId",
                           value: locationTypeId,
                       }
                   ]
   
                   this.props.onReloadData(utils.generateSearchQuery(querys))
               } else {
                   this.props.onReloadData("")
               }
           })
   
       } */

    onClickSyncAll = () => {
        const { t, onSyncLocation } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.syncAllData"),
                    content: t("message.confirmSyncAll"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onSyncLocation(() => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }


    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let parentId = ""
                if (!!model.parentId && model.parentId !== defaultValue.SELECT_ALL) {
                    parentId = model.parentId
                }

                let locationTypeId = ""
                if (!!model.locationTypeId && model.locationTypeId !== defaultValue.SELECT_ALL) {
                    locationTypeId = model.locationTypeId
                }

                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "name",
                        value: model.name,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "parentId",
                        value: parentId,
                    },
                    {
                        name: "locationTypeId",
                        value: locationTypeId,
                    }
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }


    render() {
        try {
            const { t, optionLocationTypeList } = this.props
            const { isSearch, isLoading, model, optionParentList, pagingDataModel, isLoadingTable } = this.state

            let tempOptionParentList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            tempOptionParentList = tempOptionParentList.concat(optionParentList)

            let tempOptionLocationTypeList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            tempOptionLocationTypeList = tempOptionLocationTypeList.concat(optionLocationTypeList)

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {!!model && this.getContentModal(model)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('location.title')}>
                        <div className="module viewLocation">
                            <Container>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign="left" verticalAlign="bottom">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: () => this.onClickAdd()
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                            {
                                                this.bCookie.getData(cookiesKey.IS_ENABLE_PLATFORM_SERVICE) === true &&
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t('location.syncLocation'),
                                                        color: "yellow",
                                                        onClick: this.onClickSyncAll
                                                    })}
                                                />
                                            }
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch} >
                                                <FormSearchLocation
                                                    onSearch={this.onSearchByForm}
                                                    optionParentList={tempOptionParentList}
                                                    optionLocationTypeList={tempOptionLocationTypeList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewLocation.defaultProps = {
    optionLocationTypeList: [],
    optionLocationList: [],
    onDelete: () => console.error("Please provide Delete action."),
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    },
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewLocation)