
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import ControlDataModel from '../../../models/sensorRuleData/actionData/ControlDataModel'
import { sensorRuleLocationType } from '../../../../constant'

class ControlDataForm extends MyForm {
    getOptionResource = (sensorRuleResourceList) => {
        try {
            let options = []
            if (Array.isArray(sensorRuleResourceList) && sensorRuleResourceList.length > 0) {
                options = sensorRuleResourceList.map(data => {
                    return {
                        key: data.id,
                        value: data.id,
                        text: data.displayName
                    }
                })
            }
            return options
        } catch (error) {
            return []
        }
    }
    getOptionLocation = (locationList) => {
        try {
            let options = []
            if (Array.isArray(locationList) && locationList.length > 0) {
                options = locationList.map(data => {
                    return {
                        key: data.id,
                        value: data.id,
                        text: data.name
                    }
                })
            }
            return options
        } catch (error) {
            return []
        }
    }
    getOptionScene = (sceneList) => {
        try {
            let options = []
            if (Array.isArray(sceneList) && sceneList.length > 0) {
                options = sceneList.map(data => {
                    return {
                        key: data.id,
                        value: data.id,
                        text: data.name
                    }
                })
            }
            return options
        } catch (error) {
            return []
        }
    }

    // handleChange = (e, { name, value }) => {
    //     try {
    //         if (name === "id") {
    //             let ModelConditionData = this.bSensorRule.getModelSensorRuleCondition(value)
    //             let data = {
    //                 [name]: value,
    //                 sceneId: "",
    //             }
    //             if (ModelConditionData) {
    //                 data.conditionData = new ModelConditionData()
    //             }
    //             this.props.handleChangeMultiValue(e, data)
    //         } else {
    //             this.props.handleChange(e, { name, value })
    //         }
    //     } catch (error) {
    //         console.error("Handle Change Error: " + error.message)
    //     }
    // }

    handleChangeId = (e, { name, value }, type) => {
        const { locationList, sensorRuleResourceList } = this.props
        if (name === "id") {
            let action = () => { }
            let dataLocation = {
                code: "",
                name: "",
            }
            if (type === sensorRuleLocationType.LOCATION) {
                let data = locationList.find(l => l.id === value)
                if (!!data) {
                    dataLocation.code = data.code
                    dataLocation.name = data.name
                }
                action = () => this.props.getScene(type, value)
            } else if (type === sensorRuleLocationType.RESOURCE) {
                let data = sensorRuleResourceList.find(r => r.id === value)
                if (!!data) {
                    dataLocation.code = data.code
                    dataLocation.name = data.givenName
                }
                action = () => this.props.getScene(data.resourceTypeCode, value)
            }
            let data = {
                [name]: value,
                ...dataLocation,
                sceneId: "",
            }
            this.props.handleChangeMultiValue(e, data)
            action()
        }
    }
    render() {
        try {
            const { t, model, errors, optionsSensorRuleLocationType, sensorRuleResourceList, locationList, sceneList } = this.props
            let typeValidate = this.getErrorInput("type", errors)
                , idValidate = this.getErrorInput("id", errors)
                , actionModeValidate = this.getErrorInput("actionMode", errors)
                , sceneIdValidate = this.getErrorInput("sceneId", errors)

            let optionsId = []
                , textHeaderResource = `${t("sensorRule.controlLocation")}/${t("sensorRule.controlResource")}`
            if (model.type === sensorRuleLocationType.LOCATION) {
                optionsId = this.getOptionLocation(locationList)
                textHeaderResource = t("sensorRule.controlLocation")
            } else if (model.type === sensorRuleLocationType.RESOURCE) {
                optionsId = this.getOptionResource(sensorRuleResourceList)
                textHeaderResource = t("sensorRule.controlResource")
            }

            let optionsScene = this.getOptionScene(sceneList)
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.controlType")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSensorRuleLocationType}
                                        onChange={this.handleChange}
                                        name="type"
                                        value={model.type}
                                        placeholder={t("common.pleaseSelect")}
                                        error={typeValidate.isError ? typeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={`${textHeaderResource}`} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsId}
                                        onChange={(e, d) => this.handleChangeId(e, d, model.type)}
                                        name="id"
                                        value={model.id}
                                        placeholder={t("common.pleaseSelect")}
                                        error={idValidate.isError ? idValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.controlActionMode")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={[
                                            {
                                                key: "ON",
                                                value: "ON",
                                                text: "ON"
                                            },
                                            {
                                                key: "OFF",
                                                value: "OFF",
                                                text: "OFF"
                                            }
                                        ]}
                                        onChange={this.handleChange}
                                        name="actionMode"
                                        value={model.actionMode}
                                        placeholder={t("common.pleaseSelect")}
                                        error={actionModeValidate.isError ? actionModeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                model.actionMode === "ON" &&
                                <React.Fragment>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("sensorRule.controlScene")} isRequire={true} />
                                            <Form.Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                options={optionsScene}
                                                onChange={this.handleChange}
                                                name="sceneId"
                                                value={model.sceneId}
                                                placeholder={t("common.pleaseSelect")}
                                                error={sceneIdValidate.isError ? sceneIdValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row></Grid.Row>
                                </React.Fragment>
                            }
                        </Grid>
                        <br></br>
                        <br></br>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ControlDataForm.defaultProps = {
    model: new ControlDataModel(),
    errors: [],
    optionsSensorRuleLocationType: [],
    sensorRuleResourceList: [],
    locationList: [],
    sceneList: [],
    handleChange: () => console.error("Please provide Handle Change action."),
    getScene: () => console.error("Please provide \"Get Scene\" action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ControlDataForm)