
import React from 'react'
import moment from 'moment'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Table, Header, Grid, Segment, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import SensorRuleModel from '../../models/SensorRuleModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'

import ViewNoResult from '../common/ViewNoResult'
import ViewButton from '../common/ViewButton'
import { sensorRuleType, sensorRuleActionType, dateTimeFormat } from '../../../constant'

class SensorRuleView extends MyPage {

    onCreate = () => {
        this.props.history.push(`/sensorrule/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/sensorrule/update/${model.id}`)
    }

    onCancel = () => {
        this.props.history.replace("/sensorrule")
    }

    getColumnConfigAction() {
        const { t } = this.props

        const columns = [
            {
                name: t("sensorRule.actionType"),
                selector: (row) => row.type ? row.type : '-',
                width: '200px',
            },
            {
                name: t("sensorRule.actionResult"),
                selector: (row) => row.result ? row.result : '-',
                width: '200px',
            },
            {
                name: t("sensorRule.detail"),
                selector: (row) => {
                    let detail = '-'
                    if (row.data) {
                        detail = row.data.detail

                        if (row.type === sensorRuleActionType.ALERT_DASHBOARD) {
                            let colorDetail = JSON.parse(row.data.detail)

                            if (colorDetail) {
                                return <div style={{
                                    borderStyle: "solid",
                                    borderWidth: `1px`,
                                    width: "2em",
                                    height: "1em",
                                    backgroundColor: `rgba(${colorDetail.r}, ${colorDetail.g}, ${colorDetail.b}, 1)`
                                }}>
                                </div>
                            }

                        }
                    }

                    return detail
                },
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = [
                        // {
                        //     text: "",
                        //     title: t("common.delete"),
                        //     color: "red",
                        //     iconClass: "trash",
                        //     size: "mini"
                        // }
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    getColumnConfigTimeSlot() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.time"),
                selector: (row) => row.startTime && row.endTime ? row.startTime + " - " + row.endTime : '-',
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = [
                        // {
                        //     text: "",
                        //     title: t("common.delete"),
                        //     color: "red",
                        //     iconClass: "trash",
                        //     size: "mini"
                        // }
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    getColumnIncludeExcludeDaysConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.date"),
                selector: (row) => {
                    if (!!row.d) {
                        return moment(row.d).format(dateTimeFormat.apiFullDate)
                    }

                    return '-'
                },
                sortable: false
            },
        ]
        return columns
    }

    render() {
        try {
            const { t, model } = this.props
            const { isLoading } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t("common.back"),
                    color: "grey",
                    onClick: this.onCancel
                })
            )

            let recurrenceType = "-"
                , recurrenceDayOfWeek = []
                , recurrenceStartDateEnable = false
                , recurrenceStartDate = "-"
                , recurrenceEndDateEnable = false
                , recurrenceEndDate = "-"
                , conditionDataIncludeDays = []
                , conditionDataExcludeDays = []

            if (!!model) {
                if (!!model.conditionData) {
                    if (!!model.conditionData.recurrence) {
                        if (!!model.conditionData.recurrence.type) {
                            recurrenceType = model.conditionData.recurrence.type
                        }

                        if (!!model.conditionData.recurrence.weeklyEveryDayOfWeek) {
                            recurrenceDayOfWeek = model.conditionData.recurrence.weeklyEveryDayOfWeek
                        }

                        if (!!model.conditionData.recurrence.startDate) {
                            recurrenceStartDateEnable = model.conditionData.recurrence.startDate.isEnable
                            recurrenceStartDate = moment(model.conditionData.recurrence.startDate.date).format(dateTimeFormat.apiFullDate)
                        }

                        if (!!model.conditionData.recurrence.endDate) {
                            recurrenceEndDateEnable = model.conditionData.recurrence.endDate.isEnable
                            recurrenceEndDate = moment(model.conditionData.recurrence.endDate.date).format(dateTimeFormat.apiFullDate)
                        }

                    }

                    if (Array.isArray(model.conditionData.includeDays) && model.conditionData.includeDays.length > 0) {
                        conditionDataIncludeDays = model.conditionData.includeDays
                    }

                    if (Array.isArray(model.conditionData.excludeDays) && model.conditionData.excludeDays.length > 0) {
                        conditionDataExcludeDays = model.conditionData.excludeDays
                    }
                }
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('sensorRule.viewSensorRule')} buttons={buttons}>
                        <Container text className="view-container">
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("sensorRule.sensorRuleType")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.type ? t(`constantTranslate.sensorRuleType.${model.type}`) : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.code")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.name")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.description")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("sensorRule.sourceSensor")} /></Table.Cell>
                                                    <Table.Cell>
                                                        <div className="minThree">
                                                            {
                                                                Array.isArray(model.sourceSensors) && model.sourceSensors.length > 0 ?
                                                                    model.sourceSensors.map((sensor, i) => {
                                                                        if (!!sensor.description) {
                                                                            return <p key={i}>• {sensor.description}</p>
                                                                        } else {
                                                                            return <p key={i}>• {sensor.sensorModel}</p>
                                                                        }

                                                                    })
                                                                    : '-'
                                                            }
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <br></br>
                                        <br></br>
                                        <Header as='h1' dividing className="form header view-header-button">
                                            {t("sensorRule.condition")}
                                        </Header>
                                        {
                                            !!model.conditionData ?
                                                <React.Fragment>
                                                    <Table striped celled>
                                                        <Table.Body>
                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t("common.timeSlot")} /></Table.Cell>
                                                                <Table.Cell>
                                                                    <div className="minThree">
                                                                        {
                                                                            Array.isArray(model.conditionData.timeSlots) && model.conditionData.timeSlots.length > 0 ?
                                                                                model.conditionData.timeSlots.map((timeSlots, i) => {
                                                                                    return <p key={i}>• {timeSlots.startTime + '-' + timeSlots.endTime} </p>
                                                                                })
                                                                                : '-'
                                                                        }
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            {
                                                                model.type !== sensorRuleType.COLLECT &&
                                                                <Table.Row>
                                                                    <Table.Cell width={4}><LabelInput text={t("sensorRule.delayTime")} /></Table.Cell>
                                                                    <Table.Cell><p>{!!model.conditionData.delayTime ? model.conditionData.delayTime : '0'}</p></Table.Cell>
                                                                </Table.Row>
                                                            }
                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t("sensorRule.holidayType")} /></Table.Cell>
                                                                <Table.Cell><p>{!!model.conditionData.holidayType ? t(`constantTranslate.recurrenceHolidayType.${model.conditionData.holidayType}`) : '-'}</p></Table.Cell>
                                                            </Table.Row>
                                                        </Table.Body>
                                                    </Table>
                                                    <br></br>
                                                    <Header as='h3' className="form header">
                                                        {t("sensorRule.recurrence")}
                                                    </Header>
                                                    <Segment className="group-segment-input">
                                                        <Table striped celled>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}><LabelInput text={t("common.type")} /></Table.Cell>
                                                                    <Table.Cell><p>{t(`constantTranslate.recurrenceType.${recurrenceType}`)}</p></Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}><LabelInput text={t("sensorRule.dayOfWeek")} /></Table.Cell>
                                                                    <Table.Cell>
                                                                        <div className="minThree">
                                                                            {Array.isArray(recurrenceDayOfWeek) && recurrenceDayOfWeek.length > 0 ?
                                                                                recurrenceDayOfWeek.map((day) => {
                                                                                    return <p>• {t(`constantTranslate.dayOfWeek.${day}`)}</p>
                                                                                })
                                                                                : '-'
                                                                            }
                                                                        </div>
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                        <br></br>
                                                        <Header as='h3' className="form header">
                                                            {t("common.startDate")}
                                                        </Header>
                                                        <Table striped celled>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}><LabelInput text={t("common.enable")} /></Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            (recurrenceStartDateEnable) &&
                                                                            <Icon color="green" name="checkmark" />
                                                                        }
                                                                        {
                                                                            (recurrenceStartDateEnable === false) &&
                                                                            <Icon color="red" name="close" />
                                                                        }
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><LabelInput text={t("common.date")} /></Table.Cell>
                                                                    <Table.Cell><p>{recurrenceStartDate}</p></Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                        <br></br>
                                                        <Header as='h3' className="form header">
                                                            {t("common.endDate")}
                                                        </Header>
                                                        <Table striped celled>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell width={4}><LabelInput text={t("common.enable")} /></Table.Cell>
                                                                    <Table.Cell>
                                                                        {
                                                                            (recurrenceEndDateEnable) &&
                                                                            <Icon color="green" name="checkmark" />
                                                                        }
                                                                        {
                                                                            (recurrenceEndDateEnable === false) &&
                                                                            <Icon color="red" name="close" />
                                                                        }
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                <Table.Row>
                                                                    <Table.Cell><LabelInput text={t("common.date")} /></Table.Cell>
                                                                    <Table.Cell><p>{recurrenceEndDate}</p></Table.Cell>
                                                                </Table.Row>
                                                            </Table.Body>
                                                        </Table>
                                                    </Segment>
                                                    <br></br>
                                                    <Header as='h3' className="form header">
                                                        {t("sensorRule.includeDays")}
                                                    </Header>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <ViewTable
                                                                    columns={this.getColumnIncludeExcludeDaysConfig()}
                                                                    datas={conditionDataIncludeDays}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    <br></br>
                                                    <Header as='h3' className="form header">
                                                        {t("sensorRule.excludeDays")}
                                                    </Header>
                                                    <Grid>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <ViewTable
                                                                    columns={this.getColumnIncludeExcludeDaysConfig()}
                                                                    datas={conditionDataExcludeDays}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </React.Fragment>
                                                : <ViewNoResult message={t("common.noData")} />
                                        }
                                        {/*                     {
                                !!model.conditionData ?
                                    <React.Fragment>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <LabelInput text={t("common.timeSlot")} />
                                                    <ViewTable
                                                        columns={this.getColumnConfigTimeSlot()}
                                                        datas={model.conditionData.timeSlots}
                                                        pagination={false}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {
                                                model.type !== sensorRuleType.COLLECT &&
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t("sensorRule.delayTime")} />
                                                        <p>{model.conditionData.delayTime}</p>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <LabelInput text={t("sensorRule.holidayType")} />
                                                    <p>{model.conditionData.holidayType}</p>
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </React.Fragment>
                                    : <ViewNoResult message={t("common.noData")} />
                            } */}
                                        <br></br>
                                        <br></br>
                                        <Header as='h1' dividing className="form header view-header-button">
                                            {t("sensorRule.sensorRuleAction")}
                                        </Header>
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewTable
                                                        columns={this.getColumnConfigAction()}
                                                        datas={model.actions}
                                                        pagination={false}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorRuleView.defaultProps = {
    model: new SensorRuleModel()
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(SensorRuleView)