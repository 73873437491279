
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Segment } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import NotificationManager from '../../business/NotificationManager'
import { notificationService } from '../../../constant'
import LineDataForm from './dataForm/LineDataForm'
import SendGridDataForm from './dataForm/SendGridDataForm'
import SMTPDataForm from './dataForm/SMTPDataForm'
import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../common/ViewButton'

class NotificationForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isTest = null
        this.bNotification = new NotificationManager()
    }

    handleChange = (e, { name, value }) => {
        try {
            if (name === "service") {
                let ModelNotificationData = this.bNotification.getModelNotificationData(value)
                let data = {
                    [name]: value,
                    data: null
                }
                if (ModelNotificationData) {
                    data.data = new ModelNotificationData()
                }
                this.props.handleChangeMultiValue(e, data)
                this.props.handleChange(null, { name: "systemIntegrationId", value: "" })
            } else {
                this.props.handleChange(e, { name, value })
            }
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    handleChangeData = (e, { name, value }, masterKey) => {
        try {
            this.props.handleChange(e, { name, value }, masterKey)
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    getNotificationDataForm = (service) => {
        let ComponentForm = null
        switch (service) {
            case notificationService.LINE_BOT:
                ComponentForm = LineDataForm
                break;
            case notificationService.SEND_GRID:
                ComponentForm = SendGridDataForm
                break;
            case notificationService.SMTP:
                ComponentForm = SMTPDataForm
                break;
            default:
                break;
        }
        return ComponentForm
    }

    onTestNotification = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onTestNotification()
            let tempState = {
                isLoading: false,
                isTest: null
            }
            if (!!res && res.result === true) {
                tempState.isTest = true
            } else {
                tempState.isTest = false
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t, optionsNotificationService, optionsNotificationSystem, model, errors } = this.props
            const { isLoading, isTest } = this.state

            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , serviceValidate = this.getErrorInput("service", errors)
                , systemIntegrationIdValidate = this.getErrorInput("systemIntegrationId", errors)

            let dataMasterKey = "data"
                , dataErrors = errors.filter(err => err.path.includes(`${dataMasterKey}.`))
                    .map(err => {
                        return {
                            ...err,
                            path: err.path.replace(`${dataMasterKey}.`, "")
                        }
                    })
            let ComponentForm = this.getNotificationDataForm(model.service)

            let optionsNotificationSystemFlitered = ""

            if (!!model.service) {
                optionsNotificationSystemFlitered = optionsNotificationSystem.filter(system => system.service === model.service)
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {
                        (isTest !== null && isTest !== undefined) &&
                        this.getModalContent(this.getInfoModalOption({
                            onClose: () => { },
                            headerText: t("notification.testNotification"),
                            size: "mini",
                            content: <div style={{ textAlign: "center" }}>
                                {
                                    isTest === true ?
                                        <h3 style={{ color: "#0f0" }}>{t("notification.notificationSuccess")}</h3>
                                        : <h3 style={{ color: "#f00" }}>{t("notification.notificationFail")}</h3>
                                }
                            </div>,
                            buttons: [
                                new ButtonModel({
                                    text: t('common.close'),
                                    color: "grey",
                                    onClick: () => this.setState({ isTest: null })
                                })
                            ]
                        }))
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('notification.service')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="service"
                                        value={model.service}
                                        options={optionsNotificationService}
                                        onChange={this.handleChange}
                                        error={serviceValidate.isError ? serviceValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('notification.system')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="systemIntegrationId"
                                        value={model.systemIntegrationId}
                                        options={optionsNotificationSystemFlitered}
                                        onChange={this.handleChange}
                                        error={systemIntegrationIdValidate.isError ? systemIntegrationIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                        {
                            (!!ComponentForm || (!!model.service && !!model.data)) &&
                            <Segment className="group-segment-input">
                                <Grid>
                                    {
                                        !!ComponentForm &&
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ComponentForm
                                                    model={model.data}
                                                    handleChange={(e, data) => this.handleChangeData(e, data, dataMasterKey)}
                                                    errors={dataErrors}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                    {
                                        !!model.service && !!model.data &&
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t('notification.testNotification')}`,
                                                        color: "blue",
                                                        onClick: this.onTestNotification
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    }
                                </Grid>
                            </Segment>
                        }
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

NotificationForm.defaultProps = {
    model: null,
    errors: [],
    optionsNotificationService: [],
    optionsNotificationSystem: [],
    handleChange: () => console.error("Please provide \"Change SystemIntegration\" action."),
    handleChangeMultiValue: () => console.error("Please provide \"Handle Change Multi Value\" action."),
    onTestNotification: () => {
        console.error("Please provide \"Test\" action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(NotificationForm)