import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Table, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import EventControlRuleConditionModel from '../../../models/eventControlRuleStep/EventControlRuleConditionModel'
import LabelInput from '../../common/LabelInput'
import { dateTimeFormat, recurrenceTypeCode } from '../../../../constant'
import { eventControlRuleStep } from '../../../../constant'

class ConditionView extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = new EventControlRuleConditionModel(props.dataStep)

        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = () => {
        this.onValidate(this.onNext)
    }

    onBack = () => {
        this.props.onBack(this.state.model)
    }

    onNext = () => {
        this.props.onNext(this.state.model)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    onCreate = () => {
        this.props.history.push(`/eventcontrolrule/create`)
    }

    onEdit = () => {
        const { dataConfig } = this.props
        this.props.history.push(`/eventcontrolrule/update/${dataConfig.editID}?step=${eventControlRuleStep.CONDITION}`)
    }

    getColumnIncludeExcludeDaysConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.date"),
                selector: (row) => {
                    if (!!row.d) {
                        return moment(row.d).format(dateTimeFormat.apiFullDate)
                    }

                    return '-'
                },
                sortable: false
            },
        ]
        return columns
    }

    render() {
        try {
            const { t } = this.props
            const { model, isLoading } = this.state

            let recurrenceType = "-"
                , recurrenceDayOfWeek = []
                , recurrenceStartDateEnable = false
                , recurrenceStartDate = "-"
                , recurrenceEndDateEnable = false
                , recurrenceEndDate = "-"
                , conditionIncludeDays = []
                , conditionExcludeDays = []

            if (!!model) {
                if (!!model.condition) {
                    if (!!model.condition.recurrence) {
                        if (!!model.condition.recurrence.type) {
                            recurrenceType = model.condition.recurrence.type
                        }

                        if (!!model.condition.recurrence.weeklyEveryDayOfWeek) {
                            recurrenceDayOfWeek = model.condition.recurrence.weeklyEveryDayOfWeek
                        }

                        if (!!model.condition.recurrence.startDate) {
                            recurrenceStartDateEnable = model.condition.recurrence.startDate.isEnable
                            recurrenceStartDate = moment(model.condition.recurrence.startDate.date).format(dateTimeFormat.apiFullDate)
                        }

                        if (!!model.condition.recurrence.endDate) {
                            recurrenceEndDateEnable = model.condition.recurrence.endDate.isEnable
                            recurrenceEndDate = moment(model.condition.recurrence.endDate.date).format(dateTimeFormat.apiFullDate)
                        }

                    }

                    if (Array.isArray(model.condition.includeDays) && model.condition.includeDays.length > 0) {
                        conditionIncludeDays = model.condition.includeDays
                    }

                    if (Array.isArray(model.condition.excludeDays) && model.condition.excludeDays.length > 0) {
                        conditionExcludeDays = model.condition.excludeDays
                    }
                }
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("common.timeSlot")} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {
                                            Array.isArray(model.condition.timeSlots) && model.condition.timeSlots.length > 0 ?
                                                model.condition.timeSlots.map((timeSlots, i) => {
                                                    return <p key={i}>• {timeSlots.startTime + '-' + timeSlots.endTime} </p>
                                                })
                                                : '-'
                                        }
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("eventControlRule.holidayType")} /></Table.Cell>
                                <Table.Cell><p>{!!model.condition.holidayType ? t(`constantTranslate.recurrenceHolidayType.${model.condition.holidayType}`) : '-'}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        <Table.Row>
                            <Table.Cell width={4}><LabelInput text={t("eventControlRule.recurrenceType")} /></Table.Cell>
                            <Table.Cell><p>{recurrenceType !== '-' ? t(`constantTranslate.recurrenceType.${recurrenceType}`) : '-'}</p></Table.Cell>
                        </Table.Row>
                        {
                            recurrenceType === recurrenceTypeCode.WEEKLY &&
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("eventControlRule.recurrenceDayOfWeek")} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {recurrenceDayOfWeek.map((day) => {
                                            return <p>• {t(`constantTranslate.dayOfWeek.${day}`)}</p>
                                        })}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {
                            recurrenceStartDateEnable &&
                            <Table.Row>
                                <Table.Cell><LabelInput text={t("eventControlRule.recurrenceStartDate")} /></Table.Cell>
                                <Table.Cell><p>{recurrenceStartDate}</p></Table.Cell>
                            </Table.Row>
                        }
                        {
                            recurrenceEndDateEnable &&
                            <Table.Row>
                                <Table.Cell><LabelInput text={t("eventControlRule.recurrenceEndDate")} /></Table.Cell>
                                <Table.Cell><p>{recurrenceEndDate}</p></Table.Cell>
                            </Table.Row>
                        }
                        {
                            (Array.isArray(conditionIncludeDays) && conditionIncludeDays.length > 0) &&
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("eventControlRule.includeDays")} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {conditionIncludeDays.map((day) => {
                                            return <p>{`• ${moment(day).format(dateTimeFormat.displayDate)}`}</p>
                                        })}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        }
                        {
                            (Array.isArray(conditionExcludeDays) && conditionExcludeDays.length > 0) &&
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("eventControlRule.excludeDays")} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {conditionExcludeDays.map((day) => {
                                            return <p>{`• ${moment(day).format(dateTimeFormat.displayDate)}`}</p>
                                        })}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        }
                    </Table>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.next"),
                                    color: "teal",
                                    // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                    onClick: this.onSubmit
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.create'),
                                    color: "teal",
                                    onClick: () => this.onCreate()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.edit'),
                                    color: "green",
                                    onClick: () => this.onEdit()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/eventcontrolrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

ConditionView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
    // optionGreetingTypeList: [],
    // onCreate: () => console.error("Please provide \"Create Visitor\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ConditionView)