
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewUserList from '../../views/user/ViewUserList'

import { getUserStatusList } from '../../actions/userActions'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
// import { getLocationTypeList } from '../../actions/locationTypeActions'
import UserManager from '../../business/UserManager'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'
import { getDepartmentList } from '../../actions/departmentActions'
import { getSettingInfo } from '../../actions/settingActions'

class UserListController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bUser = new UserManager(this)
    }

    onResetPasswordUser = async (model, callBack) => {
        const { t } = this.props
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bUser.resetPasswordUser(model.id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => {
                        // this.props.getUserList()
                    }

                if (!!res && res.result === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.resetPasswordError"),
                            content: t("message.unableToResetUserPassword"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(tempState, action)
            })
        })
    }

    onChangeStatusUser = (model, callBack) => {
        const { t } = this.props
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bUser.updateUserStatus(model.id, model.status, (res) => {
                let body = {
                    isLoadingComponent: false,
                },
                    action = () => { }

                if (!!res && res.result === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeUserStatusError"),
                            content: t("message.unableToChangeUserStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(body, action)
            })
        })
    }

    onDeleteUser = (model, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.deleteUser(model.id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => {
                        // this.props.getUserList()
                    }

                if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(tempState, action)
            })
        })
    }

    onImportUserFromPlatform = async (ids, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bUser.importUser({ userIds: ids }, (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(body, action)
            })
        })

    }

    getUserListPaging = (query) => {
        return this.bUser.getUserListPaging(query)
    }

    onSyncAllUser = (callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bUser.syncAllUser((res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { t, resourceGroupList, userStatusList, departmentList, settingInfo } = this.props
            const { isLoadingComponent } = this.state

            const setting = settingInfo.getData()

            let allowResetPassword = false

            if (setting) {
                if (setting.systemNotificationInfo) {
                    if (setting.systemNotificationInfo.notification && setting.systemNotificationInfo.resetPassword.isEnable) {
                        allowResetPassword = true
                    }
                }
            }

            let resourceGroupDatas = resourceGroupList.getDatas()

            let optionResourceGroup = resourceGroupList.getDatasOption()
            optionResourceGroup = optionResourceGroup.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionsDepartment = departmentList.getDatasOption()
            optionsDepartment = optionsDepartment.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionUserStatus = userStatusList.getDatasOption()
            optionUserStatus = optionUserStatus.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.status.${d.value}`)
                }
            })
            optionUserStatus = optionUserStatus.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionUserStatusList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionUserStatusList = optionUserStatusList.concat(optionUserStatus)

            let optionResourceGroupList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionResourceGroupList = optionResourceGroupList.concat(optionResourceGroup)

            let optionsDepartmentList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionsDepartmentList = optionsDepartmentList.concat(optionsDepartment)

            return (
                <React.Fragment>
                    {super.render()}
                    {(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
                    <ViewUserList
                        resourceGroupList={resourceGroupDatas}
                        onResetPasswordUser={this.onResetPasswordUser}
                        onChangeStatusUser={this.onChangeStatusUser}
                        onImportUserFromPlatform={this.onImportUserFromPlatform}

                        optionUserStatus={optionUserStatus}
                        optionUserStatusList={optionUserStatusList}
                        optionResourceGroupList={optionResourceGroupList}
                        optionsDepartmentList={optionsDepartmentList}
                        allowResetPassword={allowResetPassword}
                        onGetDataPaging={this.getUserListPaging}
                        onSyncAllUser={this.onSyncAllUser}
                        onDelete={this.onDeleteUser}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
            userStatusList: state.userStatusList,
            departmentList: state.departmentList,
            settingInfo: state.settingInfo,
        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getUserStatusList() {
                dispatch(getUserStatusList())
            },
            getDepartmentList() {
                dispatch(getDepartmentList())
            },
            getSettingInfo() {
                dispatch(getSettingInfo())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
            this.props.getUserStatusList()
            this.props.getDepartmentList()
            this.props.getSettingInfo()
        }
    })
)


export default enhance(UserListController)