
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'
import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import FormImportExcel from './FormImportExcel'
import UserImportExcelModel from '../../models/UserImportExcelModel'
import { defaultValue, downloadFileType } from '../../../constant'
import utils from '../../core/utility/utils'

class ViewImportExternalUser extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.model = new UserImportExcelModel({ formatCode: defaultValue.PLEASE_SELECT })
        this.state.modelErrors = []
        this.state.modalCustom = null
    }

    onDownloadExampleFormat = () => {
        const { model } = this.state
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onGetExcelTemplate(model.formatCode)
            let tempState = {
                isLoading: false
            },
                action = () => { }
            if (!!res) {
                let fileName = `${model.formatCode}.${downloadFileType.EXCEL.filenameExtension}`
                action = () => utils.downloadFile(res, downloadFileType.EXCEL.type, fileName)
            }
            this.setState(tempState, action)
        })
    }

    onValidateSuccess = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onImportExcel(this.state.model)
            let tempState = {
                isLoading: false
            },
                action = () => this.afterImportExcel(res)
            this.setState(tempState, action)
        })

    }

    afterImportExcel = (res) => {
        // console.log(res)
        const { t } = this.props
        if (!!res && res.result === true) {
            this.setState({
                modalCustom: this.getInfoModalOption({
                    headerText: t("common.system"),
                    content: t("message.importUserSuccess"),
                    buttons: [
                        new ButtonModel({
                            text: t("common.back"),
                            color: "blue",
                            onClick: () => this.props.history.goBack()
                        })
                    ]
                })
            })
        }
        // else {
        //     this.setState({
        //         modalCustom: this.getErrorModalOption({
        //             headerText: t("common.system"),
        //             content: t("message.somethingWrong"),
        //             buttons: [
        //                 new ButtonModel({
        //                     text: t("common.close"),
        //                     color: "grey",
        //                     onClick: () => this.setState({ modalCustom: null })
        //                 })
        //             ]
        //         })
        //     })
        // }
    }

    onCancel = () => {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, formatCodeList } = this.props
            const { model, modelErrors, isLoading, modalCustom } = this.state
            let buttons = [
                new ButtonModel({
                    text: t("common.import"),
                    color: "blue",
                    onClick: () => this.onValidate(this.onValidateSuccess)
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            let optionsFormatCode = formatCodeList.map(code => {
                return {
                    key: code,
                    value: code,
                    text: code,
                }
            })

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('user.importExcelTitle')} buttons={buttons} >
                        <div className="module viewImportExternalUser">
                            <Container text>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <FormImportExcel
                                                model={model}
                                                errors={modelErrors}
                                                handleChange={this.handleChange}
                                                optionsFormatCode={optionsFormatCode}
                                                onDownloadExampleFormat={this.onDownloadExampleFormat}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewImportExternalUser.defaultProps = {
    formatCodeList: [],
    // importSystemList: [],
    onGetExcelTemplate: () => {
        console.error("Please provide Get Excel Template action.")
        return null
    },
    onImportExcel: () => {
        console.error("Please provide Import Excel action.")
    }
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewImportExternalUser)