
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import LabelInput from '../../common/LabelInput'
import LineDataModel from '../../../models/notificationData/LineDataModel'
import ViewButton from '../../common/ViewButton'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewTable from '../../../core/view/common/table/ViewTable'

class LineDataView extends MyPage {
    getColumnGroupConfig(datas) {
        const { t } = this.props
        const columns = [
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false
            },
            {
                name: '',
                width: '200px',
                wrap: true,
                cell: row => {
                    let buttons = [

                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div >
                },
                right: true
            },
        ]

        return columns
    }

    getColumnUserConfig(datas) {
        const { t } = this.props
        const columns = [
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false
            },
            {
                name: '',
                width: '200px',
                wrap: true,
                cell: row => {
                    let buttons = [

                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t, model } = this.props

            let dataGroups = []
                , dataUsers = []

            if (!!model) {
                if (Array.isArray(model.groups)) {
                    dataGroups = model.groups
                }
                if (Array.isArray(model.users)) {
                    dataUsers = model.users
                }
            }
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Header as='h1' dividing className="form header">
                            {t("notification.notiLineBot")}
                        </Header>
                        <Grid>
                            <Grid.Row verticalAlign="bottom">
                                <Grid.Column width={8}>
                                    <LabelInput text={t("common.group")} isRequire={false} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnGroupConfig(dataGroups)}
                                        datas={dataGroups}
                                        pagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <Grid>
                            <Grid.Row verticalAlign="bottom">
                                <Grid.Column width={8}>
                                    <LabelInput text={t("common.user")} isRequire={false} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnUserConfig(dataUsers)}
                                        datas={dataUsers}
                                        pagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

LineDataView.defaultProps = {
    model: new LineDataModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(LineDataView)