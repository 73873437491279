
import types from '../types'
import { requestStatus } from '../../constant'
import EventControlStatusResource from '../selectors/eventControlStatusResource'

const eventControlStatusReducer = {
	/* eventControlStatusInfo: (state = null, action) => {
		if (!state || !(state instanceof EventControlStatusResource)) {
			state = new EventControlStatusResource()
		}

		switch (action.type) {
			case types.GET_CONTROLLER_INFO_REQUEST:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_INFO_SUCCESS:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_CONTROLLER_INFO_FAILURE:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}, */
	eventControlStatusList: (state = null, action) => {
		if (!state || !(state instanceof EventControlStatusResource)) {
			state = new EventControlStatusResource()
		}

		switch (action.type) {
			case types.GET_EVENT_CONTROL_STATUS_LIST_REQUEST:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_EVENT_CONTROL_STATUS_LIST_SUCCESS:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_EVENT_CONTROL_STATUS_LIST:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_EVENT_CONTROL_STATUS_LIST_FAILURE:
				state = new EventControlStatusResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default eventControlStatusReducer