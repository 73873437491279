
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
//import { getControllerList } from '../../actions/controllerActions'
import { getLocationList } from '../../actions/locationActions'
import { getSensorInfo, getSensorResourceList, getSensorModuleList, getSensorTypeList, getSensorModelList, getSensorLocationTypeList, getSensorControllerList } from '../../actions/sensorActions'
import SensorManager from '../../business/SensorManager'
import utils from '../../core/utility/utils'
import SensorUpdate from '../../views/sensor/SensorUpdate'

class UpdateSensorController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSensor = new SensorManager(this)
	}

	getPropsResetStatus() {
		return ["sensorInfo"]
	}

	onUpdateSensor = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensor.updateSensor(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/sensor/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { sensorInfo, sensorControllerList, locationList, sensorResourceList, sensorTypeList, sensorModelList, sensorModuleList, sensorLocationTypeList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const sensor = sensorInfo.getData()
				, controllerDatas = sensorControllerList.getDatas()
				, optionsControllerList = sensorControllerList.getDatasOption()
				, optionsLocationList = locationList.getDatasOption()
				, optionsResourceList = sensorResourceList.getDatasOption()
				, optionsSensorTypeList = sensorTypeList.getDatasOption()
				, optionsSensorModelList = sensorModelList.getDatasOption()
				, optionsSensorModuleList = sensorModuleList.getDatasOption()
				, optionsSensorLocationTypeList = sensorLocationTypeList.getDatasOption()

			return (
				<React.Fragment>
					{super.render()}
					<SensorUpdate
						isLoadingControl={isLoadingComponent}
						model={sensor}
						controllerList={controllerDatas}
						optionsControllerList={optionsControllerList}
						optionsLocationList={optionsLocationList}
						optionsResourceList={optionsResourceList}
						optionsSensorTypeList={optionsSensorTypeList}
						optionsSensorModelList={optionsSensorModelList}
						optionsSensorModuleList={optionsSensorModuleList}
						optionsSensorLocationTypeList={optionsSensorLocationTypeList}
						onUpdate={this.onUpdateSensor}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			sensorControllerList: state.sensorControllerList,
			sensorInfo: state.sensorInfo,
			locationList: state.locationList,
			sensorResourceList: state.sensorResourceList,
			sensorTypeList: state.sensorTypeList,
			sensorModelList: state.sensorModelList,
			sensorModuleList: state.sensorModuleList,
			sensorLocationTypeList: state.sensorLocationTypeList
		}),
		(dispatch, props) => ({
			getSensorControllerList() {
				dispatch(getSensorControllerList())
			},
			getSensorInfo() {
				dispatch(getSensorInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getSensorResourceList() {
				dispatch(getSensorResourceList())
			},
			getSensorTypeList() {
				dispatch(getSensorTypeList())
			},
			getSensorModelList() {
				dispatch(getSensorModelList())
			},
			getSensorModuleList() {
				dispatch(getSensorModuleList())
			},
			getSensorLocationTypeList() {
				dispatch(getSensorLocationTypeList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSensorControllerList()
			this.props.getSensorInfo()
			this.props.getLocationList()
			this.props.getSensorResourceList()
			this.props.getSensorTypeList()
			this.props.getSensorModelList()
			this.props.getSensorModuleList()
			this.props.getSensorLocationTypeList()
		}
	}),
)


export default enhance(UpdateSensorController)