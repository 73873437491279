
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetVisitorGroupList = () => {
	return {
		type: type.RESET_VISITOR_GROUP_LIST
	}
}

const getVisitorGroupInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitorgroup/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_VISITOR_GROUP_INFO_REQUEST,
				{
					type: type.GET_VISITOR_GROUP_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_VISITOR_GROUP_INFO_FAILURE
			]
		}
	}
}

const getVisitorGroupList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitorgroup${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_VISITOR_GROUP_LIST_REQUEST,
				{
					type: type.GET_VISITOR_GROUP_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_VISITOR_GROUP_LIST_FAILURE
			]
		}
	}
}

const getFaceComparisonGroupList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitorgroup/faceconparisiongroup`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_FACE_COMPARISON_GROUP_LIST_REQUEST,
				{
					type: type.GET_FACE_COMPARISON_GROUP_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_FACE_COMPARISON_GROUP_LIST_FAILURE
			]
		}
	}
}

const getOrganizationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitorgroup/organization`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ORGANIZATION_LIST_REQUEST,
				{
					type: type.GET_ORGANIZATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ORGANIZATION_LIST_FAILURE
			]
		}
	}
}

const getVisitorGroupPrivacyList = () => {
	return {
		
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitorgroup/privacy`,
			method: `GET`,
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_VISITOR_GROUP_PRIVACY_LIST_REQUEST,
				{
					type: type.GET_VISITOR_GROUP_PRIVACY_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_VISITOR_GROUP_PRIVACY_LIST_FAILURE
			]
		}
	}
}

export {
	getVisitorGroupInfo,
	getVisitorGroupList,
	getVisitorGroupPrivacyList,
	getFaceComparisonGroupList,
	getOrganizationList,
	resetVisitorGroupList
}