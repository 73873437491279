
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import SendGridDataModel from '../../../models/systemIntegrationData/SendGridDataModel'

class SendGridDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let tokenValidate = this.getErrorInput("token", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.sendGrid")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.token")} isRequire={true} />
                                <Form.Input
                                    name="token"
                                    value={model.token}
                                    onChange={this.handleChange}
                                    error={tokenValidate.isError ? tokenValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SendGridDataForm.defaultProps = {
    model: new SendGridDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SendGridDataForm)