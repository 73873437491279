import React from 'react'
import { translate } from 'react-switch-lang'
import MyPage from '../../../components/MyPage'
import { Pagination, Icon, Dropdown } from 'semantic-ui-react'
import ButtonModel from '../../../model/ButtonModel'
import PagingDataModel from '../../../model/PagingDataModel'
import PagingModel from '../../../model/PagingModel'
import ViewTable from './ViewTable'
import ViewButton from '../../../../views/common/ViewButton'

class ViewTablePaging extends MyPage {
    constructor(props) {
        super(props)
        // let startState = this.getDataPaging(props.pagingData)
        // this.state.pagingData = props.pagingData instanceof PagingDataModel ? props.pagingData : null

        // this.state.datas = startState.datas

        // this.state.activePage = startState.activePage
        this.state.boundaryRange = 1
        this.state.siblingRange = 1
        // this.state.totalPages = startState.totalPages

        this.state.showEllipsis = true
        this.state.showFirstAndLastNav = true
        this.state.showPreviousAndNextNav = true

        // this.state.pagingModel = null
    }

    // getDataPaging = (pagingData) => {
    //     // if (pagingData instanceof PagingDataModel) {
    //     //     return {
    //     //         datas: pagingData.datas,
    //     //         pagingModel: pagingData.pagination,
    //     //         activePage: pagingData.pagination.pageNumber,
    //     //         totalPages: Math.ceil(pagingData.pagination.totalItem / pagingData.pagination.pageSize),
    //     //     }
    //     // } else {
    //     return {
    //         // datas: [],
    //         // pagingModel: null,
    //         activePage: 0,
    //         totalPages: 0,
    //     }
    //     // }
    // }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     console.log("999")
    //     let tempState = {}
    //     if ("pagingData" in nextProps) {
    //         if (nextProps.pagingData instanceof PagingDataModel) {
    //             if (!prevState.pagingData || prevState.pagingData.id !== nextProps.pagingData.id) {
    //                 tempState.datas = nextProps.pagingData.datas
    //                 tempState.pagingModel = nextProps.pagination
    //                 tempState.activePage = nextProps.pagingData.pagination.pageNumber
    //                 tempState.totalPages = Math.ceil(nextProps.pagingData.pagination.totalItem / nextProps.pagingData.pagination.pageSize)
    //             }
    //         } else {
    //             // tempState.datas = []
    //             // tempState.pagingModel = null
    //             // tempState.activePage = 0
    //             // tempState.totalPages = 0
    //         }
    //     }
    //     if (Object.keys(tempState).length > 0) {
    //         return tempState
    //     }

    //     return null
    // }

    handlePaginationChange = (e, { activePage }) => {
        // console.log(1, moment().format("HH:mm:ss"))
        // let pagingModel = new PagingModel(this.state.pagingModel)
        // pagingModel.pageNumber = activePage
        // this.setState({
        //     activePage,
        //     // datas: [],
        //     // pagingModel: null,
        // }, () => {
        //     console.log(2, moment().format("HH:mm:ss"))
        //     this.props.onChangePage(pagingModel)
        // })
        let pagingModel = new PagingModel(this.props.pagingData.pagination)
        pagingModel.pageNumber = activePage
        this.props.onChangePage(pagingModel)
    }
    onChangePage = (page, totalRows) => {
        let pagingModel = new PagingModel(this.props.pagingData.pagination)
        pagingModel.pageNumber = page
        // pagingModel.pageSize = totalRows
        this.props.onChangePage(pagingModel)
    }
    onChangePagesize = (e, { value }, page) => {
        let pagingModel = new PagingModel(this.props.pagingData.pagination)
        pagingModel.pageNumber = page
        pagingModel.pageSize = value
        this.props.onChangePage(pagingModel)
    }

    mapNewColumn = (columns) => {
        let mapColumns = columns.map(column => {
            let newColumn = {}
            if (column.name === "buttons") {
                let columnButtons = {
                    ...column,
                    name: ""
                }
                let buttons = []
                if ("buttons" in column) {
                    delete columnButtons.buttons
                    if (Array.isArray(column.buttons) && column.buttons.length > 0) {
                        buttons = column.buttons
                    }
                }
                columnButtons.cell = (model) => {
                    return <div className="box-action-table" >
                        {
                            buttons.map((btnModel, i) => {
                                let nBtnModel = new ButtonModel(btnModel)
                                nBtnModel.onClick = () => btnModel.onClick(model)
                                return <ViewButton
                                    key={i}
                                    model={nBtnModel}
                                />
                            })
                        }
                    </div>
                }
                newColumn = columnButtons
            } else {
                newColumn = {
                    ...column
                }
            }
            return newColumn
        })

        return mapColumns
    }
    render() {
        const { t, columns, isLoading, pagingData, fillEmptyRows } = this.props
        const {
            // activePage,
            boundaryRange,
            siblingRange,
            // totalPages,

        } = this.state
        if (!Array.isArray(columns) || columns.length === 0) {
            return null
        }

        let mapColumns = this.mapNewColumn(columns)
            , mapDatas = []
            , activePage = 0
            , totalPages = 0
            , pageSize = 0
            , totalItem = 0
        if (pagingData instanceof PagingDataModel) {
            mapDatas = pagingData.datas
            activePage = pagingData.pagination.pageNumber
            pageSize = pagingData.pagination.pageSize
            totalItem = pagingData.pagination.totalItem
            totalPages = Math.ceil(pagingData.pagination.totalItem / pagingData.pagination.pageSize)
        }
        return (
            <div className="module viewTablePaging">

                <ViewTable
                    columns={mapColumns}
                    datas={mapDatas}
                    isLoading={isLoading}
                    isShowPagination={false}
                    fillEmptyRows={fillEmptyRows}
                // onChangePage={this.onChangePage}
                />
                {
                    (totalPages > 0 && activePage > 0) &&
                    <div className="vt-custom-pagination">
                        <div className="vt-custom-pagination">
                            <div className="vt-c-p-rows">
                                <div className="vt-c-p-rows-wrapper">
                                    <div>
                                        <p>{t("common.rowsPerPage")}:</p>
                                    </div>
                                    <div>
                                        <Dropdown
                                            selection
                                            fluid
                                            options={[
                                                { value: 10, key: 10, text: 10 },
                                                { value: 20, key: 20, text: 20 },
                                                { value: 50, key: 50, text: 50 },
                                                { value: 100, key: 100, text: 100 },
                                            ]}
                                            value={pageSize}
                                            onChange={(e, data) => this.onChangePagesize(e, data, data.value >= totalPages ? 1 : activePage)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="vt-c-p-of">
                                <div className="vt-c-p-of-wrapper">
                                    <p>{`${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + mapDatas.length}`} {t("common.rangeSeparator")} {totalItem}</p>
                                </div>
                            </div>
                            <div className="vt-c-p-page">
                                <Pagination
                                    onPageChange={this.handlePaginationChange}

                                    activePage={activePage}
                                    boundaryRange={boundaryRange}
                                    size='mini'
                                    siblingRange={siblingRange}
                                    totalPages={totalPages}

                                    // Heads up! All items are powered by shorthands, if you want to hide one of them, just pass `null` as value
                                    // ellipsisItem={showEllipsis ? undefined : null}
                                    // firstItem={showFirstAndLastNav ? undefined : null}
                                    // lastItem={showFirstAndLastNav ? undefined : null}
                                    // prevItem={showPreviousAndNextNav ? undefined : null}
                                    // nextItem={showPreviousAndNextNav ? undefined : null}

                                    ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                    // firstItem={{ content: <Icon name='angle double left' />, icon: true }}
                                    // lastItem={{ content: <Icon name='angle double right' />, icon: true }}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={{ content: <Icon name='angle left' disabled={activePage <= 1} />, icon: true }}
                                    nextItem={{ content: <Icon name='angle right' disabled={activePage === totalPages} />, icon: true }}
                                />
                            </div>
                        </div>
                    </div>
                }
            </div >
        )
    }
}

ViewTablePaging.defaultProps = {
    columns: [],
    pagingData: null,
    isLoading: false,
    onChangePage: () => {
        return null
    },
    fillEmptyRows: false, // if you want the table to fill in extra rows when not having enough data (e.g if pagination is 10, and the data array only has 5, the table will fill the rest with empty rows.)
}

export default translate(ViewTablePaging)