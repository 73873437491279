
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Table, Card, Image, Header, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import VisitorModel from '../../models/VisitorModel'
import LabelInput from '../common/LabelInput'
import { CORE_API_ENDPOINT } from '../../lib'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import { dateTimeFormat, inviteInfoType } from '../../../constant'
import ViewNoResult from '../common/ViewNoResult'

class VisitorView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onEdit = (model) => {
        this.props.history.push(`/visitor/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/visitor`)
    }

    onConfirmUnIntegrate = (serviceInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onUnintegtationUser(model, serviceInfo)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("visitor.service"),
                selector: (row) => row.service ? row.service : '-',
                sortable: false
            },
            {
                name: '',
                width: '300px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmUnIntegrate(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getColumnRegisterConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("visitor.type"),
                selector: (row) => row.type ? t(`constantTranslate.walkinVisitorIdentity.${row.type}`) : '-',
                sortable: false,
                width: '150px',
            },
            {
                name: t("visitor.idNumber"),
                selector: (row) => {
                    let idNumDisplay = '-'
                    if (!!row.type && !!row.data) {
                        switch (row.type) {
                            case "PASSPORT":
                                idNumDisplay = row.data.passportNo
                                break;
                            case "ID_CARD":
                                idNumDisplay = row.data.cardNo
                                break;
                            case "BUILDING_CARD":
                                idNumDisplay = row.data.cardId
                                break;
                            case "PARKING_CARD":
                                idNumDisplay = row.data.cardId
                                break;
                            case "VISITOR_CARD":
                                idNumDisplay = row.data.cardId
                                break;
                            case "DRIVING_CARD":
                                idNumDisplay = row.data.citizenId
                                break;
                            default:
                                break;
                        }
                    }

                    return idNumDisplay
                },
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => {
                    let nameDisplay = '-'
                    if (!!row.type && !!row.data) {
                        switch (row.type) {
                            case "PASSPORT":
                                nameDisplay = row.data.displayName
                                break;
                            case "ID_CARD":
                                nameDisplay = row.data.displayName
                                break;
                            case "BUILDING_CARD":
                                nameDisplay = row.data.cardId
                                break;
                            case "PARKING_CARD":
                                nameDisplay = row.data.cardId
                                break;
                            case "VISITOR_CARD":
                                nameDisplay = row.data.cardId
                                break;
                            case "DRIVING_CARD":
                                nameDisplay = row.data.displayName
                                break;
                            default:
                                break;
                        }
                    }

                    return nameDisplay
                },
                sortable: false
            },
            {
                name: t("common.description"),
                selector: (row) => {
                    let descriptionDisplay = '-'
                    if (!!row.type && !!row.data) {
                        switch (row.type) {
                            case "PASSPORT":
                                if (!!row.data.passportType) {
                                    descriptionDisplay = row.data.passportType
                                }
                                break;
                            case "ID_CARD":
                                if (!!row.data.dateOf && !!row.data.dateOf.expiry) {
                                    descriptionDisplay = moment(row.data.dateOf.expiry).format(dateTimeFormat.displayFullDateTime)
                                }
                                break;
                            case "BUILDING_CARD":
                                descriptionDisplay = '-'
                                break;
                            case "PARKING_CARD":
                                descriptionDisplay = '-'
                                break;
                            case "DRIVING_CARD":
                                descriptionDisplay = '-'
                                break;
                            default:
                                break;
                        }
                    }

                    return descriptionDisplay
                },
                sortable: false,
                width: '180px'
            },
        ]

        return columns
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformVisitor(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t } = this.props
            const { model, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )
            let isInvite = false
                , inviteType = "-"
                , inviteSubject = "-"
                , inviteOrganizerName = "-"
                , inviteDateTime = "-"
            if (!!model && !!model.inviteInfo) {
                isInvite = true
                let inviteInfo = model.inviteInfo
                    , inviteData = model.inviteInfo.data
                if (inviteInfo.type) {
                    inviteType = inviteInfo.type
                }
                if (!!inviteData && !!inviteType) {
                    if (inviteType === inviteInfoType.EVENT_ROOMMINISTER) {
                        if (!!inviteData.subject) {
                            inviteSubject = inviteData.subject
                        }
                        if (!!inviteData.organizerName) {
                            inviteOrganizerName = inviteData.organizerName
                        }
                        if (!!inviteData.subject) {
                            inviteSubject = inviteData.subject
                        }
                        let startDateTime = moment(inviteData.startDateTime)
                            , endDateTime = moment(inviteData.endDateTime)
                        if (startDateTime.isSame(endDateTime, "day")) {
                            inviteDateTime = startDateTime.format(dateTimeFormat.displayFullDateTime) + " - " + moment(endDateTime).format(dateTimeFormat.displayTime)
                        } else {
                            inviteDateTime = startDateTime.format(dateTimeFormat.displayFullDateTime) + " - " + moment(endDateTime).format(dateTimeFormat.displayFullDateTime)
                        }
                    }
                    if (inviteType === inviteInfoType.PRE_REGISTER_VMS) {
                        if (!!inviteData.subject) {
                            inviteSubject = inviteData.subject
                        }
                        if (!!inviteData.organizerName) {
                            inviteOrganizerName = inviteData.organizerName
                        }
                        if (!!inviteData.subject) {
                            inviteSubject = inviteData.subject
                        }
                        let startDateTime = moment(inviteData.startDateTime)
                            , endDateTime = moment(inviteData.endDateTime)
                        if (startDateTime.isSame(endDateTime, "day")) {
                            inviteDateTime = startDateTime.format(dateTimeFormat.displayFullDateTime) + " - " + moment(endDateTime).format(dateTimeFormat.displayTime)
                        } else {
                            inviteDateTime = startDateTime.format(dateTimeFormat.displayFullDateTime) + " - " + moment(endDateTime).format(dateTimeFormat.displayFullDateTime)
                        }
                    }
                }
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('visitor.viewVisitor')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.code")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.givenName")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.givenName ? model.givenName : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.sn")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.sn ? model.sn : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.username")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.username ? model.username : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.mobile")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.mobile ? model.mobile : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.email")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.email ? model.email : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.pinCode")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.pinCode ? model.pinCode : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.rfId")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.rfId ? model.rfId : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("visitor.visitorGroups")} /></Table.Cell>
                                                    <Table.Cell>
                                                        <div className="minThree">
                                                            <p>
                                                                {
                                                                    (Array.isArray(model.visitorGroups) && model.visitorGroups.length > 0) && model.visitorGroups.map((d, i) => {
                                                                        return <p>• {d.name}</p>
                                                                    })
                                                                }
                                                            </p>
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Grid>
                                            <Grid.Column width={8}>
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Header>{t("common.image")}</Card.Header>
                                                    </Card.Content>
                                                    <Card.Content>
                                                        <Image
                                                            src={`${CORE_API_ENDPOINT}/image/visitor/${model.id}?time=${moment().format(dateTimeFormat.apiFullDateTime)}`}
                                                            size="medium"
                                                        />
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                            <Grid.Column width={8}>
                                                <Card>
                                                    <Card.Content>
                                                        <Card.Header>{t("common.qrcode")}</Card.Header>
                                                    </Card.Content>
                                                    <Card.Content>
                                                        <Image
                                                            src={`${CORE_API_ENDPOINT}/image/visitor/qrcode/${model.id}?time=${moment().format(dateTimeFormat.apiFullDateTime)}`}
                                                            size="medium"
                                                        />
                                                    </Card.Content>
                                                </Card>
                                            </Grid.Column>
                                        </Grid>
                                        {
                                            isInvite &&
                                            <React.Fragment>
                                                <br></br>
                                                <br></br>
                                                <Header as='h1' dividing className="form header">
                                                    {t("visitor.inviteInfo")}
                                                </Header>
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.type")} /></Table.Cell>
                                                            <Table.Cell><p>{inviteType}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.subject")} /></Table.Cell>
                                                            <Table.Cell><p>{inviteSubject}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.organizer")} /></Table.Cell>
                                                            <Table.Cell><p>{inviteOrganizerName}</p></Table.Cell>
                                                        </Table.Row>
                                                        {
                                                            (!!model.inviteInfo.data && !!model.inviteInfo.data.startDateTime && !!model.inviteInfo.data.endDateTime) &&
                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t("common.description")} /></Table.Cell>
                                                                <Table.Cell><p>{inviteDateTime}</p></Table.Cell>
                                                            </Table.Row>
                                                        }
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        }
                                        {
                                            (!!model.proveOfApplicationInfo && Array.isArray(model.proveOfApplicationInfo) && model.proveOfApplicationInfo.length > 0) &&
                                            <React.Fragment>
                                                <br></br>
                                                <br></br>
                                                <Header as='h1' dividing className="form header">
                                                    {t("visitor.registerInfo")}
                                                </Header>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getColumnRegisterConfig()}
                                                                datas={model.proveOfApplicationInfo}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        {
                                            !!model && !!model.expiryInfo &&
                                            <React.Fragment>
                                                <br></br>
                                                <br></br>
                                                <Header as='h1' dividing className="form header">
                                                    {t("visitor.expiryInfo")}
                                                </Header>
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.type")} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.expiryInfo.type ? model.expiryInfo.type : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.startDateTime")} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.expiryInfo.startDateTime ? moment(model.expiryInfo.startDateTime).format(dateTimeFormat.displayFullDateTime) : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t("visitor.endDateTime")} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.expiryInfo.endDateTime ? moment(model.expiryInfo.endDateTime).format(dateTimeFormat.displayFullDateTime) : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </React.Fragment>
                                        }
                                        {/*                             <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t("user.profile")} />
                                        <div className="box-image-view-info">
                                            <img src={`${CORE_API_ENDPOINT}/image/visitor/${model.id}?time=${moment().format(dateTimeFormat.apiFullDateTime)}`} alt="No Data" />
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column width={8}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid> */}
                                        <br></br>
                                        <br></br>
                                        {
                                            (!!model.integrationInfo && model.integrationInfo.length !== 0) &&
                                            <React.Fragment>
                                                <Grid divided='vertically'>
                                                    <Grid.Row className="remove-bottom-padding">
                                                        <Grid.Column width={16}>
                                                            <Header as='h1'>
                                                                {t("common.integrationInfo")}
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getColumnConfig()}
                                                                datas={model.integrationInfo}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

VisitorView.defaultProps = {
    model: new VisitorModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(VisitorView)