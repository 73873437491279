
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'

import LoginModel from '../../models/LoginModel'
import { withRouter } from 'react-router-dom'

class ADLoginForm extends MyForm {

    render() {
        const { t, model, errors } = this.props
        if (!model) {
            return null
        }
        let usernameValidate = this.getErrorInput("username", errors)
            , passwordValidate = this.getErrorInput("password", errors)

        try {
            return (
                <React.Fragment>
                    {/* <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off"> */}
                    <Grid className="grid-half-padding">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("auth.username")} />
                                <Form.Input
                                    name="username"
                                    value={model.username}
                                    onChange={this.handleChange}
                                    icon='users'
                                    iconPosition='left'
                                    placeholder={t("auth.enterUsername")}
                                    fluid
                                    error={usernameValidate.isError ? usernameValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("auth.password")} />
                                <Form.Input
                                    name="password"
                                    value={model.password}
                                    onChange={this.handleChange}
                                    icon='key'
                                    iconPosition='left'
                                    placeholder={t("auth.enterPassword")}
                                    fluid
                                    type="password"
                                    error={passwordValidate.isError ? passwordValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Form.Checkbox
                                    label={t("auth.remember")}
                                    name="isRemember"
                                    onChange={this.handleChangeBoolean}
                                    checked={model.isRemember}
                                />
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <div className="box-for-got-password">
                                    <p onClick={() => this.props.history.push('/forgotpassword')}>{t("auth.forgotPassword")}</p>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/* </Form> */}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ADLoginForm.defaultProps = {
    model: new LoginModel()
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)

export default enhance(ADLoginForm)