
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const RoomSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	locationId: { type: String, required: [true, "validate.require"], default: null },
	location: { type: Object, default: null },
	description: { type: String, default: null },
	fileUpload: { type: FileSchema, default: null },
	areas: { type: Object, default: null },
	integrationInfo: { type: Object, default: null },
	status: { type: String, default: null },
	iotConfigs: { type: Object, default: null }
}, { _id: false })

setBaseSchema(RoomSchema, "06")

RoomSchema.path("code").validate(async function (code) {
	return !!code
}, "validate.require")

RoomSchema.path("name").validate(async function (name) {
	return !!name
}, "validate.require")

RoomSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			locationId: this.locationId,
			description: this.description
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		return result

	} catch (error) {
		return null
	}
}

RoomSchema.methods.serializeOption = function () {
	try {
		let option = {
			key: this.id,
			value: this.id,
			text: this.name,
		}
		return option
	} catch (error) {
		return null
	}
}

const RoomModel = mongoose.model("room", RoomSchema, "room")

export default RoomModel