
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ScheduleControlModel from '../../models/ScheduleControlModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'

class FormSearchScheduleControl extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new ScheduleControlModel()
    }

    onClickClear = () => {
        let model = new ScheduleControlModel({ code: "", name: "", description: "" })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch } = this.props

            return (
                <div className="module formSearchScheduleControl">
                    <Form onKeyDown= {this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("common.code")} isRequire={false}/>
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.name")} isRequire={false}/>
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.description")} isRequire={false}/>
                                    <Form.Input
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )

        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchScheduleControl.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new ScheduleControlModel()
}

export default (translate(FormSearchScheduleControl))