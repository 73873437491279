
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'
import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import MultipleCheckboxList from '../common/MultipleCheckboxList'
import LabelInput from '../common/LabelInput'

class ModalResources extends MyForm {

    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.modalStatus = "CREATE"

        if (!!props.model) {
            this.state.type = !!props.model.type ? props.model.type : null
            this.state.id = !!props.model.id ? props.model.id : null
            this.state.resources = (Array.isArray(props.model.resources) && props.model.resources.length > 0) ? props.model.resources : []
            this.state.modalStatus = "UPDATE"
        } else {
            this.state.type = ""
            this.state.id = []
            this.state.resources = []
        }

        this.state.isLoading = false
    }

    componentDidMount() {
        const { type } = this.state

        this.props.onGetDataList(type)

    }

    handleChange = (e, { name, value }) => {
        //  const { optionsResources } = this.props
        const { optionsResources } = this.props

        if (name in this.state) {
            let state = this.state
            state[name] = value
            let action = null
            if (name === "type") {
                state["isLoading"] = true
                state["id"] = []
                state["resources"] = []
                action = () => {
                    this.props.onGetDataList(value, () => {
                        let body = {
                            isLoading: false,
                        }
                        //, action = () => { }

                        this.setState(body)
                    })
                }
            } else if (name === "id") {
                // find the names from ids

                const resourceNameRaw = optionsResources.filter((el) => {
                    return value.some((resource) => {
                        return resource === el.key;
                    });
                });

                state.resources = resourceNameRaw.map(resourceName => {
                    return {
                        name: resourceName.text,
                        code: resourceName.code,
                    }
                })

            }
            this.setState(state, action)
        }

    }


    onSubmit = () => {
        const { type, id, resources } = this.state
        let obj = {
            type,
            id,
            resources
        }
        if (type) {
            this.props.onSubmit(obj)
        }
    }

    render() {
        try {
            const { t, onClose, optionsResourceType, optionsResources, resourceFieldName } = this.props
            const { type, id, modalStatus, isLoading } = this.state

            let wording = ""
            let buttonModal = ""
            if (modalStatus === "CREATE") {
                switch (resourceFieldName) {
                    case "source.resources":
                        wording = t("eventControlRule.addResource")
                        break;

                    case "source.areaResources":
                        wording = t("eventControlRule.addAreaResource")
                        break;

                    default:
                        wording = t("eventControlRule.addResource")
                        break;
                }

                buttonModal = [
                    new ButtonModel({
                        text: t("common.add"),
                        color: "green",
                        disabled: (!type && !id),
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            } else if (modalStatus === "UPDATE") {
                switch (resourceFieldName) {
                    case "source.resources":
                        wording = t("eventControlRule.editResource")
                        break;

                    case "source.areaResources":
                        wording = t("eventControlRule.editAreaResource")
                        break;

                    default:
                        wording = t("eventControlRule.editResource")
                        break;
                }

                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        disabled: (!type && !id),
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {this.getModalContent(this.getInfoModalOption({
                        // onClose: onClose,
                        headerText: wording,
                        content: <div className="module greetingRuleSource">
                            <Form onKeyDown={this.onKeyDownSubmitForm}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('eventControlRule.resourceType')} />
                                            <Form.Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                value={type}
                                                options={optionsResourceType}
                                                onChange={this.handleChange}
                                                name="type"
                                                placeholder={t("common.pleaseSelect")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('eventControlRule.resource')} />
                                            <MultipleCheckboxList
                                                name="id"
                                                optionsDataList={optionsResources}
                                                value={id}
                                                onChange={this.handleChange}
                                                isLoadingSegment={isLoading}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalResources.defaultProps = {
    optionsResources: [],
    optionsAreaAccessResourceType: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ModalResources)