
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Grid, Container, } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import AccountForm from './AccountForm'

import ButtonModel from '../../core/model/ButtonModel'
import AccountModel from '../../models/AccountModel'

class AccountUpdate extends MyPage {
    constructor(props) {
        super(props)
        let tempModel = null
        if (!!props.accountInfo && !!props.accountInfo.id) {
            tempModel = new AccountModel(props.accountInfo)
        }
        this.state.model = tempModel
        this.state.model.formType = "CHANGE_INFO"
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && (!!nextProps.accountInfo && !!nextProps.accountInfo.id)) {
            tempState.model = new AccountModel(nextProps.accountInfo)
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onUpdate(this.state.model)
    }

    onCancel = () => {
        this.props.history.goBack()
    }


    render() {
        try {
            const { t, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state
            
            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t("common.save"),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('account.updateAccount')} buttons={buttons}>
                        <Container text>
                            <Grid>
                                <Grid.Column width={16}>
                                    <AccountForm
                                        model={model}
                                        errors={modelErrors}
                                        handleChange={this.handleChange}
                                        onSubmitForm={() => this.onValidate(this.onValidateSuccess)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

AccountUpdate.defaultProps = {
    accountInfo: null,
    onUpdate: () => console.error("Please provide Update action.")
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(AccountUpdate)