
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ADIntegrationSchema = new Schema({
	givenName: { type: String, default: "" },
	sn: { type: String, default: "" },
	sAMAccountName: { type: String, default: "" },
	mail: { type: String, default: "" },
	userPrincipalName: { type: String, default: "" }
}, { _id: false })

setBaseSchema(ADIntegrationSchema, "adIntegration")


ADIntegrationSchema.methods.serializeAPI = function () {
	try {
		let result = {

		}

		return result

	} catch (error) {
		return null
	}
}

const ADIntegrationModel = mongoose.model("adIntegration", ADIntegrationSchema, "adIntegration")

export default ADIntegrationModel