import React from 'react'
import moment from 'moment'
import { translate } from 'react-switch-lang'
import { Button, Icon } from 'semantic-ui-react'
import MyComponent from '../../components/MyComponent'
import Webcam from "react-webcam"

import '../../scss/viewSelectImageOrTakePhoto.scss'
import ButtonModel from '../../model/ButtonModel'
import { CORE_API_ENDPOINT, CORE_SERVICE_ENDPOINT } from '../../../lib/api'
import { dateTimeFormat } from '../../../../constant'
// import FileBase64 from "react-file-base64";

const facingMode = {
    user: "user",
    environment: "environment"
},
    fileTypes = ["image/png", "image/jpeg", "image/jpg"]
    , timeGap = 1000

class ViewSelectImageOrTakePhoto extends MyComponent {

    constructor(props) {
        super(props)
        this.state.heightBoxCamera = null
        this.state.facingModeCamera = facingMode.environment
        this.state.imageBase64FromTakePhoto = null

        this.state.isOpenModalTakePhoto = false

        this.state.isLoadingSelectFile = false
        this.state.isLoadingTakePhotoFile = false

        this.state.imageBase64 = null
        this.state.imageData = null

        this.state.timeStamp = moment().format(dateTimeFormat.apiFullDateTime)

        this.webcamRef = React.createRef()
        this.inputRef = React.createRef()
    }

    getConfigWebCam = (heightBoxCamera, facingModeCamera) => {
        let config = {
            audio: false,
            mirrored: true,
            screenshotFormat: "image/jpeg",
            width: 135 * 2,
            height: 189 * 2,
            videoConstraints: {
                facingMode: facingModeCamera,
                width: 135 * 2,
                height: 189 * 2
            },

        }
        // if (!!heightBoxCamera && heightBoxCamera > -1 && heightBoxCamera > -1) {
        //     config.height = heightBoxCamera
        // }
        return config
    }

    onToggleFacingMode = () => {
        const { facingModeCamera } = this.state
        let newMode = facingModeCamera
        if (facingModeCamera === facingMode.environment) {
            newMode = facingMode.user
        } else {
            newMode = facingMode.environment
        }
        this.setState({
            facingModeCamera: newMode
        })
    }

    onTakePhoto = async () => {
        var image = await this.webcamRef.current.getScreenshot()
        this.setState({
            imageBase64FromTakePhoto: image
        })
    }

    onReTakePhoto = () => {
        this.setState({
            imageBase64FromTakePhoto: null
        })
    }

    onSaveTakePhoto = async () => {

        this.setState({
            isOpenModalTakePhoto: false,
            isLoadingTakePhotoFile: true
            // imageBase64FromTakePhoto: null,
            // imageBase64: this.state.imageBase64FromTakePhoto
        }, async () => {
            let body = {
                isLoadingTakePhotoFile: false,
                imageBase64FromTakePhoto: null
            },
                action = () => { }

            let data = null
            try {
                // convert base64 to raw binary data held in a string
                // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
                var byteString = atob(this.state.imageBase64FromTakePhoto.split(',')[1]);

                // separate out the mime component
                var mimeString = this.state.imageBase64FromTakePhoto.split(',')[0].split(':')[1].split(';')[0]

                // write the bytes of the string to an ArrayBuffer
                var ab = new ArrayBuffer(byteString.length);
                var ia = new Uint8Array(ab);
                for (var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }

                // write the ArrayBuffer to a blob, and you're done
                var file = new Blob([ab], { type: mimeString });

                data = await new Promise((reslove, reject) => {
                    try {
                        let type = file.type
                            , extension = type.split('/')[1]
                        // Make new FileReader
                        let reader = new FileReader();

                        // Convert the file to base64 text
                        reader.readAsDataURL(file);

                        // on reader load somthing...
                        reader.onload = () => {
                            try {
                                // Make a fileInfo Object
                                let contentBase64 = reader.result

                                let fileInfo = {
                                    fileName: "image_take_photo." + extension,
                                    mimeType: type,
                                    size: file.size,
                                    content: contentBase64,
                                    extension: extension
                                };

                                setTimeout(() => {
                                    return reslove(fileInfo)
                                }, timeGap)
                            } catch (error) {
                                return reject(error)
                            }

                        } // reader.onload

                    } catch (error) {
                        return reject(error)
                    }
                })
            } catch (error) {

            }

            if (data) {
                body.imageBase64 = data.content
                body.imageData = data
                action = () => this.props.onChange(null, { name: this.props.name, value: data })
            }

            this.setState(body, action)
        })
    }

    getOptionModalTakePhoto = (facingModeCamera, imageBase64FromTakePhoto) => {
        // const { t } = this.props
        let actions = []
        // [
        //     new ButtonModel({
        //         text: "",
        //         iconClass: "camera",
        //         color: "violet"
        //     }),
        //     new ButtonModel({
        //         text: "",
        //         iconClass: "retweet",
        //         color: "purple",
        //         onClick: this.onToggleFacingMode
        //     }),
        // ]
        if (!!imageBase64FromTakePhoto) {
            actions = [
                new ButtonModel({
                    text: "",
                    iconClass: "check",
                    color: "green",
                    onClick: this.onSaveTakePhoto
                }),
                new ButtonModel({
                    text: "",
                    iconClass: "redo",
                    color: "grey",
                    onClick: this.onReTakePhoto
                }),
            ]
        } else {
            actions = [
                new ButtonModel({
                    text: "",
                    iconClass: "camera",
                    color: "violet",
                    onClick: this.onTakePhoto
                }),
                new ButtonModel({
                    text: "",
                    iconClass: "retweet",
                    color: "purple",
                    onClick: this.onToggleFacingMode
                }),
            ]
        }
        actions = actions.concat([
            new ButtonModel({
                text: "",
                iconClass: "close",
                color: "red",
                onClick: this.onCloseModalTakePhoto
            })
        ])
        return this.getInfoModalOption({
            onClose: this.onCloseModalTakePhoto,
            headerText: "Take Photo",
            content: <div className="module modalTakePhoto">
                <div className="mtp-wrapper">
                    <div className={`box-webcam ${!imageBase64FromTakePhoto ? "open" : "hide"}`}>
                        <Webcam
                            {...this.getConfigWebCam(null, facingModeCamera)}
                            ref={this.webcamRef}
                        />
                    </div>
                    {
                        !!imageBase64FromTakePhoto &&
                        <div className="box-preview">
                            <img src={imageBase64FromTakePhoto} alt="No Data" />
                        </div>
                    }
                </div>
            </div>,
            buttons: actions
        })
    }

    onCloseModalTakePhoto = () => {
        this.setState({
            isOpenModalTakePhoto: false
        })
    }

    onOpenModalTakePhoto = () => {
        this.setState({
            isOpenModalTakePhoto: true
        })
    }

    onOpenBrowseFile = () => {
        this.inputRef.current.click();
    }

    handleChangeImage = (e) => {
        e.persist();

        this.setState({
            isLoadingSelectFile: true
        }, async () => {
            // get the files

            let datas = null

            try {
                let files = e.target.files;
                datas = await new Promise((reslove, reject) => {
                    try {
                        var allFiles = []
                        for (var i = 0; i < files.length; i++) {

                            let file = files[i]
                                , type = file.type
                                , extension = type.split('/')[1]

                            // Make new FileReader
                            let reader = new FileReader();

                            // Convert the file to base64 text
                            reader.readAsDataURL(file);

                            // on reader load somthing...
                            reader.onload = () => {
                                try {
                                    // Make a fileInfo Object
                                    let contentBase64 = reader.result

                                    let fileInfo = {
                                        fileName: file.name,
                                        mimeType: type,
                                        size: file.size,
                                        content: contentBase64,
                                        extension: extension
                                    };

                                    // Push it to the state
                                    allFiles.push(fileInfo);
                                    if (allFiles.length === files.length) {
                                        setTimeout(() => {
                                            return reslove(allFiles)
                                        }, timeGap)
                                    }
                                } catch (error) {
                                    return reject(error)
                                }

                            } // reader.onload

                        } // for

                    } catch (error) {
                        return reject(error)
                    }
                })
            } catch (error) {

            }


            let body = {
                isLoadingSelectFile: false
            },
                action = () => { }

            if (Array.isArray(datas) && datas.length > 0) {
                let fileInfo = datas[0]
                    , fileType = fileInfo.mimeType
                if (fileTypes.includes(fileType)) {
                    body.imageBase64 = fileInfo.content
                    body.imageData = fileInfo
                    action = () => this.props.onChange(null, { name: this.props.name, value: fileInfo })
                }
            }

            this.setState(body, action)
        })


    }

    render() {
        const { t, defaultImage, serviceDefaultImage, isUploadPhoto, isTakePhoto } = this.props
        const { imageBase64, isOpenModalTakePhoto, facingModeCamera, imageBase64FromTakePhoto, isLoadingSelectFile, isLoadingTakePhotoFile, timeStamp } = this.state

        return (
            <React.Fragment>
                {/* {super.render()} */}
                {isOpenModalTakePhoto && this.getModalContent(this.getOptionModalTakePhoto(facingModeCamera, imageBase64FromTakePhoto))}
                <div className="module viewSelectImageOrTakePhoto">
                    <div className="vsiotp-wrapper">
                        <div className="box-btn-action">
                            {
                                isUploadPhoto &&
                                <Button color="teal" onClick={this.onOpenBrowseFile} loading={isLoadingSelectFile} disabled={isLoadingSelectFile}>
                                    <Icon name="folder open" />{t("common.uploadPhoto")}
                                </Button>
                            }
                            {
                                isTakePhoto &&
                                <Button color="violet" onClick={this.onOpenModalTakePhoto} loading={isLoadingTakePhotoFile} disabled={isLoadingTakePhotoFile}>
                                    <Icon name="camera" /> {t("common.takePhoto") + " 5:9"}
                                </Button>
                            }
                        </div>
                        <div className="box-input-file">
                            <input ref={this.inputRef} type="file" accept={fileTypes.join(", ")} onChange={this.handleChangeImage} />
                        </div>
                        {
                            (!!imageBase64 || !!defaultImage || !!serviceDefaultImage) &&
                            <div className="box-show-image">
                                <div className="image-render">
                                    <img src={imageBase64 ? imageBase64 : !!defaultImage ? `${CORE_API_ENDPOINT}/image/${defaultImage}?time=${timeStamp}` : !!serviceDefaultImage ? `${CORE_SERVICE_ENDPOINT}${serviceDefaultImage}?time=${timeStamp}` : ""} alt="No Data" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ViewSelectImageOrTakePhoto.defaultProps = {
    name: "",
    defaultImage: "",
    serviceDefaultImage: "",
    isUploadPhoto: true,
    isTakePhoto: true,
    onChange: () => console.error("Please provide On Change Image action.")
}


export default translate(ViewSelectImageOrTakePhoto)