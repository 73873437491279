
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import utils from '../core/utility/utils'

const Schema = mongoose.Schema

const LoginSchema = new Schema({
	systemId: { type: String, required: false, default: "" },
	service: { type: String, required: false, default: "" },
	username: { type: String, required: [true, "validate.require"], default: "" },
	password: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(LoginSchema, "login")

LoginSchema.methods.serializeAPI = function () {
	try {
		let result = {
			username: this.username,
			password: utils.encode(this.password),
		}
		return result
	} catch (error) {
		return null
	}
}
LoginSchema.methods.serializeAPIWidthSystem = function () {
	try {
		let result = this.serializeAPI()
		result.systemId = this.systemId
		return result
	} catch (error) {
		return null
	}
}

const LoginModel = mongoose.model("login", LoginSchema, "login")

export default LoginModel