
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormDepartment from './FormDepartment'

import ButtonModel from '../../core/model/ButtonModel'
import DepartmentModel from '../../models/DepartmentModel'

class ViewCreateDepartment extends MyPage {
    constructor(props) {
        super(props)

        this.state.isLoading = false
        this.state.model = new DepartmentModel()
        this.state.modelErrors = []
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onCreate(this.state.model)
    }

    onCancel = () => {
        this.props.history.push(`/department`)
    }

    render() {
        try {
            const { t, isLoadingControl } = this.props
            const { isLoading, model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t("common.create"),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t("department.createDepartment")} buttons={buttons}>
                        <div className="module viewCreateDepartment">
                            <Container text>
                                <Grid>
                                    {
                                        model &&
                                        <Grid.Column width={16}>
                                            <FormDepartment
                                                model={model}
                                                errors={modelErrors}
                                                handleChange={this.handleChange}
                                            />
                                        </Grid.Column>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewCreateDepartment.defaultProps = {
    isLoadingControl: false,
    onCreate: () => console.error("Please provide Create action.")
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(ViewCreateDepartment)