
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router-dom'

import MyPage from '../../core/components/MyPage'
import LayoutBlank from '../layout/LayoutBlank'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import config from '../../config'

class ViewResetPassword extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = true
        this.state.isResetSuccess = false
        this.state.code = this.getQueryFromProps("code")
    }

    componentDidMount() {
        if (!!this.state.code) {
            this.onResetPassword(this.state.code)
        } else {
            this.setState({
                isLoading: false,
                isResetSuccess: false
            })
        }
    }

    onResetPassword = (code) => {
        this.setState({
            isLoading: true
        }, async () => {
            try {
                let res = await this.props.onResetPassword({ code: code })

                let tempState = {
                    isLoading: false,
                    isResetSuccess: false,
                },
                    action = () => { }

                if (!!res && res.result === true) {
                    tempState.isResetSuccess = true
                } else {
                    tempState.isResetSuccess = false
                }
                this.setState(tempState, action)
            } catch (error) {
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    getModalFail = () => {
        const { t } = this.props
        return this.getModalContent(this.getErrorModalOption({
            headerText: t("common.system"),
            content: <p>{t("message.changePasswordError")}</p>,
            buttons: [
                new ButtonModel(
                    {
                        text: t("common.close"),
                        color: "grey",
                        onClick: () => {
                            this.setState({
                                isOpenModalFail: false
                            })
                        },
                    }
                )
            ]
        }))
    }

    goToLogin = () => {
        this.props.history.replace('/login')
    }

    render() {
        try {
            const { t } = this.props
            const { isLoading, isResetSuccess } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {/* {isOpenModalFail && this.getModalFail()} */}
                    <LayoutBlank>
                        <div className="module viewResetPassword">
                            <div className="vl-background" >
                                <img src={`${config.publicUrl}/assets/images/image-forest_fog_trees.jpg`} alt="No Background" />
                            </div>
                            <div className="vl-wrapper">
                                <h1 className="text-header-login">{t("auth.resetPassword")}</h1>
                                <div className="vl-form">
                                    {
                                        isLoading ?
                                            this.getLoadingComponent()
                                            :
                                            <h2 style={{ color: `${isResetSuccess ? "#0f0" : "#f00"}` }}>{t(`auth.${isResetSuccess ? "resetPasswordSuccess" : "resetPasswordFail"}`)}</h2>
                                    }
                                </div>
                                <div className="vl-action">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("auth.goToLogin"),
                                            color: "blue",
                                            onClick: this.goToLogin
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </LayoutBlank>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewResetPassword.defaultProps = {
    onResetPassword: () => {
        console.error("Please provide Change Password action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewResetPassword)