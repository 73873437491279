
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const airQualityStandardInfoSchema = new Schema({
	sensorType: { type: String, default: "" },
	operation: { type: String, default: "" },
	value: { type: Number, default: "" },
}, { _id: false })

const sensorInfoSchema = new Schema({
	airQualityStandardInfo: [{ type: airQualityStandardInfoSchema }]
}, { _id: false })

const SettingSensorSchema = new Schema({
	sensorInfo: { type: sensorInfoSchema },
}, { _id: false })

setBaseSchema(SettingSensorSchema, "06")

SettingSensorSchema.methods.serializeAPI = function () {

	let sensorInfo = {
		airQualityStandardInfo: this.sensorInfo.airQualityStandardInfo
	}

	return {
		sensorInfo: sensorInfo
	}
}


const SettingSensorModel = mongoose.model("settingSensor", SettingSensorSchema, "settingSensor")

export default SettingSensorModel