
import types from '../types'
import { requestStatus } from '../../constant'
import AccountResource from '../selectors/accountResource'
import IntegrationResource from '../selectors/integrationResource'

const accountReducer = {
	accountInfo: (state = null, action) => {
		if (!state || !(state instanceof AccountResource)) {
			state = new AccountResource()
		}

		switch (action.type) {
			case types.GET_ACCOUNT_INFO_REQUEST:
				state = new AccountResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ACCOUNT_INFO_SUCCESS:
				state = new AccountResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ACCOUNT_INFO_FAILURE:
				state = new AccountResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	accountIntegrationList: (state = null, action) => {
		if (!state || !(state instanceof IntegrationResource)) {
			state = new IntegrationResource()
		}

		switch (action.type) {
			case types.GET_ACCOUNT_INTEGRATION_LIST_REQUEST:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ACCOUNT_INTEGRATION_LIST_SUCCESS:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ACCOUNT_INTEGRATION_LIST_FAILURE:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default accountReducer