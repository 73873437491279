
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import VisitorView from '../../views/visitor/VisitorView'
import { getVisitorInfo } from '../../actions/visitorActions'
import utils from '../../core/utility/utils'
import VisitorManager from '../../business/VisitorManager'

class ViewVisitorController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bVisitor = new VisitorManager(this)
    }

    getPropsResetStatus() {
		return ["visitorInfo"]
	}

    onUnintegtationUser = (model, serviceInfo) => {
         this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.unintegrateUser(model.id, serviceInfo.service, (res) => {
                
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getUserInfo(model.id) }

                this.setState(tempState, action)
            })
        }) 
    }

    testSyncPlatformVisitor = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bVisitor.visitorTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { visitorInfo } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

            const visitor = visitorInfo.getData()

            return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<VisitorView
						model={visitor}
						onUnintegtationUser={this.onUnintegtationUser}
                        testSyncPlatformVisitor={this.testSyncPlatformVisitor}
					/>
				</React.Fragment>
			)

        } catch(error) {
            return this.renderErrorComponent(error)
        }
    }
}

const enhance = compose(
	translate,
	connect(
		(state, props) => ({
			visitorInfo: state.visitorInfo
		}),
		(dispatch, props) => ({
			getVisitorInfo() {
				dispatch(getVisitorInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getVisitorInfo()
		}
	}),
)


export default enhance(ViewVisitorController) 