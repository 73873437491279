
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const Locationchema = new Schema({
	type: { type: String, default: null },
	id: { type: String, default: null },
	code: { type: String, default: null },
	name: { type: String, default: null }
}, { _id: false })

const SensorSchema = new Schema({
	id: { type: String, default: null },
	serials: [{ type: String }],
	configValue: { type: Object, default: null },
	description: { type: String, default: null },
	moduleCode: { type: String, default: "" },
	sensorType: { type: String, default: "" },
	sensorModel: { type: String, default: "" },
	sensorSN: { type: String, default: "" },
	locationId: { type: String, default: null },
	location: { type: Locationchema, default: null },
	controllerId: { type: String, default: null },
	controller: { type: Object, default: null },
	isDisable: { type: Boolean, default: false }
}, { _id: false })

setBaseSchema(SensorSchema, "06")

SensorSchema.path("controllerId").validate(async function (controllerId) {
	return !!controllerId
}, "validate.require")

SensorSchema.path("moduleCode").validate(async function (moduleCode) {
	return !!moduleCode
}, "validate.require")

SensorSchema.path("sensorType").validate(async function (sensorType) {
	return !!sensorType
}, "validate.require")

SensorSchema.path("sensorModel").validate(async function (sensorModel) {
	return !!sensorModel
}, "validate.require")

SensorSchema.path("serials").validate(async function (serials) {
	return (Array.isArray(serials) && serials.length > 0)
}, "validate.require")

SensorSchema.path("configValue").validate(async function (configValue) {
	if(this.moduleCode === "RS485") {
		return !!configValue
	}
	return true
}, "validate.require")

SensorSchema.path("location.type").validate(async function (location) {
	return !!location
}, "validate.require")

SensorSchema.path("location.id").validate(async function (location) {
	return !!location
}, "validate.require")

SensorSchema.methods.serializeAPI = function () {
	try {
		let result = {
			controllerId: this.controllerId,
			moduleCode: this.moduleCode,
			sensorType: this.sensorType,
			sensorModel: this.sensorModel,
			sensorSN: this.sensorSN,
			location: this.location,
			serials: this.serials,
			configValue: this.configValue,
			description: this.description
		}

		return result

	} catch (error) {
		return null
	}
}

const SensorModel = mongoose.model("sensor", SensorSchema, "sensor")

export default SensorModel