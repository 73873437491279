
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
// import MyController from '../../core/components/MyController'
import { getDoorInfo, getDoorWithIOTConfigInfo, getDoorSceneInfo } from '../../actions/doorActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import DoorManager from '../../business/DoorManager'
import DoorView from '../../views/door/DoorView'
import ControlManager from '../../business/ControlManager'
import { eventSocket } from '../../../constant'
import SocketController from '../SocketController'

class ViewDoorController extends SocketController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false

		this.state.model = null
		this.state.isRegisSubscribe = false
		this.state.doorIOTInfoData = null

		this.bDoor = new DoorManager(this)
		this.bControl = new ControlManager(this)
	}

	getPropsResetStatus() {
		return ["doorInfo", "doorWithIOTConfigInfo", "doorSceneInfo", "locationList"]
	}

	componentDidMount() {
		this.onConnectSocket((socket) => {
			console.log(this.isConnectSocket())
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		if (nextProps.doorWithIOTConfigInfo.requestFinishedAfterRequest()) {
			tempState.doorIOTInfoData = nextProps.doorWithIOTConfigInfo.getData()
		}
		if (nextProps.doorInfo.requestFinishedAfterRequest()) {
			tempState.model = nextProps.doorInfo.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		const { isRegisSubscribe, model, doorIOTInfoData } = this.state
		if (!isRegisSubscribe && !!model && !!doorIOTInfoData) {
			// console.log("Start Socket", model, doorIOTInfoData)
			this.setState({
				isRegisSubscribe: true
			}, () => { this.onRegisterSubscribeIOTConfig(model, doorIOTInfoData) })
		}
	}

	componentWillUnmount() {
		this.onDisconnectSocket()
	}

	//For socket
	onRegisterSubscribeIOTConfig = (room, doorIOTInfoData) => {
		let roomId = room.id
			, iotConfigIds = []
		if (Array.isArray(doorIOTInfoData.iotConfigs) && doorIOTInfoData.iotConfigs.length > 0) {
			iotConfigIds = doorIOTInfoData.iotConfigs.map(config => config.id)
		}
		if (!!roomId && iotConfigIds.length > 0 && this.isConnectSocket()) {
			this.state.socket.emit(eventSocket.REGISTER, { id: roomId, iotConfigIds: iotConfigIds })
			this.state.socket.on(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, this.onReceiveResponseStatusChange)
		}
	}

	onReceiveResponseStatusChange = (res) => {
		this.afterReceiveSocket(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, res, this.onStatusChange)
	}

	onStatusChange = (event, res) => {
		if (!!res && !!res.data) {
			const { model, doorIOTInfoData } = this.state
			let data = res.data
			if (data.id === model.id && Array.isArray(data.result) && data.result.length > 0) {
				let tempDoorIOTInfoData = doorIOTInfoData
					, iotConfigs = tempDoorIOTInfoData.iotConfigs
				if (Array.isArray(iotConfigs) && iotConfigs.length > 0) {
					let isChange = false
					data.result.forEach(change => {
						let index = iotConfigs.findIndex(iot => iot.id === change.id)
						if (index > -1) {
							isChange = true
							let oldData = iotConfigs[index]
							oldData.status = change.status
							iotConfigs[index] = oldData
						}
					})
					if (isChange) {
						tempDoorIOTInfoData.iotConfigs = iotConfigs
						this.setState({
							doorIOTInfoData: tempDoorIOTInfoData
						})
					}
				}
			}
		}
	}

	onDeleteAreaDoor = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.deleteAreaDoor(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDoorInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaDoor = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.upsertAreaDoor(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDoorInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationDoor = (model, serviceInfo) => {
		// console.log("Model ID: ", model.id)
		// console.log("Service: ", serviceInfo.service)

		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.unintegrateDoor(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDoorInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onDeleteSceneDoor = (doorId, sceneId) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.deleteDoorScene(doorId, sceneId, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDoorSceneInfo(doorId) }

				this.setState(tempState, action)
			})
		})
	}

	onCreateSceneDoor = (id, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.createDoorScene(id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDoorSceneInfo(id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUpdateSceneDoor = (doorId, sceneId, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDoor.updateDoorScene(doorId, sceneId, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDoorSceneInfo(doorId)
				}

				this.setState(tempState, action)
			})
		})
	}

	onControlStatus = (doorId, iotId, status) => {
		// console.log("status: ", status)
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.controlStatus(iotId, status, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDoorWithIOTConfigInfo(doorId)
				}

				this.setState(tempState, action)
			})
		})
	}

	turnOnSceneDoor = (sceneID) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.turnOnSceneResource(sceneID, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDeskWithIOTConfigInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	testSyncPlatformDoor = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bDoor.doorTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { doorInfo, doorSceneInfo, locationList, isLoadingComponent } = this.props //doorWithIOTConfigInfo,
			const { doorIOTInfoData } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const door = doorInfo.getData()
				// , doorIOTInfoData = doorWithIOTConfigInfo.getData()
				, doorSceneInfoData = doorSceneInfo.getDatas()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let sortedDoorSceneInfoData = doorSceneInfoData.sort((a, b) => { return a.code.localeCompare(b.code) })

			let locationInfo = null

			if (!!door) {
				if (!!door.locationId) {
					locationInfo = locationList.findDataByKey("id", door.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<DoorView
							model={door}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaDoor={this.onDeleteAreaDoor}
							onUpsertAreaDoor={this.onUpsertAreaDoor}
							onUnintegtationDoor={this.onUnintegtationDoor}
							onCreateSceneDoor={this.onCreateSceneDoor}
							onUpdateSceneDoor={this.onUpdateSceneDoor}
							onDeleteSceneDoor={this.onDeleteSceneDoor}
							onControlStatus={this.onControlStatus}
							turnOnSceneDoor={this.turnOnSceneDoor}
							locationList={locationDatas}
							doorIOTInfo={doorIOTInfoData}
							doorSceneInfo={sortedDoorSceneInfoData}
							testSyncPlatformDoor={this.testSyncPlatformDoor}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			doorInfo: state.doorInfo,
			doorWithIOTConfigInfo: state.doorWithIOTConfigInfo,
			doorSceneInfo: state.doorSceneInfo,
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getDoorInfo() {
				dispatch(getDoorInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getDoorWithIOTConfigInfo() {
				dispatch(getDoorWithIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getDoorSceneInfo() {
				dispatch(getDoorSceneInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getDoorInfo()
			this.props.getDoorWithIOTConfigInfo()
			this.props.getDoorSceneInfo()
		}
	}),
)


export default enhance(ViewDoorController)