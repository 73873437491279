
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormDepartment from './FormDepartment'
import ButtonModel from '../../core/model/ButtonModel'
import DepartmentModel from '../../models/DepartmentModel'

class ViewUpdateDepartment extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.department
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && (!!nextProps.department && !!nextProps.department.id)) {
            tempState.model = new DepartmentModel(nextProps.department)
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onUpdate(this.state.model)
    }

    onView = (model) => {
        this.props.history.push(`/department/view/${model.id}`)
    }

    onCancel = () => {
        this.props.history.push(`/department`)
        //this.props.history.goBack()
    }

    render() {
        try {
            const { t, isLoadingControl } = this.props
            const { isLoading, model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons= [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t("common.save"),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t("department.updateDepartment")} buttons={buttons}>
                        <div className="module viewUpdateDepartment">
                            <Container text>
                                <Grid>
                                    {
                                        !!model &&
                                        <Grid.Column width={16}>
                                            <FormDepartment
                                                model={model}
                                                errors={modelErrors}
                                                handleChange={this.handleChange}
                                            />
                                        </Grid.Column>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewUpdateDepartment.defaultProps = {
    isLoadingControl: false,
    onUpdate: () => console.error("Please provide Update action.")
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(ViewUpdateDepartment)