
import MyComponent from './MyComponent'

export default class MyForm extends MyComponent {

    constructor(props) {
        super(props)

        this.selfHandleForm = false
    }

    onValidate = async (callback) => {
        try {
            let model = this.state.model
            if (await model.validateError()) {
                this.setState({
                    modelErrors: []
                }, callback)
            } else {
                this.setState({
                    modelErrors: model.getErrors()
                })
            }
        } catch (error) {
            console.error("On Validate Error: " + error.message)
            return false
        }
    }

    handleChange = (e, { name, value }) => {
        try {
            if (this.selfHandleForm) {
                let model = this.state.model
                // if (model && name in model) {
                //     model[name] = value
                //     this.setState({ model })
                // } else if (name in this.state) {
                //     let state = this.state
                //     state[name] = value
                //     this.setState(state)
                // }
                let tempState = {}
                if (model) {

                    let names = name.split(".")
                    if (names.length === 1) {
                        model[name] = value
                    } else if (names.length > 1) {
                        this.changeDataModel(names, model, value)
                    }
                    tempState.model = model
                } else if (name in this.state) {
                    tempState[name] = value
                }
                this.setState(tempState)
            } else if (this.props.handleChange && typeof this.props.handleChange === "function") {
                this.props.handleChange(e, { name, value })
            } else {
                console.error("Please provide \"Handle Change\" action")
            }
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    handleChangeBoolean = (e, { name, value, checked }) => {
        try {
            this.handleChange(e, { name, value: typeof value === "boolean" ? checked : value })
        } catch (error) {
            console.error("Handle Change Boolean Error: " + error.message)
        }
    }

    handleChangeArray = (e, { name, value, checked }) => {
        try {
            let model = this.props.model
            if (!model) {
                throw new Error("Please provide model for this form")
            }

            let newValue = Array.isArray(model[name]) ? model[name] : []

            if (!!checked) {
                newValue.push(value)
            } else if (!checked && !!value && newValue.includes(value)) {
                newValue.splice(newValue.indexOf(value), 1)
            }

            this.handleChange(e, { name, value: newValue })
        } catch (error) {
            console.error("Handle Change Array Error: " + error.message)
        }
    }

    getErrorInput = (name, errors) => {
        try {
            const { t } = this.props
            let data = {
                isError: false,
                content: null
            }
            if (Array.isArray(errors) && errors.length > 0 && !!name) {
                let error = errors.find(e => e.path === name)
                if (error) {
                    data.isError = true
                    data.content = typeof t === "function" ? t(error.message) : error.message
                }
            }
            return data
        } catch (error) {
            return {
                isError: true,
                content: error.message
            }
        }
    }

    onKeyDownSubmitForm = (event) => {
        if (event.shiftKey && event.key === 'Enter') {
            return false
        }

        if (event.keyCode === 13) {
            event.preventDefault();
            if (this.selfHandleForm) {
                if (typeof this.onSubmitForm === "function") {
                    this.onSubmitForm()
                }
            } else {
                if (typeof this.props.onSubmitForm === "function") {
                    this.props.onSubmitForm()
                }
            }
        }
    }
}