
import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Button, Icon, Header, Grid, Modal } from 'semantic-ui-react'
// import { Button, Icon, Portal, Segment, Header, Grid } from 'semantic-ui-react'
import MyController from '../../core/components/MyController'
import ViewImage from '../common/ViewImage'
import SwitchLanguage from './SwitchLanguage'

import AuthManager from '../../business/AuthManager'
import CookiesManager from '../../core/business/CookiesManager'
import { getAccountInfo } from '../../actions/accountActions'
import { CORE_API_ENDPOINT } from '../../lib'
import { enableExternalService, cookiesKey } from '../../../constant'
import { version } from '../../../../package.json'
import config from '../../config'

var menuActiveId = null;
class ViewHome extends MyController {

    constructor(props) {
        super(props)
        this.state.activeMenu = this.getActiveMenu(props)

        this.bAuth = new AuthManager(this)
        this.bCookie = new CookiesManager(this)

        this.state.aboutIsActive = false
    }

    getUsername = () => {
        try {
            const { accountInfo } = this.props

            const accountInfoData = accountInfo.getData()

            return accountInfoData.displayName
        } catch (error) {
            return ""
        }
    }

    getActiveMenu = (props) => {
        try {
            let active = ""
            let currentMainPath = []
            if (!!props && !!props.match) {
                currentMainPath = props.match.path.match("/[^/]*")
                active = currentMainPath[0]

                if (currentMainPath[0] === "/hotdesk"
                    || currentMainPath[0] === "/roomminister"
                    || currentMainPath[0] === "/setting"
                    || currentMainPath[0] === "/report") {
                    currentMainPath = props.match.path.match(/\/\w+\/[^/]*/)
                    active = currentMainPath[0]
                }
            }
            return active
        } catch (error) {
            return null
        }
    }

    getContentMenu = (menu, indexParent) => {
        try {
            if (!!menu) {
                let isSubmenu = (!!menu.menus && Array.isArray(menu.menus) && menu.menus.length > 0)
                    , idItem = "item-" + indexParent
                    , idPanel = "panel-" + indexParent
                return <React.Fragment>
                    <div key={idItem} id={idItem} className={`menu-item ${isSubmenu ? "accordion" : ""} ${this.state.activeMenu === menu.path ? "active" : ""}`}>
                        <div className={`item-wrapper `} onClick={isSubmenu ? () => this.onClickAccordion(idItem, idPanel) : () => this.onClickMenuItem(idItem, menu.path, menu.onClick)}>
                            <div className="i-text">
                                <p>{menu.text}</p>
                            </div>
                            {
                                isSubmenu &&
                                <div className="i-icon">
                                    <Icon name="caret down" />
                                </div>
                            }
                        </div>
                    </div>
                    {
                        isSubmenu &&
                        <div key={idPanel} className={`menu-panel`} id={idPanel}>
                            <div className="panel-wrapper">
                                {this.generateMenus(menu.menus, indexParent)}
                            </div>
                        </div>
                    }
                </React.Fragment >
            } else {
                return null
            }

        } catch (error) {
            return null
        }
    }

    generateMenus = (menus, id = null) => {
        try {
            let menuComponent = null
            if (!!menus && Array.isArray(menus) && menus.length > 0) {
                menuComponent = <React.Fragment>
                    {
                        menus.map((menu, i) => {
                            if (menu.disable) {
                                return null
                            }

                            let idParent = id ? id + "-" + i : "menu-" + i
                            return this.getContentMenu(menu, idParent)
                        })
                    }
                </React.Fragment>
            }
            return menuComponent
        } catch (error) {
            return null
        }
    }

    onClickAccordion = (idItem, idPanel, scrollIntoView) => {
        let itemAccordion = document.getElementById(idItem)
            , itemPanel = document.getElementById(idPanel)
        if (!!itemAccordion) {
            // itemAccordion.classList.toggle("active");
            itemAccordion.classList.toggle("active-panel");
        }
        if (!!itemPanel) {
            if (itemPanel.style.maxHeight) {
                itemPanel.style.maxHeight = null;
            } else {
                itemPanel.style.maxHeight = itemPanel.scrollHeight + "px";
            }
        }

        if (scrollIntoView) {
            document.getElementById(idItem).scrollIntoView();
        }
    }

    onClickMenuItem = (idItem, path, callBack) => {
        if (!!path) {
            if (!!menuActiveId && menuActiveId !== idItem) {
                let itemOldActive = document.getElementById(menuActiveId)
                if (!!itemOldActive) {
                    itemOldActive.classList.toggle("active");
                }
            }

            let itemAcive = document.getElementById(idItem)
            if (!!itemAcive) {
                itemAcive.classList.toggle("active");
            }

            if (menuActiveId !== idItem) {
                menuActiveId = idItem
            } else {
                menuActiveId = null
            }
        }

        typeof callBack === "function" && callBack()
    }

    onClickLogOut = () => {
        this.bCookie.removeAll()
        // this.bAuth.redirectToFirstPage()
        this.props.history.replace(`/`)
    }

    onClickAbout = () => {
        this.setState({
            aboutIsActive: true
        })
    }

    onClickAboutClose = () => {
        this.setState({
            aboutIsActive: false
        })
    }

    onClickExternal = (externalServiceCode) => {
        let externalService = this.bCookie.getData(cookiesKey.EXTERNAL_SERVICES)
            , token = this.bCookie.getData(cookiesKey.TOKEN)
        let findService = externalService.find(data => !!data && data.code === externalServiceCode)

        if (!!findService) {
            window.open(findService.domain + `?bypass=${encodeURIComponent(token)}`, '_blank');
            //window.location.replace(findService.domain)
        }
    }

    componentDidMount() {
        if (!this.requestFinishedAfterRequest()) {
            this.props.getAccountInfo()
        }

        if (this.state.activeMenu === "/desk"
            || this.state.activeMenu === "/door"
            || this.state.activeMenu === "/room"
            || this.state.activeMenu === "/admin"
            || this.state.activeMenu === "/user"
            || this.state.activeMenu === "/resourcegroup"
            || this.state.activeMenu === "/department") {

            this.onClickAccordion("item-menu-0", "panel-menu-0", true)
        } else if (this.state.activeMenu === "/location"
            || this.state.activeMenu === "/locationtype") {

            this.onClickAccordion("item-menu-1", "panel-menu-1", true)
        } else if (this.state.activeMenu === "/visitor"
            || this.state.activeMenu === "/visitorgroup") {

            this.onClickAccordion("item-menu-2", "panel-menu-2", true)
        } else if (this.state.activeMenu === "/iotconfig"
            || this.state.activeMenu === "/controller"
            || this.state.activeMenu === "/gateway"
            || this.state.activeMenu === "/schedulecontrol") {

            this.onClickAccordion("item-menu-4", "panel-menu-4", true)
        } else if (this.state.activeMenu === "/sensor"
            || this.state.activeMenu === "/sensorrule") {
            this.onClickAccordion("item-menu-5", "panel-menu-5", true)
        } else if (this.state.activeMenu === "/report/airqualitydaily"
            || this.state.activeMenu === "/report/airqualityweekly"
            || this.state.activeMenu === "/report/airqualitymonthly") {
            this.onClickAccordion("item-menu-6", "panel-menu-6", true)
        } else if (this.state.activeMenu === "/eventcontrolrule"
            || this.state.activeMenu === "/eventcontrolstatus") {
            this.onClickAccordion("item-menu-8", "panel-menu-8", true)
        } else if (this.state.activeMenu === "/systemintegration"
            || this.state.activeMenu === "/notification") {
            this.onClickAccordion("item-menu-9", "panel-menu-9", true)
        } else if (this.state.activeMenu === "/setting/authorization"
            || this.state.activeMenu === "/setting/sensor") {
            this.onClickAccordion("item-menu-11", "panel-menu-11", true)
        }
    }

    render() {
        const { t, history } = this.props
        const isLoading = !this.requestFinishedAfterRequest()

        let externalService = this.bCookie.getData(cookiesKey.EXTERNAL_SERVICES)

        let externalServiceIsArray = false

        if (Array.isArray(externalService) && externalService.length > 0) {
            if (externalService.length === 1) {
                if (externalService[0].code === "SMART_CONTROL_BACKEND") {
                    externalServiceIsArray = false
                } else {
                    externalServiceIsArray = true
                }
            } else {
                externalServiceIsArray = true
            }
        }

        const menus = [
            {
                id: "resource",
                text: t('resource.title'),
                menus: [
                    {
                        id: "desk",
                        text: t('desk.title'),
                        path: "/desk",
                        onClick: () => {
                            history.push('/desk')
                        },
                        disable: false,
                    },
                    {
                        id: "door",
                        text: t('door.title'),
                        path: "/door",
                        onClick: () => {
                            history.push('/door')
                        },
                        disable: false,
                    },
                    {
                        id: "room",
                        text: t('room.title'),
                        path: "/room",
                        onClick: () => {
                            history.push('/room')
                        },
                        disable: false,
                    },
                    {
                        id: "admin",
                        text: t('admin.title'),
                        path: "/admin",
                        onClick: () => {
                            history.push('/admin')
                        },
                        disable: false,
                    },
                    {
                        id: "user",
                        text: t('user.title'),
                        path: "/user",
                        onClick: () => {
                            history.push('/user')
                        },
                        disable: false,
                    },
                    {
                        id: "resourcegroup",
                        text: t('resourceGroup.title'),
                        path: "/resourcegroup",
                        onClick: () => {
                            history.push('/resourcegroup')
                        },
                        disable: false,
                    },
                    {
                        id: "department",
                        text: t('department.title'),
                        path: "/department",
                        onClick: () => {
                            history.push('/department')
                        },
                        disable: false,
                    },
                ],
                disable: false,
            },
            {

                id: "location",
                text: t('location.title'),
                menus: [
                    {
                        id: "location",
                        text: t('location.title'),
                        path: "/location",
                        onClick: () => {
                            history.push('/location')
                        },
                        disable: false,
                    },
                    {
                        id: "locationtype",
                        text: t('locationType.title'),
                        path: "/locationtype",
                        onClick: () => {
                            history.push("/locationtype")
                        },
                        disable: false,
                    },
                ],
                disable: false,
            },
            {
                id: "visitor",
                text: t('visitor.title'),
                menus: [
                    {
                        id: "visitor",
                        text: t("visitor.title"),
                        path: "/visitor",
                        onClick: () => {
                            history.push('/visitor')
                        },
                        disable: false,
                    },
                    {
                        id: "visitorgroup",
                        text: t('visitorGroup.title'),
                        path: "/visitorgroup",
                        onClick: () => {
                            history.push('/visitorgroup')
                        },
                        disable: false,
                    },
                ]
            },
            {
                id: "rfidcard",
                text: t('rfidCard.title'),
                path: "/rfidcard",
                onClick: () => {
                    history.push('/rfidcard')
                },
                disable: false,
            },
            {
                id: "actuator",
                text: t('common.actuator'),
                menus: [
                    {
                        id: "iotconfig",
                        text: t('iotConfig.title'),
                        path: "/iotconfig",
                        onClick: () => {
                            history.push('/iotconfig')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false,
                    },
                    {
                        id: "controller",
                        text: t('controller.title'),
                        path: "/controller",
                        onClick: () => {
                            history.push('/controller')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false,
                    },
                    {
                        id: "gateway",
                        text: t('gateway.title'),
                        path: "/gateway",
                        onClick: () => {
                            history.push('/gateway')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false,
                    },
                    {
                        id: "schedulecontrol",
                        text: t('scheduleControl.title'),
                        path: "/schedulecontrol",
                        onClick: () => {
                            history.push('/schedulecontrol')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_SCHEDULE_CONTROL_SERVICE) === false,
                    },
                ],
                disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false && this.bCookie.getData(cookiesKey.IS_ENABLE_SCHEDULE_CONTROL_SERVICE) === false,
            },
            {
                id: "sensor",
                text: t('sensor.title'),
                menus: [
                    {
                        id: "sensor",
                        text: t('sensor.title'),
                        path: "/sensor",
                        onClick: () => {
                            history.push('/sensor')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false || this.bCookie.getData(cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE) === false,
                    },
                    {
                        id: "sensorRule",
                        text: t('sensorRule.title'),
                        path: "/sensorrule",
                        onClick: () => {
                            history.push('/sensorrule')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false || this.bCookie.getData(cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE) === false,
                    },
                ],
                disable: this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) === false || this.bCookie.getData(cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE) === false,
            },
            {

                id: "report",
                text: t('report.title'),
                menus: [
                    {
                        id: "report_airqualitydaily",
                        text: t('report.airQualityDaily'),
                        path: "/report/airqualitydaily",
                        onClick: () => {
                            history.push('/report/airqualitydaily')
                        },
                        disable: false,
                    },
                    {
                        id: "report_airqualityweekly",
                        text: t('report.airQualityWeekly'),
                        path: "/report/airqualityweekly",
                        onClick: () => {
                            history.push('/report/airqualityweekly')
                        },
                        disable: false,
                    },
                    {
                        id: "report_airqualitymonthly",
                        text: t('report.airQualityMonthly'),
                        path: "/report/airqualitymonthly",
                        onClick: () => {
                            history.push('/report/airqualitymonthly')
                        },
                        disable: false,
                    },
                ],
                disable: false,
            },
            {
                id: "holiday",
                text: t('holiday.title'),
                path: "/holiday",
                onClick: () => {
                    history.push('/holiday')
                },
                disable: false,
            },
            {
                id: "eventcontrol",
                text: t("common.eventControl"),
                menus: [
                    {
                        id: "eventControlRule",
                        text: t('eventControlRule.title'),
                        path: "/eventcontrolrule",
                        onClick: () => {
                            history.push('/eventcontrolrule')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_EVENT_CONTROL_SERVICE) === false
                    },
                    {
                        id: "eventControlStatus",
                        text: t('eventControlStatus.title'),
                        path: "/eventcontrolstatus",
                        onClick: () => {
                            history.push('/eventcontrolstatus')
                        },
                        disable: this.bCookie.getData(cookiesKey.IS_ENABLE_EVENT_CONTROL_SERVICE) === false
                    },
                ],
                disable: this.bCookie.getData(cookiesKey.IS_ENABLE_EVENT_CONTROL_SERVICE) === false,
            },
            /*             {
                            id: "role",
                            text: t('role.title'),
                            path: "/role",
                            onClick: () => {
                                history.push('/role')
                            },
                            disable: false,
                        }, */
            {
                id: "system",
                text: t("common.system"),
                menus: [
                    {
                        id: "systemintegration",
                        text: t('systemIntegration.title'),
                        path: "/systemintegration",
                        onClick: () => {
                            history.push("/systemintegration")
                        },
                        disable: false,
                    },
                    {
                        id: "notification",
                        text: t('notification.title'),
                        path: "/notification",
                        onClick: () => {
                            history.push('/notification')
                        },
                        disable: false,
                    },

                ]
            },
            {
                id: "service",
                text: t('common.service'),
                menus: [
                    {
                        id: "platformbackend",
                        text: t('common.platformbackend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.PLATFORM_BACKEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.PLATFORM_BACKEND) === false,
                    },
                    {
                        id: "platformfrontend",
                        text: t('common.platformfrontend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.PLATFORM_FRONTEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.PLATFORM_FRONTEND) === false,
                    },
                    {
                        id: "vmsbackend",
                        text: t('common.vmsbackend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.SMART_VMS_BACKEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.SMART_VMS_BACKEND) === false,
                    },
                    {
                        id: "vmsfrontend",
                        text: t('common.vmsfrontend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.SMART_VMS_FRONTEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.SMART_VMS_FRONTEND) === false,
                    },
                    {
                        id: "controlfrontend",
                        text: t('common.controlfrontend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.SMART_CONTROL_FRONTEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.SMART_CONTROL_FRONTEND) === false,
                    },
                    {
                        id: "lockerbackend",
                        text: t('common.lockerbackend'),
                        path: "",
                        onClick: () => {
                            this.onClickExternal(enableExternalService.SMART_LOCKER_BACKEND)
                        },
                        disable: !!externalService.find(data => !!data && data.code === enableExternalService.SMART_LOCKER_BACKEND) === false,
                    },
                ],
                disable: externalServiceIsArray === false
            },
            {
                id: "setting",
                text: t('setting.title'),
                menus: [
                    {
                        id: "authorization",
                        text: t('setting.authorization'),
                        path: "/setting/authorization",
                        onClick: () => {
                            history.push('/setting/authorization/0')
                        },
                        disable: false,
                    },
                    {
                        id: "settingSensor",
                        text: t('settingSensor.title'),
                        path: "/setting/sensor",
                        onClick: () => {
                            history.push('/setting/sensor')
                        },
                        disable: false,
                    },

                ],
                disable: false,
            },
            {
                id: "about",
                text: t('common.about'),
                path: "",
                onClick: () => {
                    this.onClickAbout()
                },
            },
        ]
        try {
            const { accountInfo } = this.props
            const { aboutIsActive } = this.state

            const accountInfoData = accountInfo.getData()

            let resourceTypeCodeChecker = ""

            if (!!accountInfoData) {
                resourceTypeCodeChecker = accountInfoData.resourceTypeCode
            }

            return (
                <React.Fragment>
                    <Modal
                        className="module aboutModal"
                        onClose={this.onClickAboutClose}
                        open={aboutIsActive}
                        size="tiny"
                    >
                        <Modal.Content className="module aboutModal insideContent">
                            <Grid columns={2}>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <img src={`${config.publicUrl}/assets/images/image-bainisys.png`} alt="No Login"></img>
                                    </Grid.Column>
                                    <Grid.Column width={7}>
                                        <Grid>
                                            <Grid.Row>
                                                <br></br>
                                                <Header>Smart Control (Backend)</Header>
                                                <div className="module labelInput">
                                                    <div className="label-input-wrapper">
                                                        <label>{t("common.version")}</label>: {`${version}`}
                                                    </div>
                                                </div>
                                            </Grid.Row>
                                        </Grid>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Content>
                    </Modal>

                    <div className="module sidebar">
                        <div className="sidebar-header">
                            <div className="header-image">
                                <div className="image-wrapper">
                                    <Link to="/">
                                        <img src={`${config.publicUrl}/assets/images/image-bainisys.png`} alt="No Login"></img>
                                    </Link>
                                </div>
                            </div>
                            <div className="header-language">
                                <SwitchLanguage />
                            </div>
                        </div>
                        <div className="sidebar-body">
                            <div className="body-wrapper">
                                <div className="menu-wrapper">
                                    {this.generateMenus(menus)}
                                </div>
                            </div>
                        </div>
                        {
                            !isLoading &&
                            <div className="sidebar-footer">
                                <div className="footer-wrapper">
                                    <Link to="/account" style={{ color: '#FFF' }} >
                                        <div className="f-user">
                                            <div className="user-wrapper">
                                                <div className="u-image">
                                                    <div className="image-wrapper">
                                                        {
                                                            (resourceTypeCodeChecker === "") &&
                                                            <p></p>
                                                        }
                                                        {
                                                            (resourceTypeCodeChecker === "USER") &&
                                                            <ViewImage imageUrl={`${CORE_API_ENDPOINT}/image/user/${accountInfoData.id}`}
                                                                isCircular={true}
                                                            />
                                                        }

                                                        {
                                                            (resourceTypeCodeChecker === "PROFILE_CENTER") &&
                                                            <ViewImage imageUrl={`${CORE_API_ENDPOINT}/image/admin/PROFILE_CENTER`}
                                                                isCircular={true}
                                                            />
                                                        }

                                                        {
                                                            (resourceTypeCodeChecker === "SUPER_ADMINISTRATOR") &&
                                                            <ViewImage imageUrl={`${CORE_API_ENDPOINT}/image/admin/SUPER_ADMINISTRATOR`}
                                                                isCircular={true}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="u-data">
                                                    <p>{this.getUsername()}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                    <div className="f-action">

                                    </div>
                                </div>
                            </div>
                        }
                        <div className="sidebar-action">
                            <Button color="red" onClick={this.onClickLogOut}>{t("common.logout")}</Button>
                        </div>
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            accountInfo: state.accountInfo
        }),
        (dispatch, props) => ({
            getAccountInfo() {
                dispatch(getAccountInfo())
            }
        }),
    ),
)

export default enhance(ViewHome)