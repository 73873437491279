
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const TimeSlotSchema = new Schema({
	startTime: { type: String, required: true, default: "00:00" },
	endTime: { type: String, required: true, default: "00:00" }
}, { _id: false })

setBaseSchema(TimeSlotSchema)

export default TimeSlotSchema
