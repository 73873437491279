
// import SensorRuleManager from '../business/SensorRuleManager'
import ActionResourceObject from '../core/redux/ActionResourceObject'
// import SensorRuleActionModel from '../models/SensorRuleActionModel'
import SensorRuleModel from '../models/SensorRuleModel'

export default class SensorRuleResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					let model = new SensorRuleModel()
					model.setData(d)
					return model
				})
			} else {
				result = new SensorRuleModel()
				result.setData(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

	setResourceOption(data) {
		try {

			if (!data) {
				throw new Error("Data not found")
			}

			let result = null

			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.name,
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.name,
				}
			}

			return result
		} catch (error) {
			return null
		}
	}

	// setModelData(data) {
	// 	try {
	// 		let bSensorRule = new SensorRuleManager()
	// 		let modelData = {
	// 			id: data.id,
	// 			code: data.code,
	// 			name: data.name,
	// 			description: data.description,
	// 			type: data.type,
	// 		}
	// 		if (!!data.conditionData) {
	// 			let ModalCondition = bSensorRule.getModelSensorRuleCondition(data.type)
	// 			if (!!ModalCondition) {
	// 				modelData.conditionData = new ModalCondition(data.conditionData)
	// 			}
	// 		}
	// 		if (Array.isArray(data.actions) && data.actions.length > 0) {
	// 			modelData.actions = data.actions.map(action => {
	// 				let model = new SensorRuleActionModel()
	// 				model.setData(action)
	// 				return model
	// 			})
	// 		}
	// 	} catch (error) {
	// 		return null
	// 	}
	// }
}