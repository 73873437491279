
import axios from 'axios'
import CookiesManager from './CookiesManager'
import { cookiesKey } from '../../../constant'
import { MIN_EXPIRE_TOKEN, CORE_API_ENDPOINT, afterRefreshToken } from '../../lib'
import moment from 'moment'

export default class MyManager {

	constructor(controller) {
		this.controller = controller

		this.requestMethod = {
			GET: "GET",
			POST: "POST",
			PATCH: "PATCH",
			DELETE: "DELETE",
		}

		this.bCookie = new CookiesManager()
	}

	async requestActionModel(model, options, callback) {
		try {
			if (await model.validateError()) {
				let res = await this.requestAction(options, callback)
				return res
			} else {
				this.setDefaultModalError(model.getErrors())
			}
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async requestAction(options, callback) {
		let isHandleError = true
			, isCheckExpireToken = true
		try {
			if (!options) {
				throw new Error("Invalid parameters")
			}

			if ("isHandleError" in options && typeof options.isHandleError === "boolean") {
				isHandleError = options.isHandleError
				delete options.isHandleError
			}

			if ("isCheckExpireToken" in options && typeof options.isCheckExpireToken === "boolean") {
				isCheckExpireToken = options.isCheckExpireToken
				delete options.isCheckExpireToken
			}

			if (isCheckExpireToken === true) {
				try {
					let expire = this.bCookie.getData(cookiesKey.TOKEN_EXPIRES_DATE)
						, refreshToken = this.bCookie.getData(cookiesKey.REFRESH_TOKEN)
					if (!!expire && !!refreshToken) {
						let current = moment()
							, expireDate = moment(expire)
							, duration = moment.duration(expireDate.diff(current))
						if (duration.asMinutes() <= MIN_EXPIRE_TOKEN) { //min

							let res = await axios({
								method: this.requestMethod.POST,
								url: `${CORE_API_ENDPOINT}/auth/refreshtoken`,
								headers: {
									Authorization: `Bearer ${this.bCookie.getData(cookiesKey.REFRESH_TOKEN)}`
								}
							})
							if (!!res && !!res.data && !!res.data.result === true) {
								let cIsRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
									, isRemember = typeof cIsRemember === "boolean" ? cIsRemember : false
								afterRefreshToken(res.data.data, isRemember)
							} else {
								this.bCookie.removeAll()
								window.location.replace(`/`)
							}
						}
					}
				} catch (error) {

				}
			}

			let token = this.bCookie.getData(cookiesKey.TOKEN)
			if (token && (options.useToken || !("useToken" in options))) {
				if (!options.headers) {
					options.headers = {
						'Authorization': `Bearer ${token}`,
						'Content-Type': 'application/json'
					}
				} else {
					options.headers.Authorization = `Bearer ${token}`
				}
			}

			let res = await axios(options)

			if (!res.data.result && Array.isArray(res.data.errors) && res.data.errors.length > 0) {
				if (isHandleError) {
					this.setDefaultModalError(res.data.errors)
				}
			}

			let response = res.data && "value" in res.data ? res.data.value : res.data
			if (callback && typeof callback === "function") {
				callback(response)
			}

			return response

		} catch (error) {
			if (isHandleError) {
				const { response } = error
				this.setDefaultModalError(response ? response.data : error)
			}
			if (callback && typeof callback === "function") {
				callback(false)
			}
		}

		return false
	}

	setDefaultModalError(error) {
		if (this.controller && typeof this.controller.setDefaultModalError === "function") {
			this.controller.setDefaultModalError(error)
		} else {
			throw error
		}
	}
}