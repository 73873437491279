
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSystemIntegrationServiceList } from '../../actions/systemIntegrationActions'
import SystemIntegrationManager from '../../business/SystemIntegrationManager'
import SystemIntegrationList from '../../views/systemIntegration/SystemIntegrationList'
import { defaultValue } from '../../../constant'


class SystemIntegrationController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSystemIntegration = new SystemIntegrationManager(this)
	}

	getPropsResetStatus() {
		return ["systemIntegrationList", "systemIntegrationServiceList"]
	}

	onDeleteSystemIntegration = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSystemIntegration.deleteIntegration(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onImportSystemIntegrationFromPlatform = async (ids, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSystemIntegration.importSystemIntegrationFromPlatform({ systemIntegrationIds: ids }, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getSystemIntegrationListPaging = (query) => {
		return this.bSystemIntegration.getSystemIntegrationListPaging(query)
	}

	render() {
		try {
			const { t, systemIntegrationServiceList } = this.props
			const { isLoadingComponent } = this.state

			let optionsSystemIntegrationService = systemIntegrationServiceList.getDatasOption()
			optionsSystemIntegrationService = optionsSystemIntegrationService.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.systemIntegration.${d.value}`)
				}
			})
			optionsSystemIntegrationService = optionsSystemIntegrationService.sort((a, b) => { return a.text.localeCompare(b.text) })

			let optionConcatList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			optionsSystemIntegrationService = optionConcatList.concat(optionsSystemIntegrationService)

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<SystemIntegrationList
						optionsSystemIntegrationService={optionsSystemIntegrationService}
						onDeleteSystemIntegration={this.onDeleteSystemIntegration}
						onImportSystemIntegrationFromPlatform={this.onImportSystemIntegrationFromPlatform}
						onGetDataPaging={this.getSystemIntegrationListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			systemIntegrationServiceList: state.systemIntegrationServiceList
		}),
		(dispatch) => ({
			getSystemIntegrationServiceList() {
				dispatch(getSystemIntegrationServiceList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSystemIntegrationServiceList()
		}
	}),
)


export default enhance(SystemIntegrationController)