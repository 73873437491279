
import types from '../types'
import { requestStatus } from '../../constant'
import DepartmentResource from '../selectors/departmentResource'

const departmentReducer = {
	department: (state = null, action) => {
		if (!state || !(state instanceof DepartmentResource)) {
			state = new DepartmentResource()
		}

		switch (action.type) {
			case types.CORE_GET_DEPARTMENT_REQUEST:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_DEPARTMENT_SUCCESS:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_DEPARTMENT_FAILURE:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	departmentList: (state = null, action) => {
		if (!state || !(state instanceof DepartmentResource)) {
			state = new DepartmentResource()
		}

		switch (action.type) {
			case types.CORE_GET_DEPARTMENT_LIST_REQUEST:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_DEPARTMENT_LIST_SUCCESS:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_DEPARTMENT_LIST_FAILURE:
				state = new DepartmentResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default departmentReducer