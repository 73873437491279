
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const RoleSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

}, { _id: false })

setBaseSchema(RoleSchema, "06")

const RoleModel = mongoose.model("role", RoleSchema, "role")

export default RoleModel