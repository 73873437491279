
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ResourceGroupManager from '../../business/ResourceGroupManager'
import ResourceGroupList from '../../views/resourceGroup/ResourceGroupList'

class ResourceGroupController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bResourceGroup = new ResourceGroupManager(this)
	}

	getPropsResetStatus() {
		return ["resourceGroupList"]
	}

	onDeleteResourceGroup = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bResourceGroup.deleteResourceGroup(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})

	}

	onSyncResourceGroup = (callBack) => {
		this.setState({
			isLoading: true
		}, () => {
			this.bResourceGroup.syncResourceGroup((res) => {
				let tempState = {
					isLoading: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(tempState, action)
			})
		})
	}

	onImportResourceGroupFromPlatform = async (ids, callBack) => {
		this.setState({
			isLoading: true
		}, () => {
			this.bResourceGroup.importResourceGroup({ resourceGroupIds: ids }, (res) => {
				let body = {
					isLoading: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})

	}

	getResourceGroupListPaging = (query) => {
		return this.bResourceGroup.getResourceGroupListPaging(query)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<ResourceGroupList
						onDeleteResourceGroup={this.onDeleteResourceGroup}
						onSyncResourceGroup={this.onSyncResourceGroup}
						onImportResourceGroupFromPlatform={this.onImportResourceGroupFromPlatform}
						onGetDataPaging={this.getResourceGroupListPaging}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	/* 	connect(
			(state, props) => ({
	
			}),
			(dispatch, props) => ({
	
			}),
		),
		lifecycle({
			componentDidMount() {
	
			}
		}), */
)


export default enhance(ResourceGroupController)