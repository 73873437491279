
import MyComponent from './MyComponent'
import ActionResource from '../redux/ActionResource'
import { requestStatus } from '../../../constant'

export default class MyController extends MyComponent {

    constructor(props) {
        super(props)
        this.resetStatusProps()
        this.changeStatusPropsSuccess()
    }

    init() {
        document.title = this.title
    }

    setTitle() {
        return ""
    }

    requestFinished() {
        try {

            let result = true
            for (let key in this.props) {
                let prop = this.props[key]
                if (prop instanceof ActionResource) {
                    result = result && prop.requestFinished()
                }
            }

            return result
        } catch (error) {

            return false
        }
    }

    requestFinishedAfterRequest() {
        try {

            let result = true
            for (let key in this.props) {
                let prop = this.props[key]
                if (prop instanceof ActionResource) {
                    result = result && prop.requestFinishedAfterRequest()
                }
            }

            return result
        } catch (error) {

            return false
        }
    }

    getQueryString(tag) {
        try {
            if (!this.props.location.search) {
                throw new Error("Not Found")
            }

            const params = new URLSearchParams(this.props.location.search)
            const tags = params.get(tag)

            return tags
        } catch (error) {
            return null
        }
    }

    getPropsResetStatus() {
        return []
    }

    resetStatusProps() {
        try {
            var keys = this.getPropsResetStatus()
            if (Array.isArray(keys)) {
                keys.forEach(key => {
                    if (!!key && typeof key === "string" && key in this.props && this.props[key] instanceof ActionResource) {
                        this.props[key].setStatus(requestStatus.NO_ACTION)
                        this.props[key].setValue(null)
                    }
                })
            }
        } catch (error) {
            console.error("Change status props fail.")
        }
    }

    getPropsChangeStatusSuccess() {
        return []
    }

    changeStatusPropsSuccess() {
        try {
            var keys = this.getPropsChangeStatusSuccess()
            if (Array.isArray(keys)) {
                keys.forEach(key => {
                    if (!!key && typeof key === "string" && key in this.props && this.props[key] instanceof ActionResource) {
                        this.props[key].setStatus(requestStatus.REQUEST_SUCCESS)
                        this.props[key].setValue([])
                    }
                })
            }
        } catch (error) {
            console.error("Change status props fail.")
        }
    }
}