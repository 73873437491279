
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Grid, Container, Header, Table, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'

import ButtonModel from '../../core/model/ButtonModel'
import UserModel from '../../models/UserModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ViewImageInfo from '../../core/view/common/ViewImageInfo'
import ViewNoResult from '../common/ViewNoResult'
import UserInputSelectActiveDirectory from './UserInputSelectActiveDirectory'
import UserInputSelectExchange from './UserInputSelectExchange'
import { serviceCode } from '../../../constant'

class ViewUser extends MyPage {
    constructor(props) {
        super(props)

        this.state.isOpenADIntegration = false
        this.state.adID = ""

        this.state.isOpenExchangeIntegration = false
        this.state.exchangeID = ""

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/user/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/user/update/${model.id}`)
    }

    onCancel = () => {
        this.props.history.replace("/user")
    }

    onConfirmUnIntegrate = (serviceInfo, systemIntegrationId) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onUnintegtationUser(model, serviceInfo, systemIntegrationId)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.service"),
                selector: (row) => {
                    if (row.systemIntegration) {
                        return row.systemIntegration.name ? row.systemIntegration.name : '-'
                    } else {
                        return row.service
                    }
                },
                sortable: false,
                maxWidth: '12vw',
            },
            {
                name: t("common.integrationStatus"),
                selector: (row) => {
                    if (row.isIntegration) {
                        //return `${t("common.integrated")}`
                        return <Icon color="green" name="checkmark" />
                    } else if (row.isIntegration === false) {
                        //return `${t("common.notIntegrated")}`
                        return <Icon color="red" name="close" />
                    } else {
                        //return `${t("common.unknown")}`
                        return <Icon color="grey" name="circle" />
                    }
                },
                sortable: false,
                width: '150px',
                center: true,
            },
            {
                name: '',
                minWidth: 'fit-content',
                cell: row => {
                    let buttons = []

                    if (row.isIntegration && row.isEnableIntegration) {
                        buttons = [
                            {
                                text: "",
                                title: t("common.unIntegration"),
                                color: "red",
                                onClick: () => {
                                    if (row.systemIntegration) {
                                        this.onConfirmUnIntegrate(row.service, row.systemIntegration.id)
                                    } else {
                                        this.onConfirmUnIntegrate(row.service)
                                    }
                                },
                                iconClass: "unlink",
                                size: "mini"
                            }
                        ]
                    } else {
                        if (row.isEnableIntegration && row.systemIntegration) {

                            let integrationFunction = null
                            if (row.systemIntegration.service === serviceCode.ACTIVE_DIRECTORY) {
                                integrationFunction = () => { this.onClickADIntegration(row.systemIntegration.id) }
                            } else if (row.systemIntegration.service === serviceCode.EXCHANGE) {
                                integrationFunction = () => { this.onClickExchangeIntegration(row.systemIntegration.id) }
                            } else if (row.systemIntegration.service === serviceCode.GOOGLE_AUTHEN) {

                            } else if (row.systemIntegration.service === serviceCode.O365) {

                            }

                            buttons = [
                                {
                                    text: "",
                                    title: t("common.integration"),
                                    color: "green",
                                    onClick: () => { integrationFunction() },
                                    iconClass: "linkify",
                                    size: "mini"
                                }
                            ]

                        } else if (row.isEnableIntegration) {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.integration"),
                                    color: "green",
                                    onClick: () => { },
                                    iconClass: "linkify",
                                    size: "mini"
                                }
                            ]
                        }
                    }

                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    onClickADIntegration = (serviceId) => {
        this.setState({
            isOpenADIntegration: true,
            adID: serviceId
        })
    }

    onCloseADIntegration = () => {
        this.setState({
            isOpenADIntegration: false
        })
    }

    onSubmitUserADIntegration = (accName) => {
        const { model } = this.props
        const { adID } = this.state

        this.setState({
            isOpenADIntegration: false
        }, () => {
            let body = {
                systemId: adID,
                sAMAccountName: accName
            }

            this.props.onADIntegrationUser(model, body)
        })
    }

    onClickExchangeIntegration = (serviceId) => {
        this.setState({
            isOpenExchangeIntegration: true,
            exchangeID: serviceId
        })
    }

    onCloseExchangeIntegration = () => {
        this.setState({
            isOpenExchangeIntegration: false
        })
    }

    onSubmitUserExchangeIntegration = (accName) => {
        const { model } = this.props
        const { exchangeID } = this.state

        this.setState({
            isOpenExchangeIntegration: false
        }, () => {
            let body = {
                systemId: exchangeID,
                userPrincipalName: accName
            }

            this.props.onExchangeIntegrationUser(model, body)
        })
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformUser(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, model, userIntegrationList } = this.props
            const { isLoading, isOpenADIntegration, adID, isOpenExchangeIntegration, exchangeID, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t("common.back"),
                    color: "grey",
                    onClick: this.onCancel
                })
            )
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {isOpenADIntegration &&
                        <UserInputSelectActiveDirectory
                            id={adID}
                            onSubmit={(accName) => { this.onSubmitUserADIntegration(accName) }}
                            onClose={this.onCloseADIntegration}
                        />
                    }
                    {isOpenExchangeIntegration &&
                        <UserInputSelectExchange
                            id={exchangeID}
                            onSubmit={(accName) => { this.onSubmitUserExchangeIntegration(accName) }}
                            onClose={this.onCloseExchangeIntegration}
                        />
                    }
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('user.viewUser')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("user.employeeCode")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("user.fullname")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.displayName ? model.displayName : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("user.username")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.username ? model.username : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("user.mobile")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.mobile ? model.mobile : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.email")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.email ? model.email : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("user.pinCode")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.pinCode ? model.pinCode : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("rfidCard.title")} /></Table.Cell>
                                                    <Table.Cell>
                                                        <div className="minThree">
                                                            {Array.isArray(model.cards) && model.cards.length > 0 ?
                                                                model.cards.map((d, i) => {
                                                                    return <p>• {d.cardNo} ({d.type})</p>
                                                                })
                                                                : '-'
                                                            }
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                                {/*     <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("admin.rfid")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.rfId ? model.rfId : '-'}</p></Table.Cell>
                                                </Table.Row> */}
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("resourceGroup.title")} /></Table.Cell>
                                                    <Table.Cell>
                                                        <div className="minThree">
                                                            {Array.isArray(model.resourceGroups) && model.resourceGroups.length > 0 ?
                                                                model.resourceGroups.map((d, i) => {
                                                                    return <p>• {d.name}</p>
                                                                })
                                                                : '-'
                                                            }
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("department.title")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.department ? model.department.name : '-'}</p></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <ViewImageInfo src={`user/${model.id}`} />
                                        <br></br>
                                        <br></br>
                                        {
                                            (Array.isArray(userIntegrationList) && userIntegrationList.length > 0) &&
                                            <React.Fragment>
                                                <Header as='h1' dividing className="form header">
                                                    {t("common.integrationInfo")}
                                                </Header>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getColumnConfig()}
                                                                datas={userIntegrationList}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </React.Fragment>

                                        }
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewUser.defaultProps = {
    model: new UserModel(),
    userIntegrationList: [],
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(ViewUser)