import utils from "../../utility/utils"


export default class Step {

	constructor(stepList) {
		this.stepList = stepList
		this.next = null
		this.prev = null

		this.id = utils.makeid(10)
		this.title = "Step"
		this.key = "step"
		this.data = null
	}

	initData() {

	}

	nextStep() {
		this.stepList.nextStep(this)
	}

	prevStep() {
		this.stepList.prevStep(this)
	}

	resetStep() {
		this.stepList.resetStep(this)
	}

	cancelStep() {
		this.stepList.cancelStep(this)
	}

	saveStep(allResult) {
		this.stepList.saveStep(allResult)
	}

	getData() {
		return this.data
	}

	setData(allResult) {
		if (!allResult) {
			allResult = {}
		}

		return allResult
	}

	getNavigatorData() {
        return this.stepList.getNavigatorData(this)
    }

	render() {
		return null
		// return <div>
		// 	{this.title}
		// 	<div>
		// 		<Button onClick={() => this.prevStep()} >Prev</Button>
		// 		<Button onClick={() => this.nextStep()} >Next</Button>
		// 	</div>
		// </div>
	}
}