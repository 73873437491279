
import React from 'react'
import Step from '../../core/business/step/Step'

import SelectUserController from '../../controllers/user/importExternalUser/SelectUserController'

export default class SelectUserStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "user.title"
		this.key = "USER"
		this.data = {
			userSelects: [],
			service: null,
		}
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data) {
			if ("userSelects" in data) {
				this.data.userSelects = data.userSelects
			}
			if ("service" in data) {
				this.data.service = data.service
			}
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult.userSelects = data.userSelects
		return allResult
	}

	nextStep(data) {
		if (!!data) {
			this.data.userSelects = data.userSelects
		}
		super.nextStep()
	}

	prevStep(data) {
		// if (typeof data === "boolean") {
		// 	this.isBypass = data
		// }
		super.prevStep()
	}

	render(dataConfig) {
		return <SelectUserController
			// isRequire={this.isRequire}
			dataConfig={dataConfig}
			dataStep={this.data}
			// isBypass={this.isBypass}
			// controller={controller}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
		/>
	}
}