
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchVisitor from './FormSearchVisitor'
import VisitorModel from '../../models/VisitorModel'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class ViewVisitorList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isLoadingTable = false
        this.state.isSearch = false
        this.state.modelSearch = new VisitorModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("visitor.fullName"),
                selector: (row) => row.displayName ? row.displayName : '-',
                sortable: false,
            },
            {
                name: t("common.email"),
                selector: (row) => row.email ? row.email : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("visitor.status"),
                selector: (row) => row.status ? t(`constantTranslate.status.${row.status}`) : '-',
                sortable: false,
                width: '100px'
            },
            {
                name: "",
                selector: (row) => row.statusStr ? row.statusStr : '-',
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let button = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/visitor/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/visitor/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                    ]
                    return <div className="box-action-table">
                        {
                            button.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let status = ""
                if (!!model.status && model.status !== defaultValue.SELECT_ALL) {
                    status = model.status
                }

                let visitorGroupId = ""
                if (!!model.visitorGroupId && model.visitorGroupId !== defaultValue.SELECT_ALL) {
                    visitorGroupId = model.visitorGroupId
                }

                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "fullName",
                        value: model.displayName,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "username",
                        value: model.username,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "email",
                        value: model.email,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "pinCode",
                        value: model.pinCode,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "status",
                        value: status,
                    },
                    {
                        name: "visitorGroupIds",
                        value: visitorGroupId,
                        queryType: searchQueryType.IN
                    },

                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t, optionVisitorStatusList, optionVisitorGroupList } = this.props
            const { isLoading, isSearch, model, pagingDataModel, isLoadingTable } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {!!model && this.getContentModal(model)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('visitor.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left" verticalAlign="buttom">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch} >
                                                <FormSearchVisitor
                                                    onSearch={this.onSearchByForm}
                                                    optionVisitorStatusList={optionVisitorStatusList}
                                                    optionVisitorGroupList={optionVisitorGroupList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )

        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ViewVisitorList.defaultProps = {
    visitorList: [],
    optionVisitorStatusList: [],
    optionVisitorGroupList: [],
    isLoadingTable: false,
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewVisitorList)