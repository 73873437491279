
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormLocation from './FormLocation'
import ButtonModel from '../../core/model/ButtonModel'
import LocationModel from '../../models/LocationModel'

class ViewUpdateLocation extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = !!props.locationInfo ? new LocationModel(props.locationInfo) : null
        this.state.modelErrors = []

        this.state.model.formType = "UPDATE_LOCATION"
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && (!!nextProps.locationInfo && !!nextProps.locationInfo.id)) {
            tempState.model = new LocationModel(nextProps.locationInfo)
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    componentDidMount() {
        let getParentArray = this.findParentLocationType()
        this.props.filterLocationList(getParentArray)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onUpdate(this.state.model)
    }

    onView = (model) => {
        this.props.history.push(`/location/view/${model.id}`)
    }

    onCancel = () => {
        this.props.history.push(`/location`)
        //this.props.history.goBack()
    }

    findParentLocationType = () => {
        let { model } = this.state
        const { optionsLocationType } = this.props

        let parentArray = []
        let findParentStop = true

        while (findParentStop) {
            if (parentArray.length === 0) {
                let currentId = model.locationTypeId
                let findInOptions = optionsLocationType.find(data => currentId === data.value)
                let findParent = findInOptions

                if (!!findParent && !!findParent.parent) {
                    parentArray.push(findParent.parent.id)
                } else {
                    findParentStop = false
                }

            } else if (parentArray.length > 0) {
                let currentId = parentArray[parentArray.length - 1]
                let findInOptions = optionsLocationType.find(data => currentId === data.value)
                let findParent = findInOptions

                if (!!findParent && !!findParent.parent) {
                    parentArray.push(findParent.parent.id)
                } else {
                    findParentStop = false
                }

            } else {
                findParentStop = false
            }
        }

        return parentArray
    }

    handleChangeLocationType = (e, { name, value }) => {
        let model = this.state.model
        let modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []
        let tempState = {}

        if (model && name in model) {
            model[name] = value
            this.setState({ model })
        }

        let index = modelErrors.findIndex(e => e.path === name)
        if (index > -1) {
            modelErrors.splice(index, 1);
            tempState.modelErrors = modelErrors
        }

        this.setState(tempState)

        let getParentArray = this.findParentLocationType()
        this.props.filterLocationList(getParentArray)
    }

    render() {
        try {
            const { t, optionsLocationType, optionsTreeLocation, isLoadingControl } = this.props
            const { isLoading, model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t("common.save"),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t("location.updateLocation")} buttons={buttons}>
                        <div className="module viewUpdateLocation">
                            <Container text>
                                <Grid>
                                    {
                                        !!model &&
                                        <Grid.Column width={16}>
                                            <FormLocation
                                                model={model}
                                                errors={modelErrors}
                                                optionsLocationType={optionsLocationType}
                                                optionsTreeLocation={optionsTreeLocation}
                                                handleChange={this.handleChange}
                                                handleChangeLocationType={this.handleChangeLocationType}
                                            />
                                        </Grid.Column>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewUpdateLocation.defaultProps = {
    isLoadingControl: false,
    optionsTreeLocation: [],
    optionsLocationType: [],
    locationInfo: null,
    onUpdate: () => console.error("Please provide Update action.")
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(ViewUpdateLocation)