
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class IOTResourceDropdownResource extends ActionResourceObject {
	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.displayName
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.displayName
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}