
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const cardDataSchema = new Schema({
	type: { type: String, default: "" },
	value: { type: String, default: "" },
}, { _id: false })

const RFIDCardSchema = new Schema({
	id: { type: String, default: null },
	cardNo: { type: String, required: [true, "validate.require"], default: "" },
	type: { type: String, required: [true, "validate.require"], default: "" },
	datas: [{ type: cardDataSchema }],
}, { _id: false })

setBaseSchema(RFIDCardSchema, "06")

RFIDCardSchema.methods.serializeAPI = function () {
	try {
		let result = {
			cardNo: this.cardNo,
			type: this.type,
			datas: this.datas,
		}

		return result

	} catch (error) {
		return null
	}
}

const RFIDCardModel = mongoose.model("door", RFIDCardSchema, "door")

export default RFIDCardModel