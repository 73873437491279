
import React from 'react'
import Step from '../../core/business/step/Step'

import SelectServiceController from '../../controllers/admin/importExternalUser/SelectServiceController'

export default class SelectServiceStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "systemIntegration.service"
		this.key = "SERVICE"
		this.data = {
			serviceId: "",
			service: null
		}
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data) {
			if ("serviceId" in data) {
				this.data.serviceId = data.serviceId
			}
			if ("service" in data) {
				this.data.service = data.service
			}
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult.serviceId = data.serviceId
		allResult.service = data.service
		return allResult
	}

	nextStep(data) {
		if (!!data) {
			if (data.serviceId) {
				this.data.serviceId = data.serviceId
			}
			if (data.service) {
				this.data.service = data.service
			}
		}
		super.nextStep()
	}

	prevStep(data) {
		// if (typeof data === "boolean") {
		// 	this.isBypass = data
		// }
		super.prevStep()
	}

	render(dataConfig) {
		return <SelectServiceController
			// isRequire={this.isRequire}
			dataConfig={dataConfig}
			dataStep={this.data}
			// isBypass={this.isBypass}
			// controller={controller}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
		/>
	}
}