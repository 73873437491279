
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from './LabelInput'
import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'
import AreaModel from '../../models/AreaModel'
import { areaTypes } from '../../../constant'

class AreaForm extends MyForm {

    render() {
        try {
            const { t, model, optionsTreeLocation, errors, disabledForm } = this.props

            let locationIdValidate = this.getErrorInput("locationId", errors)
                , areaTypeValidate = this.getErrorInput("areaType", errors)

            let optionsAreaType = [
                {
                    key: areaTypes.POLYGON,
                    value: areaTypes.POLYGON,
                    text: t(`constantTranslate.areaType.${areaTypes.POLYGON}`),
                },
                {
                    key: areaTypes.CIRCLE,
                    value: areaTypes.CIRCLE,
                    text: t(`constantTranslate.areaType.${areaTypes.CIRCLE}`),
                }
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <LabelInput text={t("location.title")} isRequire={true} />
                                    <ViewTreeSelect
                                        label={t("location.title")}
                                        name="locationId"
                                        datas={optionsTreeLocation}
                                        value={model.locationId}
                                        onChange={this.handleChange}
                                        error={locationIdValidate.isError ? locationIdValidate.content : null}
                                        disabled={disabledForm}
                                    />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <LabelInput text={t('common.areaType')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.areaType")}
                                        name="type"
                                        value={model.type}
                                        options={optionsAreaType}
                                        onChange={this.handleChange}
                                        error={areaTypeValidate.isError ? areaTypeValidate.content : null}
                                        disabled={disabledForm}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

AreaForm.defaultProps = {
    model: new AreaModel(),
    errors: [],
    optionsTreeLocation: [],
    handleChange: () => console.error("Please provide Handle Change action."),
    disabledForm: false,
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(AreaForm)