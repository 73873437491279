// import { createStore, applyMiddleware } from 'redux'
// import thunk from 'redux-thunk'
// import { checkToken, api, } from '../middlewares'
// import setToken from '../middlewares/setToken'
// import resetCookiesExpire from '../middlewares/resetCookiesExpire'
// import rootReducer from '../reducers'

// export default function (initialState) {
//     const middlewares = [
//         checkToken,
//         setToken,
//         api,
//         thunk,
//         resetCookiesExpire,
//     ]

//     const store = createStore(
//         rootReducer,
//         initialState,
//         applyMiddleware(...middlewares)
//     )

//     return store
// }

import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { checkToken, api, setToken } from '../middlewares'
import rootReducer from '../reducers'

const config = (initialState) => {
    const middlewares = [
        checkToken,
        setToken,
        api,
        thunk,
    ]

    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middlewares)
    )

    return store
}

export default config