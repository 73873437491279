
import React from 'react'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormLocationType from './FormLocationType'
import LocationTypeManager from '../../business/LocationTypeManager'
import LocationTypeModel from '../../models/LocationTypeModel'
import ButtonModel from '../../core/model/ButtonModel'

class ViewUpdateLocationType extends MyPage {
    constructor(props) {
        super(props)
        let model = (!!props.locationType && !!props.locationType.id ? new LocationTypeModel(props.locationType) : null)
        this.state.model = model
        this.state.errors = []
        this.state.isLoading = false

        this.bLocationType = new LocationTypeManager(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && (!!nextProps.locationType && !!nextProps.locationType.id)) {
            tempState.model = new LocationTypeModel(nextProps.locationType)
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onUpdate(this.state.model)
    }

    onView = (model) => {
        this.props.history.push(`/locationtype/view/${model.id}`)
    }

    onClickCancel = () => {
        this.props.history.push(`/locationtype`)
        //this.props.history.goBack()
    }

    render() {
        const { t, optionsTreeLocationType, isLoadingControl } = this.props
        const { errors, model, isLoading } = this.state

        let isDisableSubmit = isLoadingControl

        try {

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t("common.save"),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onClickCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('locationType.update')} buttons={buttons}>
                        <div className="module viewUpdateLocationType">
                            <Container text>
                                <div className="vult-wrapper">
                                    <Grid>
                                        {
                                            !!model &&
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <FormLocationType
                                                        handleChange={this.handleChange}
                                                        errors={errors}
                                                        model={model}
                                                        optionsTreeLocationType={optionsTreeLocationType}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        }
                                    </Grid>
                                </div>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>

            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewUpdateLocationType.defaultProps = {
    isLoadingControl: false,
    optionsTreeLocationType: [],
    locationType: null,
    onUpdate: () => console.error("Please provide Update action.")
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewUpdateLocationType)