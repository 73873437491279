import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import ButtonModel from '../../core/model/ButtonModel'

class ViewButton extends React.Component {

    render() {
        const { model } = this.props
        let propsBtn = {
            size: model.size
        }
            , externalProps = {}
        if (!!model.iconClass) {
            propsBtn.icon = true
        }
        if (!!model.color) {
            propsBtn.color = model.color
        }
        if (!!model.title) {
            propsBtn.title = model.title
        }
        if (typeof model.disabled === "boolean") {
            propsBtn.disabled = model.disabled
        }
        if (!!model.externalProps && Object.keys(model.externalProps).length > 0) {
            externalProps = model.externalProps
        }
        try {
            if (model.visible === false) {
                return null
            }
            return (
                <Button {...externalProps} {...propsBtn} onClick={model.onClick} >
                    {
                        !!model.iconClass &&
                        <Icon name={model.iconClass} />
                    }
                    {model.text}
                </Button>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}


ViewButton.defaultProps = {
    model: new ButtonModel()
}

export default ViewButton