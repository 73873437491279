
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Header, Grid, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import DepartmentModel from '../../models/DepartmentModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewNoResult from '../common/ViewNoResult'

class ViewDepartmentInfo extends MyPage {
    constructor(props) {
        super(props)

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/department/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/department/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/department`)
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("common.parent"),
                selector: (row) => row.parent ? row.parent.name : '-',
                sortable: false,
                width: '150px'
            },
            /*             {
                            name: t("common.description"),
                            selector: (row) => row.description ? row.description : '-',
                            sortable: false
                        } */
        ]

        return columns
    }

    getColumnUsersConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("user.employeeCode"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("user.fullname"),
                selector: (row) => row.displayName ? row.displayName : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("common.email"),
                selector: (row) => row.email ? row.email : '-',
                sortable: false,
                width: '200px'
            }
        ]

        return columns
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformDepartment(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, departmentList, userList, model } = this.props
            const { isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )
            let userListChildMap = []
            if (!!model) {
                userListChildMap = userList.map((d, i) => {
                    return (d.departmentId === model.id) ? d : null

                })
            }

            let userListChild = userListChildMap.filter(x => x !== null)

            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('department.viewDepartment')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('department.parent')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.parent ? model.parent.name : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            {
                                                !!departmentList && (Array.isArray(departmentList) && departmentList.length > 0) &&
                                                /*     <React.Fragment>
                                                        <br></br>
                                                        <br></br>
                                                        <Header as='h1' dividing className="form header">
                                                            {t("department.title")}
                                                        </Header>
                                                        <ViewTable
                                                            columns={this.getColumnConfig()}
                                                            datas={departmentList}
                                                        />
                                                    </React.Fragment> */
                                                <React.Fragment>
                                                    <Grid divided='vertically'>
                                                        <Grid.Row className="remove-bottom-padding">
                                                            <Grid.Column width={16}>
                                                                <Header as='h1'>
                                                                    {t("department.title")}
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <ViewTable
                                                                    columns={this.getColumnConfig()}
                                                                    datas={departmentList}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </React.Fragment>
                                            }
                                            {
                                                !!userListChild && (Array.isArray(userListChild) && userListChild.length > 0) &&
                                                <React.Fragment>
                                                    <Grid divided='vertically'>
                                                        <Grid.Row className="remove-bottom-padding">
                                                            <Grid.Column width={16}>
                                                                <Header as='h1'>
                                                                    {t("user.title")}
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <ViewTable
                                                                    columns={this.getColumnUsersConfig()}
                                                                    datas={userListChild}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </React.Fragment>
                                                /* <React.Fragment>
                                                    <br></br>
                                                    <br></br>
                                                    <Header as='h1' dividing className="form header">
                                                        {t("user.title")}
                                                    </Header>
                                                    <ViewTable
                                                        columns={this.getColumnUsersConfig()}
                                                        datas={userListChild}
                                                    />
                                                </React.Fragment> */
                                            }

                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ViewDepartmentInfo.defaultProps = {
    model: new DepartmentModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewDepartmentInfo)