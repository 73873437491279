
import mongoose from 'mongoose'
import { defaultValue } from '../../constant'
import { setBaseSchema } from '../core/schema/Schema'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const RFIDCardImportExcelModelSchema = new Schema({
	formatCode: { type: String, required: [true, "validate.require"], default: "" },
	fileUpload: { type: FileSchema, required: [true, "validate.require"], default: null },
}, { _id: false })

setBaseSchema(RFIDCardImportExcelModelSchema, "import_excel_rfid_card")

RFIDCardImportExcelModelSchema.path("formatCode").validate(async function (formatCode) {
	return !!formatCode && formatCode !== defaultValue.PLEASE_SELECT
}, "validate.require")

RFIDCardImportExcelModelSchema.methods.serializeAPI = function () {
	try {
		let result = {
			formatCode: this.formatCode,
		}
		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}
		return result
	} catch (error) {
		return null
	}
}

const RFIDCardImportExcelModel = mongoose.model("import_excel_rfid_card", RFIDCardImportExcelModelSchema, "import_excel_rfid_card")

export default RFIDCardImportExcelModel