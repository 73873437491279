
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header, Label } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import AdminModel from '../../models/AdminModel'
import LabelInput from '../common/LabelInput'
import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'
import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'
import MultipleCheckboxList from '../common/MultipleCheckboxList'
import ModalRFIDCard from './ModalRFIDCard'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ViewTable from '../../core/view/common/table/ViewTable'

class FormAdmin extends MyForm {
    constructor(props) {
        super(props)

        this.state.openRFIDCardModal = false
        this.state.rfidCardModal = null
        this.state.rfidCardIndex = null
        this.state.rfidCardModalStatus = null
    }

    onOpenModelRFIDCard = (status, model, index) => {
        this.setState({
            openRFIDCardModal: true,
            rfidCardModal: model,
            rfidCardModalStatus: status,
            rfidCardIndex: index
        })
    }

    onCloseModelRFIDCard = () => {
        this.setState({
            openRFIDCardModal: false
        })
    }

    onSubmitModelRFIDCard = (rfidCardInfo, rfidCardList) => {
        const { rfidCardModalStatus, rfidCardIndex } = this.state
        let currentRFIDCardList = []

        if (rfidCardModalStatus === "CREATE") {
            currentRFIDCardList = rfidCardList.map(rfidCard => rfidCard)

            rfidCardInfo.forEach(rfidCard => {
                if (!!currentRFIDCardList.find(rfidCardList => rfidCard.id === rfidCardList.id) === false) {
                    let rfidCardInfoRaw = {
                        id: rfidCard.id,
                        cardNo: rfidCard.cardNo,
                        type: rfidCard.type
                    }
                    currentRFIDCardList.push(rfidCardInfoRaw)
                }
            })

        } else if (rfidCardModalStatus === "UPDATE") {
            currentRFIDCardList = rfidCardList.map(rfidCard => rfidCard)
            currentRFIDCardList[rfidCardIndex] = {
                id: rfidCardInfo.id,
                code: rfidCardInfo.code,
                name: rfidCardInfo.name
            }
        }

        this.setState({
            openRFIDCardModal: false
        }, () => {
            this.handleChange(null, { name: "cards", value: currentRFIDCardList })
        })
    }

    onRemoveModelRFIDCard = (index) => {
        const { model } = this.props

        if (Array.isArray(model.cards) && model.cards.length > 0) {
            let currentRFIDCardList = model.cards.map(t => t)

            if (index > -1) {
                currentRFIDCardList.splice(index, 1)
            }
            this.props.handleChange(null, { name: "cards", value: currentRFIDCardList })
        }

    }

    getColumnRFIDCardConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("rfidCard.cardNo"),
                selector: (row) => row.cardNo ? row.cardNo : '-',
                sortable: false,
                maxWidth: '250px',
            },
            {
                name: t("common.type"),
                selector: (row) => row.type ? row.type : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelRFIDCard(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    render() {
        try {
            const { t, model, errors, optionsResourceGroup, optionsTreeDepartment } = this.props
            const { openRFIDCardModal } = this.state

            let resourceGroupIdsValidate = this.getErrorInput("resourceGroupIds", errors)
                , codeValidate = this.getErrorInput("code", errors)
                , mobileValidate = this.getErrorInput("mobile", errors)
                , givenNameValidate = this.getErrorInput("givenName", errors)
                , snValidate = this.getErrorInput("sn", errors)
                , emailValidate = this.getErrorInput("email", errors)
                , cardsValidate = this.getErrorInput("cards", errors)
                //, rfidValidate = this.getErrorInput("rfId", errors)
                , usernameValidate = this.getErrorInput("username", errors)
                , passwordValidate = this.getErrorInput("password", errors)
                , pinCodeValidate = this.getErrorInput(" pinCode", errors)
                , confirmPasswordValidate = this.getErrorInput("confirmPassword", errors)
                , departmentIdValidate = this.getErrorInput("departmentId", errors)

            return (
                <div className="module formAdmin">
                    {openRFIDCardModal &&
                        <ModalRFIDCard
                            onSubmit={(rfidCardInfo) => this.onSubmitModelRFIDCard(rfidCardInfo, model.cards)}
                            onClose={this.onCloseModelRFIDCard}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Header as='h1' dividing className="form header">
                            {t("admin.adminInfo")}
                        </Header>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.employeeCode")} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.givenName")} isRequire={true} />
                                    <Form.Input
                                        name="givenName"
                                        value={model.givenName}
                                        onChange={this.handleChange}
                                        fluid
                                        error={givenNameValidate.isError ? givenNameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.sn")} isRequire={false} />
                                    <Form.Input
                                        name="sn"
                                        value={model.sn}
                                        onChange={this.handleChange}
                                        fluid
                                        error={snValidate.isError ? snValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.mobile")} isRequire={false} />
                                    <Form.Input
                                        name="mobile"
                                        value={model.mobile}
                                        onChange={this.handleChange}
                                        fluid
                                        error={mobileValidate.isError ? mobileValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.email")} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        fluid
                                        type="email"
                                        error={emailValidate.isError ? emailValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.pinCode")} isRequire={false} />
                                    <Form.Input
                                        name="pinCode"
                                        value={model.pinCode}
                                        onChange={this.handleChange}
                                        fluid
                                        error={pinCodeValidate.isError ? pinCodeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('rfidCard.title')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.add'),
                                            color: "green",
                                            onClick: () => { this.onOpenModelRFIDCard("CREATE", null, null) },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnRFIDCardConfig()}
                                        datas={model.cards}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (cardsValidate.isError &&
                                    cardsValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {cardsValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                            {/*              <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.rfid")} isRequire={false} />
                                    <Form.Input
                                        name="rfId"
                                        value={model.rfId}
                                        onChange={this.handleChange}
                                        fluid
                                        error={rfidValidate.isError ? rfidValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row> */}
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("resourceGroup.title")} isRequire={true} />
                                    <MultipleCheckboxList
                                        name="resourceGroupIds"
                                        optionsDataList={optionsResourceGroup}
                                        value={model.resourceGroupIds}
                                        onChange={this.handleChange}
                                        error={resourceGroupIdsValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("department.title")} isRequire={false} />
                                    <ViewTreeSelect
                                        label={t("department.title")}
                                        name="departmentId"
                                        datas={optionsTreeDepartment}
                                        value={model.departmentId}
                                        placeholder={t("common.pleaseSelect")}
                                        onChange={this.handleChange}
                                        error={departmentIdValidate.isError ? departmentIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("admin.profile")} isRequire={false} />
                                    <ViewSelectImageOrTakePhoto name="fileUpload" defaultImage={!!model.id ? `admin/${model.id}` : ""} onChange={this.handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <input
                            name="username"
                            style={{ display: "none" }}
                        />
                        <input
                            name="password"
                            type={"password"}
                            style={{ display: "none" }}
                        />
                        {
                            !model.id &&
                            <React.Fragment>
                                <br></br>
                                <br></br>
                                <Header as='h1' dividing className="form header">
                                    {t("admin.sysInfo")}
                                </Header>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("admin.username")} isRequire={true} />
                                            <Form.Input
                                                name="username"
                                                value={model.username}
                                                onChange={this.handleChange}
                                                fluid
                                                error={usernameValidate.isError ? usernameValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("admin.password")} isRequire={!model.id} />
                                            <Form.Input
                                                name="password"
                                                value={model.password}
                                                onChange={this.handleChange}
                                                fluid
                                                type="password"
                                                error={passwordValidate.isError ? passwordValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("admin.confirmPassword")} isRequire={!!model.password && !model.id} />
                                            <Form.Input
                                                name="confirmPassword"
                                                value={model.confirmPassword}
                                                onChange={this.handleChange}
                                                fluid
                                                type="password"
                                                error={confirmPasswordValidate.isError ? confirmPasswordValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        }

                    </Form >
                </div >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormAdmin.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    model: new AdminModel(),
    errors: [],
    optionsResourceGroup: [],
    optionsTreeDepartment: [],
}

export default (translate(FormAdmin))