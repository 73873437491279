
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchSensor from './FormSearchSensor'
import SensorModel from '../../models/SensorModel'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class SensorList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.isLoadingTable = false
        this.state.modelSearch = new SensorModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    onClickDelete = (model) => {
        const { t, onDeleteSensor } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteSensor(model, () => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickStatus = (model) => {
        const { t, onChangeDisableSensor, onChangeEnableSensor } = this.props
        try {
            if (!!model) {
                if (model.status === "ACTIVE") {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.disableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeDisableSensor(model, () => this.onSearchByForm(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.enableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeEnableSensor(model, () => this.onSearchByForm(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                }
            }

        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("sensor.type"),
                selector: (row) => row.sensorType ? row.sensorType : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("sensor.model"),
                selector: (row) => row.sensorModel ? row.sensorModel : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("sensor.sn"),
                selector: (row) => row.sensorSN ? row.sensorSN : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("location.title"),
                selector: (row) => row.location ? row.location.name : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            /*             {
                            name: t("common.enable"),
                            selector: (row, i) => {
                                if (row.isDisable === null) {
                                    return ""
                                } else if (row.isDisable === false) {
                                    return <Icon color="green" name="checkmark" />
                                } else if (row.isDisable === true) {
                                    return <Icon color="red" name="close" />
                                }
                            },
                            sortable: false,
                            width: '100px'
                        }, */
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/sensor/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/sensor/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]

                    /*                     let buttons = []
                    
                                        if (!!row) {
                                            if (row.isDisable === false) {
                                                buttons = [
                                                    {
                                                        text: "",
                                                        title: t("common.view"),
                                                        color: "violet",
                                                        onClick: () => { this.props.history.push(`/sensor/view/${row.id}`) },
                                                        iconClass: "search",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.changeStatusDisable"),
                                                        color: "red",
                                                        onClick: () => { this.onClickStatus(row) },
                                                        iconClass: "close",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.edit"),
                                                        color: "blue",
                                                        onClick: () => { this.props.history.push(`/sensor/update/${row.id}`) },
                                                        iconClass: "edit",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.delete"),
                                                        color: "red",
                                                        onClick: () => { this.onClickDelete(row) },
                                                        iconClass: "trash",
                                                        size: "mini"
                                                    }
                                                ]
                                            } else {
                                                buttons = [
                                                    {
                                                        text: "",
                                                        title: t("common.view"),
                                                        color: "violet",
                                                        onClick: () => { this.props.history.push(`/sensor/view/${row.id}`) },
                                                        iconClass: "search",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.changeStatusEnable"),
                                                        color: "green",
                                                        onClick: () => { this.onClickStatus(row) },
                                                        iconClass: "check",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.edit"),
                                                        color: "blue",
                                                        onClick: () => { this.props.history.push(`/sensor/update/${row.id}`) },
                                                        iconClass: "edit",
                                                        size: "mini"
                                                    },
                                                    {
                                                        text: "",
                                                        title: t("common.delete"),
                                                        color: "red",
                                                        onClick: () => { this.onClickDelete(row) },
                                                        iconClass: "trash",
                                                        size: "mini"
                                                    }
                                                ]
                                            }
                                        } */

                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickAdd = () => {
        this.props.history.push(`/sensor/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

  /*   onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let sensorType = ""
                if (!!model.sensorType && model.sensorType !== defaultValue.SELECT_ALL) {
                    sensorType = model.sensorType
                }

                let sensorModel = ""
                if (!!model.sensorModel && model.sensorModel !== defaultValue.SELECT_ALL) {
                    sensorModel = model.sensorModel
                }

                let locationId = ""
                if (!!model.location && !!model.location.id && model.location.id !== defaultValue.SELECT_ALL) {
                    locationId = model.location.id
                }

                let locationType = ""
                if (!!model.location && !!model.location.type && model.location.type !== defaultValue.SELECT_ALL) {
                    locationType = model.location.type
                }

                let querys = [
                    {
                        name: "sensorType",
                        value: sensorType
                    },
                    {
                        name: "sensorModel",
                        value: sensorModel
                    },
                    {
                        name: "sensorSN",
                        value: model.sensorSN,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "location.type",
                        value: locationType
                    },
                    {
                        name: "location.id",
                        value: locationId,
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    } */

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let sensorType = ""
                if (!!model.sensorType && model.sensorType !== defaultValue.SELECT_ALL) {
                    sensorType = model.sensorType
                }

                let sensorModel = ""
                if (!!model.sensorModel && model.sensorModel !== defaultValue.SELECT_ALL) {
                    sensorModel = model.sensorModel
                }

                let locationId = ""
                if (!!model.location && !!model.location.id && model.location.id !== defaultValue.SELECT_ALL) {
                    locationId = model.location.id
                }

                let locationType = ""
                if (!!model.location && !!model.location.type && model.location.type !== defaultValue.SELECT_ALL) {
                    locationType = model.location.type
                }

                let querys = [
                    {
                        name: "sensorType",
                        value: sensorType
                    },
                    {
                        name: "sensorModel",
                        value: sensorModel
                    },
                    {
                        name: "sensorSN",
                        value: model.sensorSN,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "location.type",
                        value: locationType
                    },
                    {
                        name: "location.id",
                        value: locationId,
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t, optionResourceList, optionsSensorTypeList, optionsSensorModelList, optionLocationResourceList, optionsSensorLocationTypeList } = this.props
            const { isSearch, isLoading, pagingDataModel, isLoadingTable } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('sensor.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchSensor
                                                    onSearch={this.onSearchByForm}
                                                    optionsSensorTypeList={optionsSensorTypeList}
                                                    optionsSensorModelList={optionsSensorModelList}
                                                    optionResourceList={optionResourceList}
                                                    optionLocationResourceList={optionLocationResourceList}
                                                    optionsSensorLocationTypeList={optionsSensorLocationTypeList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorList.defaultProps = {
    optionResourceList: [],
    optionsSensorTypeList: [],
    optionsSensorModelList: [],
    optionLocationResourceList: [],
    optionsSensorLocationTypeList: [],
    onDeleteSensor: () => console.error("Please provide \"Delete Sensor\" action."),
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SensorList)