
import types from '../types'
import { requestStatus } from '../../constant'
import HolidayResource from '../selectors/holidayResource'

const holidayReducer = {
	holidayInfo: (state = null, action) => {
		if (!state || !(state instanceof HolidayResource)) {
			state = new HolidayResource()
		}

		switch (action.type) {
			case types.GET_HOLIDAY_INFO_REQUEST:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_HOLIDAY_INFO_SUCCESS:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_HOLIDAY_INFO_FAILURE:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	holidayList: (state = null, action) => {
		if (!state || !(state instanceof HolidayResource)) {
			state = new HolidayResource()
		}

		switch (action.type) {
			case types.GET_HOLIDAY_LIST_REQUEST:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_HOLIDAY_LIST_SUCCESS:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_HOLIDAY_LIST:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_HOLIDAY_LIST_FAILURE:
				state = new HolidayResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default holidayReducer