
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getNotificationInfo, getNotificationServiceList, getNotificationSystemList } from '../../actions/notificationActions'
import NotificationManager from '../../business/NotificationManager'
import utils from '../../core/utility/utils'
import NotificationUpdate from '../../views/notification/NotificationUpdate'

class UpdateNotificationController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bNotification = new NotificationManager(this)
	}

	getPropsResetStatus() {
		return ["notificationInfo"]
	}

	onUpdateNotification = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bNotification.updateNotification(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/notification/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onTestNotification = async (model) => {
		try {
			return this.bNotification.testNotification(model.serializeAPI())
		} catch (error) {
			return false
		}
	}


	render() {
		try {
			const { t, notificationInfo, notificationServiceList, notificationSystemList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const notification = notificationInfo.getData()
				, optionsNotificationSystem = notificationSystemList.getDatasOption()

			let optionsNotificationService = notificationServiceList.getDatasOption()
			optionsNotificationService = optionsNotificationService.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.systemIntegration.${d.value}`)
				}
			})
			optionsNotificationService = optionsNotificationService.sort((a, b) => { return a.text.localeCompare(b.text) })

			return (
				<React.Fragment>
					{super.render()}
					<NotificationUpdate
						isLoadingControl={isLoadingComponent}
						model={notification}
						optionsNotificationService={optionsNotificationService}
						optionsNotificationSystem={optionsNotificationSystem}
						onUpdate={this.onUpdateNotification}
						onTestNotification={this.onTestNotification}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			notificationInfo: state.notificationInfo,
			notificationServiceList: state.notificationServiceList,
			notificationSystemList: state.notificationSystemList
		}),
		(dispatch, props) => ({
			getNotificationInfo() {
				dispatch(getNotificationInfo(utils.getParamFromProps(props, "id")))
			},
			getNotificationServiceList() {
				dispatch(getNotificationServiceList())
			},
			getNotificationSystemList() {
				dispatch(getNotificationSystemList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getNotificationInfo()
			this.props.getNotificationServiceList()
			this.props.getNotificationSystemList()
		}
	}),
)


export default enhance(UpdateNotificationController)