
import { CORE_API_ENDPOINT, setVerifyCookies, afterRefreshToken } from '../lib'
import MyManager from '../core/business/MyManager'
import { cookiesKey } from '../../constant'

export default class AuthManager extends MyManager {


	async localVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/local`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async adVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/ad`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async exchangeVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/exchange`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async profileVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/profile`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async googleVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/google`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async oThreeSixFiveVerify(data, callback) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/verify/o365`,
				data: data
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	afterVerify(verifyData, isRemember = false) {
		try {
			if (!verifyData) {
				throw new Error("Invalid verify data")
			}

			setVerifyCookies(verifyData, isRemember)
			this.redirectToFirstPage(verifyData)
		} catch (error) {

		}
	}
	redirectToFirstPage(verifyData) {
		try {
			const targetURL = this.bCookie.getData(cookiesKey.TARGET_URL)
			let isFirstLogin = false
				, toUrl = "/"
			if (!!verifyData.user && typeof verifyData.user.isFirstLogin === "boolean") {
				isFirstLogin = verifyData.user.isFirstLogin
			}
			if (targetURL) {
				toUrl = targetURL
				this.bCookie.removeData(cookiesKey.TARGET_URL)
			} else {
				if (isFirstLogin) {
					if (verifyData.user && typeof verifyData.user.username === "string") {
						if (verifyData.user.username === "bainisysadmin") {
							toUrl = "/"
						} else {
							toUrl = "/account"
						}
					}
				}
			}

			this.redirectToUrl(toUrl)
		} catch (error) {

		}
	}

	redirectToUrl = (url) => {
		if (!!this.controller.history) {
			this.controller.history.replace(url)
		} else {
			window.location.replace(url)
		}
	}

	async refreshToken(data) {
		try {
			const options = {
				isHandleError: false,
				isCheckExpireToken: false,
				useToken: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/refreshtoken`,
				data: data,
				headers: {
					Authorization: `Bearer ${this.bCookie.getData(cookiesKey.REFRESH_TOKEN)}`
				}
			}
			let res = await this.requestAction(options)
			if (!!res && !!res.result === true) {
				let cIsRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
					, isRemember = typeof cIsRemember === "boolean" ? cIsRemember : false
				afterRefreshToken(res.data, isRemember)
			}
			return res
		} catch (error) {
			return null
		}
	}

	async changePassword(body, callback) {
		if (typeof callback === "function") {
			callback(false)
		}
		return false
		// try {
		// 	const options = {
		// 		useToken: false,
		// 		method: this.requestMethod.POST,
		// 		url: `${CORE_API_ENDPOINT}/auth/verify/ad`,
		// 		data: data
		// 	}
		// 	let res = await this.requestAction(options, callback)
		// 	return res
		// } catch (error) {
		// 	this.setDefaultModalError(error)
		// }
	}

	async forgotPassword(body) {
		try {
			const option = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/forgotpassword`,
				data: body,
				isHandleError: false
			}
			let res = await this.requestAction(option)
			return res
		} catch (error) {
			return false
		}
	}

	async resetPassword(body) {
		try {
			const option = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/auth/resetforgotpassword`,
				data: body,
				isHandleError: false
			}
			let res = await this.requestAction(option)
			return res
		} catch (error) {
			return false
		}
	}

	async verifyBypass(code) {
		try {
			const options = {
				useToken: false,
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/auth/bypass/verify?code=${code}`,
				isHandleError: false
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			return false
		}
	}
}