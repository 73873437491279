
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import HolidayManager from '../../business/HolidayManager'
import HolidayCreate from '../../views/holiday/HolidayCreate'

class CreateHolidayController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bHoliday = new HolidayManager(this)
	}

	onCreateHoliday = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bHoliday.createHoliday(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/holiday/view/${res.data.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onClickBack = () => {
		this.props.history.push(`/holiday`)
	}

	render() {
		try {
			const { t } = this.props
			const { isLoadingComponent } = this.state
			let currentYear = parseInt(moment().format("YYYY"))

			const optionsYearRaw = [
				currentYear,
				currentYear + 1,
				currentYear + 2,
				currentYear + 3,
				currentYear + 4,
				currentYear + 5,
				currentYear + 6,
				currentYear + 7,
				currentYear + 8,
				currentYear + 9,
				currentYear + 10
			]

			const optionsYear = optionsYearRaw.map((d, i) => {
				return {
					key: "" + d,
					value: "" + d,
					text: "" + d
				}
			})

			const optionsWeekday = [
				{
					key: "MON",
					value: "MON",
					text: t(`constantTranslate.dayOfWeek.MON`),
				},
				{
					key: "TUE",
					value: "TUE",
					text: t(`constantTranslate.dayOfWeek.TUE`),
				},
				{
					key: "WED",
					value: "WED",
					text: t(`constantTranslate.dayOfWeek.WED`),
				},
				{
					key: "THU",
					value: "THU",
					text: t(`constantTranslate.dayOfWeek.THU`),
				},
				{
					key: "FRI",
					value: "FRI",
					text: t(`constantTranslate.dayOfWeek.FRI`),
				},
				{
					key: "SAT",
					value: "SAT",
					text: t(`constantTranslate.dayOfWeek.SAT`),
				},
				{
					key: "SUN",
					value: "SUN",
					text: t(`constantTranslate.dayOfWeek.SUN`),
				}
			]

			const optionsDateType = [
				{
					key: "ALL_YEAR",
					value: "ALL_YEAR",
					text: t(`constantTranslate.holidayDateType.ALL_YEAR`)
				},
				{
					key: "RANGE",
					value: "RANGE",
					text: t(`constantTranslate.holidayDateType.RANGE`)
				},
				{
					key: "DAY",
					value: "DAY",
					text: t(`constantTranslate.holidayDateType.DAY`)
				},

			]


			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					<HolidayCreate
						isLoadingControl={isLoadingComponent}
						onCreate={this.onCreateHoliday}
						optionsYear={optionsYear}
						optionsWeekday={optionsWeekday}
						optionsDateType={optionsDateType}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
/* 	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {

		}
	}), */
)


export default enhance(CreateHolidayController)