
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const EventControlStatusSchema = new Schema({
	id: { type: String, default: null },
	locations: { type: Object, default: null },
	resources: { type: Object, default: null },
}, { _id: false })

setBaseSchema(EventControlStatusSchema, "06")

const EventControlStatusModel = mongoose.model("doodCategory", EventControlStatusSchema, "foodCategory")

export default EventControlStatusModel