
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ResourceGroupForm from './ResourceGroupForm'
import ButtonModel from '../../core/model/ButtonModel'
import ResourceGroupModel from '../../models/ResourceGroupModel'

class resourceGroupCreate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = new ResourceGroupModel()
        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onCreate)
        }
    }

    onCreate = () => {
        this.props.onCreate(this.state.model)
    }

    onBack = () => {
        this.props.history.push(`/resourcegroup`)
        //this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsLocationList, optionsResourceList, optionsSensorRuleList, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('resourceGroup.createResource')} buttons={buttons}>
                        <Container text>
                            <div className="body">
                                {
                                    !!model ?
                                        <ResourceGroupForm
                                            model={model}
                                            optionsLocationList={optionsLocationList}
                                            optionsResourceList={optionsResourceList}
                                            optionsSensorRuleList={optionsSensorRuleList}
                                            handleChange={this.handleChange}
                                            errors={modelErrors}
                                            onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                        />
                                        : <p>{t("common.noData")}</p>
                                }
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

resourceGroupCreate.defaultProps = {
    isLoadingControl: false,
    optionsLocationList: [],
    optionsResourceList: [],
    optionsSensorRuleList: [],
    onCreate: () => console.error("Please provide \"Create Resource\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(resourceGroupCreate)