import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetRoomList = () => {
	return {
		type: type.RESET_ROOM_LIST
	}
}

const getRoomInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/room/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROOM_INFO_REQUEST,
				{
					type: type.GET_ROOM_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROOM_INFO_FAILURE
			]
		}
	}
}

const getRoomList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/room${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROOM_LIST_REQUEST,
				{
					type: type.GET_ROOM_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROOM_LIST_FAILURE
			]
		}
	}
}

const getRoomWithIOTConfigInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/room/withstatus/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROOM_WITH_IOT_CONFIG_INFO_REQUEST,
				{
					type: type.GET_ROOM_WITH_IOT_CONFIG_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROOM_WITH_IOT_CONFIG_INFO_FAILURE
			]
		}
	}
}

const getRoomSceneInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/room/scene/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROOM_SCENE_INFO_REQUEST,
				{
					type: type.GET_ROOM_SCENE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROOM_SCENE_INFO_FAILURE
			]
		}
	}
}

export {
    getRoomInfo,
    getRoomList,
	resetRoomList,
	getRoomWithIOTConfigInfo,
	getRoomSceneInfo
}