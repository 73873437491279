
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSettingInfo } from '../../actions/settingActions'
import SettingManager from '../../business/SettingManager'
import SettingUpdate from '../../views/setting/SettingUpdate'
import { getSettingSystemList, getSettingNotificationList } from '../../actions/settingActions'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
import { serviceCode } from '../../../constant'

class UpdateSettingController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSetting = new SettingManager(this)
	}

	getPropsResetStatus() {
		return ["settingInfo"]
	}

	onUpdateSetting = async (model, activeIndex) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSetting.upsertSetting(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/setting/authorization/${activeIndex}`)
				}
				this.setState(body, action)
			})
		})
	}

	testForgotPassword = async (body) => {
		try {
			return this.bSetting.testForgotPassword(body)
		} catch (error) {
			return false
		}
	}

	testResetPassword = async (body) => {
		try {
			return this.bSetting.testResetPassword(body)
		} catch (error) {
			return false
		}
	}

	render() {
		try {
			const { settingInfo, settingSystemList, settingNotificationList, resourceGroupList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const setting = settingInfo.getData()
				, optionsSystemIntegration = settingSystemList.getDatasOption()
				, optionsNotification = settingNotificationList.getDatasOption()
				, optionsResourceGroup = resourceGroupList.getDatasOption()

			let optionsSystemIntegrationList = [
				{
					key: serviceCode.LOCAL,
					value: serviceCode.LOCAL,
					text: serviceCode.LOCAL
				}
			]

			optionsSystemIntegrationList = optionsSystemIntegrationList.concat(optionsSystemIntegration)

			return (
				<React.Fragment>
					{super.render()}
					<SettingUpdate
						isLoadingControl={isLoadingComponent}
						model={setting}
						optionsSystemIntegration={optionsSystemIntegration}
						optionsSystemIntegrationWithLocal={optionsSystemIntegrationList}
						optionsNotification={optionsNotification}
						optionsResourceGroup={optionsResourceGroup}
						testForgotPassword={this.testForgotPassword}
						testResetPassword={this.testResetPassword}
						onUpdate={this.onUpdateSetting}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			settingInfo: state.settingInfo,
			settingSystemList: state.settingSystemList,
			settingNotificationList: state.settingNotificationList,
			resourceGroupList: state.resourceGroupList
		}),
		(dispatch, props) => ({
			getSettingInfo() {
				dispatch(getSettingInfo())
			},
			getSettingSystemList() {
				dispatch(getSettingSystemList())
			},
			getSettingNotificationList() {
				dispatch(getSettingNotificationList())
			},
			getResourceGroupList() {
				dispatch(getResourceGroupList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSettingInfo()
			this.props.getSettingSystemList()
			this.props.getSettingNotificationList()
			this.props.getResourceGroupList()
		}
	}),
)


export default enhance(UpdateSettingController)