
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const Locationchema = new Schema({
	type: { type: String, default: null },
	id: { type: String, default: null },
	code: { type: String, default: null },
	name: { type: String, default: null }
})

const IOTConfigSchema = new Schema({
	id: { type: String, default: null },
	serials: [{ type: String }],
	iotCode: { type: String, default: "" },
	initValue: { type: Object, default: null },
	description: { type: String, default: null },
	controllerId: { type: String, default: null },
	controller: { type: Object, default: null },
	locationId: { type: String, default: null },
	location: { type: Locationchema, default: null },
	isDisable: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(IOTConfigSchema, "06")

IOTConfigSchema.path("controllerId").validate(async function (controllerId) {
	return !!controllerId
}, "validate.require")

IOTConfigSchema.path("iotCode").validate(async function (iotCode) {
	return !!iotCode
}, "validate.require")

IOTConfigSchema.path("serials").validate(async function (serials) {
	return (Array.isArray(serials) && serials.length > 0)
}, "validate.require")

IOTConfigSchema.path("initValue").validate(async function (initValue) {
	return !!initValue
}, "validate.require")

IOTConfigSchema.path("location.type").validate(async function (location) {
	return !!location
}, "validate.require")

IOTConfigSchema.path("location.id").validate(async function (location) {
	return !!location
}, "validate.require")

IOTConfigSchema.methods.serializeAPI = function () {
	try {
		let result = {
			controllerId: this.controllerId,
			iotCode: this.iotCode,
			location: this.location,
			description: this.description,
			initValue: this.initValue,
			serials: this.serials
		}

		return result

	} catch (error) {
		return null
	}
}

const IOTConfigModel = mongoose.model("iotconfig", IOTConfigSchema, "iotconfig")

export default IOTConfigModel