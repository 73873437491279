import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetRFIDCardList = () => {
	return {
		type: type.RESET_RFID_CARD_LIST
	}
}

const getRFIDCardInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/rfidcard/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_RFID_CARD_INFO_REQUEST,
				{
					type: type.GET_RFID_CARD_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_RFID_CARD_INFO_FAILURE
			]
		}
	}
}

const getRFIDCardList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/rfidcard${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_RFID_CARD_LIST_REQUEST,
				{
					type: type.GET_RFID_CARD_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_RFID_CARD_LIST_FAILURE
			]
		}
	}
}

const getRFIDCardTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/rfidcard/type/list`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_RFID_CARD_TYPE_LIST_REQUEST,
				{
					type: type.GET_RFID_CARD_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_RFID_CARD_TYPE_LIST_FAILURE
			]
		}
	}
}

const getRFIDCardDataTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/rfidcard/value/list`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_RFID_CARD_DATA_TYPE_LIST_REQUEST,
				{
					type: type.GET_RFID_CARD_DATA_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_RFID_CARD_DATA_TYPE_LIST_FAILURE
			]
		}
	}
}

export {
    getRFIDCardInfo,
    getRFIDCardList,
	getRFIDCardTypeList,
	getRFIDCardDataTypeList,
	resetRFIDCardList
}