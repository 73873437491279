
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

const Schema = mongoose.Schema

const SensorInformationSchema = new Schema({
    type: { type: String, required: [true, "validate.require"] },
    code: { type: String, required: [true, "validate.require"] },
    name: { type: String, required: [true, "validate.require"] },
    description: { type: String },
    sourceSensorIds: [{ type: String }],
})

setBaseSchema(SensorInformationSchema, "sensor_rule_information")

SensorInformationSchema.path("sourceSensorIds").validate(async function (sourceSensorIds) {
	return sourceSensorIds.length > 0
}, "validate.require")

const InformationModel = mongoose.model("sensor_rule_information", SensorInformationSchema, "sensor_rule_information")

export default InformationModel
