
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Table, Header, Icon } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import LabelInput from '../../common/LabelInput'
import EWSDataModel from '../../../models/systemIntegrationData/EWSDataModel'

class ExchangeDataView extends MyPage {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' className="form header">
                        {t("systemIntegration.exchange")}
                    </Header>
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.domain")} /></Table.Cell>
                                <Table.Cell><p>{model.host}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.allowImpersonate")} /></Table.Cell>
                                <Table.Cell>
                                    {
                                        model.allowImpersonate === true ?
                                            <Icon name="check" color="green" />
                                            : <Icon name="close" color="red" />
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.username")} /></Table.Cell>
                                <Table.Cell><p>{model.username}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.address")} /></Table.Cell>
                                <Table.Cell><p>{model.addressListId}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.version")} /></Table.Cell>
                                <Table.Cell><p>{model.version}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.authType")} /></Table.Cell>
                                <Table.Cell><p>{model.authType}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ExchangeDataView.defaultProps = {
    model: new EWSDataModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ExchangeDataView)