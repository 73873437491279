
import types from '../types'
import { requestStatus } from '../../constant'
import RoomResource from '../selectors/roomResource'
import SceneResource from '../selectors/sceneResource'

const roomReducer = {
	roomInfo: (state = null, action) => {
		if (!state || !(state instanceof RoomResource)) {
			state = new RoomResource()
		}

		switch (action.type) {
			case types.GET_ROOM_INFO_REQUEST:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROOM_INFO_SUCCESS:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ROOM_INFO_FAILURE:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	roomList: (state = null, action) => {
		if (!state || !(state instanceof RoomResource)) {
			state = new RoomResource()
		}

		switch (action.type) {
			case types.GET_ROOM_LIST_REQUEST:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROOM_LIST_SUCCESS:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_ROOM_LIST:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_ROOM_LIST_FAILURE:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	roomWithIOTConfigInfo: (state = null, action) => {
		if (!state || !(state instanceof RoomResource)) {
			state = new RoomResource()
		}

		switch (action.type) {
			case types.GET_ROOM_WITH_IOT_CONFIG_INFO_REQUEST:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROOM_WITH_IOT_CONFIG_INFO_SUCCESS:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ROOM_WITH_IOT_CONFIG_INFO_FAILURE:
				state = new RoomResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	roomSceneInfo: (state = null, action) => {
		if (!state || !(state instanceof SceneResource)) {
			state = new SceneResource()
		}

		switch (action.type) {
			case types.GET_ROOM_SCENE_INFO_REQUEST:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROOM_SCENE_INFO_SUCCESS:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ROOM_SCENE_INFO_FAILURE:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default roomReducer