
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import GoogleAuthenDataModel from '../../../models/systemIntegrationData/GoogleAuthenDataModel'

class GoogleAuthenDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let clientIdValidate = this.getErrorInput("clientId", errors)
                , projectIdValidate = this.getErrorInput("projectId", errors)
                , authUriValidate = this.getErrorInput("authUri", errors)
                , tokenUriValidate = this.getErrorInput("tokenUri", errors)
                , authProviderX509CertUrlValidate = this.getErrorInput("authProviderX509CertUrl", errors)
                , clientSecretValidate = this.getErrorInput("clientSecret", errors)
                , redirectUriValidate = this.getErrorInput("redirectUri", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.googleAuthen")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientId")} isRequire={true} />
                                <Form.Input
                                    name="clientId"
                                    value={model.clientId}
                                    onChange={this.handleChange}
                                    error={clientIdValidate.isError ? clientIdValidate.content : null}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.projectId")} isRequire={true} />
                                <Form.Input
                                    name="projectId"
                                    value={model.projectId}
                                    onChange={this.handleChange}
                                    error={projectIdValidate.isError ? projectIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authUri")} isRequire={true} />
                                <Form.Input
                                    name="authUri"
                                    value={model.authUri}
                                    onChange={this.handleChange}
                                    error={authUriValidate.isError ? authUriValidate.content : null}
                                />

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.tokenUri")} isRequire={true} />
                                <Form.Input
                                    name="tokenUri"
                                    value={model.tokenUri}
                                    onChange={this.handleChange}
                                    error={tokenUriValidate.isError ? tokenUriValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authProviderX509CertUrl")} isRequire={true} />
                                <Form.Input
                                    name="authProviderX509CertUrl"
                                    value={model.authProviderX509CertUrl}
                                    onChange={this.handleChange}
                                    error={authProviderX509CertUrlValidate.isError ? authProviderX509CertUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientSecret")} isRequire={true} />
                                <Form.Input
                                    name="clientSecret"
                                    value={model.clientSecret}
                                    onChange={this.handleChange}
                                    error={clientSecretValidate.isError ? clientSecretValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.redirectUri")} isRequire={true} />
                                <Form.Input
                                    name="redirectUri"
                                    value={model.redirectUri}
                                    onChange={this.handleChange}
                                    error={redirectUriValidate.isError ? redirectUriValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

GoogleAuthenDataForm.defaultProps = {
    model: new GoogleAuthenDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(GoogleAuthenDataForm)