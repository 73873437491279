
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ParentSchema = new Schema({
	id: { type: String, default: null },
	name: { type: String, default: null },
	code: { type: String, default: null },
	parentId: { type: String, default: null },
}, { _id: false })

const LocationTypeSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

	parentId: { type: String, default: "" },
	parent: { type: ParentSchema, default: null },
}, { _id: false })

setBaseSchema(LocationTypeSchema, "03")



// LocationTypeSchema.methods.serialize = function () {
// 	try {
// 		return {
// 			id: this.id,
// 			code: this.code,
// 			name: this.name,
// 			description: this.description,
// 		}
// 	} catch (error) {
// 		return null
// 	}
// }

// LocationTypeSchema.methods.serializeAPI = function () {
// 	try {
// 		return {
// 			code: this.code,
// 			name: this.name,
// 			description: this.description ? this.description : "",
// 		}
// 	} catch (error) {
// 		return null
// 	}
// }

const LocationTypeModel = mongoose.model("locationType", LocationTypeSchema, "locationType")

export default LocationTypeModel