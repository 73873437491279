import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetGatewayList = () => {
	return {
		type: type.RESET_GATEWAY_LIST
	}
}

const getGatewayInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/gateway/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_GATEWAY_INFO_REQUEST,
				{
					type: type.GET_GATEWAY_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_GATEWAY_INFO_FAILURE
			]
		}
	}
}

const getGatewayList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/gateway${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_GATEWAY_LIST_REQUEST,
				{
					type: type.GET_GATEWAY_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_GATEWAY_LIST_FAILURE
			]
		}
	}
}

export {
    getGatewayInfo,
    getGatewayList,
	resetGatewayList
}