
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Grid, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import VisitorGroupModel from '../../models/VisitorGroupModel'
import LabelInput from '../common/LabelInput'
import ViewNoResult from '../common/ViewNoResult'

class VisitorGroupView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/visitorgroup/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/visitorgroup/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/visitorgroup`)
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformVisitorGroup(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t } = this.props
            const { model, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )
            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('visitorGroup.viewVisitor')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

VisitorGroupView.defaultProps = {
    model: new VisitorGroupModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(VisitorGroupView)