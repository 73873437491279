
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import SMS8X8DataModel from '../../../models/systemIntegrationData/SMS8X8DataModel'

class SMS8X8DataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props

            let urlValidate = this.getErrorInput("url", errors)
                , tokenValidate = this.getErrorInput("token", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.SMS8X8")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.url")} isRequire={true} />
                                <Form.Input
                                    name="url"
                                    value={model.url}
                                    onChange={this.handleChange}
                                    error={urlValidate.isError ? urlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.token")} isRequire={true} />
                                <Form.Input
                                    name="token"
                                    value={model.token}
                                    onChange={this.handleChange}
                                    error={tokenValidate.isError ? tokenValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SMS8X8DataForm.defaultProps = {
    model: new SMS8X8DataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SMS8X8DataForm)