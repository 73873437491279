
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import O365DataModel from '../../../models/systemIntegrationData/O365DataModel'

class O365DataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let apiUrlValidate = this.getErrorInput("apiUrl", errors)
                , authenUrlValidate = this.getErrorInput("authenUrl", errors)
                , clientIdValidate = this.getErrorInput("clientId", errors)
                , clientSecretValidate = this.getErrorInput("clientSecret", errors)
                , tenantIdValidate = this.getErrorInput("tenantId", errors)
                , redirectUrlValidate = this.getErrorInput("redirectUrl", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.o365")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.apiUrl")} isRequire={true} />
                                <Form.Input
                                    name="apiUrl"
                                    value={model.apiUrl}
                                    onChange={this.handleChange}
                                    error={apiUrlValidate.isError ? apiUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authenUrl")} isRequire={true} />
                                <Form.Input
                                    name="authenUrl"
                                    value={model.authenUrl}
                                    onChange={this.handleChange}
                                    error={authenUrlValidate.isError ? authenUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientId")} isRequire={true} />
                                <Form.Input
                                    name="clientId"
                                    value={model.clientId}
                                    onChange={this.handleChange}
                                    error={clientIdValidate.isError ? clientIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.clientSecret")} isRequire={true} />
                                <Form.Input
                                    name="clientSecret"
                                    value={model.clientSecret}
                                    onChange={this.handleChange}
                                    error={clientSecretValidate.isError ? clientSecretValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.tenantId")} isRequire={true} />
                                <Form.Input
                                    name="tenantId"
                                    value={model.tenantId}
                                    onChange={this.handleChange}
                                    error={tenantIdValidate.isError ? tenantIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.redirectUrl")} isRequire={true} />
                                <Form.Input
                                    name="redirectUrl"
                                    value={model.redirectUrl}
                                    onChange={this.handleChange}
                                    error={redirectUrlValidate.isError ? redirectUrlValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

O365DataForm.defaultProps = {
    model: new O365DataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(O365DataForm)