
// import types from '../types'
// import errorResource from '../selectors/errorResource'
import ErrorModel from '../core/model/ErrorModel'

const errorReducer = {
    errorList: (state = null, action) => {
        // if (!state || !(state instanceof errorResource)) {
        //     state = new errorResource()
        // }
        if (!state || !Array.isArray(state)) {
            state = []
        }

        let typeSp = action.type ? action.type.split("_") : []
            , type = typeSp.length > 0 ? typeSp[typeSp.length - 1] : ""
        switch (type) {
            case "FAILURE":
                let errors = []
                if (Array.isArray(action.errorList)) {
                    errors.concat(action.errorList)
                }
                if (!!action.payload) {
                    if (Array.isArray(action.payload) && action.payload.length > 0) {
                        action.payload.forEach(obj => {
                            if (!!obj && obj instanceof ErrorModel) {
                                errors.push(obj)
                            }
                        });
                    } else {
                        if (!!action.payload && action.payload instanceof ErrorModel) {
                            errors.push(action.payload)
                        }
                    }
                }
                state = errors
                break
            case "RESETERROR":
                state = []
                break
            default:
                break
        }

        return state
    }
}

export default errorReducer