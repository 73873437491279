
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

const Schema = mongoose.Schema

const ControlDataSchema = new Schema({
	type: { type: String, required: [true, "validate.require"] },
	id: { type: String, required: [true, "validate.require"] },
	code: { type: String, required: [true, "validate.require"] },
	name: { type: String, required: [true, "validate.require"] },
	actionMode: { type: String, enum: ["ON", "OFF"], required: [true, "validate.require"] },
	sceneId: { type: String },
	detail: { type: String },
}, { _id: false })

ControlDataSchema.path("sceneId").validate(async function (sceneId) {
	if (this.actionMode === "OFF") {
		return true
	}

	return !!sceneId
}, "validate.require")

setBaseSchema(ControlDataSchema)

ControlDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			type: this.type,
			id: this.id,
			code: this.code,
			name: this.name,
			actionMode: this.actionMode,
			sceneId: this.sceneId,
		}
		return result
	} catch (error) {
		return null
	}
}

const ControlDataModel = mongoose.model("sensor_rule_action_control", ControlDataSchema, "sensor_rule_action_control")

export default ControlDataModel
