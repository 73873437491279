
import types from '../types'
import { requestStatus } from '../../constant'
import RoleResource from '../selectors/roleResource'

const roleReducer = {
	roleInfo: (state = null, action) => {
		if (!state || !(state instanceof RoleResource)) {
			state = new RoleResource()
		}

		switch (action.type) {
			case types.GET_ROLE_INFO_REQUEST:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROLE_INFO_SUCCESS:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ROLE_INFO_FAILURE:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	roleList: (state = null, action) => {
		if (!state || !(state instanceof RoleResource)) {
			state = new RoleResource()
		}

		switch (action.type) {
			case types.GET_ROLE_LIST_REQUEST:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ROLE_LIST_SUCCESS:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_ROLE_LIST:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_ROLE_LIST_FAILURE:
				state = new RoleResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default roleReducer