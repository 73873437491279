
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import HolidayForm from './HolidayForm'
import ButtonModel from '../../core/model/ButtonModel'
import HolidayModel from '../../models/HolidayModel'

class HolidayUpdate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = nextProps.model
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onView = (model) => {
        this.props.history.push(`/holiday/view/${model.id}`)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onUpdate = () => {
        let model = this.state.model
        this.props.onUpdate(model)
    }

    onBack = () => {
        this.props.history.push(`/holiday`)
        //this.props.history.goBack()
    }

    onDeleteDate = (index) => {
        const model = this.state.model
        if (Array.isArray(model.dateInfo)) {
            model.dateInfo.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitDate = (dateInfo, status, index) => {
        const model = this.state.model
        const modelErrors = this.state.modelErrors

        if (!!dateInfo) {
            if (status === "CREATE") {
                model.dateInfo.push(dateInfo)
            } else if (status === "UPDATE") {
                model.dateInfo[index] = dateInfo
            }

            if (Array.isArray(modelErrors) && modelErrors.length > 0) {
                let newModelErrors = {}
                let index = modelErrors.findIndex(e => e.path === "dateInfo")
                if (index > -1) {
                    modelErrors.splice(index, 1);
                    newModelErrors = modelErrors
                }

                this.setState({
                    model: model,
                    modelErrors: newModelErrors
                })
            } else {
                this.setState({
                    model: model
                })
            }

        }
    }

    render() {
        try {
            const { t, optionsYear, optionsWeekday, optionsDateType, isLoadingControl } = this.props
            const { model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('holiday.updateHoliday')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                !!model ?
                                                    <HolidayForm
                                                        model={model}
                                                        handleChange={this.handleChange}
                                                        errors={modelErrors}
                                                        optionsYear={optionsYear}
                                                        optionsWeekday={optionsWeekday}
                                                        optionsDateType={optionsDateType}
                                                        onDeleteDate={this.onDeleteDate}
                                                        onSubmitDate={this.onSubmitDate}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

HolidayUpdate.defaultProps = {
    isLoadingControl: false,
    optionsYear: [],
    optionsWeekday: [],
    optionsDateType: [],
    model: new HolidayModel(),
    onUpdate: () => console.error("Please provide \"Update\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(HolidayUpdate)