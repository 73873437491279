
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewUser from '../../views/user/ViewUser'
import { getUserInfo, getUserIntegrationList } from '../../actions/userActions'
import utils from '../../core/utility/utils'
import UserManager from '../../business/UserManager'

class ViewUserController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false

        this.bUser = new UserManager(this)
    }

    getPropsResetStatus() {
        return ["userInfo"]
    }

    onUnintegtationUser = (model, serviceInfo, systemIntegrationId) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.unintegrateUser(model.id, serviceInfo, systemIntegrationId, (res) => {

                let tempState = {
                    isLoadingComponent: false,
                }

                let action = { }
                
                if (serviceInfo === "CONTROL_USER") {
                    action = () => { this.props.history.replace(`/user`) }
                } else {
                    action = () => { this.props.getUserInfo(model.id) }
                }
                    

                this.setState(tempState, action)
            })
        })
    }

    onADIntegrationUser = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.userIntegrationAD(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getUserInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onExchangeIntegrationUser = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.userIntegrationExchange(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getUserInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onGoogleIntegrationUser = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.userIntegrationGoogle(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getUserInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onO365IntegrationUser = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.userIntegrationO365(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getUserInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    testSyncPlatformUser = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bUser.userTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { userInfo, userIntegrationList } = this.props
            const { isLoadingComponent } = this.props
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const userInfoData = userInfo.getData()
            , userIntegrationDatas = userIntegrationList.getDatas()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewUser
                        model={userInfoData}
                        userIntegrationList={userIntegrationDatas}
                        onUnintegtationUser={this.onUnintegtationUser}
                        onADIntegrationUser={this.onADIntegrationUser}
                        onExchangeIntegrationUser={this.onExchangeIntegrationUser}
                        onGoogleIntegrationUser={this.onGoogleIntegrationUser}
                        onO365IntegrationUser={this.onO365IntegrationUser}
                        testSyncPlatformUser={this.testSyncPlatformUser}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            userInfo: state.userInfo,
            userIntegrationList: state.userIntegrationList
        }),
        (dispatch, props) => ({
            getUserInfo(id) {
                dispatch(getUserInfo(id))
            },
            getUserIntegrationList(id) {
                dispatch(getUserIntegrationList(id))
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getUserInfo(utils.getParamFromProps(this.props, "id"))
            this.props.getUserIntegrationList(utils.getParamFromProps(this.props, "id"))
        }
    })
)


export default enhance(ViewUserController)