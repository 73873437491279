
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import VisitorForm from './VisitorForm'
import ButtonModel from '../../core/model/ButtonModel'
import VisitorModel from '../../models/VisitorModel'

class VisitorUpdate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = nextProps.model
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onView = (model) => {
        this.props.history.push(`/visitor/view/${model.id}`)
    }

    onUpdate = () => {
        let model = this.state.model
        this.props.onUpdate(model)
    }

    onBack = () => {
        this.props.history.push(`/visitor`)
    }

    onChangeVisitorGroup = (visitorGroupIds) => {

        if (!!visitorGroupIds && Array.isArray(visitorGroupIds)) {
            let state = this.state
            state.model.visitorGroupIds = visitorGroupIds

            let index = state.modelErrors.findIndex(e => e.path === "visitorGroupIds")
            if (index > -1) {
                state.modelErrors.splice(index, 1);
            }
        }
    }

    render() {
        try {
            const { t, optionVisitorGroup, model, isLoadingControl } = this.props
            const { modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('visitor.updateVisitor')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                !!model ?
                                                    <VisitorForm
                                                        model={model}
                                                        errors={modelErrors}
                                                        handleChange={this.handleChange}
                                                        optionVisitorGroup={optionVisitorGroup}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                        onChangeVisitorGroup={this.onChangeVisitorGroup}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

VisitorUpdate.defaultProps = {
    isLoadingControl: false,
    model: new VisitorModel(),
    optionVisitorGroup: [],
    visitorInfo: null,
    onUpdate: () => console.error("Please provide \"Update\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(VisitorUpdate)