
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Table, Header } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import LabelInput from '../../common/LabelInput'
import SMS8X8DataModel from '../../../models/systemIntegrationData/SMS8X8DataModel'

class SMS8X8DataView extends MyPage {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' className="form header">
                        {t("systemIntegration.SMS8X8")}
                    </Header>
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.url")} /></Table.Cell>
                                <Table.Cell><p>{model.url}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.token")} /></Table.Cell>
                                <Table.Cell><p>{model.token}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SMS8X8DataView.defaultProps = {
    model: new SMS8X8DataModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SMS8X8DataView)