import React from 'react'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'
import { compose } from 'recompose'
// import { Link } from 'react-router-dom'
import MyPage from '../../core/components/MyPage'

class ViewImage extends MyPage {

    render() {
        const { imageUrl, onLoadImage, content, isRatio, isCircular, ratioArea } = this.props

        try {
            return (
                <div className={"module viewImage"}>
                    <div className={`create-body-image ${isCircular ? "circular" : isRatio ? "" : "square"}`}>
                        <div className="image-wrapper">
                            <div className="box-image">
                                <div className={`render-image ${isRatio ? `ratio${!!ratioArea ? "-" + ratioArea : ""}` : "noratio"}`}>
                                    {!!imageUrl && <img src={imageUrl} onLoad={onLoadImage} alt="" />}
                                </div>
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}


ViewImage.defaultProps = {
    imageUrl: null,
    onLoadImage: () => { },
    content: null,
    isRatio: true,
    isCircular: false,
    ratioArea: "" // width,heigth
}

export default compose(
    withRouter,
    translate,
)(ViewImage)