
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'
import utils from '../../core/utility/utils'

const Schema = mongoose.Schema
// enum: ["Exchange2010", "Exchange2013", "Exchange2016"],
const EWSDataSchema = new Schema({
	host: { type: String, required: [true, "validate.require"], default: "" },
	username: { type: String, required: [true, "validate.require"], default: "" },
	password: { type: String, required: [true, "validate.require"], default: "" },
	addressListId: { type: String, default: "" },
	version: { type: String, required: [true, "validate.require"], default: "" },
	authType: { type: String, default: "" },
	allowImpersonate: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(EWSDataSchema, "system_integration_ews")

EWSDataSchema.methods.setData = function (data) {
	try {
		if (!Array.isArray(data) && typeof data === "object") {
			for (const key in data) {
				if (key in this) {
					if (key === "password") {
						this[key] = utils.decode(data[key])
					} else {
						this[key] = data[key]
					}
				}
			}
		}
	} catch (error) {

	}
}

EWSDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			host: this.host,
			username: this.username,
			password: utils.encode(this.password),
			addressListId: this.addressListId,
			version: this.version,
			authType: this.authType,
			allowImpersonate: this.allowImpersonate,
		}
		return result
	} catch (error) {
		console.log(error)
		return null
	}
}

const EWSDataModel = mongoose.model("system_integration_ews", EWSDataSchema, "system_integration_ews")

export default EWSDataModel