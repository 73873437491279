
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormLocation from './FormLocation'

import ButtonModel from '../../core/model/ButtonModel'
import LocationModel from '../../models/LocationModel'
import { defaultValue } from '../../../constant'

class ViewCreateLocation extends MyPage {
    constructor(props) {
        super(props)

        let parentId = (!!props.parent && !!props.parent.id ? props.parent.id : "")
            , locationTypeId = (Array.isArray(props.optionsLocationType) && props.optionsLocationType.length > 0 ? props.optionsLocationType[0].value : "")

        this.state.parentId = parentId
        this.state.locationTypeId = locationTypeId
        this.state.model = new LocationModel({ parentId: parentId, locationTypeId: !!locationTypeId ? locationTypeId : defaultValue.PLEASE_SELECT })
        this.state.modelErrors = []

        this.state.model.formType = "CREATE_LOCATION"

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        const newParentId = (!!nextProps.parent && !!nextProps.parent.id ? nextProps.parent.id : "")
        tempState.parentId = newParentId
        const newLocationTypeId = (Array.isArray(nextProps.optionsLocationType) && nextProps.optionsLocationType.length > 0 ? nextProps.optionsLocationType[0].value : "")
        tempState.locationTypeId = newLocationTypeId
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.parentId !== this.state.parentId) {
            this.setState({
                model: new LocationModel({ parentId: this.state.parentId, locationTypeId: this.state.locationTypeId })
            })
        }
        if (prevState.locationTypeId !== this.state.locationTypeId) {
            this.setState({
                model: new LocationModel({ parentId: this.state.parentId, locationTypeId: this.state.locationTypeId })
            })
        }
    }

    componentDidMount() {
        let getParentArray = this.findParentLocationType()
        this.props.filterLocationList(getParentArray)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onCreate(this.state.model)
    }

    onCancel = () => {
        this.props.history.push(`/location`)
    }

    findParentLocationType = () => {
        let { model } = this.state
        const { optionsLocationType } = this.props

        let parentArray = []
        let findParentStop = true

        while (findParentStop) {
            if (parentArray.length === 0) {
                let currentId = model.locationTypeId
                let findInOptions = optionsLocationType.find(data => currentId === data.value)
                let findParent = findInOptions

                if (!!findParent && !!findParent.parent) {
                    parentArray.push(findParent.parent.id)
                } else {
                    findParentStop = false
                }

            } else if (parentArray.length > 0) {
                let currentId = parentArray[parentArray.length - 1]
                let findInOptions = optionsLocationType.find(data => currentId === data.value)
                let findParent = findInOptions

                if (!!findParent && !!findParent.parent) {
                    parentArray.push(findParent.parent.id)
                } else {
                    findParentStop = false
                }

            } else {
                findParentStop = false
            }
        }

        return parentArray
    }

    handleChangeLocationType = (e, { name, value }) => {
        let model = this.state.model
        let modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []
        let tempState = {}

        if (model && name in model) {
            model[name] = value
            this.setState({ model })
        }

        let index = modelErrors.findIndex(e => e.path === name)
        if (index > -1) {
            modelErrors.splice(index, 1);
            tempState.modelErrors = modelErrors
        }

        this.setState(tempState)

        let getParentArray = this.findParentLocationType()
        this.props.filterLocationList(getParentArray)
    }

    render() {
        try {
            const { t, optionsLocationType, optionsTreeLocation, isLoadingControl } = this.props
            const { isLoading, model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let tempOptionsLocationType = []
            if (Array.isArray(optionsLocationType) && optionsLocationType.length === 0) {
                tempOptionsLocationType = [
                    { key: defaultValue.PLEASE_SELECT, value: defaultValue.PLEASE_SELECT, text: t("common.pleaseSelect") }
                ]
            } else {
                tempOptionsLocationType = optionsLocationType
            }

            let buttons = [
                new ButtonModel({
                    text: t("common.create"),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t("location.createLocation")} buttons={buttons}>
                        <div className="module viewCreateLocation">
                            <Container text>
                                <Grid>
                                    <Grid.Column width={16}>
                                        <FormLocation
                                            model={model}
                                            errors={modelErrors}
                                            optionsLocationType={tempOptionsLocationType}
                                            handleChange={this.handleChange}
                                            optionsTreeLocation={optionsTreeLocation}
                                            handleChangeLocationType={this.handleChangeLocationType}
                                        />
                                    </Grid.Column>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewCreateLocation.defaultProps = {
    isLoadingControl: false,
    optionsTreeLocation: [],
    optionsLocationType: [],
    parent: null,
    onCreate: () => console.error("Please provide Create action.")
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(ViewCreateLocation)