import mongoose from 'mongoose'
import utils from '../../utility/utils'

const Schema = mongoose.Schema

const PagingSchema = new Schema({
    pageNumber: { type: Number, default: 1 },
    pageSize: { type: Number, default: 10 },
    totalItem: { type: Number, default: 0 },
    id: { type: String, default: utils.makeid(40) }
}, { _id: false })

export default PagingSchema