
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getRoleInfo } from '../../actions/roleActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import RoleManager from '../../business/RoleManager'
import RoleView from '../../views/role/RoleView'

class ViewRoleController extends MyController {
	constructor(props) {
		super(props)

		this.bRole = new RoleManager(this)
	}

	getPropsResetStatus() {
		return ["roleInfo"]
	}

	render() {
		try {
			const { roleInfo } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const role = roleInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<RoleView
							model={role}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			roleInfo: state.roleInfo
		}),
		(dispatch, props) => ({
			getRoleInfo() {
				dispatch(getRoleInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getRoleInfo()
		}
	}),
)


export default enhance(ViewRoleController) 