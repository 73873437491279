
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'

import ForgotPasswordModel from '../../models/ForgotPasswordModel'
import { withRouter } from 'react-router-dom'

class ForgotPasswordForm extends MyForm {

    render() {
        const { t, model, errors } = this.props
        if (!model) {
            return null
        }
        let textSearchValidate = this.getErrorInput("username", errors)

        try {
            return (
                <React.Fragment>
                    {/* <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off"> */}
                    <Grid className="grid-half-padding">
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("auth.username")} />
                                <input
                                    name="username"
                                    style={{ display: "none" }}
                                />
                                <Form.Input
                                    name="textSearch"
                                    value={model.textSearch}
                                    onChange={this.handleChange}
                                    icon='users'
                                    iconPosition='left'
                                    placeholder={t("auth.enterUsername")}
                                    fluid
                                    error={textSearchValidate.isError ? textSearchValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {/* </Form> */}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ForgotPasswordForm.defaultProps = {
    model: new ForgotPasswordModel()
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)

export default enhance(ForgotPasswordForm)