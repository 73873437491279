
import moment from 'moment'
import mongoose from 'mongoose'
import { dateTimeFormat } from '../../constant'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const DateInfoSchema = new Schema({
	type: { type: String },
	startDateTime: { type: Date },
	endDateTime: { type: Date },
	weekday: [{ type: String }]
}, { _id: false })

const HolidaySchema = new Schema({
	id: { type: String, default: "" },
	subject: { type: String, required: [true, "validate.require"], default: "" },
	year: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },
	dateInfo: [{ type: DateInfoSchema }]

}, { _id: false })

setBaseSchema(HolidaySchema, "06")

HolidaySchema.methods.serializeAPI = function () {
	try {
		let result = {
			subject: this.subject,
			year: this.year,
			description: this.description
		}

		let dateInfos = []
		if (Array.isArray(this.dateInfo)) {
			this.dateInfo.forEach(info => {
				dateInfos.push({
					type: info.type,
					startDateTime: info.startDateTime ? moment(info.startDateTime).format(dateTimeFormat.apiFullDate) : null,
					endDateTime: info.endDateTime ? moment(info.endDateTime).format(dateTimeFormat.apiFullDate) : null,
					weekday: Array.isArray(info.weekday) ? info.weekday.map(e => e) : []
				})
			})
		}
		result.dateInfo = dateInfos
		return result

	} catch (error) {
		return null
	}
}

HolidaySchema.path("dateInfo").validate(async function (dateInfo) {
	return (Array.isArray(dateInfo) && dateInfo.length > 0)
}, "validate.require")

const HolidayModel = mongoose.model("holiday", HolidaySchema, "holiday")

export default HolidayModel