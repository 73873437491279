
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import UserModel from '../../models/UserModel'

class UserStatusForm extends MyForm {
    render() {
        const { model, optionUserStatus } = this.props

        return (
            <React.Fragment>
                <Form>
                    <Form.Dropdown
                        fluid
                        selection
                        options={optionUserStatus}
                        onChange={this.handleChange}
                        name="status"
                        value={model.status}
                    />
                </Form>
            </React.Fragment>
        )
    }
}

UserStatusForm.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    model: new UserModel(),
    optionUserStatus: [],
    error: [],
}

export default (translate(UserStatusForm))