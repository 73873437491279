import common from './en/common.json'
import home from './en/home.json'
import location from './en/location.json'
import auth from './en/auth.json'
import validator from './en/validator.json'
import button from './en/button.json'
import locationType from './en/locationType.json'
import department from './en/department.json'
import message from './en/message.json'
import resourceType from './en/resourceType.json'
import resource from './en/resource.json'
import visitorGroup from './en/visitorGroup.json'
import resourceGroup from './en/resourceGroup.json'
import counter from './en/counter.json'
import desk from './en/desk.json'
import device from './en/device.json'
import kiosk from './en/kiosk.json'
import room from './en/room.json'
import user from './en/user.json'
import visitor from './en/visitor.json'
import account from './en/account.json'
import systemIntegration from './en/systemIntegration.json'
import display from './en/display.json'
import admin from './en/admin.json'
import flightboard from './en/flightboard.json'
import facilityCategory from './en/facilityCategory.json'
import facility from './en/facility.json'
import foodCategory from './en/foodCategory.json'
import food from './en/food.json'
import tempCloseSchedule from './en/tempCloseSchedule.json'
import termCondition from './en/termCondition.json'
import availableTimeSlot from './en/availableTimeSlot.json'
import role from './en/role.json'
import background from './en/background.json'
import displayProfile from './en/displayProfile.json'
import roomFormat from './en/roomFormat.json'
import setting from './en/setting.json'
import controller from './en/controller.json'
import gateway from './en/gateway.json'
import iotConfig from './en/iotConfig.json'
import holiday from './en/holiday.json'
import scheduleControl from './en/scheduleControl.json'
import door from './en/door.json'
import sensorRule from './en/sensorRule.json'
import notification from './en/notification.json'
import sensor from './en/sensor.json'
import settingSensor from './en/settingSensor.json'
import report from './en/report.json'
import constantTranslate from './en/constantTranslate.json'
import eventControlRule from './en/eventControlRule.json'
import eventControlStatus from './en/eventControlStatus.json'
import rfidCard from './en/rfidCard.json'

const en = {
    ...common,
    ...home,
    ...location,
    ...auth,
    ...validator,
    ...button,
    ...locationType,
    ...department,
    ...message,
    ...resourceType,
    ...resource,
    ...visitorGroup,
    ...resourceGroup,
    ...counter,
    ...desk,
    ...device,
    ...kiosk,
    ...room,
    ...user,
    ...visitor,
    ...account,
    ...systemIntegration,
    ...display,
    ...admin,
    ...flightboard,
    ...facilityCategory,
    ...facility,
    ...foodCategory,
    ...food,
    ...tempCloseSchedule,
    ...termCondition,
    ...availableTimeSlot,
    ...role,
    ...background,
    ...displayProfile,
    ...roomFormat,
    ...setting,
    ...controller,
    ...gateway,
    ...iotConfig,
    ...holiday,
    ...scheduleControl,
    ...door,
    ...sensorRule,
    ...notification,
    ...sensor,
    ...settingSensor,
    ...report,
    ...constantTranslate,
    ...eventControlRule,
    ...eventControlStatus,
    ...rfidCard,
}

export default en