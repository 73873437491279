
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const VisitorGroupSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

}, { _id: false })

setBaseSchema(VisitorGroupSchema, "06")

const VisitorGroupModel = mongoose.model("visitorGroup", VisitorGroupSchema, "visitorGroup")

VisitorGroupSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
		}

		return result
		
	} catch (error) {
		return null
	}
}

export default VisitorGroupModel