
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'
import ViewTable from '../../core/view/common/table/ViewTable'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchIOTConfig from './FormSearchIOTConfig'
import IOTConfigModel from '../../models/IOTConfigModel'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import ModalSyncIOTConfig from './ModalSyncIOTConfig'

class IOTConfigList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.modelSearch = new IOTConfigModel()
        this.state.model = null
    }

    componentDidMount() {
        this.onSearch(this.state.modelSearch)
    }

    onClickDelete = (model) => {
        const { t, onDeleteIOTConfig } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteIOTConfig(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickStatus = (model) => {
        const { t, onChangeDisableIOTConfig, onChangeEnableIOTConfig } = this.props
        try {
            if (!!model) {
                if (model.isDisable === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.disableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeDisableIOTConfig(model, () => this.onSearch(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.enableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeEnableIOTConfig(model, () => this.onSearch(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                }
            }

        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("iotConfig.type"),
                selector: (row, i) => {
                    if (row.location === null) {
                        return '-'
                    } else {
                        return t(`constantTranslate.iotConfigLocation.${row.location.type}`)
                    }
                },
                sortable: false,
                width: '150px'
            },
            {
                name: t("location.title"),
                selector: (row, i) => {
                    if (row.location === null) {
                        return "-"
                    } else {
                        return row.location.name
                    }

                },
                sortable: false,
                width: '300px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: t("common.enable"),
                selector: (row, i) => {
                    if (row.isDisable === null) {
                        return ""
                    } else if (row.isDisable === false) {
                        return <Icon color="green" name="checkmark" />
                    } else if (row.isDisable === true) {
                        return <Icon color="red" name="close" />
                    }
                },
                sortable: false,
                center: true,
                width: '100px'
            },
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                cell: row => {
                    let buttons = []

                    if (!!row) {
                        if (row.isDisable === false) {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/iotconfig/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusDisable"),
                                    color: "red",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "close",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.props.history.push(`/iotconfig/update/${row.id}`) },
                                    iconClass: "edit",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.delete"),
                                    color: "red",
                                    onClick: () => { this.onClickDelete(row) },
                                    iconClass: "trash",
                                    size: "mini"
                                }
                            ]
                        } else {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/iotconfig/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusEnable"),
                                    color: "green",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "check",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.props.history.push(`/iotconfig/update/${row.id}`) },
                                    iconClass: "edit",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.delete"),
                                    color: "red",
                                    onClick: () => { this.onClickDelete(row) },
                                    iconClass: "trash",
                                    size: "mini"
                                }
                            ]
                        }
                    }

                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickAdd = () => {
        this.props.history.push(`/iotconfig/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let locationId = ""
                if (!!model.locationId && model.locationId !== defaultValue.SELECT_ALL) {
                    locationId = model.locationId
                }

                let locationType = ""
                if (!!model.location && !!model.location.type && model.location.type !== defaultValue.SELECT_ALL) {
                    locationType = model.location.type
                }

                let controllerId = ""
                if (!!model.controllerId && model.controllerId !== defaultValue.SELECT_ALL) {
                    controllerId = model.controllerId
                }

                let querys = [
                    {
                        name: "locationType",
                        value: locationType
                    },
                    {
                        name: "locationId",
                        value: locationId,
                    },
                    {
                        name: "controllerId",
                        value: controllerId
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    render() {
        try {
            const { t, iotConfigList, isLoadingTable, optionLocationList, optionsTypeList, optionResourceList, optionsControllerList } = this.props
            const { isSearch, isLoading, isOpenModalSync, iotConfigsPlatform } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {isOpenModalSync && <ModalSyncIOTConfig iotConfigsPlatform={iotConfigsPlatform} onSubmit={this.onSubmitModalSync} onClose={this.onCloseModalSync} />}
                    <LayoutWithSidebarHeaderFooter textHeader={t('iotConfig.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchIOTConfig
                                                    onSearch={this.onSearch}
                                                    optionLocationList={optionLocationList}
                                                    optionsTypeList={optionsTypeList}
                                                    optionResourceList={optionResourceList}
                                                    optionsControllerList={optionsControllerList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTable
                                                columns={this.getColumnConfig()}
                                                datas={iotConfigList}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

IOTConfigList.defaultProps = {
    iotConfigList: [],
    isLoadingTable: false,
    optionLocationList: [],
    optionsTypeList: [], 
    optionResourceList: [], 
    optionsControllerList: [],
    onDeleteIOTConfig: () => console.error("Please provide \"Delete IOTConfig\" action."),
    onReloadData: () => console.error("Please provide Load Data action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(IOTConfigList)