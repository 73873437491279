import React from 'react'
import DataTable from 'react-data-table-component'
import { translate } from 'react-switch-lang'
import MyPage from '../../../components/MyPage'
import Pagination from './Pagination'
import PagingDataModel from '../../../model/PagingDataModel'

class ViewTable extends MyPage {
    constructor(props) {
        super(props)

        this.state.pageSizeSelect = 10
    }

    onChangePage = (page, totalRows) => {
        if (typeof this.props.onChangePage === "function") {
            this.props.onChangePage(page, totalRows)
        }
    }

    convertToPageModel = (data, mapDatas) => {
        try {
            let model = new PagingDataModel()
            model.pagination.pageNumber = data.currentPage
            model.pagination.pageSize = data.rowsPerPage
            model.pagination.totalItem = mapDatas.length
            model.datas = mapDatas.map(e => e).splice((model.pagination.pageNumber - 1) * model.pagination.pageSize, ((model.pagination.pageNumber - 1) * model.pagination.pageSize) + model.pagination.pageSize)
            return model
        } catch (error) {
            return null
        }
    }

    onChangePagination = (pagingModel, data) => {
        const { currentPage, rowsPerPage, onChangePage, onChangeRowsPerPage } = data
        if (pagingModel.pageNumber !== currentPage) {
            onChangePage(pagingModel.pageNumber, data.rowCount)
        } else if (pagingModel.pageSize !== rowsPerPage) {
            onChangeRowsPerPage(pagingModel.pageSize, pagingModel.pageNumber)
            this.setState({
                pageSizeSelect: pagingModel.pageSize
            });
        }
    }

    render() {
        const { isShowPagination, columns, datas, t, isLoading, isShowNoData, noDataComponent, fillEmptyRows } = this.props
        const { pageSizeSelect } = this.state
        
        if (!Array.isArray(columns)) {
            return null
        }

        let mapColums = columns
            , mapDatas = []

        if (Array.isArray(datas)) {
            mapDatas = datas.map((d, i) => {
                if (typeof d === "string") {
                    return { d, numRow: i }
                }
                return { ...d, numRow: i }
            })
        }

        if (fillEmptyRows) {
            let newLength = mapDatas.length + (pageSizeSelect - (mapDatas.length % pageSizeSelect))
            mapDatas = mapDatas.concat((new Array(newLength)).fill("empty_arr")).slice(0, newLength);
        }

        const customStyles = {
            headRow: {
                style: {
                    backgroundColor: '#ccc'

                }
            },
            headCells: {
                style: {
                    color: '#202124',
                    fontSize: '14px',
                    fontWeight: 'bold'
                },
            },
            rows: {
                highlightOnHoverStyle: {
                    backgroundColor: 'rgb(230, 244, 244)',
                    borderBottomColor: '#FFFFFF',
                    borderRadius: '25px',
                    outline: '1px solid #FFFFFF',
                },
            },
            pagination: {
                style: {
                    border: 'none',
                },
            },
        }

        const conditionalRowStyles = [
            {
                when: (row) => {
                    const modNo = row.numRow % 2
                    return modNo === 0
                },
                style: {
                    backgroundColor: 'rgba(62, 144, 299, 15%)',
                    // '&:hover': {
                    // 	cursor: 'pointer',
                    // },
                },
            },
            {
                when: (row) => {
                    const modNo = row.numRow % 2
                    return modNo !== 0
                },
                style: {
                    backgroundColor: 'rgba(237, 237, 237, 45%)',
                    // '&:hover': {
                    // 	cursor: 'pointer',
                    // },
                },
            },
            {
                when: (row) => {
                    return row === "empty_arr"
                },
                style: {
                    backgroundColor: 'rgba(237, 237, 237, 100%)',
                }
            },
        ]

        const paginationOptions = { rowsPerPageText: t("common.rowsPerPage"), rangeSeparatorText: t("common.rangeSeparator") }

        let pagination = true
        if (typeof isShowPagination === "boolean") {
            pagination = isShowPagination
        }

        return (
            <div className="module viewTable">
                <div className="view-table-wrapper">
                    <DataTable
                        columns={mapColums}
                        data={mapDatas}
                        pagination={pagination}
                        paginationComponentOptions={paginationOptions}
                        paginationComponent={(data) => {
                            let pagingDataModel = this.convertToPageModel(data, mapDatas)
                            return <Pagination pagingDataModel={pagingDataModel} onChangePagination={(pagingModel) => this.onChangePagination(pagingModel, data)} />
                        }}
                        customStyles={customStyles}
                        noHeader={true}
                        conditionalRowStyles={conditionalRowStyles}
                        noDataComponent={isShowNoData ? (noDataComponent ? noDataComponent : <div className="vt-no-data"><p>{t("common.noData")}</p></div>) : null}
                        progressPending={isLoading}
                        progressComponent={<div className="vt-loading">{this.getLoadingComponent()}</div>}
                        persistTableHead={true}
                        responsive={false}
                        fixedHeader={this.onChangePage}
                    />
                </div>
            </div >
        )
    }
}

ViewTable.defaultProps = {
    columns: [],
    datas: [],
    noDataComponent: null,
    isShowPagination: true,
    isLoading: false,
    isShowNoData: true,

    onChangePage: null,
    fillEmptyRows: false, // if you want the table to fill in extra rows when not having enough data (e.g if pagination is 10, and the data array only has 5, the table will fill the rest with empty rows.)
}

export default translate(ViewTable)