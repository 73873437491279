
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const LineDataSchema = new Schema({
	channelAccessToken: { type: String, required: [true, "validate.require"], default: "" },
	channelSecret: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(LineDataSchema, "system_integration_line")

const LineDataModel = mongoose.model("system_integration_line", LineDataSchema, "system_integration_line")

export default LineDataModel