import mongoose from 'mongoose'

const Schema = mongoose.Schema

const FileSchema = new Schema({
    fileName: { type: String, default: null },
    size: { type: Number, default: null },
    extension: { type: String, default: null },
    mimeType: { type: String, default: null },
    content: { type: String, default: null },
}, { _id: false })

export default FileSchema