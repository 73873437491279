
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LocationModel from '../../models/LocationModel'
import LabelInput from '../common/LabelInput'
import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'
import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'

class FormLocation extends MyForm {

    render() {
        const { t, model, errors, optionsLocationType, optionsTreeLocation } = this.props

        let codeValidate = this.getErrorInput("code", errors)
            , nameValidate = this.getErrorInput("name", errors)
            , locationTypeValidate = this.getErrorInput("locationTypeId", errors)
            , locationParentValidate = this.getErrorInput("parentId", errors)

        try {
            return (
                <React.Fragment>
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.code")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.name")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        fluid
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("locationType.title")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        placeholder={t("common.pleaseSelect")}
                                        options={optionsLocationType}
                                        onChange={this.props.handleChangeLocationType}
                                        name="locationTypeId"
                                        value={model.locationTypeId}
                                        error={locationTypeValidate.isError ? locationTypeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("location.parent")} isRequire={false} />
                                    <ViewTreeSelect
                                        label={t("location.parent")}
                                        name="parentId"
                                        datas={optionsTreeLocation}
                                        value={model.parentId}
                                        onChange={this.handleChange}
                                        placeholder={t("common.pleaseSelect")}
                                        error={locationParentValidate.isError ? locationParentValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row >
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.TextArea
                                        rows={5}
                                        onChange={this.handleChange}
                                        name="description"
                                        value={model.description} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.image")} isRequire={false} />
                                    <ViewSelectImageOrTakePhoto name="fileUpload" defaultImage={!!model.id ? `location/${model.id}` : ""} onChange={this.handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormLocation.defaultProps = {
    errors: [],
    handleChange: () => console.error("Please provide \"Handle Change\" action"),
    model: new LocationModel(),
    optionsLocationType: [],
    optionsTreeLocation: [],
}

export default (translate(FormLocation))