
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetHolidayList = () => {
	return {
		type: type.RESET_HOLIDAY_LIST
	}
}

const getHolidayInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/holiday/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_HOLIDAY_INFO_REQUEST,
				{
					type: type.GET_HOLIDAY_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_HOLIDAY_INFO_FAILURE
			]
		}
	}
}

const getHolidayList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/holiday${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_HOLIDAY_LIST_REQUEST,
				{
					type: type.GET_HOLIDAY_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_HOLIDAY_LIST_FAILURE
			]
		}
	}
}

export {
	getHolidayInfo,
	getHolidayList,
	resetHolidayList
}