
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import SensorUpsert from './SensorUpsert'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import SensorForm from './SensorForm'
import ButtonModel from '../../core/model/ButtonModel'
import SensorModel from '../../models/SensorModel'

class SensorCreate extends SensorUpsert {
    constructor(props) {
        super(props)

        this.state.model = new SensorModel({
            location: {
                type: "",
                location: ""
            }
        })
    }

    render() {
        try {
            const { t, controllerList, optionsControllerList, optionsLocationList, optionsResourceList, optionsSensorTypeList, optionsSensorModelList, optionsSensorModuleList, optionsSensorLocationTypeList, isLoadingControl } = this.props
            const { model, modelErrors, isLoading, optionsModule, serialList } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit, model),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('sensor.createSensor')} buttons={buttons}>
                        <Container text>
                            <div className="body">
                                {
                                    !!model ?
                                        <SensorForm
                                            model={model}
                                            controllerList={controllerList}
                                            optionsControllerList={optionsControllerList}
                                            optionsLocationList={optionsLocationList}
                                            optionsResourceList={optionsResourceList}
                                            optionsModuleList={optionsModule}
                                            optionsSensorTypeList={optionsSensorTypeList}
                                            optionsSensorModelList={optionsSensorModelList}
                                            optionsSensorModuleList={optionsSensorModuleList}
                                            optionsSensorLocationTypeList={optionsSensorLocationTypeList}
                                            optionsSerialList={serialList}
                                            handleChange={this.handleChange}
                                            onchangeController={this.onchangeController}
                                            onchangeModule={this.onchangeModule}
                                            handleChangeSerialList={this.handleChangeSerialList}
                                            onchangeLocationType={this.onchangeLocationType}
                                            onchangeLocationId={this.onchangeLocationId}
                                            errors={modelErrors}
                                            onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                        />
                                        : <p>{t("common.noData")}</p>
                                }
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorCreate.defaultProps = {
    isLoadingControl: false,
    controllerList: [],
    optionsControllerList: [],
    optionsLocationList: [],
    optionsResourceList: [],
    optionsSensorTypeList: [],
    optionsSensorModelList: [],
    optionsSensorModuleList: [],
    optionsSensorLocationTypeList: [],
    onCreate: () => console.error("Please provide \"Create Sensor\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SensorCreate)