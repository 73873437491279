
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class SensorRuleActionResultResource extends ActionResourceObject {

	setResource(data) {
		try {

			if (!data) {
				throw new Error("Data not found")
			}

			let result = null

			if (Array.isArray(data)) {
				result = data.map(d => d)
			} else {
				result = data
			}

			return result
		} catch (error) {
			return null
		}
	}
}