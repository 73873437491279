
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ExchangeIntegrationSchema = new Schema({
	id: { type: String, default: "" },
	displayName: { type: String, default: "" },
	userPrincipalName: { type: String, default: "" },
	phones: [{ type: Object }],
}, { _id: false })

setBaseSchema(ExchangeIntegrationSchema, "exchangeIntegration")


ExchangeIntegrationSchema.methods.serializeAPI = function () {
	try {
		let result = {

		}

		return result

	} catch (error) {
		return null
	}
}

const ExchangeIntegrationModel = mongoose.model("exchangeIntegration", ExchangeIntegrationSchema, "exchangeIntegration")

export default ExchangeIntegrationModel