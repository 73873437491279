import types from '../types'
import { requestStatus } from '../../constant'
import SystemIntegrationResource from '../selectors/SystemIntegrationResource'
import SystemIntegrationServiceListResource from '../selectors/systemIntegrationResource/systemIntegrationServiceListResource'

const systemIntegrationReducer = {
    systemIntegrationServiceList: (state = null, action) => {
        if (!state || !(state instanceof SystemIntegrationServiceListResource)) {
            state = new SystemIntegrationServiceListResource()
        }

        switch (action.type) {
            case types.GET_SYSTEM_INTEGRATION_SERVICE_LIST_REQUEST:
                state = new SystemIntegrationServiceListResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break

            case types.GET_SYSTEM_INTEGRATION_SERVICE_LIST_SUCCESS:
                state = new SystemIntegrationServiceListResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.GET_SYSTEM_INTEGRATION_SERVICE_LIST_FAILURE:
                state = new SystemIntegrationServiceListResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                break

            default:
                break
        }
        return state
    },
    systemIntegrationList: (state = null, action) => {
        if (!state || !(state instanceof SystemIntegrationResource)) {
            state = new SystemIntegrationResource()
        }
        switch (action.type) {
            case types.GET_SYSTEM_INTEGRATION_LIST_REQUEST:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break

            case types.GET_SYSTEM_INTEGRATION_LIST_SUCCESS:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.GET_SYSTEM_INTEGRATION_LIST_FAILURE:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                break

            default:
                break
        }
        return state
    },
    systemIntegrationInfo: (state = null, action) => {
        if (!state || !(state instanceof SystemIntegrationResource)) {
            state = new SystemIntegrationResource()
        }
        switch (action.type) {
            case types.GET_SYSTEM_INTEGRATION_INFO_REQUEST:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break

            case types.GET_SYSTEM_INTEGRATION_INFO_SUCCESS:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.GET_SYSTEM_INTEGRATION_INFO_FAILURE:
                state = new SystemIntegrationResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                break

            default:
                break
        }
        return state
    }
}

export default systemIntegrationReducer