
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getSettingInfo } from '../../actions/settingActions'
import { translate } from 'react-switch-lang'
import SettingManager from '../../business/SettingManager'
import SettingView from '../../views/setting/SettingView'

class ViewSettingController extends MyController {
	constructor(props) {
		super(props)

		this.bSetting = new SettingManager(this)
	}

	getPropsResetStatus() {
		return ["settingInfo"]
	}

	testForgotPassword = async (body) => {
		try {
			return this.bSetting.testForgotPassword(body)
		} catch (error) {
			return false
		}
	}

	testResetPassword = async (body) => {
		try {
			return this.bSetting.testResetPassword(body)
		} catch (error) {
			return false
		}
	}

	render() {
		try {
			const { settingInfo } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const setting = settingInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<SettingView
							model={setting}
							testForgotPassword={this.testForgotPassword}
							testResetPassword={this.testResetPassword}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			settingInfo: state.settingInfo
		}),
		(dispatch, props) => ({
			getSettingInfo() {
				dispatch(getSettingInfo())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSettingInfo()
		}
	}),
)


export default enhance(ViewSettingController)