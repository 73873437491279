
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'
import BoxModalHeaderWithContent from '../common/BoxModalHeaderWithContent'

class ModalAddIdName extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.model = {
            id: "",
            name: ""
        }
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.model)
    }

    isDisabledSubmit = (data) => {
        if (!!data || !!data.id || !!data.name) {
            return false
        }
        return true
    }

    render() {
        try {
            const { model } = this.state
            const { t, onClose, headerText } = this.props

            // let headerText = ""

            // if (name === "Group") {
            //     headerText = t("notification.addGroup")
            // } else if (name === "User") {
            //     headerText = t("notification.addUser")
            // }

            return this.getModalContent(this.getModalOption({
                headerText: headerText,
                onClose: () => { },
                content: <BoxModalHeaderWithContent
                    content={
                        <React.Fragment>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('common.id')} isRequire={true} />
                                        <Form.Input
                                            name="id"
                                            value={model.id}
                                            onChange={this.handleChange}
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('common.name')} isRequire={true} />
                                        <Form.Input
                                            name="name"
                                            value={model.name}
                                            onChange={this.handleChange}
                                            fluid
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <br></br>
                        </React.Fragment>
                    }
                />,
                buttons: [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(model)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalAddIdName.defaultProps = {
    headerText: "",
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalAddIdName))