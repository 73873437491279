
//import React from 'react'
import MyPage from '../../core/components/MyPage'

class IOTConfigUpsert extends MyPage {
    constructor(props) {
        super(props)

        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = (isDisable, model) => {
        if (isDisable === false) {
            this.onValidate(() => this.onCreate(model))
        }
    }

    onCreate = (model) => {
        this.props.onCreate(model)
    }

    onBack = () => {
        //this.props.history.push(`/iotconfig`)
        this.props.history.goBack()
    }

    onchangeController = (controller) => {
        let model = this.state.model
        model.controllerId = controller.id
        this.onchangeIOT("")
        this.removeError("controllerId")
    }

    onchangeIOT = (iotCode) => {
        let model = this.state.model
        model.iotCode = iotCode

        let initalVal = null
        
        if (!!model.initValue) {
            initalVal = model.initValue
        } else {
            switch (iotCode) {
                case "LIGHT":
                    initalVal = "OFF"
                    break;
                case "CURTAIN":
                    initalVal = "STOP"
                    break;
                case "LIGHT_DMX":
                    initalVal = {
                        r: 0,
                        g: 0,
                        b: 0
                    }
                    break;
                case "LIGHT_RGB":
                    initalVal = {
                        r: 0,
                        g: 0,
                        b: 0
                    }
                    break;
                case "LIGHT_DALI":
                    initalVal = "0"
                    break;
                case "BACNET":
                    initalVal = {
                        status: "OFF",
                        temp: 25
                    }
                    break;
                default:
                    initalVal = null
                    break;
            }
        }

        model.initValue = initalVal

        this.handleChangeSerialList([])
        this.removeError("iotCode")
    }

    onchangeLocationType = (locationType) => {
        let model = this.state.model
        model.location.type = locationType
        model.location.id = ""

        this.setState({ model })
        this.removeError("location.type")
    }

    onchangeLocationId = (locationId) => {
        let model = this.state.model
        model.location.id = locationId

        this.setState({ model })
        this.removeError("location.id")
    }

    handleChangeSerialList = (selectedSerialList) => {
        let model = this.state.model
        model.serials = selectedSerialList

        this.setState({ model })
        this.removeError("serials")
    }

    removeError(code) {
        let modelErrors = this.state.modelErrors
        let index = modelErrors.findIndex(e => e.path === code)
        if (index > -1) {
            modelErrors.splice(index, 1)
            this.setState({ modelErrors })
        }
    }
}

export default IOTConfigUpsert