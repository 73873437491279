
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getSensorInfo } from '../../actions/sensorActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import SensorManager from '../../business/SensorManager'
import SensorView from '../../views/sensor/SensorView'

class ViewSensorController extends MyController {
	constructor(props) {
		super(props)

		this.bSensor = new SensorManager(this)
		this.state.isLoadingComponent = false
	}

	getPropsResetStatus() {
		return ["sensorInfo"]
	}

	onDeleteAreaSensor = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bSensor.deleteAreaSensor(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getSensorInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaSensor = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bSensor.upsertAreaSensor(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getSensorInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationSensor = (model, serviceInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bSensor.unintegrateSensor(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getSensorInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}


	render() {
		try {
			const { sensorInfo, locationList, isLoadingComponent } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const sensor = sensorInfo.getData()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let locationInfo = null

			if (!!sensor) {
				if (!!sensor.locationId) {
					locationInfo = locationList.findDataByKey("id", sensor.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<SensorView
							model={sensor}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaSensor={this.onDeleteAreaSensor}
							onUpsertAreaSensor={this.onUpsertAreaSensor}
							onUnintegtationSensor={this.onUnintegtationSensor}
							locationList={locationDatas}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			sensorInfo: state.sensorInfo,
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getSensorInfo() {
				dispatch(getSensorInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getSensorInfo()
		}
	}),
)


export default enhance(ViewSensorController)