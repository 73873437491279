
import React from 'react'
import { translate } from 'react-switch-lang'
import { Checkbox, Icon, Form } from 'semantic-ui-react'
//import ViewButton from '../../../views/common/ViewButton'
import MyComponent from '../../components/MyComponent'
import ButtonModel from '../../model/ButtonModel'
import '../../scss/viewTreeSelect.scss'

class ViewTreeSelect extends MyComponent {
    constructor(props) {
        super(props)
        this.state.isOpenModal = false
        this.state.selectValue = null
        this.state.selectData = null

        this.state.datasRender = []

        this.state.multiple = false
    }

    onClickDropdown = () => {
        const { datas, value, multiple } = this.props
        let tempState = {
            isOpenModal: true,
            multiple: typeof multiple === "boolean" ? multiple : false
        }
        let newDatas = []
        if (Array.isArray(datas) && datas.length > 0) {
            newDatas = datas.map(e => e)
        }
        let tempValue = null
        if (tempState.multiple === true) {
            if (Array.isArray(value)) {
                tempValue = value.map(e => e)
            } else {
                tempValue = [value]
            }
        } else {
            if (!Array.isArray(value)) {
                tempValue = value
            }
        }
        tempState.datasRender = newDatas
        tempState.selectValue = tempValue
        this.setState(tempState, () => this.checkAutoExpand(this.state.datasRender, this.state.selectValue))
    }

    autoExpand = (model, selectValue) => {
        if (!!model) {
            if (
                (Array.isArray(selectValue) && !!selectValue.find(v => v === model.value)) ||
                (!Array.isArray(selectValue) && selectValue === model.value)
            ) {
                model.onExpand()
            }
            if (Array.isArray(model.children) && model.children.length > 0) {
                model.children.forEach(childModel => {
                    return this.autoExpand(childModel, selectValue)
                })
            }
        }
    }

    checkAutoExpand = async (datas, selectValue) => {
        await datas.forEach((model) => {
            return this.autoExpand(model, selectValue)
        })
        this.forceUpdate()
    }

    onCloseModalSelect = () => {
        this.setState({
            isOpenModal: false
        })
    }

    onSubmitModalSelect = (selectValue) => {
        this.setState({
            isOpenModal: false
        }, () => {
            if (!!this.props.name) {
                this.props.onChange(null, { name: this.props.name, value: selectValue })
            }
        })
    }

    getOptionModalSelect = (datas, selectValue) => {
        const { t, label, multiple } = this.props
        let textDisplay = this.getValueDisplay(multiple, datas, selectValue)
        return this.getInfoModalOption({
            onClose: this.onCloseModalSelect,
            headerText: label,
            content: <div className="module modalTreeSelect">
                <div className="mts-wrapper">
                    <div className={`box-list`}>
                        {this.generateList(datas, selectValue)}
                    </div>
                    <div className="box-your-select">
                        <p><b>{t("common.yourSelect")}: </b> {textDisplay ? textDisplay : "-"}</p>
                    </div>
{/*                     <div className="box-action">
                        <ViewButton
                            model={new ButtonModel({
                                text: t("common.ok"),
                                color: "blue",
                                disabled: !selectValue,
                                onClick: () => this.onSubmitModalSelect(selectValue)
                            })}
                        />
                        <ViewButton
                            model={new ButtonModel({
                                text: t("common.cancel"),
                                color: "red",
                                onClick: this.onCloseModalSelect
                            })}
                        />
                    </div> */}
                </div>
            </div>,
            buttons: [
                new ButtonModel({
                    text: t("common.ok"),
                    color: "green",
                    // disabled: !selectValue,
                    onClick: () => this.onSubmitModalSelect(selectValue)
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onCloseModalSelect
                })
            ],
        })
    }

    handleChange = (e, { name, value, checked }, model) => {
        const { multiple, selectValue } = this.state
        let body = {}
        if (checked === true) {
            if (multiple === true) {
                let tempValue = []
                if (Array.isArray(selectValue)) {
                    tempValue = tempValue.concat(selectValue)
                }
                if (!tempValue.find(v => v === value)) {
                    tempValue.push(value)
                }
                body.selectValue = tempValue
            } else {
                body.selectValue = value
            }
        } else {
            if (multiple === true) {
                let tempValue = []
                if (Array.isArray(selectValue)) {
                    tempValue = tempValue.concat(selectValue)
                }
                let indexValue = tempValue.findIndex(v => v === value)
                if (indexValue > -1) {
                    tempValue.splice(indexValue, 1)
                }
                body.selectValue = tempValue
            } else {
                body.selectValue = null
            }
        }

        this.setState(body)
    }

    onClickExpand = (no, model) => {
        if (model.expanded === true) {
            model.onCollapse()
        } else {
            model.onExpand()
        }
        this.forceUpdate()
    }

    handleChangeExpand = (round, no, data, dataSelect) => {
        let newRound = (round + 1)
        if (round === no) {
            if (data.value === dataSelect.value) {
                data.expanded = (!data.expanded)
            }
            return data
        } else {
            data.children.map(d => {
                return this.handleChangeExpand(newRound, no, d, dataSelect)
            })
            return data
        }
    }

    getDataSelect = (datas, selectValue) => {
        let dataSelect = null
        let childrens = []
        if (Array.isArray(datas) && datas.length > 0) {
            datas.forEach(data => {
                if (!dataSelect) {
                    if (data.value === selectValue) {
                        dataSelect = data
                    } else {
                        if (data.children.length > 0) {
                            childrens = childrens.concat(data.children)
                        }
                    }
                }
            });
        }
        if (!!dataSelect || childrens.length === 0) {
            return dataSelect
        } else {
            return this.getDataSelect(childrens, selectValue)
        }
    }

    getContentItem = (menu, no, selectValue) => {
        const { multiple } = this.state
        let propsInput = {}
        if (multiple === false) {
            propsInput.radio = true
        }
        let newNo = (no + 1)
            , isChildren = (Array.isArray(menu.children) && menu.children.length > 0)
            , isChecked = (Array.isArray(selectValue) ? !!selectValue.find(m => m === menu.value) : (menu.value === selectValue))
        return <div className="parent" key={menu.value} style={{ paddingLeft: (5 * no) + "px" }} >
            <div className={`box-icon-expand ${menu.expanded ? "expanded" : ""}`} onClick={() => this.onClickExpand(no, menu)}>
                {isChildren && <Icon name="caret right" />}
            </div>
            <div className={`box-checkbox ${menu.expanded ? "expanded" : ""}`}>
                <div className="b-c-wrapper">
                    <div className="b-c-input"
                        onClick={(e) => this.handleChange(e, { name: "selectValue", value: menu.value, checked: !isChecked }, menu)}
                    >
                        <Checkbox
                            {...propsInput}
                            // label={menu.text}
                            name="selectValue"
                            value={menu.value}
                            checked={isChecked}
                        // onChange={(e, d) => this.handleChange(e, d, menu)}
                        />
                        {
                            !!menu.imageURL &&
                            <div className="image">
                                <img src={menu.imageURL} alt="No Data" />
                            </div>
                        }
                        {
                            !!menu.iconClass &&
                            <div className="icon">
                                <Icon name={menu.iconClass} color={menu.iconColor ? menu.iconColor : "black"} />
                            </div>
                        }
                        <div className="text">
                            <p>{menu.text}</p>
                        </div>
                        {/* <div className="empty"></div> */}
                    </div>
                    {
                        (isChildren) &&
                        <div className={`children`}>
                            {
                                menu.children.map(menu => {
                                    return this.getContentItem(menu, newNo, selectValue)
                                })
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    generateList = (datas, selectValue) => {
        let content = <React.Fragment>
            {
                datas.map((menu) => {
                    return this.getContentItem(menu, 0, selectValue)
                })
            }
        </React.Fragment>

        return content
    }

    getValueDisplay = (multiple, datas, value) => {
        try {
            let text = ""
                , isMultiple = (typeof multiple === "boolean" ? multiple : false)
            if (isMultiple === true) {
                let modelSelects = []
                if (Array.isArray(value) && value.length > 0) {
                    value.forEach(id => {
                        let modelSelect = this.getDataSelect(datas, id)
                        if (!!modelSelect) {
                            modelSelects.push(modelSelect)
                        }
                    });
                    if (modelSelects.length > 0) {
                        text = modelSelects.map(m => m.text).join(", ")
                    }
                }
            } else if(value === "Not Select") {
                text = "Not Select"
            } else {
                let modelSelect = this.getDataSelect(datas, value)
                if (!!modelSelect) {
                    text = modelSelect.text
                }
            }
            return text
        } catch (error) {
            return ""
        }
    }

    render() {
        const { value, datas, error, placeholder, multiple } = this.props
        const { isOpenModal, datasRender, selectValue } = this.state

        return (
            <React.Fragment>
                {isOpenModal && this.getModalContent(this.getOptionModalSelect(datasRender, selectValue))}
                <div className="module viewTreeSelect">
                    <div className="vts-wrapper">
                        <div className="box-input" onClick={this.onClickDropdown}>
                            <Form.Input value={this.getValueDisplay(multiple, datas, value)} disabled error={error} placeholder={placeholder} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ViewTreeSelect.defaultProps = {
    name: "",
    value: "",
    label: "",
    placeholder: "",
    datas: [],
    multiple: false,
    onChange: () => console.error("Please provide On Change Image action.")
}


export default translate(ViewTreeSelect)