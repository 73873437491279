
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import HolidayManager from '../../business/HolidayManager'
import HolidayList from '../../views/holiday/HolidayList'
import { defaultValue } from '../../../constant'

class HolidayController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bHoliday = new HolidayManager(this)
	}

	getPropsResetStatus() {
		return ["holidayList"]
	}

	onDeleteHoliday = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bHoliday.deleteHoliday(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})

	}

	getHolidayListPaging = (query) => {
		return this.bHoliday.getHolidayListPaging(query)
	}

	render() {
		try {
			const { t } = this.props
			const { isLoadingComponent } = this.state

			let currentYear = parseInt(moment().format("YYYY"))

			const optionsYearRaw = [
				currentYear,
				currentYear + 1,
				currentYear + 2,
				currentYear + 3,
				currentYear + 4,
				currentYear + 5,
				currentYear + 6,
				currentYear + 7,
				currentYear + 8,
				currentYear + 9,
				currentYear + 10
			]

			let optionsYear = optionsYearRaw.map((d, i) => {
				return {
					key: "" + d,
					value: "" + d,
					text: "" + d
				}
			})

			let optionConcatList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			optionsYear = optionConcatList.concat(optionsYear)

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<HolidayList
						optionsYear={optionsYear}
						onDeleteHoliday={this.onDeleteHoliday}
						onGetDataPaging={this.getHolidayListPaging}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	/* 	connect(
			(state, props) => ({
				
			}),
			(dispatch, props) => ({
		
			}),
		),
		lifecycle({
			componentDidMount() {
			
			}
		}), */
)


export default enhance(HolidayController) 