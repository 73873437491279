
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import SystemIntegrationForm from './SystemIntegrationForm'
import ButtonModel from '../../core/model/ButtonModel'
import SystemIntegrationModel from '../../models/SystemIntegrationModel'
import MyPageSystemIntegration from './MyPageSystemIntegration'

class SystemIntegrationCreate extends MyPageSystemIntegration {
    constructor(props) {
        super(props)
        this.state.model = new SystemIntegrationModel()
        this.state.modelErrors = []
        this.state.isLoading = false

        this.isTest = null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onCreate)
        }
    }

    onCreate = (model) => {
        this.props.onCreate(model)
    }

    onBack = () => {
        this.props.history.push(`/systemintegration`)
        //this.props.history.goBack()
    }

    onTestSystemIntegration = () => {
        return this.props.onTestSystemIntegration(this.state.model)
    }

    render() {
        try {
            const { t, optionsSystemIntegrationService, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}

                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('systemIntegration.createSystemIntegration')} buttons={buttons}>
                        <Container text>
                            <div>
                                <SystemIntegrationForm
                                    model={model}
                                    optionsSystemIntegrationService={optionsSystemIntegrationService}
                                    handleChange={this.handleChange}
                                    handleChangeMultiValue={this.handleChangeMultiValue}
                                    errors={modelErrors}
                                    onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                    onTestSystemIntegration={this.onTestSystemIntegration}
                                />
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SystemIntegrationCreate.defaultProps = {
    isLoadingControl: false,
    optionsSystemIntegrationService: [],
    onCreate: () => console.error("Please provide \"Create System Integration\" action."),
    onTestSystemIntegration: () => {
        console.error("Please provide \"Test\" action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SystemIntegrationCreate)