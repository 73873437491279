
import ActionResourceObject from '../core/redux/ActionResourceObject'
import ResourceModel from '../models/ResourceModel'

export default class ResourceResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new ResourceModel(d))
			} else {
				result = new ResourceModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

}