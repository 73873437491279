
import ActionResourceObject from '../core/redux/ActionResourceObject'
import SettingLoginModel from '../models/SettingLoginModel'
import SystemIntegrationManager from '../business/SystemIntegrationManager'

export default class SettingLoginInfoResource extends ActionResourceObject {
	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}
			let bSystemIntegration = new SystemIntegrationManager()
			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					let model = new SettingLoginModel(d)
					if (!!d.service && !!d.data) {
						let GetDataModel = bSystemIntegration.getSystemIntegrationDataModel(d.service)
						if (GetDataModel !== null) {
							let modelData = new GetDataModel()
							modelData.setData(d.data)
							model.data = modelData
						} else {
							model.data = null
						}
					}
					return model
				})
			} else {
				let model = new SettingLoginModel(data)
				if (!!data.service && !!data.data) {
					let GetDataModel = bSystemIntegration.getSystemIntegrationDataModel(data.service)
					if (GetDataModel !== null) {
						let modelData = new GetDataModel()
						modelData.setData(data.data)
						model.data = modelData
					} else {
						model.data = null
					}
				}
				result = model
			}
			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.name
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.name
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}