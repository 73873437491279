
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../../core/components/MyController'
import ViewSelectUser from '../../../views/user/importExternalUser/ViewSelectUser'
import AdminManager from '../../../business/AdminManager'
import { systemIntegrationService } from '../../../../constant'

class SelectUserController extends MyController {
    constructor(props) {
        super(props)
        const { dataStep } = this.props
        let serviceCode = null
            , serviceId = null
        if (!!dataStep && !!dataStep.service && !!dataStep.service.service) {
            serviceId = dataStep.service.id
            serviceCode = dataStep.service.service
        }
        this.state.isLoadingData = false
        this.state.serviceId = serviceId
        this.state.serviceCode = serviceCode

        this.state.userList = []
        this.state.userRenderList = []

        this.bAdmin = new AdminManager(this)
    }

    componentDidMount() {
        const { serviceCode, serviceId } = this.state
        if (serviceCode === systemIntegrationService.ACTIVE_DIRECTORY) {
            this.onGetUserImportAD(serviceId, serviceCode)
        } else if (serviceCode === systemIntegrationService.EXCHANGE) {
            this.onGetUserImportExchange(serviceId, serviceCode)
        }
    }

    onGetUserImportAD = (serviceId, serviceCode) => {
        this.setState({
            isLoadingData: true
        }, async () => {
            let res = await this.bAdmin.getUserImportAD(serviceId)
                , dataRenderList = this.makeDataUserForRender(res, serviceCode)
            let tempState = {
                isLoadingData: false,
                userList: res,
                userRenderList: dataRenderList
            },
                action = () => { }
            this.setState(tempState, action)
        })
    }

    onGetUserImportExchange = (serviceId, serviceCode) => {
        this.setState({
            isLoadingData: true
        }, async () => {
            let res = await this.bAdmin.getUserImportExchange(serviceId)
                , dataRenderList = this.makeDataUserForRender(res, serviceCode)
            let tempState = {
                isLoadingData: false,
                userList: res,
                userRenderList: dataRenderList
            },
                action = () => { }
            this.setState(tempState, action)
        })
    }

    makeDataUserForRender = (datas, serviceCode) => {
        try {
            let userRenderList = []
            if (serviceCode === systemIntegrationService.ACTIVE_DIRECTORY) {
                datas.forEach(m => {
                    if (!!m.sAMAccountName) {
                        userRenderList.push({
                            text: !!m.givenName ? m.givenName : m.sAMAccountName,
                            value: m.sAMAccountName
                        })
                    }
                })
            } else if (serviceCode === systemIntegrationService.EXCHANGE) {
                datas.forEach(m => {
                    if (!!m.userPrincipalName) {
                        userRenderList.push({
                            text: !!m.displayName ? m.displayName : m.userPrincipalName,
                            value: m.userPrincipalName
                        })
                    }
                })
            }

            return userRenderList
        } catch (error) {
            return []
        }
    }

    onBack = () => {
        this.props.onBack()
    }

    onNext = (userSelects) => {
        let data = {
            userSelects: userSelects,
        }
        this.props.onNext(data)
    }

    onCancel = () => {
        this.props.onCancel()
    }
    render() {
        try {
            const { dataStep } = this.props
            const { userRenderList, isLoadingData } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {/* {isLoadingData && this.getLoadingPage()} */}
                    <ViewSelectUser
                        isLoadingData={isLoadingData}
                        dataStep={dataStep}
                        users={userRenderList}
                        // optionsImportService={optionsImportService}
                        // optionsResourceGroup={optionsResourceGroup}
                        onBack={this.onBack}
                        onNext={this.onNext}
                        onCancel={this.onCancel}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

SelectUserController.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {

        }
    })
)


export default enhance(SelectUserController)