
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetResourceGroupList = () => {
	return {
		type: type.CORE_RESET_RESOURCE_GROUP_LIST
	}
}

const getResourceGroupInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/resourcegroup/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_RESOURCE_GROUP_INFO_REQUEST,
				{
					type: type.CORE_GET_RESOURCE_GROUP_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_RESOURCE_GROUP_INFO_FAILURE
			]
		}
	}
}

const getResourceGroupList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/resourcegroup${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_RESOURCE_GROUP_LIST_REQUEST,
				{
					type: type.CORE_GET_RESOURCE_GROUP_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_RESOURCE_GROUP_LIST_FAILURE
			]
		}
	}
}

const getResourceGroupUserList = (query = "") => {
	let url = `${CORE_API_ENDPOINT}/user${query}`

	return {
		[CALL_API]: {
			endpoint: url,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_RESOURCE_GROUP_USER_LIST_REQUEST,
				{
					type: type.CORE_GET_RESOURCE_GROUP_USER_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_RESOURCE_GROUP_USER_LIST_FAILURE
			]
		}
	}
}

export {
	getResourceGroupInfo,
	getResourceGroupList,
	resetResourceGroupList,
	getResourceGroupUserList
}