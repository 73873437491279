
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import RoomModel from '../../models/RoomModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchRoom extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new RoomModel()
        this.state.model.locationId = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new RoomModel({ code: "", givenName: "", locationId: defaultValue.SELECT_ALL, description: "" })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionLocationList } = this.props

            return (
                <div className="module formSearchRoom">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("common.code")} isRequire={false} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.name")} isRequire={false} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("common.location")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionLocationList}
                                        onChange={this.handleChange}
                                        name="locationId"
                                        value={model.locationId}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                    <Form.Input
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )

        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchRoom.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new RoomModel(),
    optionLocationList: []
}

export default (translate(FormSearchRoom))