
import types from '../types'
import { requestStatus } from '../../constant'
import UserResource from '../selectors/userResource'
import UserStatusResource from '../selectors/userStatusResource'
import IntegrationResource from '../selectors/integrationResource'
import adIntegrationResource from '../selectors/adIntegrationResource'
import exchangeIntegrationResource from '../selectors/exchangeIntegrationResource'

const userReducer = {
	userInfo: (state = null, action) => {
		if (!state || !(state instanceof UserResource)) {
			state = new UserResource()
		}

		switch (action.type) {
			case types.GET_USER_INFO_REQUEST:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_INFO_SUCCESS:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_USER_INFO_FAILURE:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	userList: (state = null, action) => {
		if (!state || !(state instanceof UserResource)) {
			state = new UserResource()
		}

		switch (action.type) {
			case types.GET_USER_LIST_REQUEST:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_LIST_SUCCESS:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_USER_LIST:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue([])
				break
			case types.GET_USER_LIST_FAILURE:
				state = new UserResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	userStatusList: (state = null, action) => {
		if (!state || !(state instanceof UserStatusResource)) {
			state = new UserStatusResource()
		}

		switch (action.type) {
			case types.GET_USER_STATUS_LIST_REQUEST:
				state = new UserStatusResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_STATUS_LIST_SUCCESS:
				state = new UserStatusResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_USER_STATUS_LIST_FAILURE:
				state = new UserStatusResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	userIntegrationList: (state = null, action) => {
		if (!state || !(state instanceof IntegrationResource)) {
			state = new IntegrationResource()
		}

		switch (action.type) {
			case types.GET_USER_INTEGRATION_LIST_REQUEST:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_INTEGRATION_LIST_SUCCESS:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_USER_INTEGRATION_LIST_FAILURE:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	userADList: (state = null, action) => {
		if (!state || !(state instanceof adIntegrationResource)) {
			state = new adIntegrationResource()
		}

		switch (action.type) {
			case types.GET_USER_ACTIVE_DIRECTORY_LIST_REQUEST:
				state = new adIntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_ACTIVE_DIRECTORY_LIST_SUCCESS:
				state = new adIntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_USER_ACTIVE_DIRECTORY_LIST_FAILURE:
				state = new adIntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	userExchangeList: (state = null, action) => {
		if (!state || !(state instanceof exchangeIntegrationResource)) {
			state = new exchangeIntegrationResource()
		}

		switch (action.type) {
			case types.GET_USER_EXCHANGE_LIST_REQUEST:
				state = new exchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_USER_EXCHANGE_LIST_SUCCESS:
				state = new exchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_USER_EXCHANGE_LIST_FAILURE:
				state = new exchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default userReducer