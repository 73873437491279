
//import React from 'react'
import MyPage from '../../core/components/MyPage'

class SensorUpsert extends MyPage {
    constructor(props) {
        super(props)

        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = (isDisable, model) => {
        if (isDisable === false) {
            this.onValidate(() => this.onCreate(model))
        }
    }

    onCreate = (model) => {
        this.props.onCreate(model)
    }

    onBack = () => {
        //this.props.history.push(`/iotconfig`)
        this.props.history.goBack()
    }

    onchangeController = (controller) => {
        let model = this.state.model
        model.controllerId = controller.id
        this.onchangeModule("")
        this.removeError("controllerId")
    }

    onchangeModule = (moduleCode) => {
        let model = this.state.model
        model.moduleCode = moduleCode

        let configValue = null

        if (!!model.configValue) {
            configValue = model.configValue
        } else {
            switch (moduleCode) {
                case "RS485":
                    configValue = {
                        channel: 0,
                        buadRate: 0
                    }
                    break;
                default:
                    configValue = null
                    break;
            }
        }


        model.configValue = configValue

        this.handleChangeSerialList([])
        this.removeError("moduleCode")
    }

    onchangeLocationType = (locationType) => {
        let model = this.state.model
        model.location.type = locationType
        model.location.id = ""

        this.setState({ model })
        this.removeError("location.type")
    }

    onchangeLocationId = (locationId) => {
        let model = this.state.model
        model.location.id = locationId

        this.setState({ model })
        this.removeError("location.id")
    }

    handleChangeSerialList = (selectedSerialList) => {
        let model = this.state.model
        model.serials = selectedSerialList

        this.setState({ model })
        this.removeError("serials")
    }

    removeError(code) {
        let modelErrors = this.state.modelErrors
        let index = modelErrors.findIndex(e => e.path === code)
        if (index > -1) {
            modelErrors.splice(index, 1)
            this.setState({ modelErrors })
        }
    }
}

export default SensorUpsert