
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header, Label } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import LineDataModel from '../../../models/notificationData/LineDataModel'
import ViewButton from '../../common/ViewButton'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewTable from '../../../core/view/common/table/ViewTable'
import ModalAddIdName from '../ModalAddIdName'

class LineDataForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isOpenModalAddGroup = false
        this.state.isOpenModalAddUser = false
    }
    getColumnGroupConfig(datas) {
        const { t } = this.props
        const columns = [
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false
            },
            {
                name: '',
                width: '200px',
                wrap: true,
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveGroup(datas, row) },
                            iconClass: "trash",
                            size: "mini"
                        },
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div >
                },
                right: true
            },
        ]

        return columns
    }

    getColumnUserConfig(datas) {
        const { t } = this.props
        const columns = [
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false
            },
            {
                name: '',
                width: '200px',
                wrap: true,
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveUser(datas, row) },
                            iconClass: "trash",
                            size: "mini"
                        },
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div >
                },
                right: true
            },
        ]

        return columns
    }

    onClickAddGroup = () => {
        this.setState({
            isOpenModalAddGroup: true
        })
    }
    onCloseModalGroup = () => {
        this.setState({
            isOpenModalAddGroup: false
        })
    }
    onRemoveGroup = (datas, row) => {
        let index = row.numRow
        if (index > -1) {
            let tempDatas = datas
            tempDatas.splice(index, 1)
            this.props.handleChange(null, { name: "groups", value: tempDatas })
        }
    }

    onClickAddUser = () => {
        this.setState({
            isOpenModalAddUser: true
        })
    }
    onCloseModalUser = () => {
        this.setState({
            isOpenModalAddUser: false
        })
    }
    onRemoveUser = (datas, row) => {
        let index = row.numRow
        if (index > -1) {
            let tempDatas = datas
            tempDatas.splice(index, 1)
            this.props.handleChange(null, { name: "users", value: tempDatas })
        }
    }

    onAdd = (name, data, datas) => {
        let tempDatas = []
        if (Array.isArray(datas)) {
            tempDatas = tempDatas.concat(datas)
        }
        if (!!data) {
            tempDatas.push(data)
        }
        this.setState({
            isOpenModalAddGroup: false,
            isOpenModalAddUser: false
        }, () => {
            this.props.handleChange(null, { name: name, value: tempDatas })
        })
    }
    render() {
        try {
            const { t, model, errors } = this.props
            const { isOpenModalAddGroup, isOpenModalAddUser } = this.state

            let groupsValidate = this.getErrorInput("groups", errors)
                , usersValidate = this.getErrorInput("users", errors)

            let dataGroups = []
                , dataUsers = []

            if (!!model) {
                if (Array.isArray(model.groups)) {
                    dataGroups = model.groups
                }
                if (Array.isArray(model.users)) {
                    dataUsers = model.users
                }
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenModalAddGroup && <ModalAddIdName headerText={t("notification.addGroup")} onSubmit={(d) => this.onAdd("groups", d, dataGroups)} onClose={this.onCloseModalGroup} />}
                    {isOpenModalAddUser && <ModalAddIdName headerText={t("notification.addUser")} onSubmit={(d) => this.onAdd("users", d, dataUsers)} onClose={this.onCloseModalUser} />}
                    <Header as='h1' dividing className="form header">
                        {t("notification.notiLineBot")}
                    </Header>
                    <Grid>
                        <Grid.Row verticalAlign="bottom">
                            <Grid.Column width={8}>
                                <LabelInput text={t("common.group")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t('common.add'),
                                        color: "green",
                                        onClick: this.onClickAddGroup
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ViewTable
                                    columns={this.getColumnGroupConfig(dataGroups)}
                                    datas={dataGroups}
                                    pagination={false}
                                />
                                {
                                    (groupsValidate.isError) &&
                                    <Form.Field>
                                        <Label pointing prompt>
                                            {groupsValidate.content}
                                        </Label>
                                    </Form.Field>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br></br>
                    <Grid>
                        <Grid.Row verticalAlign="bottom">
                            <Grid.Column width={8}>
                                <LabelInput text={t("common.user")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={8} textAlign="right">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t('common.add'),
                                        color: "green",
                                        onClick: this.onClickAddUser
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ViewTable
                                    columns={this.getColumnUserConfig(dataUsers)}
                                    datas={dataUsers}
                                    pagination={false}
                                />
                                {
                                    (usersValidate.isError) &&
                                    <Form.Field>
                                        <Label pointing prompt>
                                            {usersValidate.content}
                                        </Label>
                                    </Form.Field>
                                }
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            console.error(error)
            return null
            // return this.renderErrorComponent(error)
        }
    }
}

LineDataForm.defaultProps = {
    model: new LineDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(LineDataForm)