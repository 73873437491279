
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import { getIOTConfigResourceList, getIOTConfigIOTList, getIOTConfigControllerList } from '../../actions/iotConfigActions'
import IOTConfigManager from '../../business/IOTConfigManager'
import IOTConfigCreate from '../../views/iotConfig/IOTConfigCreate'

class CreateIOTConfigController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bIOTConfig = new IOTConfigManager(this)
    }

    onCreateIOTConfig = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bIOTConfig.createIOTConfig(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {

                    action = this.props.history.push(`/iotconfig/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/iotconfig`)
    }

    render() {
        try {
            const { t, iotConfigControllerList, locationList, iotConfigResourceList, iotConfigIOTList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            const optionsControllerList = iotConfigControllerList.getDatasOption()
                , controllerDatas = iotConfigControllerList.getDatas()
                , optionsLocationList = locationList.getDatasOption() 
                , optionsResourceList = iotConfigResourceList.getDatasOption() 
                , optionsIOTList = iotConfigIOTList.getDatasOption()

            let optionsTypeList = [
                {
                    key: "LOCATION",
                    value: "LOCATION",
                    text: t(`constantTranslate.iotConfigLocation.LOCATION`)
                },
                {
                    key: "RESOURCE",
                    value: "RESOURCE",
                    text: t(`constantTranslate.iotConfigLocation.RESOURCE`)
                },
            ]


            return (
                <React.Fragment>
                    {super.render()}
                    <IOTConfigCreate
                        isLoadingControl={isLoadingComponent}
                        controllerList={controllerDatas}
                        optionsControllerList={optionsControllerList}
                        optionsLocationList={optionsLocationList}
                        optionsResourceList={optionsResourceList}
                        optionsIOTList={optionsIOTList}
                        optionsTypeList={optionsTypeList}
                        onCreate={this.onCreateIOTConfig} />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            iotConfigControllerList: state.iotConfigControllerList,
            iotConfigResourceList: state.iotConfigResourceList,
            iotConfigIOTList: state.iotConfigIOTList,
            locationList: state.locationList
        }),
        (dispatch, props) => ({
            getIOTConfigControllerList() {
                dispatch(getIOTConfigControllerList())
            },
            getIOTConfigResourceList() {
                dispatch(getIOTConfigResourceList())
            },
            getIOTConfigIOTList() {
                dispatch(getIOTConfigIOTList())
            },
            getLocationList() {
                dispatch(getLocationList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getIOTConfigControllerList()
            this.props.getIOTConfigResourceList()
            this.props.getIOTConfigIOTList()
            this.props.getLocationList()
        }
    }),
)


export default enhance(CreateIOTConfigController)