
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const ProfileCenterDataSchema = new Schema({
	server: { type: String, required: [true, "validate.require"], default: "" },
	company: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(ProfileCenterDataSchema, "system_integration_profile_center")

const ProfileCenterDataModel = mongoose.model("system_integration_profile_center", ProfileCenterDataSchema, "system_integration_profile_center")

export default ProfileCenterDataModel