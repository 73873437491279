
import CookiesManager from "../core/business/CookiesManager"
import { CALL_API } from './api'
import { cookiesKey } from "../../constant"


const SetToken = store => next => action => {
    const entry = action[CALL_API]
        , bCookie = new CookiesManager()

    if (typeof entry !== 'object') return next(action)

    const token = bCookie.getData(cookiesKey.TOKEN)


    if (token && (entry.useToken || !("useToken" in entry))) {
        entry.headers.Authorization = `Bearer ${token}`
    }
    
    return next(action)
}

export default SetToken