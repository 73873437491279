
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const SettingLoginSchema = new Schema({
    id: { type: String, default: "" },
    code: { type: String, required: [true, "validate.require"], default: "" },
    name: { type: String, required: [true, "validate.require"], default: "" },
    description: { type: String, default: "" },
    service: { type: String, required: [true, "validate.require"], default: "" },
    isDefault: { type: Boolean, default: false },
    data: { type: Object, required: false },
}, { _id: false })

setBaseSchema(SettingLoginSchema, "setting_login")

const SettingLoginModel = mongoose.model("setting_login", SettingLoginSchema, "setting_login")

export default SettingLoginModel