
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class AccountManager extends MyManager {


	async updateAccount(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

    async changePasswordAccount(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/changepassword`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async accountIntegrationAD(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/integration/ad`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async accountIntegrationExchange(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/integration/exchange`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async accountIntegrationGoogle(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/integration/google`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async accountIntegrationO365(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/integration/o365`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async unintegrateAccount(id, service, systemIntegrationId, callback) {
		try {
			let data = {
				"systemId": systemIntegrationId,
				"unintegrationService": service
			}

			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/account/unintegration`,
				data: data
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

} 