
import React from 'react'
import moment from 'moment'
import { translate } from 'react-switch-lang'
import MyPage from '../../core/components/MyPage'
import ButtonModel from '../../core/model/ButtonModel'
import TimeSlotModel from '../../models/TimeSlotModel'
import TimeSlotForm from './TimeSlotForm'

class ModalTimeSlot extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = !!props.model ? new TimeSlotModel(props.model) : new TimeSlotModel({
            startTime: moment().startOf("d"),
            endTime: moment().startOf("d")
        })

    }
    onSubmit = () => {
        this.props.onSubmit(this.state.model)
    }
    render() {
        try {
            const { t, onClose, minuteStep } = this.props
            const { model } = this.state

            let wording = t("common.addTimeSlot")
            let buttonModal = [
                new ButtonModel({
                    text: t("common.add"),
                    color: "green",
                    onClick: this.onSubmit
                }),
                new ButtonModel({
                    text: t("common.close"),
                    color: "grey",
                    onClick: onClose
                })
            ]
            if (this.props.model) {
                wording = t("common.editTimeSlot")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }
            return (
                <React.Fragment>
                    {this.getModalContent(this.getInfoModalOption({
                        open: true,
                        size: "mini",
                        headerText: wording,
                        onClose: () => { },
                        content: <div>
                            <TimeSlotForm handleChange={this.handleChange} model={model} minuteStep={minuteStep} />
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalTimeSlot.defaultProps = {
    model: null,
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
    minuteStep: 15,
}

export default translate(ModalTimeSlot)