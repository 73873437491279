
import mongoose from 'mongoose'
import NotificationManager from '../business/NotificationManager'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const NotificationSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },
	service: { type: String, required: [true, "validate.require"], default: "" },
	systemIntegrationId: { type: String, required: [true, "validate.require"], default: "" },
	systemIntegration: { type: Object, default: null },
	data: { type: Object, required: false }
}, { _id: false })

setBaseSchema(NotificationSchema, "notification")

NotificationSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
			service: this.service,
			systemIntegrationId: this.systemIntegrationId,
		}
		if (!!this.data) {
			let data = this.data.serializeAPI()
			result.data = data
		}
		return result
	} catch (error) {
		console.error(error)
		return null
	}
}

NotificationSchema.methods.setData = function (data) {
	try {
		let bNoti = new NotificationManager()
		if (!Array.isArray(data) && typeof data === "object") {
			for (const key in data) {
				if (key in this) {
					if (key === "data") {
						let Model = bNoti.getModelNotificationData(data.service)
						this.data = null
						if (!!Model) {
							this.data = new Model(data[key])
						}
					} else {
						this[key] = data[key]
					}
				}
			}
		}

		if (this.data === undefined) {
			let Model = bNoti.getModelNotificationData(data.service)
			this.data = null
			if (!!Model) {
				this.data = new Model()
			}
		}
	} catch (error) {

	}
}

const NotificationModel = mongoose.model("notification", NotificationSchema, "notification")

export default NotificationModel