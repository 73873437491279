
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import { getResourceGroupList } from '../../actions/resourceGroupActions'
import UserManager from '../../business/UserManager'
import ViewImportExternalUser from '../../views/user/ViewImportExternalUser'

class ImportExternalUserController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoading = false
        this.state.isLoadingData = false
        this.state.importSystemList = []

        this.bUser = new UserManager(this)
    }

    componentDidMount() {
        this.onGetDatas()
    }

    onGetDatas = () => {
        this.setState({
            isLoadingData: true
        }, async () => {
            let res = await this.bUser.getImportSystemList()
            let tempState = {
                isLoadingData: false,
                importSystemList: res
            }
            this.setState(tempState)
        })
    }

    onImportUserExchange = (service, body) => {
        return this.bUser.importUserExchange(service, body)
    }

    onImportUserAD = (service, body) => {
        return this.bUser.importUserAD(service, body)
    }

    render() {
        try {
            const { resourceGroupList } = this.props
            const { isLoading, importSystemList } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            const resourceGroupListData = resourceGroupList.getDatas()
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <ViewImportExternalUser
                        resourceGroupList={resourceGroupListData}
                        importSystemList={importSystemList}

                        onImportUserAD={this.onImportUserAD}
                        onImportUserExchange={this.onImportUserExchange}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
        }
    })
)


export default enhance(ImportExternalUserController)