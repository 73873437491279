
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Header, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import ScheduleControlModel from '../../models/ScheduleControlModel'
import LabelInput from '../common/LabelInput'
import { dateTimeFormat } from '../../../constant'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewNoResult from '../common/ViewNoResult'

class ScheduleControlView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
    }

    onCreate = () => {
        this.props.history.push(`/schedulecontrol/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/schedulecontrol/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/schedulecontrol`)
    }

    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.code"),
                selector: (row, i) => !!row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row, i) => !!row.name ? row.name : '-',
                sortable: false
            },
            {
                name: t("scheduleControl.action"),
                selector: (row) => !!row.actionMode ? row.actionMode : '-',
                sortable: false,
                width: '200px'
            }
        ]

        return columns
    }

    render() {
        try {
            const { t } = this.props
            const { model } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            let displayActionTime = ""

            if (!!model && !!model.actionTime) {
                displayActionTime = moment(model.actionTime).format(dateTimeFormat.displayTime)
            }

            let recurrenceTypeDisplay = "-"

            if (!!model && !!model.recurrence && !!model.recurrence.type) {
                recurrenceTypeDisplay = t(`constantTranslate.recurrenceType.${model.recurrence.type}`)
            }

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('scheduleControl.viewScheduleControl')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.time')} /></Table.Cell>
                                                        <Table.Cell><p>{displayActionTime}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('scheduleControl.holidayType')} /></Table.Cell>
                                                        <Table.Cell><p>{model.holidayType ? t(`constantTranslate.recurrenceHolidayType.${model.holidayType}`) : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <Header as='h1' dividing className="form header view-header-button">
                                                {t("scheduleControl.recurrence")}
                                            </Header>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.type')} /></Table.Cell>
                                                        <Table.Cell><p>{recurrenceTypeDisplay}</p></Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        !!model && !!model.recurrence && model.recurrence.type === "ONETIME" &&
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('scheduleControl.startDate')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.recurrence.startDate ? moment(model.recurrence.startDate).format(dateTimeFormat.displayDate) : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                    }
                                                    {
                                                        !!model && !!model.recurrence && model.recurrence.type === "WEEKLY" &&
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('scheduleControl.dayOfWeek')} /></Table.Cell>
                                                            <Table.Cell>
                                                                <div className="minThree">
                                                                    {Array.isArray(model.recurrence.weeklyEveryDayOfWeek) && model.recurrence.weeklyEveryDayOfWeek.length > 0 ?
                                                                        model.recurrence.weeklyEveryDayOfWeek.map(day => {
                                                                            return <p>• {t(`constantTranslate.dayOfWeek.${day}`)}</p>
                                                                        })

                                                                        : '-'}
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    }
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <Header as='h3' className="form header view-small-divider">
                                                {t("common.resource")}
                                            </Header>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ViewTable
                                                            columns={this.getColumnConfig()}
                                                            datas={model.resources}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <br></br>
                                            <br></br>
                                            <Header as='h3' className="form header view-small-divider">
                                                {t("location.title")}
                                            </Header>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ViewTable
                                                            columns={this.getColumnConfig()}
                                                            datas={model.locations}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ScheduleControlView.defaultProps = {
    model: new ScheduleControlModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ScheduleControlView)