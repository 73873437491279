
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Icon, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import GatewayModel from '../../models/GatewayModel'
import LabelInput from '../common/LabelInput'
//import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import { onlineOffline } from '../../../constant'
import ViewNoResult from '../common/ViewNoResult'

class GatewayView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
    }

    onBack = () => {
        this.props.history.push(`/gateway`)
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("gateway.type"),
                selector: (row) => row.type ? row.type : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("location.title"),
                selector: (row) => row.locationId ? row.locationId : '-',
                sortable: false
            },
            {
                name: '',
                width: '250px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmAreaDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t } = this.props
            const { model } = this.state

            let buttons = [
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment >
                    {super.render()}
                    < LayoutWithSidebarHeaderFooter textHeader={t('gateway.viewGateway')} buttons={buttons} >
                        <div className="module GatewayView">
                            <Container text>
                                {
                                    !model ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewNoResult />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        : <React.Fragment>
                                            <div className="Gateway-wrapper">
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('gateway.status')} /></Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    (model.status === onlineOffline.OFFLINE) &&
                                                                    <Icon color="red" name="circle" size="small" />
                                                                }
                                                                {
                                                                    (model.status === onlineOffline.ONLINE) &&
                                                                    <Icon color="green" name="circle" size="small" />
                                                                }
                                                                {
                                                                    (model.status !== onlineOffline.OFFLINE && model.status !== onlineOffline.ONLINE) &&
                                                                    <Icon color="grey" name="circle" size="small" />
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('gateway.enable')} /></Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    (model.isDisable === true) &&
                                                                    <Icon color="red" name="close" />
                                                                }
                                                                {
                                                                    (model.isDisable === false) &&
                                                                    <Icon color="green" name="checkmark" />
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                {/*                                   <br></br>
                                    <br></br>
                                    <Header as='h1' dividing className="form header">
                                        {t("gateway.configIOT")}
                                    </Header>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewTable
                                                    columns={this.getColumnConfig()}
                                                    datas={model.iotConfigs}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid> */}
                                            </div>
                                        </React.Fragment>
                                }
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

GatewayView.defaultProps = {
    model: new GatewayModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(GatewayView)