
import React from 'react'
import MyPage from '../../core/components/MyPage'

class BoxAdvancedSearch extends MyPage {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isSearch !== this.props.isSearch) {
            this.onToggleBodySearch()
        }
    }
    onToggleBodySearch = () => {
        let wrapper = document.getElementById("bas-wrapper")
        if (!!wrapper) {
            // wrapper.classList.toggle("active-panel");
        }
        if (!!wrapper) {
            if (wrapper.style.maxHeight) {
                wrapper.style.maxHeight = null;

                setTimeout(() => {
                    wrapper.style.display = "none";
                }, 200)
            } else {
                wrapper.style.display = "block";
                wrapper.style.maxHeight = wrapper.scrollHeight + "px";
            }
        }
    }
    render() {
        const { isSearch } = this.props
        try {
            return (
                <React.Fragment>
                    <div className="module boxAdvancedSearch">
                        <div className={`bas-wrapper ${isSearch ? "search" : "searchHidden"}`} id={`bas-wrapper`}>
                            <div className="body-search" id="body-search">
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

BoxAdvancedSearch.defaultProps = {
    isSearch: false,
}



export default BoxAdvancedSearch