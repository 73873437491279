
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewCreateResource from '../../views/user/ViewCreateUser'

import { getResourceGroupList } from '../../actions/resourceGroupActions'
import UserModel from '../../models/UserModel'
import UserManager from '../../business/UserManager'
import { getDepartmentList } from '../../actions/departmentActions'
import { getLocationList } from '../../actions/locationActions'
import utils from '../../core/utility/utils'

class ViewUserController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false

        this.bUser = new UserManager(this)
    }

    onCreateUser = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bUser.createUser(model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    let user = new UserModel(res.data)
                    action = () => { this.props.history.replace(`/user/view/${user.id}`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { resourceGroupList, departmentList, locationList } = this.props
            const { isLoadingComponent } = this.state

            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const optionsResourceGroup = resourceGroupList.getDatasOption()
                , departments = departmentList.getDatas()
                , location = locationList.getDatas()
                , optionsTreeDepartment = utils.makeGroupedArrayForTreeSelect(departments, "id", "parentId", null, "name", "id")
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(location, "id", "parentId", null, "name", "id")


            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewCreateResource
                        isLoadingControl={isLoadingComponent}
                        optionsResourceGroup={optionsResourceGroup}
                        optionsTreeDepartment={optionsTreeDepartment}
                        optionsTreeLocation={optionsTreeLocation}
                        onCreate={this.onCreateUser}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
            locationList: state.locationList,
            departmentList: state.departmentList
        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getDepartmentList() {
                dispatch(getDepartmentList())
            },
            getLocationList() {
                dispatch(getLocationList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
            this.props.getDepartmentList()
            this.props.getLocationList()
        }
    })
)


export default enhance(ViewUserController)