
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from './LabelInput'
import InputTimePicker from '../../core/view/common/InputTimePicker'
import TimeSlotModel from '../../models/TimeSlotModel'


class TimeSlotForm extends MyForm {

    render() {
        try {
            const { t, model, minuteStep } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm}>
                        <Grid className="grid-half">
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.start")} isRequire={true} />
                                    <InputTimePicker
                                        name="startTime"
                                        value={model.startTime}
                                        onChange={this.handleChange}
                                        minuteStep={minuteStep}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.end")} isRequire={true} />
                                    <InputTimePicker
                                        name="endTime"
                                        value={model.endTime}
                                        onChange={this.handleChange}
                                        minuteStep={minuteStep}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

TimeSlotForm.defaultProps = {
    model: new TimeSlotModel(),
    handleChange: () => console.error("Please provide Handle Change action."),
    minuteStep: 15
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(TimeSlotForm)