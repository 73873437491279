
import React from 'react'
import { translate } from 'react-switch-lang'
import { Segment, Form, Icon } from 'semantic-ui-react'

import MyController from '../../core/components/MyController'
import BoxModalHeaderWithContent from '../common/BoxModalHeaderWithContent'
import ButtonModel from '../../core/model/ButtonModel'
import ViewNoResult from '../common/ViewNoResult'
import UserManager from '../../business/UserManager'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingDataModel from '../../core/model/PagingDataModel'
import PagingModel from '../../core/model/PagingModel'
// import Pagination from '../../core/view/common/table/Pagination'

class ModalSyncUser extends MyController {
    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.isLoading = true
        this.state.textSearch = ""
        this.state.selectDataIds = []

        this.state.modelPaging = new PagingModel({ pageSize: 100 })
        this.state.pagingDataModel = null

        this.bUser = new UserManager(this)
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    onSearchByForm = () => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelPaging: modelPaging,
            selectDataIds: [],
        }, () => {
            let query = this.getQuerySearchByForm(true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (isNewQuery = true) => {
        try {
            let query = ""
            const { textSearch } = this.state
            let querys = [
                {
                    name: "displayName",
                    value: textSearch,
                    queryType: searchQueryType.LIKE
                },
            ]

            query = utils.generateSearchQuery(querys, isNewQuery)
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoading: true,
            pagingDataModel: null,
        }, async () => {
            let res = await this.bUser.getUserPlatform(query)
            let tempState = {
                isLoading: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }

            this.setState(tempState)

        })
    }

    handleChangeTextSearch = (e, { name, value }) => {
        this.setState({
            [name]: value
        })
    }

    handleChangeList = (e, { name, value, checked }, datas = []) => {
        let selectDataIds = this.state.selectDataIds.map(id => id)
        if (name === "selectAll") {
            if (checked === true) {
                selectDataIds = datas.map(d => d.id)
            } else if (checked === false) {
                selectDataIds = []
            }
        } else if (name === "selectDataIds") {
            if (checked === true) {
                selectDataIds.push(value)
            } else if (checked === false) {
                let index = selectDataIds.findIndex(id => id === value)
                if (index > -1) {
                    selectDataIds.splice(index, 1)
                }
            }
        }
        this.setState({
            selectDataIds
        })
    }


    render() {
        try {
            const { textSearch, selectDataIds, pagingDataModel, isLoading } = this.state
            const { t, onClose, onSubmit } = this.props
            let calssList = ""
                , mapDatas = []
            if (pagingDataModel instanceof PagingDataModel) {
                mapDatas = pagingDataModel.datas
            }
            if (mapDatas.length === 0) {
                calssList = "no-data"
            }
            if (isLoading === true) {
                calssList = "loading"
            }

            return this.getModalContent(this.getModalOption({
                headerText: t("user.title"),
                size: "tiny",
                onClose: () => { },
                content: <div className="module modalSyncInfo">
                    <Form autoComplete="off">
                        <BoxModalHeaderWithContent
                            header={<div className="msi-search">
                                <Form.Input
                                    fluid
                                    name="textSearch"
                                    value={textSearch}
                                    icon={<Icon name="search" size="small" inverted circular link onClick={this.onSearchByForm} />}
                                    placeholder={t("common.search") + "..."}
                                    onChange={this.handleChangeTextSearch}
                                />
                            </div>}
                            content={
                                <div className="msi-content">
                                    <div className="mis-c-list">
                                        <Segment>
                                            <div className={`data-list ${calssList}`}>
                                                {
                                                    (Array.isArray(mapDatas) && mapDatas.length > 0) &&
                                                    <React.Fragment>
                                                        <Form.Checkbox
                                                            name="selectAll"
                                                            value={defaultValue.SELECT_ALL}
                                                            onChange={(e, d) => this.handleChangeList(e, d, mapDatas)}
                                                            label={t("common.selectAll")}
                                                            checked={selectDataIds.length === mapDatas.length}
                                                        />
                                                        {
                                                            mapDatas.map((d, i) => {
                                                                let isChecked = selectDataIds.findIndex(id => id === d.id) > -1
                                                                return <Form.Checkbox key={i} name="selectDataIds" value={d.id} onChange={this.handleChangeList} label={d.givenName + ' ' + d.sn} checked={isChecked} />
                                                            })
                                                        }
                                                    </React.Fragment>
                                                }
                                                {
                                                    isLoading &&
                                                    this.getLoadingComponent()
                                                }
                                                {
                                                    calssList === "no-data" &&
                                                    <ViewNoResult />
                                                }
                                            </div>
                                        </Segment>
                                    </div>
                                    {/* <div className="mis-c-pagination">
                                        {
                                            isLoading ?
                                                <div className="mis-c-p-loading">
                                                    {this.getLoadingComponent()}
                                                </div>
                                                : <Pagination pagingDataModel={pagingDataModel} onChangePagination={this.onSearchByPaging} />
                                        }
                                    </div> */}
                                </div>
                            }
                        />
                    </Form>
                </div>,
                buttons: [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: () => onSubmit(selectDataIds),
                            disabled: selectDataIds.length === 0
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalSyncUser.defaultProps = {
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalSyncUser))