
import ActionResourceObject from '../core/redux/ActionResourceObject'
import SceneModel from '../models/SceneModel'

export default class SceneResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new SceneModel(d))
			} else {
				result = new SceneModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

}