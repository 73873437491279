
import React from 'react'
// import moment from 'moment'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
// import FormUser from './FormUser'
// import ViewButton from '../common/ViewButton'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import { defaultValue, systemIntegrationService } from '../../../../constant'
import SelectServiceForm from './SelectServiceForm'
// import StepList from '../../core/business/step/StepList'
// import UserModel from '../../models/UserModel'
// import { defaultValue } from '../../../constant'

class ViewSelectService extends MyPage {
    constructor(props) {
        super(props)
        const { dataStep } = props
        let serviceId = defaultValue.PLEASE_SELECT
        if (!!dataStep && !!dataStep.serviceId) {
            serviceId = dataStep.serviceId
        }
        this.state.model = {
            serviceId: serviceId
        }
        this.state.modelErrors = []
    }

    render() {
        try {
            const { t, optionsImportService, onNext, onBack, importSystemList } = this.props
            const { model, modelErrors } = this.state
            let tempOptionsImportService = [
                {
                    key: defaultValue.PLEASE_SELECT,
                    value: defaultValue.PLEASE_SELECT,
                    text: t("common.pleaseSelect")
                }
            ]
            if (Array.isArray(optionsImportService)) {
                tempOptionsImportService = tempOptionsImportService.concat(optionsImportService)
            }
            let serviceCode = null
            if (!!model.serviceId && model.serviceId !== defaultValue.PLEASE_SELECT) {
                let service = importSystemList.find(m => m.id === model.serviceId)
                if (!!service) {
                    serviceCode = service.service
                }
            }
            let isNext = [systemIntegrationService.ACTIVE_DIRECTORY, systemIntegrationService.EXCHANGE].includes(serviceCode)
            return (
                <React.Fragment>
                    {super.render()}
                    <Container text>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <SelectServiceForm
                                        optionsImportService={tempOptionsImportService}
                                        // optionsResourceGroup={tempOptionsResourceGroup}
                                        model={model}
                                        errors={modelErrors}
                                        handleChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.next"),
                                            color: "teal",
                                            disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                            onClick: () => onNext(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.cancel"),
                                            color: "grey",
                                            onClick: onBack
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewSelectService.defaultProps = {
    optionsImportService: [],
    optionsResourceGroup: [],
    importSystemList: [],
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewSelectService)