
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import { Checkbox, Icon, Form, Input } from 'semantic-ui-react'
//import { searchQueryType } from '../../../constant'
import UserManager from '../../business/UserManager'
import MyController from '../../core/components/MyController'
// import MyComponent from '../../core/components/MyComponent'
// import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
//import utils from '../../core/utility/utils'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewNoResult from '../common/ViewNoResult'
import { getUserExchangeList } from '../../actions/userActions'

class UserInputSelectExchange extends MyController {
    constructor(props) {
        super(props)
        this.state.isOpenModal = false
        this.state.isLoading = false

        this.state.selectValue = null
        this.state.selectData = null

        this.state.tempSelectValue = null
        this.state.tempSelectData = null

        this.state.pagination = null
        this.state.datasRender = []

        this.state.searchValue = ""

        this.bUser = new UserManager(this)

        this.state.userExchangeStateList = null
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (prevState.tempSelectValue !== nextProps.value) {
            tempState.tempSelectValue = nextProps.value
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState) {

    }

    onGetUserInfo = async (id) => {
        let res = await this.bUser.getUserInfo(id)
        if (!!res) {
            this.setState({
                tempSelectData: res
            })
        }
    }

    onClickDropdown = () => {
        let tempState = {
            isOpenModal: true
        }
        if (!!this.props.value) {
            tempState.selectValue = this.props.value
        }
        this.setState(tempState)
    }

    onCloseModal = () => {
        this.setState({
            isOpenModal: false
        })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onSubmitForm()
        }
    }

    onSubmitModal = (selectValue) => {
        const { initialDatas } = this.props
        const { selectData } = this.state
        this.setState({
            isOpenModal: false,
            tempSelectData: selectData,
            tempSelectValue: selectValue,
        }, () => {
            if (!!this.props.name) {
                let tempValue = selectValue
                if (Array.isArray(initialDatas) && initialDatas.length > 0) {
                    let def = initialDatas.find(d => d.id === selectValue)
                    if (!!def) {
                        tempValue = def.value
                    }
                }
                this.props.onChange(null, { name: this.props.name, value: tempValue })
            }
        })
    }

    /*     onSubmitForm = () => {
            const { searchValue } = this.state
    
            // if (searchValue) {
            let querys = [
                {
                    name: "code",
                    value: searchValue,
                    queryType: searchQueryType.LIKE
                },
                {
                    name: "name",
                    value: searchValue,
                    queryType: searchQueryType.LIKE
                },
                {
                    name: "pageNumber",
                    value: 1,
                },
                {
                    name: "pageSize",
                    value: 100,
                },
            ]
            this.onGetUserList(utils.generateSearchQuery(querys))
            // } else {
            //     this.setState({
            //         datasRender: []
            //     })
            // }
        } */

    onGetUserList = (query) => {
        const { initialDatas } = this.props
        this.setState({
            isLoading: true,
        }, async () => {
            let tempState = {
                isLoading: false,
                pagination: null,
                datasRender: []
            }, action = () => { }
            let res = await this.bUser.getUserListPaging(query)
            if (res instanceof PagingDataModel) {
                tempState.pagination = res.pagination
                let datasRender = []
                if (Array.isArray(initialDatas) && initialDatas.length > 0) {
                    initialDatas.forEach(data => {
                        if (!!data && !!data.id && !!data.text) {
                            datasRender.push({ ...data, name: data.text })
                        }
                    })
                }
                datasRender = datasRender.concat(res.datas)
                tempState.datasRender = datasRender
            }
            this.setState(tempState, action)
        })
    }

    handleChange = (e, { name, value, checked }, data) => {
        let tempState = {}
        if (name === "searchValue") {
            tempState[name] = value
        } else if (name === "selectValue") {
            if (checked === true) {
                tempState.selectData = data
                tempState.selectValue = value
            } else {
                tempState.selectData = null
                tempState.selectValue = ""
            }
        }
        this.setState(tempState)
    }

    onSubmitForm = () => {
        const { userExchangeList } = this.props
        const { searchValue } = this.state

        let userExchangeDatas = userExchangeList.getDatas()
            , filterDatas = []

        if (!!searchValue) {
            filterDatas = userExchangeDatas.filter(data => {
                let name = data.displayName.toLowerCase()
                    , tempValue = searchValue.toLowerCase()

                return name.includes(tempValue)
            })
        } else {
            filterDatas = userExchangeDatas
        }
        this.setState({
            selectDataIds: [],
            userExchangeStateList: filterDatas
        })
    }

    render() {
        const { t, userExchangeList, onClose } = this.props
        const { userExchangeStateList, selectValue, isLoading, searchValue } = this.state

        if (!this.requestFinishedAfterRequest()) {
            return this.getLoadingPage()
        }

        const userExchangeDatas = userExchangeList.getDatas()

        let displayData = null

        if (userExchangeStateList) {
            displayData = userExchangeStateList
        } else {
            displayData = userExchangeDatas
        }

        let calssList = ""
        if (!Array.isArray(displayData) || displayData.length === 0) {
            calssList = "no-data"
        }
        if (isLoading === true) {
            calssList = "loading"
        }

        return (
            <React.Fragment>
                {this.getModalContent(this.getInfoModalOption({
                    size: "tiny",
                    headerText: t("user.userExchangeIntegration"),
                    content: <div className="module modalInputSelectIntegration">
                        <div className="misd-wrapper">
                            <div className="box-search">
                                <div className="bs-input">
                                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                                        <Input
                                            fluid
                                            icon={<Icon name='search' size="small" inverted circular link onClick={this.onSubmitForm} />}
                                            placeholder={t("common.search")}
                                            value={searchValue}
                                            name="searchValue"
                                            onChange={this.handleChange}
                                        />
                                    </Form>
                                </div>
                            </div>
                            <div className={`box-list`}>
                                <div className={`bs-wrapper ${calssList}`}>
                                    {
                                        Array.isArray(displayData) && displayData.length > 0 &&
                                        displayData.map((ex, i) => {
                                            let isChecked = (selectValue === ex.userPrincipalName)
                                            if (ex.givenName) {
                                                return <div key={i} className="bs-item">
                                                    <div className="bs-i-wrapper">
                                                        <Checkbox
                                                            radio
                                                            name="selectValue"
                                                            label={ex.displayName}
                                                            value={ex.userPrincipalName}
                                                            onChange={(e, d) => this.handleChange(e, d, ex)}
                                                            checked={isChecked}
                                                        />
                                                    </div>
                                                </div>
                                            } else {
                                                return <div key={i} className="bs-item">
                                                    <div className="bs-i-wrapper">
                                                        <Checkbox
                                                            radio
                                                            name="selectValue"
                                                            label={ex.userPrincipalName}
                                                            value={ex.userPrincipalName}
                                                            onChange={(e, d) => this.handleChange(e, d, ex)}
                                                            checked={isChecked}
                                                        />
                                                    </div>
                                                </div>
                                            }

                                        })
                                    }
                                    {
                                        calssList === "no-data" &&
                                        <ViewNoResult />
                                    }
                                    {
                                        calssList === "loading" &&
                                        this.getLoadingComponent()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>,
                    buttons: [
                        new ButtonModel({
                            text: t("common.ok"),
                            color: "green",
                            disabled: !selectValue,
                            onClick: () => { this.props.onSubmit(selectValue) }
                        }),
                        new ButtonModel({
                            text: t('common.cancel'),
                            color: "grey",
                            onClick: onClose
                        })
                    ],
                }))}
            </React.Fragment>
        )
    }
}

UserInputSelectExchange.defaultProps = {
    id: "",
    userExchangeList: [],
    onClose: () => console.error("Please provide On Close Action"),
    onChange: () => console.error("Please provide On Change Action."),
    onSubmit: () => console.error("Please provide On Submit Action")
}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            userExchangeList: state.userExchangeList,
        }),
        (dispatch, props) => ({
            getUserExchangeList(id) {
                dispatch(getUserExchangeList(id))
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getUserExchangeList(this.props.id)
        }
    })
)

export default enhance(UserInputSelectExchange)