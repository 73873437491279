import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetIOTConfigList = () => {
	return {
		type: type.RESET_IOT_CONFIG_LIST
	}
}

const getIOTConfigInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_INFO_REQUEST,
				{
					type: type.GET_IOT_CONFIG_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_INFO_FAILURE
			]
		}
	}
}

const getIOTConfigList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_LIST_REQUEST,
				{
					type: type.GET_IOT_CONFIG_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_LIST_FAILURE
			]
		}
	}
}

const getIOTConfigLocationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig/location`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_LOCATION_LIST_REQUEST,
				{
					type: type.GET_IOT_CONFIG_LOCATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_LOCATION_LIST_FAILURE
			]
		}
	}
}

const getIOTConfigResourceList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig/resource`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_RESOURCE_LIST_REQUEST,
				{
					type: type.GET_IOT_CONFIG_RESOURCE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_RESOURCE_LIST_FAILURE
			]
		}
	}
}

const getIOTConfigIOTList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig/iot`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_IOT_LIST_REQUEST,
				{
					type: type.GET_IOT_CONFIG_IOT_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_IOT_LIST_FAILURE
			]
		}
	}
}

const getIOTConfigControllerList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/iotconfig/controller/list`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_IOT_CONFIG_CONTROLLER_LIST_REQUEST,
				{
					type: type.GET_IOT_CONFIG_CONTROLLER_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_IOT_CONFIG_CONTROLLER_LIST_FAILURE
			]
		}
	}
}

export {
    getIOTConfigInfo,
    getIOTConfigList,
	resetIOTConfigList,
	getIOTConfigLocationList,
	getIOTConfigResourceList,
	getIOTConfigIOTList,
	getIOTConfigControllerList
}