
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Grid, Form } from 'semantic-ui-react'

import SensorRuleManager from '../../business/SensorRuleManager'
import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import SystemIntegrationModel from '../../models/SystemIntegrationModel'
import { sensorRuleActionType } from '../../../constant'

import ControlDataForm from './sensorRuleActionForm/ControlDataForm'
import AlertDashboardDataForm from './sensorRuleActionForm/AlertDashboardDataForm'
import AlertMessageDataForm from './sensorRuleActionForm/AlertMessageDataForm'
import DatabaseDataForm from './sensorRuleActionForm/DatabaseDataForm'

class SensorRuleForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isLoading = false

        this.bSensorRule = new SensorRuleManager()
    }

    handleChange = (e, { name, value }) => {
        try {
            if (name === "type") {
                let ModelActionData = this.bSensorRule.getModelSensorRuleAction(value)
                let data = {
                    [name]: value,
                }
                if (ModelActionData) {
                    data.data = new ModelActionData()
                    if (value === sensorRuleActionType.ALERT_DASHBOARD) {
                        data.data = new ModelActionData({
                            color: {
                                r: 0,
                                g: 0,
                                b: 0
                            }
                        })
                    }
                }
                this.props.handleChangeMultiValue(e, data)
            } else {
                this.props.handleChange(e, { name, value })
            }
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    handleChangeOnKey = (e, { name, value }, masterKey) => {
        try {
            this.props.handleChange(e, { name, value }, masterKey)
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    render() {
        try {
            const { t, handleChangeMultiValue, errors, model, optionsSensorRuleActionType, optionsSensorRuleActionResult, optionsSensorRuleLocationType,
                sensorRuleResourceList, locationList, sceneList, getScene, optionsNotification, optionsDataType, optionsSensorType } = this.props
            const { isLoading } = this.state
            let typeValidate = this.getErrorInput("type", errors)
                , resultValidate = this.getErrorInput("result", errors)

            let onKeyData = "data"
                , dataErrors = []

            dataErrors = errors.filter(err => err.path.includes(`${onKeyData}.`))
                .map(err => {
                    return {
                        ...err,
                        path: err.path.replace(`${onKeyData}.`, "")
                    }
                })
            let ComponentForm = null
                , propsForm = {}
            if (model.type === sensorRuleActionType.CONTROL) {
                ComponentForm = ControlDataForm
                propsForm.optionsSensorRuleLocationType = optionsSensorRuleLocationType
                propsForm.sensorRuleResourceList = sensorRuleResourceList
                propsForm.locationList = locationList
                propsForm.sceneList = sceneList
                propsForm.getScene = getScene
            } else if (model.type === sensorRuleActionType.ALERT) {
                ComponentForm = AlertMessageDataForm
                propsForm.optionsNotification = optionsNotification
            } else if (model.type === sensorRuleActionType.ALERT_DASHBOARD) {
                ComponentForm = AlertDashboardDataForm
            } else if (model.type === sensorRuleActionType.DATABASE) {
                ComponentForm = DatabaseDataForm
                propsForm.optionsDataType = optionsDataType
                propsForm.optionsSensorType = optionsSensorType
            }

            return (
                <div className="module sensorRuleForm">
                    <div className="sensorRuleAction">
                        {super.render()}
                        {isLoading && this.getLoadingPage()}<Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t("sensorRule.actionType")} isRequire={true} />
                                        <Form.Dropdown
                                            fluid
                                            selection
                                            selectOnBlur={false}
                                            options={optionsSensorRuleActionType}
                                            onChange={this.handleChange}
                                            name="type"
                                            value={model.type}
                                            placeholder={t("common.pleaseSelect")}
                                            error={typeValidate.isError ? typeValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t("sensorRule.actionResult")} isRequire={true} />
                                        <Form.Dropdown
                                            fluid
                                            selection
                                            selectOnBlur={false}
                                            options={optionsSensorRuleActionResult}
                                            onChange={this.handleChange}
                                            name="result"
                                            value={model.result}
                                            placeholder={t("common.pleaseSelect")}
                                            error={resultValidate.isError ? resultValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    !!ComponentForm &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ComponentForm
                                                {...propsForm}
                                                model={model.data}
                                                handleChange={(e, data) => this.handleChangeOnKey(e, data, onKeyData)}
                                                handleChangeMultiValue={(e, d) => handleChangeMultiValue(e, d, onKeyData)}
                                                errors={dataErrors}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Form>
                        {/*                     {
                        !!ComponentForm === false &&
                        <React.Fragment>
                            <div className="sensorRuleAction">

                            </div>
                        </React.Fragment>
                    } */}
                    </div>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorRuleForm.defaultProps = {
    model: new SystemIntegrationModel(),
    errors: [],
    optionsSensorRuleActionType: [],
    optionsSensorRuleActionResult: [],
    optionsSensorRuleLocationType: [],
    optionsNotification: [],
    optionsDataType: [],
    optionsSensorType: [],
    sensorList: [],
    sensorRuleResourceList: [],
    locationList: [],
    handleChange: () => console.error("Please provide \"Change SystemIntegration\" action."),
    handleChangeMultiValue: () => console.error("Please provide \"Handle Change Multi Value\" action."),

    sceneList: [],
    getScene: () => console.error("Please provide \"Get Scene\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SensorRuleForm)