
import { requestStatus } from '../../../constant'

class ActionResource {
    constructor() {
        this.status = requestStatus.NO_ACTION
        this.value = null
        this.error = null
    }

    requestFinished() {
        return (this.status !== requestStatus.REQUEST)
    }

    requestFinishedAfterRequest() {
        return (this.status !== requestStatus.REQUEST && this.status !== requestStatus.NO_ACTION)
    }

    setStatus(status) {
        this.status = status
    }

    setValue(value) {
        this.value = value
    }

    setResource(data) {
        return data
    }

    setResourceOption(data) {
        return data
    }
}

export default ActionResource