
import React from 'react'
import MyPage from '../../core/components/MyPage'

class BoxModalHeaderWithContent extends MyPage {

    render() {
        try {
            const { header, content } = this.props
            return (
                <React.Fragment>
                    <div className="module boxModalHeaderWithContent">
                        <div className={`bmhwc-wrapper`} >
                            {
                                !!header &&
                                <div className="header">
                                    <div className="header-wrapper">
                                        {header}
                                    </div>
                                </div>
                            }
                            {
                                !!content &&
                                <div className="content">
                                    <div className="content-wrapper">
                                        {content}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}
BoxModalHeaderWithContent.defaultProps = {
    header: null,
    content: null
}



export default BoxModalHeaderWithContent