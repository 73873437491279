
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router-dom'

import MyPage from '../../core/components/MyPage'
import LayoutBlank from '../layout/LayoutBlank'
import LoginForm from './LoginForm'
import LoginModel from '../../models/LoginModel'
import AuthManager from '../../business/AuthManager'
import SettingManager from '../../business/SettingManager'
import { serviceCode } from '../../../constant'

import config from '../../config'

class ViewLogin extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = null
        this.state.isLoading = false

        this.state.loginList = []

        this.state.modelErrors = []

        this.state.serviceActive = null

        this.bAuth = new AuthManager(this)
        this.bSetting = new SettingManager(this)
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if ((!Array.isArray(prevState.loginList) || prevState.loginList.length === 0) && (Array.isArray(nextProps.loginSetting) && nextProps.loginSetting.length > 0)) {
            let loginSetting = nextProps.loginSetting.map(l => l)
            let indexLocal = loginSetting.findIndex(l => !!l && l.service === serviceCode.LOCAL)
                , loginDefault = loginSetting.find(l => !!l && l.isDefault === true)
            let loginList = []
            if (indexLocal > -1) {
                let localInfo = loginSetting[indexLocal]
                loginList.push(localInfo)
                loginSetting.splice(indexLocal, 1)
                tempState.serviceActive = localInfo.id
            }
            loginList = loginList.concat(loginSetting)
            if (!!loginDefault) {
                tempState.serviceActive = loginDefault.id
            }
            tempState.loginList = loginList
        }
        return tempState
    }

    componentDidUpdate(prevProps, prevState) {
        if (!!this.state.serviceActive && prevState.serviceActive !== this.state.serviceActive) {
            let model = this.getModelLogin(this.state.serviceActive)
            this.setState({
                model: model
            })
        }
    }

    getModelLogin = (serviceActive) => {
        let model = null
        const { loginList } = this.state
        let info = loginList.find(d => d.id === serviceActive)
        let service = null
            , serviceId = ""
        if (!!info) {
            serviceId = info.id
            service = info.service
        }
        switch (service) {
            case serviceCode.LOCAL:
            case serviceCode.ACTIVE_DIRECTORY:
            case serviceCode.PROFILE_CENTER:
            case serviceCode.EXCHANGE:
                model = new LoginModel({ systemId: serviceId })
                break;
            default:
                break;
        }
        return model
    }

    onSubmit = () => {
        const { serviceActive, model, loginList } = this.state
        let info = loginList.find(d => d.id === serviceActive)
        let service = null
        if (!!info) {
            service = info.service
        }
        if (service === serviceCode.LOCAL) {
            this.onValidate(() => this.props.onVerifyLocal(model))
        } else if (service === serviceCode.ACTIVE_DIRECTORY) {
            this.onValidate(() => this.props.onVerifyAD(model))
        } else if (service === serviceCode.PROFILE_CENTER) {
            this.onValidate(() => this.props.onVerifyProfileCenter(model))
        } else if (service === serviceCode.EXCHANGE) {
            this.onValidate(() => this.props.onVerifyExchange(model))
        } else if (service === serviceCode.O365) {

        } else if (service === serviceCode.GOOGLE) {

        }
    }

    getOptionsLogin = (loginList) => {
        try {
            const { t } = this.props
            let options = []
            if (Array.isArray(loginList) && loginList.length > 0) {
                loginList.forEach(info => {
                    let text = info.service
                    if (info.service === serviceCode.ACTIVE_DIRECTORY) {
                        text = t("auth.activeDirectory")
                    } else if (info.service === serviceCode.PROFILE_CENTER) {
                        text = t("auth.profileCenter")
                    } else if (info.service === serviceCode.EXCHANGE) {
                        text = t("auth.exchange")
                    } else if (info.service === serviceCode.O365) {
                        text = t("auth.o365")
                    } else if (info.service === serviceCode.GOOGLE_AUTHEN || info.service === serviceCode.GOOGLE_SERVICE_ACCOUNT) {
                        text = t("auth.google")
                    }
                    if (!!info.name) {
                        text = info.name
                    }
                    options.push({
                        key: info.id,
                        value: info.id,
                        text: text
                    })
                })
            }
            return options
        } catch (error) {
            return []
        }
    }

    render() {
        try {
            const { t, isLoading } = this.props
            const { serviceActive, model, loginList, modelErrors } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutBlank>
                        <div className="module viewLogin">
                            <div className="vl-background" >
                                <img src={`${config.publicUrl}/assets/images/image-forest_fog_trees.jpg`} alt="No Background" />
                            </div>
                            <div className="vl-wrapper">
                                <h1 className="text-header-login">{t("auth.pageHeader")}</h1>
                                {
                                    isLoading &&
                                    this.getLoadingComponent()
                                }
                                <div className="vl-form">
                                    <LoginForm
                                        serviceActive={serviceActive}
                                        model={model}
                                        loginList={loginList}
                                        optionsLoginList={this.getOptionsLogin(loginList)}
                                        handleChange={this.handleChange}
                                        onSubmitForm={this.onSubmit}
                                        errors={modelErrors}
                                    />
                                </div>
                            </div>
                        </div>
                    </LayoutBlank>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewLogin.defaultProps = {
    isLoading: false,
    isLoadingVerify: false,
    loginSetting: [],
    onVerifyLocal: () => console.error("Please provide Verify Local action."),
    onVerifyAD: () => console.error("Please provide Verify AD action."),
    onVerifyExchange: () => console.error("Please provide Verify Exchange action."),
    onVerifyProfileCenter: () => console.error("Please provide Verify Profile Center action.")
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewLogin)