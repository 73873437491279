
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header, Segment, Label } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ModalLocationScene from './ModalLocationScene'
import InputTimePicker from '../../core/view/common/InputTimePicker'
import MultipleCheckboxList from '../common/MultipleCheckboxList'
import InputDatePicker from '../../core/view/common/InputDatePicker'

let MINUTE_STEP = 1

class ScheduleControlForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.isOpenModalLocation = false
        this.state.isOpenModalResource = false

        this.state.sceneModel = null
        this.state.sceneStatus = null
        this.state.sceneIndex = null
    }

    onOpenModalResource = (status, model, index) => {
        this.setState({
            isOpenModalResource: true,
            sceneStatus: status,
            sceneModel: model,
            sceneIndex: index
        })
    }

    onCloseModalResource = () => {
        this.setState({
            isOpenModalResource: false
        })
    }

    onSubmitResource = (status, obj, index) => {
        this.setState({
            isOpenModalResource: false
        }, () => {
            this.props.onSubmitResource(status, obj, index)
        })
    }

    onOpenModalLocation = (status, model, index) => {
        this.setState({
            isOpenModalLocation: true,
            sceneStatus: status,
            sceneModel: model,
            sceneIndex: index
        })
    }

    onCloseModalLocation = () => {
        this.setState({
            isOpenModalLocation: false
        })
    }

    onSubmitLocation = (status, obj, index) => {
        this.setState({
            isOpenModalLocation: false
        }, () => {
            this.props.onSubmitLocation(status, obj, index)
        })
    }

    getColumnResourceConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.code"),
                selector: (row, i) => !!row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row, i) => !!row.name ? row.name : '-',
                sortable: false
            },
            {
                name: t("scheduleControl.action"),
                selector: (row) => !!row.actionMode ? row.actionMode : '-',
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '100px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModalResource("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.props.onDeleteResource(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getColumnLocationConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.code"),
                selector: (row, i) => !!row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row, i) => !!row.name ? row.name : '-',
                sortable: false
            },
            {
                name: t("scheduleControl.action"),
                selector: (row) => !!row.actionMode ? row.actionMode : '-',
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '100px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModalLocation("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.props.onDeleteLocation(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t, model, errors, optionsRecurrenceType, optionsDayOfWeek, optionsLocationList, optionsResourceList, optionsLocationTypeList, optionsHolidayTypeList } = this.props
            const { sceneModel, sceneStatus, sceneIndex, isOpenModalLocation, isOpenModalResource } = this.state

            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , actionTimeValidate = this.getErrorInput("actionTime", errors)
                , holidayTypeValidate = this.getErrorInput("holidayType", errors)
                , typeValidate = this.getErrorInput("recurrence.type", errors)
                , weeklyEveryDayOfWeekValidate = this.getErrorInput("recurrence.weeklyEveryDayOfWeek", errors)
                , startDateValidate = this.getErrorInput("recurrence.startDate", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , resourcesValidate = this.getErrorInput("resources", errors)
                , locationsValidate = this.getErrorInput("locations", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalLocation &&
                        <ModalLocationScene
                            sceneModel={sceneModel}
                            sceneStatus={sceneStatus}
                            sceneIndex={sceneIndex}
                            type="LOCATION"
                            optionsLocationList={optionsLocationList}
                            optionsLocationTypeList={optionsLocationTypeList}
                            optionsResourceList={optionsResourceList}
                            onSubmit={this.onSubmitLocation}
                            onClose={this.onCloseModalLocation}
                            handleChange={this.handleChange}
                        />
                    }
                    {
                        isOpenModalResource &&
                        <ModalLocationScene
                            sceneModel={sceneModel}
                            sceneStatus={sceneStatus}
                            sceneIndex={sceneIndex}
                            type="RESOURCE"
                            optionsLocationList={optionsLocationList}
                            optionsLocationTypeList={optionsLocationTypeList}
                            optionsResourceList={optionsResourceList}
                            onSubmit={this.onSubmitResource}
                            onClose={this.onCloseModalResource}
                            handleChange={this.handleChange}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <LabelInput text={t('common.time')} isRequire={true} />
                                    <InputTimePicker
                                        name="actionTime"
                                        value={model.actionTime}
                                        onChange={this.handleChange}
                                        minuteStep={MINUTE_STEP}
                                        error={actionTimeValidate.isError ? actionTimeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('scheduleControl.holidayType')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="holidayType"
                                        value={model.holidayType}
                                        options={optionsHolidayTypeList}
                                        onChange={this.handleChange}
                                        error={holidayTypeValidate.isError ? holidayTypeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                        <Header as='h1' dividing className="form header">
                            {t("scheduleControl.recurrence")}
                        </Header>
                        <Segment className="group-segment-input">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('common.type')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="recurrence.type"
                                            value={model.recurrence.type}
                                            options={optionsRecurrenceType}
                                            onChange={this.props.handleChangeRecurrence}
                                            error={typeValidate.isError ? typeValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    (!!model && !!model.recurrence && !!model.recurrence.type && model.recurrence.type === "ONETIME") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('scheduleControl.startDate')} />
                                            <InputDatePicker
                                                name="startDate"
                                                value={model.recurrence.startDate}
                                                onChange={this.props.handleChangeRecurrence}
                                                error={startDateValidate.isError ? startDateValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {
                                    (!!model && !!model.recurrence && !!model.recurrence.type && model.recurrence.type === "WEEKLY") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('scheduleControl.dayOfWeek')} />
                                            <MultipleCheckboxList
                                                name="weeklyEveryDayOfWeek"
                                                optionsDataList={optionsDayOfWeek}
                                                value={model.recurrence.weeklyEveryDayOfWeek}
                                                onChange={this.props.handleChangeRecurrence}
                                                error={weeklyEveryDayOfWeekValidate}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Segment>
                        <br></br>
                        <br></br>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left" verticalAlign='bottom'>
                                    <LabelInput text={t("common.resource")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign='bottom'>
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => this.onOpenModalResource("CREATE", null, null),
                                            size: "medium"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnResourceConfig()}
                                        datas={model.resources}
                                        pagination={false}
                                    />
                                    {
                                        (resourcesValidate.isError) &&
                                        <Form.Field>
                                            <Label pointing prompt>
                                                {resourcesValidate.content}
                                            </Label>
                                        </Form.Field>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left" verticalAlign='bottom'>
                                    <LabelInput text={t("location.title")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign='bottom'>
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => this.onOpenModalLocation("CREATE", null, null),
                                            size: "medium"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnLocationConfig()}
                                        datas={model.locations}
                                        pagination={false}
                                    />
                                    {
                                        (locationsValidate.isError) &&
                                        <Form.Field>
                                            <Label pointing prompt>
                                                {locationsValidate.content}
                                            </Label>
                                        </Form.Field>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ScheduleControlForm.defaultProps = {
    model: null,
    errors: [],
    handleChange: () => console.error("Please provide \"Change Resource Group\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ScheduleControlForm)