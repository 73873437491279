
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getEventControlStatusList } from '../../actions/eventControlStatusActions'
import EventControlStatusList from '../../views/eventControlStatus/EventControlStatusList'
import EventControlStatusManager from '../../business/EventControlStatusManager'

class EventControlStatusController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bEventControlStatus = new EventControlStatusManager(this)

	}

	getPropsResetStatus() {
		return ["eventControlStatusList"]
	}

	onForceTurnOffResource = (model, callBack) => {
		//const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bEventControlStatus.forceTurnOffResource(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onForceTurnOffLocation = (model, callBack) => {
		//const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bEventControlStatus.forceTurnOffLocation(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { eventControlStatusList } = this.props
			const { isLoadingComponent } = this.state

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const eventControlStatusDatas = eventControlStatusList.getData()

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<EventControlStatusList
						isLoadingTable={!this.requestFinishedAfterRequest()}
						eventControlStatusList={eventControlStatusDatas}
						onReloadData={this.props.getEventControlStatusList}
						onForceTurnOffResource={this.onForceTurnOffResource}
						onForceTurnOffLocation={this.onForceTurnOffLocation}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			eventControlStatusList: state.eventControlStatusList,
		}),
		(dispatch) => ({
			getEventControlStatusList(query) {
				dispatch(getEventControlStatusList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getEventControlStatusList()
		}
	}),
)


export default enhance(EventControlStatusController)