
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import ActiveDirectoryDataModel from '../../../models/systemIntegrationData/ActiveDirectoryDataModel'

class ActiveDirectoryDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let serverValidate = this.getErrorInput("server", errors)
                , portValidate = this.getErrorInput("port", errors)
                , usernameValidate = this.getErrorInput("username", errors)
                , passwordValidate = this.getErrorInput("password", errors)
                , domainValidate = this.getErrorInput("domain", errors)
                , baseDNValidate = this.getErrorInput("baseDN", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.activeDirectory")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.server")} isRequire={true} />
                                <Form.Input
                                    name="server"
                                    value={model.server}
                                    onChange={this.handleChange}
                                    error={serverValidate.isError ? serverValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.port")} isRequire={true} />
                                <Form.Input
                                    name="port"
                                    value={model.port}
                                    onChange={this.handleChange}
                                    error={portValidate.isError ? portValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.username")} isRequire={true} />
                                <Form.Input
                                    name="username"
                                    value={model.username}
                                    onChange={this.handleChange}
                                    error={usernameValidate.isError ? usernameValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.password")} isRequire={true} />
                                <Form.Input
                                    name="password"
                                    value={model.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    error={passwordValidate.isError ? passwordValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.domain")} />
                                <Form.Input
                                    name="domain"
                                    value={model.domain}
                                    onChange={this.handleChange}
                                    error={domainValidate.isError ? domainValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.baseDN")} />
                                <Form.Input
                                    name="baseDN"
                                    value={model.baseDN}
                                    onChange={this.handleChange}
                                    error={baseDNValidate.isError ? baseDNValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ActiveDirectoryDataForm.defaultProps = {
    model: new ActiveDirectoryDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ActiveDirectoryDataForm)