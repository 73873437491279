import React from 'react'
// import { translate } from 'react-switch-lang'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import ButtonModel from '../../core/model/ButtonModel'
import ListErrorFromAPI from '../../core/view/errors/listErrorFromAPI'
import { resetError } from '../../actions/errorActions'

const handleDataRedux = WrappedComponent => {
    class ComponentHandleDataRedux extends MyController {
        constructor(props) {
            super(props);
            this.state = {
                errors: []
            };
        }

        // componentDidMount() {
        // }

        // componentDidUpdate(prevProps, prevState) {
        //  if ((!prevProps.errors || prevProps.errors.length === 0) || (Array.isArray(this.props.errors) && this.props.errors.length > 0)) {
        //         let errors = this.props.errors
        //             , error = errors.find(e => e.code === "401")
        //     }
        // }

        // closeModal = () => {

        // }

        render() {
            const { t } = this.props
            let errors = []
            if (Array.isArray(this.props.errors)) {
                errors = this.props.errors
            }
            return (
                <React.Fragment>
                    {
                        errors.length > 0 &&
                        this.getModalContent(this.getErrorModalOption(
                            {
                                open: true,
                                headerText: t ? t("common.system") : "System",
                                content: <ListErrorFromAPI errors={errors} />,

                                buttons: [
                                    new ButtonModel({ text: t ? t("common.close") : "Close", color: "red", onClick: () => { this.props.resetError() } })
                                ]
                            }
                        ))
                    }
                    <WrappedComponent {...this.props} />
                </React.Fragment>
            );
        }
    };

    const enhance = compose(
        // translate,
        connect(
            (state, props) => ({
                errors: state.errors
            }),
            (dispatch, props) => ({
                resetError() {
                    dispatch(resetError())
                }
            }),
        ),
        lifecycle({
        }),
    )

    return enhance(ComponentHandleDataRedux)
}

export default handleDataRedux