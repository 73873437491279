
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
// import { resourceStatus } from '../../constant'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const ResourceGroupSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

}, { _id: false })

const UserSchema = new Schema({
	resourceTypeCode: { type: String, required: [true, "validate.require"], default: "USER" },
	resourceGroupIds: [{ type: String }],
	resourceGroupId: { type: String, default: "" },
	resourceGroups: [{ type: ResourceGroupSchema }],
	departmentId: { type: String, default: null },
	department: { type: Object, default: null },
	status: { type: String, default: null },

	integrationInfo: { type: Object, default: null },

	username: { type: String, default: null },
	password: { type: String, default: null },
	confirmPassword: { type: String, default: null },

	code: { type: String, required: [true, "validate.require"], default: null },
	givenName: { type: String, required: [true, "validate.require"], default: null },
	sn: { type: String, default: null },
	email: { type: String, default: null },
	mobile: { type: String, default: null },

	pinCode: { type: String, default: null },

	rfId: { type: String, default: null },
	cardIds: [{ type: String }],
	cards: [{ type: Object }],
	
	displayName: { type: String, default: null },

	id: { type: String, default: null },

	fileUpload: { type: FileSchema, default: null },

	formType: { type: String, enum: ["CREATE_USER", "UPDATE_USER"], default: "CREATE_USER"},

}, { _id: false })

setBaseSchema(UserSchema, "05")

UserSchema.path("username").validate(async function (username) {
	if (!!this.id) {
		return true
	}
	return !!username
}, "validate.require")

UserSchema.path("password").validate(async function (password) {
	if (!!this.id) {
		return true
	}
	return !!password
}, "validate.require")

UserSchema.path("confirmPassword").validate(async function (confirmPassword) {
	if (!!this.id) {
		return true
	}
	let isValid = true
	if (!!this.password) {
		isValid = (confirmPassword === this.password)
	}
	return isValid
}, "validate.passwordNotMatchWithConfirmPassword") 

UserSchema.path("resourceGroupIds").validate(async function (resourceGroupIds) {
	return (Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0)
}, "validate.require")

UserSchema.path("email").validate(async function (email) {
	let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

	let isValid = true
	if(email === null) {
		isValid = true
	} else if(email === "") {
		isValid = true
	}
	else if (!pattern.test(email)) 
	{
		isValid = false
	} 
	
	return isValid
}, "validate.wrongEmailFormat")

UserSchema.methods.serializeAPI = function () {
	try {
		let result = {
			resourceTypeCode: this.resourceTypeCode,
			resourceGroupIds: this.resourceGroupIds,
			departmentId: this.departmentId,
			code: this.code,
			givenName: this.givenName,
			sn: this.sn,
			email: this.email,
			mobile: this.mobile,
			pinCode: this.pinCode,
			rfId: this.rfId,
			cardIds: (Array.isArray(this.cards) && this.cards.length > 0) ? this.cards.map(data => data.id) : [],
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		if (this.formType === "CREATE_USER") {
				result.username = this.username
				result.password = this.password
				result.confirmPassword = this.confirmPassword
		}

		return result

	} catch (error) {
		return null
	}
}

const UserModel = mongoose.model("resource", UserSchema, "resource")

export default UserModel