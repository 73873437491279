
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ResourcePlatformSchema = new Schema({
	id: { type: String, default: null },
	displayName: { type: String, default: "" },

}, { _id: false })

setBaseSchema(ResourcePlatformSchema, "12")

const ResourcePlatformModel = mongoose.model("ResourcePlatform", ResourcePlatformSchema, "ResourcePlatform")

export default ResourcePlatformModel