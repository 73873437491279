
import React from 'react'
import { sensorRuleStep } from '../../../constant'
import Step from '../../core/business/step/Step'

import ActionInformationView from '../../views/sensorRuleStep/step/ActionInformationView'

export default class ActionInformationStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "sensorRule.action"
		this.key = sensorRuleStep.ACTION_INFORMATION
		this.data = {
			actions: [],
			type: "",
		}
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data) {
			let information = data[sensorRuleStep.SENSOR_INFORMATION]
			if (!!information) {
				this.data.type = information.type
			}

			if (Array.isArray(data[this.key])) {
				this.data.actions = data[this.key]
			}

		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult[this.key] = data.actions
		return allResult
	}

	nextStep(data) {
		this.data.actions = Array.isArray(data) ? data : []
		super.nextStep()
	}

	prevStep(data) {
		this.data.actions = Array.isArray(data) ? data : []
		super.prevStep()
	}

	saveStep(data, callback) {
		this.data.actions = Array.isArray(data) ? data : []
		super.saveStep()
		if (typeof callback === "function") {
			callback(this.key)
		} else {
			console.error("Please provide Callback Submit function for Save Step.")
		}
	}

	render(dataConfig) {
		return <ActionInformationView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
			onSave={(data) => this.saveStep(data, dataConfig.onSubmit)}
		/>
	}
}