
import React from 'react'
import { translate } from 'react-switch-lang'
import { Checkbox, Icon, Form, Input } from 'semantic-ui-react'
import { searchQueryType } from '../../../constant'
import DepartmentManager from '../../business/DepartmentManager'
import MyController from '../../core/components/MyController'
// import MyComponent from '../../core/components/MyComponent'
// import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import utils from '../../core/utility/utils'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewNoResult from '../common/ViewNoResult'
import PagingModel from '../../core/model/PagingModel'

class InputSelectDepartment extends MyController {
    constructor(props) {
        super(props)
        this.state.isOpenModal = false
        this.state.isLoading = false

        this.state.selectValue = null
        this.state.selectData = null

        this.state.tempSelectValue = null
        this.state.tempSelectData = null

        this.state.modelPaging = new PagingModel({ pageSize: 100 })
        this.state.pagingDataModel = null

        this.state.searchValue = ""

        this.bDepartment = new DepartmentManager(this)
    }

    isCheckId = (value) => {
        try {
            if (!value) {
                throw new Error("No value")
            }
            let reg = new RegExp(/[0-9a-fA-F]{24}$/)
            return reg.test(value)
        } catch (error) {
            return false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (prevState.tempSelectValue !== nextProps.value) {
            tempState.tempSelectValue = nextProps.value
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    componentDidMount() {
        if (
            (!this.state.tempSelectData && !!this.props.value && this.isCheckId(this.props.value))
        ) {
            this.onGetDepartmentInfo(this.state.tempSelectValue)
        }

        this.onSearchByForm()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (!this.state.tempSelectData && !!this.props.value && this.isCheckId(this.props.value))
        ) {
            this.onGetDepartmentInfo(this.state.tempSelectValue)
        }
    }

    onGetDepartmentInfo = async (id) => {
        let res = await this.bDepartment.getDepartmentInfo(id)
        if (!!res) {
            this.setState({
                tempSelectData: res
            })
        }
    }

    onClickDropdown = () => {
        let tempState = {
            isOpenModal: true
        }
        if (!!this.props.value) {
            tempState.selectValue = this.props.value
        }
        this.setState(tempState)
    }

    onCloseModal = () => {
        this.setState({
            isOpenModal: false
        })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onSubmitForm()
        }
    }

    onSubmitModal = (selectValue) => {
        const { initialDatas } = this.props
        const { selectData } = this.state
        this.setState({
            isOpenModal: false,
            tempSelectData: selectData,
            tempSelectValue: selectValue,
        }, () => {
            if (!!this.props.name) {
                let tempValue = selectValue
                if (Array.isArray(initialDatas) && initialDatas.length > 0) {
                    let def = initialDatas.find(d => d.id === selectValue)
                    if (!!def) {
                        tempValue = def.value
                    }
                }
                this.props.onChange(null, { name: this.props.name, value: tempValue })
            }
        })
    }

    onSearchByForm = () => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (isNewQuery = true) => {
        try {
            let query = ""
            const { searchValue } = this.state

            let querys = [
                {
                    name: "code",
                    value: searchValue,
                    queryType: searchQueryType.LIKE
                },
                {
                    name: "name",
                    value: searchValue,
                    queryType: searchQueryType.LIKE
                },
            ]

            query = utils.generateSearchQuery(querys, isNewQuery)
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoading: true,
            pagingDataModel: null,
        }, async () => {
            let res = await this.bDepartment.getDepartmentListPaging(query)
            let tempState = {
                isLoading: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
        
            this.setState(tempState)
         
        })
    }

    handleChange = (e, { name, value, checked }, data) => {
        let tempState = {}
        if (name === "searchValue") {
            tempState[name] = value
        } else if (name === "selectValue") {
            if (checked === true) {
                tempState.selectData = data
                tempState.selectValue = value
            } else {
                tempState.selectData = null
                tempState.selectValue = ""
            }
        }
        this.setState(tempState)
    }

    handlePaginationChange = (e, { activePage }) => {
        let pagingModel = new PagingModel(this.state.pagingDataModel.pagination)
        pagingModel.pageNumber = activePage
        this.onSearchByPaging(pagingModel)
    }
    onChangePagesize = (e, { value }, page) => {
        let pagingModel = new PagingModel(this.state.pagingDataModel.pagination)
        pagingModel.pageNumber = page
        pagingModel.pageSize = value
        this.onSearchByPaging(pagingModel)
    }

    getOptionModalSelect = (pagingDataModel, selectValue, searchValue, isLoading) => {
        const { t, initialDatas, selfId } = this.props
        let calssList = ""
            , mapDatas = []
            // , boundaryRange = 1
            // , siblingRange = 1
            // , activePage = 0
            // , totalPages = 0
            // , pageSize = 0
            // , totalItem = 0
        if (Array.isArray(initialDatas) && initialDatas.length > 0) {
            initialDatas.forEach(data => {
                if (!!data && !!data.id && !!data.text) {
                    mapDatas.push({ ...data, name: data.text })
                }
            })
        }
        if (pagingDataModel instanceof PagingDataModel) {
            mapDatas = mapDatas.concat(pagingDataModel.datas)
            // activePage = pagingDataModel.pagination.pageNumber
            // pageSize = pagingDataModel.pagination.pageSize
            // totalItem = pagingDataModel.pagination.totalItem
            // totalPages = Math.ceil(pagingDataModel.pagination.totalItem / pagingDataModel.pagination.pageSize)
        }
        if (!pagingDataModel) {
            calssList = "no-data"
        }
        if (isLoading === true) {
            calssList = "loading"
        }

        if (!!selfId) {
            let findIndexData = mapDatas.findIndex(data => data.id === selfId)
        
            if(findIndexData > -1) {
                mapDatas.splice(findIndexData, 1)
            }
        } // Remove the current department that we are going to update

        return this.getInfoModalOption({
            size: "tiny",
            onClose: this.onCloseModal,
            headerText: t("department.title"),
            content: <div className="module modalInputSelectDepartment">
                <div className="misd-wrapper">
                    <div className="box-search">
                        <div className="bs-input">
                            <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                                <Input
                                    fluid
                                    icon={<Icon name='search' size="small" inverted circular link onClick={this.onSearchByForm} />}
                                    placeholder={t("common.search")}
                                    value={searchValue}
                                    name="searchValue"
                                    onChange={this.handleChange}
                                />
                            </Form>
                        </div>
                    </div>
                    <div className={`box-list`}>
                        <div className={`bs-wrapper ${calssList}`}>
                            {
                                (calssList !== "loading" && calssList !== "no-data" && Array.isArray(mapDatas) && mapDatas.length > 0) &&
                                mapDatas.map((department, i) => {
                                    let isChecked = (selectValue === department.id)
                                    return <div key={i} className="bs-item">
                                        <div className="bs-i-wrapper">
                                            <Checkbox
                                                radio
                                                name="selectValue"
                                                label={department.name}
                                                value={department.id}
                                                onChange={(e, d) => this.handleChange(e, d, department)}
                                                checked={isChecked}
                                            />
                                        </div>
                                    </div>
                                })
                            }
                            {
                                calssList === "no-data" &&
                                <ViewNoResult />
                            }
                            {
                                calssList === "loading" &&
                                this.getLoadingComponent()
                            }
                        </div>
                    </div>
                    {/* {
                        isLoading &&
                        <div className="box-loading-paging">
                            {
                                this.getLoadingComponent()
                            }
                        </div>
                    }
                    {
                        (totalPages > 0 && activePage > 0) &&
                        <div className="module viewTablePaging">
                            <div className="vt-custom-pagination">
                                <div className="vt-custom-pagination">
                                    <div className="vt-c-p-rows">
                                        <div className="vt-c-p-rows-wrapper">
                                            <div>
                                                <p>{t("common.rowsPerPage")}:</p>
                                            </div>
                                            <div>
                                                <Dropdown
                                                    selection
                                                    fluid
                                                    options={[
                                                        { value: 10, key: 10, text: 10 },
                                                        { value: 20, key: 20, text: 20 },
                                                        { value: 50, key: 50, text: 50 },
                                                        { value: 100, key: 100, text: 100 },
                                                    ]}
                                                    value={pageSize}
                                                    onChange={(e, data) => this.onChangePagesize(e, data, data.value >= totalPages ? 1 : activePage)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vt-c-p-of">
                                        <div className="vt-c-p-of-wrapper">
                                            <p>{`${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + mapDatas.length}`} {t("common.rangeSeparator")} {totalItem}</p>
                                        </div>
                                    </div>
                                    <div className="vt-c-p-page">
                                        <Pagination
                                            onPageChange={this.handlePaginationChange}

                                            activePage={activePage}
                                            boundaryRange={boundaryRange}
                                            size='mini'
                                            siblingRange={siblingRange}
                                            totalPages={totalPages}

                                            ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                            firstItem={null}
                                            lastItem={null}
                                            prevItem={{ content: <Icon name='angle left' disabled={activePage <= 1} />, icon: true }}
                                            nextItem={{ content: <Icon name='angle right' disabled={activePage === totalPages} />, icon: true }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                </div>
            </div>,
            buttons: [
                new ButtonModel({
                    text: t("common.ok"),
                    color: "green",
                    disabled: !selectValue,
                    onClick: () => this.onSubmitModal(selectValue)
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onCloseModal
                })
            ],
        })
    }

    render() {
        const { value, error, placeholder, defaultValue, initialDatas } = this.props
        const { isOpenModal, selectValue, isLoading, searchValue, tempSelectData, pagingDataModel } = this.state

        let valueInput = ""
        if (!!value) {
            valueInput = value
        }
        if (!valueInput && !!defaultValue) {
            valueInput = defaultValue
        }
        if (!!tempSelectData && valueInput === tempSelectData.id) {
            valueInput = tempSelectData.name
        } else {
            if (Array.isArray(initialDatas) && initialDatas.length > 0) {
                let def = initialDatas.find(ini => !!ini && ini.id === valueInput)
                if (!!def) {
                    valueInput = def.text
                }
            }
        }
        return (
            <React.Fragment>
                {isOpenModal && this.getModalContent(this.getOptionModalSelect(pagingDataModel, selectValue, searchValue, isLoading))}
                <div className="module inputSelectDepartment">
                    <div className="isd-wrapper">
                        <div className="box-input" onClick={this.onClickDropdown}>
                            <Form.Input fluid value={valueInput} disabled error={error} placeholder={placeholder} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

InputSelectDepartment.defaultProps = {
    name: "",
    value: "",
    label: "",
    placeholder: "",
    defaultValue: "",
    initialDatas: [],
    selfId: "",
    onChange: () => console.error("Please provide On Change Image action.")
}


export default translate(InputSelectDepartment)