
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Grid, Container, } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormUser from './FormUser'
import ButtonModel from '../../core/model/ButtonModel'
import UserModel from '../../models/UserModel'
import { defaultValue } from '../../../constant'

class ViewCreateUser extends MyPage {
    constructor(props) {
        super(props)

        let tempModel = new UserModel()

        let defaultDepartment = (Array.isArray(props.optionsDepartment) && props.optionsDepartment.length > 0 ? props.optionsDepartment[0] : null)
        if (!!defaultDepartment) {
            tempModel.departmentId = defaultDepartment.value
        } else {
            tempModel.departmentId = defaultValue.PLEASE_SELECT
        }

        let defaultLocation = (Array.isArray(props.optionsLocation) && props.optionsLocation.length > 0 ? props.optionsLocation[0] : null)
        if (!!defaultLocation) {
            tempModel.locationId = defaultLocation.value
        } else {
            tempModel.locationId = defaultValue.PLEASE_SELECT
        }

        this.state.isLoadingComponent = false

        this.state.model = new UserModel()
        this.state.model.formType = "CREATE_USER"
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}

        let tempModel = null

        if ((!!prevState.model && prevState.model.departmentId === defaultValue.PLEASE_SELECT) && (Array.isArray(nextProps.optionsDepartment) && nextProps.optionsDepartment.length > 0)) {
            if (!tempModel) {
                new UserModel()
            }
            tempModel.departmentId = nextProps.optionsDepartment[0].value
        }
        if ((!!prevState.model && prevState.model.locationId === defaultValue.PLEASE_SELECT) && (Array.isArray(nextProps.optionsLocation) && nextProps.optionsLocation.length > 0)) {
            if (!tempModel) {
                new UserModel()
            }
            tempModel.locationId = nextProps.optionsLocation[0].value
        }
        if (!!tempModel) {
            tempState.model = tempModel
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onCreate(this.state.model)
    }

    onCancel = () => {
        this.props.history.push(`/user`)
        //this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsResourceGroup, optionsTreeDepartment, isLoadingControl } = this.props
            const { model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t("common.create"),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('user.createUser')} buttons={buttons} >
                        <Container text>
                            <Grid>
                                <Grid.Column width={16}>
                                    <FormUser
                                        model={model}
                                        errors={modelErrors}
                                        handleChange={this.handleChange}
                                        optionsResourceGroup={optionsResourceGroup}
                                        optionsTreeDepartment={optionsTreeDepartment}
                                        onSubmitForm={() => this.onValidate(this.onValidateSuccess)}
                                    />
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewCreateUser.defaultProps = {
    isLoadingControl: false,
    optionsResourceGroup: [],
    optionsTreeDepartment: [],
    onCreate: () => console.error("Please provide Create action.")
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewCreateUser)