
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import NotificationManager from '../../business/NotificationManager'
import NotificationCreate from '../../views/notification/NotificationCreate'
import { getNotificationSystemList, getNotificationServiceList } from '../../actions/notificationActions'

class CreateNotificationController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bNotification = new NotificationManager(this)
	}

	onCreateNotification = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bNotification.createNotification(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/notification/view/${res.data.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onClickBack = () => {
		this.props.history.push(`/notification`)
	}

	onTestNotification = async (model) => {
		try {
			return this.bNotification.testNotification(model.serializeAPI())
		} catch (error) {
			return false
		}
	}

	render() {
		const { t, notificationServiceList, notificationSystemList } = this.props
		const { isLoadingComponent } = this.state

		let optionsNotificationService = notificationServiceList.getDatasOption()
		optionsNotificationService = optionsNotificationService.map(d => {
			return {
				key: d.key,
				value: d.value,
				text: t(`constantTranslate.systemIntegration.${d.value}`)
			}
		})
		optionsNotificationService = optionsNotificationService.sort((a, b) => { return a.text.localeCompare(b.text) })

		const optionsNotificationSystem = notificationSystemList.getDatasOption()

		try {

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					<NotificationCreate
						isLoadingControl={isLoadingComponent}
						onCreate={this.onCreateNotification}
						optionsNotificationService={optionsNotificationService}
						optionsNotificationSystem={optionsNotificationSystem}
						onTestNotification={this.onTestNotification}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			notificationServiceList: state.notificationServiceList,
			notificationSystemList: state.notificationSystemList
		}),
		(dispatch, props) => ({
			getNotificationServiceList() {
				dispatch(getNotificationServiceList())
			},
			getNotificationSystemList() {
				dispatch(getNotificationSystemList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getNotificationServiceList()
			this.props.getNotificationSystemList()
		}
	}),
)


export default enhance(CreateNotificationController)