
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'

class ModalSettingSensor extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.modalStatus = "CREATE"

        if (props.model) {
            this.state.airQuality = props.model
            this.state.modalStatus = "UPDATE"
        } else {
            this.state.airQuality = {
                sensorType: "",
                operation: "",
                value: "",
            }
        }
    }

    handleChange = (e, { name, value }) => {
        let airQuality = this.state.airQuality

        airQuality[name] = value

        this.setState({
            airQuality: airQuality
        })
    }

    handleChangeBoolean = (e, { name, value, checked }) => {
        try {
            this.handleChange(e, { name, value: value, checked: checked })
        } catch (error) {
            console.error("Handle Change Boolean Error: " + error.message)
        }
    }

    onSubmit = () => {
        const { airQuality } = this.state

        this.props.onSubmit(airQuality)
    }

    isDisabledSubmit = (airQuality) => {
        let disableChecker = false

        if (airQuality.sensorType === "") {
            disableChecker = true
        } else if (airQuality.operation === "") {
            disableChecker = true
        } else if (airQuality.value === "") {
            disableChecker = true
        }

        return disableChecker
    }

    render() {
        try {
            const { airQuality, modalStatus } = this.state
            const { t, onClose, optionsSensorType, optionsSensorStandardOperation } = this.props

            let headerText = t("settingSensor.addAirQuality")
            let buttons = [
                new ButtonModel(
                    {
                        text: t("common.add"),
                        color: "green",
                        onClick: this.onSubmit,
                        disabled: this.isDisabledSubmit(airQuality)
                    }
                ),
                new ButtonModel(
                    {
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    }
                )
            ]

            if (modalStatus === "UPDATE") {
                headerText = t("settingSensor.editAirQuality")
                buttons = [
                    new ButtonModel(
                        {
                            text: t("common.edit"),
                            color: "blue",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(airQuality)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            }

            return this.getModalContent(this.getModalOption({
                headerText: headerText,
                onClose: () => { },
                content: <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('settingSensor.sensorType')} isRequire={true} />
                                <Form.Dropdown
                                    selection
                                    selectOnBlur={false}
                                    placeholder={t("common.type")}
                                    name="sensorType"
                                    value={airQuality.sensorType}
                                    options={optionsSensorType}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('settingSensor.operation')} isRequire={true} />
                                <Form.Dropdown
                                    selection
                                    selectOnBlur={false}
                                    placeholder={t("common.type")}
                                    name="operation"
                                    value={airQuality.operation}
                                    options={optionsSensorStandardOperation}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("settingSensor.value")} isRequire={true} />
                                <Form.Input
                                    name={'value'}
                                    value={airQuality.value}
                                    onChange={this.handleChange}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>,
                buttons: buttons
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalSettingSensor.defaultProps = {
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalSettingSensor))