
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import UserModel from '../../models/UserModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchUser extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new UserModel()
        this.state.model.status = defaultValue.SELECT_ALL
        this.state.model.departmentId = defaultValue.SELECT_ALL
        this.state.model.resourceGroupId = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new UserModel({ code: "", displayName: "", username: "", email: "", status: defaultValue.SELECT_ALL, resourceGroupId: defaultValue.SELECT_ALL, pinCode: "", departmentId: defaultValue.SELECT_ALL })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionUserStatusList, optionResourceGroupList, optionsDepartmentList } = this.props
            return (
                <div className="module formSearchUser">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("user.employeeCode")} isRequire={false} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("user.fullname")} isRequire={false} />
                                    <Form.Input
                                        name="displayName"
                                        value={model.displayName}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>

                                <Grid.Column>
                                    <LabelInput text={t("user.username")} isRequire={false} />
                                    <Form.Input
                                        name="username"
                                        value={model.username}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("common.email")} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("department.title")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsDepartmentList}
                                        onChange={this.handleChange}
                                        name="departmentId"
                                        value={model.departmentId}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("resourceGroup.title")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionResourceGroupList}
                                        onChange={this.handleChange}
                                        name="resourceGroupId"
                                        value={model.resourceGroupId}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("user.status")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionUserStatusList}
                                        onChange={this.handleChange}
                                        name="status"
                                        value={model.status}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form >
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchUser.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new UserModel(),
    optionUserStatusList: [], 
    optionResourceGroupList: [], 
    optionsDepartmentList: [],
}

export default (translate(FormSearchUser))