
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import PagingDataModel from '../core/model/PagingDataModel'
import LocationModel from '../models/LocationModel'
import SceneModel from '../models/SceneModel'

export default class LocationManager extends MyManager {

	async createLocation(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/location`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateLocation(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/location/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async createLocationScene(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/location/scene/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateLocationScene(locationId, sceneId, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/location/scene/${locationId}/${sceneId}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteLocationScene(locationId, sceneId, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/location/scene/${locationId}/${sceneId}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async upsertAreaLocation(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/location/area/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteAreaLocation(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/location/area/${id}/${body.id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteLocation(model, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/location/${model.id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getLocationList(query = "", callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location${!!query ? "?" + query : ""}`,
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	afterGetLocationList(datas) {
		try {
			let tempDatas = []
			if (Array.isArray(datas) && datas.length > 0) {
				tempDatas = datas.map(d => new LocationModel(d))
			}
			return tempDatas
		} catch (error) {
			return []
		}
	}

	async getLocationInfo(locationId, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location/${locationId}`,
			}

			let res = await this.requestAction(options, callback)

			let dataInfo = null
			if (!!res && !!res.result === true) {
				dataInfo = new LocationModel(res.data)
			}
			return dataInfo
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async syncLocation(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/location/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getSceneById(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location/scene/${id}`,
			}
			let res = await this.requestAction(options)
			let scenes = []
			if (!!res && !!res.result === true && Array.isArray(res.datas)) {
				scenes = res.datas.map(data => {
					return new SceneModel(data)
				})
			}
			return scenes
		} catch (error) {
			return []
		}
	}

	async getLocationListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null

			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new LocationModel(d)
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async locationTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}