
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import VisitorGroupManager from '../../business/VisitorGroupManager'
import { getVisitorGroupInfo } from '../../actions/visitorGroupActions'
import utils from '../../core/utility/utils'
import VisitorGroupView from '../../views/visitorGroup/VisitorGroupView'

class ViewVisitorGroupController extends MyController {
	constructor(props) {
		super(props)

		this.bVisitorGroup = new VisitorGroupManager(this)
	}

	getPropsResetStatus() {
		return ["visitorGroupInfo"]
	}

	testSyncPlatformVisitorGroup = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bVisitorGroup.visitorGroupTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }


	render() {
		try {
			const { visitorGroupInfo } = this.props
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const visitorGroup = visitorGroupInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<div>
						<VisitorGroupView
							model={visitorGroup}
							testSyncPlatformVisitorGroup={this.testSyncPlatformVisitorGroup}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			visitorGroupInfo: state.visitorGroupInfo
		}),
		(dispatch, props) => ({
			getVisitorGroupInfo() {
				dispatch(getVisitorGroupInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getVisitorGroupInfo()
		}
	}),
)


export default enhance(ViewVisitorGroupController) 