import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ConditionForm from './ConditionForm'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'

class ConditionView extends MyPage {
    constructor(props) {
        super(props)
        let model = null
        if (!!props.dataStep.conditionModel && !!props.dataStep.conditionDataModel) {
            let ConditionModel = props.dataStep.conditionModel
            model = new ConditionModel(props.dataStep.conditionDataModel)
        }
        this.state.model = model

        this.state.chosenType = ""

        if (!!props.dataStep.type) {
            this.state.chosenType = props.dataStep.type
        }

        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = () => {
        this.onValidate(this.onNext)
    }

    onBack = () => {
        this.props.onBack(this.state.model)
    }

    onNext = () => {
        this.props.onNext(this.state.model)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    render() {
        try {
            const { t, dataConfig } = this.props
            const { model, isLoading, modelErrors, chosenType } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ConditionForm
                                    model={model}
                                    chosenType={chosenType}
                                    errors={modelErrors}
                                    handleChange={this.handleChange}
                                    optionsConditionHolidayType={dataConfig.optionsConditionHolidayType}
                                    optionsRecurrenceTypeList={dataConfig.optionsRecurrenceTypeList}
                                    optionsDayofWeekList={dataConfig.optionsDayofWeekList}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    {/*     <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.next"),
                                        color: "teal",
                                        // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                        onClick: this.onSubmit
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.back"),
                                        color: "yellow",
                                        onClick: this.onBack
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.cancel"),
                                        color: "grey",
                                        onClick: () => { this.props.history.push(`/sensorrule`) }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row> */}
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            {
                                dataConfig.stepStatus === "UPDATE" &&
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.save"),
                                        color: "green",
                                        // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                        onClick: () => { this.onValidate(() => this.props.onSave(model)) }
                                    })}
                                />
                            }
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.next"),
                                    color: "teal",
                                    // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                    onClick: this.onSubmit
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.cancel"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/sensorrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

ConditionView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
    // optionGreetingTypeList: [],
    // onCreate: () => console.error("Please provide \"Create Visitor\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ConditionView)