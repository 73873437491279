
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getNotificationInfo } from '../../actions/notificationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import NotificationView from '../../views/notification/NotificationView'

class ViewNotificationController extends MyController {
	getPropsResetStatus() {
		return ["notificationInfo"]
	}

	render() {
		try {
			const { notificationInfo } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const notification = notificationInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<NotificationView
							model={notification}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			notificationInfo: state.notificationInfo,
		}),
		(dispatch, props) => ({
			getNotificationInfo() {
				dispatch(getNotificationInfo(utils.getParamFromProps(props, "id")))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getNotificationInfo()
		}
	}),
)


export default enhance(ViewNotificationController) 