
import mongoose from 'mongoose'
import { areaTypes } from '../../../constant'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const PointsSchema = new Schema({
	order: { type: Number, required: true },
	x: { type: Number, required: true },
	y: { type: Number, required: true },
}, { _id: false })

const AreaSchema = new Schema({
	id: { type: String },
	locationTypeCode: { type: String, required: true },
	locationId: { type: String, required: true },
	type: { type: String, enum: [areaTypes.POLYGON, areaTypes.CIRCLE], required: true, default: areaTypes.POLYGON },
	points: [{ type: PointsSchema }],
})

setBaseSchema(AreaSchema)

AreaSchema.methods.serializeAPI = function () {
	try {
		let result = {
			locationTypeCode: this.locationTypeCode,
			locationId: this.locationId,
			type: this.type,
			points: this.points
		}
		if (!!this.id) {
			result.id = this.id
		}
		return result
	} catch (error) {
		return null
	}
}

export default AreaSchema
