

import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

import CollectDataModel from './sensorRuleData/conditionData/CollectDataModel'
import DoorAlertDataModel from './sensorRuleData/conditionData/DoorAlertDataModel'
import MotionDataModel from './sensorRuleData/conditionData/MotionDataModel'

import SensorRuleActionModel from './SensorRuleActionModel'
import SensorRuleManager from '../business/SensorRuleManager'

let modelsCondition = [CollectDataModel, DoorAlertDataModel, MotionDataModel]

const Schema = mongoose.Schema

const SensorRuleSchema = new Schema({
	id: { type: String },
	code: { type: String, required: [true, "validate.require"], },
	name: { type: String, required: [true, "validate.require"], },
	description: { type: String },

	type: { type: String, required: [true, "validate.require"], },
	sourceSensorIds: [{ type: String }],
	sourceSensors: { type: Object },
	conditionData: { type: Object, required: [true, "validate.require"] },
	actions: [{ type: Object }],
	isDisable: { type: Boolean },
	status: { type: String }
}, { _id: false })

setBaseSchema(SensorRuleSchema, "sensor_rule")

SensorRuleSchema.path("sourceSensorIds").validate(async function (sourceSensorIds) {
	return sourceSensorIds.length > 0
}, "validate.require")

SensorRuleSchema.path("actions").validate(async function (actions) {
	return actions.length > 0
}, "validate.require")

// SensorRuleSchema.path("conditionData").validate(async function (conditionData) {
// 	console.log("ConditionData", conditionData)
// 	return !!conditionData
// }, "validate.require")

SensorRuleSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
			type: this.type,
			sourceSensorIds: this.sourceSensorIds,
		}
		if (!!this.conditionData && !!modelsCondition.find(model => this.conditionData instanceof model)) {
			result.conditionData = this.conditionData.serializeAPI()
		}
		if (Array.isArray(this.actions) && this.actions.length > 0) {
			let tempAction = []
			this.actions.forEach(action => {
				if (action instanceof SensorRuleActionModel) {
					tempAction.push(action.serializeAPI())
				}
			})
			result.actions = tempAction
		}
		return result
	} catch (error) {
		return null
	}
}

SensorRuleSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data.id
			this.code = data.code
			this.name = data.name
			this.description = data.description
			this.type = data.type
			this.sourceSensorIds = data.sourceSensorIds
			this.sourceSensors = data.sourceSensors
			this.isDisable = data.isDisable
			this.status = data.status
		}
		let bSensorRule = new SensorRuleManager()
		if (!!data.conditionData) {
			let ModalCondition = bSensorRule.getModelSensorRuleCondition(data.type)
			if (!!ModalCondition) {
				this.conditionData = new ModalCondition(data.conditionData)
			}
		}
		if (Array.isArray(data.actions) && data.actions.length > 0) {
			this.actions = data.actions.map(action => {
				let model = new SensorRuleActionModel()
				model.setData(action)
				return model
			})
		}
	} catch (error) {

	}
}

const SensorRuleModel = mongoose.model("sensor_rule", SensorRuleSchema, "sensor_rule")

export default SensorRuleModel