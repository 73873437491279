
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Header, Table } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import LabelInput from '../../common/LabelInput'
import O365DataModel from '../../../models/systemIntegrationData/O365DataModel'

class O365DataView extends MyPage {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' className="form header">
                        {t("systemIntegration.o365")}
                    </Header>
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.apiUrl")} /></Table.Cell>
                                <Table.Cell><p>{model.apiUrl}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.authenUrl")} /></Table.Cell>
                                <Table.Cell><p>{model.authenUrl}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.clientId")} /></Table.Cell>
                                <Table.Cell><p>{model.clientId}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.clientSecret")} /></Table.Cell>
                                <Table.Cell><p>{model.clientSecret}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.tenantId")} /></Table.Cell>
                                <Table.Cell><p>{model.tenantId}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("systemIntegration.redirectUrl")} /></Table.Cell>
                                <Table.Cell><p>{model.redirectUrl}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

O365DataView.defaultProps = {
    model: new O365DataModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(O365DataView)