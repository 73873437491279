
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const DoorSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	locationId: { type: String, required: [true, "validate.require"], default: "" },
	location: { type: Object, default: null },
	description: { type: String, default: "" },
	fileUpload: { type: FileSchema, default: null },
	areas: { type: Object, default: null },
	integrationInfo: { type: Object, default: null },
	status: { type: String, default: "" },
	iotConfigs: { type: Object, default: null }
}, { _id: false })

setBaseSchema(DoorSchema, "06")

DoorSchema.path("code").validate(async function (code) {
	return !!code
}, "validate.require")

DoorSchema.path("name").validate(async function (name) {
	return !!name
}, "validate.require")

DoorSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			locationId: this.locationId,
			description: this.description
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		return result
		
	} catch (error) {
		return null
	}
}

const DoorModel = mongoose.model("desk", DoorSchema, "desk")

export default DoorModel