
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'
import ViewTable from '../../core/view/common/table/ViewTable'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchRole from './FormSearchRole'
import RoleModel from '../../models/RoleModel'
import { searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
// import ModalSyncRole from './ModalSyncRole'

class ViewRoleList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.modelSearch = new RoleModel()
        this.state.model = null

        this.state.isOpenModalSync = false

        this.state.rolePlatform = []
    }

    onClickDelete = (model) => {
        const { t, onDeleteRole } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteRole(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/role/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/role/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickAdd = () => {
        this.props.history.push(`/role/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "name",
                        value: model.name,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    onClickSync = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let body = {
                isLoading: false,
                isOpenModalSync: true,
            }

            let rolePlatform = []
/*             if (Array.isArray(this.state.rolePlatform) && this.state.rolePlatform.length > 0) {
                rolePlatform = this.state.rolePlatform
            }
            if (rolePlatform.length === 0) { */
                let tempDatas = await this.props.onGetrolePlatformList()
                rolePlatform = Array.isArray(tempDatas) ? tempDatas : []
            /* } */

            body.rolePlatform = rolePlatform

            this.setState(body)
        })
    }

    onCloseModalSync = () => {
        this.setState({
            isOpenModalSync: false
        })
    }

    onSubmitModalSync = (ids) => {
        this.setState({
            isOpenModalSync: false
        }, () => {
            this.props.onImportRoleFromPlatform(ids)
        })
    }

    onClickSyncAll = () => {
        const { t, onSyncRole } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.syncAllData"),
                    content: t("message.confirmSyncAll"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onSyncRole()
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    render() {
        try {
            const { t, roleList, isLoadingTable } = this.props
            const { isSearch, isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('role.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
{/*                                         <Grid.Column width={8} textAlign="right">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.syncInfo'),
                                                    color: "orange",
                                                    onClick: this.onClickSync
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.syncAll'),
                                                    color: "yellow",
                                                    onClick: this.onClickSyncAll
                                                })}
                                            />
                                        </Grid.Column> */}
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchRole onSearch={this.onSearch} />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTable
                                                columns={this.getColumnConfig()}
                                                datas={roleList}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ViewRoleList.defaultProps = {
    roleList: null,
    isLoadingTable: false,
    onDeleterole: () => console.error("Please provide \"Delete Resource\" action."),
    onReloadData: () => console.error("Please provide Load Data action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewRoleList)