
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import VisitorGroupList from '../../views/visitorGroup/VisitorGroupList'
import VisitorGroupManager from '../../business/VisitorGroupManager'

class VisitorGroupController extends MyController {

	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bVisitorGroup = new VisitorGroupManager(this)
	}

	onDeleteVisitorGroup = (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bVisitorGroup.deleteVisitorGroup(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }

				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}

				this.setState(body, action)
			})
		})
	}

	onSyncVisitorGroup = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bVisitorGroup.syncVisitorGroup((res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onImportVisitorGroupPlatform = (ids, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bVisitorGroup.importVisitorGroupPlatform(ids, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getVisitorGroupListPaging = (query) => {
		return this.bVisitorGroup.getVisitorGroupListPaging(query)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<VisitorGroupList
						isLoadingControl={isLoadingComponent}
						onDeleteVisitorGroup={this.onDeleteVisitorGroup}
						onSyncVisitorGroup={this.onSyncVisitorGroup}
						onImportVisitorGroupPlatform={this.onImportVisitorGroupPlatform}
						onGetDataPaging={this.getVisitorGroupListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}


const enhance = compose(
	translate,
	withRouter,
	/* 	connect(
			(state, props) => ({
			
			}),
			(dispatch, props) => ({
	
			}),
		),
		lifecycle({
			componentDidMount() {
	
			}
		}), */
)


export default enhance(VisitorGroupController) 