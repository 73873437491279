
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import NotificationModel from '../../models/NotificationModel'
import LabelInput from '../common/LabelInput'
import LineDataView from './dataView/LineDataView'
import { notificationService } from '../../../constant'
import SendGridDataView from './dataView/SendGridDataView'
import SMTPDataView from './dataView/SMTPDataView'
import ViewNoResult from '../common/ViewNoResult'

class NotificationView extends MyPage {
    onCreate = () => {
        this.props.history.push(`/notification/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/notification/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/notification`)
    }

    getNotificationDataView = (service) => {
        let ComponentView = null
        switch (service) {
            case notificationService.LINE_BOT:
                ComponentView = LineDataView
                break;
            case notificationService.SEND_GRID:
                ComponentView = SendGridDataView
                break;
            case notificationService.SMTP:
                ComponentView = SMTPDataView
                break;
            default:
                break;
        }
        return ComponentView
    }


    render() {
        try {
            const { t, model } = this.props

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            let ComponentView = this.getNotificationDataView(model.service)

            let systemName = ""

            if (!!model && !!model.systemIntegration) {
                systemName = model.systemIntegration.name
            }

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('notification.viewNotification')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    {/* <Table.Row>
                                            <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                            <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                        </Table.Row> */}
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('notification.service')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.service ? t(`constantTranslate.systemIntegration.${model.service}`) : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('notification.system')} /></Table.Cell>
                                                        <Table.Cell>{systemName}</Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            {
                                                !!ComponentView &&
                                                <div>
                                                    <ComponentView model={model.data} />
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

NotificationView.defaultProps = {
    model: new NotificationModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(NotificationView)