
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Grid, Form, Label, Header, Segment } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import utils from '../../core/utility/utils'
import IOTInput from '../common/IOTInputForm'
import ViewNoResult from '../common/ViewNoResult'

class IOTConfigForm extends MyForm {
    constructor(props) {
        super(props)

        this.state = {
            model: props.model,
            selectedSerialList: Array.isArray(props.model.serials) ? props.model.serials : [],
            iotOption: [],
            serialOption: [],
        }

        this.moduleMapping = {
            DOUT: ["LIGHT"],
            CURTAIN: ["CURTAIN"],
            DMX: ["LIGHT_DMX"],
            DALI: ["LIGHT_DALI"],
            BACNET: ["BACNET"],
            DOUT_RGB: ["LIGHT_RGB"],
        }
    }

    componentDidMount() {
        const { model, controllerList } = this.props
        const { selectedSerialList } = this.state

        this.setState({ selectedSerialList: Array.isArray(model.serials) ? model.serials : [] })

        let controller = controllerList.find(d => d.id === model.controllerId)

        let iotOption = []

        if (controller) {
            iotOption = this.getIOTOption(controller)
            this.setState({ iotOption })
            this.props.onchangeIOT(model.iotCode)
        }

        if (!!model.iotCode && model.iotCode !== "") {
            this.getSerialOption(model.iotCode)
            this.props.handleChangeSerialList(selectedSerialList)
        }

    }

    onChangeController = (e, { name, value }) => {
        const { controllerList } = this.props

        let controller = controllerList.find(d => d.id === value)
            , iotOption = []

        if (controller) {
            iotOption = this.getIOTOption(controller)
        }

        this.setState({ iotOption, serialOption: [] })
        this.props.onchangeController(controller)
    }

    getIOTOption = (controller) => {
        if (!controller || !Array.isArray(controller.enableModule)) {
            return []
        }

        return controller.enableModule.map(e => e.moduleCode)
            .reduce((r, e) => r.concat(this.moduleMapping[e]), [])
            .filter(item => item !== undefined)
            .map(e => {
                return {
                    key: e,
                    text: e,
                    value: e
                }
            })
    }

    onChangeIOT = (e, { name, value }) => {
        const { model, controllerList } = this.props
        let controller = controllerList.find(d => d.id === model.controllerId)
            , enableModule = null

        for (let moduleCode in this.moduleMapping) {
            if (!this.moduleMapping[moduleCode].includes(value)) {
                continue
            }

            enableModule = controller.enableModule.find(e => e.moduleCode === moduleCode)
            break
        }

        if (!enableModule) {
            return
        }

        this.setState({ serialOption: enableModule.serials })
        this.props.onchangeIOT(value)
    }

    getSerialOption = (iotCode) => {
        const { model, controllerList } = this.props
        let controller = controllerList.find(d => d.id === model.controllerId)
            , enableModule = null

        for (let moduleCode in this.moduleMapping) {
            if (!this.moduleMapping[moduleCode].includes(iotCode)) {
                continue
            }

            enableModule = controller.enableModule.find(e => e.moduleCode === moduleCode)
            break
        }

        if (!enableModule) {
            return
        }

        this.setState({ serialOption: enableModule.serials })
    }

    serialListCheckBoxList = (optionsSerialList) => {
        // const { selectedSerialList } = this.state
        const { model } = this.props

        let component = null

        if (!!optionsSerialList && Array.isArray(optionsSerialList) && optionsSerialList.length > 0) {
            component = optionsSerialList.map((serial, i) => {
                return <Form.Checkbox
                    label={serial}
                    name="select"
                    value={serial}
                    onChange={this.handleChangeSerialList}
                    checked={model.serials.includes(serial)}
                />
            })
        }

        return component
    }

    handleChangeSerialList = (e, { name, value, checked }) => {
        const { serialOption } = this.state
        const { model } = this.props

        let selectedSerialList = model.serials.map(s => s)
        if (name === "select") {
            if (checked) {
                if (!serialOption.includes(value) || selectedSerialList.includes(value)) {
                    return
                }

                selectedSerialList.push(value)
            } else {
                selectedSerialList = utils.arrayDiff(selectedSerialList, [value])
            }
        } else if (name === "selectAll") {
            if (checked) {
                selectedSerialList = serialOption
            } else {
                selectedSerialList = []
            }
        }

        this.props.handleChangeSerialList(selectedSerialList)
    }

    handleChangeLocationType = (e, { name, value }) => {
        this.props.onchangeLocationType(value)
    }

    handleChangeLocationId = (e, { name, value }) => {
        this.props.onchangeLocationId(value)
    }

    render() {
        try {
            const { t, model, optionsControllerList, optionsLocationList, optionsResourceList, optionsTypeList, errors } = this.props
            const { iotOption, serialOption } = this.state

            let IOTValidate = this.getErrorInput("iotCode", errors)
                , controllerIdValidate = this.getErrorInput("controllerId", errors)
                , initValueValidate = this.getErrorInput("initValue", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , serialListIdsValidate = this.getErrorInput("serials", errors)
                , locationTypeValidate = this.getErrorInput("location.type", errors)
                , locationIdValidate = this.getErrorInput("location.id", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('controller.title')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="controllerId"
                                        value={model.controllerId}
                                        options={optionsControllerList}
                                        onChange={this.onChangeController}
                                        error={controllerIdValidate.isError ? controllerIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('iotConfig.iotCode')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="iotCode"
                                        value={model.iotCode}
                                        options={iotOption}
                                        onChange={this.onChangeIOT}
                                        error={IOTValidate.isError ? IOTValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('common.description')} />
                                <Form.TextArea
                                    name="description"
                                    value={model.description}
                                    onChange={this.handleChange}
                                    error={descriptionValidate.isError ? descriptionValidate.content : null}
                                />
                            </Grid.Column>
                            {
                                !!model.iotCode &&
                                <Grid.Row>
                                    <Grid.Column width={16}>

                                        <React.Fragment>
                                            <LabelInput text={t("iotConfig.initialValue")} isRequire={true} />
                                            <IOTInput
                                                name="initValue"
                                                type={model.iotCode}
                                                value={model.initValue}
                                                onChange={this.handleChange}
                                            />
                                        </React.Fragment>

                                        {
                                            (initValueValidate.isError &&
                                                initValueValidate.content) &&
                                            <Form.Field>
                                                <Label pointing prompt>
                                                    {initValueValidate.content}
                                                </Label>
                                            </Form.Field>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                        <br></br>
                        {
                            // (!!serialOption && serialOption.length !== 0) &&
                            (!!model.iotCode) &&
                            <Grid.Row>
                                <Grid.Column width={16}>

                                    <React.Fragment>
                                        <LabelInput text={t("iotConfig.serial")} isRequire={true} />
                                        {
                                            Array.isArray(serialOption) && serialOption.length > 0 ?
                                                <div className="module multipleCheckboxContainer">
                                                    <Segment className="group-segment">
                                                        <div className="checkbox-wrapper">

                                                            <Form.Checkbox
                                                                label={t("common.selectAll")}
                                                                name={`selectAll`}
                                                                onChange={this.handleChangeSerialList}
                                                                checked={serialOption.length === model.serials.length}
                                                            />


                                                            {this.serialListCheckBoxList(serialOption)}
                                                        </div>
                                                    </Segment>
                                                </div>
                                                : <ViewNoResult message={t("iotConfig.noSerial")} />
                                        }
                                    </React.Fragment>

                                    {
                                        (serialListIdsValidate.isError &&
                                            serialListIdsValidate.content) &&
                                        <Form.Field>
                                            <Label pointing prompt>
                                                {serialListIdsValidate.content}
                                            </Label>
                                        </Form.Field>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <br></br>
                        <Header as='h1' dividing className="form header">
                            {t("location.title")}
                        </Header>
                        <Segment className="group-segment-input">
                            <Grid>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('iotConfig.type')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="location.type"
                                            value={model.location.type}
                                            options={optionsTypeList}
                                            onChange={this.handleChangeLocationType}
                                            error={locationTypeValidate.isError ? locationTypeValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    (model.location.type === "LOCATION") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('location.title')} isRequire={true} />
                                            <Form.Dropdown
                                                selection
                                                selectOnBlur={false}
                                                placeholder={t("common.pleaseSelect")}
                                                name="location.id"
                                                value={model.location.id}
                                                options={optionsLocationList}
                                                onChange={this.handleChangeLocationId}
                                                error={locationIdValidate.isError ? locationIdValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {
                                    (model.location.type === "RESOURCE") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('iotConfig.resource')} isRequire={true} />
                                            <Form.Dropdown
                                                selection
                                                selectOnBlur={false}
                                                placeholder={t("common.pleaseSelect")}
                                                name="location.id"
                                                value={model.location.id}
                                                options={optionsResourceList}
                                                onChange={this.handleChangeLocationId}
                                                error={locationIdValidate.isError ? locationIdValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Segment>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

IOTConfigForm.defaultProps = {
    model: null,
    errors: [],
    optionsControllerList: [], 
    optionsLocationList: [], 
    optionsResourceList: [], 
    optionsTypeList: [],
    handleChange: () => console.error("Please provide \"Change IOTConfig\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(IOTConfigForm)