
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import { sensorRuleActionType, sensorRuleType } from '../../constant'
import DoorAlertDataModel from '../models/sensorRuleData/conditionData/DoorAlertDataModel'
import MotionDataModel from '../models/sensorRuleData/conditionData/MotionDataModel'
import CollectDataModel from '../models/sensorRuleData/conditionData/CollectDataModel'
import ControlDataModel from '../models/sensorRuleData/actionData/ControlDataModel'
import AlertMessageDataModel from '../models/sensorRuleData/actionData/AlertMessageDataModel'
import AlertDashboardDataModel from '../models/sensorRuleData/actionData/AlertDashboardDataModel'
import DatabaseDataModel from '../models/sensorRuleData/actionData/DatabaseDataModel'
import PagingDataModel from '../core/model/PagingDataModel'
import SensorRuleModel from '../models/SensorRuleModel'

export default class SensorRuleManager extends MyManager {
    async createSensorRule(body, callback) {
        try {
            const options = {
                method: this.requestMethod.POST,
                url: `${CORE_API_ENDPOINT}/sensorrule`,
                data: body
            }

            let res = await this.requestAction(options, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async updateSensorRule(id, body, callback) {
        try {
            const options = {
                method: this.requestMethod.PATCH,
                url: `${CORE_API_ENDPOINT}/sensorrule/${id}`,
                data: body
            }

            let res = await this.requestAction(options, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async deleteSensorRule(id, callback) {
        try {
            const options = {
                method: this.requestMethod.DELETE,
                url: `${CORE_API_ENDPOINT}/sensorrule/${id}`,
            }

            let res = await this.requestAction(options, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    getModelSensorRuleCondition(type) {
        try {
            let model = null
            switch (type) {
                case sensorRuleType.DOOR_ALERT:
                    model = DoorAlertDataModel
                    break;
                case sensorRuleType.MOTION:
                    model = MotionDataModel
                    break;
                case sensorRuleType.COLLECT:
                    model = CollectDataModel
                    break;
                default:
                    break;
            }
            return model
        } catch (error) {
            return null
        }
    }

    getModelSensorRuleAction(type) {
        try {
            let model = null
            switch (type) {
                case sensorRuleActionType.CONTROL:
                    model = ControlDataModel
                    break;
                case sensorRuleActionType.ALERT:
                    model = AlertMessageDataModel
                    break;
                case sensorRuleActionType.ALERT_DASHBOARD:
                    model = AlertDashboardDataModel
                    break;
                case sensorRuleActionType.DATABASE:
                    model = DatabaseDataModel
                    break;
                default:
                    break;
            }
            return model
        } catch (error) {
            return null
        }
    }

    async enableSensorRuleStatus(id, callback) {
        // console.log(id, body)
        try {
            const options = {
                method: this.requestMethod.PATCH,
                url: `${CORE_API_ENDPOINT}/sensorrule/enable/${id}`,
            }
            let res = await this.requestAction(options, callback)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async disableSensorRuleStatus(id, callback) {
        // console.log(id, body)
        try {
            const options = {
                method: this.requestMethod.PATCH,
                url: `${CORE_API_ENDPOINT}/sensorrule/disable/${id}`,
            }
            let res = await this.requestAction(options, callback)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async getSensorRuleListPaging(query) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/sensorrule/page${!!query ? query : ""}`,
            }

            let res = await this.requestAction(options)

            let data = null

            if (!!res && res.result === true && !!res.data) {
                data = new PagingDataModel({ pagination: res.data.pagination })
                if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
                    data.datas = res.data.datas.map(d => {
                        return new SensorRuleModel(d)
                    })
                }
            }

            return data
        } catch (error) {
            this.setDefaultModalError(error)
            return null
        }
    }

    async getSensorRuleInfo(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/sensorrule/${id}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new SensorRuleModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}
}