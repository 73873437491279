
import mongoose from 'mongoose'
//import { SceneTypes } from '../../../constant'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const IOTConfigsSchema = new Schema({
	id: { type: String },
	actionMode: { type: String },
	status: { type: Object }
}, { _id: false })

const SceneSchema = new Schema({
	id: { type: String },
	code: { type: String, required: [true, "validate.require"] },
	name: { type: String, required: [true, "validate.require"] },
	description: { type: String, default: "" },
	iotConfigs: [{ type: IOTConfigsSchema }],
})

setBaseSchema(SceneSchema)

SceneSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
		}

		let iotConfigs = this.iotConfigs.map((iotData, i) => {
			return {
				id: iotData.id,
				actionMode: iotData.actionMode,
				status: iotData.status
			}
		})

		result.iotConfigs = iotConfigs

		return result
	} catch (error) {
		return null
	}
}

export default SceneSchema
