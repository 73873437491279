
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'
import ViewTable from '../../core/view/common/table/ViewTable'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchGateway from './FormSearchGateway'
import GatewayModel from '../../models/GatewayModel'
import { searchQueryType, onlineOffline } from '../../../constant'
import utils from '../../core/utility/utils'

class GatewayList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.modelSearch = new GatewayModel()
        this.state.model = null
    }

    onClickDelete = (model) => {
        const { t, onDeleteGateway } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteGateway(model, () => this.onSearch(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickStatus = (model) => {
        const { t, onChangeDisableGateway, onChangeEnableGateway } = this.props
        try {
            if (!!model) {
                if (model.isDisable === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.disableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeDisableGateway(model, () => this.onSearch(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.enableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeEnableGateway(model, () => this.onSearch(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                }
            }

        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false
            },
            {
                name: t("gateway.status"),
                selector: (row, i) => {
                    if (row.status === onlineOffline.ONLINE) {
                        return <Icon color="green" name="circle" size="small" />
                    } else if (row.status === onlineOffline.OFFLINE) {
                        return <Icon color="red" name="circle" size="small" />
                    } else {
                        return <Icon color="grey" name="circle" size="small" />
                    }
                },
                sortable: false,
                center: true,
                width: '100px'
            },
            {
                name: t("gateway.enable"),
                selector: (row, i) => {
                    if (row.isDisable === null) {
                        return ""
                    } else if (row.isDisable === false) {
                        return <Icon color="green" name="checkmark" />
                    } else if (row.isDisable === true) {
                        return <Icon color="red" name="close" />
                    }
                },
                sortable: false,
                center: true,
                width: '100px'
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = []

                    if (!!row) {
                        if (row.isDisable === false) {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/gateway/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusDisable"),
                                    color: "red",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "close",
                                    size: "mini"
                                }
                            ]
                        } else {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/gateway/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusEnable"),
                                    color: "green",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "check",
                                    size: "mini"
                                }
                            ]
                        }
                    }

                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {

                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    render() {
        try {
            const { t, gatewayList, isLoadingTable, optionLocationList, optionsStatusList } = this.props
            const { isSearch, isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('gateway.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            {/*                                             <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.add'),
                                                    color: "green",
                                                    onClick: this.onClickAdd
                                                })}
                                            /> */}
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        {/*                                         <Grid.Column width={8} textAlign="right">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.syncInfo'),
                                                    color: "orange",
                                                    onClick: this.onClickSync
                                                })}
                                            />
                                        </Grid.Column> */}
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchGateway onSearch={this.onSearch}
                                                    optionLocationList={optionLocationList}
                                                    optionsStatusList={optionsStatusList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTable
                                                columns={this.getColumnConfig()}
                                                datas={gatewayList}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

GatewayList.defaultProps = {
    gatewayList: [],
    optionLocationList: [], 
    optionsStatusList: [],
    isLoadingTable: false,
    onDeleteGateway: () => console.error("Please provide \"Delete Gateway\" action."),
    onReloadData: () => console.error("Please provide Load Data action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(GatewayList)