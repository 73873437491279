
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Header, Table, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import RFIDCardModel from '../../models/RFIDCardModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewNoResult from '../common/ViewNoResult'

class RFIDCardView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.addPoint = false

        this.state.modelArea = null
        this.state.areaStatus = null

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/rfidcard/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/rfidcard/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/rfidcard`)
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.type ? row.type : "-",
                sortable: false,
                maxWidth: '250px'
            },
            {
                name: t("rfidCard.value"),
                selector: (row) => row.value ? row.value : '-',
                sortable: false,
            },
        ]

        return columns
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformRFIDCard(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, model } = this.props
            const { isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('rfidCard.viewRFIDCard')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('rfidCard.cardNo')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.cardNo ? model.cardNo : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><LabelInput text={t('common.type')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.type ? model.type : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16} textAlign="left" verticalAlign="bottom">
                                                        <Header as='h1'>{t("rfidCard.cardData")}</Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ViewTable
                                                            columns={this.getColumnConfig()}
                                                            datas={model.datas}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>


                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

RFIDCardView.defaultProps = {
    model: new RFIDCardModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(RFIDCardView)