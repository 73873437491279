
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const SMS8X8DataSchema = new Schema({
	url: { type: String, required: [true, "validate.require"], default: "" },
	token: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(SMS8X8DataSchema, "system_integration_sms8x8")

const SMS8X8DataModel = mongoose.model("system_integration_sms8x8", SMS8X8DataSchema, "system_integration_sms8x8")

export default SMS8X8DataModel