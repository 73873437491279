
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getECRHolidayTypeList, getECRActionTypeList, getECRActionStatusList, getECRRecurrenceTypeList, getECRDayOfWeekList, getECREventList } from '../../actions/eventControlRuleActions'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
import { getVisitorGroupList } from '../../actions/visitorGroupActions'
import { getLocationList } from '../../actions/locationActions'
import EventControlRuleManager from '../../business/EventControlRuleManager'
import ViewEventControlRuleUpdate from '../../views/eventControlRuleStep/ViewEventControlRuleUpdate'
import utils from '../../core/utility/utils'

class UpdateEventControlRuleController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bEventControlRule = new EventControlRuleManager(this)

        this.state.optionsResources = []
    }

    onGetEventControlRuleInfo = (id) => {
        return this.bEventControlRule.getEventControlRuleInfo(id)
    }

    getPropsResetStatus() {
        return ["ecrHolidayTypeList", "ecrActionTypeList", "ecrActionStatusList",
            "ecrRecurrenceTypeList", "ecrDayOfWeekList", "ecrEventList", "locationList",
            "resourceGroupList", "visitorGroupList"]
    }

    onUpdateEventControlRule = async (model, key) => {
        // console.log("model: ", model.serializeAPI())
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bEventControlRule.updateEventControlRule(model.id, model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {
                    if (key) {
                        action = this.props.history.push(`/eventcontrolrule/view/${res.data.id}?step=${key}`)
                    } else {
                        action = this.props.history.push(`/eventcontrolrule/view/${res.data.id}`)
                    }
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/eventcontrolrule`)
    }

    onGetDataList = async (resourceType, callback) => {
        if (resourceType === "ROOM") {
            this.bEventControlRule.getRoomList((res) => {
                let data = []
                if (!!res && res.result === true && !!res.datas) {
                    if (Array.isArray(res.datas) && res.datas.length > 0) {
                        data = res.datas.map(d => {
                            return {
                                key: d.id,
                                value: d.id,
                                text: d.name,
                                code: d.code,
                            }
                        })

                    }
                }

                this.setState({
                    optionsResources: data
                }, callback)
            })
        } else if (resourceType === "DESK") {
            this.bEventControlRule.getDeskList((res) => {
                let data = []
                if (!!res && res.result === true && !!res.datas) {
                    if (Array.isArray(res.datas) && res.datas.length > 0) {
                        data = res.datas.map(d => {
                            return {
                                key: d.id,
                                value: d.id,
                                text: d.name,
                                code: d.code,
                            }
                        })

                    }
                }

                this.setState({
                    optionsResources: data
                }, callback)
            })
        } else if (resourceType === "DOOR") {
            this.bEventControlRule.getDoorList((res) => {
                let data = []
                if (!!res && res.result === true && !!res.datas) {
                    if (Array.isArray(res.datas) && res.datas.length > 0) {
                        data = res.datas.map(d => {
                            return {
                                key: d.id,
                                value: d.id,
                                text: d.name,
                                code: d.code,
                            }
                        })

                    }
                }

                this.setState({
                    optionsResources: data
                }, callback)
            })
        } else {
            this.setState({
                optionsResources: []
            }, callback)
        }

    }

    render() {
        try {
            const { ecrHolidayTypeList, ecrActionTypeList, ecrActionStatusList, ecrRecurrenceTypeList, ecrDayOfWeekList, ecrEventList, locationList, visitorGroupList, resourceGroupList } = this.props
            const { isLoadingComponent, optionsResources } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            let optionsEventList = ecrEventList.getDatasOption()
                , locationDatas = locationList.getDatas()
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")
                , optionsResourceGroupList = resourceGroupList.getDatasOption()
                , optionsVisitorGroupList = visitorGroupList.getDatasOption()
                , optionsHolidayTypeList = ecrHolidayTypeList.getDatasOption()
                , optionsRecurrenceTypeList = ecrRecurrenceTypeList.getDatasOption()
                , optionsDayOfWeekList = ecrDayOfWeekList.getDatasOption()
                , optionsActionTypeList = ecrActionTypeList.getDatasOption()
                , optionsActionStatusList = ecrActionStatusList.getDatasOption()

            const optionsResourceType = [
                {
                    key: "ROOM",
                    value: "ROOM",
                    text: "ROOM"
                },
                {
                    key: "DESK",
                    value: "DESK",
                    text: "DESK"
                },
                {
                    key: "DOOR",
                    value: "DOOR",
                    text: "DOOR"
                }
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewEventControlRuleUpdate
                        isLoadingControl={isLoadingComponent}
                        optionsEventList={optionsEventList}
                        optionsTreeLocation={optionsTreeLocation}
                        optionsResourceGroupList={optionsResourceGroupList}
                        optionsVisitorGroupList={optionsVisitorGroupList}
                        optionsHolidayTypeList={optionsHolidayTypeList}
                        optionsRecurrenceTypeList={optionsRecurrenceTypeList}
                        optionsDayOfWeekList={optionsDayOfWeekList}
                        optionsActionTypeList={optionsActionTypeList}
                        optionsActionStatusList={optionsActionStatusList}
                        optionsResources={optionsResources}
                        optionsResourceType={optionsResourceType}
                        onSubmit={this.onUpdateEventControlRule}
                        onGetEventControlRuleInfo={this.onGetEventControlRuleInfo}
                        onGetDataList={this.onGetDataList}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            ecrHolidayTypeList: state.ecrHolidayTypeList,
            ecrActionTypeList: state.ecrActionTypeList,
            ecrActionStatusList: state.ecrActionStatusList,
            ecrRecurrenceTypeList: state.ecrRecurrenceTypeList,
            ecrDayOfWeekList: state.ecrDayOfWeekList,
            ecrEventList: state.ecrEventList,
            locationList: state.locationList,
            resourceGroupList: state.resourceGroupList,
            visitorGroupList: state.visitorGroupList,
        }),
        (dispatch, props) => ({
            getECRHolidayTypeList() {
                dispatch(getECRHolidayTypeList())
            },
            getECRActionTypeList() {
                dispatch(getECRActionTypeList())
            },
            getECRActionStatusList() {
                dispatch(getECRActionStatusList())
            },
            getECRRecurrenceTypeList() {
                dispatch(getECRRecurrenceTypeList())
            },
            getECRDayOfWeekList() {
                dispatch(getECRDayOfWeekList())
            },
            getECREventList() {
                dispatch(getECREventList())
            },
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getLocationList() {
                dispatch(getLocationList())
            },
            getVisitorGroupList() {
                dispatch(getVisitorGroupList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getECRHolidayTypeList()
            this.props.getECRActionTypeList()
            this.props.getECRActionStatusList()
            this.props.getECRRecurrenceTypeList()
            this.props.getECRDayOfWeekList()
            this.props.getECREventList()
            this.props.getResourceGroupList()
            this.props.getLocationList()
            this.props.getVisitorGroupList()
        }
    }),
)


export default enhance(UpdateEventControlRuleController)