
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import BoxModalForTimeSlots from '../common/BoxModalForTimeSlots'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import moment from 'moment'

class ModalDateSelect extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.date = !!props.date ? moment(props.date) : moment()
    }

    handleChange = (e, { name, value }) => {
        this.setState({
            date: value
        })
    }

    onSubmit = () => {
        const { date } = this.state
        this.props.onSubmit(date)
    }

    render() {
        try {
            const { date } = this.state
            const { t, onClose, textHeader, labelTitle } = this.props
            
            let buttonModal = [
                new ButtonModel({
                    text: t("common.add"),
                    color: "green",
                    onClick: this.onSubmit
                }),
                new ButtonModel({
                    text: t("common.close"),
                    color: "grey",
                    onClick: onClose
                })
            ]
            if (this.props.date) {
                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }

            return this.getModalContent(this.getModalOption({
                headerText: textHeader,
                onClose: () => { },
                content: <Form>
                    <BoxModalForTimeSlots
                        content={
                            <React.Fragment>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8}>
                                            <LabelInput text={labelTitle} isRequire={true} />
                                            <InputDatePicker
                                                name="date"
                                                value={date}
                                                onChange={this.handleChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        }
                    />
                </Form>,
                buttons: buttonModal
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalDateSelect.defaultProps = {
    textHeader: "",
    labelTitle: "",
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
    date: null,
}

export default (translate(ModalDateSelect))