
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class VisitorResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (typeof data === "string") {
				result = data
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d,
						value: d,
						text: d
					}
				})
			} else {
				result = {
					key: data,
					value: data,
					text: data
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}