
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchScheduleControl from './FormSearchScheduleControl'
import ScheduleControlModel from '../../models/ScheduleControlModel'
import { searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class ScheduleControlList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.isLoadingTable = false
        this.state.modelSearch = new ScheduleControlModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    onClickDelete = (model) => {
        const { t, onDeleteScheduleControl } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteScheduleControl(model, () => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickStatus = (model) => {
        const { t, onChangeDisableScheduleControl, onChangeEnableScheduleControl } = this.props
        try {
            if (!!model) {
                if (model.isDisable === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.disableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeDisableScheduleControl(model, () => this.onSearchByForm(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeStatus"),
                            content: t("message.enableStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                            onChangeEnableScheduleControl(model, () => this.onSearchByForm(this.state.modelSearch))
                                        }
                                    }
                                ),
                                new ButtonModel(
                                    {
                                        text: t("common.close"),
                                        color: "grey",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                }
            }

        }
        catch (error) {
            this.setModalCatch(error)
        }
    }


    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: t("common.status"),
                selector: (row, i) => {
                    if (row.scheduleJobStatus === "START") {
                        return <Icon color="green" name="circle" size="small" />
                    } else {
                        return <Icon color="red" name="circle" size="small" />
                    } /* else {
                        return <Icon color="grey" name="circle" size="small"/>
                    } */

                },
                sortable: false,
                center: true,
                width: '100px'
            },
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                cell: row => {
                    let buttons = []

                    if (!!row) {
                        if (row.isDisable === false) {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/schedulecontrol/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusDisable"),
                                    color: "red",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "close",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.props.history.push(`/schedulecontrol/update/${row.id}`) },
                                    iconClass: "edit",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.delete"),
                                    color: "red",
                                    onClick: () => { this.onClickDelete(row) },
                                    iconClass: "trash",
                                    size: "mini"
                                }
                            ]
                        } else {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.view"),
                                    color: "violet",
                                    onClick: () => { this.props.history.push(`/schedulecontrol/view/${row.id}`) },
                                    iconClass: "search",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.changeStatusEnable"),
                                    color: "green",
                                    onClick: () => { this.onClickStatus(row) },
                                    iconClass: "check",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.props.history.push(`/schedulecontrol/update/${row.id}`) },
                                    iconClass: "edit",
                                    size: "mini"
                                },
                                {
                                    text: "",
                                    title: t("common.delete"),
                                    color: "red",
                                    onClick: () => { this.onClickDelete(row) },
                                    iconClass: "trash",
                                    size: "mini"
                                }
                            ]
                        }
                    }
                    /*                     let buttons = [
                                            {
                                                text: "",
                                                title: t("common.view"),
                                                color: "violet",
                                                onClick: () => { this.props.history.push(`/schedulecontrol/view/${row.id}`) },
                                                iconClass: "search",
                                                size: "mini"
                                            },
                                            {
                                                text: "",
                                                title: t("common.edit"),
                                                color: "blue",
                                                onClick: () => { this.props.history.push(`/schedulecontrol/update/${row.id}`) },
                                                iconClass: "edit",
                                                size: "mini"
                                            },
                                            {
                                                text: "",
                                                title: t("common.delete"),
                                                color: "red",
                                                onClick: () => { this.onClickDelete(row) },
                                                iconClass: "trash",
                                                size: "mini"
                                            }
                                        ] */
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickAdd = () => {
        this.props.history.push(`/schedulecontrol/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "name",
                        value: model.name,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "name",
                        value: model.name,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t } = this.props
            const { isSearch, isLoading, pagingDataModel, isLoadingTable } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('scheduleControl.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchScheduleControl onSearch={this.onSearchByForm} />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ScheduleControlList.defaultProps = {
    onDeleteScheduleControl: () => console.error("Please provide \"Delete Resource\" action."),
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ScheduleControlList)