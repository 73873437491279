
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPageSystemIntegration from './MyPageSystemIntegration'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import SystemIntegrationForm from './SystemIntegrationForm'
import ButtonModel from '../../core/model/ButtonModel'
import SystemIntegrationModel from '../../models/SystemIntegrationModel'

class SystemIntegrationUpdate extends MyPageSystemIntegration {
    constructor(props) {
        super(props)
        this.state.model = !!props.model ? new SystemIntegrationModel(props.model) : null
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = new SystemIntegrationModel(nextProps.model)
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onView = (model) => {
        this.props.history.push(`/systemintegration/view/${model.id}`)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onUpdate = () => {
        let model = this.state.model
        this.props.onUpdate(model)
    }

    onBack = () => {
        this.props.history.push(`/systemintegration`)
        //this.props.history.goBack()
    }

    onTestSystemIntegration = () => {
        return this.props.onTestSystemIntegration(this.state.model)
    }

    render() {
        try {
            const { t, optionsSystemIntegrationService, isLoadingControl } = this.props
            const { model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('systemIntegration.updateSystemIntegration')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                !!model ?
                                                    <SystemIntegrationForm
                                                        model={model}
                                                        optionsSystemIntegrationService={optionsSystemIntegrationService}
                                                        handleChange={this.handleChange}
                                                        handleChangeMultiValue={this.handleChangeMultiValue}
                                                        errors={modelErrors}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                        onTestSystemIntegration={this.onTestSystemIntegration}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SystemIntegrationUpdate.defaultProps = {
    // model: new SystemIntegrationModel(),
    isLoadingControl: false,
    optionsSystemIntegrationService: [],
    onUpdate: () => console.error("Please provide \"Update System Integration\" action."),
    onTestSystemIntegration: () => {
        console.error("Please provide \"Test\" action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SystemIntegrationUpdate)