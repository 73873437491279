
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form, Label } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import EventControlRuleInfoModel from '../../../models/eventControlRuleStep/EventControlRuleInfoModel'
import LabelInput from '../../common/LabelInput'
import MultipleCheckboxList from '../../common/MultipleCheckboxList'
import ModalDepartmentECR from '../ModalDepartmentECR'
import ModalResourcesECR from '../ModalResourcesECR'
import ModalLocationECR from '../ModalLocationECR'
import ViewButton from '../../common/ViewButton'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewTable from '../../../core/view/common/table/ViewTable'

class EventInformationForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.openDepartmentModal = false
        this.state.departmentModal = null
        this.state.departmentIndex = null
        this.state.departmentModalStatus = null

        this.state.isOpenModalResources = false
        this.state.resourceModel = null
        this.state.resourceIndex = null
        this.state.resourceStatus = null
        this.state.resourceFieldName = null

        this.state.isOpenLocationModal = false
        this.state.locationModel = null
        this.state.locationIndex = null
        this.state.locationStatus = null
        this.state.locationFieldName = null
    }

    onOpenModelDepartment = (status, model, index) => {
        this.setState({
            openDepartmentModal: true,
            departmentModal: model,
            departmentModalStatus: status,
            departmentIndex: index
        })
    }

    onCloseModelDepartment = () => {
        this.setState({
            openDepartmentModal: false
        })
    }

    onSubmitModelDepartment = (departmentInfo, departmentList) => {
        const { departmentModalStatus, departmentIndex } = this.state
        let currentDepartmentList = []

        if (departmentModalStatus === "CREATE") {
            currentDepartmentList = departmentList.map(department => department)

            departmentInfo.forEach(department => {
                if (!!currentDepartmentList.find(departmentList => department.id === departmentList.id) === false) {
                    let departmentInfoRaw = {
                        id: department.id,
                        code: department.code,
                        name: department.name
                    }
                    currentDepartmentList.push(departmentInfoRaw)
                }
            })

        } else if (departmentModalStatus === "UPDATE") {
            currentDepartmentList = departmentList.map(department => department)
            currentDepartmentList[departmentIndex] = {
                id: departmentInfo.id,
                code: departmentInfo.code,
                name: departmentInfo.name
            }
        }

        this.setState({
            openDepartmentModal: false
        }, () => {
            this.handleChange(null, { name: "source.departments", value: currentDepartmentList })
        })
    }

    onRemoveModelDepartment = (index) => {
        const { model } = this.props

        if (Array.isArray(model.source.departments) && model.source.departments.length > 0) {
            let currentDepartmentList = model.source.departments.map(t => t)

            if (index > -1) {
                currentDepartmentList.splice(index, 1)
            }
            this.props.handleChange(null, { name: "source.departments", value: currentDepartmentList })
        }

    }

    getColumnDepartmentConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelDepartment(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onOpenModalResource = (status, model, index, fieldName) => {
        this.setState({
            isOpenModalResources: true,
            resourceModel: model,
            resourceStatus: status,
            resourceIndex: index,
            resourceFieldName: fieldName
        })
    }

    onCloseModalResource = () => {
        this.setState({
            isOpenModalResources: false,
            resourceFieldName: ""
        })
    }

    onSubmitModelResource = (resourceInfo) => {
        const { model } = this.props
        const { resourceStatus, resourceIndex, resourceFieldName } = this.state
        let currentResourceList = []

        if (resourceStatus === "CREATE") {
            if (resourceFieldName === "source.resources") {
                let resourceList = !!model.source.resources ? model.source.resources : []
                currentResourceList = resourceList.map(resource => resource)
            } else if (resourceFieldName === "source.areaResources") {
                let areaResourceList = !!model.source.areaResources ? model.source.areaResources : []
                currentResourceList = areaResourceList.map(resource => resource)
            }

            let resourceType = resourceInfo.type
            let resourceIds = resourceInfo.id.map(data => {
                return data
            })
            let resourcesInfomation = resourceInfo.resources.map(data => {
                return data.name
            })

            if (Array.isArray(resourceIds) && resourceIds.length > 0) {
                let idLength = resourceIds.length

                for (let i = 0; i < idLength; i++) {
                    if (!!currentResourceList.find(resource => resource.id === resourceIds[i]) === false) {
                        let resourceRawData = {
                            resourceTypeCode: resourceType,
                            id: resourceIds[i],
                            displayName: resourcesInfomation[i]
                        }
                        currentResourceList.push(resourceRawData)
                    }
                }
            }

        } else if (resourceStatus === "UPDATE") {
            if (resourceFieldName === "source.resources") {
                let resourceList = !!model.source.resources ? model.source.resources : []
                currentResourceList = resourceList.map(resource => resource)
            } else if (resourceFieldName === "source.areaResources") {
                let areaResourceList = !!model.source.areaResources ? model.source.areaResources : []
                currentResourceList = areaResourceList.map(resource => resource)
            }

            currentResourceList[resourceIndex] = resourceInfo
        }

        this.setState({
            isOpenModalResources: false
        }, () => {
            this.props.handleChange(null, { name: resourceFieldName, value: currentResourceList })
        })
    }

    onRemoveModelResource = (index, fieldName) => {
        const { model } = this.props

        if (fieldName === "source.resources") {
            if (Array.isArray(model.source.resources) && model.source.resources.length > 0) {
                let currentResourceList = model.source.resources.map(t => t)

                if (index > -1) {
                    currentResourceList.splice(index, 1)
                }
                this.props.handleChange(null, { name: fieldName, value: currentResourceList })
            }
        } else if (fieldName === "source.areaResources") {
            if (Array.isArray(model.source.areaResources) && model.source.areaResources.length > 0) {
                let currentResourceList = model.source.areaResources.map(t => t)

                if (index > -1) {
                    currentResourceList.splice(index, 1)
                }
                this.props.handleChange(null, { name: fieldName, value: currentResourceList })
            }
        }


    }

    getColumnResourceConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.resourceTypeCode ? row.resourceTypeCode : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("eventControlRule.resource"),
                selector: (row) => {
                    /*    if (Array.isArray(row.resources) && row.resources.length > 0) {
                           return <div>
                               {
                                   row.resources.map(resource => {
                                       return <p>• {resource.name}</p>
                                   })
                               }
                           </div>
                       } */

                    return row.displayName ? row.displayName : '-'
                },
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*         {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.onOpenModalResource("UPDATE", row, i, "source.resources") },
                                    iconClass: "edit",
                                    size: "mini"
                                }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelResource(i, "source.resources") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    getColumnAreaResourceConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.resourceTypeCode ? row.resourceTypeCode : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("eventControlRule.resource"),
                selector: (row) => {
                    /*     if (Array.isArray(row.resources) && row.resources.length > 0) {
                            return <div>
                                {
                                    row.resources.map(resource => {
                                        return <p>• {resource.name}</p>
                                    })
                                }
                            </div>
                        } */

                    return row.displayName ? row.displayName : '-'
                },
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*           {
                                      text: "",
                                      title: t("common.edit"),
                                      color: "blue",
                                      onClick: () => { this.onOpenModalResource("UPDATE", row, i, "source.areaResources") },
                                      iconClass: "edit",
                                      size: "mini"
                                  }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelResource(i, "source.areaResources") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onOpenLocation = (status, model, index, fieldName) => {
        this.setState({
            isOpenLocationModal: true,
            locationModel: model,
            locationIndex: index,
            locationStatus: status,
            locationFieldName: fieldName
        })
    }

    onCloseLocation = () => {
        this.setState({
            isOpenLocationModal: false
        })
    }

    onSubmitModalLocation = (locationInfo) => {
        const { model } = this.props
        const { locationIndex, locationStatus, locationFieldName } = this.state

        let currentLocationList = []

        if (locationFieldName === "source.locations") {
            currentLocationList = !!model.source.locations ? model.source.locations : []
        } else if (locationFieldName === "source.areaLocations") {
            currentLocationList = !!model.source.areaLocations ? model.source.areaLocations : []
        }

        if (locationStatus === "CREATE") {
            // let rawLocationInfo = {
            //     id: locationInfo.value,
            //     //code: locationInfo.code,
            //     name: locationInfo.text
            // }

            locationInfo.forEach(data => {
                let findValue = currentLocationList.findIndex(location => {
                    return location.id === data.value
                })

                if (findValue === -1) {
                    currentLocationList.push({
                        id: data.value,
                        name: data.text
                    })
                }
            })

            //currentLocationList.push(rawLocationInfo)

        } else if (locationStatus === "UPDATE") {
            currentLocationList[locationIndex] = {
                id: locationInfo.value,
                //code: locationInfo.code,
                name: locationInfo.text
            }
        }

        this.setState({
            isOpenLocationModal: false
        }, () => {
            this.props.handleChange(null, { name: locationFieldName, value: currentLocationList })
        })
    }

    onClickDeleteLocation = (index, fieldName) => {
        const { model } = this.props

        if (fieldName === "source.locations") {
            if (Array.isArray(model.source.locations) && model.source.locations.length > 0) {
                let currentResourceList = model.source.locations.map(t => t)

                if (index > -1) {
                    currentResourceList.splice(index, 1)
                }
                this.props.handleChange(null, { name: fieldName, value: currentResourceList })
            }
        } else if (fieldName === "source.areaLocations") {
            if (Array.isArray(model.source.areaLocations) && model.source.areaLocations.length > 0) {
                let currentResourceList = model.source.areaLocations.map(t => t)

                if (index > -1) {
                    currentResourceList.splice(index, 1)
                }
                this.props.handleChange(null, { name: fieldName, value: currentResourceList })
            }
        }
    }

    getColumnLocationConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("location.title"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*                         {
                                                    text: "",
                                                    title: t("common.view"),
                                                    color: "violet",
                                                    onClick: () => { this.props.history.push(`/holiday/view/${row.id}`) },
                                                    iconClass: "search",
                                                    size: "mini"
                                                }, */
                        /*          {
                                     text: "",
                                     title: t("common.edit"),
                                     color: "blue",
                                     onClick: () => { this.onOpenLocation("UPDATE", row, i, "source.locations") },
                                     iconClass: "edit",
                                     size: "mini"
                                 }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDeleteLocation(i, "source.locations") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    getColumnAreaLocationConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("location.title"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*                         {
                                                    text: "",
                                                    title: t("common.view"),
                                                    color: "violet",
                                                    onClick: () => { this.props.history.push(`/holiday/view/${row.id}`) },
                                                    iconClass: "search",
                                                    size: "mini"
                                                }, */
                        /*        {
                                   text: "",
                                   title: t("common.edit"),
                                   color: "blue",
                                   onClick: () => { this.onOpenLocation("UPDATE", row, i, "source.areaLocations") },
                                   iconClass: "edit",
                                   size: "mini"
                               }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDeleteLocation(i, "source.areaLocations") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    render() {
        try {
            const { t, model, errors, optionsEventList, optionsTreeLocation, optionsResourceGroupList, optionsVisitorGroupList, optionsResources, optionsResourceType } = this.props
            const { openDepartmentModal, isOpenModalResources, resourceModel, isOpenLocationModal, locationModel, resourceFieldName, locationFieldName } = this.state

            let eventValidate = this.getErrorInput("source.events", errors)
                , resourceGroupValidate = this.getErrorInput("source.resourceGroupIds", errors)
                , visitorGroupValidate = this.getErrorInput("source.visitorGroupIds", errors)
                , departmentValidate = this.getErrorInput("source.departments", errors)
                , resourceValidate = this.getErrorInput("source.resources", errors)
                , locationValidate = this.getErrorInput("source.locations", errors)
                , areaResourceValidate = this.getErrorInput("source.areaResources", errors)
                , areaLocationValidate = this.getErrorInput("source.areaLocations", errors)

            let departmentList = null
                , resourcesList = null
                , locationList = null
                , areaResourcesList = null
                , areaLocationsList = null

            if (model) {
                departmentList = !!model.source.departments ? model.source.departments : []
                resourcesList = !!model.source.resources ? model.source.resources : []
                locationList = !!model.source.locations ? model.source.locations : []
                areaResourcesList = !!model.source.areaResources ? model.source.areaResources : []
                areaLocationsList = !!model.source.areaLocations ? model.source.areaLocations : []
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {openDepartmentModal &&
                        <ModalDepartmentECR
                            onSubmit={(departmentInfo) => this.onSubmitModelDepartment(departmentInfo, departmentList)}
                            onClose={this.onCloseModelDepartment}
                        />
                    }
                    {
                        isOpenModalResources &&
                        <ModalResourcesECR
                            model={resourceModel}
                            optionsResourceType={optionsResourceType}
                            optionsResources={optionsResources}
                            onGetDataList={this.props.onGetDataList}
                            onSubmit={(resourceInfo) => this.onSubmitModelResource(resourceInfo)}
                            onClose={this.onCloseModalResource}
                            resourceFieldName={resourceFieldName}
                        />
                    }
                    {isOpenLocationModal &&
                        <ModalLocationECR
                            model={locationModel}
                            onSubmit={(locationInfo) => { this.onSubmitModalLocation(locationInfo) }}
                            onClose={this.onCloseLocation}
                            optionsTreeLocation={optionsTreeLocation}
                            locationFieldName={locationFieldName}

                        />}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('eventControlRule.event')} />
                                    <MultipleCheckboxList
                                        name="source.events"
                                        optionsDataList={optionsEventList}
                                        value={model.source.events}
                                        onChange={this.handleChange}
                                        error={eventValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('resourceGroup.title')} />
                                    <MultipleCheckboxList
                                        name="source.resourceGroupIds"
                                        optionsDataList={optionsResourceGroupList}
                                        value={model.source.resourceGroupIds}
                                        onChange={this.handleChange}
                                        error={resourceGroupValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitorGroup.title')} />
                                    <MultipleCheckboxList
                                        name="source.visitorGroupIds"
                                        optionsDataList={optionsVisitorGroupList}
                                        value={model.source.visitorGroupIds}
                                        onChange={this.handleChange}
                                        error={visitorGroupValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('department.title')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.add'),
                                            color: "green",
                                            onClick: () => { this.onOpenModelDepartment("CREATE", null, null) },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnDepartmentConfig()}
                                        datas={departmentList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (departmentValidate.isError &&
                                    departmentValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {departmentValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('eventControlRule.resource')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.add'),
                                            color: "green",
                                            onClick: () => { this.onOpenModalResource("CREATE", null, null, "source.resources") },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnResourceConfig()}
                                        datas={resourcesList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (resourceValidate.isError &&
                                    resourceValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {resourceValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('location.title')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => { this.onOpenLocation("CREATE", null, null, "source.locations") },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnLocationConfig()}
                                        datas={locationList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (locationValidate.isError &&
                                    locationValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {locationValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('eventControlRule.areaResource')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.add'),
                                            color: "green",
                                            onClick: () => { this.onOpenModalResource("CREATE", null, null, "source.areaResources") },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnAreaResourceConfig()}
                                        datas={areaResourcesList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (areaResourceValidate.isError &&
                                    areaResourceValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {areaResourceValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                            <Grid.Row>
                                <Grid.Column width={8} verticalAlign="bottom">
                                    <LabelInput text={t('eventControlRule.areaLocation')} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => { this.onOpenLocation("CREATE", null, null, "source.areaLocations") },
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnAreaLocationConfig()}
                                        datas={areaLocationsList}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (areaLocationValidate.isError &&
                                    areaLocationValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {areaLocationValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        }
        catch (error) {
            console.log(error)
            return this.renderErrorComponent(error)
        }
    }
}

EventInformationForm.defaultProps = {
    model: new EventControlRuleInfoModel(),
    errors: [],
    optionsSensorRuleType: [],
    sensorList: [],
}

export default (translate(EventInformationForm))