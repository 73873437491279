
import mongoose from 'mongoose'
import moment from 'moment'
import { setBaseSchema } from '../../../core/schema/Schema'
import TimeSlotSchema from '../../schemas/TimeSlotSchema'

const Schema = mongoose.Schema

const StartDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const EndDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const RecurrenceSchema = new Schema({
	type: { type: String, required: [true, "validate.require"], default: "" },
	weeklyEveryDayOfWeek: [{ type: String }],
	startDate: { type: StartDateSchema, default: {} },
	endDate: { type: EndDateSchema, default: {} },
}, { _id: false })

const MotionDataSchema = new Schema({
	timeSlots: [{ type: TimeSlotSchema }],
	delayTime: { type: Number, required: [true, "validate.require"], default: 30 },
	holidayType: { type: String, required: [true, "validate.require"] },
	recurrence: { type: RecurrenceSchema, default: {} },
	includeDays: [{ type: Date }],
	excludeDays: [{ type: Date }],
}, { _id: false })

setBaseSchema(MotionDataSchema)

MotionDataSchema.path("timeSlots").validate(async function (timeSlots) {
	return timeSlots.length > 0
}, "validate.require")

MotionDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			timeSlots: this.timeSlots.map(slot => {
				return {
					startTime: slot.startTime,
					endTime: slot.endTime,
				}
			}),
			delayTime: this.delayTime,
			holidayType: this.holidayType,
			includeDays: this.includeDays,
			excludeDays: this.excludeDays
		}
		if (!!this.recurrence && !!this.recurrence.type) {
			result.recurrence = this.recurrence
		}
		return result
	} catch (error) {
		return null
	}
}

const MotionDataModel = mongoose.model("sensor_rule_condition_motion", MotionDataSchema, "sensor_rule_condition_motion")

export default MotionDataModel
