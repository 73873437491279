
import moment from 'moment'
import mongoose from 'mongoose'
import { dateTimeFormat } from '../../constant'
import { setBaseSchema } from '../core/schema/Schema'
import { reportType } from '../../constant'

const Schema = mongoose.Schema

const ReportSchema = new Schema({
	type: { type: String, required: [true, "validate.require"], default: "" },
	date: { type: Date, default: moment() },
	startDate: { type: Date, default: moment() },
	endDate: { type: Date, default: moment() },
}, { _id: false })

setBaseSchema(ReportSchema, "report")

ReportSchema.path("date").validate(async function (date) {
	if (this.type === reportType.AIR_QUALITY_DAILY) {
		return !!date
	} else {
		return true
	}
}, "validate.require")

ReportSchema.path("endDate").validate(async function (endDate) {
	if(this.type === reportType.AIR_QUALITY_DAILY) {
		return true
	} else {
		return moment(this.startDate).isSameOrBefore(moment(endDate), "d")
	}
}, "validate.endMustBeGreaterOrEqualStart")

ReportSchema.methods.serializeAPI = function () {
	try {
		let result = { }

		if(this.type === reportType.AIR_QUALITY_DAILY) {
			result = {
				date: moment(this.date).startOf("d").format(dateTimeFormat.apiFullDate)
			}
		} else {
			result = {
				startDate: moment(this.startDate).startOf("d").format(dateTimeFormat.apiFullDate),
				endDate: moment(this.endDate).endOf("d").format(dateTimeFormat.apiFullDate)
			}
		}

		return result
	} catch (error) {
		return null
	}
}

const ReportModel = mongoose.model("report", ReportSchema, "report")

export default ReportModel