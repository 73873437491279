import React from 'react'
import Routes from '../routes/index'
import '../scss/index.scss'

class App extends React.Component {

    render() {
        return (
            <Routes />
        )
    }
}

export default (App)