
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'
import utils from '../../core/utility/utils'

const Schema = mongoose.Schema

const SMTPDataSchema = new Schema({
	host: { type: String, required: [true, "validate.require"], default: "" },
	port: { type: Number, required: [true, "validate.require"], default: "" },
	enablecredential: { type: Boolean, default: false },
	username: { type: String, required: [true, "validate.require"], default: "" },
	password: { type: String, default: "" },
}, { _id: false })

setBaseSchema(SMTPDataSchema, "system_integration_smtp")

SMTPDataSchema.path("password").validate(async function (password) {
	if (this.enablecredential === true) {
		return !!this.password
	}
	return true
}, "validate.require")

SMTPDataSchema.methods.setData = function (data) {
	try {
		if (!Array.isArray(data) && typeof data === "object") {
			for (const key in data) {
				if (key in this) {
					if (key === "password") {
						this[key] = utils.decode(data[key])
					} else {
						this[key] = data[key]
					}
				}
			}
		}
	} catch (error) {

	}
}

SMTPDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			host: this.host,
			port: this.port,
			enablecredential: this.enablecredential,
			username: this.username,
		}
		if (!!this.password) {
			result.password = utils.encode(this.password)
		}
		return result
	} catch (error) {
		console.log(error)
		return null
	}
}

const SMTPDataModel = mongoose.model("system_integration_smtp", SMTPDataSchema, "system_integration_smtp")

export default SMTPDataModel