import React from 'react'
import { translate } from 'react-switch-lang'
import { serviceCode } from '../../../constant'
import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import utils from '../../core/utility/utils'
import { Form, Grid } from 'semantic-ui-react'
import LabelInput from '../common/LabelInput'

class ModalIntegrationLogin extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true

        this.state.integrationType = null

        if (props.integrationType) {
            this.state.integrationType = props.integrationType
        }

        if (props.id) {
            this.state.model = {
                systemId: props.id,
                username: "",
                password: ""
            }
        } else {
            this.state.model = null
        }


    }

    isDisabledSubmit = (model) => {
        let disableButton = false

        if (model.username === "") {
            disableButton = true
        } else if (model.password === "") {
            disableButton = true
        }

        return disableButton

    }

    onSubmit = () => {
        const { model } = this.state

        let body = {
            systemId: model.systemId,
            username: model.username,
            password: utils.encode(model.password)
        }
        this.props.onSubmit(body)
    }

    render() {
        try {
            const { t, onClose } = this.props
            const { model, integrationType } = this.state

            let wording = ""

            if (integrationType) {
                if (integrationType === serviceCode.ACTIVE_DIRECTORY) {
                    wording = t("account.userADIntegration")
                } else if (integrationType === serviceCode.EXCHANGE) {
                    wording = t("account.userExchangeIntegration")
                }
            }

            let buttonModal = [
                new ButtonModel({
                    text: t("common.integration"),
                    color: "green",
                    onClick: this.onSubmit,
                    disabled: this.isDisabledSubmit(model)
                }),
                new ButtonModel({
                    text: t("common.close"),
                    color: "grey",
                    onClick: onClose
                })
            ]

            return (
                <React.Fragment>
                    {this.getModalContent(this.getInfoModalOption({
                        open: true,
                        size: "tiny",
                        headerText: wording,
                        onClose: () => { },
                        content: <div>
                            {
                                model &&
                                <Form onKeyDown={this.onKeyDownSubmitForm}>
                                    <Grid className="grid-half-padding">
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("auth.username")} />
                                                <Form.Input
                                                    name="username"
                                                    value={model.username}
                                                    onChange={this.handleChange}
                                                    icon='users'
                                                    iconPosition='left'
                                                    placeholder={t("auth.enterUsername")}
                                                    fluid
                                                //error={usernameValidate.isError ? usernameValidate.content : null}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("auth.password")} />
                                                <Form.Input
                                                    name="password"
                                                    value={model.password}
                                                    onChange={this.handleChange}
                                                    icon='key'
                                                    iconPosition='left'
                                                    placeholder={t("auth.enterPassword")}
                                                    fluid
                                                    type="password"
                                                //error={passwordValidate.isError ? passwordValidate.content : null}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            }
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalIntegrationLogin.defaultProps = {
    model: null,
    modalStatus: "CREATE",
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action.")
}

export default translate(ModalIntegrationLogin)