
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ViewLoginBypass from '../../views/auth/ViewLoginBypass'
import AuthManager from '../../business/AuthManager'
import { cookiesKey } from '../../../constant'

class LoginBypassController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = true
		this.state.loginSetting = []

		this.bAuth = new AuthManager(this)
	}

	componentDidMount() {
		let bypass = this.bCookie.getData(cookiesKey.BYPASS_TOKEN)
		if (!!bypass) {
			this.bCookie.removeData(cookiesKey.BYPASS_TOKEN)
			this.verifyBypass(bypass)
		} else {
			this.props.history.replace('/')
		}
	}


	verifyBypass = (code) => {
		try {
			this.setState({
				isLoading: true
			}, async () => {
				let res = await this.bAuth.verifyBypass(code)
				let tempState = {
					isLoading: false,
				},
					action = () => { }
				if (!!res && res.result === true && !!res.data) {
					action = () => {
						let isRemember = this.bCookie.getData(cookiesKey.IS_REMEMBER)
						this.bAuth.afterVerify(res.data, typeof isRemember === "boolean" ? isRemember : false)
					}
				} else {
					action = () => {
						this.bCookie.removeAll()
						this.props.history.replace('/login')
					}
				}
	
				this.setState(tempState, action)
			
			})
		} catch (error) {
			this.setState({
				isLoading: false
			}, () => {
				// this.bCookie.removeAll()
				// this.props.history.replace('/login')
			})
		}
	}

	render() {
		try {
			const { isLoading } = this.state
			return (
				<React.Fragment>
					{super.render()}
					{/* {(isLoading && this.getLoadingPage())} */}
					<ViewLoginBypass
						isLoading={isLoading}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({

	})
)


export default enhance(LoginBypassController)