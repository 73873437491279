
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import { notificationService } from '../../constant'
import LineDataModel from '../models/notificationData/LineDataModel'
import SendGridDataModel from '../models/notificationData/SendGridDataModel'
import PagingDataModel from '../core/model/PagingDataModel'
import NotificationModel from '../models/NotificationModel'

export default class NotificationManager extends MyManager {
	async createNotification(model, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/notification`,
				data: model
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateNotification(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/notification/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteNotification(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/notification/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async testNotification(body) {
		try {
			const option = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/notification/test`,
				data: body,
				isHandleError: false
			}
			let res = await this.requestAction(option)
			return res
		} catch (error) {
			console.log(error)
			//this.setDefaultModalError(error)
			return false
		}
	}

	getModelNotificationData(type) {
		try {
			let model = null
			switch (type) {
				case notificationService.LINE_BOT:
					model = LineDataModel
					break;
				case notificationService.SEND_GRID:
					model = SendGridDataModel
					break;
				default:
					break;
			}
			return model
		} catch (error) {
			return null
		}
	}

	async getNotificationListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/notification/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null

			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new NotificationModel(d)
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}
}