
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid, Step } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import StepList from '../../core/business/step/StepList'
import SensorInformationStep from '../../business/sensorRuleStep/SensorInformationStep'
import ConditionStep from '../../business/sensorRuleStep/ConditionStep'
import ActionInformationStep from '../../business/sensorRuleStep/ActionInformationStep'
import { sensorRuleStep } from '../../../constant'
import SensorRuleModel from '../../models/SensorRuleModel'

class ViewSensorRuleCreate extends MyPage {
    constructor(props) {
        super(props)
        this.stepList = new StepList(this)

        this.state.currentStep = null

        this.state.isLoading = false
        this.state.registerFail = null
        this.state.modalCustom = null
        this.initStep()
    }

    initStep() {
        this.stepList.addStep(new SensorInformationStep(this.stepList))
        this.stepList.addStep(new ConditionStep(this.stepList))
        this.stepList.addStep(new ActionInformationStep(this.stepList))
        this.state.currentStep = this.stepList.root
    }

    changeCurrentStep = (step) => {
        this.setState({ currentStep: step })
    }

    onClickHeaderStep = (step) => {
        const { currentStep } = this.state
        let dataStepList = this.stepList.data
        let activeKey = null
        if (!!currentStep) {
            activeKey = currentStep.key
        }
        if (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key]) {
            this.stepList.gotoStep(step.id)
        }
    }

    submit() {
        let data = this.stepList.data
        if (!!data) {
            let sensorInformationModel = data[sensorRuleStep.SENSOR_INFORMATION]
                , conditionModel = data[sensorRuleStep.CONDITION]
                , actionInformationModel = data[sensorRuleStep.ACTION_INFORMATION]
   
            let model = new SensorRuleModel({
                type: sensorInformationModel.type,
                code: sensorInformationModel.code,
                name: sensorInformationModel.name,
                description: sensorInformationModel.description,
                sourceSensorIds: sensorInformationModel.sourceSensorIds,

                conditionData: conditionModel,

                actions: actionInformationModel,
            })

            this.props.onSubmit(model)
        }
    }

    back() {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsSensorRuleType, sensorList, optionsConditionHolidayType, locationList, optionsNotification,
                optionsSensorRuleActionType, sensorRuleActionResult, optionsSensorRuleLocationType, sensorRuleResourceList,
                optionsDataType, optionsSensorType, optionsRecurrenceTypeList, optionsDayofWeekList } = this.props
            const { currentStep, isLoading, modalCustom } = this.state
            let buttons = []

            let activeKey = null
            if (!!currentStep) {
                activeKey = currentStep.key
            }
            let dataConfig = {
                optionsSensorRuleType: optionsSensorRuleType,
                sensorList: sensorList,
                optionsConditionHolidayType: optionsConditionHolidayType,
                locationList: locationList,
                optionsNotification: optionsNotification,
                optionsSensorRuleActionType: optionsSensorRuleActionType,
                sensorRuleActionResult: sensorRuleActionResult,
                optionsSensorRuleLocationType: optionsSensorRuleLocationType,
                sensorRuleResourceList: sensorRuleResourceList,
                optionsDataType: optionsDataType,
                optionsSensorType: optionsSensorType,
                optionsRecurrenceTypeList: optionsRecurrenceTypeList,
                optionsDayofWeekList: optionsDayofWeekList,
            }

            let dataStepList = this.stepList.data
                , stepList = this.stepList.getNavigator()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('sensorRule.createSensorRule')} buttons={buttons} >
                        <div className="module viewSensorRuleCreate">
                            <Container text>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Step.Group fluid ordered className="step-group-custom">
                                                {
                                                    Array.isArray(stepList) &&
                                                    stepList.map((step, i) => {
                                                        let isCompleted = (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key])
                                                            , isActive = (activeKey === step.key)
                                                            , classNameStep = "item-step"
                                                        if (!isCompleted || isActive) {
                                                            classNameStep += "step-disabled"
                                                        }
                                                        return <Step key={step.key} active={isActive} completed={isCompleted} onClick={() => this.onClickHeaderStep(step)} className={classNameStep}>
                                                            <Step.Content>
                                                                <Step.Title>{t(step.title)}</Step.Title>
                                                            </Step.Content>
                                                        </Step>
                                                    })
                                                }
                                            </Step.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {currentStep && currentStep.render(dataConfig)}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewSensorRuleCreate.defaultProps = {
    optionsSensorRuleType: [],
    optionsSensorRuleLocationType: [],
    sensorRuleResourceList: [],
    optionsConditionHolidayType: [],
    optionsSensorRuleActionType: [],
    optionsNotification: [],
    optionsDataType: [],
    optionsSensorType: [],
    sensorRuleActionResult: null,
    sensorList: [],
    locationList: [],
    optionsRecurrenceTypeList: [],
    optionsDayofWeekList: [],
    onSubmit: () => console.error("Please provide Create Sensor Rule action.")
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewSensorRuleCreate)