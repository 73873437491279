
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import DoorManager from '../../business/DoorManager'
import DoorList from '../../views/door/DoorList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class DoorController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bDoor = new DoorManager(this)
	}

	getPropsResetStatus() {
		return ["doorList", "locationList"]
	}

	onDeleteDoor = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDoor.deleteDoor(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableDoor = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDoor.enableDoorStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableDoor = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDoor.disableDoorStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onSyncDoor = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDoor.syncDoor((res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(tempState, action)
			})
		})
	}

	onImportDoorFromPlatform = async (ids, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDoor.importDoor({ doorIds: ids }, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getDoorListPaging = (query) => {
		return this.bDoor.getDoorListPaging(query)
	}

	render() {
		try {
			const { t, locationList } = this.props
			const { isLoadingComponent } = this.state

			const optionLocation = locationList.getDatasOption()
			let optionLocationList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionLocationList = optionLocationList.concat(optionLocation)
			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<DoorList
						optionLocationList={optionLocationList}
						onDeleteDoor={this.onDeleteDoor}
						onChangeEnableDoor={this.onChangeEnableDoor}
						onChangeDisableDoor={this.onChangeDisableDoor}
						onSyncDoor={this.onSyncDoor}
						onImportDoorFromPlatform={this.onImportDoorFromPlatform}
						onGetDataPaging={this.getDoorListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			locationList: state.locationList
		}),
		(dispatch) => ({
			getLocationList() {
				dispatch(getLocationList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
		}
	}),
)


export default enhance(DoorController)