
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetUserList = () => {
	return {
		type: type.RESET_USER_LIST
	}
}

const getUserInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/user/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_INFO_REQUEST,
				{
					type: type.GET_USER_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_INFO_FAILURE
			]
		}
	}
}

const getUserList = (query = "") => {
	let url = `${CORE_API_ENDPOINT}/user${query}`

	return {
		[CALL_API]: {
			endpoint: url,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_LIST_REQUEST,
				{
					type: type.GET_USER_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_LIST_FAILURE
			]
		}
	}
}

const getUserStatusList = () => {
	return {
		
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/user/status`,
			method: `GET`,
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_STATUS_LIST_REQUEST,
				{
					type: type.GET_USER_STATUS_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_STATUS_LIST_FAILURE
			]
		}
	}
}

const getUserIntegrationList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/user/integration/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_INTEGRATION_LIST_REQUEST,
				{
					type: type.GET_USER_INTEGRATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_INTEGRATION_LIST_FAILURE
			]
		}
	}
}

const getUserADList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/user/ad/user/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_ACTIVE_DIRECTORY_LIST_REQUEST,
				{
					type: type.GET_USER_ACTIVE_DIRECTORY_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_ACTIVE_DIRECTORY_LIST_FAILURE
			]
		}
	}
}

const getUserExchangeList = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/user/exchange/user/list/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_USER_EXCHANGE_LIST_REQUEST,
				{
					type: type.GET_USER_EXCHANGE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_USER_EXCHANGE_LIST_FAILURE
			]
		}
	}
}

export {
	getUserInfo,
	getUserList,
	resetUserList,
	getUserStatusList,
	getUserIntegrationList,
	getUserADList,
	getUserExchangeList
}