
import moment from 'moment'
import { searchQueryType } from '../../../constant'
import ItemTreeSelectModel from '../model/ItemTreeSelectModel'
import base64 from 'base-64'

const utils = {
	arrayChunk(datas, size) {
		try {
			var result = []
			if (!datas || !Array.isArray(datas)) {
				return []
			}

			var token = {}
				, key = 0
			for (let i = 0; i < datas.length; i++) {
				let data = datas[i]

				if (token[key.toString()] === undefined || !Array.isArray(token[key.toString()])) {
					token[key.toString()] = []
				}

				token[key.toString()].push(data)

				if (token[key.toString()].length >= size) {
					key++
				}
			}

			for (var propertyName in token) {
				result.push(token[propertyName])
			}

			return result

		} catch (error) {
			return []
		}
	},
	makeGroupedObject(datas, key) {
		try {
			if (Array.isArray(datas)) {
				var result = {}
				for (let data of datas) {
					let keyValue = data[key]
					if (keyValue === null) {
						keyValue = "Null"
					} else if (keyValue === undefined) {
						keyValue = "Undefined"
					}
					if (!Array.isArray(result[keyValue])) {
						result[keyValue] = []
					}

					result[keyValue].push(data)
				}

				return result
			}

			return {}
		} catch (error) {
			return {}
		}
	},
	makeGroupedArrayMultiLevel(datas, keys) {
		var result = datas
		keys.forEach(key => {
			if (Array.isArray(result)) {
				result = this.makeGroupedObject(result, key)
			} else {
				var token = {}
				for (let myKey in result) {
					token[myKey] = this.makeGroupedArrayMultiLevel(result[myKey], keys.slice(1, keys.length))
				}

				result = token
			}
		})

		return result
	},
	makeGroupedArrayForTreeSelect(datas, primaryKey, foreignKey, parent = null, textKey, valueKey) {
		try {
			if (!primaryKey || !foreignKey || !textKey || !valueKey) {
				return []
			}
			let parents = []
				, childrens = []

			if (Array.isArray(datas) && datas.length > 0) {
				datas.forEach(data => {
					if (!!data) {
						let model = new ItemTreeSelectModel()
						model.text = data[textKey]
						model.value = data[valueKey]
						model.code = data["code"]
						model.externalProps = data
						if (!!data.iconClass) {
							model.iconClass = data.iconClass
						}
						if (!!data.iconColor) {
							model.iconColor = data.iconColor
						}
						if (!!data.imageURL) {
							model.imageURL = data.imageURL
						}
						if (!!parent && !!parent.externalProps && parent.externalProps[primaryKey] === data[foreignKey]) {
							model.parent = parent
						}
						if ((parent === null && !data[foreignKey]) || !!model.parent) {
							parents.push(model)
						} else {
							childrens.push(data)
						}
					}
				});
			}
			if (childrens.length === 0) {
				return parents
			} else {
				parents = parents.map(p => {
					p.children = this.makeGroupedArrayForTreeSelect(childrens, primaryKey, foreignKey, p, textKey, valueKey)
					return p
				})
				return parents
			}
		} catch (error) {
			console.error(error)
			return []
		}
	},
	arrayUnique(datas) {
		if (!Array.isArray(datas)) {
			return []
		}

		return datas.filter((value, index, self) => {
			return self.indexOf(value) === index;
		})
	},
	arrayDiff(arrayA, arrayB) {
		if (!Array.isArray(arrayA)) {
			return []
		}
		if (!Array.isArray(arrayB)) {
			return arrayA
		}

		return arrayA.filter(a => {
			let b = arrayB.find(b => b === a)
			return !b
		})
	},
	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	},
	findMinDate(datas) {
		if (!Array.isArray(datas) || datas.length === 0) {
			return null
		}

		let t = datas.sort((a, b) => {
			if (moment(a).isBefore(moment(b))) return 1
			else return -1
		})

		return t[0]
	},
	findMaxDate(datas) {
		if (!Array.isArray(datas) || datas.length === 0) {
			return null
		}

		let t = datas.sort((a, b) => {
			if (moment(a).isBefore(moment(b))) return -1
			else return 1
		})

		return t[0]
	},
	isObjectEmpty(obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}

		return JSON.stringify(obj) === JSON.stringify({});
	},
	getDateTimeFromStep(dateTime, step) {
		try {
			let minute = moment(dateTime).minute()
				, t = minute % step

			return moment(dateTime).minute(minute - t).startOf("m")
		} catch (error) {
			return dateTime
		}
	},
	// isEmail(email = "") {
	// 	try {
	// 		if (email === "") {
	// 			return false
	// 		}
	// 		let reg = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
	// 		return reg.test(email)
	// 	} catch (error) {
	// 		return false
	// 	}
	// },
	getOptionsTime(step) {
		try {
			let options = []
			if (!isNaN(step) && step > 0) {
				let startTime = moment().startOf("day")
					, endTime = moment().endOf("day")
				options.push({ key: '0000', value: '00:00', text: '00:00' })
				for (let index = 1; index < 1440; index++) {
					let slot = moment(startTime).add(step * index, 'm')
					if (endTime.isBefore(slot)) {
						options.push({ key: '2359', value: '23:59', text: '23:59' })
						break
					} else {
						options.push({ key: slot.format('HHmm'), value: slot.format('HH:mm'), text: slot.format('HH:mm') })
					}
				}
			}
			return options
		} catch (error) {
			return []
		}
	},
	getOptionsDuration(step) {
		try {
			let options = []
			if (!isNaN(step) && step > 0) {
				let startTime = moment().startOf("day")
					, endTime = moment().endOf("day")
				// options.push({ key: step, value: step, text: step })
				for (let index = 1; index < 1440; index++) {
					let totalStep = step * index
					let slot = moment(startTime).add(totalStep, 'm')
					if (endTime.isBefore(slot)) {
						totalStep = step * (index - 1)
						slot = moment(startTime).add(totalStep, 'm')

						let diff = endTime.diff(slot)
							, duration = moment.duration(diff)
							, mins = Math.trunc(duration.asMinutes())

						totalStep += mins

						options.push({ key: totalStep, value: totalStep, text: totalStep })
						break
					} else {
						options.push({ key: totalStep, value: totalStep, text: totalStep })
					}
				}
			}
			return options
		} catch (error) {
			return []
		}
	},
	makeOptions(datas, key, t) {
		let items = []
		datas.map(d => {
			items.push({ key: d[key], value: d[key], text: d.name })
			return true
		})
		return [{ key: "0", value: "0", text: t('common.pleaseSelect') }].concat(items)
	},
	getQueryFromProps(props, key = "") {
		try {
			let search = null
			if (props && props.location && props.location.search) {
				search = props.location.search
			}
			let querys = ""
			if (search) {
				const params = new URLSearchParams(search)
				const code = params.get(key)
				if (code) {
					querys = code
				}
			}
			return querys
		} catch (error) {
			return ""
		}
	},
	getParamFromProps(props, key = "") {
		try {
			let params = null
			if (!!props && !!props.match && !!props.match.params) {
				params = props.match.params
			}
			let value = ""
			if (!!params) {
				if (!!params[key]) {
					value = params[key]
				}
			}
			return value
		} catch (error) {
			return ""
		}
	},
	getStateLocationFormProps(props, key = "") {
		try {
			let locationState = null
			if (!!props && props.location && props.location.state) {
				locationState = props.location.state
			}
			let data = null
			if (locationState) {
				if (!!key && !!locationState[key]) {
					data = locationState[key]
				}
			}
			return data
		} catch (error) {
			return null
		}
	},
	generateSearchQuery(configs, isNewQuery = true) {
		try {
			let queryString = ""
			if (Array.isArray(configs) && configs.length > 0) {
				configs.forEach(config => {
					if (!!config) {
						const { name, value, queryType } = config
						if (!!name) {
							let setQuery = name
								, isSearch = false
							if (!!queryType && !!searchQueryType[queryType]) {
								setQuery += ("_" + queryType)
							}

							if (value !== null && value !== undefined && value !== "" && value !== []) {
								if (Array.isArray(value)) {
									if (value.length > 0) {
										let filterValue = value.filter(v => v !== null && v !== undefined && v !== "")
										if (filterValue.length > 0) {
											isSearch = true
											setQuery += ("=" + filterValue.join(","))
										}
									}
								} else {
									setQuery += ("=" + value)
									isSearch = true
								}
							}

							if (!!setQuery && isSearch) {
								if (!!queryString) {
									queryString += ("&" + setQuery)
								} else if (isNewQuery) {
									queryString = ("?" + setQuery)
								} else {
									queryString = setQuery
								}
							}
						}
					}
				})
			}
			return queryString
		} catch (error) {
			console.error("Generate Query Search Fail =>", error)
			return ""
		}
	},
	decode(dataStr) {
		try {
			if (typeof dataStr !== "string") {
				throw new Error("Data invalid.")
			}
			let dataBase64 = base64.decode(dataStr)
			return decodeURIComponent(dataBase64)
		} catch (error) {
			return ""
		}
	},
	encode(dataStr) {
		try {
			if (typeof dataStr !== "string") {
				throw new Error("Data invalid.")
			}
			let dataUTF8 = encodeURIComponent(dataStr)
			return base64.encode(dataUTF8)
		} catch (error) {
			return ""
		}
	},
	downloadFile(data, type, name) {
		try {
			if (!data || !type || !name) {
				throw new Error("Download file fail.")
			}
			let blob = null
			try {
				// array buffer to JSON
				var decodedString = String.fromCharCode.apply(null, new Uint8Array(data));
				JSON.parse(decodedString);
			} catch (error) {
				blob = new Blob(
					[data],
					{ type: type }
				)
			}
			if (!blob) {
				throw new Error("Download file fail. [NO BLOB]")
			}
			// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
			const blobUrl = URL.createObjectURL(blob);

			// Create a link element
			const link = document.createElement("a");

			// Set link's href to point to the Blob URL
			link.href = blobUrl;
			link.download = name;

			// Append link to the body
			document.body.appendChild(link);

			// Dispatch click event on the link
			// This is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent('click', {
					bubbles: true,
					cancelable: true,
					view: window
				})
			);

			// Remove link from body
			document.body.removeChild(link);
		} catch (error) {

		}
	}
}

export default utils