import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ActionInformationForm from './ActionInformationForm'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import EventControlRuleActionModel from '../../../models/eventControlRuleStep/EventControlRuleActionModel'
import { eventControlRuleStep } from '../../../../constant'

class ActionInformationView extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = new EventControlRuleActionModel(props.dataStep)
        this.state.isLoading = false
    }

    // onSubmit = () => {
    //     this.onValidate(this.onNext)
    // }

    onBack = () => {
        this.props.onBack(this.state.model)
    }

    onNext = () => {
        this.props.onNext(this.state.model)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    onView = () => {
        const { dataConfig } = this.props
        this.props.history.push(`/eventcontrolrule/view/${dataConfig.editID}?step=${eventControlRuleStep.ACTION_INFORMATION}`)
    }

    render() {
        try {
            const { t, dataConfig } = this.props
            const { model, isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ActionInformationForm
                                    model={model}
                                    handleChange={this.handleChange}
                                    optionsActionTypeList={dataConfig.optionsActionTypeList}
                                    optionsActionStatusList={dataConfig.optionsActionStatusList}
                                    optionsResources={dataConfig.optionsResources}
                                    optionsResourceType={dataConfig.optionsResourceType}
                                    optionsTreeLocation={dataConfig.optionsTreeLocation}
                                    onGetDataList={dataConfig.onGetDataList}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {/*    <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.save"),
                                        color: "green",
                                        disabled: !(Array.isArray(model.actions) && model.actions.length !== 0),
                                        onClick: this.onNext
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.back"),
                                        color: "yellow",
                                        onClick: this.onBack
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.cancel"),
                                        color: "grey",
                                        onClick: () => { this.props.history.push(`/eventcontrolrule`) }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row> */}
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            {
                                dataConfig.stepStatus === "UPDATE" ?
                                    <React.Fragment>
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t('common.view'),
                                                color: "violet",
                                                onClick: () => this.onView()
                                            })}
                                        />
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: t("common.save"),
                                                color: "green",
                                                disabled: !(Array.isArray(model.actions) && model.actions.length !== 0),
                                                onClick: () => { this.onValidate(() => this.props.onSave(model)) }
                                            })}
                                        />
                                    </React.Fragment>
                                    :
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.save"),
                                            color: "green",
                                            disabled: !(Array.isArray(model.actions) && model.actions.length !== 0),
                                            onClick: this.onNext
                                        })}
                                    />
                            }

                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.cancel"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/eventcontrolrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

ActionInformationView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ActionInformationView)