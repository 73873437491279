
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import ProfileCenterDataModel from '../../../models/systemIntegrationData/ProfileCenterDataModel'

class ProfileCenterDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let serverValidate = this.getErrorInput("server", errors)
                , companyValidate = this.getErrorInput("company", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.profileCenter")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.server")} isRequire={true} />
                                <Form.Input
                                    name="server"
                                    value={model.server}
                                    onChange={this.handleChange}
                                    error={serverValidate.isError ? serverValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.company")} isRequire={true} />
                                <Form.Input
                                    name="company"
                                    value={model.company}
                                    onChange={this.handleChange}
                                    error={companyValidate.isError ? companyValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ProfileCenterDataForm.defaultProps = {
    model: new ProfileCenterDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ProfileCenterDataForm)