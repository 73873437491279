
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'
import LabelInput from '../LabelInput'
import MyForm from '../../../core/components/MyForm'
import inputUtils from '../../../core/utility/inputUtils'

class DaliForm extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true
    }

    handleChangeValue = (e, { name, value }) => {
        this.props.onChange(null, { name: this.props.name, value: value })
    }

    render() {
        try {
            const { t, disabled, value } = this.props

            let daliValue = ""

            if (!isNaN(value) && (value >= 0 && value <= 100)) {
                daliValue = value
            }


            return (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("common.brightness")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form.Input
                                    name="inputNum"
                                    onKeyDown={inputUtils.onKeyNumberDali}
                                    value={daliValue}
                                    onChange={this.handleChangeValue}
                                    disabled={disabled}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
                // <React.Fragment>
                //     <Form.Input
                //         name="inputNum"
                //         onKeyDown={inputUtils.onKeyNumber}
                //         value={daliValue}
                //         onChange={this.handleChangeValue}
                //         disabled={disabled}
                //     />
                // </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

DaliForm.defaultProps = {
    name: "",
    type: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate
)

export default enhance(DaliForm)