
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const getSettingSensorInfo = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_SENSOR_INFO_REQUEST,
				{
					type: type.GET_SETTING_SENSOR_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_SENSOR_INFO_FAILURE
			]
		}
	}
}

const getSettingSensorTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting/sensortype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_SENSOR_TYPE_LIST_REQUEST,
				{
					type: type.GET_SETTING_SENSOR_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_SENSOR_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSettingSensorStandardOperationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/setting/standardoperation`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_REQUEST,
				{
					type: type.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_FAILURE
			]
		}
	}
}



export {
	getSettingSensorInfo,
	getSettingSensorTypeList,
	getSettingSensorStandardOperationList
}