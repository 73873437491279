
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class ScheduleControlOptionsResource extends ActionResourceObject {
	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					let displayText = d

					switch (d) {
						case "INCLUDE_HOLIDAY":
							displayText = "Include Holiday"
							break;
						case "EXCLUDE_HOLIDAY":
							displayText = "Exclude Holiday"
							break;
						case "HOLIDAY_ONLY":
							displayText = "Holiday Only"
							break;
						default:
							break;
					}

					return {
						key: d,
						value: d,
						text: displayText
					}
				})
			} else {
				let displayText = data

				switch (data) {
					case "INCLUDE_HOLIDAY":
						displayText = "Include Holiday"
						break;
					case "EXCLUDE_HOLIDAY":
						displayText = "Exclude Holiday"
						break;
					case "HOLIDAY_ONLY":
						displayText = "Holiday Only"
						break;
					default:
						break;
				}

				result = {
					key: data,
					value: data,
					text: displayText
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}