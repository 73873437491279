
import mongoose from 'mongoose'
import moment from 'moment'
import { setBaseSchema } from '../core/schema/Schema'
import TimeSlotSchema from './schemas/TimeSlotSchema'

const Schema = mongoose.Schema

const StartDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const EndDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const RecurrenceSchema = new Schema({
	type: { type: String, required: [true, "validate.require"], default: "" },
	weeklyEveryDayOfWeek: [{ type: String }],
	startDate: { type: StartDateSchema, default: {} },
	endDate: { type: EndDateSchema, default: {} },
}, { _id: false })

const conditionSchema = new Schema({
	timeSlots: [{ type: TimeSlotSchema }],
	holidayType: { type: String, required: [true, "validate.require"] },
	recurrence: { type: RecurrenceSchema, default: {} },
	includeDays: [{ type: Date }],
	excludeDays: [{ type: Date }],
}, { _id: false })

const sourceSchema = new Schema({
	events: [{ type: String }],
	departmentIds: [{ type: String }],
	departments: { type: Object },
	resourceGroupIds: [{ type: String }],
	resourceGroups: { type: Object },
	visitorGroupIds: [{ type: String }],
	visitorGroups: { type: Object },
	resourceIds: [{ type: String }],
	resources: { type: Object },
	locationIds: [{ type: String }],
	locations: { type: Object },
	areaResourceIds: [{ type: String }],
	areaResources: { type: Object },
	areaLocationIds: [{ type: String }],
	areaLocations: { type: Object },
}, { _id: false })

const actionDatasSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	type: { type: String, default: "" },
	//ModelCode: { type: String, default: "" },
}, { _id: false })

const actionSchema = new Schema({
	type: { type: String, default: "" },
	status: { type: String, default: "OFF" },
	datas: [{ type: actionDatasSchema }],
}, { _id: false })

const EventControlRuleSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },
	source: { type: sourceSchema, default: {} },
	condition: { type: conditionSchema, default: {} },
	actions: [{ type: actionSchema }],
	isDisable: { type: Boolean, default: false },
}, { _id: false })

setBaseSchema(EventControlRuleSchema, "06")


EventControlRuleSchema.methods.serializeAPI = function () {
	try {
		let sourceResult = {
			events: (Array.isArray(this.source.events) && this.source.events.length > 0) ? this.source.events : [],
			departmentIds: (Array.isArray(this.source.departments) && this.source.departments.length > 0) ?
				this.source.departments.map(data => data.id) : [],
			resourceGroupIds: (Array.isArray(this.source.resourceGroupIds) && this.source.resourceGroupIds.length > 0) ? this.source.resourceGroupIds : [],
			visitorGroupIds: (Array.isArray(this.source.visitorGroupIds) && this.source.visitorGroupIds.length > 0) ? this.source.visitorGroupIds : [],
			resourceIds: (Array.isArray(this.source.resources) && this.source.resources.length > 0) ? this.source.resources.map(data => data.id) : [],
			locationIds: (Array.isArray(this.source.locations) && this.source.locations.length > 0) ? this.source.locations.map(data => data.id) : [],
			areaResourceIds: (Array.isArray(this.source.areaResources) && this.source.areaResources.length > 0) ? this.source.areaResources.map(data => data.id) : [],
			areaLocationIds: (Array.isArray(this.source.areaLocations) && this.source.areaLocations.length > 0) ? this.source.areaLocations.map(data => data.id) : [],
		}

		let conditionResult = {
			timeSlots: Array.isArray(this.condition.timeSlots) && this.condition.timeSlots.length > 0 ?
				this.condition.timeSlots.map(slot => {
					return {
						startTime: slot.startTime,
						endTime: slot.endTime,
					}
				}) : [],
			holidayType: this.condition.holidayType,
			includeDays: this.condition.includeDays,
			excludeDays: this.condition.excludeDays
		}

		if (!!this.condition.recurrence && !!this.condition.recurrence.type) {
			conditionResult.recurrence = this.condition.recurrence
		}

		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
			source: sourceResult,
			condition: conditionResult,
			actions: this.actions
		}

		return result

	} catch (error) {
		return null
	}
}

const EventControlRuleModel = mongoose.model("eventControlRule", EventControlRuleSchema, "eventControlRule")

export default EventControlRuleModel