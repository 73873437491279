
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewAdmin from '../../views/admin/ViewAdmin'
import { getAdminInfo, getAdminIntegrationList } from '../../actions/adminActions'
import utils from '../../core/utility/utils'
import AdminManager from '../../business/AdminManager'

class ViewAdminController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bAdmin = new AdminManager(this)
    }

    getPropsResetStatus() {
        return ["adminInfo"]
    }

    onUnintegtationAdmin = (model, serviceInfo, systemIntegrationId) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.unintegrateAdmin(model.id, serviceInfo, systemIntegrationId, (res) => {

                let tempState = {
                    isLoadingComponent: false,
                }

                let action = {}

                if (serviceInfo === "CONTROL_ADMIN") {
                    action = () => { this.props.history.replace(`/admin`) }
                } else {
                    action = () => { this.props.getAdminInfo(model.id) }
                }

                this.setState(tempState, action)
            })
        })
    }

    onADIntegrationAdmin = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.adminIntegrationAD(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAdminInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onExchangeIntegrationAdmin = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.adminIntegrationExchange(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAdminInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onGoogleIntegrationAdmin = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.adminIntegrationGoogle(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAdminInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onO365IntegrationAdmin = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.adminIntegrationO365(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAdminInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    testSyncPlatformAdmin = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.adminTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { adminInfo, adminIntegrationList } = this.props
            const { isLoadingComponent } = this.props
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const adminInfoData = adminInfo.getData()
            , adminIntegrationDatas = adminIntegrationList.getDatas()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewAdmin
                        model={adminInfoData}
                        adminIntegrationList={adminIntegrationDatas}
                        onUnintegtationAdmin={this.onUnintegtationAdmin}
                        onADIntegrationAdmin={this.onADIntegrationAdmin}
                        onExchangeIntegrationAdmin={this.onExchangeIntegrationAdmin}
                        onGoogleIntegrationAdmin={this.onGoogleIntegrationAdmin}
                        onO365IntegrationAdmin={this.onO365IntegrationAdmin}
                        testSyncPlatformAdmin={this.testSyncPlatformAdmin}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            adminInfo: state.adminInfo,
            adminIntegrationList: state.adminIntegrationList
        }),
        (dispatch, props) => ({
            getAdminInfo(id) {
                dispatch(getAdminInfo(id))
            },
            getAdminIntegrationList(id) {
                dispatch(getAdminIntegrationList(id))
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getAdminInfo(utils.getParamFromProps(this.props, "id"))
            this.props.getAdminIntegrationList(utils.getParamFromProps(this.props, "id"))
        }
    })
)


export default enhance(ViewAdminController)