
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Header, Table } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import LabelInput from '../../common/LabelInput'
import GoogleAuthenDataModel from '../../../models/systemIntegrationData/GoogleAuthenDataModel'

class GoogleAuthenDataView extends MyPage {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' className="form header">
                        {t("systemIntegration.googleAuthen")}
                    </Header>
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.clientId")} /></Table.Cell>
                                <Table.Cell><p>{model.clientId}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.projectId")} /></Table.Cell>
                                <Table.Cell><p>{model.projectId}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.authUri")} /></Table.Cell>
                                <Table.Cell><p>{model.authUri}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.tokenUri")} /></Table.Cell>
                                <Table.Cell><p>{model.tokenUri}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.authProviderX509CertUrl")} /></Table.Cell>
                                <Table.Cell><p>{model.authProviderX509CertUrl}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.clientSecret")} /></Table.Cell>
                                <Table.Cell><p>{model.clientSecret}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={5}><LabelInput text={t("systemIntegration.redirectUri")} /></Table.Cell>
                                <Table.Cell><p>{model.redirectUri}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

GoogleAuthenDataView.defaultProps = {
    model: new GoogleAuthenDataModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(GoogleAuthenDataView)