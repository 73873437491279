
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const GatewaySchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	mac: { type: String, default: "" },
	ip: { type: String, default: "" },
	status: { type: String, default: null },
	isDisable: { type: Boolean, default: false },
	disableBy: { type: String, default: null },
	disableDate: { type: Date, default: null },
	iotConfigs: [{ type: Object, default: null }]
}, { _id: false })

setBaseSchema(GatewaySchema, "06")


/* GatewaySchema.methods.serializeAPI = function () {
	try {
		let result = {
			GatewayCategoryId: this.GatewayCategoryId,
			code: this.code,
			name: this.name,
			amount: this.amount,
			description: this.description
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		return result
		
	} catch (error) {
		return null
	}
} */

const GatewayModel = mongoose.model("gateway", GatewaySchema, "gateway")

export default GatewayModel