
import ActionResourceObject from '../core/redux/ActionResourceObject'
import IntegrationModel from '../models/IntegrationModel'

export default class IntegrationResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new IntegrationModel(d))
			} else {
				result = new IntegrationModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

}