
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import SystemIntegrationModel from '../../models/SystemIntegrationModel'
import { serviceCode } from '../../../constant'
import ButtonModel from '../../core/model/ButtonModel'

import ActiveDirectoryDataModel from '../../models/systemIntegrationData/ActiveDirectoryDataModel'
import EWSDataModel from '../../models/systemIntegrationData/EWSDataModel'
import GoogleAuthenDataModel from '../../models/systemIntegrationData/GoogleAuthenDataModel'
import GoogleServiceAccountDataModel from '../../models/systemIntegrationData/GoogleServiceAccountDataModel'
import O365DataModel from '../../models/systemIntegrationData/O365DataModel'
import ProfileCenterDataModel from '../../models/systemIntegrationData/ProfileCenterDataModel'
import SendGridDataModel from '../../models/systemIntegrationData/SendGridDataModel'
import SMTPDataModel from '../../models/systemIntegrationData/SMTPDataModel'
import LineDataModel from '../../models/systemIntegrationData/LineDataModel'
import SMS8X8DataModel from '../../models/systemIntegrationData/SMS8X8DataModel'

import ActiveDirectoryDataForm from './systemIntegrationDataForm/ActiveDirectoryDataForm'
import ExchangeDataForm from './systemIntegrationDataForm/ExchangeDataForm'
import GoogleAuthenDataForm from './systemIntegrationDataForm/GoogleAuthenDataForm'
import GoogleServiceAccountDataForm from './systemIntegrationDataForm/GoogleServiceAccountDataForm'
import O365DataForm from './systemIntegrationDataForm/O365DataForm'
import ProfileCenterDataForm from './systemIntegrationDataForm/ProfileCenterDataForm'
import SendGridDataForm from './systemIntegrationDataForm/SendGridDataForm'
import SMTPDataForm from './systemIntegrationDataForm/SMTPDataForm'
import LineDataForm from './systemIntegrationDataForm/LineDataForm'
import SMS8X8DataForm from './systemIntegrationDataForm/SMS8X8DataForm'

import ViewButton from '../common/ViewButton'


class SystemIntegrationForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.ComponentForm = null
        this.state.isLoading = false
        this.state.isTest = null
    }

    getDataIntegrationData = (service) => {
        let model = null
            , ComponentForm = null
            , textTest = ""
        switch (service) {
            case serviceCode.ACTIVE_DIRECTORY:
                model = new ActiveDirectoryDataModel()
                ComponentForm = ActiveDirectoryDataForm
                textTest = ""
                break;
            case serviceCode.EXCHANGE:
                model = new EWSDataModel()
                ComponentForm = ExchangeDataForm
                textTest = ""
                break;
            case serviceCode.GOOGLE_AUTHEN:
                model = new GoogleAuthenDataModel()
                ComponentForm = GoogleAuthenDataForm
                textTest = ""
                break;
            case serviceCode.GOOGLE_SERVICE_ACCOUNT:
                model = new GoogleServiceAccountDataModel()
                ComponentForm = GoogleServiceAccountDataForm
                textTest = ""
                break;
            case serviceCode.O365:
                model = new O365DataModel()
                ComponentForm = O365DataForm
                textTest = ""
                break;
            case serviceCode.PROFILE_CENTER:
                model = new ProfileCenterDataModel()
                ComponentForm = ProfileCenterDataForm
                textTest = ""
                break;
            case serviceCode.SEND_GRID:
                model = new SendGridDataModel()
                ComponentForm = SendGridDataForm
                textTest = ""
                break;
            case serviceCode.SMTP:
                model = new SMTPDataModel()
                ComponentForm = SMTPDataForm
                textTest = ""
                break;
            case serviceCode.LINE:
                model = new LineDataModel()
                ComponentForm = LineDataForm
                textTest = ""
                break;
            case serviceCode.SMS_8X8:
                model = new SMS8X8DataModel()
                ComponentForm = SMS8X8DataForm
                textTest = ""
                break;
            // case serviceCode.ZOOM:
            //     model = new ZoomDataModel()
            //     break;
            default:
                break;
        }
        return {
            model,
            ComponentForm,
            textTest
        }
    }

    handleChange = (e, { name, value }) => {
        try {
            if (name === "service") {
                let getModel = this.getDataIntegrationData(value)
                let data = {
                    [name]: value,
                    data: getModel.model
                }
                this.props.handleChangeMultiValue(e, data)
            } else {
                this.props.handleChange(e, { name, value })
            }
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    handleChangeData = (e, { name, value }, masterKey) => {
        try {
            // let dataModel = this.props.model.data
            // if (!!dataModel && name in dataModel) {
            //     dataModel[name] = value
            //     this.props.handleChange(e, { name: "data", value: dataModel })
            // }
            this.props.handleChange(e, { name, value }, masterKey)
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    onTestSystemIntegration = () => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onTestSystemIntegration()
            let tempState = {
                isLoading: false,
                isTest: null
            }
            if (!!res && res.result === true) {
                tempState.isTest = true
            } else {
                tempState.isTest = false
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t, optionsSystemIntegrationService, errors, model } = this.props
            const { isLoading, isTest } = this.state
            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , serviceValidate = this.getErrorInput("service", errors)

            let dataMasterKey = "data"
                , dataErrors = errors.filter(err => err.path.includes(`${dataMasterKey}.`))
                    .map(err => {
                        return {
                            ...err,
                            path: err.path.replace(`${dataMasterKey}.`, "")
                        }
                    })

            let getData = this.getDataIntegrationData(model.service)
            let ComponentForm = getData.ComponentForm
            // , textTest = getData.textTest ? getData.textTest : ""
            //console.log(errors)
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {
                        (isTest !== null && isTest !== undefined) &&
                        this.getModalContent(this.getInfoModalOption({
                            onClose: () => { },
                            headerText: t("systemIntegration.testConnection"),
                            size: "mini",
                            content: <div style={{ textAlign: "center" }}>
                                {
                                    isTest === true ?
                                        <h3 style={{ color: "#0f0" }}>{t("systemIntegration.connectionSuccess")}</h3>
                                        : <h3 style={{ color: "#f00" }}>{t("systemIntegration.connectionFail")}</h3>
                                }
                            </div>,
                            buttons: [
                                new ButtonModel({
                                    text: t('common.close'),
                                    color: "grey",
                                    onClick: () => this.setState({ isTest: null })
                                })
                            ]
                        }))
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("systemIntegration.service")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSystemIntegrationService}
                                        onChange={this.handleChange}
                                        name="service"
                                        value={model.service}
                                        placeholder={t("common.pleaseSelect")}
                                        error={serviceValidate.isError ? serviceValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                        <Grid>
                            {
                                !!ComponentForm &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <ComponentForm
                                            model={model.data}
                                            handleChange={(e, data) => this.handleChangeData(e, data, dataMasterKey)}
                                            errors={dataErrors}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {
                                !!model.service && !!model.data &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: `${t('systemIntegration.testConnection')}`,
                                                color: "blue",
                                                onClick: this.onTestSystemIntegration
                                            })}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SystemIntegrationForm.defaultProps = {
    model: new SystemIntegrationModel(),
    errors: [],
    optionsLocation: [],
    handleChange: () => console.error("Please provide \"Change SystemIntegration\" action."),
    handleChangeMultiValue: () => console.error("Please provide \"Handle Change Multi Value\" action."),
    onTestSystemIntegration: () => {
        console.error("Please provide \"Test\" action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SystemIntegrationForm)