
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import SensorInformationModel from '../../../models/sensorRuleData/sensorRuleStep/SensorInformationModel'
import LabelInput from '../../common/LabelInput'
import MultipleCheckboxList from '../../common/MultipleCheckboxList'
import { sensorRuleType, sensorType } from '../../../../constant'

class SensorInformationForm extends MyForm {

    filterSensorBySensorRuleType = (sensorList, type) => {
        try {
            let sensorFilter = []
            if (!!type && Array.isArray(sensorList) && sensorList.length > 0) {
                if (type === sensorRuleType.DOOR_ALERT) {
                    sensorFilter = sensorList.filter(sensor => sensor.sensorType === sensorType.DOOR || sensor.sensorType === sensorType.BUTTON_PUSH)
                } else if (type === sensorRuleType.MOTION) {
                    sensorFilter = sensorList.filter(sensor => sensor.sensorType === sensorType.MOTION)
                } else if (type === sensorRuleType.COLLECT) {
                    let sensorTypeForCollect = [
                        sensorType.CH2O,
                        sensorType.CO,
                        sensorType.CO2,
                        sensorType.O2,
                        sensorType.PM10,
                        sensorType.PM2_5,
                        sensorType.PRESSURE,
                        sensorType.TEMPERATURE,
                        sensorType.TVOC,
                        sensorType.WATER,
                        sensorType.WATT,
                        sensorType.HUMIDITY,
                    ]
                    sensorFilter = sensorList.filter(sensor => sensorTypeForCollect.includes(sensor.sensorType))
                }
            }
            return sensorFilter
        } catch (error) {
            return []
        }
    }

    render() {
        try {
            const { t, model, errors, optionsSensorRuleType, sensorList } = this.props

            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , typeValidate = this.getErrorInput("type", errors)
                , sourceSensorIdsValidate = this.getErrorInput("sourceSensorIds", errors)

            let sensorFilter = this.filterSensorBySensorRuleType(sensorList, model.type)

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.type')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="type"
                                        value={model.type}
                                        options={optionsSensorRuleType}
                                        onChange={this.handleChange}
                                        error={typeValidate.isError ? typeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('sensorRule.sourceSensor')} isRequire={true} />
                                    <MultipleCheckboxList
                                        name="sourceSensorIds"
                                        optionsDataList={sensorFilter}
                                        value={model.sourceSensorIds}
                                        onChange={this.handleChange}
                                        error={sourceSensorIdsValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        }
        catch (error) {
            console.log(error)
            return this.renderErrorComponent(error)
        }
    }
}

SensorInformationForm.defaultProps = {
    model: new SensorInformationModel(),
    errors: [],
    optionsSensorRuleType: [], 
    sensorList: [],
}

export default (translate(SensorInformationForm))