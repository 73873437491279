
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Header, Grid, Button } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import SettingSensorModel from '../../models/SettingSensorModel'
import ViewButton from '../common/ViewButton'
import ModalSettingSensor from './ModalSettingSensor'
import ViewTable from '../../core/view/common/table/ViewTable'

class SettingSensorView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model

        this.state.isOpenModalAirQuality = false
        this.state.modelAirQuality = null
        this.state.airQualityIndex = null
        this.state.airQualityStatus = ""
    }

    onEdit = () => {
        const { activeIndex } = this.state
        this.props.history.push(`/setting/authorization/update/${activeIndex}`)
    }

    onBack = () => {
        const { activeIndex } = this.state
        this.props.history.push(`/setting/authorization/${activeIndex}`)
    }

    onOpenModalAirQuality = (status, model, index) => {
        if (status === "UPDATE") {
            this.setState({
                isOpenModalAirQuality: true,
                modelAirQuality: model,
                airQualityIndex: index,
                airQualityStatus: status
            })
        } else if (status === "CREATE") {
            this.setState({
                isOpenModalAirQuality: true,
                modelAirQuality: null,
                airQualityIndex: null,
                airQualityStatus: status
            })
        }
    }

    onCloseModalAirQuality = () => {
        this.setState({
            isOpenModalAirQuality: false
        })
    }

    onSubmitModalAirQuality = (airInfo, airQuality) => {
        const { airQualityStatus, airQualityIndex } = this.state
        let currentAirQuality = []

        if (airQualityStatus === "CREATE") {
            currentAirQuality = airQuality.map(t => t)
            currentAirQuality.push(airInfo)
        } else if (airQualityStatus === "UPDATE") {
            // currentAirQuality = airQuality.map(t => t)
            currentAirQuality[airQualityIndex] = airInfo
        }

        let model = this.state.model
        model.sensorInfo.airQualityStandardInfo = currentAirQuality


        this.setState({
            model: model,
            isOpenModalAirQuality: false
        }, () => {
            this.props.onUpdate(model)
        })
    }

    onRemoveModelAirQuality = (index) => {
        const { model } = this.state

        if (Array.isArray(model.sensorInfo.airQualityStandardInfo) && model.sensorInfo.airQualityStandardInfo.length > 0) {
            let currentAirQualitys = model.sensorInfo.airQualityStandardInfo.map(t => t)

            if (index > -1) {
                currentAirQualitys.splice(index, 1)
            }
            this.handleChange(null, { name: "sensorInfo.airQualityStandardInfo", value: currentAirQualitys })
            this.props.onUpdate(model)
        }
    }

    onConfirmDeleteAirQuality = (index) => {
        const { t } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    this.onRemoveModelAirQuality(index)
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("settingSensor.sensorType"),
                selector: (row) => {
                    return row.sensorType ? row.sensorType : '-'
                },
                sortable: false
            },
            {
                name: t("settingSensor.operation"),
                selector: (row) => {
                    return row.operation ? t(`constantTranslate.airQualityOperation.${row.operation}`) : '-'
                },
                sortable: false,
                width: '200px'
            },
            {
                name: t("settingSensor.value"),
                selector: (row) => {
                    return row.value ? row.value : '-'
                },
                sortable: false,
                width: '150px'
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {

                    let airQualityValue = {
                        sensorType: row.sensorType,
                        operation: row.operation,
                        value: row.value
                    }

                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModalAirQuality("UPDATE", airQualityValue, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmDeleteAirQuality(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t, optionsSensorType, optionsSensorStandardOperation } = this.props
            const { model, isOpenModalAirQuality, modelAirQuality } = this.state

            let airQualityList = []

            if (model) {
                if (model.sensorInfo) {
                    let sensorInfo = model.sensorInfo
                    if (sensorInfo.airQualityStandardInfo) {
                        airQualityList = sensorInfo.airQualityStandardInfo
                    }
                }
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalAirQuality &&
                        <ModalSettingSensor
                            model={modelAirQuality}
                            onClose={this.onCloseModalAirQuality}
                            onSubmit={(airQuality) => this.onSubmitModalAirQuality(airQuality, airQualityList)}
                            modalStatus={"CREATE"}
                            optionsSensorType={optionsSensorType}
                            optionsSensorStandardOperation={optionsSensorStandardOperation}
                        />
                    }
                    <LayoutWithSidebarHeaderFooter textHeader={t('settingSensor.settingSensor')}>
                        <Container text>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={8} verticalAlign={'bottom'}>
                                        <Header as='h3' className="form header view-header-button">
                                            {t("settingSensor.airQualityStandard")}
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={8} textAlign={"right"}>
                                        <Button
                                            content={t('common.add')}
                                            floated="right"
                                            color="green"
                                            onClick={() => this.onOpenModalAirQuality("CREATE", null, null)}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <ViewTable
                                            columns={this.getColumnConfig()}
                                            datas={airQualityList}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SettingSensorView.defaultProps = {
    model: new SettingSensorModel(),
    optionsSensorType: [], 
    optionsSensorStandardOperation: [],
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SettingSensorView)