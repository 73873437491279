
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import EventControlRuleView from '../../views/eventControlRuleStep/ViewEventControlRule'
import EventControlRuleManager from '../../business/EventControlRuleManager'

class ViewEventControlRuleController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bEventControlRule = new EventControlRuleManager(this)
	}

	onGetEventControlRuleInfo = (id) => {
		return this.bEventControlRule.getEventControlRuleInfo(id)
	}

	getPropsResetStatus() {
		return ["eventControlRuleInfo"]
	}

	render() {
		try {
			//const { eventControlRuleInfo } = this.props
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			//const eventControlRule = eventControlRuleInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<EventControlRuleView
						//model={eventControlRule}
						onGetEventControlRuleInfo={this.onGetEventControlRuleInfo}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	/* connect(
		(state, props) => ({
			eventControlRuleInfo: state.eventControlRuleInfo
		}),
		(dispatch, props) => ({
			getEventControlRuleInfo() {
				dispatch(getEventControlRuleInfo(utils.getParamFromProps(props, "id")))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getEventControlRuleInfo()
		}
	}), */
)


export default enhance(ViewEventControlRuleController)