const inputUtils = {
    onKeyNumber(e) {
        var charCode = (e.which) ? e.which : e.keyCode
            , key = e.key
            , isValid = true
        if (!!key) {
            isValid = /^[0-9]*$/.test(key)
        }
        if ([8, 13].includes(charCode)) { // 8=Backspace 13=Enter
            isValid = true
        }
        if (!isValid) {
            e.preventDefault()
            e.stopPropagation()
        }
        return isValid
    },

    onKeyNumberDali(e) {
        var charCode = (e.which) ? e.which : e.keyCode
            , key = e.key
            , isValid = true
        if (!!key) {
            isValid = (/^[0-9]*$/.test(key)) && (key >= 0 && key <= 100)
        }
        if ([8, 13].includes(charCode)) { // 8=Backspace 13=Enter
            isValid = true
        }
        if (!isValid) {
            e.preventDefault()
            e.stopPropagation()
        }

        return isValid

    }
}

export default inputUtils