
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import VisitorManager from '../../business/VisitorManager'
import utils from '../../core/utility/utils'
import VisitorUpdate from '../../views/visitor/VisitorUpdate'
import { getVisitorGroupList } from '../../actions/visitorGroupActions'
import { getVisitorInfo } from '../../actions/visitorActions'

class UpdateVisitorController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bVisitor = new VisitorManager(this)
	}

	getPropsResetStatus() {
		return ["visitorInfo"]
	}

	onUpdateVisitor = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bVisitor.updateVisitor(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				},

					action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/visitor/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { visitorInfo, visitorGroupList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const optionVisitorGroup = visitorGroupList.getDatasOption()
			const visitor = visitorInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<VisitorUpdate
						isLoadingControl={isLoadingComponent}
						optionVisitorGroup={optionVisitorGroup}
						model={visitor}
						onUpdate={this.onUpdateVisitor}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			visitorGroupList: state.visitorGroupList,
			visitorInfo: state.visitorInfo
		}),
		(dispatch, props) => ({
			getVisitorGroupList() {
				dispatch(getVisitorGroupList())
			},
			getVisitorInfo(id) {
				dispatch(getVisitorInfo(id))
			}
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.getVisitorGroupList()
			this.props.getVisitorInfo(utils.getParamFromProps(this.props, "id"))
		}
	}),
)


export default enhance(UpdateVisitorController)