
import types from '../types'
import { requestStatus } from '../../constant'
import RFIDCardResource from '../selectors/rfidCardResource'
import RFIDCardListResource from '../selectors/rfidCardListResource'

const rfidCardReducer = {
	rfidCardInfo: (state = null, action) => {
		if (!state || !(state instanceof RFIDCardResource)) {
			state = new RFIDCardResource()
		}

		switch (action.type) {
			case types.GET_RFID_CARD_INFO_REQUEST:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_RFID_CARD_INFO_SUCCESS:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_RFID_CARD_INFO_FAILURE:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	rfidCardList: (state = null, action) => {
		if (!state || !(state instanceof RFIDCardResource)) {
			state = new RFIDCardResource()
		}

		switch (action.type) {
			case types.GET_RFID_CARD_LIST_REQUEST:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_RFID_CARD_LIST_SUCCESS:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_RFID_CARD_LIST:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_RFID_CARD_LIST_FAILURE:
				state = new RFIDCardResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	rfidCardTypeList: (state = null, action) => {
		if (!state || !(state instanceof RFIDCardListResource)) {
			state = new RFIDCardListResource()
		}

		switch (action.type) {
			case types.GET_RFID_CARD_TYPE_LIST_REQUEST:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_RFID_CARD_TYPE_LIST_SUCCESS:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_RFID_CARD_TYPE_LIST_FAILURE:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	rfidCardDataTypeList: (state = null, action) => {
		if (!state || !(state instanceof RFIDCardListResource)) {
			state = new RFIDCardListResource()
		}

		switch (action.type) {
			case types.GET_RFID_CARD_DATA_TYPE_LIST_REQUEST:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_RFID_CARD_DATA_TYPE_LIST_SUCCESS:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_RFID_CARD_DATA_TYPE_LIST_FAILURE:
				state = new RFIDCardListResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default rfidCardReducer