
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import DatabaseDataModel from '../../../models/sensorRuleData/actionData/DatabaseDataModel'

class DatabaseDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors, optionsDataType, optionsSensorType } = this.props
            let collectionNameValidate = this.getErrorInput("collectionName", errors)
                , sensorTypeValidate = this.getErrorInput("sensorType", errors)
                , typeValidate = this.getErrorInput("type", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.databaseCollectName")} isRequire={true} />
                                    <Form.Input
                                        name="collectionName"
                                        value={model.collectionName}
                                        onChange={this.handleChange}
                                        placeholder={t('sensorRule.databaseCollectName')}
                                        error={collectionNameValidate.isError ? collectionNameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.databaseSensorType")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSensorType}
                                        onChange={this.handleChange}
                                        name="sensorType"
                                        value={model.sensorType}
                                        placeholder={t("common.pleaseSelect")}
                                        error={sensorTypeValidate.isError ? sensorTypeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.databaseDataType")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsDataType}
                                        onChange={this.handleChange}
                                        name="type"
                                        value={model.type}
                                        placeholder={t("common.pleaseSelect")}
                                        error={typeValidate.isError ? typeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <br></br>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

DatabaseDataForm.defaultProps = {
    model: new DatabaseDataModel(),
    errors: [],
    optionsDataType: [],
    optionsSensorType: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(DatabaseDataForm)