
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewDepartment from '../../views/department/ViewDepartment'
import DepartmentManager from '../../business/DepartmentManager'

class DepartmentController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bDepartment = new DepartmentManager(this)
    }

    onDeleteDepartment = (model, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bDepartment.deleteDepartment(model.id, (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }

                if (!!res && res.result === false) {
                } else if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(body, action)
            })
        })
    }

    onSyncDepartment = (callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bDepartment.syncDepartment((res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(tempState, action)
            })
        })
    }

    getDepartmentListPaging = (query) => {
        return this.bDepartment.getDepartmentListPaging(query)
    }

    render() {
        try {
            const { isLoadingComponent } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
                    <ViewDepartment
                        isLoadingControl={isLoadingComponent}
                        onDelete={this.onDeleteDepartment}
                        onSyncDepartment={this.onSyncDepartment}
                        onGetDataPaging={this.getDepartmentListPaging}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
/*     connect(
        (state, props) => ({
         
        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {
        }
    }) */
)


export default enhance(DepartmentController)