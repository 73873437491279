
import React from 'react'
import { sensorRuleStep } from '../../../constant'
import Step from '../../core/business/step/Step'

import SensorRuleManager from '../SensorRuleManager'
import ConditionView from '../../views/sensorRuleStep/step/ConditionView'

export default class ConditionStep extends Step {
	constructor(stepList) {
		super(stepList)
		this.title = "sensorRule.condition"
		this.key = sensorRuleStep.CONDITION
		this.data = {
			type: "",
			conditionModel: null,
			conditionDataModel: null,
		}
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		let bSensorRule = new SensorRuleManager()
		if (!!data) {
			let information = data[sensorRuleStep.SENSOR_INFORMATION]
			if (!!information) {
				this.data.type = information.type
				let ModalCondition = bSensorRule.getModelSensorRuleCondition(this.data.type)
				if (!!ModalCondition) {
					this.data.conditionModel = ModalCondition
					this.data.conditionDataModel = new ModalCondition(data[this.key])
				}
			}
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult[this.key] = data.conditionDataModel
		return allResult
	}

	nextStep(data) {
		let bSensorRule = new SensorRuleManager()

		if (!!this.data.type) {
			let ModalCondition = bSensorRule.getModelSensorRuleCondition(this.data.type)
			if (!!ModalCondition) {
				this.data.conditionModel = ModalCondition
				this.data.conditionDataModel = new ModalCondition(data)
			}
		}

		super.nextStep()
	}

	prevStep(data) {
		let bSensorRule = new SensorRuleManager()

		if (!!this.data.type) {
			let ModalCondition = bSensorRule.getModelSensorRuleCondition(this.data.type)
			if (!!ModalCondition) {
				this.data.conditionModel = ModalCondition
				this.data.conditionDataModel = new ModalCondition(data)
			}
		}
		super.prevStep()
	}

	saveStep(data, callback) {
		let bSensorRule = new SensorRuleManager()

		if (!!this.data.type) {
			let ModalCondition = bSensorRule.getModelSensorRuleCondition(this.data.type)
			if (!!ModalCondition) {
				this.data.conditionModel = ModalCondition
				this.data.conditionDataModel = new ModalCondition(data)
			}
		}

		super.saveStep()
		if (typeof callback === "function") {
			callback(this.key)
		} else {
			console.error("Please provide Callback Submit function for Save Step.")
		}
	}

	render(dataConfig) {
		return <ConditionView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
			onSave={(data) => this.saveStep(data, dataConfig.onSubmit)}
		/>
	}
}