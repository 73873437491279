
import types from '../types'
import { requestStatus } from '../../constant'
import LocationResource from '../selectors/locationResource'
import SceneResource from '../selectors/sceneResource'

const locationReducer = {
	locationInfo: (state = null, action) => {
		if (!state || !(state instanceof LocationResource)) {
			state = new LocationResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_REQUEST:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_SUCCESS:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_LOCATION_FAILURE:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	locationList: (state = null, action) => {
		if (!state || !(state instanceof LocationResource)) {
			state = new LocationResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_LIST_REQUEST:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_LIST_SUCCESS:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_RESET_LOCATION_LIST:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_LIST_FAILURE:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	locationWithIOTConfigInfo: (state = null, action) => {
		if (!state || !(state instanceof LocationResource)) {
			state = new LocationResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_REQUEST:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_SUCCESS:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_LOCATION_WITH_IOT_CONFIG_INFO_FAILURE:
				state = new LocationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	locationSceneInfo: (state = null, action) => {
		if (!state || !(state instanceof SceneResource)) {
			state = new SceneResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_SCENE_INFO_REQUEST:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_SCENE_INFO_SUCCESS:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_LOCATION_SCENE_INFO_FAILURE:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default locationReducer