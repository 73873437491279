
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Grid, Form, Label, Header, Segment } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import utils from '../../core/utility/utils'
import SensorInput from '../common/SensorInputForm'
import ViewNoResult from '../common/ViewNoResult'
// import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'
// import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'

class SensorForm extends MyForm {
    constructor(props) {
        super(props)

        this.state = {
            model: props.model,
            selectedSerialList: Array.isArray(props.model.serials) ? props.model.serials : [],
            moduleOption: [],
            serialOption: [],
        }

        this.rawModuleMapping = props.optionsSensorModuleList.map(module => {
            let moduleMap = []
            moduleMap[module.text] = [module.text]
            return moduleMap
        })

        this.moduleMapping = {}

        this.rawModuleMapping.forEach(module => {
            Object.assign(this.moduleMapping, module)
        })

        /*        
        
                this.moduleMapping = {
                    DIN: ["DIN"],
                    RS485: ["RS485"]
                }
         */
    }

    componentDidMount() {
        const { model, controllerList } = this.props
        const { selectedSerialList } = this.state

        this.setState({ selectedSerialList: Array.isArray(model.serials) ? model.serials : [] })

        let controller = controllerList.find(d => d.id === model.controllerId)

        let moduleOption = []

        if (controller) {
            moduleOption = this.getModuleOption(controller)
            this.setState({ moduleOption })
            this.props.onchangeModule(model.moduleCode)
        }

        if (!!model.moduleCode && model.moduleCode !== "") {
            this.getSerialOption(model.moduleCode)
            this.props.handleChangeSerialList(selectedSerialList)
        }
    }

    onChangeController = (e, { name, value }) => {
        const { controllerList } = this.props

        let controller = controllerList.find(d => d.id === value)
            , moduleOption = []

        if (controller) {
            moduleOption = this.getModuleOption(controller)
        }

        this.setState({ moduleOption, serialOption: [] })
        this.props.onchangeController(controller)
    }

    getModuleOption = (controller) => {
        if (!controller || !Array.isArray(controller.enableModule)) {
            return []
        }

        return controller.enableModule.map(e => e.moduleCode)
            .reduce((r, e) => r.concat(this.moduleMapping[e]), [])
            .filter(item => item !== undefined)
            .map(e => {
                return {
                    key: e,
                    text: e,
                    value: e
                }
            })
    }

    onChangeModule = (e, { name, value }) => {
        const { model, controllerList } = this.props
        let controller = controllerList.find(d => d.id === model.controllerId)
            , enableModule = null

        for (let moduleCode in this.moduleMapping) {
            if (!this.moduleMapping[moduleCode].includes(value)) {
                continue
            }

            enableModule = controller.enableModule.find(e => e.moduleCode === moduleCode)
            break
        }

        if (!enableModule) {
            return
        }

        this.setState({ serialOption: enableModule.serials })
        this.props.onchangeModule(value)
    }

    getSerialOption = (sensorModuleCode) => {
        const { model, controllerList } = this.props
        let controller = controllerList.find(d => d.id === model.controllerId)
            , enableModule = null

        for (let moduleCode in this.moduleMapping) {
            if (!this.moduleMapping[moduleCode].includes(sensorModuleCode)) {
                continue
            }

            enableModule = controller.enableModule.find(e => e.moduleCode === moduleCode)
            break
        }

        if (!enableModule) {
            return
        }

        this.setState({ serialOption: enableModule.serials })
    }

    serialListCheckBoxList = (optionsSerialList) => {
        // const { selectedSerialList } = this.state
        const { model } = this.props

        let component = null

        if (!!optionsSerialList && Array.isArray(optionsSerialList) && optionsSerialList.length > 0) {
            component = optionsSerialList.map((serial, i) => {
                return <Form.Checkbox
                    key={i}
                    label={serial}
                    name="select"
                    value={serial}
                    onChange={this.handleChangeSerialList}
                    checked={model.serials.includes(serial)}
                />
            })
        }

        return component
    }

    handleChangeSerialList = (e, { name, value, checked }) => {
        const { serialOption } = this.state
        const { model } = this.props

        let selectedSerialList = model.serials.map(s => s)
        if (name === "select") {
            if (checked) {
                if (!serialOption.includes(value) || selectedSerialList.includes(value)) {
                    return
                }

                selectedSerialList.push(value)
            } else {
                selectedSerialList = utils.arrayDiff(selectedSerialList, [value])
            }
        } else if (name === "selectAll") {
            if (checked) {
                selectedSerialList = serialOption
            } else {
                selectedSerialList = []
            }
        }

        this.props.handleChangeSerialList(selectedSerialList)
    }

    handleChangeLocationType = (e, { name, value }) => {
        this.props.onchangeLocationType(value)
    }

    handleChangeLocationId = (e, { name, value }) => {
        this.props.onchangeLocationId(value)
    }

    render() {
        try {
            const { t, model, optionsControllerList, optionsLocationList, optionsResourceList, optionsSensorTypeList, optionsSensorModelList, optionsSensorLocationTypeList, errors } = this.props
            const { moduleOption, serialOption } = this.state

            let sensorModuleValidate = this.getErrorInput("moduleCode", errors)
                , controllerIdValidate = this.getErrorInput("controllerId", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                , serialListIdsValidate = this.getErrorInput("serials", errors)
                , locationTypeValidate = this.getErrorInput("location.type", errors)
                , locationIdValidate = this.getErrorInput("location.id", errors)
                , sensorTypeValidate = this.getErrorInput("sensorType", errors)
                , sensorModelValidate = this.getErrorInput("sensorModel", errors)
                , sensorSNValidate = this.getErrorInput("sensorSN", errors)
                , sensorConfigValidate = this.getErrorInput("configValue", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('controller.title')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="controllerId"
                                        value={model.controllerId}
                                        options={optionsControllerList}
                                        onChange={this.onChangeController}
                                        error={controllerIdValidate.isError ? controllerIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('sensor.module')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="moduleCode"
                                        value={model.moduleCode}
                                        options={moduleOption}
                                        onChange={this.onChangeModule}
                                        error={sensorModuleValidate.isError ? sensorModuleValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('sensor.type')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="sensorType"
                                        value={model.sensorType}
                                        options={optionsSensorTypeList}
                                        onChange={this.handleChange}
                                        error={sensorTypeValidate.isError ? sensorTypeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('sensor.model')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="sensorModel"
                                        value={model.sensorModel}
                                        options={optionsSensorModelList}
                                        onChange={this.handleChange}
                                        error={sensorModelValidate.isError ? sensorModelValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('sensor.sn')} isRequire={true} />
                                    <Form.Input
                                        name="sensorSN"
                                        value={model.sensorSN}
                                        onChange={this.handleChange}
                                        error={sensorSNValidate.isError ? sensorSNValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <Grid>
                            {
                                // (!!serialOption && serialOption.length !== 0) &&
                                (!!model.moduleCode) &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <React.Fragment>
                                            <LabelInput text={t("sensor.serial")} isRequire={true} />
                                            {
                                                Array.isArray(serialOption) && serialOption.length > 0 ?
                                                    <div className="module multipleCheckboxContainer">
                                                        <Segment className="group-segment">
                                                            <div className="checkbox-wrapper">
                                                                <Form.Checkbox
                                                                    label={t("common.selectAll")}
                                                                    name={`selectAll`}
                                                                    onChange={this.handleChangeSerialList}
                                                                    checked={serialOption.length === model.serials.length}
                                                                />
                                                                {this.serialListCheckBoxList(serialOption)}
                                                            </div>
                                                        </Segment>
                                                    </div>
                                                    : <ViewNoResult message={t("moduleConfig.noSerial")} />
                                            }
                                        </React.Fragment>

                                        {
                                            (serialListIdsValidate.isError &&
                                                serialListIdsValidate.content) &&
                                            <Form.Field>
                                                <Label pointing prompt>
                                                    {serialListIdsValidate.content}
                                                </Label>
                                            </Form.Field>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                        <br></br>
                        <Header as='h1' dividing className="form header">
                            {t("location.title")}
                        </Header>
                        <Segment className="group-segment-input">
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('sensor.type')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="location.type"
                                            value={model.location.type}
                                            options={optionsSensorLocationTypeList}
                                            onChange={this.handleChangeLocationType}
                                            error={locationTypeValidate.isError ? locationTypeValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                {
                                    (model.location.type === "LOCATION") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('location.title')} isRequire={true} />
                                            <Form.Dropdown
                                                selection
                                                selectOnBlur={false}
                                                placeholder={t("common.pleaseSelect")}
                                                name="location.id"
                                                value={model.location.id}
                                                options={optionsLocationList}
                                                onChange={this.handleChangeLocationId}
                                                error={locationIdValidate.isError ? locationIdValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                                {
                                    (model.location.type === "RESOURCE") &&
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('common.resource')} isRequire={true} />
                                            <Form.Dropdown
                                                selection
                                                selectOnBlur={false}
                                                placeholder={t("common.pleaseSelect")}
                                                name="location.id"
                                                value={model.location.id}
                                                options={optionsResourceList}
                                                onChange={this.handleChangeLocationId}
                                                error={locationIdValidate.isError ? locationIdValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                }
                            </Grid>
                        </Segment>

                        <br></br>
                        {
                            (!!model.moduleCode && model.moduleCode === "RS485") &&
                            <React.Fragment>
                                <Header as='h1' dividing className="form header">
                                    {t("sensor.config")}
                                </Header>
                                <Segment className="group-segment-input">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>

                                                <SensorInput
                                                    name="configValue"
                                                    type={model.moduleCode}
                                                    value={model.configValue}
                                                    onChange={this.handleChange}
                                                />

                                                {
                                                    (sensorConfigValidate.isError &&
                                                        sensorConfigValidate.content) &&
                                                    <Form.Field>
                                                        <Label pointing prompt>
                                                            {sensorConfigValidate.content}
                                                        </Label>
                                                    </Form.Field>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </React.Fragment>

                        }
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorForm.defaultProps = {
    model: null,
    errors: [],
    optionsLocation: [],
    handleChange: () => console.error("Please provide \"Change Sensor\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SensorForm)