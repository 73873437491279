import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetSensorList = () => {
	return {
		type: type.RESET_SENSOR_LIST
	}
}

const getSensorInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_INFO_REQUEST,
				{
					type: type.GET_SENSOR_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_INFO_FAILURE
			]
		}
	}
}

const getSensorList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_LIST_REQUEST,
				{
					type: type.GET_SENSOR_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_LIST_FAILURE
			]
		}
	}
}

const getSensorLocationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/location`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_LOCATION_LIST_REQUEST,
				{
					type: type.GET_SENSOR_LOCATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_LOCATION_LIST_FAILURE
			]
		}
	}
}

const getSensorResourceList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/location/resource`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RESOURCE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RESOURCE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RESOURCE_LIST_FAILURE
			]
		}
	}
}

const getSensorTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/sensorcode`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorModelList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/sensormodel`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_MODEL_LIST_REQUEST,
				{
					type: type.GET_SENSOR_MODEL_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_MODEL_LIST_FAILURE
			]
		}
	}
}

const getSensorModuleList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/modulecode`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_MODULE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_MODULE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_MODULE_LIST_FAILURE
			]
		}
	}
}

const getSensorLocationTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/location/locationtype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_LOCATION_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_LOCATION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_LOCATION_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorControllerList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensor/controller/list`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_CONTROLLER_LIST_REQUEST,
				{
					type: type.GET_SENSOR_CONTROLLER_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_CONTROLLER_LIST_FAILURE
			]
		}
	}
}

export {
    getSensorInfo,
    getSensorList,
	resetSensorList,
	getSensorLocationList,
	getSensorResourceList,
	getSensorTypeList,
	getSensorModelList,
	getSensorModuleList,
	getSensorLocationTypeList,
	getSensorControllerList
}