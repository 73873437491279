
import { CORE_SERVICE_ENDPOINT, CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import EventControlRuleModel from '../models/EventControlRuleModel'
import PagingDataModel from '../core/model/PagingDataModel'

export default class EventControlRuleManager extends MyManager {
	async createEventControlRule(model, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule`,
				data: model
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateEventControlRule(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteEventControlRule(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async enableEventControlRuleStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/enable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async disableEventControlRuleStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/disable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getEventControlRuleListPaging(query) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/page${!!query ? query : ""}`,
            }

            let res = await this.requestAction(options)

            let data = null

            if (!!res && res.result === true && !!res.data) {
                data = new PagingDataModel({ pagination: res.data.pagination })
                if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
                    data.datas = res.data.datas.map(d => {
                        return new EventControlRuleModel(d)
                    })
                }
            }

            return data
        } catch (error) {
            this.setDefaultModalError(error)
            return null
        }
    }

	async getEventControlRuleInfo(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/${id}`,
			}

			let res = await this.requestAction(options)
			if (!!res && res.result === true && !!res.data) {
				let model = new EventControlRuleModel()
				model.setData(res.data)
				res.data = model
			}
			return res
		} catch (error) {
			return null
		}
	}

	async getRoomList(callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/room`,
			}

			let res = await this.requestAction(options, callback)

			let data = null

			if (!!res && res.result === true && !!res.datas) {
				if (Array.isArray(res.datas) && res.datas.length > 0) {
					data = res.datas.map(d => {
						return {
							key: d.id,
							value: d.id,
							text: d.name,
							code: d.code,
						}
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async getDeskList(callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk`,
			}

			let res = await this.requestAction(options, callback)

			let data = null

			if (!!res && res.result === true && !!res.datas) {
				if (Array.isArray(res.datas) && res.datas.length > 0) {
					data = res.datas.map(d => {
						return {
							key: d.id,
							value: d.id,
							text: d.name,
							code: d.code,
						}
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async getDoorList(callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/door`,
			}

			let res = await this.requestAction(options, callback)

			let data = null

			if (!!res && res.result === true && !!res.datas) {
				if (Array.isArray(res.datas) && res.datas.length > 0) {
					data = res.datas.map(d => {
						return {
							key: d.id,
							value: d.id,
							text: d.name,
							code: d.code,
						}
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

}