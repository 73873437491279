
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ViewLogin from '../../views/auth/ViewLogin'
import AuthManager from '../../business/AuthManager'
import SettingManager from '../../business/SettingManager'

import { getAuthLoginInfo } from '../../actions/settingActions'
import HandleDataRedux from '../../views/common/HandleDataRedux'

class LoginController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.state.isLoadingComponent = false
		this.state.loginSetting = []

		this.bAuth = new AuthManager(this)
		this.bSetting = new SettingManager(this)
	}

	getPropsResetStatus() {
		return ["settingLoginInfo"]
	}

	componentDidMount() {
		// this.getLoginSetting()
	}

	getLoginSetting = () => {
		this.setState({
			isLoading: true
		}, async () => {
			let tempState = {
				isLoading: false
			},
				action = () => { }
			let res = await this.bSetting.getLoginInfo()
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				tempState.loginSetting = res.datas
			}
			this.setState(tempState, action)
		})
	}

	onVerifyLocal = (model) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bAuth.localVerify(model.serializeAPI(), res => {
				this.setState({
					isLoadingComponent: false
				}, () => {
					if (res) {
						this.bAuth.afterVerify(res.data, model.isRemember)
					}
				})
			})
		})
	}
	onVerifyAD = (model) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bAuth.adVerify(model.serializeAPIWidthSystem(), res => {
				this.setState({
					isLoadingComponent: false
				}, () => {
					if (res) {
						this.bAuth.afterVerify(res.data, model.isRemember)
					}
				})
			})
		})
	}
	onVerifyExchange = (model) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bAuth.exchangeVerify(model.serializeAPIWidthSystem(), res => {
				this.setState({
					isLoadingComponent: false
				}, () => {
					if (res) {
						this.bAuth.afterVerify(res.data, model.isRemember)
					}
				})
			})
		})
	}
	onVerifyProfileCenter = (model) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bAuth.profileVerify(model.serializeAPIWidthSystem(), res => {
				this.setState({
					isLoadingComponent: false
				}, () => {
					if (res) {
						this.bAuth.afterVerify(res.data, model.isRemember)
					}
				})
			})
		})
	}

	render() {
		try {
			const { settingLoginInfo } = this.props
			const { isLoading, isLoadingComponent } = this.state
			let loginSettingData = settingLoginInfo.getDatas()
			return (
				<React.Fragment>
					{super.render()}
					{(isLoading || isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<ViewLogin
						isLoading={isLoading}
						isLoadingVerify={isLoadingComponent}
						loginSetting={loginSettingData}
						onVerifyLocal={this.onVerifyLocal}
						onVerifyAD={this.onVerifyAD}
						onVerifyExchange={this.onVerifyExchange}
						onVerifyProfileCenter={this.onVerifyProfileCenter}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	HandleDataRedux,
	connect(
		(state, props) => ({
			settingLoginInfo: state.settingLoginInfo
		}),
		(dispatch, props) => ({
			getAuthLoginInfo() {
				dispatch(getAuthLoginInfo())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getAuthLoginInfo()
		}
	})
)


export default enhance(LoginController)