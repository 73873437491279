
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyForm from '../../core/components/MyForm'
import RS485Form from './sensorInput/RS485Form'

class SensorInputForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.sensorValue = props.value
        this.selfHandleForm = true
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props

        if (prevProps.value !== value) {
            this.setState({
                sensorValue: value
            })
        }
    }

    handleChangeSensor = (e, { name, value }) => {
        let sensorValue = value

        this.setState({ sensorValue: sensorValue })

        this.props.onChange(null, { name: this.props.name, value: sensorValue })
    }

    render() {
        try {
            const { disabled, type } = this.props
            const { sensorValue } = this.state

            if (!type) {
                return <p></p>
            }

            return (
                <React.Fragment>
                    {
                        (type === "RS485") &&
                        <RS485Form 
                            name="RS485"
                            value={sensorValue}
                            disabled={disabled}
                            onChange={this.handleChangeSensor}
                        />
                    }
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorInputForm.defaultProps = {
    name: "",
    type: "",
    value: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate
)

export default enhance(SensorInputForm)