

import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

import SensorRuleManager from '../business/SensorRuleManager'

import AlertDashboardDataModel from './sensorRuleData/actionData/AlertDashboardDataModel'
import AlertMessageDataModel from './sensorRuleData/actionData/AlertMessageDataModel'
import ControlDataModel from './sensorRuleData/actionData/ControlDataModel'
import DatabaseDataModel from './sensorRuleData/actionData/DatabaseDataModel'

let modelsAction = [AlertDashboardDataModel, AlertMessageDataModel, ControlDataModel, DatabaseDataModel]

const Schema = mongoose.Schema

const SensorRuleActionSchema = new Schema({
	type: { type: String, required: [true, "validate.require"] },
	result: { type: String, required: [true, "validate.require"] },
	data: { type: Object, required: [true, "validate.require"] },
}, { _id: false })

setBaseSchema(SensorRuleActionSchema, "sensor_rule_action")

SensorRuleActionSchema.methods.serializeAPI = function () {
	try {
		let result = {
			type: this.type,
			result: this.result,
		}
		if (!!this.data) {
			if (!!modelsAction.find(model => this.data instanceof model)) {
				result.data = this.data.serializeAPI()
			}
		}
		return result
	} catch (error) {
		return null
	}
}

SensorRuleActionSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.type = data.type
			this.result = data.result
			if (!!data.type) {
				let bSensorRule = new SensorRuleManager()
					, ModelAction = bSensorRule.getModelSensorRuleAction(data.type)
				if (!!ModelAction) {
					this.data = new ModelAction(data.data)
				}
			}
		}
	} catch (error) {

	}
}

const SensorRuleActionModel = mongoose.model("sensor_rule_action", SensorRuleActionSchema, "sensor_rule_action")

export default SensorRuleActionModel