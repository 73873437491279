
import types from '../types'
import { requestStatus } from '../../constant'
import ControllerResource from '../selectors/controllerResource'

const controllerReducer = {
	controllerInfo: (state = null, action) => {
		if (!state || !(state instanceof ControllerResource)) {
			state = new ControllerResource()
		}

		switch (action.type) {
			case types.GET_CONTROLLER_INFO_REQUEST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_INFO_SUCCESS:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_CONTROLLER_INFO_FAILURE:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	controllerList: (state = null, action) => {
		if (!state || !(state instanceof ControllerResource)) {
			state = new ControllerResource()
		}

		switch (action.type) {
			case types.GET_CONTROLLER_LIST_REQUEST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_LIST_SUCCESS:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_CONTROLLER_LIST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_LIST_FAILURE:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	controllerConfigInfo: (state = null, action) => {
		if (!state || !(state instanceof ControllerResource)) {
			state = new ControllerResource()
		}

		switch (action.type) {
			case types.GET_CONTROLLER_CONFIG_INFO_REQUEST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_CONFIG_INFO_SUCCESS:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_CONTROLLER_LIST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_CONTROLLER_CONFIG_INFO_FAILURE:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default controllerReducer