
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import DeskManager from '../../business/DeskManager'
import DeskList from '../../views/desk/DeskList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class DeskController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bDesk = new DeskManager(this)
	}

	getPropsResetStatus() {
		return ["deskList", "locationList"]
	}

	onDeleteDesk = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.deleteDesk(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableDesk = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.enableDeskStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableDesk = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.disableDeskStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onSyncDesk = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.syncDesk((res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(tempState, action)
			})
		})
	}

	onImportDeskFromPlatform = async (ids, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.importDesk({ deskIds: ids }, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getDeskListPaging = (query) => {
		return this.bDesk.getDeskListPaging(query)
	}

	render() {
		try {
			const { t, locationList } = this.props
			const { isLoadingComponent } = this.state

			const optionLocation = locationList.getDatasOption()
			let optionLocationList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionLocationList = optionLocationList.concat(optionLocation)
			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<DeskList
						optionLocationList={optionLocationList}
						onDeleteDesk={this.onDeleteDesk}
						onChangeEnableDesk={this.onChangeEnableDesk}
						onChangeDisableDesk={this.onChangeDisableDesk}
						onSyncDesk={this.onSyncDesk}
						onImportDeskFromPlatform={this.onImportDeskFromPlatform}
						onGetDataPaging={this.getDeskListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			locationList: state.locationList,
		}),
		(dispatch) => ({
			getLocationList() {
				dispatch(getLocationList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
		}
	}),
)


export default enhance(DeskController)