
import ActionResourceObject from '../core/redux/ActionResourceObject'
import SensorModel from '../models/SensorModel'

export default class SensorResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new SensorModel(d))
			} else {
				result = new SensorModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d,
						value: d,
						text: d
					}
				})
			} else {
				result = {
					key: data,
					value: data,
					text: data
				}
			}

			return result
		} catch (error) {
			return null
		}
	}

}