
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getRoleList } from '../../actions/roleActions'
import RoleManager from '../../business/RoleManager'
import RoleList from '../../views/role/RoleList'
import utils from '../../core/utility/utils'

class RoleController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRole = new RoleManager(this)

		this.state.roleId = this.getQueryFromProps("id")
		this.state.role = null
	}

	getPropsResetStatus() {
        return ["roleList"]
    }

	onReloadData = () => {
		this.props.getRoleList()
	}

	componentDidMount() {
		this.startGetRoleList(this.state.roleId)
	}

	startGetRoleList = (roleId) => {
		this.props.getRoleList("")
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		let roleId = utils.getQueryFromProps(nextProps, "id")
		if (prevState.roleId !== roleId) {
			tempState.roleId = roleId
			tempState.roleId = null
		}
		if (Object.keys(tempState).length > 0) {
			return tempState
		}

		return null
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.roleId !== this.state.roleId) {
			this.startGetRoleList(this.state.roleId)
		}
	}

	startGetRoleInfo = (roleId) => {
		this.setState({
			isLoadingComponent: true
		}, () => this.getRoleInfo(roleId))
	}

	getRoleInfo = async (roleId) => {
		let role = await this.bRole.getRoleInfo(roleId)
		this.setState({
			isLoadingComponent: false,
			role: role
		})
	}

	onDeleteRole = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRole.deleteRole(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
				, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") 
					{
                        action = callBack
                    }
				}
				this.setState(body, action)
			})
		})

	}

	onSyncRole = () => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRole.syncRole((res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					action = () => this.startGetRoleList(this.state.roleId)
				}
				this.setState(tempState, action)
			})
		})
	}

	onGetRolePlatformList = () => {
		try {
			return this.bRole.getRolePlatform()
		} catch (error) {
			return []
		}
	}

	onImportRoleFromPlatform = async (ids) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRole.importRole({ roleIds: ids }, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.onReloadData
				}
				this.setState(body, action)
			})
		})

	}

	render() {
		try {
			const { roleList } = this.props
			const { isLoadingComponent } = this.state

			const roleModel = roleList.getDatas()

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<RoleList
						isLoadingTable={!this.requestFinishedAfterRequest()}
						roleList={roleModel}
						onReloadData={this.props.getRoleList}
						onDeleteRole={this.onDeleteRole}
						onSyncRole={this.onSyncRole}
						onGetRolePlatformList={this.onGetRolePlatformList}
						onImportRoleFromPlatform={this.onImportRoleFromPlatform}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			roleList: state.roleList
		}),
		(dispatch, props) => ({
			getRoleList(query) {
				dispatch(getRoleList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getRoleList()
		}
	}),
)


export default enhance(RoleController) 