
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../../core/components/MyController'
import ViewSelectService from '../../../views/user/importExternalUser/ViewSelectService'

class SelectServiceController extends MyController {
    onBack = () => {
        this.props.onBack()
    }

    onNext = (model) => {
        const { dataConfig } = this.props
        let data = {
            serviceId: model.serviceId,
            service: null,
        }
        if (!!dataConfig) {
            if (Array.isArray(dataConfig.importSystemList)) {
                let service = dataConfig.importSystemList.find(m => m.id === model.serviceId)
                if (!!service) {
                    data.service = service
                }
            }
        }
        this.props.onNext(data)
    }

    onCancel = () => {
        this.props.onCancel()
    }
    render() {
        try {
            const { dataConfig, dataStep } = this.props
            let optionsImportService = []
                , optionsResourceGroup = []
                , importSystemList = []
            if (!!dataConfig) {
                if (Array.isArray(dataConfig.optionsImportService)) {
                    optionsImportService = dataConfig.optionsImportService
                }
                if (Array.isArray(dataConfig.optionsResourceGroup)) {
                    optionsResourceGroup = dataConfig.optionsResourceGroup
                }
                if (Array.isArray(dataConfig.importSystemList)) {
                    importSystemList = dataConfig.importSystemList
                }
            }
            return (
                <React.Fragment>
                    {super.render()}
                    <ViewSelectService
                        dataStep={dataStep}
                        optionsImportService={optionsImportService}
                        importSystemList={importSystemList}
                        optionsResourceGroup={optionsResourceGroup}
                        onBack={this.onBack}
                        onNext={this.onNext}
                        onCancel={this.onCancel}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

SelectServiceController.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {

        }
    })
)


export default enhance(SelectServiceController)