
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import AdminModel from '../models/AdminModel'
import UserModel from '../models/UserModel'
import PagingDataModel from '../core/model/PagingDataModel'
import ExchangeIntegrationModel from '../models/ExchangeIntegrationModel'
import ADIntegrationModel from '../models/ADIntegrationModel'
import SystemIntegrationModel from '../models/SystemIntegrationModel'

export default class AdminManager extends MyManager {

	async createAdmin(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/admin`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateAdmin(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getAdminStatusList() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/status`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateAdminStatus(code, body, callback) {
		// console.log(code, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/changestatus/${code}`,
				data: {
					"status": body
				}
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async resetPasswordAdmin(code, callback) {
		try {
			const option = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/resetPassword/${code}`,
			}
			let res = await this.requestAction(option, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteAdmin(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/admin/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async unintegrateAdmin(id, service, systemIntegrationId, callback) {
		try {
			let data = {}
			if (systemIntegrationId) {
				data = {
					"systemId": systemIntegrationId,
					"unintegrationService": service
				}
			} else {
				data = {
					"unintegrationService": service
				}
			}

			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/unintegration/${id}`,
				data: data
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getAdminPlatform(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/platformdata/list${!!query ? query : ""}`
			}

			let res = await this.requestAction(options)
			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new AdminModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importAdmin(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/admin/import/platformdata`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUser(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/user/list${!!query ? query : ""}`
			}

			let res = await this.requestAction(options)
			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new UserModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importUser(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/admin/import/user`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async adminIntegrationAD(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/integration/ad/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async adminIntegrationExchange(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/integration/exchange/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async adminIntegrationGoogle(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/integration/google/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async adminIntegrationO365(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/admin/integration/o365/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUserImportAD(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/ad/user/list/${!!id ? id : ""}`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(d => {
					return new ADIntegrationModel(d)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getUserImportExchange(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/exchange/user/list/${!!id ? id : ""}`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(d => {
					return new ExchangeIntegrationModel(d)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getImportSystemList() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/import/service/list`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(data => {
					return new SystemIntegrationModel(data)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async importUserExchange(service, body) {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/admin/import/exchange/${service}`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async importUserAD(service, body) {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/admin/import/ad/${service}`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async getAdminListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null

			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new AdminModel(d)
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async adminTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/admin/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}