

import React from 'react'
import MyModal from '../view/modals/MyModal'
// import ModalCustom from '../../views/common/ModalCustom'
import ListErrorFromAPI from '../view/errors/listErrorFromAPI'
import CookiesManager from '../business/CookiesManager'
import ViewLoading from '../view/common/ViewLoading'
import ButtonModel from '../model/ButtonModel'


export default class MyComponent extends React.Component {

	constructor(props) {
		super(props)
		this.isRenderSystemModal = true
		this.modalThemes = ["mymodal"]
		this.state = {
			modal: null,
			error: [],
		}
		this.modalTheme = "mymodal"
		this.bCookie = new CookiesManager()
	}

	getOptionList(datas, valueField, textField) {
		try {
			return datas.map(data => {
				return {
					value: data && data[valueField] ? data[valueField] : "",
					text: data && data[textField] ? data[textField] : "Empty",
				}
			})
		} catch (error) {
			return []
		}
	}

	renderErrorComponent(errorObj) {
		try {
			console.error(errorObj)
			return errorObj.message
		} catch (error) {
			console.error(error)
			return error.message
		}

	}

	render() {
		const { modal } = this.state
		return (
			<React.Fragment>
				{this.isRenderSystemModal && this.getModalContent(modal)}
			</React.Fragment>
		)
	}

	getLoadingComponent(message = "") {
		return <ViewLoading isPage={false} message={message} />
	}

	getLoadingPage(message = "") {
		return <ViewLoading message={message} />
	}

	getModalView = () => {
		try {
			let ModalView = null
			if (this.modalTheme) {
				let getTheme = this.modalThemes.includes(this.modalTheme.toLowerCase()) ? this.modalTheme.toLowerCase() : "custommodal"
				if (getTheme === "mymodal") {
					ModalView = MyModal
				}
			}
			return ModalView
		} catch (error) {
			return null
		}
	}

	getModalContent(modal) {
		let ModalView = this.getModalView()
		if (!ModalView) {
			return null
		}
		return <ModalView
			{...modal}
			size={modal ? modal.size : null}
			scrolling={modal && typeof modal.scrolling === "boolean" ? modal.scrolling : false}
			open={modal ? modal.open : false}
			type={modal ? modal.type : null}
			headerText={modal ? modal.headerText : null}
			content={modal ? modal.content : null}
			onClose={modal && typeof modal.onClose === "function" ? modal.onClose : this.closeModal}
			buttons={modal ? modal.buttons : []}
		/>
	}

	openInfoModal(options = {}) {
		let modalOption = this.getInfoModalOption(options)
		this.setState({ modal: modalOption })
	}

	openWarningModal(options = {}) {
		let modalOption = this.getWarningModalOption(options)
		this.setState({ modal: modalOption })
	}

	openSuccessModal(options = {}) {
		let modalOption = this.getSuccessModalOption(options)
		this.setState({ modal: modalOption })
	}

	openErrorModal(options = {}) {
		let modalOption = this.getErrorModalOption(options)
		this.setState({ modal: modalOption })
	}

	openModal(options = {}) {
		let modalOption = this.getModalOption(options)
		this.setState({ modal: modalOption })
	}


	getInfoModalOption(options = {}) {
		let config = this.getModalOption({ type: "info", ...options })
		return config
	}

	getWarningModalOption(options = {}) {
		let config = this.getModalOption({ type: "warning", ...options })
		return config
	}

	getSuccessModalOption(options = {}) {
		let config = this.getModalOption({ type: "success", ...options })
		return config
	}

	getErrorModalOption(options = {}) {
		let config = this.getModalOption({ type: "error", ...options })
		return config
	}

	getModalOption(options = {}) {
		return {
			open: true,
			type: "info",
			scrolling: options && typeof options.scrolling === "boolean" ? options.scrolling : false,
			size: options.size,
			onClose: typeof options.onClose === "function" ? options.onClose : this.closeModal,
			headerText: options.headerText,
			content: options.content,
			buttons: options.buttons ? options.buttons : [],
		}
	}

	closeModal = (callback) => {
		try {
			let modal = this.state.modal
			if(!!modal) {
				modal.open = false
				let action = () => { }
				if (typeof callback === "function") {
					action = callback
				}
				this.setState({ modal: modal }, action)
			}
		} catch (error) {
			/* console.log("error: ", error) */
		}

	}

	setDefaultModalError(defaultErrors) {
		let dataErrorModal = null
		try {
			if (!Array.isArray(defaultErrors)) {
				defaultErrors = [defaultErrors]
			}

			dataErrorModal = { errors: [] }
			for (let defaultError of defaultErrors) {
				let code = defaultError.code ? defaultError.code : "00000000"
					, message = defaultError.message ? defaultError.message : "Something went wrong. Please try again later"

				dataErrorModal.errors.push({
					code: code,
					statusText: message,
					statusCode: code,
					message: message,
					isShow: true,
				})

				console.warn(defaultError)
			}
		} catch (error) {
			dataErrorModal = {
				errors: [{
					code: "0000000",
					statusText: error.message,
					statusCode: "0000000",
					message: error.message,
					isShow: true,
				}]
			}

			console.warn(error)
		}

		this.setModalErrors(dataErrorModal)
	}

	setModalErrors(dataError) {
		console.error("Error :", dataError)
		const { t } = this.props

		if (dataError) {
			this.openErrorModal({
				content: <ListErrorFromAPI errors={dataError.errors} />,
				headerText: "System",
				buttons: [
					{
						iconClass: "",
						iconColor: null,
						color: "red",
						text: t ? t("common.close") : "Close",
						title: t ? t("common.close") : "Close",
						onClick: () => this.closeModal(),
						visible: true,
					}
				]
			})
		}
	}

	setModalCatch = (error) => {
		console.error("Error :", error)
		const { t } = this.props
		try {
			this.openErrorModal({
				content: t ? t("message.somethingWrong") : "Something went wrong. Please try again later.",
				headerText: t ? t("common.system") : "System",
				buttons: [
					new ButtonModel({
						iconClass: "",
						iconColor: null,
						color: "red",
						text: t ? t("common.close") : "Close",
						title: t ? t("common.close") : "Close",
						onClick: () => this.closeModal(),
						visible: true,
					})
				]
			})
		} catch (error) {
			console.error("Error :", error)
		}
	}

	getQueryFromProps(key = "") {
		try {
			let search = null
			if (this.props && this.props.location && this.props.location.search) {
				search = this.props.location.search
			}
			let querys = ""
			if (search) {
				const params = new URLSearchParams(search)
				const code = params.get(key)
				if (code) {
					querys = code
				}
			}
			return querys
		} catch (error) {
			return ""
		}
	}

	getParamFromProps(key = "") {
		try {
			let params = null
			if (!!this.props && !!this.props.match && !!this.props.match.params) {
				params = this.props.match.params
			}
			let value = ""
			if (!!params) {
				if (!!params[key]) {
					value = params[key]
				}
			}
			return value
		} catch (error) {
			return ""
		}
	}

	getStateLocationFormProps(key = "") {
		try {
			let locationState = null
			if (this.props && this.props.location && this.props.location.state) {
				locationState = this.props.location.state
			}
			let data = null
			if (locationState) {
				if (!!key && !!locationState[key]) {
					data = locationState[key]
				}
			}
			return data
		} catch (error) {
			return null
		}
	}

	changeDataModel = (names, model, value) => {
		if (!!model) {
			if (names.length === 1) {
				model[names[0]] = value
			} else {
				let tempModel = model[names[0]]
				names.splice(0, 1)
				this.changeDataModel(names, tempModel, value)
			}
		}
	}
}