
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import { resourceStatus } from '../../constant'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const ResourceGroupSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },

}, { _id: false })

const AccountSchema = new Schema({
	resourceTypeCode: { type: String, required: [true, "validate.require"], default: "USER" },
	resourceGroupIds: [{ type: String }],
	resourceGroups: [{ type: ResourceGroupSchema }],
	departmentId: { type: String, default: null },
	department: { type: Object, default: null },
	locationId: { type: String, default: null },

	status: { type: String, enum: [resourceStatus.ACTIVE, resourceStatus.INACTIVE], default: resourceStatus.ACTIVE },

	integrationInfo: { type: Object, default: null },

	// username: { type: String, required: [true, "validate.require"], default: null },
	username: { type: String, default: null },
	oldPassword: { type: String, default: null },
	newPassword: { type: String, default: null },
	confirmPassword: { type: String, default: null },

	code: { type: String, required: [true, "validate.require"], default: null },
	givenName: { type: String, required: [true, "validate.require"], default: null },
	sn: { type: String, default: null },
	email: { type: String, default: null },
	mobile: { type: String, default: null },

	pinCode: { type: String, default: null },

	rfId: { type: String, default: null },

	displayName: { type: String, default: null },

	id: { type: String, default: null },

	fileUpload: { type: FileSchema, default: null },

	formType: { type: String, enum: ["CHANGE_INFO", "CHANGE_PASSWORD"], default: "CHANGE_INFO" }
}, { _id: false })

setBaseSchema(AccountSchema, "05")

AccountSchema.path("oldPassword").validate(async function (oldPassword) {
	if (this.formType === "CHANGE_INFO") {
		return true
	} else if (!!oldPassword && this.formType === "CHANGE_PASSWORD") {
		return true
	} else if (this.formType === "CHANGE_PASSWORD") {
		return false
	}
	return true
}, "validate.require")

AccountSchema.path("newPassword").validate(async function (newPassword) {
	if (this.formType === "CHANGE_INFO") {
		return true
	} else if (!!newPassword && this.formType === "CHANGE_PASSWORD") {
		return true
	} else if (this.formType === "CHANGE_PASSWORD") {
		return false
	}
	return true
}, "validate.require")

AccountSchema.path("newPassword").validate(async function (newPassword) {
	let isValid = true
	if (this.formType === "CHANGE_INFO") {
		return isValid
	} else if (!!newPassword && this.formType === "CHANGE_PASSWORD") {
		if (!!this.oldPassword) {
			isValid = (newPassword !== this.oldPassword)
		}
	}

	return isValid
}, "validate.oldNewPasswordMatchError")

AccountSchema.path("confirmPassword").validate(async function (confirmPassword) {
	if (this.formType === "CHANGE_INFO") {
		return true
	} else if (!!confirmPassword && this.formType === "CHANGE_PASSWORD") {
		return true
	} else if (this.formType === "CHANGE_PASSWORD") {
		return false
	}
	return true
}, "validate.require")

AccountSchema.path("confirmPassword").validate(async function (confirmPassword) {
	let isValid = true
	if (this.formType === "CHANGE_INFO") {
		return true
	} else if (!!confirmPassword) {
		if (!!this.newPassword) {
			isValid = (confirmPassword === this.newPassword)
		}
	}

	return isValid
}, "validate.confirmNewPasswordNotMatchError")

/* AccountSchema.path("resourceGroupIds").validate(async function (resourceGroupIds) {
	return (Array.isArray(resourceGroupIds) && resourceGroupIds.length > 0)
}, "validate.require") */

AccountSchema.path("email").validate(async function (email) {
	let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

	let isValid = true
	if (email === null) {
		isValid = true
	} else if (email === "") {
		isValid = true
	}
	else if (!pattern.test(email)) {
		isValid = false
	}

	return isValid
}, "validate.invalid")

AccountSchema.methods.serializeAPI = function () {
	try {
		let result = []

		if (this.formType === "CHANGE_PASSWORD") {
			result = {
				oldPassword: this.oldPassword,
				newPassword: this.newPassword,
				confirmPassword: this.confirmPassword
			}
		} else if (this.formType === "CHANGE_INFO") {
			result = {
				givenName: this.givenName,
				sn: this.sn,
				email: this.email,
				mobile: this.mobile
			}

			if (!!this.fileUpload) {
				result.fileUpload = this.fileUpload
			}
		}

		return result

		/* 		return {
					resourceTypeCode: this.resourceTypeCode,
					resourceGroupIds: this.resourceGroupIds,
					departmentId: this.departmentId,
					locationId: this.locationId,
					code: this.code,
					username: this.code,
					password: this.code,
					confirmPassword: this.code,
		
					pinCode: this.pinCode,
					rfId: this.rfId,
					fileUpload: this.fileUpload
				} */

	} catch (error) {
		return null
	}
}

const AccountModel = mongoose.model("resource", AccountSchema, "resource")

export default AccountModel