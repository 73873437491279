
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetDepartmentList = () => {
	return {
		type: type.CORE_RESET_DEPARTMENT_LIST
	}
}

const getDepartmentInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/department/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_DEPARTMENT_REQUEST,
				{
					type: type.CORE_GET_DEPARTMENT_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_DEPARTMENT_FAILURE
			]
		}
	}
}

const getDepartmentList = (query = "") => {
	let url = `${CORE_API_ENDPOINT}/department${!!query ? query : ""}`

	return {
		[CALL_API]: {
			endpoint: url,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_DEPARTMENT_LIST_REQUEST,
				{
					type: type.CORE_GET_DEPARTMENT_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_DEPARTMENT_LIST_FAILURE
			]
		}
	}
}

export {
	getDepartmentInfo,
	getDepartmentList,
	resetDepartmentList
}