import React from 'react'
import { TimePicker } from "antd"
import "antd/dist/antd.css"
import { Form } from 'semantic-ui-react'
import '../../scss/inputTimePicker.scss'
import moment from 'moment'
import { dateTimeFormat } from '../../../../constant'

class InputTimePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }
    onChange = (time, timeString) => {
        const { name } = this.props
        this.setState({
            isOpen: false
        }, () => {
            this.props.onChange(null, { name: name, value: time })
        })
    }
    render() {
        const { format, value, minuteStep, disabled } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <div className="module inputTimePicker">
                    <div className="hide-picker">
                        <TimePicker
                            minuteStep={minuteStep}
                            open={isOpen}
                            format="HH:mm"
                            onChange={this.onChange}
                            onOpenChange={(e) => this.setState({ isOpen: false })}
                        // onPanelChange={(e, a, f) => console.log("8888", e, a, f)}
                        />
                    </div>
                    <div className={`input-display ${disabled === true ? "disabled" : ""}`} onClick={() => this.setState({ isOpen: true })}>
                        <Form.Input
                            icon={{ name: "clock outline", link: true }}
                            readOnly={true}
                            value={moment(value).format(format)}

                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

InputTimePicker.defaultProps = {
    name: "",
    value: moment(),
    minuteStep: 15,
    onChange: () => console.error("Please provide On Change action."),
    format: dateTimeFormat.displayTime,
    disabled: false
    // isActive: true,
    // isPage: true,
    // message: ""
}


export default InputTimePicker