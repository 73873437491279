
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ScheduleControlForm from './ScheduleControlForm'
import ButtonModel from '../../core/model/ButtonModel'
import ScheduleControlModel from '../../models/ScheduleControlModel'

class ScheduleControlUpdate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = nextProps.model
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onView = (model) => {
        this.props.history.push(`/schedulecontrol/view/${model.id}`)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onUpdate = () => {
        let model = this.state.model
        this.props.onUpdate(model)
    }

    onBack = () => {
        this.props.history.push(`/schedulecontrol`)
        //this.props.history.goBack()
    }

    onDeleteLocation = (index) => {
        const model = this.state.model
        if (Array.isArray(model.locations)) {
            model.locations.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitLocation = (status, location, index) => {
        const model = this.state.model

        if (!!location) {
            if (status === "CREATE") {
                model.locations.push(location)
                this.setState({
                    model: model
                })

                let modelErrors = this.state.modelErrors
                let index1 = modelErrors.findIndex(e => e.path === "locations")
                let index2 = modelErrors.findIndex(e => e.path === "resources")
                if (index > -1) {
                    modelErrors.splice(index1, 1);
                    modelErrors.splice(index2, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }
            } else if (status === "UPDATE") {
                model.locations[index] = location
                this.setState({
                    model: model
                })
            }

        }
    }

    onDeleteResource = (index) => {
        const model = this.state.model
        if (Array.isArray(model.resources)) {
            model.resources.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitResource = (status, resource, index) => {
        const model = this.state.model

        if (!!resource) {
            if (status === "CREATE") {
                model.resources.push(resource)
                this.setState({
                    model: model
                })

                let modelErrors = this.state.modelErrors
                let index1 = modelErrors.findIndex(e => e.path === "locations")
                let index2 = modelErrors.findIndex(e => e.path === "resources")
                if (index > -1) {
                    modelErrors.splice(index1, 1);
                    modelErrors.splice(index2, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }
            } else if (status === "UPDATE") {
                model.resources[index] = resource
                this.setState({
                    model: model
                })
            }

        }
    }

    handleChangeRecurrence = (e, { name, value }) => {
        let model = this.state.model

        if (name === "recurrence.type") {
            if (value === "ONETIME") {
                model.recurrence.weeklyEveryDayOfWeek = []
            } else if (value === "WEEKLY") {
                model.recurrence.startDate = moment()
            }

            model.recurrence.type = value

        } else if (name === "startDate") {
            model.recurrence.startDate = value
        } else if (name === "weeklyEveryDayOfWeek") {
            model.recurrence.weeklyEveryDayOfWeek = value
        }

        this.setState({
            model: model
        })
    }


    render() {
        try {
            const { t, optionsRecurrenceType, optionsDayOfWeek, optionsResourceList, optionsLocationList, optionsLocationTypeList, optionsHolidayTypeList, isLoadingControl } = this.props
            const { model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('scheduleControl.updateScheduleControl')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                !!model ?
                                                    <ScheduleControlForm
                                                        model={model}
                                                        handleChange={this.handleChange}
                                                        handleChangeRecurrence={this.handleChangeRecurrence}
                                                        errors={modelErrors}
                                                        optionsRecurrenceType={optionsRecurrenceType}
                                                        optionsDayOfWeek={optionsDayOfWeek}
                                                        optionsResourceList={optionsResourceList}
                                                        optionsLocationList={optionsLocationList}
                                                        optionsLocationTypeList={optionsLocationTypeList}
                                                        optionsHolidayTypeList={optionsHolidayTypeList}
                                                        onDeleteLocation={this.onDeleteLocation}
                                                        onSubmitLocation={this.onSubmitLocation}
                                                        onDeleteResource={this.onDeleteResource}
                                                        onSubmitResource={this.onSubmitResource}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ScheduleControlUpdate.defaultProps = {
    isLoadingControl: false,
    optionsRecurrenceType: [],
    optionsDayOfWeek: [],
    optionsResourceList: [],
    optionsLocationList: [],
    optionsLocationTypeList: [],
    optionsHolidayTypeList: [],
    model: new ScheduleControlModel(),
    onUpdate: () => console.error("Please provide \"Update\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ScheduleControlUpdate)