
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const sourceSchema = new Schema({
	events: [{ type: String }],
	departmentIds: [{ type: String }],
	departments: { type: Object },
	resourceGroupIds: [{ type: String }],
	resourceGroups: { type: Object },
	visitorGroupIds: [{ type: String }],
	visitorGroups: { type: Object },
	resourceIds: [{ type: String }],
	resources: { type: Object },
	locationIds: [{ type: String }],
	locations: { type: Object },
	areaResourceIds: [{ type: String }],
	areaResources: { type: Object },
	areaLocationIds: [{ type: String }],
	areaLocations: { type: Object },
}, { _id: false })

const EventControlRuleInfoSchema = new Schema({
	source: { type: sourceSchema, default: {} },
})

setBaseSchema(EventControlRuleInfoSchema, "event_control_rule_info")


const EventControlRuleInfoModel = mongoose.model("event_control_rule_info", EventControlRuleInfoSchema, "event_control_rule_info")

export default EventControlRuleInfoModel
