
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewAdminList from '../../views/admin/ViewAdminList'

import { getAdminStatusList } from '../../actions/adminActions'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
import { getDepartmentList } from '../../actions/departmentActions'
import AdminManager from '../../business/AdminManager'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'
import { getSettingInfo } from '../../actions/settingActions'

class adminListController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bAdmin = new AdminManager(this)
    }

    getPropsResetStatus() {
        return ["adminList", "resourceGroupList", "adminStatusList"]
    }

    onResetPasswordAdmin = async (model, callBack) => {
        const { t } = this.props
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAdmin.resetPasswordAdmin(model.id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => {

                    }

                if (!!res && res.result === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.resetPasswordError"),
                            content: t("message.unableToResetAdminPassword"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(tempState, action)
            })
        })
    }

    onChangeStatusAdmin = (model, callBack) => {
        const { t } = this.props
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAdmin.updateAdminStatus(model.id, model.status, (res) => {
                let body = {
                    isLoadingComponent: false,
                },
                    action = () => { }

                if (!!res && res.result === false) {
                    this.openWarningModal(
                        {
                            headerText: t("message.changeAdminStatusError"),
                            content: t("message.unableToChangeAdminStatus"),
                            buttons: [
                                new ButtonModel(
                                    {
                                        text: t("common.ok"),
                                        color: "red",
                                        onClick: () => {
                                            this.closeModal()
                                        }
                                    }
                                )
                            ]
                        }
                    )
                } else if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(body, action)
            })
        })
    }

    onDeleteAdmin = (model, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAdmin.deleteAdmin(model.id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => {

                    }

                if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(tempState, action)
            })
        })
    }

    onImportAdminFromPlatform = async (ids, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAdmin.importAdmin({ userIds: ids }, (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(body, action)
            })
        })

    }

    onImportUser = async (ids, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAdmin.importUser({ userIds: ids }, (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(body, action)
            })
        })

    }

    getAdminListPaging = (query) => {
        return this.bAdmin.getAdminListPaging(query)
    }

    render() {
        try {
            const { t, resourceGroupList, adminStatusList, departmentList, settingInfo } = this.props
            const { isLoadingComponent } = this.state

            const setting = settingInfo.getData()

            let allowResetPassword = false

            if (setting) {
                if (setting.systemNotificationInfo) {
                    if (setting.systemNotificationInfo.notification && setting.systemNotificationInfo.resetPassword.isEnable) {
                        allowResetPassword = true
                    }
                }
            }

            let optionResourceGroup = resourceGroupList.getDatasOption()
            optionResourceGroup = optionResourceGroup.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionsDepartment = departmentList.getDatasOption()
            optionsDepartment = optionsDepartment.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionAdminStatus = adminStatusList.getDatasOption()
            optionAdminStatus = optionAdminStatus.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.status.${d.value}`)
                }
            })
            optionAdminStatus = optionAdminStatus.sort((a, b) => { return a.text.localeCompare(b.text) })

            let optionAdminStatusList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionAdminStatusList = optionAdminStatusList.concat(optionAdminStatus)

            let optionsDepartmentList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionsDepartmentList = optionsDepartmentList.concat(optionsDepartment)

            let optionResourceGroupList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionResourceGroupList = optionResourceGroupList.concat(optionResourceGroup)

            return (
                <React.Fragment>
                    {super.render()}
                    {(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
                    <ViewAdminList
                        onImportAdminFromPlatform={this.onImportAdminFromPlatform}
                        onResetPasswordAdmin={this.onResetPasswordAdmin}
                        onChangeStatusAdmin={this.onChangeStatusAdmin}
                        onImportUser={this.onImportUser}
                        onDelete={this.onDeleteAdmin}
                        optionAdminStatus={optionAdminStatus}
                        optionAdminStatusList={optionAdminStatusList}
                        optionResourceGroupList={optionResourceGroupList}
                        optionsDepartmentList={optionsDepartmentList}
                        allowResetPassword={allowResetPassword}
                        onGetDataPaging={this.getAdminListPaging}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
            adminStatusList: state.adminStatusList,
            departmentList: state.departmentList,
            settingInfo: state.settingInfo,

        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getAdminStatusList() {
                dispatch(getAdminStatusList())
            },
            getDepartmentList() {
                dispatch(getDepartmentList())
            },
            getSettingInfo() {
                dispatch(getSettingInfo())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
            this.props.getAdminStatusList()
            this.props.getDepartmentList()
            this.props.getSettingInfo()
        }
    })
)


export default enhance(adminListController)