
import types from '../types'
import { requestStatus } from '../../constant'
import ResourceGroupResource from '../selectors/resourceGroupResource'
import ResourceGroupUserResource from '../selectors/resourceGroupUserResource'

const resourceGroupReducer = {
	resourceGroupInfo: (state = null, action) => {
		if (!state || !(state instanceof ResourceGroupResource)) {
			state = new ResourceGroupResource()
		}

		switch (action.type) {
			case types.CORE_GET_RESOURCE_GROUP_INFO_REQUEST:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_RESOURCE_GROUP_INFO_SUCCESS:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_RESOURCE_GROUP_INFO_FAILURE:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	resourceGroupList: (state = null, action) => {
		if (!state || !(state instanceof ResourceGroupResource)) {
			state = new ResourceGroupResource()
		}

		switch (action.type) {
			case types.CORE_GET_RESOURCE_GROUP_LIST_REQUEST:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_RESOURCE_GROUP_LIST_SUCCESS:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_RESET_RESOURCE_GROUP_LIST:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.CORE_GET_RESOURCE_GROUP_LIST_FAILURE:
				state = new ResourceGroupResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	resourceGroupUserList: (state = null, action) => {
		if (!state || !(state instanceof ResourceGroupUserResource)) {
			state = new ResourceGroupUserResource()
		}

		switch (action.type) {
			case types.CORE_GET_RESOURCE_GROUP_USER_LIST_REQUEST:
				state = new ResourceGroupUserResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_RESOURCE_GROUP_USER_LIST_SUCCESS:
				state = new ResourceGroupUserResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_RESOURCE_GROUP_USER_LIST_FAILURE:
				state = new ResourceGroupUserResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default resourceGroupReducer