
import React from 'react'
import { eventControlRuleStep } from '../../../constant'
import Step from '../../core/business/step/Step'

import ActionInformationView from '../../views/eventControlRuleStep/step/ActionInformationView'
import EventControlRuleActionModel from '../../models/eventControlRuleStep/EventControlRuleActionModel'

export default class ActionInformationStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "eventControlRule.action"
		this.key = eventControlRuleStep.ACTION_INFORMATION
		this.data = new EventControlRuleActionModel()
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data && data[this.key] instanceof EventControlRuleActionModel) {
			this.data = new EventControlRuleActionModel(data[this.key])
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult[this.key] = data
		return allResult
	}

	nextStep(data) {
		this.data = new EventControlRuleActionModel(data)
		super.nextStep()
	}

	prevStep(data) {
		this.data = new EventControlRuleActionModel(data)
		super.prevStep()
	}

	saveStep(data, callback) {
		this.data = new EventControlRuleActionModel(data)
		super.saveStep()
		if (typeof callback === "function") {
			callback(this.key)
		} else {
			console.error("Please provide Callback Submit function for Save Step.")
		}
	}

	render(dataConfig) {
		return <ActionInformationView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
			onSave={(data) => this.saveStep(data, dataConfig.onSubmit)}
		/>
	}
}