
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import SendGridDataModel from '../../../models/notificationData/SendGridDataModel'

class SendGridDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let senderNameValidate = this.getErrorInput("senderName", errors)
                , senderEmailValidate = this.getErrorInput("senderEmail", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Header as='h1' dividing className="form header">
                            {t("notification.notiSendGrid")}
                        </Header>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.email")} isRequire={true} />
                                    <Form.Input
                                        name="senderEmail"
                                        value={model.senderEmail}
                                        onChange={this.handleChange}
                                        error={senderEmailValidate.isError ? senderEmailValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.name")} isRequire={true} />
                                    <Form.Input
                                        name="senderName"
                                        value={model.senderName}
                                        onChange={this.handleChange}
                                        error={senderNameValidate.isError ? senderNameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SendGridDataForm.defaultProps = {
    model: new SendGridDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SendGridDataForm)