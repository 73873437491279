
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewCreateLocation from '../../views/location/ViewCreateLocation'
import { getLocationTypeList } from '../../actions/locationTypeActions'
import { getLocationList } from '../../actions/locationActions'
import LocationManager from '../../business/LocationManager'
import LocationModel from '../../models/LocationModel'
import utils from '../../core/utility/utils'

class CreateLocationController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bLocation = new LocationManager(this)

        this.state.filterLocationResult = []
    }

    onCreateLocation = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bLocation.createLocation(model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    let location = new LocationModel(res.data)
                    action = () => { this.props.history.replace(`/location/view/${location.id}`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    filterLocationList = (parentLocationTypeArray) => {
        const { locationList } = this.props

        let locationDatas = locationList.getDatas()

        let filterLocation = locationDatas.filter(item => parentLocationTypeArray.includes(item.locationTypeId));

        this.setState({
            filterLocationResult: filterLocation
        })
    }

    render() {
        try {
            const { locationTypeList } = this.props
            const { isLoadingComponent, filterLocationResult } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            let optionsLocationType = locationTypeList.getDatasOption()
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(filterLocationResult, "id", "parentId", null, "name", "id")

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewCreateLocation
                        isLoadingControl={isLoadingComponent}
                        optionsLocationType={optionsLocationType}
                        optionsTreeLocation={optionsTreeLocation}
                        onCreate={this.onCreateLocation}
                        filterLocationList={this.filterLocationList}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            locationTypeList: state.locationTypeList,
            locationList: state.locationList
        }),
        (dispatch, props) => ({
            getLocationTypeList() {
                dispatch(getLocationTypeList())
            },
            getLocationList() {
                dispatch(getLocationList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getLocationTypeList()
            this.props.getLocationList()
        }
    })
)


export default enhance(CreateLocationController)