
class ItemTreeSelectModel {
	constructor() {
		this.iconClass = ""
		this.iconColor = ""
		this.imageURL = ""
		this.text = ""
		this.value = ""
		this.code = ""
		this.expanded = false
		this.children = []
		this.externalProps = null

		this.parent = null
	}

	onExpand() {
		this.expanded = true
		if (!!this.parent && typeof this.parent.onExpand === "function") {
			this.parent.onExpand()
		}
	}

	onCollapse() {
		this.expanded = false
	}
}

export default ItemTreeSelectModel