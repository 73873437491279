import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
// import { Input, Form } from 'semantic-ui-react'
import { translate } from 'react-switch-lang'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, Modifier, ContentState, getDefaultKeyBinding } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import draftToHtml from 'draftjs-to-html'
import { TextArea } from 'semantic-ui-react';
import ViewButton from '../../../views/common/ViewButton';
import ButtonModel from '../../model/ButtonModel';
// import InputHtmlButtonKeyword from './InputHtmlButtonKeyword'
// import * as Icons from '../../../../dist/assets/images/icons'

const inputMode = {
    TEXT: "TEXT",
    HTML: "HTML",
}

class InputHtml extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mode: inputMode.HTML,
            editorState: this.convertContentToEditorState(props.value),
            textAreaState: props.value,
            content: props.value,
            textAreaCursorPositionStart: null,
            textAreaCursorPositionEnd: null,
        }

        this.textAreaRef = React.createRef()
    }

    convertContentToEditorState(data) {
        let htmlData = EditorState.createEmpty()
        if (data && typeof data === "string") {
            const contentBlock = htmlToDraft(data)
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            htmlData = editorState
        }

        return htmlData
    }

    sendTextToEditor(text, event) {
        const { mode, textAreaCursorPositionStart, textAreaCursorPositionEnd } = this.state

        if (mode === inputMode.HTML) {
            let editorState = this.insertText(text, this.state.editorState)

            this.onEditorStateChange(editorState)
        } else if (mode === inputMode.TEXT && (textAreaCursorPositionStart === textAreaCursorPositionEnd)) {
            const { textAreaState } = this.state

            let textBeforeCursorPosition = textAreaState.substring(0, textAreaCursorPositionStart)
            let textAfterCursorPosition = textAreaState.substring(textAreaCursorPositionEnd, textAreaState.length)

            let newText = textBeforeCursorPosition + text + textAfterCursorPosition

            if (event) {
                event.currentTarget.blur()
            }

            this.onTextAreaStateChange(null, { name: "", value: newText }, textAreaCursorPositionEnd + text.length)
        } else if (mode === inputMode.TEXT && (textAreaCursorPositionStart !== textAreaCursorPositionEnd)) {
            const { textAreaState } = this.state

            let textBeforeCursorPosition = textAreaState.substring(0, textAreaCursorPositionStart)
            let textAfterCursorPosition = textAreaState.substring(textAreaCursorPositionEnd, textAreaState.length)

            let newText = textBeforeCursorPosition + text + textAfterCursorPosition


            if (event) {
                event.currentTarget.blur()
            }

            let selectorDistance = textAreaCursorPositionEnd - textAreaCursorPositionStart

            if (newText.length > textAreaCursorPositionEnd) {
                this.onTextAreaStateChange(null, { name: "", value: newText }, textAreaCursorPositionEnd + text.length - selectorDistance)
            } else if (newText.length < textAreaCursorPositionEnd) {
                this.onTextAreaStateChange(null, { name: "", value: newText }, newText.length - selectorDistance)
            }

        }

    }

    insertText(text, editorState) {
        const currentContent = editorState.getCurrentContent(),
            currentSelection = editorState.getSelection();

        const newContent = Modifier.replaceText(
            currentContent,
            currentSelection,
            text,
        )

        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
        return EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())
    }

    onEditorStateChange = (editorState) => {
        let hasText = editorState.getCurrentContent().hasText()
            , content = hasText ? draftToHtml(convertToRaw(editorState.getCurrentContent())) : ""

        this.setState({ editorState: editorState, content: content, textAreaState: content });

        this.handleChange(content)
    }

    onTextAreaStateChange = (e, { name, value }, textLength) => {
        if (e) {
            let cursorPositionStart = e.currentTarget.selectionStart
                , cursorPositionEnd = e.currentTarget.selectionEnd

            this.setState({
                textAreaState: value,
                content: value,
                editorState: this.convertContentToEditorState(value),
                textAreaCursorPositionStart: cursorPositionStart,
                textAreaCursorPositionEnd: cursorPositionEnd,
            }, () => this.handleChangeText(value))
        } else {
            let newCursorPosition = textLength

            this.setState({
                textAreaState: value,
                content: value,
                editorState: this.convertContentToEditorState(value),
                textAreaCursorPositionStart: newCursorPosition,
                textAreaCursorPositionEnd: newCursorPosition,
            }, () => {
                this.handleChangeText(value)
                this.textAreaRef.current.ref.current.setSelectionRange(this.state.textAreaCursorPositionStart, this.state.textAreaCursorPositionEnd)
                this.textAreaRef.current.focus()
            })
        }
    }

    handleChange = (content) => {
        this.props.onChange(null, { name: this.props.name, value: !!content && content.toString().trim() !== "" && content.toString().trim() !== "<p></p>" ? content.toString().trim() : "" })
    }

    handleChangeText = (content) => {
        // console.log(content.toString().trim())
        this.props.onChange(null, { name: this.props.name, value: !!content ? content.toString().trim() : "" })
        // this.props.onChange(null, { name: this.props.name, value: !!content ? content.toString().trim().replace(/\\/g, ">") : "" })
    }

    onKeyDownSubmitForm = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    onKeyUpTextField = (event) => {
        let cursorPositionStart = event.currentTarget.selectionStart
            , cursorPositionEnd = event.currentTarget.selectionEnd

        this.setState({
            textAreaCursorPositionStart: cursorPositionStart,
            textAreaCursorPositionEnd: cursorPositionEnd
        })
    }

    getSelectPosition = (event) => {
        let cursorPositionStart = event.currentTarget.selectionStart
            , cursorPositionEnd = event.currentTarget.selectionEnd

        this.setState({
            textAreaCursorPositionStart: cursorPositionStart,
            textAreaCursorPositionEnd: cursorPositionEnd
        })
    }

    onChangeModeInput = (mode) => {
        this.setState({
            mode: mode
        })
    }

    keyBindingFn = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
        }

        return getDefaultKeyBinding(e)
    }


    render() {
        const { buttonKeyWords, isTextMode } = this.props
        const { editorState, mode, textAreaState } = this.state

        return <React.Fragment>
            {
                isTextMode === true &&
                <div style={{ marginBottom: "1rem" }}>
                    <ViewButton
                        model={new ButtonModel({
                            text: "Text Mode",
                            onClick: () => {
                                if (mode !== inputMode.TEXT) {
                                    this.onChangeModeInput(inputMode.TEXT)
                                }
                            },
                            color: (mode === inputMode.TEXT) ? "purple" : "",
                        })}
                    />
                    <ViewButton
                        model={new ButtonModel({
                            text: "HTML Mode",
                            onClick: () => {
                                if (mode !== inputMode.HTML) {
                                    this.onChangeModeInput(inputMode.HTML)
                                }
                            },
                            color: (mode === inputMode.HTML) ? "purple" : "",
                        })}
                    />
                </div>
            }
            {
                mode === inputMode.HTML &&
                <React.Fragment>
                    <Editor
                        keyBindingFn={this.keyBindingFn}
                        editorState={editorState}
                        editorStyle={{ height: "300px", overflowY: "auto" }}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link'],
                            inline: {
                                bold: {},
                                italic: {},
                                underline: {},
                                strikethrough: {},
                                monospace: {},
                                superscript: {},
                                subscript: {}
                            },
                            blockType: {},
                            fontSize: {},
                            list: {
                                unordered: {},
                                ordered: {},
                                indent: {},
                                outdent: {},
                            },
                            textAlign: {
                                left: {},
                                center: {},
                                right: {},
                                justify: {},
                            },
                            fontFamily: {},
                            colorPicker: {},
                        }}
                    />
                </React.Fragment>
            }
            {
                mode === inputMode.TEXT &&
                <TextArea
                    style={{ height: "300px", overflowY: "auto" }}
                    value={textAreaState}
                    onChange={this.onTextAreaStateChange}
                    onKeyDown={this.onKeyDownSubmitForm}
                    onKeyUp={this.onKeyUpTextField}
                    onMouseUp={this.getSelectPosition}
                    onMouseLeave={this.getSelectPosition}
                    ref={this.textAreaRef}
                />
            }
            {
                Array.isArray(buttonKeyWords) && buttonKeyWords.length > 0 &&
                <div style={{ marginTop: "1rem" }}>
                    {
                        buttonKeyWords.map((m, i) => {
                            if (!m || !m.text || !m.value) {
                                return null
                            }

                            return <ViewButton
                                style={{ marginTop: "1rem" }}
                                model={new ButtonModel({
                                    text: m.text,
                                    size: "tiny",
                                    onClick: (event) => this.sendTextToEditor(m.value, event),
                                    color: "teal",
                                })}
                            />

                        })
                    }
                </div>
            }
        </React.Fragment >
    }
}

InputHtml.defaultProps = {
    isTextMode: false,
    name: "",
    value: "",
    disabled: false,
    buttonKeyWords: [],
    onChange: () => console.error('Require func onChangeEditor')
}

export default compose(
    withRouter,
    translate
)(InputHtml)