
// export const CORE_API_ENDPOINT = 'http://127.0.0.1:4000'
// export const CORE_SERVICE_ENDPOINT = 'http://127.0.0.1:4000/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.20.4:9040'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000

// KRS .22
// export const CORE_API_ENDPOINT = 'http://10.0.30.22:8031/api'
// export const CORE_SERVICE_ENDPOINT = 'http://10.0.30.22:8031/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.30.22:8031'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000

// KBANK .22
// export const CORE_API_ENDPOINT = 'http://10.0.30.22:9031/api'
// export const CORE_SERVICE_ENDPOINT = 'http://10.0.30.22:9031/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.30.22:9031'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000

//SOR
// export const CORE_API_ENDPOINT = 'http://10.0.30.10:8221/api'
// export const CORE_SERVICE_ENDPOINT = 'http://10.0.30.10:8221/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.30.10:8221'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000 

// export const CORE_API_ENDPOINT = 'http://10.0.30.11:9031/api'
// export const CORE_SERVICE_ENDPOINT = 'http://10.0.30.11:9031/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.30.11:9031'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000

// export const CORE_API_ENDPOINT = 'http://10.0.20.4:7031/api'
// export const CORE_SERVICE_ENDPOINT = 'http://10.0.20.4:7031/service'
// export const SERVICE_SOCKET_ENDPOINT = 'http://10.0.20.4:7031'
// export const PATH_SOCKET = '/service/webapp/socket'
// export const TIME_OUT_SOCKET = 60000 

// Build prod
export const CORE_API_ENDPOINT = '/api'
export const CORE_SERVICE_ENDPOINT = '/service'
export const SERVICE_SOCKET_ENDPOINT = ''
export const PATH_SOCKET = '/service/webapp/socket'
export const TIME_OUT_SOCKET = 60000

export const MIN_EXPIRE_TOKEN = 5 //min