
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import UpdateLocationType from '../../views/locationType/ViewUpdateLocationType'
import { getLocationTypeInfo, getLocationTypeList } from '../../actions/locationTypeActions'
import utils from '../../core/utility/utils'
import LocationTypeManager from '../../business/LocationTypeManager'
import LocationTypeModel from '../../models/LocationTypeModel'

class UpdateLocationTypeController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bLocationType = new LocationTypeManager(this)
	}

	onUpdateLocationType = (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bLocationType.updateLocationType(model.id, model.serializeAPI(), (res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					let locationType = new LocationTypeModel(res.data)
					action = () => { this.props.history.replace(`/locationtype/view/${locationType.id}`) }
				}
				this.setState(tempState, action)
			})
		})
	}

	render() {
		try {
			const { locationType, locationTypeList } = this.props
			const { isLoadingComponent } = this.state

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			let locationTypeModel = locationType.getData()
				, locationTypeDatas = locationTypeList.getDatas()

			if (!!locationTypeModel) {
				let locationTypeIndex = locationTypeDatas.findIndex(e => e.id === locationTypeModel.id)
				locationTypeDatas.splice(locationTypeIndex, 1);
			}

			let optionsTreeLocationType = utils.makeGroupedArrayForTreeSelect(locationTypeDatas, "id", "parentId", null, "name", "id")

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<UpdateLocationType
						isLoadingControl={isLoadingComponent}
						locationType={locationTypeModel}
						onUpdate={this.onUpdateLocationType}
						optionsTreeLocationType={optionsTreeLocationType}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	connect(
		(state, props) => ({
			locationType: state.locationType,
			locationTypeList: state.locationTypeList
		}),
		(dispatch, props) => ({
			getLocationTypeInfo(id) {
				dispatch(getLocationTypeInfo(id))
			},
			getLocationTypeList(query) {
				dispatch(getLocationTypeList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationTypeInfo(utils.getParamFromProps(this.props, "id"))
			this.props.getLocationTypeList()
		}
	}),
)


export default enhance(UpdateLocationTypeController)