
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import MyPage from './MyPageNotification'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import NotificationForm from './NotificationForm'
import ButtonModel from '../../core/model/ButtonModel'
import NotificationModel from '../../models/NotificationModel'

class NotificationCreate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = new NotificationModel()
        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onCreate)
        }
    }

    onCreate = () => {
        this.props.onCreate(this.state.model)
    }

    onBack = () => {
        this.props.history.push(`/notification`)
        //this.props.history.goBack()
    }

    onTestNotification = () => {
        return this.props.onTestNotification(this.state.model)
    }

    render() {
        try {
            const { t, optionsNotificationService, optionsNotificationSystem, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('notification.createNotification')} buttons={buttons}>
                        <Container text>
                            <div className="body">
                                <NotificationForm
                                    model={model}
                                    optionsNotificationService={optionsNotificationService}
                                    optionsNotificationSystem={optionsNotificationSystem}
                                    handleChange={this.handleChange}
                                    handleChangeMultiValue={this.handleChangeMultiValue}
                                    errors={modelErrors}
                                    onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                    onTestNotification={this.onTestNotification}
                                />
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

NotificationCreate.defaultProps = {
    isLoadingControl: false,
    optionsNotificationService: [],
    optionsNotificationSystem: [],
    onCreate: () => console.error("Please provide \"Create Resource\" action."),
    onTestNotification: () => {
        console.error("Please provide \"Test\" Notification")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(NotificationCreate)