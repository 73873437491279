
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ScheduleControlForm from './ScheduleControlForm'
import ButtonModel from '../../core/model/ButtonModel'
import ScheduleControlModel from '../../models/ScheduleControlModel'

class ScheduleControlCreate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = new ScheduleControlModel()
        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onCreate)
        }
    }

    onCreate = () => {
        this.props.onCreate(this.state.model)
    }

    onBack = () => {
        this.props.history.push(`/schedulecontrol`)
        //this.props.history.goBack()
    }

    onDeleteLocation = (index) => {
        const model = this.state.model
        if (Array.isArray(model.locations)) {
            model.locations.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitLocation = (status, location, index) => {
        const model = this.state.model

        if (!!location) {
            if (status === "CREATE") {
                model.locations.push(location)
                this.setState({
                    model: model
                })

                let modelErrors = this.state.modelErrors
                let index1 = modelErrors.findIndex(e => e.path === "locations")
                let index2 = modelErrors.findIndex(e => e.path === "resources")
                if (index > -1) {
                    modelErrors.splice(index1, 1);
                    modelErrors.splice(index2, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }

            } else if (status === "UPDATE") {
                model.locations[index] = location
                this.setState({
                    model: model
                })
            }

        }
    }

    onDeleteResource = (index) => {
        const model = this.state.model
        if (Array.isArray(model.resources)) {
            model.resources.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitResource = (status, resource, index) => {
        const model = this.state.model

        if (!!resource) {
            if (status === "CREATE") {
                model.resources.push(resource)
                this.setState({
                    model: model
                })

                let modelErrors = this.state.modelErrors
                let index1 = modelErrors.findIndex(e => e.path === "locations")
                let index2 = modelErrors.findIndex(e => e.path === "resources")
                if (index > -1) {
                    modelErrors.splice(index1, 1);
                    modelErrors.splice(index2, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }
            } else if (status === "UPDATE") {
                model.resources[index] = resource
                this.setState({
                    model: model
                })
            }

        }
    }

    handleChangeRecurrence = (e, { name, value }) => {
        let model = this.state.model

        if (name === "recurrence.type") {
            if (value === "ONETIME") {
                model.recurrence.weeklyEveryDayOfWeek = []
            } else if (value === "WEEKLY") {
                model.recurrence.startDate = moment()
            }

            model.recurrence.type = value

        } else if (name === "startDate") {
            model.recurrence.startDate = value
        } else if (name === "weeklyEveryDayOfWeek") {
            model.recurrence.weeklyEveryDayOfWeek = value
        }

        this.setState({
            model: model
        })
    }

    render() {
        try {
            const { t, optionsRecurrenceType, optionsDayOfWeek, optionsResourceList, optionsLocationList, optionsLocationTypeList, optionsHolidayTypeList, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('scheduleControl.createScheduleControl')} buttons={buttons}>
                        <Container text>
                            <div className="body">
                                {
                                    !!model ?
                                        <ScheduleControlForm
                                            model={model}
                                            handleChange={this.handleChange}
                                            handleChangeRecurrence={this.handleChangeRecurrence}
                                            errors={modelErrors}
                                            optionsRecurrenceType={optionsRecurrenceType}
                                            optionsDayOfWeek={optionsDayOfWeek}
                                            optionsResourceList={optionsResourceList}
                                            optionsLocationList={optionsLocationList}
                                            optionsLocationTypeList={optionsLocationTypeList}
                                            optionsHolidayTypeList={optionsHolidayTypeList}
                                            onDeleteLocation={this.onDeleteLocation}
                                            onSubmitLocation={this.onSubmitLocation}
                                            onDeleteResource={this.onDeleteResource}
                                            onSubmitResource={this.onSubmitResource}
                                            onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                        />
                                        : <p>{t("common.noData")}</p>
                                }
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ScheduleControlCreate.defaultProps = {
    isLoadingControl: false,
    optionsRecurrenceType: [],
    optionsDayOfWeek: [],
    optionsResourceList: [],
    optionsLocationList: [],
    optionsLocationTypeList: [],
    optionsHolidayTypeList: [],
    onCreate: () => console.error("Please provide \"Create Resource\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ScheduleControlCreate)