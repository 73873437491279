
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import RoomManager from '../../business/RoomManager'
import RoomList from '../../views/room/RoomList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class RoomController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRoom = new RoomManager(this)
	}

	getPropsResetStatus() {
		return ["roomList", "locationList"]
	}

	onDeleteRoom = async (model, callBack) => {

		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.deleteRoom(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableRoom = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.enableRoomStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableRoom = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.disableRoomStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onSyncRoom = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.syncRoom((res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(tempState, action)
			})
		})
	}

	onImportRoomFromPlatform = async (ids, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRoom.importRoom({ roomIds: ids }, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})

	}

	getRoomListPaging = (query) => {
		return this.bRoom.getRoomListPaging(query)
	}

	render() {
		try {
			const { t, locationList } = this.props
			const { isLoadingComponent } = this.state

			const optionLocation = locationList.getDatasOption()
			let optionLocationList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionLocationList = optionLocationList.concat(optionLocation)
			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<RoomList
						optionLocationList={optionLocationList}
						onDeleteRoom={this.onDeleteRoom}
						onChangeEnableRoom={this.onChangeEnableRoom}
						onChangeDisableRoom={this.onChangeDisableRoom}
						onSyncRoom={this.onSyncRoom}
						onImportRoomFromPlatform={this.onImportRoomFromPlatform}
						onGetDataPaging={this.getRoomListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getLocationList() {
				dispatch(getLocationList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
		}
	}),
)


export default enhance(RoomController)