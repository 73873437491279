
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getRFIDCardInfo, getRFIDCardTypeList, getRFIDCardDataTypeList } from '../../actions/rfidCardActions'
import RFIDCardManager from '../../business/RFIDCardManager'
import utils from '../../core/utility/utils'
import RFIDCardUpdate from '../../views/rfidCard/RFIDCardUpdate'

class UpdateRFIDCardController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRFIDCard = new RFIDCardManager(this)
	}

	getPropsResetStatus() {
		return ["rfidCardInfo"]
	}

	onUpdateRFIDCard = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRFIDCard.updateRFIDCard(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/rfidcard/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { rfidCardInfo, rfidCardTypeList, rfidCardDataTypeList } = this.props
			const { isLoadingComponent } = this.state

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const rfidCard = rfidCardInfo.getData()
				, optionsRFIDCardType = rfidCardTypeList.getDatasOption()
				, optionsRFIDCardDataType = rfidCardDataTypeList.getDatasOption()

			return (
				<React.Fragment>
					{super.render()}
					<RFIDCardUpdate
						isLoadingControl={isLoadingComponent}
						model={rfidCard}
						optionsRFIDCardType={optionsRFIDCardType}
						optionsRFIDCardDataType={optionsRFIDCardDataType}
						onUpdate={this.onUpdateRFIDCard}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			rfidCardTypeList: state.rfidCardTypeList,
			rfidCardDataTypeList: state.rfidCardDataTypeList,
			rfidCardInfo: state.rfidCardInfo
		}),
		(dispatch, props) => ({
			getRFIDCardTypeList() {
				dispatch(getRFIDCardTypeList())
			},
			getRFIDCardDataTypeList() {
				dispatch(getRFIDCardDataTypeList())
			},
			getRFIDCardInfo() {
				dispatch(getRFIDCardInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getRFIDCardTypeList()
			this.props.getRFIDCardDataTypeList()
			this.props.getRFIDCardInfo()
		}
	}),
)


export default enhance(UpdateRFIDCardController)