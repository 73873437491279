
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const SendGridDataSchema = new Schema({
	token: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(SendGridDataSchema, "system_integration_send_grid")

const SendGridDataModel = mongoose.model("system_integration_send_grid", SendGridDataSchema, "system_integration_send_grid")

export default SendGridDataModel