
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const ResourceGroupSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },
	resourceControls: [{ type: String }],
	locationControls: [{ type: String }],
	sensorRuleControls: [{ type: String }]
}, { _id: false })

setBaseSchema(ResourceGroupSchema, "06")

const ResourceGroupModel = mongoose.model("resourcegroup", ResourceGroupSchema, "resourcegroup")

export default ResourceGroupModel