import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import App from './App'
// import ErrorBoundary from './common/ErrorBoundary'
import { configureStore } from '../store'
import { setTranslations, setDefaultLanguage, setLanguageCookie, getLanguage, } from 'react-switch-lang'
import { languageKey, cookiesKey } from '../../constant'

import en from '../wording/en'
import th from '../wording/th'

const store = configureStore()

setTranslations({ en, th });
setLanguageCookie(cookiesKey.LANGUAGE, { maxAge: 86400 }, undefined)
const language = getLanguage()
if (!language && !(language === languageKey.th || language === languageKey.en)) {
    setDefaultLanguage(languageKey.en);
}

const Root = () => {

    return <Provider store={store}>
        {/* <ErrorBoundary> */}
        <Router>
            <App />
        </Router>
        {/* </ErrorBoundary> */}
    </Provider>
}

export default Root