import types from '../types'
import { requestStatus } from '../../constant'
import VisitorResource from '../selectors/visitorResource'
import visitorStatusResource from '../selectors/visitorStatusResource'

const visiorGroupReducer = {
    visitorInfo: (state = null, action) =>
    {
        if(!state || !(state instanceof VisitorResource))
        {
            state = new VisitorResource()
        }

        switch(action.type)
        {
            case types.GET_VISITOR_INFO_REQUEST:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            
            case types.GET_VISITOR_INFO_SUCCESS:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.GET_VISITOR_INFO_FAILURE:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            
                default:
                    break
        }
        return state
    },

    visitorList: (state = null, action) =>
    {
        if(!state || !(state instanceof VisitorResource))
        {
            state = new VisitorResource()
        }

        switch(action.type)
        {
            case types.GET_VISITOR_LIST_REQUEST:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break

            case types.GET_VISITOR_LIST_SUCCESS:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.RESET_VISITOR_LIST:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(null)
                break
                
            case types.GET_VISITOR_LIST_FAILURE:
                state = new VisitorResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break

                default:
                    break

        }
        return state
    },

    visitorStatusList: (state = null, action) =>
    {
        if(!state || !(state instanceof visitorStatusResource))
        {
            state = new visitorStatusResource()
        }

        switch(action.type)
        {
            case types.GET_VISITOR_STATUS_LIST_REQUEST:
                state = new visitorStatusResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break

            case types.GET_VISITOR_STATUS_LIST_SUCCESS:
                state = new visitorStatusResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break

            case types.GET_VISITOR_STATUS_LIST_FAILURE:
                state = new visitorStatusResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break

                default:
                    break

        }
        return state
    }
}

export default visiorGroupReducer

