
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ViewCreateLocationType from '../../views/locationType/ViewCreateLocationType'
import LocationTypeManager from '../../business/LocationTypeManager'
import { getLocationTypeInfo, getLocationTypeList } from '../../actions/locationTypeActions'
import LocationModel from '../../models/LocationModel'
import utils from '../../core/utility/utils'

class CreateLocationTypeController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bLocation = new LocationTypeManager(this)
	}

	onCreateLocationType = (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bLocation.createLocationType(model.serializeAPI(), (res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					let locationType = new LocationModel(res.data)
					action = () => { this.props.history.replace(`/locationtype/view/${locationType.id}`) }
				}
				this.setState(tempState, action)
			})
		})
	}

	render() {
		try {
			const { isLoadingComponent, locationType } = this.state
			const { locationTypeList } = this.props

			const locationTypeDatas = locationTypeList.getDatas()
				, optionsTreeLocationType = utils.makeGroupedArrayForTreeSelect(locationTypeDatas, "id", "parentId", null, "name", "id")

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<ViewCreateLocationType
						isLoadingControl={isLoadingComponent}
						parent={locationType}
						onCreate={this.onCreateLocationType}
						optionsTreeLocationType={optionsTreeLocationType}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}
}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationTypeList: state.locationTypeList
		}),
		(dispatch, props) => ({
			getLocationTypeInfo(id) {
				dispatch(getLocationTypeInfo(id))
			},
			getLocationTypeList(query) {
				dispatch(getLocationTypeList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationTypeList()
		}
	}),
)


export default enhance(CreateLocationTypeController)