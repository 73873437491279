
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid, Step } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import StepList from '../../core/business/step/StepList'
import EventControlRuleInfoStep from '../../business/eventControlRuleStep/EventControlRuleInfoStep'
import EventControlRuleSourceStep from '../../business/eventControlRuleStep/EventControlRuleSourceStep'
import ConditionStep from '../../business/eventControlRuleStep/ConditionStep'
import ActionInformationStep from '../../business/eventControlRuleStep/ActionInformationStep'
import { eventControlRuleStep } from '../../../constant'
import EventControlRuleModel from '../../models/EventControlRuleModel'
import EventControlRuleInfoModel from '../../models/eventControlRuleStep/EventControlRuleInfoModel'
import EventControlRuleSourceModel from '../../models/eventControlRuleStep/EventControlRuleSourceModel'
import EventControlRuleConditionModel from '../../models/eventControlRuleStep/EventControlRuleConditionModel'
import EventControlRuleActionModel from '../../models/eventControlRuleStep/EventControlRuleActionModel'

class ViewEventControlRuleUpdate extends MyPage {
    constructor(props) {
        super(props)
        this.stepList = new StepList(this)

        this.state.currentStep = null

        this.state.isLoading = false
        this.state.registerFail = null
        this.state.modalCustom = null
        this.state.model = null
        this.state.id = this.getParamFromProps("id")
        // this.initDataStep(props.model)
        // this.initStep()
    }

    componentDidMount() {
        this.onGetData()
    }

    onGetData = () => {
        this.setState({
            isLoading: true,
        }, async () => {
            let tempState = {
                isLoading: false
            },
                action = () => { }

            let res = await this.props.onGetEventControlRuleInfo(this.state.id)
            if (!!res && res.result === true && !!res.data) {
                tempState.model = res.data
                action = async () => {
                    await this.initDataStep(res.data)
                    await this.initStep()
                }
            }
            this.setState(tempState, action)
        })
    }

    initDataStep = (data) => {
        if (!!data) {
            if (!this.stepList.data) {
                this.stepList.data = {}
            }

            //console.log("data: ", data)
            this.stepList.data[eventControlRuleStep.EVENT_INFORMATION] = new EventControlRuleInfoModel({
                code: data.code,
                name: data.name,
                description: data.description,
            })

            this.stepList.data[eventControlRuleStep.SOURCE] = new EventControlRuleSourceModel({
                source: data.source,
            })

            this.stepList.data[eventControlRuleStep.CONDITION] = new EventControlRuleConditionModel({
                condition: data.condition
            })

            this.stepList.data[eventControlRuleStep.ACTION_INFORMATION] = new EventControlRuleActionModel({ actions: data.actions })
        }
    }

    initStep() {
        this.stepList.addStep(new EventControlRuleInfoStep(this.stepList))
        this.stepList.addStep(new EventControlRuleSourceStep(this.stepList))
        this.stepList.addStep(new ConditionStep(this.stepList))
        this.stepList.addStep(new ActionInformationStep(this.stepList))

        let getStep = this.getQueryFromProps("step")

        if (getStep) {
            this.setState({
                currentStep: this.stepList.root
            }, () => { this.stepList.gotoStep(getStep) })
        } else {
            this.setState({
                currentStep: this.stepList.root
            })
        }
    }

    changeCurrentStep = (step) => {
        this.setState({ currentStep: step })
    }

    onClickHeaderStep = (stepId) => {
        this.stepList.gotoStep(stepId)
    }

    submit = (key) => {
        const { model } = this.state
        let data = this.stepList.data

        if (!!data) {
            let informationModel = data[eventControlRuleStep.EVENT_INFORMATION]
                , sourceModel = data[eventControlRuleStep.SOURCE]
                , conditionModel = data[eventControlRuleStep.CONDITION]
                , actionInformationModel = data[eventControlRuleStep.ACTION_INFORMATION]

            let updateModel = new EventControlRuleModel({
                id: model.id,

                code: informationModel.code,
                name: informationModel.name,
                description: informationModel.description,
                source: sourceModel.source,

                actions: actionInformationModel.actions,

                condition: conditionModel.condition,
            })

            this.props.onSubmit(updateModel, key)
        }
    }

    back() {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsEventList, optionsTreeLocation, optionsResourceGroupList, optionsVisitorGroupList, optionsHolidayTypeList, optionsRecurrenceTypeList, optionsDayOfWeekList, optionsActionTypeList, optionsActionStatusList, optionsResources, optionsResourceType, onGetDataList } = this.props
            const { currentStep, isLoading, modalCustom, model } = this.state
            let buttons = []

            let activeKey = null
            if (!!currentStep) {
                activeKey = currentStep.key
            }

            let dataConfig = {
                optionsEventList: optionsEventList,
                optionsTreeLocation: optionsTreeLocation,
                optionsResourceGroupList: optionsResourceGroupList,
                optionsVisitorGroupList: optionsVisitorGroupList,
                optionsHolidayTypeList: optionsHolidayTypeList,
                optionsRecurrenceTypeList: optionsRecurrenceTypeList,
                optionsDayOfWeekList: optionsDayOfWeekList,
                optionsActionTypeList: optionsActionTypeList,
                optionsActionStatusList: optionsActionStatusList,
                optionsResources: optionsResources,
                optionsResourceType: optionsResourceType,
                onGetDataList: onGetDataList,
                stepStatus: "UPDATE",
                editID: (!!model && !!model.id) ? model.id : "",
                onSubmit: this.submit,
            }

            let dataStepList = this.stepList.data
                , stepList = this.stepList.getNavigator()

            //console.info("Data Step List", dataStepList)
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('eventControlRule.updateEventControlRule')} buttons={buttons} >
                        <div className="module viewGreetingUpdate">
                            <Container text>
                                <Grid>
                                    {
                                        !!model &&
                                        <React.Fragment>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Step.Group fluid ordered className="step-group-custom">
                                                        {
                                                            Array.isArray(stepList) &&
                                                            <React.Fragment>
                                                                {
                                                                    stepList.map((step, i) => {
                                                                        let isCompleted = (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key])
                                                                            , isActive = (activeKey === step.key)
                                                                            , onClick = null
                                                                        if (isCompleted && !isActive) {
                                                                            onClick = () => {
                                                                                this.onClickHeaderStep(step.id)
                                                                            }
                                                                        }
                                                                        return <Step key={i} active={isActive} completed={isCompleted}
                                                                            onClick={onClick}>
                                                                            <Step.Content>
                                                                                <Step.Title>{t(step.title)}</Step.Title>
                                                                            </Step.Content>
                                                                        </Step>
                                                                    })
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </Step.Group>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    {currentStep && currentStep.render(dataConfig)}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewEventControlRuleUpdate.defaultProps = {
    model: null,
    optionsEventList: [],
    optionsTreeLocation: [],
    optionsResourceGroupList: [],
    optionsVisitorGroupList: [],
    optionsHolidayTypeList: [],
    optionsRecurrenceTypeList: [],
    optionsDayOfWeekList: [],
    optionsActionTypeList: [],
    optionsActionStatusList: [],
    optionsResources: [],
    optionsResourceType: [],
    onGetDataList: () => console.error("Please provide get Resource Data List function"),
    onSubmit: () => console.error("Please provide Update Sensor Rule action."),
    onGetEventControlRuleInfo: () => {
        console.error("Please provide Get Sensor Rule Info action.")
        return null
    }
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewEventControlRuleUpdate)