
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'

import VisitorList from '../../views/visitor/VisitorList'

import { getVisitorStatusList } from '../../actions/visitorActions'
import { getVisitorGroupList } from '../../actions/visitorGroupActions'

import VisitorManager from '../../business/VisitorManager'
import { defaultValue } from '../../../constant'

class VisitorController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bVisitor = new VisitorManager(this)
    }

    getPropsResetStatus() {
        return ["visitorStatusList", "visitorGroupList"]
    }

    getVisitorListPaging = (query) => {
        return this.bVisitor.getVisitorListPaging(query)
    }

    render() {
        try {
            const { t, visitorStatusList, visitorGroupList } = this.props
            const { isLoadingComponent } = this.state

            const optionVisitorStatus = visitorStatusList.getDatasOption()
                , optionVisitorGroup = visitorGroupList.getDatasOption()

            let optionVisitorStatusList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionVisitorStatusList = optionVisitorStatusList.concat(optionVisitorStatus)

            optionVisitorStatusList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                },
                {
                    key: "ACTIVE",
                    value: "ACTIVE",
                    text: "ACTIVE"
                },
                {
                    key: "INACTIVE",
                    value: "INACTIVE",
                    text: "INACTIVE"
                },
                {
                    key: "LOCKED",
                    value: "LOCKED",
                    text: "LOCKED"
                },
                {
                    key: "DISABLED",
                    value: "DISABLED",
                    text: "DISABLED"
                },
            ]

            let optionVisitorGroupList = [
                {
                    key: defaultValue.SELECT_ALL,
                    value: defaultValue.SELECT_ALL,
                    text: t("common.selectAll")
                }
            ]
            optionVisitorGroupList = optionVisitorGroupList.concat(optionVisitorGroup)

            return (
                <React.Fragment>
                    {super.render()}
                    {(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
                    <VisitorList
                        optionVisitorStatus={optionVisitorStatus}
                        optionVisitorStatusList={optionVisitorStatusList}
                        optionVisitorGroupList={optionVisitorGroupList}

                        onGetDataPaging={this.getVisitorListPaging}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

const enhance = compose(
    translate,
    connect(
        (state) => ({
            // visitorList: state.visitorList,
            visitorStatusList: state.visitorStatusList,
            visitorGroupList: state.visitorGroupList
        }),
        (dispatch) => ({
            // getVisitorList(query) {
            //     dispatch(getVisitorList(query))
            // },
            getVisitorStatusList() {
                dispatch(getVisitorStatusList())
            },
            getVisitorGroupList(query) {
                dispatch(getVisitorGroupList(query))
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            // this.props.getVisitorList()
            this.props.getVisitorStatusList()
            this.props.getVisitorGroupList()
        }
    })
)

export default enhance(VisitorController)