
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'

import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchHoliday from './FormSearchHoliday'
import HolidayModel from '../../models/HolidayModel'
import { defaultValue, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class HolidayList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.isLoadingTable = false
        this.state.modelSearch = new HolidayModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    onClickDelete = (model) => {
        const { t, onDeleteHoliday } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onDeleteHoliday(model, () => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.year"),
                selector: (row) => row.year ? row.year : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.subject"),
                selector: (row) => row.subject ? row.subject : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false,
                maxWidth: '20vw',
            },
            {
                name: '',
                minWidth: 'fit-content',
                //width: '200px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/holiday/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/holiday/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onClickAdd = () => {
        this.props.history.push(`/holiday/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearch = (model) => {
        this.setState({
            modelSearch: model
        }, () => {
            if (!!model) {
                let year = ""
                if (!!model.year && model.year !== defaultValue.SELECT_ALL) {
                    year = model.year
                }

                let querys = [
                    {
                        name: "year",
                        value: year
                    },
                    {
                        name: "subject",
                        value: model.subject,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                this.props.onReloadData(utils.generateSearchQuery(querys))
            } else {
                this.props.onReloadData("")
            }
        })
    }

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }


    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let year = ""
                if (!!model.year && model.year !== defaultValue.SELECT_ALL) {
                    year = model.year
                }

                let querys = [
                    {
                        name: "year",
                        value: year
                    },
                    {
                        name: "subject",
                        value: model.subject,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "description",
                        value: model.description,
                        queryType: searchQueryType.LIKE
                    }
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }

    render() {
        try {
            const { t, optionsYear } = this.props
            const { isSearch, isLoading, pagingDataModel, isLoadingTable } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('holiday.title')}>
                        <Container>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="left">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch}>
                                                <FormSearchHoliday onSearch={this.onSearchByForm}
                                                    optionsYear={optionsYear} />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

HolidayList.defaultProps = {
    optionsYear: [],
    onDeleteHoliday: () => console.error("Please provide \"Delete Resource\" action."),
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    },
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(HolidayList)