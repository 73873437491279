
// import { CALL_API } from '../middlewares'
// import { CORE_API_ENDPOINT } from '../lib'
// import type from '../types'

const resetError = () => {
	return {
		type: "RESETERROR"
	}
}

export {
	resetError
}