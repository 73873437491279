
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import RFIDCardManager from '../../business/RFIDCardManager'
import RFIDCardList from '../../views/rfidCard/RFIDCardList'
import { defaultValue } from '../../../constant'
import { getRFIDCardTypeList } from '../../actions/rfidCardActions' // getRFIDCardDataTypeList

class RFIDCardController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRFIDCard = new RFIDCardManager(this)
	}

	getPropsChangeStatusSuccess() {
		return ["rfidCardList"]
	}

	onDeleteRFIDCard = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRFIDCard.deleteRFIDCard(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}

				this.setState(body, action)
			})
		})
	}

	onSyncRFIDCard = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRFIDCard.syncRFIDCard((res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getRFIDCardListPaging = (query) => {
		return this.bRFIDCard.getRFIDCardListPaging(query)
	}

	render() {
		try {
			const { t, rfidCardTypeList } = this.props // rfidCardDataTypeList
			const { isLoadingComponent } = this.state

			const optionsRFIDCardType = rfidCardTypeList.getDatasOption()
			//, optionsRFIDCardDataType = rfidCardDataTypeList.getDatasOption()

			let optionsRFIDCardTypeList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionsRFIDCardTypeList = optionsRFIDCardTypeList.concat(optionsRFIDCardType)

			/* 			let optionsRFIDCardDataTypeList = [
							{
								key: defaultValue.SELECT_ALL,
								value: defaultValue.SELECT_ALL,
								text: t("common.selectAll")
							}
						]
						optionsRFIDCardDataTypeList = optionsRFIDCardDataTypeList.concat(optionsRFIDCardDataType) */

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<RFIDCardList
						optionsRFIDCardTypeList={optionsRFIDCardTypeList}
						onDeleteRFIDCard={this.onDeleteRFIDCard}
						onGetDataPaging={this.getRFIDCardListPaging}
						onSyncRFIDCard={this.onSyncRFIDCard}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			rfidCardTypeList: state.rfidCardTypeList,
			//rfidCardDataTypeList: state.rfidCardDataTypeList,
		}),
		(dispatch) => ({
			getRFIDCardTypeList() {
				dispatch(getRFIDCardTypeList())
			},
			/* 	getRFIDCardDataTypeList() {
					dispatch(getRFIDCardDataTypeList())
				}, */
		})
	),
	lifecycle({
		componentDidMount() {
			this.props.getRFIDCardTypeList()
			//this.props.getRFIDCardDataTypeList()
		}
	}),
)


export default enhance(RFIDCardController) 