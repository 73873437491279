
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import SMTPDataModel from '../../../models/systemIntegrationData/SMTPDataModel'

class SMTPDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let hostValidate = this.getErrorInput("host", errors)
                , portValidate = this.getErrorInput("port", errors)
                , usernameValidate = this.getErrorInput("username", errors)
                , passwordValidate = this.getErrorInput("password", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.smtp")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.domain")} isRequire={true} />
                                <Form.Input
                                    name="host"
                                    value={model.host}
                                    onChange={this.handleChange}
                                    error={hostValidate.isError ? hostValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.port")} isRequire={true} />
                                <Form.Input
                                    name="port"
                                    value={model.port}
                                    onChange={this.handleChange}
                                    error={portValidate.isError ? portValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.username")} isRequire={true} />
                                <Form.Input
                                    name="username"
                                    value={model.username}
                                    onChange={this.handleChange}
                                    error={usernameValidate.isError ? usernameValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.password")} isRequire={true} />
                                <Form.Input
                                    name="password"
                                    value={model.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    error={passwordValidate.isError ? passwordValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.enableCredential")} isRequire={true} />
                                <Form.Checkbox
                                    name="enablecredential"
                                    checked={model.enablecredential}
                                    onChange={this.handleChangeBoolean}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SMTPDataForm.defaultProps = {
    model: new SMTPDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SMTPDataForm)