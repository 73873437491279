
import ActionResource from './ActionResource'

class ActionResourceObject extends ActionResource {

    getData(criteria = null) {
        let result = null

        if (Array.isArray(this.value)) {
            if (typeof criteria === "function") {
                result = this.value.find(criteria)
            } else if (this.value.length > 0) {
                result = this.value[0]
            }
        } else {
            result = this.value
        }

        return this.setResource(result)
    }

    getDatas() {
        let result = []
        if (Array.isArray(this.value)) {
            result = this.value
        } else if (this.value) {
            result = [this.value]
        }

        return result.map(r => this.setResource(r))
    }

    findDataByKey(key = "", value = "") {
        try {
            let datas = this.getDatas()
                , data = null
            if (Array.isArray(datas) && datas.length > 0 && !!key) {
                const tempData = datas.find(d => (key in d && d[key] === value))
                if (!!tempData) {
                    data = tempData
                }
            }
            return data
        } catch (error) {
            return null
        }
    }

    getDatasOption() {
        let result = this.getDatas()
        return result.map(r => this.setResourceOption(r))
    }

}

export default ActionResourceObject