
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import HolidayForm from './HolidayForm'
import ButtonModel from '../../core/model/ButtonModel'
import HolidayModel from '../../models/HolidayModel'

class HolidayCreate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = new HolidayModel()
        this.state.modelErrors = []
        this.state.isLoading = false

        this.state.model.year = moment().format("YYYY")

    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onCreate)
        }
    }

    onCreate = () => {
        this.props.onCreate(this.state.model)
    }

    onBack = () => {
        this.props.history.push(`/holiday`)
        //this.props.history.goBack()
    }

    onDeleteDate = (index) => {
        const model = this.state.model
        if (Array.isArray(model.dateInfo)) {
            model.dateInfo.splice(index, 1)
            this.setState({
                model: model
            })
        }
    }

    onSubmitDate = (dateInfo, status, index) => {
        const model = this.state.model
        const modelErrors = this.state.modelErrors

        if (!!dateInfo) {
            if (status === "CREATE") {
                model.dateInfo.push(dateInfo)
            } else if (status === "UPDATE") {
                model.dateInfo[index] = dateInfo
            }

            if (Array.isArray(modelErrors) && modelErrors.length > 0) {
                let newModelErrors = {}
                let index = modelErrors.findIndex(e => e.path === "dateInfo")
                if (index > -1) {
                    modelErrors.splice(index, 1);
                    newModelErrors = modelErrors
                }

                this.setState({
                    model: model,
                    modelErrors: newModelErrors
                })
            } else {
                this.setState({
                    model: model
                })
            }

        }
    }

    render() {
        try {
            const { t, optionsYear, optionsWeekday, optionsDateType, isLoadingControl } = this.props
            const { model, modelErrors, isLoading } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('holiday.createHoliday')} buttons={buttons}>
                        <Container text>
                            <div className="body">
                                {
                                    !!model ?
                                        <HolidayForm
                                            model={model}
                                            handleChange={this.handleChange}
                                            errors={modelErrors}
                                            optionsYear={optionsYear}
                                            optionsWeekday={optionsWeekday}
                                            optionsDateType={optionsDateType}
                                            onDeleteDate={this.onDeleteDate}
                                            onSubmitDate={this.onSubmitDate}
                                            onSubmitForm={() => this.onValidate(() => this.onCreate(model))}
                                        />
                                        : <p>{t("common.noData")}</p>
                                }
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

HolidayCreate.defaultProps = {
    isLoadingControl: false,
    optionsYear: [],
    optionsWeekday: [],
    optionsDateType: [],
    onCreate: () => console.error("Please provide \"Create Resource\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(HolidayCreate)