
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ViewHome from '../../views/home/ViewHome'

class HomeController extends MyController {

	render() {
		try {

			return (
				<React.Fragment>
					{super.render()}
					<ViewHome />

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
)


export default enhance(HomeController)