
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'
import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import ViewTable from '../../core/view/common/table/ViewTable'
import ModalResourcesECR from './ModalResourcesECR'
import ModalLocationECR from './ModalLocationECR'
import { eventControlRuleAction } from '../../../constant'

class ModalResources extends MyForm {

    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.modalStatus = "CREATE"
        this.state.model = {
            type: "",
            status: "OFF",
            datas: []
        }

        if (props.model) {
            this.state.modalStatus = "UPDATE"
            this.state.model = props.model
        }

        this.state.isLoading = false

        this.state.isOpenModalResources = false
        this.state.resourceModel = null
        this.state.resourceIndex = null
        this.state.resourceStatus = null

        this.state.isOpenLocationModal = false
        this.state.locationModel = null
        this.state.locationIndex = null
        this.state.locationStatus = null
    }

    onSubmit = () => {
        const { model } = this.state

        let submitModel = model
        submitModel.datas = model.datas

        this.props.onSubmit(submitModel)

    }

    onOpenModalResource = (status, model, index) => {
        this.setState({
            isOpenModalResources: true,
            resourceModel: model,
            resourceStatus: status,
            resourceIndex: index,
        })
    }

    onCloseModalResource = () => {
        this.setState({
            isOpenModalResources: false,
            resourceFieldName: ""
        })
    }

    onSubmitModelResource = (resourceInfo) => {
        const { model } = this.state
        const { resourceStatus, resourceIndex } = this.state
        let currentResourceList = []

        if (resourceStatus === "CREATE") {
            currentResourceList = model.datas.map(resource => resource)

            let resourceType = resourceInfo.type
            let resourceIds = resourceInfo.id.map(data => {
                return data
            })
            let resourcesInfomation = resourceInfo.resources.map(data => {
                return {
                    name: data.name,
                    code: data.code,
                }
            })

            if (Array.isArray(resourceIds) && resourceIds.length > 0) {
                let idLength = resourceIds.length

                for (let i = 0; i < idLength; i++) {
                    if (!!currentResourceList.find(resource => resource.id === resourceIds[i]) === false) {
                        let resourceRawData = {
                            type: resourceType,
                            id: resourceIds[i],
                            name: resourcesInfomation[i].name,
                            code: resourcesInfomation[i].code,
                        }
                        currentResourceList.push(resourceRawData)
                    }
                }
            }

        } else if (resourceStatus === "UPDATE") {
            currentResourceList = model.datas.map(resource => resource)

            currentResourceList[resourceIndex] = resourceInfo
        }

        this.setState({
            isOpenModalResources: false
        }, () => {
            this.handleChange(null, { name: "datas", value: currentResourceList })
        })
    }

    onRemoveModelResource = (index) => {
        const { model } = this.state

        if (Array.isArray(model.datas) && model.datas.length > 0) {
            let currentResourceList = model.datas.map(t => t)

            if (index > -1) {
                currentResourceList.splice(index, 1)
            }
            this.handleChange(null, { name: "datas", value: currentResourceList })
        }
    }

    getColumnResourceConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.type ? row.type : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("eventControlRule.resource"),
                selector: (row) => {
                    /*    if (Array.isArray(row.resources) && row.resources.length > 0) {
                           return <div>
                               {
                                   row.resources.map(resource => {
                                       return <p>• {resource.name}</p>
                                   })
                               }
                           </div>
                       } */

                    return row.name ? row.name : '-'
                },
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*         {
                                    text: "",
                                    title: t("common.edit"),
                                    color: "blue",
                                    onClick: () => { this.onOpenModalResource("UPDATE", row, i, "source.resources") },
                                    iconClass: "edit",
                                    size: "mini"
                                }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onRemoveModelResource(i, "source.resources") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onHandleChangeType = (e, { name, value }) => {
        this.handleChange(null, { name: name, value: value })
        this.handleChange(null, { name: "datas", value: [] })

    }

    onOpenLocation = (status, model, index) => {
        this.setState({
            isOpenLocationModal: true,
            locationModel: model,
            locationIndex: index,
            locationStatus: status,
        })
    }

    onCloseLocation = () => {
        this.setState({
            isOpenLocationModal: false
        })
    }

    onSubmitModalLocation = (locationInfo) => {
        const { model, locationIndex, locationStatus } = this.state

        let currentLocationList = []

        currentLocationList = !!model.datas ? model.datas : []

        if (locationStatus === "CREATE") {
            // let rawLocationInfo = {
            //     id: locationInfo.value,
            //     //code: locationInfo.code,
            //     name: locationInfo.text
            // }

            locationInfo.forEach(data => {
                let findValue = currentLocationList.findIndex(location => {
                    return location.id === data.value
                })

                if (findValue === -1) {
                    currentLocationList.push({
                        id: data.value,
                        name: data.text,
                        type: "LOCATION",
                        code: data.code,
                    })
                }
            })

            //currentLocationList.push(rawLocationInfo)

        } else if (locationStatus === "UPDATE") {
            currentLocationList[locationIndex] = {
                id: locationInfo.value,
                //code: locationInfo.code,
                name: locationInfo.text
            }
        }

        this.setState({
            isOpenLocationModal: false
        }, () => {
            this.handleChange(null, { name: "datas", value: currentLocationList })
        })
    }

    onClickDeleteLocation = (index) => {
        const { model } = this.state

        if (Array.isArray(model.datas) && model.datas.length > 0) {
            let currentResourceList = model.datas.map(t => t)

            if (index > -1) {
                currentResourceList.splice(index, 1)
            }
            this.handleChange(null, { name: "datas", value: currentResourceList })
        }

    }

    getColumnLocationConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("location.title"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
            },
            {
                name: '',
                width: '200px',
                cell: (row, i) => {
                    let buttons = [
                        /*                         {
                                                    text: "",
                                                    title: t("common.view"),
                                                    color: "violet",
                                                    onClick: () => { this.props.history.push(`/holiday/view/${row.id}`) },
                                                    iconClass: "search",
                                                    size: "mini"
                                                }, */
                        /*          {
                                     text: "",
                                     title: t("common.edit"),
                                     color: "blue",
                                     onClick: () => { this.onOpenLocation("UPDATE", row, i, "source.locations") },
                                     iconClass: "edit",
                                     size: "mini"
                                 }, */
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onClickDeleteLocation(i, "source.locations") },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }

    onCheckButtonDisable = () => {
        const { model } = this.state

        let disableButton = true
        if (!!model.type && (model.type === eventControlRuleAction.RESOURCE_CONTROL || model.type === eventControlRuleAction.LOCATION_CONTROL)) {
            if (model.type !== "" && model.status !== "" && (Array.isArray(model.datas) && model.datas.length > 0)) {
                disableButton = false
            }
        } else {
            if (model.type !== "" && model.status !== "") {
                disableButton = false
            }
        }

        return disableButton
    }

    render() {
        try {
            const { t, onClose, optionsResourceType, optionsResources, optionsActionTypeList, optionsActionStatusList, optionsTreeLocation } = this.props
            const { modalStatus, isOpenModalResources, resourceModel, model, locationModel, isOpenLocationModal } = this.state

            let wording = ""
            let buttonModal = ""
            if (modalStatus === "CREATE") {
                wording = t("eventControlRule.addAction")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.add"),
                        color: "green",
                        disabled: this.onCheckButtonDisable,
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            } else if (modalStatus === "UPDATE") {
                wording = t("eventControlRule.editAction")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        disabled: this.onCheckButtonDisable,
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalResources &&
                        <ModalResourcesECR
                            model={resourceModel}
                            optionsResourceType={optionsResourceType}
                            optionsResources={optionsResources}
                            onGetDataList={this.props.onGetDataList}
                            onSubmit={(resourceInfo) => this.onSubmitModelResource(resourceInfo)}
                            onClose={this.onCloseModalResource}
                        />
                    }
                    {isOpenLocationModal &&
                        <ModalLocationECR
                            model={locationModel}
                            onSubmit={(locationInfo) => { this.onSubmitModalLocation(locationInfo) }}
                            onClose={this.onCloseLocation}
                            optionsTreeLocation={optionsTreeLocation}
                        />}
                    {this.getModalContent(this.getInfoModalOption({
                        onClose: () => { },
                        headerText: wording,
                        scrolling: true,
                        //size: "Large",
                        content: <div className="module actionResourcesECR">
                            <Form onKeyDown={this.onKeyDownSubmitForm}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('common.type')} />
                                            <Form.Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                value={model.type}
                                                options={optionsActionTypeList}
                                                onChange={this.onHandleChangeType}
                                                name="type"
                                                placeholder={t("common.pleaseSelect")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('common.status')} />
                                            <Form.Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                value={model.status}
                                                options={optionsActionStatusList}
                                                onChange={this.handleChange}
                                                name="status"
                                                placeholder={t("common.pleaseSelect")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    {
                                        !!model.type && model.type === eventControlRuleAction.RESOURCE_CONTROL &&
                                        <React.Fragment>
                                            <Grid.Row>
                                                <Grid.Column width={8} verticalAlign="bottom">
                                                    <LabelInput text={t('eventControlRule.resource')} isRequire={false} />
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('common.add'),
                                                            color: "green",
                                                            onClick: () => { this.onOpenModalResource("CREATE", null, null, "source.resources") },
                                                        })}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewTable
                                                        columns={this.getColumnResourceConfig()}
                                                        datas={model.datas}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    }
                                    {
                                        !!model.type && model.type === eventControlRuleAction.LOCATION_CONTROL &&
                                        <React.Fragment>
                                            <Grid.Row>
                                                <Grid.Column width={8} verticalAlign="bottom">
                                                    <LabelInput text={t('location.title')} isRequire={false} />
                                                </Grid.Column>
                                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t("common.add"),
                                                            color: "green",
                                                            onClick: () => { this.onOpenLocation("CREATE", null, null, "source.locations") },
                                                        })}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewTable
                                                        columns={this.getColumnLocationConfig()}
                                                        datas={model.datas}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Form>
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalResources.defaultProps = {
    optionsResources: [],
    optionsAreaAccessResourceType: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ModalResources)