
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class ControllerManager extends MyManager {
	async enableControllerStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/controller/enable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async disableControllerStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/controller/disable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}
}