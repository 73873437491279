
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getGatewayInfo } from '../../actions/gatewayActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import GatewayManager from '../../business/GatewayManager'
import GatewayView from '../../views/gateway/GatewayView'

class ViewGatewayController extends MyController {
	constructor(props) {
		super(props)

		this.bGateway = new GatewayManager(this)
		this.state.isLoadingComponent = false
	}

	getPropsResetStatus() {
		return ["gatewayInfo"]
	}

	render() {
		try {
			const { gatewayInfo, isLoadingComponent } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const gateway = gatewayInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<GatewayView
							model={gateway}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			gatewayInfo: state.gatewayInfo
		}),
		(dispatch, props) => ({
			getGatewayInfo() {
				dispatch(getGatewayInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getGatewayInfo()
		}
	}),
)


export default enhance(ViewGatewayController)