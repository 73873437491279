
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Form, Grid, Segment } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import LabelInput from '../../common/LabelInput'
import ViewNoResult from '../../common/ViewNoResult'

class ViewSelectService extends MyPage {
    constructor(props) {
        super(props)
        this.state.userSelects = []
        this.state.modelErrors = []
    }

    handleChange = (e, { name, value, checked }) => {
        let userSelects = this.state.userSelects.map(id => id)
        if (name === "select_all") {
            if (checked === true) {
                userSelects = this.props.users.map(d => d.value)
            } else {
                userSelects = []
            }
        } else if (name === "select_user") {
            let index = userSelects.findIndex(id => id === value)
            if (checked === true) {
                if (index === -1) {
                    userSelects.push(value)
                }
            } else {
                if (index > -1) {
                    userSelects.splice(index, 1)
                }
            }
        }
        this.setState({ userSelects })
    }

    render() {
        try {
            const { t, users, onNext, onBack, isLoadingData } = this.props
            const { userSelects } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    <Container text>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('user.title')} isRequire={true} />

                                    <Form>
                                        <div className="module multipleCheckboxContainer">
                                            <Segment className="group-segment">
                                                {
                                                    isLoadingData ?
                                                        <div className="loading-wrapper">
                                                            {this.getLoadingComponent()}
                                                        </div>
                                                        : <React.Fragment>
                                                            {
                                                                Array.isArray(users) && users.length > 0 ?
                                                                    <div className="checkbox-wrapper">
                                                                        <Form.Checkbox
                                                                            label={t("common.selectAll")}
                                                                            name={"select_all"}
                                                                            onChange={this.handleChange}
                                                                            checked={users.length === userSelects.length}
                                                                        />
                                                                        {
                                                                            users.map((data, i) => {
                                                                                let select = userSelects.find(id => id === data.value)
                                                                                return <Form.Checkbox
                                                                                    key={i}
                                                                                    label={data.text}
                                                                                    name={"select_user"}
                                                                                    value={data.value}
                                                                                    onChange={this.handleChange}
                                                                                    checked={!!select}
                                                                                />
                                                                            })
                                                                        }
                                                                    </div>
                                                                    : <div className="nodata-wrapper">
                                                                        <ViewNoResult />
                                                                    </div>
                                                            }
                                                        </React.Fragment>
                                                }

                                            </Segment>
                                        </div>
                                    </Form>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.next"),
                                            color: "teal",
                                            disabled: (userSelects.length === 0),
                                            onClick: () => onNext(userSelects)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.cancel"),
                                            color: "grey",
                                            onClick: onBack
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewSelectService.defaultProps = {
    users: [],
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewSelectService)