import common from './th/common.json'
import home from './th/home.json'
import location from './th/location.json'
import auth from './th/auth.json'
import validator from './th/validator.json'
import button from './th/button.json'
import locationType from './th/locationType.json'
import department from './th/department.json'
import message from './th/message.json'
import resourceType from './th/resourceType.json'
import resource from './th/resource.json'
import visitorGroup from './th/visitorGroup.json'
import resourceGroup from './th/resourceGroup.json'
import counter from './th/counter.json'
import desk from './th/desk.json'
import device from './th/device.json'
import kiosk from './th/kiosk.json'
import room from './th/room.json'
import user from './th/user.json'
import visitor from './th/visitor.json'
import account from './th/account.json'
import systemIntegration from './th/systemIntegration.json'
import display from './th/display.json'
import admin from './th/admin.json'
import flightboard from './th/flightboard.json'
import facilityCategory from './th/facilityCategory.json'
import facility from './th/facility.json'
import foodCategory from './th/foodCategory.json'
import food from './th/food.json'
import tempcloseschedule from './th/tempCloseSchedule.json'
import termCondition from './th/termCondition.json'
import availableTimeSlot from './th/availableTimeSlot.json'
import role from './th/role.json'
import background from './th/background.json'
import displayProfile from './th/displayProfile.json'
import roomFormat from './th/roomFormat.json'
import setting from './th/setting.json'
import controller from './th/controller.json'
import gateway from './th/gateway.json'
import iotConfig from './th/iotConfig.json'
import holiday from './th/holiday.json'
import scheduleControl from './th/scheduleControl.json'
import door from './th/door.json'
import sensorRule from './th/sensorRule.json'
import notification from './th/notification.json'
import sensor from './th/sensor.json'
import settingSensor from './th/settingSensor.json'
import report from './th/report.json'
import constantTranslate from './th/constantTranslate.json'
import eventControlRule from './th/eventControlRule.json'
import eventControlStatus from './th/eventControlStatus.json'
import rfidCard from './th/rfidCard.json'

const th = {
    ...common,
    ...home,
    ...location,
    ...auth,
    ...validator,
    ...button,
    ...locationType,
    ...department,
    ...message,
    ...resourceType,
    ...resource,
    ...visitorGroup,
    ...resourceGroup,
    ...counter,
    ...desk,
    ...device,
    ...kiosk,
    ...room,
    ...user,
    ...visitor,
    ...account,
    ...systemIntegration,
    ...display,
    ...admin,
    ...flightboard,
    ...facilityCategory,
    ...facility,
    ...foodCategory,
    ...food,
    ...tempcloseschedule,
    ...termCondition,
    ...availableTimeSlot,
    ...role,
    ...background,
    ...displayProfile,
    ...roomFormat,
    ...setting,
    ...controller,
    ...gateway,
    ...iotConfig,
    ...holiday,
    ...scheduleControl,
    ...door,
    ...sensorRule,
    ...notification,
    ...sensor,
    ...settingSensor,
    ...report,
    ...constantTranslate,
    ...eventControlRule,
    ...eventControlStatus,
    ...rfidCard,
}

export default th