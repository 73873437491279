
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid, Step } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import StepList from '../../core/business/step/StepList'
import SensorInformationStep from '../../business/sensorRuleStep/SensorInformationStep'
import ConditionStep from '../../business/sensorRuleStep/ConditionStep'
import ActionInformationStep from '../../business/sensorRuleStep/ActionInformationStep'
import { sensorRuleStep } from '../../../constant'
import SensorRuleModel from '../../models/SensorRuleModel'
import SensorInformationModel from '../../models/sensorRuleData/sensorRuleStep/SensorInformationModel'
import SensorRuleManager from '../../business/SensorRuleManager'

class ViewSensorRuleUpdate extends MyPage {
    constructor(props) {
        super(props)
        this.stepList = new StepList(this)

        this.state.currentStep = null

        this.state.isLoading = false
        this.state.registerFail = null
        this.state.modalCustom = null
        this.state.model = null
        this.state.id = this.getParamFromProps("id")
        // this.initDataStep(props.model)
        // this.initStep()
    }

    componentDidMount() {
        this.onGetData()
    }

    onGetData = () => {
        this.setState({
            isLoading: true,
        }, async () => {
            let tempState = {
                isLoading: false
            },
                action = () => { }

            let res = await this.props.onGetSensorRuleInfo(this.state.id)
            if (!!res && res.result === true && !!res.data) {
                tempState.model = res.data
                action = async () => {
                    await this.initDataStep(res.data)
                    await this.initStep()
                }
            }
            this.setState(tempState, action)
        })
    }

    initDataStep = (data) => {
        if (!!data) {
            if (!this.stepList.data) {
                this.stepList.data = {}
            }

            this.stepList.data[sensorRuleStep.SENSOR_INFORMATION] = new SensorInformationModel({
                type: data.type,
                code: data.code,
                name: data.name,
                description: data.description,
                sourceSensorIds: data.sourceSensorIds,
            })

            let bSensorRule = new SensorRuleManager()
            if (!!data.type) {
                let ModalCondition = bSensorRule.getModelSensorRuleCondition(this.data.type)
                if (!!ModalCondition) {
                    this.data.conditionModel = ModalCondition
                    this.stepList.data[sensorRuleStep.CONDITION] = new ModalCondition(data.conditionData)
                }
            }

            this.stepList.data[sensorRuleStep.ACTION_INFORMATION] = Array.isArray(data.actions) ? data.actions : []
        }
    }

    initStep() {
        this.stepList.addStep(new SensorInformationStep(this.stepList))
        this.stepList.addStep(new ConditionStep(this.stepList))
        this.stepList.addStep(new ActionInformationStep(this.stepList))

        let getStep = this.getQueryFromProps("step")
        
        if (getStep) {
            this.setState({
                currentStep: this.stepList.root
            }, () => { this.stepList.gotoStep(getStep) })
        } else {
            this.setState({
                currentStep: this.stepList.root
            })
        }
    }

    changeCurrentStep = (step) => {
        this.setState({ currentStep: step })
    }

    onClickHeaderStep = (stepId) => {
        this.stepList.gotoStep(stepId)
    }

    submit(key) {
        const { model } = this.state
        let data = this.stepList.data
        if (!!data) {
            let informationModel = data[sensorRuleStep.SENSOR_INFORMATION]
                , conditionModel = data[sensorRuleStep.CONDITION]
                , actionInformationModel = data[sensorRuleStep.ACTION_INFORMATION]

            let updateModel = new SensorRuleModel({
                id: model.id,

                type: informationModel.type,
                code: informationModel.code,
                name: informationModel.name,
                description: informationModel.description,
                sourceSensorIds: informationModel.sourceSensorIds,

                actions: actionInformationModel,

                conditionData: conditionModel,
            })

            this.props.onSubmit(updateModel, key)
        }
    }

    back() {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsSensorRuleType, sensorList, optionsConditionHolidayType, locationList, optionsNotification,
                optionsSensorRuleActionType, sensorRuleActionResult, optionsSensorRuleLocationType, sensorRuleResourceList,
                optionsDataType, optionsSensorType, optionsRecurrenceTypeList, optionsDayofWeekList } = this.props
            const { currentStep, isLoading, modalCustom, model } = this.state
            let buttons = []

            let activeKey = null
            if (!!currentStep) {
                activeKey = currentStep.key
            }
            let dataConfig = {
                optionsSensorRuleType: optionsSensorRuleType,
                sensorList: sensorList,
                optionsConditionHolidayType: optionsConditionHolidayType,
                locationList: locationList,
                optionsNotification: optionsNotification,
                optionsSensorRuleActionType: optionsSensorRuleActionType,
                sensorRuleActionResult: sensorRuleActionResult,
                optionsSensorRuleLocationType: optionsSensorRuleLocationType,
                sensorRuleResourceList: sensorRuleResourceList,
                optionsDataType: optionsDataType,
                optionsSensorType: optionsSensorType,
                optionsRecurrenceTypeList: optionsRecurrenceTypeList,
                optionsDayofWeekList: optionsDayofWeekList,
                stepStatus: "UPDATE",
                editID: (!!model && !!model.id) ? model.id : "",
                onSubmit: this.submit,
            }

            let dataStepList = this.stepList.data
                , stepList = this.stepList.getNavigator()

            //console.info("Data Step List", dataStepList)
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('greeting.updateGreeting')} buttons={buttons} >
                        <div className="module viewGreetingUpdate">
                            <Container text>
                                <Grid>
                                    {
                                        !!model &&
                                        <React.Fragment>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <Step.Group fluid ordered className="step-group-custom">
                                                        {
                                                            Array.isArray(stepList) &&
                                                            <React.Fragment>
                                                                {
                                                                    stepList.map((step, i) => {
                                                                        let isCompleted = (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key])
                                                                            , isActive = (activeKey === step.key)
                                                                            , onClick = null
                                                                        if (isCompleted && !isActive) {
                                                                            onClick = () => {
                                                                                this.onClickHeaderStep(step.id)
                                                                            }
                                                                        }
                                                                        return <Step key={i} active={isActive} completed={isCompleted}
                                                                            onClick={onClick}>
                                                                            <Step.Content>
                                                                                <Step.Title>{t(step.title)}</Step.Title>
                                                                            </Step.Content>
                                                                        </Step>
                                                                    })
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    </Step.Group>
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    {currentStep && currentStep.render(dataConfig)}
                                                </Grid.Column>
                                            </Grid.Row>
                                        </React.Fragment>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewSensorRuleUpdate.defaultProps = {
    model: null,
    optionsSensorRuleType: [],
    sensorList: [],
    optionsConditionHolidayType: [],
    locationList: [],
    optionsNotification: [],
    optionsSensorRuleActionType: [],
    sensorRuleActionResult: [],
    optionsSensorRuleLocationType: [],
    sensorRuleResourceList: [],
    optionsDataType: [],
    optionsSensorType: [],
    optionsRecurrenceTypeList: [],
    optionsDayofWeekList: [],
    onSubmit: () => console.error("Please provide Update Sensor Rule action."),
    onGetSensorRuleInfo: () => {
        console.error("Please provide Get Sensor Rule Info action.")
        return null
    }
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewSensorRuleUpdate)