
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewUpdateAdmin from '../../views/admin/ViewUpdateAdmin'
import { getAdminInfo } from '../../actions/adminActions'
import utils from '../../core/utility/utils'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
import AdminModel from '../../models/AdminModel'
import AdminManager from '../../business/AdminManager'
import { getDepartmentList } from '../../actions/departmentActions'
import { getLocationList } from '../../actions/locationActions'

class UpdateAdminController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bAdmin = new AdminManager(this)
    }

    getPropsResetStatus() {
        return ["adminInfo"]
    }

    onUpdateResource = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAdmin.updateAdmin(model.id, model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    let admin = new AdminModel(res.data)
                    action = () => { this.props.history.replace(`/admin/view/${admin.id}`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { resourceGroupList, adminInfo, departmentList, locationList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const optionsResourceGroup = resourceGroupList.getDatasOption()
                , adminInfoData = adminInfo.getData()
                , departments = departmentList.getDatas()
                , location = locationList.getDatas()
                , optionsTreeDepartment = utils.makeGroupedArrayForTreeSelect(departments, "id", "parentId", null, "name", "id")
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(location, "id", "parentId", null, "name", "id")

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewUpdateAdmin
                        isLoadingControl={isLoadingComponent}
                        optionsResourceGroup={optionsResourceGroup}
                        adminInfo={adminInfoData}
                        optionsTreeDepartment={optionsTreeDepartment}
                        optionsTreeLocation={optionsTreeLocation}
                        onUpdate={this.onUpdateResource}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
            adminInfo: state.adminInfo,
            locationList: state.locationList,
            departmentList: state.departmentList
        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getAdminInfo(id) {
                dispatch(getAdminInfo(id))
            },
            getDepartmentList() {
                dispatch(getDepartmentList())
            },
            getLocationList() {
                dispatch(getLocationList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
            this.props.getAdminInfo(utils.getParamFromProps(this.props, "id"))
            this.props.getDepartmentList()
            this.props.getLocationList()
        }
    })
)


export default enhance(UpdateAdminController)