
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router-dom'

import MyPage from '../../core/components/MyPage'
import LayoutBlank from '../layout/LayoutBlank'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordModel from '../../models/ForgotPasswordModel'
import { Form, Grid } from 'semantic-ui-react'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import config from '../../config'
class ViewForgotPassword extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false

        this.state.isOpenModalSuccess = false
        this.state.isOpenModalFail = false

        this.state.model = new ForgotPasswordModel({
            url: window.location.origin + "/resetpassword"
        })
        this.state.modelErrors = []
    }

    onValidateSuccess = () => {
        this.setState({
            isLoading: true
        }, async () => {
            try {
                let res = await this.props.onForgotPassword(this.state.model.serializeAPI())
                let tempState = {
                    isLoading: false,
                    isOpenModalSuccess: false,
                    isOpenModalFail: false,
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    tempState.isOpenModalSuccess = true
                } else {
                    tempState.isOpenModalFail = true
                }
                this.setState(tempState, action)
            } catch (error) {
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    getModalSuccess = () => {
        const { t } = this.props
        return this.getModalContent(this.getModalOption({
            headerText: t("common.system"),
            content: <p>{t("message.forgotPasswordSuccess")}</p>,
            buttons: [
                new ButtonModel(
                    {
                        text: t("auth.goToLogin"),
                        color: "blue",
                        onClick: () => {
                            this.setState({
                                isOpenModalSuccess: false
                            }, this.onBack)
                        },
                    }
                ),
                new ButtonModel(
                    {
                        text: t("common.close"),
                        color: "grey",
                        onClick: () => {
                            this.setState({
                                isOpenModalSuccess: false
                            })
                        },
                    }
                )
            ]
        }))
    }

    getModalFail = () => {
        const { t } = this.props
        return this.getModalContent(this.getErrorModalOption({
            headerText: t("common.system"),
            content: <p>{t("message.forgotPasswordError")}</p>,
            buttons: [
                new ButtonModel(
                    {
                        text: t("common.close"),
                        color: "grey",
                        onClick: () => {
                            this.setState({
                                isOpenModalFail: false
                            })
                        },
                    }
                )
            ]
        }))
    }

    onBack = () => {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t } = this.props
            const { model, modelErrors, isOpenModalSuccess, isOpenModalFail } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenModalSuccess && this.getModalSuccess()}
                    {isOpenModalFail && this.getModalFail()}
                    <LayoutBlank>
                        <div className="module viewForgotPassword">
                            <div className="vl-background" >
                                <img src={`${config.publicUrl}/assets/images/image-forest_fog_trees.jpg`} alt="No Background" />
                            </div>
                            <div className="vl-wrapper">
                                <h1 className="text-header-login">{t("auth.forgotPassword")}</h1>
                                <div className="vl-form">
                                    <Form>
                                        <ForgotPasswordForm
                                            model={model}
                                            handleChange={this.handleChange}
                                            errors={modelErrors}
                                        />
                                    </Form>
                                </div>
                                <div className="vl-action">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16} textAlign="center">
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.confirm"),
                                                        color: "blue",
                                                        onClick: () => this.onValidate(this.onValidateSuccess)
                                                    })}
                                                />
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: t("common.back"),
                                                        color: "grey",
                                                        onClick: this.onBack
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </LayoutBlank>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewForgotPassword.defaultProps = {
    onForgotPassword: () => {
        console.error("Please provide Change Password action.")
        return false
    }
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewForgotPassword)