
import mongoose from 'mongoose'
import { defaultValue } from '../../constant'
import { setBaseSchema } from '../core/schema/Schema'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const UserImportExcelModelSchema = new Schema({
	formatCode: { type: String, required: [true, "validate.require"], default: "" },
	imagePath: { type: String, required: false, default: "" },
	fileUpload: { type: FileSchema, required: [true, "validate.require"], default: null },
}, { _id: false })

setBaseSchema(UserImportExcelModelSchema, "import_excel_user")

UserImportExcelModelSchema.path("formatCode").validate(async function (formatCode) {
	return !!formatCode && formatCode !== defaultValue.PLEASE_SELECT
}, "validate.require")

UserImportExcelModelSchema.methods.serializeAPI = function () {
	try {
		let result = {
			formatCode: this.formatCode,
			imagePath: !!this.imagePath ? this.imagePath : null,
		}
		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}
		return result
	} catch (error) {
		return null
	}
}

const UserImportExcelModel = mongoose.model("import_excel_user", UserImportExcelModelSchema, "import_excel_user")

export default UserImportExcelModel