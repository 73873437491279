
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import AccountModel from '../../models/AccountModel'
import LabelInput from '../common/LabelInput'
import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'

class AccountForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.modalTakePhoto = null
        this.state.isOpenUploadImage = false
        this.state.imageTakePhoto = null
    }

    render() {
        try {
            const { t, model, errors } = this.props

            let mobileValidate = this.getErrorInput("mobile", errors)
                , givenNameValidate = this.getErrorInput("givenName", errors)
                , snValidate = this.getErrorInput("sn", errors)
                , emailValidate = this.getErrorInput("email", errors)

            return (
                <div className="module accountForm">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("account.givenName")} isRequire={true} />
                                    <Form.Input
                                        name="givenName"
                                        value={model.givenName}
                                        onChange={this.handleChange}
                                        placeholder={t("account.givenName")}
                                        fluid
                                        error={givenNameValidate.isError ? givenNameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("account.sn")} isRequire={false} />
                                    <Form.Input
                                        name="sn"
                                        value={model.sn}
                                        onChange={this.handleChange}
                                        placeholder={t("account.sn")}
                                        fluid
                                        error={snValidate.isError ? snValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("account.mobile")} isRequire={false} />
                                    <Form.Input
                                        name="mobile"
                                        value={model.mobile}
                                        onChange={this.handleChange}
                                        placeholder={t("account.mobile")}
                                        fluid
                                        error={mobileValidate.isError ? mobileValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.email")} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        placeholder={t("common.email")}
                                        fluid
                                        type="email"
                                        error={emailValidate.isError ? emailValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("account.profile")} isRequire={false} />
                                    <ViewSelectImageOrTakePhoto name="fileUpload" defaultImage={!!model.id ? `admin/${model.id}` : ""} onChange={this.handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form >
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

AccountForm.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    model: new AccountModel(),
    errors: [],
}

export default (translate(AccountForm))