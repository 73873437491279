
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import moment from 'moment'
import { defaultValue, disableDateType } from '../../../constant'
import MultipleCheckboxList from '../common/MultipleCheckboxList'

class ModalTimeDate extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.modalStatus = "CREATE"

        if (!!props.model) {
            this.state.dateInfo = props.model
            this.state.modalStatus = "UPDATE"
        } else {
            this.state.dateInfo = {
                type: "",
                startDateTime: moment(),
                endDateTime: moment(),
                weekday: []
            }
        }

    }

    handleChange = (e, { name, value, checked }) => {
        const { optionsWeekday } = this.props

        let dateInfo = this.state.dateInfo

        if (name === "weekday") {
            if (!!value) {
                if (value === defaultValue.SELECT_ALL) {
                    if (checked === true) {
                        dateInfo.weekday = optionsWeekday.map((op) => {
                            return op
                        })
                    } else {
                        dateInfo.weekday = []
                    }
                } else {
                    let index = dateInfo.weekday.findIndex(id => id === value)
                    if (checked === true) {
                        if (index === -1) {
                            dateInfo.weekday.push(value)
                        }
                    } else {
                        if (index > -1) {
                            dateInfo.weekday.splice(index, 1)
                        }
                    }
                }
            }
        } else {
            dateInfo[name] = value

            if (name === "type") {
                dateInfo.weekday = []
            }
        }

        this.setState({
            dateInfo: dateInfo
        })
    }

    handleChangeBoolean = (e, { name, value, checked }) => {
        try {
            this.handleChange(e, { name, value: value, checked: checked })
        } catch (error) {
            console.error("Handle Change Boolean Error: " + error.message)
        }
    }

    onSubmit = () => {
        const { dateInfo } = this.state
        let submitDateInfo = null

        if (dateInfo.type === "ALL_YEAR") {
            submitDateInfo = {
                type: dateInfo.type,
                weekday: dateInfo.weekday,
                startDateTime: null,
                endDateTime: null
            }
        } else if (dateInfo.type === "RANGE") {
            submitDateInfo = {
                type: dateInfo.type,
                weekday: [],
                startDateTime: moment(dateInfo.startDateTime),
                endDateTime: moment(dateInfo.endDateTime)
            }
        } else if (dateInfo.type === "DAY") {
            submitDateInfo = {
                type: dateInfo.type,
                weekday: [],
                startDateTime: moment(dateInfo.startDateTime),
                endDateTime: moment(dateInfo.startDateTime)
            }
        }

        this.props.onSubmit(submitDateInfo)
    }

    isDisabledSubmit = (dateInfo) => {
        if (dateInfo.type === "") {
            return true
        } else if (dateInfo.type === "ALL_YEAR") {
            if (dateInfo.weekday.length === 0) {
                return true
            } else {
                return false
            }
        } else if (dateInfo.type === "RANGE") {
            let startDateTime = moment(dateInfo.startDateTime)
                , endDateTime = moment(dateInfo.endDateTime)

            return startDateTime.isAfter(endDateTime, "d")
        } else if (dateInfo.type === "DAY") {
            return false
        }

        return true

    }

    render() {
        try {
            const { dateInfo, modalStatus } = this.state
            const { t, optionsDateType, optionsWeekday } = this.props

            let wording = ""
            let buttonModal = null

            if (modalStatus === "CREATE") {
                wording = t("common.addDate")
                buttonModal = [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(dateInfo)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => this.props.onClose()
                        }
                    )
                ]
            } else if (modalStatus === "UPDATE") {
                wording = t("common.editDate")
                buttonModal = [
                    new ButtonModel(
                        {
                            text: t("common.edit"),
                            color: "blue",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(dateInfo)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => this.props.onClose()
                        }
                    )
                ]
            }

            return this.getModalContent(this.getModalOption({
                headerText: wording,
                onClose: () => { },
                content: <div className="module holidayDateTime">
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.type')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.type")}
                                        name="type"
                                        value={dateInfo.type}
                                        options={optionsDateType}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (!!dateInfo.type && dateInfo.type === "RANGE") &&
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t('tempCloseSchedule.startDate')} isRequire={true} />
                                        <InputDatePicker
                                            name="startDateTime"
                                            value={dateInfo.startDateTime}
                                            onChange={this.handleChange}
                                            checkerDate={dateInfo.endDateTime}
                                            disableDateTypeInput={disableDateType.END_DATE_INCLUDE}
                                        // error={startDateValidate.isError ? startDateValidate.content : null}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t('tempCloseSchedule.endDate')} isRequire={true} />
                                        <InputDatePicker
                                            name="endDateTime"
                                            value={dateInfo.endDateTime}
                                            onChange={this.handleChange}
                                            checkerDate={dateInfo.startDateTime}
                                            disableDateTypeInput={disableDateType.START_DATE_INCLUDE}
                                        // error={endDateValidate.isError ? endDateValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {
                                (!!dateInfo.type && dateInfo.type === "DAY") &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('tempCloseSchedule.startDate')} isRequire={true} />
                                        <InputDatePicker
                                            name="startDateTime"
                                            value={dateInfo.startDateTime}
                                            onChange={this.handleChange}
                                        // error={startDateValidate.isError ? startDateValidate.content : null}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {
                                (!!dateInfo.type && dateInfo.type === "ALL_YEAR") &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('holiday.dayOfWeek')} isRequire={true} />
                                        <MultipleCheckboxList
                                            name="weekday"
                                            optionsDataList={optionsWeekday}
                                            value={dateInfo.weekday}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </div>,
                buttons: buttonModal
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalTimeDate.defaultProps = {
    // timeSlots: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalTimeDate))