
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyForm from '../../core/components/MyForm'
import OnOffDropdown from './iotInput/OnOffDropdown'
import CurtainDropdown from './iotInput/CurtainDropdown'
import DaliForm from './iotInput/DaliForm'
import IOTColorPicker from './iotInput/IOTColorPicker'
import IOTColorPickerModal from './iotInput/IOTColorPickerModal'
import BacnetForm from './iotInput/BacnetForm'

class IOTInputForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.iotValue = props.value
        this.selfHandleForm = true
    }

    componentDidUpdate(prevProps) {
        const { value } = this.props

        if (prevProps.value !== value) {
            this.setState({
                iotValue: value
            })
        }
    }

    handleChangeIOT = (e, { name, value }) => {
        let iotValue = value

        this.setState({ iotValue: iotValue })

        this.props.onChange(null, { name: this.props.name, value: iotValue })
    }

    render() {
        try {
            const { disabled, type, name } = this.props
            const { iotValue } = this.state

            if (!type) {
                return <p></p>
            }

            return (
                <React.Fragment>
                    {
                        (type === "LIGHT") &&
                        <OnOffDropdown
                            name="onOff"
                            value={iotValue}
                            disabled={disabled}
                            onChange={this.handleChangeIOT}
                        />
                    }
                    {
                        type === "CURTAIN" &&
                        <CurtainDropdown
                            name="curtain"
                            value={iotValue}
                            disabled={disabled}
                            onChange={this.handleChangeIOT}
                        />
                    }
                    {
                        type === "LIGHT_DALI" &&
                        <DaliForm
                            name="lightIntensity"
                            value={iotValue}
                            onChange={this.handleChangeIOT}
                            disabled={disabled}
                        />
                    }
                    {
                        ((type === "LIGHT_DMX" || type === "LIGHT_RGB") && name === "miniIOTModal") &&
                        <IOTColorPickerModal
                            name="colorPicker"
                            value={iotValue}
                            onChange={this.handleChangeIOT}
                            disabled={disabled}
                        />
                    }
                    {
                        ((type === "LIGHT_DMX" || type === "LIGHT_RGB") && name !== "miniIOTModal") &&
                        <IOTColorPicker
                            name="colorPicker"
                            value={iotValue}
                            onChange={this.handleChangeIOT}
                            disabled={disabled}
                        />
                    }
                    {
                        type === "BACNET" &&
                        <BacnetForm
                            name="bacnet"
                            value={iotValue}
                            onChange={this.handleChangeIOT}
                            disabled={disabled}
                        />
                    }

                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

IOTInputForm.defaultProps = {
    name: "",
    type: "",
    value: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate
)

export default enhance(IOTInputForm)