
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const SystemIntegrationSchema = new Schema({
    id: { type: String, default: "" },
    code: { type: String, required: [true, "validate.require"], default: "" },
    name: { type: String, required: [true, "validate.require"], default: "" },
    description: { type: String, default: "" },
    service: { type: String, required: [true, "validate.require"], default: "" },
    data: { type: Object, required: false },
}, { _id: false })

setBaseSchema(SystemIntegrationSchema, "SystemIntegration")

SystemIntegrationSchema.methods.serializeAPI = function () {
    try {
        let result = {
            code: this.code,
            name: this.name,
            description: this.description,
            service: this.service,
        }
        if (!!this.data) {
            let data = this.data.serializeAPI()
            result.data = data
        }
        return result
    } catch (error) {
        return null
    }
}

SystemIntegrationSchema.methods.serializeOption = function () {
	try {
		return {
			key: this.id,
			value: this.id,
			text: this.name
		}
	} catch (error) {
		return null
	}
}

const SystemIntegrationModel = mongoose.model("SystemIntegration", SystemIntegrationSchema, "SystemIntegration")

export default SystemIntegrationModel