// import React from 'react'
import MyController from '../core/components/MyController'
import socketIOClient from 'socket.io-client'
import { SERVICE_SOCKET_ENDPOINT, PATH_SOCKET, TIME_OUT_SOCKET } from '../lib'

import { eventSocket } from '../../constant'

export default class SocketController extends MyController {

    isConnectSocket = () => {
        let isConnect = false
        if ("socket" in this.state) {
            // console.log("connected", this.state.socket.connected)
            isConnect = this.state.socket.connected
            // if (!!this.state.socket && !!this.state.socket.id) {
            //     isConnect = true
            // }
        }
        return isConnect
    }

    onConnectSocket = (callBack) => {
        const socket = socketIOClient(SERVICE_SOCKET_ENDPOINT, {
            path: PATH_SOCKET,
            timeout: TIME_OUT_SOCKET,
            reconnection: false
        })

        socket.on(eventSocket.CONNECT, () => this.onConnectSocketSuccess(socket, callBack))
        socket.on(eventSocket.CONNECT_TIMEOUT, this.onConnectTimeOut)
        socket.on(eventSocket.CONNECT_ERROR, this.onConnectError)
        socket.on(eventSocket.DISCONNECT, this.onDisconnectSuccess)
    }

    onConnectSocketSuccess = (socket, callBack) => {
        // console.log("Socket connect!!!", "ID:" + ((!!socket && !!socket.id) ? socket.id : null))
        this.setState({
            socket
        }, () => {
            if (typeof callBack === "function") {
                callBack(socket)
            }
        })
    }

    onConnectTimeOut = (error) => {
        console.error("timeout", error)
    }

    onConnectError = (error) => {
        console.error("error", error)
    }

    onDisconnectSocket = () => {
        if (this.isConnectSocket()) {
            this.state.socket.disconnect()
        }
    }

    onDisconnectSuccess = () => {
        // console.log("disconnect")
        this.setState({
            socket: null
        })
    }

    afterReceiveSocket = (event, res, callBack) => {
        // console.log(event, res)
        // if (!!res && !!res.returnEvent) {
        //     if (this.isConnectSocket()) {
        //         console.log("Emit", "returnEvent", res.returnEvent)
        //         this.state.socket.emit(res.returnEvent, true)
        //     }
        // }
        if (typeof callBack === "function") {
            callBack(event, res)
        }
    }

}