
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

const Schema = mongoose.Schema

const AlertMessageDataSchema = new Schema({
	notificationId: { type: String, required: [true, "validate.require"] },
	content: { type: String, required: [true, "validate.require"] },
	detail: { type: String },
}, { _id: false })

setBaseSchema(AlertMessageDataSchema)

AlertMessageDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			notificationId: this.notificationId,
			content: this.content,
		}
		return result
	} catch (error) {
		return null
	}
}

const AlertMessageDataModel = mongoose.model("sensor_rule_action_alert_message", AlertMessageDataSchema, "sensor_rule_action_alert_message")

export default AlertMessageDataModel
