import React from 'react'
import { DatePicker } from "antd"
import "antd/dist/antd.css"
import { Form } from 'semantic-ui-react'
import '../../scss/inputDatePicker.scss'
import moment from 'moment'
import { dateTimeFormat, disableDateType } from '../../../../constant'

class InputDatePicker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isOpen: false
        }
    }

    onChange = (date, dateString) => {
        const { name } = this.props
        this.setState({
            isOpen: false
        }, () => {
            this.props.onChange(null, { name: name, value: date })
        })
    }

    disabledDate = (current, disableDateTypeInput) => {
        const { checkerDate } = this.props

        switch (disableDateTypeInput) {
            // Allow only today
            case disableDateType.ALLOW_TODAY_ONLY:
                return current < moment().endOf('day').subtract(1, 'days') || current > moment().endOf('day')

            // allow past dates + today
            case disableDateType.PAST_DATE_INCLUDE_TODAY:
                return !(current && current < moment().endOf('day'))

            // allow past dates only
            case disableDateType.PAST_DATE_EXCLUDE_TODAY:
                return !(current && current < moment().endOf('day').subtract(1, 'days'))

            // allow future dates + today
            case disableDateType.FUTURE_DATE_INCLUDE_TODAY:
                return current < moment().endOf('day')

            // allow future dates only
            case disableDateType.FUTURE_DATE_EXCLUDE_TODAY:
                return current < moment().endOf('day').subtract(1, 'days')

            // check start date and exclude the chosen start date
            case disableDateType.START_DATE_EXCLUDE:
                if (!!checkerDate) {
                    return current && current.valueOf() < moment(checkerDate).startOf('day').add(1, 'days');
                } else {
                    return console.error("Please send in checkerDate value to Date Picker")
                }

            // check start date and include the chosen start date
            case disableDateType.START_DATE_INCLUDE:
                if (!!checkerDate) {
                    return current && current.valueOf() < moment(checkerDate).endOf('day').subtract(1, 'days');
                } else {
                    return console.error("Please send in checkerDate value to Date Picker")
                }

            // check end date and exclude the chosen end date
            case disableDateType.END_DATE_EXCLUDE:
                if (!!checkerDate) {
                    return current && current.valueOf() > moment(checkerDate).startOf('day');
                } else {
                    return console.error("Please send in checkerDate value to Date Picker")
                }

            // check end date and include the chosen end date
            case disableDateType.END_DATE_INCLUDE:
                if (!!checkerDate) {
                    return current && current.valueOf() > moment(checkerDate).endOf('day');
                } else {
                    return console.error("Please send in checkerDate value to Date Picker")
                }

            default:
                return false;

        }

    }

    render() {
        const { format, value, disabled, disableDateTypeInput } = this.props
        const { isOpen } = this.state
        return (
            <React.Fragment>
                <div className="module inputDatePicker">
                    <div className="hide-picker">
                        <DatePicker
                            open={isOpen}

                            disabledDate={(current) => this.disabledDate(current, disableDateTypeInput)}
                            onChange={this.onChange}
                            onOpenChange={(e) => this.setState({ isOpen: false })}
                        // onPanelChange={(e, a, f) => console.log("8888", e, a, f)}
                        />
                    </div>
                    <div className={`input-display ${disabled === true ? "disabled" : ""}`} onClick={() => this.setState({ isOpen: true })}>
                        <Form.Input
                            icon={{ name: "calendar alternate outline", link: true }}
                            readOnly={true}
                            value={moment(value).format(format)}

                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

InputDatePicker.defaultProps = {
    name: "",
    value: moment(),
    onChange: () => console.error("Please provide On Change action."),
    format: dateTimeFormat.displayDate,
    disabled: false,
    disableDateTypeInput: "",
    checkerDate: null, // if you want the date picker to check value of another date picker, send in the another date picker's 
    // isActive: true,
    // isPage: true,
    // message: ""
}


export default InputDatePicker