
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { getDeskInfo, getDeskWithIOTConfigInfo, getDeskSceneInfo } from '../../actions/deskActions'
import { getLocationList } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import DeskManager from '../../business/DeskManager'
import DeskView from '../../views/desk/DeskView'
import ControlManager from '../../business/ControlManager'
import SocketController from '../SocketController'
import { eventSocket } from '../../../constant'

class ViewDeskController extends SocketController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false

		this.state.model = null
		this.state.isRegisSubscribe = false
		this.state.deskIOTInfoData = null

		this.bDesk = new DeskManager(this)
		this.bControl = new ControlManager(this)
	}

	getPropsResetStatus() {
		return ["deskInfo", "deskWithIOTConfigInfo"]
	}

	componentDidMount() {
		this.onConnectSocket((socket) => {
			console.log(this.isConnectSocket())
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		if (nextProps.deskWithIOTConfigInfo.requestFinishedAfterRequest()) {
			tempState.deskIOTInfoData = nextProps.deskWithIOTConfigInfo.getData()
		}
		if (nextProps.deskInfo.requestFinishedAfterRequest()) {
			tempState.model = nextProps.deskInfo.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		const { isRegisSubscribe, model, deskIOTInfoData } = this.state
		if (!isRegisSubscribe && !!model && !!deskIOTInfoData) {
			// console.log("Start Socket", model, deskIOTInfoData)
			this.setState({
				isRegisSubscribe: true
			}, () => { this.onRegisterSubscribeIOTConfig(model, deskIOTInfoData) })
		}
	}

	componentWillUnmount() {
		this.onDisconnectSocket()
	}

	//For socket
	onRegisterSubscribeIOTConfig = (desk, deskIOTInfoData) => {
		let deskId = desk.id
			, iotConfigIds = []
		if (Array.isArray(deskIOTInfoData.iotConfigs) && deskIOTInfoData.iotConfigs.length > 0) {
			iotConfigIds = deskIOTInfoData.iotConfigs.map(config => config.id)
		}
		if (!!deskId && iotConfigIds.length > 0 && this.isConnectSocket()) {
			this.state.socket.emit(eventSocket.REGISTER, { id: deskId, iotConfigIds: iotConfigIds })
			this.state.socket.on(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, this.onReceiveResponseStatusChange)
		}
	}

	onReceiveResponseStatusChange = (res) => {
		this.afterReceiveSocket(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, res, this.onStatusChange)
	}

	onStatusChange = (event, res) => {
		if (!!res && !!res.data) {
			const { model, deskIOTInfoData } = this.state
			let data = res.data
			if (data.id === model.id && Array.isArray(data.result) && data.result.length > 0) {
				let tempDeskIOTInfoData = deskIOTInfoData
					, iotConfigs = tempDeskIOTInfoData.iotConfigs
				if (Array.isArray(iotConfigs) && iotConfigs.length > 0) {
					let isChange = false
					data.result.forEach(change => {
						let index = iotConfigs.findIndex(iot => iot.id === change.id)
						if (index > -1) {
							isChange = true
							let oldData = iotConfigs[index]
							oldData.status = change.status
							iotConfigs[index] = oldData
						}
					})
					if (isChange) {
						tempDeskIOTInfoData.iotConfigs = iotConfigs
						this.setState({
							deskIOTInfoData: tempDeskIOTInfoData
						})
					}
				}
			}
		}
	}

	onDeleteAreaDesk = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.deleteAreaDesk(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDeskInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaDesk = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.upsertAreaDesk(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDeskInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUnintegtationDesk = (model, serviceInfo) => {
		// console.log("Model ID: ", model.id)
		// console.log("Service: ", serviceInfo.service)

		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.unintegrateDesk(model.id, serviceInfo.service, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDeskInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onDeleteSceneDesk = (deskId, sceneId) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.deleteDeskScene(deskId, sceneId, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getDeskSceneInfo(deskId) }

				this.setState(tempState, action)
			})
		})
	}

	onCreateSceneDesk = (id, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.createDeskScene(id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDeskSceneInfo(id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUpdateSceneDesk = (deskId, sceneId, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bDesk.updateDeskScene(deskId, sceneId, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getDeskSceneInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	onControlStatus = (deskId, iotId, status) => {
		// console.log("status: ", status)
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.controlStatus(iotId, status, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDeskWithIOTConfigInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	turnOnSceneDesk = (sceneID) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.turnOnSceneResource(sceneID, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDeskWithIOTConfigInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	testSyncPlatformDesk = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bDesk.deskTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { deskInfo, deskSceneInfo, locationList, isLoadingComponent } = this.props // deskWithIOTConfigInfo
			const { deskIOTInfoData } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const desk = deskInfo.getData()
				// , deskIOTInfoData = deskWithIOTConfigInfo.getData()
				, deskSceneInfoData = deskSceneInfo.getDatas()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let sortedDeskSceneInfoData = deskSceneInfoData.sort((a, b) => { return a.code.localeCompare(b.code) })

			let locationInfo = null

			if (!!desk) {
				if (!!desk.locationId) {
					locationInfo = locationList.findDataByKey("id", desk.locationId)
				}
			}

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<DeskView
							model={desk}
							locationInfo={locationInfo}
							optionsTreeLocation={optionsTreeLocation}
							onDeleteAreaDesk={this.onDeleteAreaDesk}
							onUpsertAreaDesk={this.onUpsertAreaDesk}
							onUnintegtationDesk={this.onUnintegtationDesk}
							onCreateSceneDesk={this.onCreateSceneDesk}
							onUpdateSceneDesk={this.onUpdateSceneDesk}
							onDeleteSceneDesk={this.onDeleteSceneDesk}
							onControlStatus={this.onControlStatus}
							turnOnSceneDesk={this.turnOnSceneDesk}
							locationList={locationDatas}
							deskIOTInfo={deskIOTInfoData}
							deskSceneInfo={sortedDeskSceneInfoData}
							testSyncPlatformDesk={this.testSyncPlatformDesk}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			deskInfo: state.deskInfo,
			deskWithIOTConfigInfo: state.deskWithIOTConfigInfo,
			deskSceneInfo: state.deskSceneInfo,
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			getDeskInfo() {
				dispatch(getDeskInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getDeskWithIOTConfigInfo() {
				dispatch(getDeskWithIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getDeskSceneInfo() {
				dispatch(getDeskSceneInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getDeskInfo()
			this.props.getDeskWithIOTConfigInfo()
			this.props.getDeskSceneInfo()
		}
	}),
)


export default enhance(ViewDeskController)