const systemIntegrationService = {
	O365: "O365",
	GOOGLE: "GOOGLE",
	EXCHANGE: "EXCHANGE",
	ACTIVE_DIRECTORY: "ACTIVE_DIRECTORY",
	LINE: "LINE_BOT",
	ZOOM: "ZOOM",
	PROFILE_CENTER: "PROFILE_CENTER"
}

const notificationService = {
	O365: "O365",
	GOOGLE_SERVICE_ACCOUNT: "GOOGLE_SERVICE_ACCOUNT",
	EXCHANGE: "EXCHANGE",
	LINE_BOT: "LINE_BOT",
	SEND_GRID: "SEND_GRID",
	SMTP: "SMTP",
}

const requestStatus = {
	REQUEST: "REQUEST",
	REQUEST_SUCCESS: "REQUEST_SUCCESS",
	REQUEST_FAIL: "REQUEST_FAILURE",
	NO_ACTION: "NO_ACTION",
}

const cookiesKey = {
	USER_ID: "USER_ID",
	USER_EMAIL: "USER_EMAIL",
	USER_FULLNAME: "USER_FULLNAME",
	USER_RESOURCE_TYPE_CODE: "USER_RESOURCE_TYPE_CODE",
	SERVICE: "SERVICE",
	TOKEN: "TOKEN",
	REFRESH_TOKEN: "REFRESH_TOKEN",
	REFRESH_TOKEN_EXPIRES_DATE: "REFRESH_TOKEN_EXPIRES_DATE",
	TOKEN_EXPIRES_DATE: "TOKEN_EXPIRES_DATE",
	TOKEN_EXPIRES_ON: "TOKEN_EXPIRES_ON",
	BYPASS_TOKEN: "BYPASS_TOKEN",

	TARGET_URL: "TARGET_URL",

	IS_REMEMBER: "IS_REMEMBER",

	LANGUAGE: "language",

	// internal services
	IS_ENABLE_PLATFORM_SERVICE: "IS_ENABLE_PLATFORM_SERVICE",
	IS_ENABLE_DASHBOARD_SERVICE: "IS_ENABLE_DASHBOARD_SERVICE",
	IS_ENABLE_FRONTEND_SERVICE: "IS_ENABLE_FRONTEND_SERVICE",
	IS_ENABLE_SCHEDULE_CONTROL_SERVICE: "IS_ENABLE_SCHEDULE_CONTROL_SERVICE",
	IS_ENABLE_GATEWAY_SOCKET_SERVICE: "IS_ENABLE_GATEWAY_SOCKET_SERVICE",
	IS_ENABLE_SENSOR_RULE_SERVICE: "IS_ENABLE_SENSOR_RULE_SERVICE",
	IS_ENABLE_EVENT_CONTROL_SERVICE: "IS_ENABLE_EVENT_CONTROL_SERVICE",

	// external service
	EXTERNAL_SERVICES: "EXTERNAL_SERVICES"
}

const defaultValue = {
	PLEASE_SELECT: "PLEASE_SELECT",
	SELECT_ALL: "SELECT_ALL",
	NONE_SELECT: "NONE_SELECT"
}

const dateTimeFormat = {
	displayFullDateTime: "MMMM DD YYYY HH:mm",
	displayDate: "DD MMMM YYYY",
	displayTime: "HH:mm",

	apiFullDateTime: "YYYY-MM-DDTHH:mm:ss",
	apiFullDate: "YYYY-MM-DD",
	apiFullTime: "HH:mm",
}

const resourceStatus = {
	ACTIVE: "ACTIVE",
	INACTIVE: "INACTIVE"
}

const onlineOffline = {
	ONLINE: "ONLINE",
	OFFLINE: "OFFLINE"
}

const searchQueryType = {
	LIKE: "LIKE",
	DATE: "DATE",
	TIME: "TIME",
	DATETIME: "DATETIME",
	ISNULL: "ISNULL",
	ISNOTNULL: "ISNOTNULL",
	IN: "IN"
}

const areaTypes = {
	POLYGON: "POLYGON",
	CIRCLE: "CIRCLE"
}

const languageKey = {
	en: "en",
	th: "th"
}

const quotaMode = {
	LIMIT: "LIMIT",
	UNLIMIT: "UNLIMIT"
}

const quotaType = {
	TIME: "TIME",
	DURATION: "DURATION"
}

const eventSocket = {
	CONNECT: "connect",
	CONNECT_TIMEOUT: "connect_timeout",
	CONNECT_ERROR: "connect_error",
	DISCONNECT: "disconnect",

	REGISTER: "register",
	REGISTER_SUCCESS: "register_success",
	REGISTER_FAIL: "register_fail",

	CONTROLLER_SERIAL_STATUS_CHANGE: "controller_serial_status_change"
}

const serviceCode = {
	LOCAL: "LOCAL",
	O365: "O365",
	GOOGLE_AUTHEN: "GOOGLE_AUTHEN",
	GOOGLE_SERVICE_ACCOUNT: "GOOGLE_SERVICE_ACCOUNT",
	EXCHANGE: "EXCHANGE",
	ACTIVE_DIRECTORY: "ACTIVE_DIRECTORY",
	LINE: "LINE_BOT",
	// ZOOM: "ZOOM",
	PROFILE_CENTER: "PROFILE_CENTER",
	SEND_GRID: "SEND_GRID",
	SMTP: "SMTP",
	SMS_8X8: "SMS_8X8",
}

const sensorRuleType = {
	DOOR_ALERT: "DOOR_ALERT",
	MOTION: "MOTION",
	COLLECT: "COLLECT",
}

const sensorRuleActionType = {
	CONTROL: "CONTROL",
	ALERT: "ALERT",
	ALERT_DASHBOARD: "ALERT_DASHBOARD",
	DATABASE: "DATABASE",
}

const sensorType = {
	BUTTON_PUSH: "BUTTON_PUSH",
	CH2O: "CH2O",
	CO: "CO",
	CO2: "CO2",
	DOOR: "DOOR",
	HUMIDITY: "HUMIDITY",
	MOTION: "MOTION",
	O2: "O2",
	PM10: "PM10",
	PM2_5: "PM2_5",
	PRESSURE: "PRESSURE",
	TEMPERATURE: "TEMPERATURE",
	TVOC: "TVOC",
	WATER: "WATER",
	WATT: "WATT",
}

const sensorRuleLocationType = {
	LOCATION: "LOCATION",
	RESOURCE: "RESOURCE"
}

const resourceTypeCode = {
	DESK: "DESK",
	DOOR: "DOOR",
	ROOM: "ROOM",
	USER: "USER",
	SUPER_ADMINISTRATOR: "SUPER_ADMINISTRATOR",
	PROFILE_CENTER: "PROFILE_CENTER",
}

const enableService = {
	PLATFORM_SERVICE: "PLATFORM_SERVICE",
	DASHBOARD_SERVICE: "DASHBOARD_SERVICE",
	FRONTEND_SERVICE: "FRONTEND_SERVICE",
	SCHEDULE_CONTROL_SERVICE: "SCHEDULE_CONTROL_SERVICE",
	GATEWAY_SOCKET_SERVICE: "GATEWAY_SOCKET_SERVICE",
	SENSOR_RULE_SERVICE: "SENSOR_RULE_SERVICE",
	EVENT_CONTROL_SERVICE: "EVENT_CONTROL_SERVICE",
}

const enableExternalService = {
	PLATFORM_BACKEND: "PLATFORM_BACKEND",
	PLATFORM_FRONTEND: "PLATFORM_FRONTEND",
	SMART_VMS_BACKEND: "SMART_VMS_BACKEND",
	SMART_VMS_FRONTEND: "SMART_VMS_FRONTEND",
	SMART_CONTROL_BACKEND: "SMART_CONTROL_BACKEND",
	SMART_CONTROL_FRONTEND: "SMART_CONTROL_FRONTEND",
	SMART_LOCKER_BACKEND: "SMART_LOCKER_BACKEND",
}

const reportType = {
	AIR_QUALITY_DAILY: "AIR_QUALITY_DAILY",
	AIR_QUALITY_WEEKLY: "AIR_QUALITY_WEEKLY",
	AIR_QUALITY_MONTHLY: "AIR_QUALITY_MONTHLY",
}

const downloadFileType = {
	EXCEL: {
		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		filenameExtension: "xlsx"
	},
	PDF: {
		type: "application/pdf",
		filenameExtension: "pdf"
	}
}

const sensorRuleStep = {
	SENSOR_INFORMATION: "SENSOR_INFORMATION",
	CONDITION: "CONDITION",
	ACTION_INFORMATION: "ACTION_INFORMATION"
}

const recurrenceTypeCode = {
	DAILY: "DAILY",
	WEEKLY: "WEEKLY"
}

const inviteInfoType = {
	EVENT_ROOMMINISTER: "EVENT_ROOMMINISTER",
	PRE_REGISTER_VMS: "PRE_REGISTER_VMS",
}

const eventControlRuleStep = {
	EVENT_INFORMATION: "EVENT_INFORMATION",
	SOURCE: "SOURCE",
	CONDITION: "CONDITION",
	ACTION_INFORMATION: "ACTION_INFORMATION"
}

const eventControlRuleAction = {
	AREA_LOCATION_CONTROL_EVENT: "AREA_LOCATION_CONTROL_EVENT",
	AREA_RESOURCE_CONTROL_EVENT: "AREA_RESOURCE_CONTROL_EVENT",
	LOCATION_CONTROL: "LOCATION_CONTROL",
	LOCATION_CONTROL_EVENT: "LOCATION_CONTROL_EVENT",
	RESOURCE_CONTROL: "RESOURCE_CONTROL",
	RESOURCE_CONTROL_EVENT: "RESOURCE_CONTROL_EVENT",
}

const disableDateType = {
	ALLOW_TODAY_ONLY: "ALLOW_TODAY_ONLY",
	PAST_DATE_INCLUDE_TODAY: "PAST_DATE_INCLUDE_TODAY",
	FUTURE_DATE_INCLUDE_TODAY: "FUTURE_DATE_INCLUDE_TODAY",
	PAST_DATE_EXCLUDE_TODAY: "PAST_DATE_EXCLUDE_TODAY",
	FUTURE_DATE_EXCLUDE_TODAY: "FUTURE_DATE_EXCLUDE_TODAY",
	START_DATE_EXCLUDE: "START_DATE_EXCLUDE",
	START_DATE_INCLUDE: "START_DATE_INCLUDE",
	END_DATE_EXCLUDE: "END_DATE_EXCLUDE",
	END_DATE_INCLUDE: "END_DATE_INCLUDE",
}

export {
	requestStatus,
	cookiesKey,

	dateTimeFormat,

	defaultValue,
	resourceStatus,
	searchQueryType,

	areaTypes,
	systemIntegrationService,
	languageKey,

	quotaMode,
	quotaType,

	onlineOffline,

	eventSocket,

	serviceCode,

	resourceTypeCode,

	sensorType,
	sensorRuleType,
	sensorRuleActionType,
	sensorRuleLocationType,

	enableService,
	notificationService,
	enableExternalService,
	reportType,
	downloadFileType,
	sensorRuleStep,
	recurrenceTypeCode,
	inviteInfoType,
	eventControlRuleStep,

	eventControlRuleAction,
	disableDateType,
}