
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewButton from '../common/ViewButton'

import UserStatus from '../user/UserStatus'
import ButtonModel from '../../core/model/ButtonModel'
import BoxAdvancedSearch from '../common/BoxAdvancedSearch'
import FormSearchUser from './FormSearchUser'
import UserModel from '../../models/UserModel'
import { cookiesKey, defaultValue, resourceTypeCode, searchQueryType } from '../../../constant'
import utils from '../../core/utility/utils'
import ModalSyncUser from './ModalSyncUser'
import PagingModel from '../../core/model/PagingModel'
import PagingDataModel from '../../core/model/PagingDataModel'
import ViewTablePaging from '../../core/view/common/table/ViewTablePaging'

class ViewUserList extends MyPage {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.isSearch = false
        this.state.isLoadingTable = false
        this.state.modelSearch = new UserModel()
        this.state.modelPaging = new PagingModel()
        this.state.pagingDataModel = null
        this.state.model = null

        this.state.isOpenModalSync = false
    }

    componentDidMount() {
        this.onSearchByPaging(this.state.modelPaging)
    }

    onClickStatus = (model) => {
        this.setState({
            model: model
        })
    }

    getContentModal = (model) => {
        const { t, optionUserStatus, onChangeStatusUser } = this.props
        return this.getModalContent(
            this.getModalOption({
                headerText: t("message.changeStatusUser"),
                content: <UserStatus
                    model={this.state.model}
                    optionUserStatus={optionUserStatus}
                    handleChange={this.handleChange}
                />,
                buttons: [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: () => {
                                let action = () => onChangeStatusUser(model, () => this.onSearchByForm(this.state.modelSearch))
                                this.setState({ model: null }, action)
                            }
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => {
                                this.setState({
                                    model: null
                                })
                            }
                        }
                    )
                ]
            })
        )
    }

    onClickResetPassword = (model) => {
        const { t, onResetPasswordUser } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmResetPassword"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "orange",
                                onClick: () => {
                                    this.closeModal()
                                    onResetPasswordUser(model)
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onConfirmDelete = (model) => {
        const { t } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    this.props.onDelete(model, () => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t, allowResetPassword, isLoadingControl } = this.props
        const columns = [
            {
                name: t("user.employeeCode"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("user.fullname"),
                selector: (row) => row.displayName ? row.displayName : '-',
                sortable: false
            },
            {
                name: t("user.username"),
                selector: (row) => row.username ? row.username : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.email"),
                selector: (row) => row.email ? row.email : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("user.status"),
                selector: (row) => row.status ? t(`constantTranslate.status.${row.status}`) : '-',
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.view"),
                            color: "violet",
                            onClick: () => { this.props.history.push(`/user/view/${row.id}`) },
                            iconClass: "search",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.changeStatus"),
                            color: "yellow",
                            onClick: () => { this.onClickStatus(row) },
                            iconClass: "exchange",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.props.history.push(`/user/update/${row.id}`) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]

                    if (allowResetPassword) {
                        let resetButton = {
                            text: "",
                            title: t("common.resetPassword"),
                            color: "orange",
                            onClick: () => { this.onClickResetPassword(row) },
                            iconClass: "redo",
                            size: "mini",
                            disabled: isLoadingControl,
                        }

                        buttons.splice(2, 0, resetButton)
                        buttons.join()
                    }

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]

        return columns
    }

    //Add 
    onClickAdd = () => {
        this.props.history.push(`/user/create`)
    }

    toggleAdvancedSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        })
    }

    onSearchByForm = (model) => {
        let modelPaging = this.state.modelPaging
        modelPaging.pageNumber = 1
        this.setState({
            modelSearch: model,
            modelPaging: modelPaging
        }, () => {
            let query = this.getQuerySearchByForm(model, true)
                , queryPaging = this.getQuerySearchByPaging(this.state.modelPaging, false)
            let sumQuery = `${!!query ? query + "&" : "?"}${queryPaging}`
            this.onSearchPaging(sumQuery)
        })

    }

    getQuerySearchByForm = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let status = ""
                if (!!model.status && model.status !== defaultValue.SELECT_ALL) {
                    status = model.status
                }

                let departmentId = ""
                if (!!model.departmentId && model.departmentId !== defaultValue.SELECT_ALL) {
                    departmentId = model.departmentId
                }

                let resourceGroupId = ""
                if (!!model.resourceGroupId && model.resourceGroupId !== defaultValue.SELECT_ALL) {
                    resourceGroupId = model.resourceGroupId
                }

                let querys = [
                    {
                        name: "code",
                        value: model.code,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "fullName",
                        value: model.displayName,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "username",
                        value: model.username,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "email",
                        value: model.email,
                        queryType: searchQueryType.LIKE
                    },
                    {
                        name: "status",
                        value: status,
                    },
                    {
                        name: "resourceGroupIds",
                        value: resourceGroupId,
                        queryType: searchQueryType.IN
                    },
                    {
                        name: "departmentId",
                        value: departmentId
                    }
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchByPaging = (model) => {
        this.setState({
            modelPaging: model
        }, () => {
            let query = this.getQuerySearchByPaging(model, true)
                , queryForm = this.getQuerySearchByForm(this.state.modelSearch, false)
            let sumQuery = `${!!query ? query : "?"}${queryForm}`
            this.onSearchPaging(sumQuery)
        })
    }

    getQuerySearchByPaging = (model, isNewQuery = true) => {
        try {
            let query = ""
            if (!!model) {
                let querys = [
                    {
                        name: "pageNumber",
                        value: model.pageNumber,
                    },
                    {
                        name: "pageSize",
                        value: model.pageSize,
                    },
                ]
                query = utils.generateSearchQuery(querys, isNewQuery)
            }
            return query
        } catch (error) {
            return ""
        }
    }

    onSearchPaging = (query) => {
        this.setState({
            isLoadingTable: true,
            pagingDataModel: null,
        }, async () => {
            this.forceUpdate()
            let res = await this.props.onGetDataPaging(query)
            let tempState = {
                isLoadingTable: false,
                pagingDataModel: null
            }
            if (!!res && res instanceof PagingDataModel) {
                tempState.pagingDataModel = res
            }
            this.setState(tempState)
        })
    }

    onClickSync = () => {
        this.setState({
            isOpenModalSync: true,
        })
    }

    onCloseModalSync = () => {
        this.setState({
            isOpenModalSync: false
        })
    }

    onSubmitModalSync = (ids) => {
        this.setState({
            isOpenModalSync: false
        }, () => {
            this.props.onImportUserFromPlatform(ids, () => this.onSearchByForm(this.state.modelSearch))
        })
    }

    onClickImportExternalUser = () => {
        this.props.history.push("/user/importexternal")
    }

    onClickSyncAll = () => {
        const { t, onSyncAllUser } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.syncAllData"),
                    content: t("message.confirmSyncAll"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.closeModal()
                                    onSyncAllUser(() => this.onSearchByForm(this.state.modelSearch))
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        }
        catch (error) {
            this.setModalCatch(error)
        }
    }

    onClickImportExcel = () => {
        this.props.history.push("/user/importexcel")
    }

    render() {
        try {
            const { t, optionUserStatusList, optionResourceGroupList, optionsDepartmentList } = this.props
            const { isLoading, isSearch, model, isOpenModalSync, pagingDataModel, isLoadingTable } = this.state
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {!!model && this.getContentModal(model)}
                    {isOpenModalSync && <ModalSyncUser onSubmit={this.onSubmitModalSync} onClose={this.onCloseModalSync} />}
                    <LayoutWithSidebarHeaderFooter textHeader={t("user.title")}>
                        <div className="module viewUserList">
                            <Container>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign="left" verticalAlign="bottom">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.create'),
                                                    color: "teal",
                                                    onClick: this.onClickAdd
                                                })}
                                            />
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('common.advancedSearch'),
                                                    color: "purple",
                                                    onClick: this.toggleAdvancedSearch
                                                })}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            {
                                                this.bCookie.getData(cookiesKey.USER_RESOURCE_TYPE_CODE) === resourceTypeCode.SUPER_ADMINISTRATOR &&
                                                <React.Fragment>
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('common.importExternalUser'),
                                                            color: "blue",
                                                            onClick: this.onClickImportExternalUser
                                                        })}
                                                    />
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('user.importExcel'),
                                                            color: "blue",
                                                            onClick: this.onClickImportExcel
                                                        })}
                                                    />
                                                </React.Fragment>
                                            }
                                            {
                                                this.bCookie.getData(cookiesKey.IS_ENABLE_PLATFORM_SERVICE) === true &&
                                                <React.Fragment>
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('common.syncInfo'),
                                                            color: "orange",
                                                            onClick: this.onClickSync
                                                        })}
                                                    />
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('user.syncAllUser'),
                                                            color: "yellow",
                                                            onClick: this.onClickSyncAll
                                                        })}
                                                    />
                                                </React.Fragment>
                                            }
                                        </Grid.Column>
                                        <Grid.Column width={16}>
                                            <BoxAdvancedSearch isSearch={isSearch} >
                                                <FormSearchUser
                                                    onSearch={this.onSearchByForm}
                                                    optionUserStatusList={optionUserStatusList}
                                                    optionResourceGroupList={optionResourceGroupList}
                                                    optionsDepartmentList={optionsDepartmentList}
                                                />
                                            </BoxAdvancedSearch>
                                        </Grid.Column>
                                    </Grid.Row>

                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ViewTablePaging
                                                columns={this.getColumnConfig()}
                                                pagingData={pagingDataModel}

                                                onChangePage={this.onSearchByPaging}
                                                isLoading={isLoadingTable}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter >
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewUserList.defaultProps = {
    optionUserStatus: [],
    optionUserStatusList: [],
    optionResourceGroupList: [],
    optionsDepartmentList: [],
    onResetPasswordUser: () => console.error("Please provide \"Reset Password User\" action."),
    onChangeStatusUser: () => console.error("Please provide \"Change Status User\" action."),
    onGetDataPaging: () => {
        console.error("Please provide \"Get Data Paging\" action.")
        return null
    }
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewUserList)