
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class SettingSensorManager extends MyManager {
	async upsertSettingSensor(body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/setting`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}
}