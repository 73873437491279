
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import SettingSensorForm from './SettingSensorForm'
import ButtonModel from '../../core/model/ButtonModel'
import SettingSensorModel from '../../models/SettingSensorModel'
import { defaultValue } from '../../../constant'

class SettingSensorUpdate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model

        if (!!props.model.loginInfo === false) {
            this.state.model = new SettingSensorModel({
                loginInfo: {
                    defaultLoginSystemId: "",
                    enableLoginSystem: [],
                    isSigninAndUpsert: false
                },
                systemNotificationInfo: {
                    notificationId: "",
                    forgotPassword: {
                        isEnable: false,
                        subject: "",
                        content: ""
                    },
                    resetPassword: {
                        isEnable: false,
                        subject: "",
                        content: ""
                    }
                }
            })
        }

        this.state.modelErrors = []
        this.state.activeIndex = parseInt(this.getParamFromProps("tabNum"), 10)

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = nextProps.model
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onUpdate = () => {
        const { activeIndex } = this.state
        let model = this.state.model
        this.props.onUpdate(model, activeIndex)
    }

    onBack = () => {
        const { activeIndex } = this.state
        this.props.history.push(`/setting/authorization/${activeIndex}`)
    }

    onSetActiveTabIndex = (index) => {
        this.setState({
            activeIndex: index
        })
    }

    handleChangeModel = (e, { name, value, checked }) => {
        const { optionsSystemIntegration } = this.props
        let model = this.state.model

        if (name === "loginInfo.enableLoginSystemIds") {
            if (Array.isArray(model.loginInfo.enableLoginSystemIds)) {
                let tempEnableLoginSelect = model.loginInfo.enableLoginSystemIds

                if (!!value && value === defaultValue.SELECT_ALL) {
                    if (checked) {
                        tempEnableLoginSelect = optionsSystemIntegration.map(login => {
                            return login.value
                        })
                    } else {
                        tempEnableLoginSelect = []
                    }
                } else {
                    let index = tempEnableLoginSelect.findIndex(id => id === value)
                    if (checked === true) {
                        if (index === -1) {
                            tempEnableLoginSelect.push(value)
                        }
                    } else {
                        if (index > -1) {
                            tempEnableLoginSelect.splice(index, 1)
                        }
                    }
                }

                model.loginInfo.enableLoginSystemIds = tempEnableLoginSelect

                let modelErrors = this.state.modelErrors
                let index = modelErrors.findIndex(e => e.path === "loginInfo.enableLoginSystemIds")
                if (index > -1) {
                    modelErrors.splice(index, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }

            }
        } else if (name === "loginInfo.enableFrontendLoginSystemIds") {
            if (Array.isArray(model.loginInfo.enableFrontendLoginSystemIds)) {
                let tempEnableUserLoginSelect = model.loginInfo.enableFrontendLoginSystemIds

                if (!!value && value === defaultValue.SELECT_ALL) {
                    if (checked) {
                        tempEnableUserLoginSelect = optionsSystemIntegration.map(login => {
                            return login.value
                        })
                    } else {
                        tempEnableUserLoginSelect = []
                    }
                } else {
                    let index = tempEnableUserLoginSelect.findIndex(id => id === value)
                    if (checked === true) {
                        if (index === -1) {
                            tempEnableUserLoginSelect.push(value)
                        }
                    } else {
                        if (index > -1) {
                            tempEnableUserLoginSelect.splice(index, 1)
                        }
                    }
                }

                model.loginInfo.enableFrontendLoginSystemIds = tempEnableUserLoginSelect

                let modelErrors = this.state.modelErrors
                let index = modelErrors.findIndex(e => e.path === "loginInfo.enableFrontendLoginSystemIds")
                if (index > -1) {
                    modelErrors.splice(index, 1);
                    this.setState({
                        modelErrors: modelErrors
                    })
                }

            }
        } else if (name === "isSigninAndUpsert") {
            if (model.loginInfo.isSigninAndUpsert) {
                model.loginInfo.isSigninAndUpsert = false
            } else {
                model.loginInfo.isSigninAndUpsert = true
            }
        } else if (name === "defaultGroupId" || name === "defaultLoginSystemId") {
            model.loginInfo[name] = value
            let modelErrors = this.state.modelErrors
            let index = modelErrors.findIndex(e => e.path.includes(name))
            if (index > -1) {
                modelErrors.splice(index, 1);
                this.setState({
                    modelErrors: modelErrors
                })
            }
        } else if (name.includes("systemNotificationInfo")) {
            let nameSp = name.split(".")
            if (nameSp.length === 3) {
                let a = model[nameSp[0]]
                    , b = a[nameSp[1]]
                if (nameSp[2] === "isEnable") {
                    b[nameSp[2]] = checked
                } else {
                    b[nameSp[2]] = value
                }
                a[nameSp[1]] = b
                model[nameSp[0]] = a
            } else if (nameSp.length === 2) {
                let a = model[nameSp[0]]
                a[nameSp[1]] = value
                model[nameSp[0]] = a
            }
        }


        this.setState({
            model: model
        })
    }

    render() {
        try {
            const { t, optionsSystemIntegration, optionsSystemIntegrationWithLocal, optionsNotification, optionsResourceGroup, testForgotPassword, testResetPassword, isLoadingControl } = this.props
            const { model, modelErrors } = this.state

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('setting.updateSettingSensor')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                (!!model && !!model.loginInfo) ?
                                                    <SettingSensorForm
                                                        model={model}
                                                        optionsSystemIntegration={optionsSystemIntegration}
                                                        optionsSystemIntegrationWithLocal={optionsSystemIntegrationWithLocal}
                                                        handleChange={this.handleChange}
                                                        errors={modelErrors}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                        optionsNotification={optionsNotification}
                                                        optionsResourceGroup={optionsResourceGroup}
                                                        onSetActiveTabIndex={this.onSetActiveTabIndex}
                                                        testForgotPassword={testForgotPassword}
                                                        testResetPassword={testResetPassword}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SettingSensorUpdate.defaultProps = {
    isLoadingControl: false,
    model: new SettingSensorModel(),
    optionsSystemIntegration: [],
    optionsSystemIntegrationWithLocal: [],
    optionsNotification: [],
    optionsResourceGroup: [],
    onUpdate: () => console.error("Please provide \"Update\" action."),
    testForgotPassword: () => {
        console.error("Please provide \"Test\" action.")
        return false
    },
    testResetPassword: () => {
        console.error("Please provide \"Test\" action.")
        return false
    },

}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SettingSensorUpdate)