
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const GoogleServiceAccountDataSchema = new Schema({
	type: { type: String, required: [true, "validate.require"], default: "" },
	projectId: { type: String, required: [true, "validate.require"], default: "" },
	privateKey: { type: String, required: [true, "validate.require"], default: "" },
	privateKeyId: { type: String, required: [true, "validate.require"], default: "" },
	clientEmail: { type: String, required: [true, "validate.require"], default: "" },
	clientId: { type: String, required: [true, "validate.require"], default: "" },
	authUri: { type: String, required: [true, "validate.require"], default: "" },
	tokenUri: { type: String, required: [true, "validate.require"], default: "" },
	authProviderX509CertUrl: { type: String, required: [true, "validate.require"], default: "" },
	clientX509CertUrl: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(GoogleServiceAccountDataSchema, "system_integration_google_service_account")

const GoogleServiceAccountDataModel = mongoose.model("system_integration_google_service_account", GoogleServiceAccountDataSchema, "system_integration_google_service_account")

export default GoogleServiceAccountDataModel