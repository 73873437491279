
import types from '../types'
import { requestStatus } from '../../constant'
import ScheduleControlResource from '../selectors/scheduleControlResource'
import ScheduleControlOptionsResource from '../selectors/scheduleControlOptionsResource'

const scheduleControlReducer = {
	scheduleControlInfo: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlResource)) {
			state = new ScheduleControlResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_INFO_REQUEST:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_INFO_SUCCESS:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_INFO_FAILURE:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	scheduleControlList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlResource)) {
			state = new ScheduleControlResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_LIST_REQUEST:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_LIST_SUCCESS:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_SCHEDULE_CONTROL_LIST:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_LIST_FAILURE:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	recurrenceTypeList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlOptionsResource)) {
			state = new ScheduleControlOptionsResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_REQUEST:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_SUCCESS:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_FAILURE:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	dayOfWeekList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlOptionsResource)) {
			state = new ScheduleControlOptionsResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_REQUEST:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_SUCCESS:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_FAILURE:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	scheduleLocationTypeList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlOptionsResource)) {
			state = new ScheduleControlOptionsResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_REQUEST:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_SUCCESS:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_FAILURE:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	scheduleResourceList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlResource)) {
			state = new ScheduleControlResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_RESOURCE_LIST_REQUEST:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_RESOURCE_LIST_SUCCESS:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_RESOURCE_LIST_FAILURE:
				state = new ScheduleControlResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	scheduleHolidayTypeList: (state = null, action) => {
		if (!state || !(state instanceof ScheduleControlOptionsResource)) {
			state = new ScheduleControlOptionsResource()
		}

		switch (action.type) {
			case types.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_REQUEST:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_SUCCESS:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_FAILURE:
				state = new ScheduleControlOptionsResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default scheduleControlReducer