
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Header, Table, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import ControllerModel from '../../models/ControllerModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import MiniIOTModal from '../common/MiniIOTModal'
import ViewNoResult from '../common/ViewNoResult'

class ControllerView extends MyPage {
    constructor(props) {
        super(props)

        this.state.isOpenMiniIOTModal = false

        this.state.secondModalId = null
        this.state.secondModalType = null
        this.state.secondModalValue = null
        this.state.statusModalData = null
    }

    onBack = () => {
        this.props.history.push(`/controller`)
    }

    onClickControl = (status, iotId) => {
        const { model } = this.props

        if (status === "MODAL") {
            this.setState({
                isOpenMiniIOTModal: true,
                secondModalId: iotId.id,
                secondModalType: iotId.iotCode,
                secondModalValue: iotId.status
            })
        } else {
            this.props.onControlStatus(model.id, iotId, status)
        }

    }

    onCloseStatusModal = () => {
        this.setState({
            isOpenMiniIOTModal: false
        })
    }

    onSubmitStatusModal = (miniIotValue) => {
        const { model } = this.props
        const { secondModalId } = this.state

        this.setState({
            isOpenMiniIOTModal: false
        })

        this.props.onControlStatus(model.id, secondModalId, miniIotValue)
    }

    onConfirmChange = (statusText, callBack) => {
        const { t } = this.props

        let contentText = ""

        switch (statusText) {
            case "ON":
                contentText = t("message.confirmTurnOn")
                break;
            case "OFF":
                contentText = t("message.confirmTurnOff")
                break;
            case "UP":
                contentText = t("message.confirmCurtainUp")
                break;
            case "STOP":
                contentText = t("message.confirmCurtainStop")
                break;
            case "DOWN":
                contentText = t("message.confirmCurtainDown")
                break;
            default:
                contentText = ""
                break;
        }

        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: contentText,
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            if (typeof callBack === "function") {
                                                callBack()
                                            }
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getIOTColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false
            },
            {
                name: t("common.status"),
                selector: (row, i) => {
                    if (row.iotCode === "LIGHT_RGB" || row.iotCode === "LIGHT_DMX") {
                        return <div style={{
                            borderStyle: "solid",
                            borderWidth: `1px`,
                            width: "2em",
                            height: "1em",
                            backgroundColor: `rgba(${row.status.r}, ${row.status.g}, ${row.status.b}, 1)`
                        }}>
                        </div>

                    } else if (row.iotCode === "BACNET") {
                        return <div>
                            <p>{`${t("common.status")}: ${row.status.status}`}</p>
                            <p>{`${t("common.temperature")}: ${row.status.temp}`}</p>
                        </div>
                    } else {
                        return row.status
                    }
                },
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = []

                    if (row.iotCode === "CURTAIN") {
                        buttons = [
                            {
                                text: t("common.upIcon"),
                                color: "teal",
                                onClick: () => {
                                    this.onConfirmChange("UP", () => this.onClickControl("UP", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.stopIcon"),
                                color: "red",
                                onClick: () => {
                                    this.onConfirmChange("STOP", () => this.onClickControl("STOP", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.downIcon"),
                                color: "purple",
                                onClick: () => {
                                    this.onConfirmChange("DOWN", () => this.onClickControl("DOWN", row.id))
                                },
                                size: "mini"
                            }
                        ]
                    } else if (row.iotCode === "LIGHT") {
                        buttons = [
                            {
                                text: t("common.onIcon"),
                                color: "green",
                                onClick: () => {
                                    this.onConfirmChange("ON", () => this.onClickControl("ON", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.offIcon"),
                                color: "red",
                                onClick: () => {
                                    this.onConfirmChange("OFF", () => this.onClickControl("OFF", row.id))
                                },
                                size: "mini"
                            }
                        ]
                    } else {
                        buttons = [
                            {
                                text: t("common.control"),
                                color: "grey",
                                onClick: () => { this.onClickControl("MODAL", row) },
                                size: "mini"
                            }
                        ]
                    }

                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <div className="table-button">
                                    <ViewButton
                                        key={i}
                                        model={new ButtonModel(b)}
                                    />
                                </div>
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    render() {
        try {
            const { t, controllerConfig, model } = this.props
            const { secondModalType, secondModalValue, isOpenMiniIOTModal } = this.state

            let buttons = [
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment >
                    {super.render()}
                    {isOpenMiniIOTModal && <MiniIOTModal type={secondModalType} value={secondModalValue} onClose={this.onCloseStatusModal} onSubmit={this.onSubmitStatusModal} />}
                    < LayoutWithSidebarHeaderFooter textHeader={t('controller.viewController')} buttons={buttons} >
                        <div className="module ControllerView">
                            <Container text>
                                {
                                    !model ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewNoResult />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        : <React.Fragment>
                                            <div className="Controller-wrapper">
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('controller.mac')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.mac ? model.mac : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('controller.gateway')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.gateway ? model.gateway.code : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('controller.status')} /></Table.Cell>
                                                            <Table.Cell><p>{!!controllerConfig.status ? controllerConfig.status : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('controller.enable')} /></Table.Cell>
                                                            <Table.Cell>
                                                                {
                                                                    (model.isDisable === true) &&
                                                                    <Icon color="red" name="close" />
                                                                }
                                                                {
                                                                    (model.isDisable === false) &&
                                                                    <Icon color="green" name="checkmark" />
                                                                }
                                                            </Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                <br></br>
                                                <br></br>
                                                <React.Fragment>
                                                    <Grid divided='vertically'>
                                                        <Grid.Row className="remove-bottom-padding">
                                                            <Grid.Column width={16}>
                                                                <Header as='h1'>
                                                                    {t("controller.configIOT")}
                                                                </Header>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <ViewTable
                                                                    columns={this.getIOTColumnConfig()}
                                                                    datas={controllerConfig.iotConfigs}
                                                                />
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </React.Fragment>
                                                {/*   <Header as='h1' dividing className="form header">
                                                    {t("controller.configIOT")}
                                                </Header>
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getIOTColumnConfig()}
                                                                datas={controllerConfig.iotConfigs}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid> */}
                                            </div>
                                        </React.Fragment>
                                }
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ControllerView.defaultProps = {
    model: new ControllerModel(),
    controllerConfig: [],
}

const enhance = compose(
    translate,
    withRouter
)

export default enhance(ControllerView)