
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
// import MyController from '../../core/components/MyController'
import { getLocationInfo, getLocationList, getLocationWithIOTConfigInfo, getLocationSceneInfo } from '../../actions/locationActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import LocationManager from '../../business/LocationManager'
import ViewLocationInfo from '../../views/location/ViewLocationInfo'
import ControlManager from '../../business/ControlManager'
import SocketController from '../SocketController'
import { eventSocket } from '../../../constant'

class ViewLocationController extends SocketController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false

		this.state.model = null
		this.state.isRegisSubscribe = false
		this.state.locationIOTInfoData = null

		this.bLocation = new LocationManager(this)
		this.bControl = new ControlManager(this)
	}

	getPropsResetStatus() {
		return ["locationInfo", "locationWithIOTConfigInfo"]
	}

	componentDidMount() {
		this.onConnectSocket((socket) => {
			// console.log(this.isConnectSocket())
		})
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		if (nextProps.locationWithIOTConfigInfo.requestFinishedAfterRequest()) {
			tempState.locationIOTInfoData = nextProps.locationWithIOTConfigInfo.getData()
		}
		if (nextProps.locationInfo.requestFinishedAfterRequest()) {
			tempState.model = nextProps.locationInfo.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		const { isRegisSubscribe, model, locationIOTInfoData } = this.state
		if (!isRegisSubscribe && !!model && !!locationIOTInfoData) {
			// console.log("Start Socket", model, locationIOTInfoData)
			this.setState({
				isRegisSubscribe: true
			}, () => { this.onRegisterSubscribeIOTConfig(model, locationIOTInfoData) })
		}
	}

	componentWillUnmount() {
		this.onDisconnectSocket()
	}

	//For socket
	onRegisterSubscribeIOTConfig = (location, locationIOTInfoData) => {
		let locationId = location.id
			, iotConfigIds = []
		if (Array.isArray(locationIOTInfoData.iotConfigs) && locationIOTInfoData.iotConfigs.length > 0) {
			iotConfigIds = locationIOTInfoData.iotConfigs.map(config => config.id)
		}
		if (!!locationId && iotConfigIds.length > 0 && this.isConnectSocket()) {
			this.state.socket.emit(eventSocket.REGISTER, { id: locationId, iotConfigIds: iotConfigIds })
			this.state.socket.on(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, this.onReceiveResponseStatusChange)
		}
	}

	onReceiveResponseStatusChange = (res) => {
		this.afterReceiveSocket(eventSocket.CONTROLLER_SERIAL_STATUS_CHANGE, res, this.onStatusChange)
	}

	onStatusChange = (event, res) => {
		if (!!res && !!res.data) {
			const { model, locationIOTInfoData } = this.state
			let data = res.data
			if (data.id === model.id && Array.isArray(data.result) && data.result.length > 0) {
				let tempLocationIOTInfoData = locationIOTInfoData
					, iotConfigs = tempLocationIOTInfoData.iotConfigs
				if (Array.isArray(iotConfigs) && iotConfigs.length > 0) {
					let isChange = false
					data.result.forEach(change => {
						let index = iotConfigs.findIndex(iot => iot.id === change.id)
						if (index > -1) {
							isChange = true
							let oldData = iotConfigs[index]
							oldData.status = change.status
							iotConfigs[index] = oldData
						}
					})
					if (isChange) {
						tempLocationIOTInfoData.iotConfigs = iotConfigs
						this.setState({
							locationIOTInfoData: tempLocationIOTInfoData
						})
					}
				}
			}
		}
	}

	onDeleteAreaLocation = (model, areaInfo) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocation.deleteAreaLocation(model.id, areaInfo, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getLocationInfo(model.id) }

				this.setState(tempState, action)
			})
		})
	}

	onUpsertAreaLocation = (model, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocation.upsertAreaLocation(model.id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && !!res.result) {
					action = () => this.props.getLocationInfo(model.id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onDeleteSceneLocation = (locationId, sceneId) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocation.deleteLocationScene(locationId, sceneId, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { this.props.getLocationSceneInfo(locationId) }

				this.setState(tempState, action)
			})
		})
	}

	onCreateSceneLocation = (id, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocation.createLocationScene(id, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getLocationSceneInfo(id)
				}

				this.setState(tempState, action)
			})
		})
	}

	onUpdateSceneLocation = (locationId, sceneId, body) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocation.updateLocationScene(locationId, sceneId, body, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getLocationSceneInfo(locationId)
				}

				this.setState(tempState, action)
			})
		})
	}

	onControlStatus = (locationId, iotId, status) => {
		//console.log("status: ", status)
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.controlStatus(iotId, status, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				//console.log("result: ", res)
				if (!!res && res.result) {
					// action = () => this.props.getLocationWithIOTConfigInfo(locationId)
				}

				this.setState(tempState, action)
			})
		})
	}

	turnOnSceneLocation = (sceneID) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.turnOnSceneLocation(sceneID, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					// action = () => this.props.getDeskWithIOTConfigInfo(deskId)
				}

				this.setState(tempState, action)
			})
		})
	}

	testSyncPlatformLocation = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bLocation.locationTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { locationInfo, locationList, locationSceneInfo } = this.props 
			const { isLoadingComponent, locationIOTInfoData } = this.state

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const locationData = locationInfo.getData()
				// , locationIOTInfoData = locationWithIOTConfigInfo.getData()
				, locationSceneInfoData = locationSceneInfo.getDatas()
				, locationDatas = locationList.getDatas()
				//, resourceDatas = resourceList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			let sortedLocationSceneInfoData = locationSceneInfoData.sort((a, b) => { return a.code.localeCompare(b.code) })

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<ViewLocationInfo
							model={locationData}
							onDeleteAreaLocation={this.onDeleteAreaLocation}
							onUpsertAreaLocation={this.onUpsertAreaLocation}
							optionsTreeLocation={optionsTreeLocation}
							locationList={locationDatas}
							locationIOTInfo={locationIOTInfoData}
							locationSceneInfo={sortedLocationSceneInfoData}
							onCreateSceneLocation={this.onCreateSceneLocation}
							onUpdateSceneLocation={this.onUpdateSceneLocation}
							onDeleteSceneLocation={this.onDeleteSceneLocation}
							onControlStatus={this.onControlStatus}
							turnOnSceneLocation={this.turnOnSceneLocation}
							testSyncPlatformLocation={this.testSyncPlatformLocation}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationInfo: state.locationInfo,
			locationList: state.locationList,
			locationWithIOTConfigInfo: state.locationWithIOTConfigInfo,
			locationSceneInfo: state.locationSceneInfo
		}),
		(dispatch, props) => ({
			getLocationInfo() {
				dispatch(getLocationInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getLocationWithIOTConfigInfo() {
				dispatch(getLocationWithIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationSceneInfo() {
				dispatch(getLocationSceneInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationInfo()
			this.props.getLocationList()
			this.props.getLocationWithIOTConfigInfo()
			this.props.getLocationSceneInfo()
		}
	}),
)


export default enhance(ViewLocationController)