
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import { resourceStatus } from '../../constant'
import FileSchema from './schemas/FileSchema'

const Schema = mongoose.Schema

const ResourceSchema = new Schema({
	resourceTypeCode: { type: String, required: [true, "validate.require"], default: null },
	departmentId: { type: String, required: [true, "validate.require"], default: null },
	locationId: { type: String, required: [true, "validate.require"], default: null },
	profileImageId: { type: String, default: null },

	username: { type: String, default: null },
	password: { type: String, default: null },
	confirmPassword: { type: String, default: null },

	code: { type: String, required: [true, "validate.require"], default: null },
	givenName: { type: String, required: [true, "validate.require"], default: null },
	sn: { type: String, default: null },
	email: { type: String, default: null },
	mobile: { type: String, default: null },
	isAdmin: { type: Boolean, default: false },
	pinCode: { type: String, default: null },
	rfId: { type: String, default: null },
	status: { type: String, enum: [resourceStatus.ACTIVE, resourceStatus.INACTIVE], default: resourceStatus.ACTIVE },

	lastLoginTime: { type: Date, default: null },
	displayName: { type: String, default: null },

	fileUpload: { type: FileSchema, default: null },
	imageUri: { type: String, default: null },
	id: { type: String, default: null },
}, { _id: false })

setBaseSchema(ResourceSchema, "05")

ResourceSchema.methods.serializeAPI = function () {
	try {
		let data = {
			resourceTypeId: this.resourceTypeId,
			departmentId: this.departmentId,
			locationId: this.locationId,
			profileImageId: this.profileImageId,
			username: this.username,
			password: this.password,
			code: this.code,
			givenName: this.givenName,
			sn: this.sn,
			displayName: this.displayName,
			email: this.email,
			mobile: this.mobile,
			pinCode: this.pinCode,
			rfId: this.rfId,
			resourceTypeCode: this.resourceTypeCode,
			id: this.id
		}
		if (!!this.fileUpload) {
			data.fileUpload = this.fileUpload
		}
		return data

	} catch (error) {
		console.log(error)
		return null
	}
}

const ResourceModel = mongoose.model("resource", ResourceSchema, "resource")

export default ResourceModel