
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Label, Segment, Tab } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import InputHtml from '../../core/view/common/InputHtml'
import { defaultValue } from '../../../constant'
import MultipleCheckboxList from '../common/MultipleCheckboxList'
import ButtonModel from '../../core/model/ButtonModel'
import ViewButton from '../common/ViewButton'

class SettingSensorForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.isOpenAdminLoginSystem = false
        this.state.activeIndex = parseInt(this.getParamFromProps("tabNum"), 10)
    }

    onOpenLoginSystemAdmin = () => {
        this.setState({
            isOpenAdminLoginSystem: true
        })
    }

    onCloseLoginSystemAdmin = () => {
        this.setState({
            isOpenAdminLoginSystem: false
        })
    }

    onSubmitLoginSystemAdmin = (loginSystem) => {
        this.setState({
            isOpenAdminLoginSystem: false
        })
        this.props.onSubmitLoginSystemAdmin(loginSystem)
    }

    handleChangeBoolean = (e, { name, value, checked }) => {
        try {
            this.handleChange(e, { name, value: value, checked: checked })
        } catch (error) {
            console.error("Handle Change Boolean Error: " + error.message)
        }
    }

    handleChange = (e, { name, value, checked }) => {
        this.props.handleChange(e, { name, value, checked })
    }

    handleTabChange = (e, { activeIndex }) => {
        this.setState({
            activeIndex: activeIndex
        })

        this.props.onSetActiveTabIndex(activeIndex)
    }

    onTestForgotPassword = (model) => {
        let body = {
            notificationId: model.systemNotificationInfo.notificationId,
            subject: model.systemNotificationInfo.forgotPassword.subject,
            content: model.systemNotificationInfo.forgotPassword.content,
        }
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.testForgotPassword(body)
            let tempState = {
                isLoading: false,
                isTestForgot: null
            }
            if (!!res && res.result === true) {
                tempState.isTestForgot = true
            } else {
                tempState.isTestForgot = false
            }
            this.setState(tempState)
        })
    }

    onTestResetPassword = (model) => {
        let body = {
            notificationId: model.systemNotificationInfo.notificationId,
            subject: model.systemNotificationInfo.resetPassword.subject,
            content: model.systemNotificationInfo.resetPassword.content,
        }
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.testResetPassword(body)
            let tempState = {
                isLoading: false,
                isTestReset: null
            }
            if (!!res && res.result === true) {
                tempState.isTestReset = true
            } else {
                tempState.isTestReset = false
            }
            this.setState(tempState)
        })
    }

    getModalTestForgotPassword = (isTestForgot) => {
        const { t } = this.props
        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("setting.testForgotPassword"),
            size: "mini",
            content: <div style={{ textAlign: "center" }}>
                {
                    isTestForgot === true ?
                        <h3 style={{ color: "#0f0" }}>{t("setting.testForgotPasswordSuccess")}</h3>
                        : <h3 style={{ color: "#f00" }}>{t("setting.testForgotPasswordFail")}</h3>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isTestForgot: null })
                })
            ]
        }))
    }

    getModalTestResetPassword = (isTestReset) => {
        const { t } = this.props
        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("setting.testResetPassword"),
            size: "mini",
            content: <div style={{ textAlign: "center" }}>
                {
                    isTestReset === true ?
                        <h3 style={{ color: "#0f0" }}>{t("setting.testResetPasswordSuccess")}</h3>
                        : <h3 style={{ color: "#f00" }}>{t("setting.testResetPasswordFail")}</h3>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isTestReset: null })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, model, errors, optionsSystemIntegration, optionsSystemIntegrationWithLocal, optionsNotification, optionsResourceGroup } = this.props
            const { isTestForgot, isTestReset, activeIndex, isLoading } = this.state

            let tempOptionsNotification = [
                {
                    text: t("common.noSelect"),
                    value: defaultValue.NONE_SELECT,
                    key: defaultValue.NONE_SELECT,
                }
            ]
            tempOptionsNotification = tempOptionsNotification.concat(optionsNotification)

            let adminLoginSystemValidate = this.getErrorInput("loginInfo.enableLoginSystemIds", errors)
                , userLoginSystemValidate = this.getErrorInput("loginInfo.enableFrontendLoginSystemIds", errors)
                , defaultLoginSystemIdValidate = this.getErrorInput("loginInfo.defaultLoginSystemId", errors)
                , isSigninAndUpsertValidate = this.getErrorInput("isSigninAndUpsert", errors)
                , defaultGroupIdValidate = this.getErrorInput("loginInfo.defaultGroupId", errors)
                , systemNotificationValidate = this.getErrorInput("systemNotificationInfo.notificationId", errors)
                , forgotSubjectValidate = this.getErrorInput("systemNotificationInfo.forgotPassword.subject", errors)
                , forgotContentValidate = this.getErrorInput("systemNotificationInfo.forgotPassword.content", errors)
                , resetSubjectValidate = this.getErrorInput("systemNotificationInfo.resetPassword.subject", errors)
                , resetContentValidate = this.getErrorInput("systemNotificationInfo.resetPassword.content", errors)

            let componentLoginInfo = <p></p>

            if (activeIndex === 0 && !!model) {
                componentLoginInfo = <Tab.Pane attached={false}>
                    <Grid>
                        <Grid.Row>
                            {
                                Array.isArray(optionsSystemIntegration) && optionsSystemIntegration.length > 0 ?
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('setting.adminLoginSystem')} isRequire={false} />
                                        <div className="module multipleCheckboxContainer">
                                            <Segment className="group-segment">
                                                <div className="checkbox-wrapper">
                                                    <Form.Checkbox
                                                        label={t("common.selectAll")}
                                                        name={'loginInfo.enableLoginSystemIds'}
                                                        value={defaultValue.SELECT_ALL}
                                                        onChange={this.handleChangeBoolean}
                                                        checked={optionsSystemIntegration.length === model.loginInfo.enableLoginSystemIds.length}
                                                    />
                                                    {
                                                        optionsSystemIntegration.map((data, i) => {
                                                            let select = model.loginInfo.enableLoginSystemIds.find(id => id === data.value)
                                                            return <Form.Checkbox
                                                                key={i}
                                                                label={data.text}
                                                                name={'loginInfo.enableLoginSystemIds'}
                                                                value={data.value}
                                                                onChange={this.handleChangeBoolean}
                                                                checked={!!select}
                                                            />
                                                        })
                                                    }
                                                </div>
                                            </Segment>
                                        </div>
                                    </Grid.Column >
                                    : <Grid.Column width={16}>
                                        <LabelInput text={t('setting.adminLoginSystem')} isRequire={false} />
                                        <MultipleCheckboxList
                                            optionsDataList={[]}
                                        />
                                    </Grid.Column>
                            }
                            {
                                (adminLoginSystemValidate.isError &&
                                    adminLoginSystemValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {adminLoginSystemValidate.content}
                                    </Label>
                                </Form.Field>
                            }


                        </Grid.Row>
                        <Grid.Row>
                            {
                                Array.isArray(optionsSystemIntegration) && optionsSystemIntegration.length > 0 ?
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('setting.userLoginSystem')} isRequire={false} />
                                        <div className="module multipleCheckboxContainer">
                                            <Segment className="group-segment">
                                                <div className="checkbox-wrapper">
                                                    <Form.Checkbox
                                                        label={t("common.selectAll")}
                                                        name={'loginInfo.enableFrontendLoginSystemIds'}
                                                        value={defaultValue.SELECT_ALL}
                                                        onChange={this.handleChangeBoolean}
                                                        checked={optionsSystemIntegration.length === model.loginInfo.enableFrontendLoginSystemIds.length}
                                                    />
                                                    {
                                                        optionsSystemIntegration.map((data, i) => {
                                                            let select = model.loginInfo.enableFrontendLoginSystemIds.find(id => id === data.value)
                                                            return <Form.Checkbox
                                                                key={i}
                                                                label={data.text}
                                                                name={'loginInfo.enableFrontendLoginSystemIds'}
                                                                value={data.value}
                                                                onChange={this.handleChangeBoolean}
                                                                checked={!!select}
                                                            />
                                                        })
                                                    }
                                                </div>
                                            </Segment>
                                        </div>
                                    </Grid.Column >
                                    : <Grid.Column width={16}>
                                        <LabelInput text={t('setting.userLoginSystem')} isRequire={false} />
                                        <MultipleCheckboxList
                                            optionsDataList={[]}
                                        />
                                    </Grid.Column>
                            }
                            {
                                (userLoginSystemValidate.isError &&
                                    userLoginSystemValidate.content) &&
                                <Form.Field>
                                    <Label pointing prompt>
                                        {userLoginSystemValidate.content}
                                    </Label>
                                </Form.Field>
                            }
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('setting.defaultLoginSystem')} isRequire={true} />
                                <Form.Dropdown
                                    selection
                                    selectOnBlur={false}
                                    placeholder={t("common.pleaseSelect")}
                                    name="defaultLoginSystemId"
                                    value={model.loginInfo.defaultLoginSystemId}
                                    options={optionsSystemIntegrationWithLocal}
                                    onChange={this.handleChange}
                                    error={defaultLoginSystemIdValidate.isError ? defaultLoginSystemIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row >
                            <Grid.Column width={16}>
                                <Form.Checkbox
                                    label={t("setting.signinAndUpsert")}
                                    name="isSigninAndUpsert"
                                    onChange={this.handleChange}
                                    error={isSigninAndUpsertValidate.isError ? isSigninAndUpsertValidate.content : null}
                                    checked={model.loginInfo.isSigninAndUpsert}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {
                            model.loginInfo.isSigninAndUpsert &&
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('setting.defaultResourceGroup')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="defaultGroupId"
                                        value={model.loginInfo.defaultGroupId}
                                        options={optionsResourceGroup}
                                        onChange={this.handleChange}
                                        error={defaultGroupIdValidate.isError ? defaultGroupIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </Grid>
                </Tab.Pane>
            }

            let componentSystemNoti = <p></p>

            if (activeIndex === 1 && !!model) {
                componentSystemNoti = <Tab.Pane attached={false}>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput
                                    text={t('notification.title')}
                                    isRequire={(model.systemNotificationInfo.forgotPassword.isEnable === true || model.systemNotificationInfo.resetPassword.isEnable === true)}
                                />
                                <Form.Dropdown
                                    selection
                                    selectOnBlur={false}
                                    placeholder={t("common.pleaseSelect")}
                                    name="systemNotificationInfo.notificationId"
                                    value={model.systemNotificationInfo.notificationId}
                                    options={tempOptionsNotification}
                                    onChange={this.handleChange}
                                    error={systemNotificationValidate.isError ? systemNotificationValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('setting.forgotPassword')} isRequire={false} />
                                <Segment className="group-segment-input">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Form.Checkbox
                                                    label={t("common.enable")}
                                                    name={'systemNotificationInfo.forgotPassword.isEnable'}
                                                    onChange={this.handleChangeBoolean}
                                                    checked={model.systemNotificationInfo.forgotPassword.isEnable}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("common.subject")} isRequire={model.systemNotificationInfo.forgotPassword.isEnable} />
                                                <Form.Input
                                                    name={'systemNotificationInfo.forgotPassword.subject'}
                                                    value={model.systemNotificationInfo.forgotPassword.subject}
                                                    onChange={this.handleChange}
                                                    error={forgotSubjectValidate.isError ? forgotSubjectValidate.content : null}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("common.content")} isRequire={model.systemNotificationInfo.forgotPassword.isEnable} />
                                                <InputHtml
                                                    isTextMode={true}
                                                    name={'systemNotificationInfo.forgotPassword.content'}
                                                    value={model.systemNotificationInfo.forgotPassword.content}
                                                    onChange={this.handleChange}

                                                />
                                                {
                                                    (forgotContentValidate.isError) &&
                                                    <Form.Field>
                                                        <Label pointing prompt>
                                                            {forgotContentValidate.content}
                                                        </Label>
                                                    </Form.Field>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t('setting.testForgotPassword')}`,
                                                        color: "blue",
                                                        onClick: () => this.onTestForgotPassword(model)
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t('setting.resetPassword')} isRequire={false} />
                                <Segment className="group-segment-input">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <Form.Checkbox
                                                    label={t("common.enable")}
                                                    name={'systemNotificationInfo.resetPassword.isEnable'}
                                                    onChange={this.handleChangeBoolean}
                                                    checked={model.systemNotificationInfo.resetPassword.isEnable}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("common.subject")} isRequire={model.systemNotificationInfo.resetPassword.isEnable} />
                                                <Form.Input
                                                    name={'systemNotificationInfo.resetPassword.subject'}
                                                    value={model.systemNotificationInfo.resetPassword.subject}
                                                    onChange={this.handleChange}
                                                    error={resetSubjectValidate.isError ? resetSubjectValidate.content : null}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("common.content")} isRequire={model.systemNotificationInfo.resetPassword.isEnable} />
                                                <InputHtml
                                                    isTextMode={true}
                                                    name={'systemNotificationInfo.resetPassword.content'}
                                                    value={model.systemNotificationInfo.resetPassword.content}
                                                    onChange={this.handleChange}

                                                />
                                                {
                                                    (resetContentValidate.isError) &&
                                                    <Form.Field>
                                                        <Label pointing prompt>
                                                            {resetContentValidate.content}
                                                        </Label>
                                                    </Form.Field>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewButton
                                                    model={new ButtonModel({
                                                        text: `${t('setting.testResetPassword')}`,
                                                        color: "blue",
                                                        onClick: () => this.onTestResetPassword(model)
                                                    })}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Tab.Pane>
            }

            const panes = [
                { menuItem: 'Login', pane: componentLoginInfo },
                { menuItem: 'System Notification', pane: componentSystemNoti },
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {(isTestForgot !== null && isTestForgot !== undefined) && this.getModalTestForgotPassword(isTestForgot)}
                    {(isTestReset !== null && isTestReset !== undefined) && this.getModalTestResetPassword(isTestReset)}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <div className="customTab">
                            <Tab
                                menu={{ secondary: true, pointing: true }}
                                activeIndex={activeIndex}
                                onTabChange={this.handleTabChange}
                                panes={panes}
                                renderActiveOnly={false}
                            />
                        </div>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SettingSensorForm.defaultProps = {
    model: null,
    errors: [],
    optionsSystemIntegration: [],
    optionsSystemIntegrationWithLocal: [],
    optionsNotification: [],
    optionsResourceGroup: [],
    handleChange: () => console.error("Please provide \"Change Resource Group\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SettingSensorForm)