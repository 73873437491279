
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetRoleList = () => {
	return {
		type: type.RESET_ROLE_LIST
	}
}

const getRoleInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/role/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROLE_INFO_REQUEST,
				{
					type: type.GET_ROLE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROLE_INFO_FAILURE
			]
		}
	}
}

const getRoleList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/role${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ROLE_LIST_REQUEST,
				{
					type: type.GET_ROLE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ROLE_LIST_FAILURE
			]
		}
	}
}

export {
	getRoleInfo,
	getRoleList,
	resetRoleList
}