
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getLocationTypeInfo, getLocationTypeList } from '../../actions/locationTypeActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import LocationTypeManager from '../../business/LocationTypeManager'
import ViewLocationType from '../../views/locationType/ViewLocationType'
import { getLocationList } from '../../actions/locationActions'

class ViewLocationTypeController extends MyController {
	constructor(props) {
		super(props)
		this.bLocationType = new LocationTypeManager(this)
	}

	getPropsResetStatus() {
		return ["locationType", "locationList", "locationTypeList"]
	}

	testSyncPlatformLocationType = (id, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocationType.locationTypeTestSyncPlatform(id, (res) => {
				let tempState = {
					isLoadingComponent: false,
				}

				let action = null

				if (typeof callBack === "function") {
					action = callBack(res)
				}

				this.setState(tempState, action)
			})
		})
	}

	render() {
		try {
			const { locationType, locationList, locationTypeList } = this.props

			const locationTypeData = locationType.getData()
				, locationListData = locationList.getDatas()
				, locationTypeListDatas = locationTypeList.getDatas()

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					{/* (!this.requestFinishedAfterRequest() && this.getLoadingPage()) */}
					<div className="body">
						<ViewLocationType
							model={locationTypeData}
							// onReloadData={this.props.getLocationList}
							locationList={locationListData}
							locationTypeList={locationTypeListDatas}
							testSyncPlatformLocationType={this.testSyncPlatformLocationType}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationType: state.locationType,
			locationList: state.locationList,
			locationTypeList: state.locationTypeList
		}),
		(dispatch, props) => ({
			getLocationTypeInfo() {
				dispatch(getLocationTypeInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList(query) {
				dispatch(getLocationList(query))
			},
			getLocationTypeList(query) {
				dispatch(getLocationTypeList(query))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationTypeInfo()
			this.props.getLocationTypeList()
			this.props.getLocationList()
		}
	}),
)


export default enhance(ViewLocationTypeController)