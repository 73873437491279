
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Modal } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import ButtonModel from '../../core/model/ButtonModel'
import { areaTypes } from '../../../constant'
import { CORE_API_ENDPOINT } from '../../lib'
import ViewButton from './ViewButton'
import AreaModel from '../../models/AreaModel'
import utils from '../../core/utility/utils'

const renderView = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    VIEW: "VIEW"
}

class ViewChildArea extends MyPage {
    constructor(props) {
        super(props)
        this.state.stateChildList = JSON.parse(JSON.stringify(props.childList))
        this.state.convertedChildList = []

        let model = new AreaModel()
        if (!!props.model) {
            model = new AreaModel(props.model)
            model.points = []
        }
        this.state.open = true
        this.state.model = model
        this.state.timeTemp = moment().format("YYYYMMDDHHmmss")
        this.state.widthBoxMap = null
        this.state.imageData = null
        this.state.optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(props.locationList, "id", "parentId", null, "name", "id")
        this.state.positionHover = null
    }

    componentDidMount() {
        this.getWidthHeightBoxCamera()
    }

    getWidthHeightBoxCamera = () => {
        var el = document.getElementById("vsa-m-wrapper")
        if (!!el && el.offsetHeight > -1) {
            this.setState({
                widthBoxMap: el.offsetWidth
            })
        }
    }

    onOpenModal = () => {
        this.setState({
            open: true
        }, this.getWidthHeightBoxCamera)
    }
    onCloseModal = () => {
        this.setState({
            open: false
        }, this.props.onClose)
    }

    onLoadImage = (data) => {
        if (!!data) {
            const { stateChildList } = this.state
            const img = data.target
            let imageData = {
                height: img.naturalHeight,
                width: img.naturalWidth,
                heightDisplay: img.offsetHeight,
                widthDisplay: img.offsetWidth,
            },
                tempState = {
                    imageData
                }

            this.setState(tempState)
            this.convertNaturalSizeToDisplaySize(imageData, stateChildList)
        }

    }

    convertNaturalSizeToDisplaySize = (imageData, childList) => {
        try {
            let tempChildList = []
            if (!!imageData && Array.isArray(childList) && childList.length > 0) {

                tempChildList = childList.map(child => {

                    let newChildAreas = child.areas.map(areas => {
                        let newPointAreas = areas.points.map(points => {
                            return {
                                order: points.order,
                                x: Math.round((points.x * imageData.widthDisplay) / imageData.width),
                                y: Math.round((points.y * imageData.heightDisplay) / imageData.height)
                            }
                        })
                        areas.points = newPointAreas
                        return areas
                    })

                    child.areas = newChildAreas

                    return child
                })
            }

            this.setState({
                convertedChildList: tempChildList
            })
        } catch (error) {
            this.setState({
                convertedChildList: []
            })
        }
    }

    getAreaSelect = (imageData, childList) => {
        try {
            let pointStyle = {
                height: 5,
                width: 5
            }

            let content = null
            if (!!imageData && Array.isArray(childList) && childList.length > 0) {
                let contentArray = childList.map(child => {
                    let newAreaDraw = child.areas.map(areas => {
                        let pointContent = null
                        if (areas.type === areaTypes.POLYGON) {
                            pointContent = <g>
                                <polygon
                                    className="area-style"
                                    points={areas.points.map(p => p.x + "," + p.y).join(" ")}
                                ><title>{child.name}</title></polygon>
                                {
                                    areas.points.map((p, i) => {
                                        return <rect key={i} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={p.x - (pointStyle.width / 2)} y={p.y - (pointStyle.height / 2)}></rect>
                                    })
                                }
                            </g>
                        } else if (areas.type === areaTypes.CIRCLE) {
                            let startPoint = areas.points[0]
                                , radiusPoint = null
                                , radius = null
                            if (areas.points.length >= 2) {
                                radiusPoint = areas.points[1]
                            }
                            if (!!radiusPoint) {
                                radius = Math.sqrt(Math.pow(Math.abs(startPoint.x - radiusPoint.x), 2) + Math.pow(Math.abs(startPoint.y - radiusPoint.y), 2))
                            }
                            pointContent = <g>
                                {
                                    !!startPoint && !!radius &&
                                    <circle className="area-style"
                                        cx={startPoint.x} cy={startPoint.y} r={radius}
                                    ><title>{child.name}</title></circle>
                                }
                                <rect key={1} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={startPoint.x - (pointStyle.width / 2)} y={startPoint.y - (pointStyle.height / 2)}></rect>
                                {!!radiusPoint && <rect key={2} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={radiusPoint.x - (pointStyle.width / 2)} y={radiusPoint.y - (pointStyle.height / 2)}></rect>}
                            </g>
                        }

                        return pointContent
                    })

                    return newAreaDraw
                })

                content = contentArray
            }

            return content
        } catch (error) {
            return null
        }
    }

    render() {
        try {
            const { t, viewType, locationId } = this.props
            const { widthBoxMap, open, timeTemp, imageData, convertedChildList } = this.state

            return (
                <React.Fragment>
                    <Modal
                        className="module modalViewChildArea"
                        size="large"
                        open={open}
                        onClose={this.onCloseModal}
                    >
                        <Modal.Content className="module viewChildArea">
                            <div className="vsa-wrapper">
                                <div className="vsa-form">
                                    <div className="vsa-f-wrapper">
                                        <h2>{t("common.viewChildArea")}</h2>
                                    </div>
                                </div>
                                <div className="vsa-map">
                                    <div className="vsa-m-wrapper" id="vsa-m-wrapper" style={{ height: widthBoxMap > 0 ? ((widthBoxMap / 16) * 9) : 0 }}>
                                        <div className="map-wrapper">
                                            <div className="map-image">
                                                <img src={`${CORE_API_ENDPOINT}/image/location/${locationId}?time=${timeTemp}`} alt="No map" onLoad={this.onLoadImage} />
                                                <svg className={`location-svg ${viewType === renderView.VIEW ? "disabled" : ""}`}
                                                    onContextMenu={viewType !== renderView.VIEW ? this.onClickClearPoint : () => { }}
                                                >
                                                    {
                                                        viewType !== renderView.VIEW &&
                                                        <g className="l-svg-body">
                                                            <rect height="100%" width="100%" fill="#fff0"></rect>
                                                        </g>
                                                    }
                                                    {this.getAreaSelect(imageData, convertedChildList)}
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="vsa-action">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.back'),
                                            color: "grey",
                                            onClick: this.onCloseModal
                                        })}
                                    />
                                </div>
                            </div>
                        </Modal.Content>
                    </Modal>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ViewChildArea.defaultProps = {
    childList: [],
    locationId: "",
    onClose: () => console.error("Please provide Close action."),
    viewType: renderView.CREATE
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewChildArea)