
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import RoleManager from '../../business/RoleManager'
import RoleCreate from '../../views/role/RoleCreate'

class CreateRoleController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bRole = new RoleManager(this)
	}

	onCreateRole = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRole.createRole(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/role/view/${res.data.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onClickBack = () => {
		this.props.history.push(`/role`)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					<RoleCreate
						isLoadingControl={isLoadingComponent}
						onCreate={this.onCreateRole} />
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {

		}
	}),
)


export default enhance(CreateRoleController)