
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import SystemIntegrationModel from '../models/SystemIntegrationModel'
import ActiveDirectoryDataModel from '../models/systemIntegrationData/ActiveDirectoryDataModel'
import EWSDataModel from '../models/systemIntegrationData/EWSDataModel'
import GoogleAuthenDataModel from '../models/systemIntegrationData/GoogleAuthenDataModel'
import GoogleServiceAccountDataModel from '../models/systemIntegrationData/GoogleServiceAccountDataModel'
import O365DataModel from '../models/systemIntegrationData/O365DataModel'
import ProfileCenterDataModel from '../models/systemIntegrationData/ProfileCenterDataModel'
import SendGridDataModel from '../models/systemIntegrationData/SendGridDataModel'
import SMTPDataModel from '../models/systemIntegrationData/SMTPDataModel'
import LineDataModel from '../models/systemIntegrationData/LineDataModel'
import SMS8X8DataModel from '../models/systemIntegrationData/SMS8X8DataModel'
import { serviceCode } from '../../constant'
import PagingDataModel from '../core/model/PagingDataModel'

export default class SystemIntegrationManager extends MyManager {
    async getSystemIntegrationServiceList() {
        try {
            const option = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/systemintegration`,
            }
            let res = await this.requestAction(option)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async getSystemIntegrationServiceInfo(id, callback) {
        try {
            const option = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            }
            let res = await this.requestAction(option, callback)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async createSystemIntegration(model, callback) {
        try {
            const option = {
                method: this.requestMethod.POST,
                url: `${CORE_API_ENDPOINT}/systemintegration`,
                data: model
            }
            let res = await this.requestAction(option, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async updateSystemIntegration(id, model, callback) {
        try {
            const option = {
                method: this.requestMethod.PATCH,
                url: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
                data: model
            }
            let res = await this.requestAction(option, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async deleteIntegration(id, callback) {
        try {
            const option = {
                method: this.requestMethod.DELETE,
                url: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            }
            let res = await this.requestAction(option, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async testSystemIntegration(body) {
        try {
            const option = {
                method: this.requestMethod.POST,
                url: `${CORE_API_ENDPOINT}/systemintegration/test`,
                data: body,
                isHandleError: false
            }
            let res = await this.requestAction(option)
            return res
        } catch (error) {
            //this.setDefaultModalError(error)
            return false
        }
    }

    async getSystemIntegrationPlatform(query) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/systemintegration/platformdata/list${!!query ? query : ""}`
            }

            let res = await this.requestAction(options)
            let data = null
            if (!!res && res.result === true && !!res.data) {
                data = new PagingDataModel({ pagination: res.data.pagination })
                if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
                    data.datas = res.data.datas.map(d => {
                        return new SystemIntegrationModel(d)
                    })
                }
            }
            return data
        } catch (error) {
            this.setDefaultModalError(error)
            return null
        }
    }

    async importSystemIntegrationFromPlatform(body, callback) {
        try {
            const options = {
                method: this.requestMethod.POST,
                url: `${CORE_API_ENDPOINT}/systemintegration/import/platformdata`,
                data: body
            }

            let res = await this.requestAction(options, callback)
            return res
        } catch (error) {
            this.setDefaultModalError(error)
        }
    }

    getSystemIntegrationDataModel(service) {
        let model = null
        switch (service) {
            case serviceCode.ACTIVE_DIRECTORY:
                model = ActiveDirectoryDataModel
                break;
            case serviceCode.EXCHANGE:
                model = EWSDataModel
                break;
            case serviceCode.GOOGLE_AUTHEN:
                model = GoogleAuthenDataModel
                break;
            case serviceCode.GOOGLE_SERVICE_ACCOUNT:
                model = GoogleServiceAccountDataModel
                break;
            case serviceCode.O365:
                model = O365DataModel
                break;
            case serviceCode.PROFILE_CENTER:
                model = ProfileCenterDataModel
                break;
            case serviceCode.SEND_GRID:
                model = SendGridDataModel
                break;
            case serviceCode.SMTP:
                model = SMTPDataModel
                break;
            case serviceCode.LINE:
                model = LineDataModel
                break;
            case serviceCode.SMS_8X8:
                model = SMS8X8DataModel
                break;
            // case serviceCode.ZOOM:
            //     model = new ZoomDataModel()
            //     break;
            default:
                break;
        }
        return model
    }

    async getSystemIntegrationListPaging(query) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/systemintegration/page${!!query ? query : ""}`,
            }

            let res = await this.requestAction(options)

            let data = null

            if (!!res && res.result === true && !!res.data) {
                data = new PagingDataModel({ pagination: res.data.pagination })
                if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
                    data.datas = res.data.datas.map(d => {
                        return new SystemIntegrationModel(d)
                    })
                }
            }

            return data
        } catch (error) {
            this.setDefaultModalError(error)
            return null
        }
    }

    async systemIntegrationTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/systemintegration/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}