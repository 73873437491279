
import React from 'react'
import { sensorRuleStep } from '../../../constant'
import Step from '../../core/business/step/Step'

import SensorInformationView from '../../views/sensorRuleStep/step/SensorInformationView'
import SensorInformationModel from '../../models/sensorRuleData/sensorRuleStep/SensorInformationModel'

export default class SensorInformationStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "common.information"
		this.key = sensorRuleStep.SENSOR_INFORMATION
		this.data = new SensorInformationModel()
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data && data[this.key] instanceof SensorInformationModel) {
			this.data = new SensorInformationModel(data[this.key])
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		if (!!data && data instanceof SensorInformationModel) {
			allResult[this.key] = data
		}
		return allResult
	}

	nextStep(data) {
		this.data = new SensorInformationModel(data)
		super.nextStep()
	}

	prevStep(data) {
		this.data = new SensorInformationModel(data)
		super.prevStep()
	}

	saveStep(data, callback) {
		this.data = new SensorInformationModel(data)
		super.saveStep()
		if (typeof callback === "function") {
			callback(this.key)
		} else {
			console.error("Please provide Callback Submit function for Save Step.")
		}
	}

	render(dataConfig) {
		return <SensorInformationView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
			onSave={(data) => this.saveStep(data, dataConfig.onSubmit)}
		/>
	}
}