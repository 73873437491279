
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'
import types from '../types'

const getVisitorInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/visitor/${id}`,
            method: `GET`,
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_VISITOR_INFO_REQUEST,
                {
                    type: types.GET_VISITOR_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_VISITOR_INFO_FAILURE
            ]
        }
    }
}

const getVisitorList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitor${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_VISITOR_LIST_REQUEST,
				{
					type: type.GET_VISITOR_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_VISITOR_LIST_FAILURE
			]
		}
	}
}

const getVisitorStatusList = () => {
	return {
		
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/visitor/status`,
			method: `GET`,
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_VISITOR_STATUS_LIST_REQUEST,
				{
					type: type.GET_VISITOR_STATUS_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_VISITOR_STATUS_LIST_FAILURE
			]
		}
	}
}

export {
    getVisitorInfo,
    getVisitorList,
    getVisitorStatusList
}