import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetDoorList = () => {
	return {
		type: type.RESET_DOOR_LIST
	}
}

const getDoorInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/door/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_DOOR_INFO_REQUEST,
				{
					type: type.GET_DOOR_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_DOOR_INFO_FAILURE
			]
		}
	}
}

const getDoorWithIOTConfigInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/door/withstatus/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_DOOR_WITH_IOT_CONFIG_INFO_REQUEST,
				{
					type: type.GET_DOOR_WITH_IOT_CONFIG_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_DOOR_WITH_IOT_CONFIG_INFO_FAILURE
			]
		}
	}
}

const getDoorSceneInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/door/scene/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_DOOR_SCENE_INFO_REQUEST,
				{
					type: type.GET_DOOR_SCENE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_DOOR_SCENE_INFO_FAILURE
			]
		}
	}
}

const getDoorList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/door${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_DOOR_LIST_REQUEST,
				{
					type: type.GET_DOOR_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_DOOR_LIST_FAILURE
			]
		}
	}
}

export {
    getDoorInfo,
    getDoorList,
	getDoorSceneInfo,
	getDoorWithIOTConfigInfo,
	resetDoorList
}