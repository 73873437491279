
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import AlertMessageDataModel from '../../../models/sensorRuleData/actionData/AlertMessageDataModel'

class AlertMessageDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors, optionsNotification } = this.props
            let notificationIdValidate = this.getErrorInput("notificationId", errors)
                , contentValidate = this.getErrorInput("content", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.alertMessageNotification")} isRequire={true} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsNotification}
                                        onChange={this.handleChange}
                                        name="notificationId"
                                        value={model.notificationId}
                                        placeholder={t("common.pleaseSelect")}
                                        error={notificationIdValidate.isError ? notificationIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.alertMessageContent")} isRequire={true} />
                                    <Form.Input
                                        name="content"
                                        value={model.content}
                                        onChange={this.handleChange}
                                        error={contentValidate.isError ? contentValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

AlertMessageDataForm.defaultProps = {
    model: new AlertMessageDataModel(),
    errors: [],
    optionsNotification: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(AlertMessageDataForm)