
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import SensorModel from '../../models/SensorModel'
import LabelInput from '../common/LabelInput'
// import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ViewSelectArea from '../common/ViewSelectArea'
import ViewNoResult from '../common/ViewNoResult'

class SensorView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.addPoint = false
    }

    onCreate = () => {
        this.props.history.push(`/sensor/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/sensor/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/sensor`)
    }

    onClickAddPoint = () => {
        this.setState({
            addPoint: true
        })
    }

    onCreateArea = (modelArea) => {
        const { model } = this.props
        this.setState({
            addPoint: false
        }, this.props.onUpsertAreaSensor(model, {
            area: [
                modelArea.serializeAPI()
            ]
        }))
    }

    onCloseAddPoint = () => {
        this.setState({
            addPoint: false
        })
    }

    onConfirmAreaDelete = (areaInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onDeleteAreaSensor(model, areaInfo)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t, locationList } = this.props
        const columns = [
            {
                name: t("location.title"),
                selector: (row, i) => {
                    if (row.locationId === null) {
                        return ""
                    } else {
                        let locationFind = locationList.find(e => e.id === row.locationId)
                        if (!!locationFind) {
                            return locationFind.name
                        } else {
                            return '-'
                        }

                    }

                },
                sortable: false
            },
            {
                name: t("locationType.title"),
                selector: (row) => row.locationTypeCode ? row.locationTypeCode : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: '',
                width: '250px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmAreaDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getServiceColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.service"),
                selector: (row) => row.service ? row.service : '-',
                sortable: false
            },
            {
                name: '',
                width: '300px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmUnIntegrate(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    onConfirmUnIntegrate = (serviceInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onUnintegtationSensor(model, serviceInfo)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    render() {
        try {
            const { t, locationList } = this.props
            const { model, addPoint } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            return (
                <React.Fragment >
                    {super.render()}
                    {addPoint && <ViewSelectArea locationList={locationList} onSubmit={this.onCreateArea} onClose={this.onCloseAddPoint} />}
                    < LayoutWithSidebarHeaderFooter textHeader={t('sensor.viewSensor')} buttons={buttons} >
                        <div className="module SensorView">
                            <Container text>
                                {
                                    !model ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewNoResult />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        : <React.Fragment>
                                            <div className="Sensor-wrapper">
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('controller.title')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.controller ? model.controller.mac : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('sensor.module')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.moduleCode ? model.moduleCode : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('sensor.type')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.sensorType ? model.sensorType : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('sensor.model')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.sensorModel ? model.sensorModel : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('sensor.sn')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.sensorSN ? model.sensorSN : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('common.description')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t("sensor.serial")} /></Table.Cell>
                                                            <Table.Cell>
                                                                <div className="minThree">
                                                                    {
                                                                        Array.isArray(model.serials) && model.serials.length > 0 ?
                                                                            model.serials.map((ser) => {
                                                                                return <p>• {ser}</p>
                                                                            })
                                                                            : '-'
                                                                    }
                                                                </div>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('locationType.title')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.location.type ? model.location.type : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('location.title')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.location.name ? model.location.name : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        {
                                                            (!!model && !!model.moduleCode && model.moduleCode === "RS485") &&
                                                            <Table.Row>
                                                                <Table.Cell><LabelInput text={t('sensor.config')} /></Table.Cell>
                                                                <Table.Cell>
                                                                    <Grid columns={2}>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={16}>
                                                                                <p><b>{t('sensor.channel') + ': '}</b>{!!model.configValue.channel ? `${model.configValue.channel}` : '-'}</p>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                        <Grid.Row>
                                                                            <Grid.Column width={16}>
                                                                                <p><b>{t('sensor.buadRate') + ': '}</b>{!!model.configValue.buadRate ? `${model.configValue.buadRate}` : '-'}</p>
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    </Grid>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        }
                                                    </Table.Body>
                                                </Table>
                                            </div>
                                        </React.Fragment>
                                }
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SensorView.defaultProps = {
    model: new SensorModel(),
    locationList: []
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SensorView)