
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'

import LocalLoginForm from './LocalLoginForm'
import ADLoginForm from './ADLoginForm'
import ProfileCenterLoginForm from './ProfileCenterLoginForm'
import ExchangeLoginForm from './ExchangeLoginForm'

import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { serviceCode } from '../../../constant'

class LoginForm extends MyForm {

    getFormLoginByService = (serviceActive, model, errors) => {
        const { onSubmitForm, t, loginList } = this.props
        let info = loginList.find(d => d.id === serviceActive)
        let service = null
        if (!!info) {
            service = info.service
        }
        let Component = null
        if (service === serviceCode.LOCAL) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="il-form">
                    <LocalLoginForm model={model} errors={errors} handleChange={this.handleChange} onSubmitForm={onSubmitForm} />
                </div>
                <div className="il-action">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.login"),
                            color: "blue",
                            onClick: onSubmitForm
                        })}
                    />
                </div>
            </div>
        } else if (service === serviceCode.ACTIVE_DIRECTORY) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="il-form">
                    <ADLoginForm model={model} errors={errors} handleChange={this.handleChange} onSubmitForm={onSubmitForm} />
                </div>
                <div className="il-action">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.login"),
                            color: "blue",
                            onClick: onSubmitForm
                        })}
                    />
                </div>
            </div>
        } else if (service === serviceCode.PROFILE_CENTER) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="il-form">
                    <ProfileCenterLoginForm model={model} errors={errors} handleChange={this.handleChange} onSubmitForm={onSubmitForm} />
                </div>
                <div className="il-action">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.login"),
                            color: "blue",
                            onClick: onSubmitForm
                        })}
                    />
                </div>
            </div>
        } else if (service === serviceCode.EXCHANGE) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="il-form">
                    <ExchangeLoginForm model={model} errors={errors} handleChange={this.handleChange} onSubmitForm={onSubmitForm} />
                </div>
                <div className="il-action">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.login"),
                            color: "blue",
                            onClick: onSubmitForm
                        })}
                    />
                </div>
            </div>
        } else if (service === serviceCode.O365) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="loginButton">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.o365"),
                            color: "blue",
                            iconClass: "windows",
                            disabled: true,
                            externalProps: {
                                labelPosition: "left"
                            },
                            // onClick: () => this.onToggleLogin(serviceCode.EXCHANGE)
                        })}
                    />
                </div>
            </div>
        } else if (service === serviceCode.GOOGLE_AUTHEN || service === serviceCode.GOOGLE_SERVICE_ACCOUNT) {
            Component = <div className={`item-login ${service}`} key={service}>
                <div className="loginButton">
                    <ViewButton
                        model={new ButtonModel({
                            text: t("auth.google"),
                            color: "google plus",
                            iconClass: "google",
                            disabled: true,
                            externalProps: {
                                labelPosition: "left"
                            },
                            // onClick: () => this.onToggleLogin(serviceCode.EXCHANGE)
                        })}
                    />
                </div>
            </div>
        }
        return Component
    }

    render() {
        const { t, model, errors, serviceActive, optionsLoginList } = this.props
        let ComponentForm = this.getFormLoginByService(serviceActive, model, errors)
        try {
            return (
                <React.Fragment>
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid className="grid-half-padding">
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("auth.service")} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsLoginList}
                                        onChange={this.handleChange}
                                        name="serviceActive"
                                        value={serviceActive}
                                        placeholder={t("common.pleaseSelect")}
                                        error={optionsLoginList.length > 0 && !serviceActive ? t("validate.require") : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                !!ComponentForm &&
                                <Grid.Row >
                                    <Grid.Column width={16}>
                                        {ComponentForm}
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

LoginForm.defaultProps = {
    model: {},
    serviceActive: null,
    optionsLoginList: [],
    loginList: [],
    onSubmitForm: () => console.error("Please provide Submit action.")
}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)

export default enhance(LoginForm)