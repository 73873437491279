
import types from '../types'
import { requestStatus } from '../../constant'
import SettingSensorResource from '../selectors/settingSensorResource'
import SettingSensorDataResource from '../selectors/settingSensorDataResource'

const settingSensorReducer = {
	settingSensorInfo: (state = null, action) => {
		if (!state || !(state instanceof SettingSensorResource)) {
			state = new SettingSensorResource()
		}

		switch (action.type) {
			case types.GET_SETTING_SENSOR_INFO_REQUEST:
				state = new SettingSensorResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_SENSOR_INFO_SUCCESS:
				state = new SettingSensorResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_SENSOR_INFO_FAILURE:
				state = new SettingSensorResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	settingSensorTypeList: (state = null, action) => {
		if (!state || !(state instanceof SettingSensorDataResource)) {
			state = new SettingSensorDataResource()
		}

		switch (action.type) {
			case types.GET_SETTING_SENSOR_TYPE_LIST_REQUEST:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_SENSOR_TYPE_LIST_SUCCESS:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_SENSOR_TYPE_LIST_FAILURE:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	settingSensorStandardOperationList: (state = null, action) => {
		if (!state || !(state instanceof SettingSensorDataResource)) {
			state = new SettingSensorDataResource()
		}

		switch (action.type) {
			case types.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_REQUEST:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_SUCCESS:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_SENSOR_STANDARD_OPERATION_LIST_FAILURE:
				state = new SettingSensorDataResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default settingSensorReducer