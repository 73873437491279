
import React from 'react'
import { eventControlRuleStep } from '../../../../constant'
import Step from '../../../core/business/step/Step'

import EventSourceView from '../../../views/eventControlRuleStep/view_step/EventSourceView'
import EventControlRuleSourceModel from '../../../models/eventControlRuleStep/EventControlRuleSourceModel'

export default class EventControlRuleSourceStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "eventControlRule.source"
		this.key = eventControlRuleStep.SOURCE
		this.data = new EventControlRuleSourceModel()
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data && data[this.key] instanceof EventControlRuleSourceModel) {
			this.data = new EventControlRuleSourceModel(data[this.key])
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		if (!!data && data instanceof EventControlRuleSourceModel) {
			allResult[this.key] = data
		}
		return allResult
	}

	nextStep(data) {
		this.data = new EventControlRuleSourceModel(data)
		super.nextStep()
	}

	prevStep(data) {
		this.data = new EventControlRuleSourceModel(data)
		super.prevStep()
	}

	render(dataConfig) {
		return <EventSourceView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
		/>
	}
}