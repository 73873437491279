
import React from 'react'
import Step from '../../core/business/step/Step'

import SelectInformationController from '../../controllers/user/importExternalUser/SelectInformationController'

export default class SelectInformationStep extends Step {

	constructor(stepList) {
		super(stepList)
		this.title = "common.information"
		this.key = "INFORMATION"
		this.data = {
			departmentId: "",
			resourceGroupIds: null,
		}
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data) {
			if ("departmentId" in data) {
				this.data.departmentId = data.departmentId
			}
			if ("resourceGroupIds" in data) {
				this.data.resourceGroupIds = data.resourceGroupIds
			}
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		allResult.departmentId = data.departmentId
		allResult.resourceGroupIds = data.resourceGroupIds
		return allResult
	}

	nextStep(data) {
		if (!!data) {
			this.data.departmentId = data.departmentId
			this.data.resourceGroupIds = data.resourceGroupIds
		}
		super.nextStep()
	}

	prevStep(data) {
		// if (typeof data === "boolean") {
		// 	this.isBypass = data
		// }
		super.prevStep()
	}

	render(dataConfig) {
		return <SelectInformationController
			// isRequire={this.isRequire}
			dataConfig={dataConfig}
			dataStep={this.data}
			// isBypass={this.isBypass}
			// controller={controller}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
		/>
	}
}