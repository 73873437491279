
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetLocationTypeList = () => {
	return {
		type: type.CORE_RESET_LOCATION_TYPE_LIST
	}
}

const getLocationTypeInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/locationtype/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_TYPE_REQUEST,
				{
					type: type.CORE_GET_LOCATION_TYPE_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_TYPE_FAILURE
			]
		}
	}
}

const getLocationTypeList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/locationtype${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.CORE_GET_LOCATION_TYPE_LIST_REQUEST,
				{
					type: type.CORE_GET_LOCATION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.CORE_GET_LOCATION_TYPE_LIST_FAILURE
			]
		}
	}
}

export {
	getLocationTypeInfo,
	getLocationTypeList,
	resetLocationTypeList
}