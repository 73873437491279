
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import GatewayModel from '../../models/GatewayModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchGateway extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new GatewayModel()
        this.state.model.status = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new GatewayModel({ code: "", status: defaultValue.SELECT_ALL })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch } = this.props

            return (
                <div className="module formSearchGateway">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid centered>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <LabelInput text={t("common.code")} isRequire={false} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchGateway.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new GatewayModel()
}

export default (translate(FormSearchGateway))