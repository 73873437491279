
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSystemIntegrationInfo } from '../../actions/systemIntegrationActions'
import SystemIntegrationManager from '../../business/SystemIntegrationManager'
import utils from '../../core/utility/utils'
import SystemIntegrationView from '../../views/systemIntegration/SystemIntegrationView'

class ViewSystemIntegrationController extends MyController {
	constructor(props) {
		super(props)

		this.bSystemIntegration = new SystemIntegrationManager(this)
	}

	getPropsResetStatus() {
		return ["systemIntegrationInfo"]
	}

	onTestSystemIntegration = async (model) => {
        try {
            return this.bSystemIntegration.testSystemIntegration(model.serializeAPI())
        } catch (error) {
            return false
        }
    }

	testSyncPlatformSystemIntegration = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bSystemIntegration.systemIntegrationTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { systemIntegrationInfo } = this.props
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const systemIntegration = systemIntegrationInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<SystemIntegrationView
						model={systemIntegration}
						onTestSystemIntegration={this.onTestSystemIntegration}
						testSyncPlatformSystemIntegration={this.testSyncPlatformSystemIntegration}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			systemIntegrationInfo: state.systemIntegrationInfo
		}),
		(dispatch, props) => ({
			getSystemIntegrationInfo() {
				dispatch(getSystemIntegrationInfo(utils.getParamFromProps(props, "id")))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSystemIntegrationInfo()
		}
	}),
)


export default enhance(ViewSystemIntegrationController)