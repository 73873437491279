
import types from '../types'
import { requestStatus } from '../../constant'
import SettingResource from '../selectors/settingResource'
import SettingSystemResource from '../selectors/settingSystemResource'
import settingLoginInfoResource from '../selectors/settingLoginInfoResource'

const settingReducer = {
	settingInfo: (state = null, action) => {
		if (!state || !(state instanceof SettingResource)) {
			state = new SettingResource()
		}

		switch (action.type) {
			case types.GET_SETTING_INFO_REQUEST:
				state = new SettingResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_INFO_SUCCESS:
				state = new SettingResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_INFO_FAILURE:
				state = new SettingResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	settingSystemList: (state = null, action) => {
		if (!state || !(state instanceof SettingSystemResource)) {
			state = new SettingSystemResource()
		}

		switch (action.type) {
			case types.GET_SETTING_SYSTEM_LIST_REQUEST:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_SYSTEM_LIST_SUCCESS:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_SYSTEM_LIST_FAILURE:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	settingLoginInfo: (state = null, action) => {
		if (!state || !(state instanceof settingLoginInfoResource)) {
			state = new settingLoginInfoResource()
		}

		switch (action.type) {
			case types.GET_AUTH_LOGIN_INFO_REQUEST:
				state = new settingLoginInfoResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_AUTH_LOGIN_INFO_SUCCESS:
				state = new settingLoginInfoResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_AUTH_LOGIN_INFO_FAILURE:
				state = new settingLoginInfoResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	settingNotificationList: (state = null, action) => {
		if (!state || !(state instanceof SettingSystemResource)) {
			state = new SettingSystemResource()
		}

		switch (action.type) {
			case types.GET_SETTING_NOTIFICATION_LIST_REQUEST:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SETTING_NOTIFICATION_LIST_SUCCESS:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SETTING_NOTIFICATION_LIST_FAILURE:
				state = new SettingSystemResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default settingReducer