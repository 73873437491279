import React from 'react'
import moment from 'moment'
import { translate } from 'react-switch-lang'
import { Card, Image } from 'semantic-ui-react'
import MyComponent from '../../components/MyComponent'

import { CORE_API_ENDPOINT } from '../../../lib/api'
import { dateTimeFormat } from '../../../../constant'

class ViewImageInfo extends MyComponent {

    constructor(props) {
        super(props)

        this.state.timeStamp = moment().format(dateTimeFormat.apiFullDateTime)
    }

    render() {
        const { t, src, textHeader } = this.props
        const { timeStamp } = this.state
        return (
            <React.Fragment>
                <div className="module viewImageInfo">
                    <div className="vii-wrapper">
                        <Card>
                            <Card.Content>
                                <Card.Header>{!!textHeader ? textHeader : t("common.image")}</Card.Header>
                            </Card.Content>
                            <Card.Content>
                                {
                                    !!src &&
                                    <Image
                                        src={`${CORE_API_ENDPOINT}/image/${src}?time=${timeStamp}`}
                                        size="medium"
                                        alt={`No ${!!textHeader ? textHeader : "Data"}`}
                                    />
                                }
                            </Card.Content>
                        </Card>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ViewImageInfo.defaultProps = {
    src: "",
    textHeader: ""
}


export default translate(ViewImageInfo)