
import ActionResourceObject from '../core/redux/ActionResourceObject'
import RoomModel from '../models/RoomModel'

export default class RoomResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new RoomModel(d))
			} else {
				result = new RoomModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

}