
import React from 'react'
import { translate } from 'react-switch-lang'
import { Icon, Dropdown, Pagination } from 'semantic-ui-react'
import MyController from '../../../components/MyController'
import PagingDataModel from '../../../model/PagingDataModel'
import PagingModel from '../../../model/PagingModel'

class PaginationComponent extends MyController {
    handlePaginationChange = (e, { activePage }) => {
        let pagingModel = new PagingModel(this.props.pagingDataModel.pagination)
        pagingModel.pageNumber = activePage
        this.onChangePagination(pagingModel)
    }
    onChangePagesize = (e, { value }, page) => {
        let pagingModel = new PagingModel(this.props.pagingDataModel.pagination)
        pagingModel.pageNumber = page
        pagingModel.pageSize = value
        this.onChangePagination(pagingModel)
    }

    onChangePagination = (model) => {
        const { onChangePagination } = this.props
        if (typeof onChangePagination === "function") {
            onChangePagination(model)
        }
    }

    render() {
        const { t, pagingDataModel } = this.props

        let boundaryRange = 1
            , mapDatas = []
            , siblingRange = 1
            , activePage = 0
            , totalPages = 0
            , pageSize = 0
            , totalItem = 0

        if ((pagingDataModel instanceof PagingDataModel)) {
            mapDatas = pagingDataModel.datas
            activePage = pagingDataModel.pagination.pageNumber
            pageSize = pagingDataModel.pagination.pageSize
            totalItem = pagingDataModel.pagination.totalItem
            totalPages = Math.ceil(pagingDataModel.pagination.totalItem / pagingDataModel.pagination.pageSize)
        }

        return (
            <React.Fragment>
                {
                    (totalPages > 0 && activePage > 0) &&
                    <div className="module viewTablePaging">
                        <div className="vt-custom-pagination">
                            <div className="vt-custom-pagination">
                                <div className="vt-c-p-rows">
                                    <div className="vt-c-p-rows-wrapper">
                                        <div>
                                            <p>{t("common.rowsPerPage")}:</p>
                                        </div>
                                        <div>
                                            <Dropdown
                                                selection
                                                fluid
                                                options={[
                                                    { value: 10, key: 10, text: 10 },
                                                    { value: 20, key: 20, text: 20 },
                                                    { value: 50, key: 50, text: 50 },
                                                    { value: 100, key: 100, text: 100 },
                                                ]}
                                                value={pageSize}
                                                onChange={(e, data) => this.onChangePagesize(e, data, data.value >= totalPages ? 1 : activePage)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="vt-c-p-of">
                                    <div className="vt-c-p-of-wrapper">
                                        <p>{`${((activePage - 1) * pageSize) + 1}-${((activePage - 1) * pageSize) + mapDatas.length}`} {t("common.rangeSeparator")} {totalItem}</p>
                                    </div>
                                </div>
                                <div className="vt-c-p-page">
                                    <Pagination
                                        onPageChange={this.handlePaginationChange}

                                        activePage={activePage}
                                        boundaryRange={boundaryRange}
                                        size='mini'
                                        siblingRange={siblingRange}
                                        totalPages={totalPages}

                                        ellipsisItem={{ content: <Icon name='ellipsis horizontal' />, icon: true }}
                                        firstItem={null}
                                        lastItem={null}
                                        prevItem={{ content: <Icon name='angle left' disabled={activePage <= 1} />, icon: true }}
                                        nextItem={{ content: <Icon name='angle right' disabled={activePage === totalPages} />, icon: true }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }
}

PaginationComponent.defaultProps = {
    pagingDataModel: null,
    onChangePagination: () => console.error("Please provide On Change Pagination action.")
}


export default translate(PaginationComponent)