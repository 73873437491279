import React from 'react'
import { Modal } from 'semantic-ui-react'
import ViewButton from '../../../views/common/ViewButton';

class MyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    render() {
        const { open, headerText, content, onClose, buttons, size, scrolling } = this.props
        return (
            <React.Fragment>
                <Modal
                    open={open}
                    onClose={onClose}
                    size={size}
                    className="module customModal"
                >
                    {
                        !!headerText &&
                        < Modal.Header className="modal-header">
                            <div className="m-h-wrapper">
                                <h2>{headerText}</h2>
                            </div>
                        </Modal.Header>
                    }
                    <Modal.Content className="modal-content" scrolling={scrolling}>
                        <Modal.Description>
                            <div className="m-c-wrapper">
                                {
                                    typeof content === "string" ?
                                        <p>{content}</p>
                                        : content
                                }
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    {
                        Array.isArray(buttons) && buttons.length > 0 &&
                        <Modal.Actions className="modal-action">
                            <div className="m-a-wrapper">
                                {
                                    buttons.map((action, i) => {
                                        return <ViewButton model={action} key={i} />
                                        // let propsBtn = {}
                                        // if (!!action.iconClass) {
                                        //     propsBtn.icon = true
                                        // }
                                        // if (!!action.color) {
                                        //     propsBtn.color = action.color
                                        // }
                                        // return <Button key={i} {...propsBtn} onClick={action.onClick}>
                                        //     {
                                        //         !!action.iconClass &&
                                        //         <Icon name={action.iconClass} />
                                        //     }
                                        //     {action.text}
                                        // </Button>
                                    })
                                }
                            </div>
                        </Modal.Actions>
                    }
                </Modal>
            </React.Fragment >
        )
    }
}

MyModal.defaultProps = {
    headerText: "",
    content: null,
    onClose: () => console.error("Please provide Close action"),
    size: "small",
    buttons: [],
    scrolling: false
}


export default MyModal