
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSystemIntegrationInfo, getSystemIntegrationServiceList } from '../../actions/systemIntegrationActions'
import SystemIntegrationManager from '../../business/SystemIntegrationManager'
import utils from '../../core/utility/utils'
import SystemIntegrationUpdate from '../../views/systemIntegration/SystemIntegrationUpdate'

class UpdateSystemIntegrationController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSystemIntegration = new SystemIntegrationManager(this)
	}

	getPropsResetStatus() {
		return ["systemIntegrationInfo"]
	}

	onUpdateSystemIntegration = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSystemIntegration.updateSystemIntegration(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/systemIntegration/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onTestSystemIntegration = async (model) => {
		try {
			return this.bSystemIntegration.testSystemIntegration(model.serializeAPI())
		} catch (error) {
			return false
		}
	}

	render() {
		try {
			const { t, systemIntegrationInfo, systemIntegrationServiceList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const systemIntegration = systemIntegrationInfo.getData()

			let optionsSystemIntegrationService = systemIntegrationServiceList.getDatasOption()
			optionsSystemIntegrationService = optionsSystemIntegrationService.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.systemIntegration.${d.value}`)
				}
			})
			optionsSystemIntegrationService = optionsSystemIntegrationService.sort((a, b) => { return a.text.localeCompare(b.text) })

			return (
				<React.Fragment>
					{super.render()}
					<SystemIntegrationUpdate
						isLoadingControl={isLoadingComponent}
						model={systemIntegration}
						optionsSystemIntegrationService={optionsSystemIntegrationService}
						onUpdate={this.onUpdateSystemIntegration}
						onTestSystemIntegration={this.onTestSystemIntegration}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			systemIntegrationInfo: state.systemIntegrationInfo,
			systemIntegrationServiceList: state.systemIntegrationServiceList
		}),
		(dispatch, props) => ({
			getSystemIntegrationInfo() {
				dispatch(getSystemIntegrationInfo(utils.getParamFromProps(props, "id")))
			},
			getSystemIntegrationServiceList() {
				dispatch(getSystemIntegrationServiceList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSystemIntegrationInfo()
			this.props.getSystemIntegrationServiceList()
		}
	}),
)


export default enhance(UpdateSystemIntegrationController)