
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Header, Grid, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import ResourceGroupModel from '../../models/ResourceGroupModel'
import LabelInput from '../common/LabelInput'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewNoResult from '../common/ViewNoResult'
import { cookiesKey } from '../../../constant'

class ResourceGroupView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/resourcegroup/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/resourcegroup/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/resourcegroup`)
    }

    getColumnUsersConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("user.employeeCode"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("user.fullname"),
                selector: (row) => row.displayName ? row.displayName : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("common.email"),
                selector: (row) => row.email ? row.email : '-',
                sortable: false,
                width: '200px'
            }
        ]

        return columns
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformResourceGroup(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, userList, optionsLocationList, optionsSensorRuleList, optionsUserList } = this.props
            const { model, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            return (
                <React.Fragment>
                    {super.render()}
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('resourceGroup.viewResource')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        this.bCookie.getData(cookiesKey.IS_ENABLE_FRONTEND_SERVICE) &&
                                                        <React.Fragment>
                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t('resourceGroup.locationControl')} /></Table.Cell>
                                                                <Table.Cell>
                                                                    <div className="minThree">
                                                                        {
                                                                            !!model.locationControls && Array.isArray(model.locationControls) && model.locationControls.length > 0 ?
                                                                                model.locationControls.map(location => {
                                                                                    let locationName = optionsLocationList.find(locationList => locationList.value === location)

                                                                                    if (!!locationName) {
                                                                                        return <p>• {locationName.text}</p>
                                                                                    }

                                                                                    return '-'
                                                                                })
                                                                                : '-'
                                                                        }
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t('resourceGroup.resourceControl')} /></Table.Cell>
                                                                <Table.Cell>
                                                                    <div className="minThree">
                                                                        {
                                                                            !!model.resourceControls && Array.isArray(model.resourceControls) && model.resourceControls.length > 0 ?
                                                                                model.resourceControls.map(resource => {
                                                                                    let resourceName = optionsUserList.find(resourceList => resourceList.value === resource)

                                                                                    if (!!resourceName) {
                                                                                        return <p>• {resourceName.text}</p>
                                                                                    }

                                                                                    return '-'
                                                                                })
                                                                                : '-'
                                                                        }
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>

                                                            <Table.Row>
                                                                <Table.Cell width={4}><LabelInput text={t('resourceGroup.sensorRuleControl')} /></Table.Cell>
                                                                <Table.Cell>
                                                                    <div className="minThree">
                                                                        {
                                                                            !!model.sensorRuleControls && Array.isArray(model.sensorRuleControls) && model.sensorRuleControls.length > 0 ?
                                                                                model.sensorRuleControls.map(sensorRule => {
                                                                                    let sensorRuleName = optionsSensorRuleList.find(sensorRuleList => sensorRuleList.value === sensorRule)
                                                                                    if (!!sensorRuleName) {
                                                                                        return <p>• {sensorRuleName.text}</p>
                                                                                    }

                                                                                    return '-'
                                                                                })
                                                                                : '-'
                                                                        }
                                                                    </div>
                                                                </Table.Cell>
                                                            </Table.Row>
                                                        </React.Fragment>
                                                    }
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <Grid divided='vertically'>
                                                <Grid.Row className="remove-bottom-padding">
                                                    <Grid.Column width={16}>
                                                        <Header as='h1'>
                                                            {t("user.title")}
                                                        </Header>
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ViewTable
                                                            columns={this.getColumnUsersConfig()}
                                                            datas={userList}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ResourceGroupView.defaultProps = {
    model: new ResourceGroupModel(),
    userList: [],
    optionsLocationList: [],
    optionsSensorRuleList: [],
    optionsUserList: [],
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ResourceGroupView)