import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

import '../../scss/viewLoading.scss'

class ViewLoading extends React.Component {

    render() {
        const { isActive, isPage, message, size } = this.props
        if (!isActive) {
            return null
        }
        return (
            <React.Fragment>
                <div className="module viewLoading">
                    {
                        isPage ?
                            <Dimmer active page>
                                <Loader className="loading-page" size={size}>{message}</Loader>
                            </Dimmer>
                            :
                            <Loader size={size} active inline>{message}</Loader>
                    }
                </div>
            </React.Fragment>
        )
    }
}

ViewLoading.defaultProps = {
    isActive: true,
    isPage: true,
    message: "",
    size: "big"
}


export default ViewLoading