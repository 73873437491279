
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import { defaultValue } from '../../constant'

const Schema = mongoose.Schema

const loginInfoSchema = new Schema({
	defaultLoginSystemId: { type: String, default: "LOCAL" },
	defaultLoginSystem: { type: Object, default: null },
	enableLoginSystemIds: [{ type: String }],
	enableLoginSystems: [{ type: Object }],
	enableFrontendLoginSystemIds: [{ type: String }],
	enableFrontendLoginSystem: [{ type: Object }],
	isSigninAndUpsert: { type: Boolean, default: false },
	defaultGroupId: { type: String, default: "" },
	defaultGroup: { type: Object, default: null },
}, { _id: false })

const emailSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	subject: { type: String, default: "" },
	content: { type: String, default: "" },
}, { _id: false })

const systemNotificationInfoSchema = new Schema({
	notificationId: { type: String, default: defaultValue.NONE_SELECT },
	notification: { type: Object, default: null },
	forgotPassword: { type: emailSchema, default: {} },
	resetPassword: { type: emailSchema, default: {} },
}, { _id: false })

const SettingSchema = new Schema({
	loginInfo: { type: loginInfoSchema, default: {} },
	systemNotificationInfo: { type: systemNotificationInfoSchema, default: {} }
}, { _id: false })

setBaseSchema(SettingSchema, "06")

loginInfoSchema.path("defaultGroupId").validate(async function (defaultGroupId) {
	if (this.isSigninAndUpsert === true && !defaultGroupId) {
		return false
	}
	return true
}, "validate.require")

systemNotificationInfoSchema.path("notificationId").validate(async function (notificationId) {
	if (this.forgotPassword.isEnable === true || this.resetPassword.isEnable === true) {
		return (!!notificationId && notificationId !== defaultValue.NONE_SELECT)
	}
	return true
}, "validate.require")

emailSchema.path("subject").validate(async function (subject) {
	if (this.isEnable === true && !subject) {
		return false
	}
	return true
}, "validate.require")

emailSchema.path("content").validate(async function (content) {
	if (this.isEnable === true && !content) {
		return false
	}
	return true
}, "validate.require")

SettingSchema.methods.serializeAPI = function () {
	let loginInfo = {}

	if (this.loginInfo) {
		loginInfo.defaultLoginSystemId = this.loginInfo.defaultLoginSystemId
		loginInfo.enableLoginSystemIds = this.loginInfo.enableLoginSystemIds
		loginInfo.enableFrontendLoginSystemIds = this.loginInfo.enableFrontendLoginSystemIds
		loginInfo.isSigninAndUpsert = this.loginInfo.isSigninAndUpsert
		
		if (this.loginInfo.isSigninAndUpsert && !!this.loginInfo.defaultGroupId) {
			loginInfo.defaultGroupId = this.loginInfo.defaultGroupId
		}
	}

	let systemNotificationInfo = {}

	if (this.systemNotificationInfo) {
		if (!!this.systemNotificationInfo.notificationId && this.systemNotificationInfo.notificationId !== defaultValue.NONE_SELECT) {
			systemNotificationInfo.notificationId = this.systemNotificationInfo.notificationId
		} else {
			systemNotificationInfo.notificationId = null
		}
		if (this.systemNotificationInfo.forgotPassword) {
			let forgotPassword = {
				isEnable: this.systemNotificationInfo.forgotPassword.isEnable,
				subject: this.systemNotificationInfo.forgotPassword.subject,
				content: this.systemNotificationInfo.forgotPassword.content
			}
			systemNotificationInfo.forgotPassword = forgotPassword
		}
		if (this.systemNotificationInfo.resetPassword) {
			let resetPassword = {
				isEnable: this.systemNotificationInfo.resetPassword.isEnable,
				subject: this.systemNotificationInfo.resetPassword.subject,
				content: this.systemNotificationInfo.resetPassword.content
			}
			systemNotificationInfo.resetPassword = resetPassword
		}
	}

	return {
		loginInfo: loginInfo,
		systemNotificationInfo: systemNotificationInfo
	}
}


const SettingModel = mongoose.model("setting", SettingSchema, "setting")

export default SettingModel