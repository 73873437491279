
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import AccountView from '../../views/account/AccountView'
import { getAccountInfo, getAccountIntegrationList } from '../../actions/accountActions'
import AccountManager from '../../business/AccountManager'
import UserManager from '../../business/UserManager'

class ViewAccountController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false

        this.bAccount = new AccountManager(this)
        this.bUser = new UserManager(this)
    }

    getPropsResetStatus() {
        return ["accountInfo"]
    }

    onUnintegtationAccount = (model, serviceInfo, systemIntegrationId) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAccount.unintegrateAccount(model.id, serviceInfo, systemIntegrationId, (res) => {

                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAccountInfo() }

                this.setState(tempState, action)
            })
        })
    }

    onADIntegrationAccount = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAccount.accountIntegrationAD(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAccountInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onExchangeIntegrationAccount = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAccount.accountIntegrationExchange(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAccountInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onGoogleIntegrationAccount = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAccount.accountIntegrationGoogle(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAccountInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    onO365IntegrationAccount = (model, body) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bAccount.userIntegrationO365(model.id, body, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                },
                    action = () => { this.props.getAccountInfo(model.id) }

                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { accountInfo, accountIntegrationList } = this.props
            const { isLoadingComponent } = this.props
            
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const accountInfoData = accountInfo.getData()
                , accountIntegrationDatas = accountIntegrationList.getDatas()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <AccountView
                        model={accountInfoData}
                        accountIntegrationList={accountIntegrationDatas}
                        onUnintegtationAccount={this.onUnintegtationAccount}
                        onADIntegrationAccount={this.onADIntegrationAccount}
                        onExchangeIntegrationAccount={this.onExchangeIntegrationAccount}
                        onGoogleIntegrationAccount={this.onGoogleIntegrationAccount}
                        onO365IntegrationAccount={this.onO365IntegrationAccount}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            accountInfo: state.accountInfo,
            accountIntegrationList: state.accountIntegrationList
        }),
        (dispatch, props) => ({
            getAccountInfo() {
                dispatch(getAccountInfo())
            },
            getAccountIntegrationList() {
                dispatch(getAccountIntegrationList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getAccountInfo()
            this.props.getAccountIntegrationList()
        }
    })
)


export default enhance(ViewAccountController)