
import ActionResourceObject from '../core/redux/ActionResourceObject'
import LocationTypeModel from '../models/LocationTypeModel'

export default class LocationTypeResource extends ActionResourceObject {

	setResource(data) {
		try {

			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new LocationTypeModel(d))
			} else {
				result = new LocationTypeModel(data)
			}

			return result
		} catch (error) {
			return null
		}
	}

	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.name,
						parent: d.parent,
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.name,
					parent: data.parent,
				}
			}

			return result
		} catch (error) {
			return null
		}
	}

}