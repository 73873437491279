
import types from '../types'
import { requestStatus } from '../../constant'
import SensorResource from '../selectors/sensorResource'
import SensorTypeResource from '../selectors/sensorTypeResource'
import ControllerResource from '../selectors/controllerResource'
import SensorDropdownResource from '../selectors/sensorDropdownResource'
import IOTResourceDropdownResource from '../selectors/iotResourceDropdownResource'

const sensorReducer = {
	sensorInfo: (state = null, action) => {
		if (!state || !(state instanceof SensorResource)) {
			state = new SensorResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_INFO_REQUEST:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_INFO_SUCCESS:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_INFO_FAILURE:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorList: (state = null, action) => {
		if (!state || !(state instanceof SensorResource)) {
			state = new SensorResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_LIST_REQUEST:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_LIST_SUCCESS:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_SENSOR_LIST:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_SENSOR_LIST_FAILURE:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorLocationList: (state = null, action) => {
		if (!state || !(state instanceof SensorResource)) {
			state = new SensorResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_LOCATION_LIST_REQUEST:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_LOCATION_LIST_SUCCESS:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_LOCATION_LIST_FAILURE:
				state = new SensorResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorResourceList: (state = null, action) => {
		if (!state || !(state instanceof IOTResourceDropdownResource)) {
			state = new IOTResourceDropdownResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_RESOURCE_LIST_REQUEST:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_RESOURCE_LIST_SUCCESS:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_RESOURCE_LIST_FAILURE:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorTypeList: (state = null, action) => {
		if (!state || !(state instanceof SensorTypeResource)) {
			state = new SensorTypeResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_TYPE_LIST_REQUEST:
				state = new SensorTypeResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_TYPE_LIST_SUCCESS:
				state = new SensorTypeResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_TYPE_LIST_FAILURE:
				state = new SensorTypeResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorModelList: (state = null, action) => {
		if (!state || !(state instanceof SensorDropdownResource)) {
			state = new SensorDropdownResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_MODEL_LIST_REQUEST:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_MODEL_LIST_SUCCESS:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_MODEL_LIST_FAILURE:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorModuleList: (state = null, action) => {
		if (!state || !(state instanceof SensorDropdownResource)) {
			state = new SensorDropdownResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_MODULE_LIST_REQUEST:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_MODULE_LIST_SUCCESS:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_MODULE_LIST_FAILURE:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorLocationTypeList: (state = null, action) => {
		if (!state || !(state instanceof SensorDropdownResource)) {
			state = new SensorDropdownResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_LOCATION_TYPE_LIST_REQUEST:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_LOCATION_TYPE_LIST_SUCCESS:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_LOCATION_TYPE_LIST_FAILURE:
				state = new SensorDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	sensorControllerList: (state = null, action) => {
		if (!state || !(state instanceof ControllerResource)) {
			state = new ControllerResource()
		}

		switch (action.type) {
			case types.GET_SENSOR_CONTROLLER_LIST_REQUEST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_SENSOR_CONTROLLER_LIST_SUCCESS:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_SENSOR_CONTROLLER_LIST_FAILURE:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default sensorReducer