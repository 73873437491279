
import types from '../types'
import { requestStatus } from '../../constant'
import EventControlRuleResource from '../selectors/eventControlRuleResource'
import EventControlRuleDropdownResource from '../selectors/eventControlRuleDropdownResource'

const eventControlRuleReducer = {
	eventControlRuleInfo: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleResource)) {
			state = new EventControlRuleResource()
		}

		switch (action.type) {
			case types.GET_EVENT_CONTROL_RULE_INFO_REQUEST:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_EVENT_CONTROL_RULE_INFO_SUCCESS:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_EVENT_CONTROL_RULE_INFO_FAILURE:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	eventControlRuleList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleResource)) {
			state = new EventControlRuleResource()
		}

		switch (action.type) {
			case types.GET_EVENT_CONTROL_RULE_LIST_REQUEST:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_EVENT_CONTROL_RULE_LIST_SUCCESS:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_EVENT_CONTROL_RULE_LIST:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_EVENT_CONTROL_RULE_LIST_FAILURE:
				state = new EventControlRuleResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrHolidayTypeList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_HOLIDAY_TYPE_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_HOLIDAY_TYPE_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_HOLIDAY_TYPE_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrActionTypeList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_ACTION_TYPE_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_ACTION_TYPE_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_ACTION_TYPE_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrActionStatusList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_ACTION_STATUS_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_ACTION_STATUS_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_ACTION_STATUS_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrRecurrenceTypeList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_RECURRENCE_TYPE_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_RECURRENCE_TYPE_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_RECURRENCE_TYPE_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrDayOfWeekList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_DAY_OF_WEEK_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_DAY_OF_WEEK_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_DAY_OF_WEEK_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	ecrEventList: (state = null, action) => {
		if (!state || !(state instanceof EventControlRuleDropdownResource)) {
			state = new EventControlRuleDropdownResource()
		}

		switch (action.type) {
			case types.GET_ECR_EVENT_LIST_REQUEST:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ECR_EVENT_LIST_SUCCESS:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ECR_EVENT_LIST_FAILURE:
				state = new EventControlRuleDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default eventControlRuleReducer