
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'
import moment from 'moment'
import TimeSlotSchema from '../schemas/TimeSlotSchema'

const Schema = mongoose.Schema

const StartDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const EndDateSchema = new Schema({
	isEnable: { type: Boolean, default: false },
	date: { type: Date, default: moment() }
}, { _id: false })

const RecurrenceSchema = new Schema({
	type: { type: String, required: [true, "validate.require"], default: "" },
	weeklyEveryDayOfWeek: [{ type: String }],
	startDate: { type: StartDateSchema, default: {} },
	endDate: { type: EndDateSchema, default: {} },
}, { _id: false })

const conditionSchema = new Schema({
	timeSlots: [{ type: TimeSlotSchema }],
	holidayType: { type: String, required: [true, "validate.require"] },
	recurrence: { type: RecurrenceSchema, default: {} },
	includeDays: [{ type: Date }],
	excludeDays: [{ type: Date }],
}, { _id: false })

const EventControlRuleConditionModelSchema = new Schema({
	condition: { type: conditionSchema, default: {} },
})

setBaseSchema(EventControlRuleConditionModelSchema, "event_control_rule_condition")

conditionSchema.path("timeSlots").validate(async function (timeSlots) {
	return (Array.isArray(timeSlots) && timeSlots.length > 0)
}, "validate.require")

const EventControlRuleConditionModel = mongoose.model("event_control_rule_condition", EventControlRuleConditionModelSchema, "event_control_rule_condition")

export default EventControlRuleConditionModel
