
import types from '../types'
import { requestStatus } from '../../constant'
import LocationTypeResource from '../selectors/locationTypeResource'

const locationTypeReducer = {
	locationType: (state = null, action) => {
		if (!state || !(state instanceof LocationTypeResource)) {
			state = new LocationTypeResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_TYPE_REQUEST:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_TYPE_SUCCESS:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_GET_LOCATION_TYPE_FAILURE:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	locationTypeList: (state = null, action) => {
		if (!state || !(state instanceof LocationTypeResource)) {
			state = new LocationTypeResource()
		}

		switch (action.type) {
			case types.CORE_GET_LOCATION_TYPE_LIST_REQUEST:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_TYPE_LIST_SUCCESS:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.CORE_RESET_LOCATION_TYPE_LIST:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.CORE_GET_LOCATION_TYPE_LIST_FAILURE:
				state = new LocationTypeResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default locationTypeReducer