
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
//import { getControllerList } from '../../actions/controllerActions'
import { getLocationList } from '../../actions/locationActions'
import { getSensorResourceList, getSensorModuleList, getSensorTypeList, getSensorModelList, getSensorLocationTypeList, getSensorControllerList } from '../../actions/sensorActions'
import SensorManager from '../../business/SensorManager'
import SensorCreate from '../../views/sensor/SensorCreate'
//import utils from '../../core/utility/utils'

class CreateSensorController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bSensor = new SensorManager(this)
    }

    onCreateSensor = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bSensor.createSensor(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {

                    action = this.props.history.push(`/sensor/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/sensor`)
    }

    getOptions = (optionsList) => {
        try {
            var options = []
            if (Array.isArray(optionsList) && optionsList.length > 0) {
                options = optionsList.map(op => {
                    return {
                        key: op,
                        value: op,
                        text: op
                    }
                })
            }
            return options
        } catch (error) {
            return []
        }
    }

    getResourceOptions = (optionsResourceList) => {
        try {
            var options = []
            if (Array.isArray(optionsResourceList) && optionsResourceList.length > 0) {
                options = optionsResourceList.map(op => {
                    return {
                        key: op.id,
                        value: op.id,
                        text: op.displayName
                    }
                })
            }
            return options
        } catch (error) {
            return []
        }
    }

    render() {
        try {
            const { sensorControllerList, locationList, sensorResourceList, sensorTypeList, sensorModelList, sensorModuleList, sensorLocationTypeList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            const optionsControllerList = sensorControllerList.getDatasOption()
                , controllerDatas = sensorControllerList.getDatas()
                , optionsLocationList = locationList.getDatasOption()
                , optionsResourceList = sensorResourceList.getDatasOption()
                , optionsSensorTypeList = sensorTypeList.getDatasOption()
                , optionsSensorModelList = this.getOptions(sensorModelList.value)
                , optionsSensorModuleList = this.getOptions(sensorModuleList.value)
                , optionsSensorLocationTypeList = this.getOptions(sensorLocationTypeList.value)

            return (
                <React.Fragment>
                    {super.render()}
                    <SensorCreate
                        isLoadingControl={isLoadingComponent}
                        controllerList={controllerDatas}
                        optionsControllerList={optionsControllerList}
                        optionsLocationList={optionsLocationList}
                        optionsResourceList={optionsResourceList}
                        optionsSensorTypeList={optionsSensorTypeList}
                        optionsSensorModelList={optionsSensorModelList}
                        optionsSensorModuleList={optionsSensorModuleList}
                        optionsSensorLocationTypeList={optionsSensorLocationTypeList}
                        onCreate={this.onCreateSensor} />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            sensorControllerList: state.sensorControllerList,
            sensorResourceList: state.sensorResourceList,
            locationList: state.locationList,
            sensorTypeList: state.sensorTypeList,
            sensorModelList: state.sensorModelList,
            sensorModuleList: state.sensorModuleList,
            sensorLocationTypeList: state.sensorLocationTypeList
        }),
        (dispatch, props) => ({
            getSensorControllerList() {
                dispatch(getSensorControllerList())
            },
            getSensorResourceList() {
                dispatch(getSensorResourceList())
            },
            getLocationList() {
                dispatch(getLocationList())
            },
            getSensorTypeList() {
                dispatch(getSensorTypeList())
            },
            getSensorModelList() {
                dispatch(getSensorModelList())
            },
            getSensorModuleList() {
                dispatch(getSensorModuleList())
            },
            getSensorLocationTypeList() {
                dispatch(getSensorLocationTypeList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getSensorControllerList()
            this.props.getSensorResourceList()
            this.props.getLocationList()
            this.props.getSensorTypeList()
            this.props.getSensorModelList()
            this.props.getSensorModuleList()
            this.props.getSensorLocationTypeList()
        }
    }),
)


export default enhance(CreateSensorController)