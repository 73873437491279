
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'
import utils from '../../core/utility/utils'

const Schema = mongoose.Schema

const ActiveDirectoryDataSchema = new Schema({
	server: { type: String, required: [true, "validate.require"], default: "" },
	port: { type: String, required: [true, "validate.require"], default: "" },
	username: { type: String, required: [true, "validate.require"], default: "" },
	password: { type: String, required: [true, "validate.require"], default: "" },
	domain: { type: String, default: "" },
	baseDN: { type: String, default: "" },
})

setBaseSchema(ActiveDirectoryDataSchema, "system_integration_ad")

ActiveDirectoryDataSchema.methods.setData = function (data) {
	try {
		if (!Array.isArray(data) && typeof data === "object") {
			for (const key in data) {
				if (key in this) {
					if (key === "password") {
						this[key] = utils.decode(data[key])
					} else {
						this[key] = data[key]
					}

				}
			}
		}
	} catch (error) {

	}
}

ActiveDirectoryDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			server: this.server,
			port: this.port,
			username: this.username,
			password: utils.encode(this.password),
			domain: this.domain,
			baseDN: this.baseDN,
		}
		return result
	} catch (error) {
		console.log(error)
		return null
	}
}

const ActiveDirectoryDataModel = mongoose.model("system_integration_ad", ActiveDirectoryDataSchema, "system_integration_ad")

export default ActiveDirectoryDataModel