
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import AccountUpdate from '../../views/account/AccountUpdate'
import { getAccountInfo } from '../../actions/accountActions'
import utils from '../../core/utility/utils'
import { getResourceGroupList } from '../../actions/resourceGroupActions'
import AccountManager from '../../business/AccountManager'
import { getDepartmentList } from '../../actions/departmentActions'
import { getLocationList } from '../../actions/locationActions'

class UpdateAccountController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bAccount = new AccountManager(this)
    }

    getPropsResetStatus() {
        return ["accountInfo"]
    }

    onUpdateResource = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAccount.updateAccount(model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    action = () => { this.props.history.replace(`/account`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { resourceGroupList, accountInfo, departmentList, locationList } = this.props
            const { isLoadingComponent } = this.state
            
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const optionsResourceGroup = resourceGroupList.getDatasOption()
                , accountInfoData = accountInfo.getData()
                , departments = departmentList.getDatas()
                , location = locationList.getDatas()
                , optionsTreeDepartment = utils.makeGroupedArrayForTreeSelect(departments, "id", "parentId", null, "name", "id")
                , optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(location, "id", "parentId", null, "name", "id")

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <AccountUpdate
                        isLoadingControl={isLoadingComponent}
                        optionsResourceGroup={optionsResourceGroup}
                        accountInfo={accountInfoData}
                        optionsTreeDepartment={optionsTreeDepartment}
                        optionsTreeLocation={optionsTreeLocation}
                        onUpdate={this.onUpdateResource}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            resourceGroupList: state.resourceGroupList,
            accountInfo: state.accountInfo,
            locationList: state.locationList,
            departmentList: state.departmentList
        }),
        (dispatch, props) => ({
            getResourceGroupList() {
                dispatch(getResourceGroupList())
            },
            getAccountInfo() {
                dispatch(getAccountInfo())
            },
            getDepartmentList() {
                dispatch(getDepartmentList())
            },
            getLocationList() {
                dispatch(getLocationList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getResourceGroupList()
            this.props.getAccountInfo()
            this.props.getDepartmentList()
            this.props.getLocationList()
        }
    })
)


export default enhance(UpdateAccountController)