
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
// import UserModel from '../../models/UserModel'
// import TakePhoto from './TakePhoto'
// import FileBase64 from "react-file-base64"
import LabelInput from '../../common/LabelInput'
// import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'

// import ViewTakePhoto from '../common/ViewTakePhoto'
// import ViewButton from '../common/ViewButton'
// import ButtonModel from '../../core/model/ButtonModel'

// import InputSelectDepartment from '../department/InputSelectDepartment'
// import InputDatePicker from '../../core/view/common/InputDatePicker'
// import { CORE_SERVICE_ENDPOINT } from '../../lib'
// import { cookiesKey } from '../../../constant'

class SelectServiceForm extends MyForm {
    // constructor(props) {
    //     super(props)

    //     this.state.modalTakePhoto = null
    //     this.state.isOpenUploadImage = false
    //     this.state.imageTakePhoto = null

    //     this.state.selectedResourceGroup = []
    // }

    // // handleChangeBooleanStatus = (e, { name, value, checked }) => {
    // //     if (checked === true) {
    // //         this.handleChange(e, { name: name, value: resourceStatus.ACTIVE })
    // //     } else {
    // //         this.handleChange(e, { name: name, value: resourceStatus.INACTIVE })
    // //     }
    // // }
    // componentDidMount() {
    //     const { model } = this.props

    //     let resourceGroupIds = []

    //     if (!!model.resourceGroupIds && Array.isArray(model.resourceGroupIds)) {
    //         resourceGroupIds = model.resourceGroupIds.map((d, i) => {
    //             return d
    //         })
    //     }

    //     this.setState({ selectedResourceGroup: resourceGroupIds })
    // }

    // componentDidUpdate() {
    //     const { selectedResourceGroup } = this.state
    //     const { onChangeResourceGroup } = this.props

    //     onChangeResourceGroup(selectedResourceGroup)

    // }

    // resourceGroupCheckBoxList = (optionsResourceGroup) => {
    //     const { selectedResourceGroup } = this.state

    //     let component = null

    //     if (!!optionsResourceGroup && Array.isArray(optionsResourceGroup) && optionsResourceGroup.length > 0) {
    //         component = optionsResourceGroup.map((d, i) => {
    //             return <Form.Checkbox
    //                 key={i}
    //                 label={d.text}
    //                 name="select"
    //                 value={d.value}
    //                 onChange={this.handleChangeResourceGroup}
    //                 checked={!!selectedResourceGroup.find(id => id === d.value)}
    //             />
    //         })
    //     }

    //     return component
    // }

    // handleChangeResourceGroup = (e, { name, value, checked }) => {
    //     const { selectedResourceGroup } = this.state
    //     const { optionsResourceGroup } = this.props

    //     if (name === "select") {
    //         let resourceGroupIds = selectedResourceGroup.map(id => id)
    //         let findIndex = resourceGroupIds.findIndex(id => id === value)

    //         if (findIndex === -1) {
    //             resourceGroupIds.push(value)
    //         } else {
    //             if (checked) {
    //                 resourceGroupIds.push(value)
    //             } else {
    //                 resourceGroupIds.splice(findIndex, 1)
    //             }
    //         }
    //         this.setState({ selectedResourceGroup: resourceGroupIds })
    //     } else if (name === "selectAll") {
    //         let resourceGroupIds = []
    //         if (checked) {
    //             resourceGroupIds = (Array.isArray(optionsResourceGroup) ? optionsResourceGroup.map(rg => rg.value) : [])
    //         }
    //         this.setState({ selectedResourceGroup: resourceGroupIds })
    //     }
    // }

    onSubmitForm = () => {

    }

    render() {
        try {
            const { t, model, errors, optionsImportService } = this.props

            let serviceId = this.getErrorInput("serviceId", errors)
            return (
                <div className="module selectServiceForm">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("systemIntegration.service")} isRequire={true} />
                                    <Form.Dropdown
                                        name="serviceId"
                                        value={model.serviceId}
                                        onChange={this.handleChange}
                                        options={optionsImportService}
                                        selection
                                        fluid
                                        error={serviceId.isError ? serviceId.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form >
                </div >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SelectServiceForm.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    errors: [],
    model: {},
    optionsImportService: [],
}

export default (translate(SelectServiceForm))