
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form, Segment, Label } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import ButtonModel from '../../../core/model/ButtonModel'
import ConditionDataModel from '../../../models/sensorRuleData/sensorRuleStep/ConditionDataModel'
import TimeSlotModel from '../../../models/TimeSlotModel'
import LabelInput from '../../common/LabelInput'
import ViewButton from '../../common/ViewButton'
import ViewTable from '../../../core/view/common/table/ViewTable'
import InputDatePicker from '../../../core/view/common/InputDatePicker'
import MultipleCheckboxList from '../../common/MultipleCheckboxList'
import ModalTimeSlot from '../../common/ModalTimeSlot'
import ModalDateSelect from '../ModalDateSelect'
import { dateTimeFormat, recurrenceTypeCode, disableDateType } from '../../../../constant'
import moment from 'moment'

class ConditionForm extends MyForm {
    constructor(props) {
        super(props)
        this.state.modelErrors = []

        this.state.isOpenModalTimeSlot = false
        this.state.modelUpdateTimeSlot = null
        this.state.indexUpdateTimeSlot = -1

        this.state.isOpenModalIncludeDay = false
        this.state.dateUpdateIncludeDay = null
        this.state.indexUpdateIncludeDay = -1

        this.state.isOpenModalExcludeDay = false
        this.state.dateUpdateExcludeDay = null
        this.state.indexUpdateExcludeDay = -1
    }

    //Slot
    openModalCreateTimeSlot = () => {
        this.setState({
            isOpenModalTimeSlot: true,
            modelUpdateTimeSlot: null,
            indexUpdateTimeSlot: -1,
        })
    }
    openModalUpdateTimeSlot = (model, index) => {
        let timeSlotModel = new TimeSlotModel()

        timeSlotModel.startTime = moment(model.startTime, 'HH:mm')
        timeSlotModel.endTime = moment(model.endTime, 'HH:mm')

        this.setState({
            isOpenModalTimeSlot: true,
            modelUpdateTimeSlot: timeSlotModel,
            indexUpdateTimeSlot: index,
        })
    }
    closeModalTimeSlot = () => {
        this.setState({
            isOpenModalTimeSlot: false,
            modelUpdateTimeSlot: null,
            indexUpdateTimeSlot: -1,
        })
    }
    onSubmitModalTimeSlot = (tempModel) => {
        const { model } = this.props
        const { modelUpdateTimeSlot, indexUpdateTimeSlot } = this.state
        let timeSlots = model.condition.timeSlots.map(s => s)

        //let tempModelSerialize = new TimeSlotModel(tempModel).serializeAPI()
        let tempModelSerialize = {
            startTime: moment(tempModel.startTime).format(dateTimeFormat.apiFullTime),
            endTime: moment(tempModel.endTime).format(dateTimeFormat.apiFullTime)
        }

        if (!!modelUpdateTimeSlot) {
            if (indexUpdateTimeSlot > -1) {
                timeSlots[indexUpdateTimeSlot] = tempModelSerialize
            }
        } else {
            timeSlots.push(tempModelSerialize)
        }
        this.setState({
            isOpenModalTimeSlot: false,
            modelUpdateTimeSlot: null,
            indexUpdateTimeSlot: -1,
        }, () => this.handleChange(null, { name: "condition.timeSlots", value: timeSlots }))
    }
    onDeleteTimeSlot = (index) => {
        const { model } = this.props
        let timeSlots = model.condition.timeSlots.map(s => s)
        if (index > -1) {
            timeSlots.splice(index, 1)
            this.handleChange(null, { name: "condition.timeSlots", value: timeSlots })
        }
    }

    //Include Day
    openModalCreateIncludeDay = () => {
        this.setState({
            isOpenModalIncludeDay: true,
            dateUpdateIncludeDay: null,
            indexUpdateIncludeDay: -1,
        })
    }
    openModalUpdateIncludeDay = (date, index) => {
        // console.log(date, index)
        this.setState({
            isOpenModalIncludeDay: true,
            dateUpdateIncludeDay: moment(date),
            indexUpdateIncludeDay: index,
        })
    }
    closeModalIncludeDay = () => {
        this.setState({
            isOpenModalIncludeDay: false,
            dateUpdateIncludeDay: null,
            indexUpdateIncludeDay: -1,
        })
    }
    onSubmitModalIncludeDay = (tempDate) => {
        const { model } = this.props
        const { dateUpdateIncludeDay, indexUpdateIncludeDay } = this.state
        let includeDays = model.condition.includeDays.map(s => s)
        if (!!dateUpdateIncludeDay) {
            if (indexUpdateIncludeDay > -1) {
                includeDays[indexUpdateIncludeDay] = tempDate
            }
        } else {
            includeDays.push(tempDate)
        }
        this.setState({
            isOpenModalIncludeDay: false,
            dateUpdateIncludeDay: null,
            indexUpdateIncludeDay: -1,
        }, () => this.handleChange(null, { name: "condition.includeDays", value: includeDays }))
    }
    onDeleteIncludeDay = (index) => {
        const { model } = this.props
        let includeDays = model.condition.includeDays.map(s => s)
        if (index > -1) {
            includeDays.splice(index, 1)
            this.handleChange(null, { name: "condition.includeDays", value: includeDays })
        }
    }

    //Exclude Day
    openModalCreateExcludeDay = () => {
        this.setState({
            isOpenModalExcludeDay: true,
            dateUpdateExcludeDay: null,
            indexUpdateExcludeDay: -1,
        })
    }
    openModalUpdateExcludeDay = (date, index) => {
        this.setState({
            isOpenModalExcludeDay: true,
            dateUpdateExcludeDay: moment(date),
            indexUpdateExcludeDay: index,
        })
    }
    closeModalExcludeDay = () => {
        this.setState({
            isOpenModalExcludeDay: false,
            dateUpdateExcludeDay: null,
            indexUpdateExcludeDay: -1,
        })
    }
    onSubmitModalExcludeDay = (tempDate) => {
        const { model } = this.props
        const { dateUpdateExcludeDay, indexUpdateExcludeDay } = this.state
        let excludeDays = model.condition.excludeDays.map(s => s)
        if (!!dateUpdateExcludeDay) {
            if (indexUpdateExcludeDay > -1) {
                excludeDays[indexUpdateExcludeDay] = tempDate
            }
        } else {
            excludeDays.push(tempDate)
        }
        this.setState({
            isOpenModalExcludeDay: false,
            dateUpdateExcludeDay: null,
            indexUpdateExcludeDay: -1,
        }, () => this.handleChange(null, { name: "condition.excludeDays", value: excludeDays }))
    }
    onDeleteExcludeDay = (index) => {
        const { model } = this.props
        let excludeDays = model.condition.excludeDays.map(s => s)
        if (index > -1) {
            excludeDays.splice(index, 1)
            this.handleChange(null, { name: "condition.excludeDays", value: excludeDays })
        }
    }

    getColumnTimeSlot() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.time"),
                selector: (row) => {
                    if (!!row.startTime && !!row.endTime) {
                        //return `${moment(row.startTime).format(dateTimeFormat.apiFullTime)} - ${moment(row.endTime).format(dateTimeFormat.apiFullTime)}`
                        return `${row.startTime} - ${row.endTime}`
                    }
                },
                sortable: false
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.openModalUpdateTimeSlot(row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onDeleteTimeSlot(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
            }

        ]
        return columns
    }

    getColumnIncludeDaysConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.date"),
                selector: (row) => {
                    return moment(row.date).format(dateTimeFormat.apiFullDate)
                },
                sortable: false
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.openModalUpdateIncludeDay(row.date, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onDeleteIncludeDay(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
            }

        ]
        return columns
    }

    getColumnExcludeDaysConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.date"),
                selector: (row) => {
                    return moment(row.date).format(dateTimeFormat.apiFullDate)
                },
                sortable: false
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.openModalUpdateExcludeDay(row.date, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onDeleteExcludeDay(i) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return <div className="box-action-table">
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
            }

        ]
        return columns
    }

    render() {
        try {
            const { t, model, errors, optionsHolidayTypeList, optionsRecurrenceTypeList, optionsDayOfWeekList } = this.props
            const { isOpenModalTimeSlot, modelUpdateTimeSlot,
                isOpenModalIncludeDay, dateUpdateIncludeDay,
                isOpenModalExcludeDay, dateUpdateExcludeDay } = this.state

            let holidayTypeValidate = this.getErrorInput("condition.holidayType", errors)
                , recurrenceTypeValidate = this.getErrorInput("condition.recurrence.type", errors)
                , dayOfWeekValidate = this.getErrorInput("condition.recurrence.weeklyEveryDayOfWeek", errors)
                , startDateValidate = this.getErrorInput("condition.recurrence.startDate.date", errors)
                , endDateValidate = this.getErrorInput("condition.recurrence.endDate.date", errors)
                , timeSlotsValidate = this.getErrorInput("condition.timeSlots", errors)

            let includeDays = Array.isArray(model.condition.includeDays) && model.condition.includeDays.length > 0 ? model.condition.includeDays.map(d => { return { date: d } }) : []
                , excludeDays = Array.isArray(model.condition.excludeDays) && model.condition.excludeDays.length > 0 ? model.condition.excludeDays.map(d => { return { date: d } }) : []

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalTimeSlot &&
                        <ModalTimeSlot
                            model={modelUpdateTimeSlot}
                            onClose={this.closeModalTimeSlot}
                            onSubmit={this.onSubmitModalTimeSlot}
                            minuteStep={1}
                        />
                    }
                    {
                        isOpenModalIncludeDay &&
                        <ModalDateSelect
                            date={dateUpdateIncludeDay}
                            textHeader={!!dateUpdateIncludeDay ? t("eventControlRule.editIncludeDay") : t("eventControlRule.addIncludeDay")}
                            labelTitle={t("eventControlRule.includeDay")}
                            onClose={this.closeModalIncludeDay}
                            onSubmit={this.onSubmitModalIncludeDay}
                        />
                    }
                    {
                        isOpenModalExcludeDay &&
                        <ModalDateSelect
                            date={dateUpdateExcludeDay}
                            textHeader={!!dateUpdateExcludeDay ? t("eventControlRule.editExcludeDay") : t("eventControlRule.addExcludeDay")}
                            labelTitle={t("eventControlRule.excludeDay")}
                            onClose={this.closeModalExcludeDay}
                            onSubmit={this.onSubmitModalExcludeDay}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("common.timeSlot")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: this.openModalCreateTimeSlot,
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnTimeSlot()}
                                        datas={model.condition.timeSlots}
                                        isShowPagination={false}
                                    />
                                    {
                                        (timeSlotsValidate.isError &&
                                            timeSlotsValidate.content) &&
                                        <Form.Field>
                                            <Label pointing prompt>
                                                {timeSlotsValidate.content}
                                            </Label>
                                        </Form.Field>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} >
                                    <LabelInput text={t('eventControlRule.holidayType')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="condition.holidayType"
                                        value={model.condition.holidayType}
                                        options={optionsHolidayTypeList}
                                        onChange={this.handleChange}
                                        error={holidayTypeValidate.isError ? holidayTypeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('eventControlRule.recurrence')} isRequire={false} />
                                    <Segment className="group-segment-input">
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <LabelInput text={t('common.type')} isRequire={true} />
                                                    <Form.Dropdown
                                                        selection
                                                        selectOnBlur={false}
                                                        placeholder={t("common.pleaseSelect")}
                                                        name="condition.recurrence.type"
                                                        value={model.condition.recurrence.type}
                                                        options={optionsRecurrenceTypeList}
                                                        onChange={this.handleChange}
                                                        error={recurrenceTypeValidate.isError ? recurrenceTypeValidate.content : null}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            {
                                                model.condition.recurrence.type === recurrenceTypeCode.WEEKLY &&
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t('eventControlRule.dayOfWeek')} />
                                                        <MultipleCheckboxList
                                                            name="condition.recurrence.weeklyEveryDayOfWeek"
                                                            optionsDataList={optionsDayOfWeekList}
                                                            value={model.condition.recurrence.weeklyEveryDayOfWeek}
                                                            onChange={this.handleChange}
                                                            error={dayOfWeekValidate}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            }
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <LabelInput text={t('common.startDate')} />
                                                </Grid.Column>
                                                <Grid.Column width={2}>
                                                    <div className="checkBoxCenter">
                                                        <Form.Checkbox
                                                            label=""
                                                            name={'condition.recurrence.startDate.isEnable'}
                                                            value={model.condition.recurrence.startDate.isEnable}
                                                            onChange={this.handleChangeBoolean}
                                                            checked={model.condition.recurrence.startDate.isEnable}
                                                        />
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column width={14}>
                                                    <InputDatePicker
                                                        name="condition.recurrence.startDate.date"
                                                        value={model.condition.recurrence.startDate.date}
                                                        onChange={this.handleChange}
                                                        error={startDateValidate.isError ? startDateValidate.content : null}
                                                        disabled={!model.condition.recurrence.startDate.isEnable}
                                                        checkerDate={model.condition.recurrence.endDate.isEnable ? model.condition.recurrence.endDate.date : null}
                                                        disableDateTypeInput={disableDateType.END_DATE_INCLUDE}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <LabelInput text={t('common.endDate')} />
                                                </Grid.Column>
                                                <Grid.Column width={2}>
                                                    <div className="checkBoxCenter">
                                                        <Form.Checkbox
                                                            label=""
                                                            name={'condition.recurrence.endDate.isEnable'}
                                                            value={model.condition.recurrence.endDate.isEnable}
                                                            onChange={this.handleChangeBoolean}
                                                            checked={model.condition.recurrence.endDate.isEnable}
                                                        />
                                                    </div>
                                                </Grid.Column>
                                                <Grid.Column width={14}>
                                                    <InputDatePicker
                                                        name="condition.recurrence.endDate.date"
                                                        value={model.condition.recurrence.endDate.date}
                                                        onChange={this.handleChange}
                                                        error={endDateValidate.isError ? endDateValidate.content : null}
                                                        disabled={!model.condition.recurrence.endDate.isEnable}
                                                        checkerDate={model.condition.recurrence.startDate.isEnable ? model.condition.recurrence.startDate.date : null}
                                                        disableDateTypeInput={disableDateType.START_DATE_INCLUDE}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("eventControlRule.includeDays")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: this.openModalCreateIncludeDay,
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnIncludeDaysConfig()}
                                        datas={includeDays}
                                        isShowPagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("eventControlRule.excludeDays")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: this.openModalCreateExcludeDay,
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnExcludeDaysConfig()}
                                        datas={excludeDays}
                                        isShowPagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ConditionForm.defaultProps = {
    model: new ConditionDataModel(),
    errors: [],
    optionsHolidayTypeList: [],
    optionsRecurrenceTypeList: [],
    optionsDayOfWeekList: [],
}

export default (translate(ConditionForm))