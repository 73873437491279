
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const EnableModuleSchema = new Schema ({
	serials: { type: Object },
	moduleCode: { type: String },
	moduleId: { type: String }
}, { _id: false })

const ControllerSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	mac: { type: String, default: "" },
	ip: { type: String, default: "" },
	enableModule: [{ type: EnableModuleSchema }],
	gatewayId: { type: String, default: "" },
	gateway: { type: Object, default: null },
	status: { type: String, default: null },
	isDisable: { type: Boolean, default: false },
	iotConfigs: [{ type: Object, default: null }]
}, { _id: false })

setBaseSchema(ControllerSchema, "06")


/* ControllerSchema.methods.serializeAPI = function () {
	try {
		let result = {
			ControllerCategoryId: this.ControllerCategoryId,
			code: this.code,
			name: this.name,
			amount: this.amount,
			description: this.description
		}

		if (!!this.fileUpload) {
			result.fileUpload = this.fileUpload
		}

		return result
		
	} catch (error) {
		return null
	}
} */

const ControllerModel = mongoose.model("controller", ControllerSchema, "controller")

export default ControllerModel