
export default class StepList {

	constructor(controller) {
		this.controller = controller
		this.stepList = null
		this.root = null
		this.currentStep = null
		this.data = null
	}

	addStep(step) {
		try {
			if (this.root) {
				let pointer = this.root
				while (pointer.next != null) {
					pointer = pointer.next
				}

				pointer.next = step
				step.prev = pointer
			} else {
				this.root = step
				this.currentStep = step
			}
		} catch (error) {

		}
	}

	nextStep(step) {
		try {
			if (this.currentStep.id !== step.id) {
				throw new Error("Current step not match")
			} else if (!this.currentStep.next) {
				this.data = this.currentStep.setData(this.data)
				return this.submit()
			} else if (typeof this.controller.changeCurrentStep !== "function") {
				throw new Error("Change step function not found")
			}

			this.data = this.currentStep.setData(this.data)
			this.currentStep = this.currentStep.next
			this.currentStep.initData()
			this.controller.changeCurrentStep(this.currentStep)
		} catch (error) {
			console.error("Next step fail", error.message)
		}
	}

	prevStep(step) {
		try {
			if (this.currentStep.id !== step.id) {
				throw new Error("Current step not match")
			} else if (!this.currentStep.prev) {
				this.data = this.currentStep.setData(this.data)
				return this.back()
			} else if (typeof this.controller.changeCurrentStep !== "function") {
				throw new Error("Change step function not found")
			}
			this.data = this.currentStep.setData(this.data)
			this.currentStep = this.currentStep.prev
			this.currentStep.initData()
			this.controller.changeCurrentStep(this.currentStep)
		} catch (error) {
			console.error("Prev step fail", error.message)
		}
	}

	gotoStep(stepId) {
		try {
			if (!stepId) {
				throw new Error("Step id not found")
			}
			let pointer = this.root
				, step = null

			while (pointer !== null && step === null) {
				if (pointer.id === stepId || pointer.key === stepId) {
					step = pointer
				}
				pointer = pointer.next
			}
			if (!step) {
				throw new Error("Step not found")
			}
			this.currentStep = step
			this.currentStep.initData()
			this.controller.changeCurrentStep(this.currentStep)
		} catch (error) {
			console.error("Goto step fail", error.message)
		}
	}

	resetStep(step) {
		try {
			if (!this.root) {
				throw new Error("Root step not found")
			} else if (typeof this.controller.changeCurrentStep !== "function") {
				throw new Error("Change step function not found")
			}
			this.currentStep = this.root
			this.data = null
			this.controller.changeCurrentStep(this.currentStep)
		} catch (error) {
			console.error("Reset step fail", error.message)
		}
	}

	cancelStep(step) {
		try {
			return this.back()
		} catch (error) {
			console.error("Cancel step fail", error.message)
		}
	}

	getData() {
		return this.data
	}

	setData(allResult) {
		return allResult
	}

	getNavigator() {
		let pointer = this.root
			, result = []

		while (pointer != null) {
			result.push(pointer)
			pointer = pointer.next
		}

		return result
	}

	submit() {
		try {
			if (typeof this.controller.submit !== "function") {
				throw new Error("Submit function not found")
			}
			return this.controller.submit(this.data)
		} catch (error) {
			console.error("Submit fail", error.message)
		}
	}

	back() {
		try {
			if (typeof this.controller.back !== "function") {
				throw new Error("Back function not found")
			}

			return this.controller.back()
		} catch (error) {
			console.error("Submit fail", error.message)
		}
	}

	saveStep() {
		try {
			this.data = this.currentStep.setData(this.data)
		} catch (error) {
			console.error("save Step failed", error.message)
		}

	}

	render() {
		return ""
	}
}