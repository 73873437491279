
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'
import LabelInput from '../LabelInput'
import MyForm from '../../../core/components/MyForm'
import inputUtils from '../../../core/utility/inputUtils'

class RS485Form extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true
    }

    handleChangeValue = (e, { name, value }) => {
        let valueProps = this.props.value

        if(valueProps === "") {
            valueProps = {
                channel: 0,
                buadRate: 0
            }
        }

        if (name === "channel") {
            valueProps.channel = parseInt(value)
        } else if (name === "buadRate") {
            valueProps.buadRate = parseInt(value)
        }

        this.props.onChange(null, { name: this.props.name, value: valueProps })
    }

    render() {
        try {
            const { t, disabled, value } = this.props

            let channelValue = 0

            if (!!value && !isNaN(value.channel)) {
                channelValue = parseInt(value.channel)
            }

            let buadRateValue = 0

            if (!!value && !isNaN(value.buadRate)) {
                buadRateValue = parseInt(value.buadRate)
            }

            return (
                <React.Fragment>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("sensor.channel")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form.Input
                                    name="channel"
                                    onKeyDown={inputUtils.onKeyNumberDali}
                                    value={channelValue}
                                    onChange={this.handleChangeValue}
                                    disabled={disabled}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("sensor.buadRate")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form.Input
                                    name="buadRate"
                                    onKeyDown={inputUtils.onKeyNumberDali}
                                    value={buadRateValue}
                                    onChange={this.handleChangeValue}
                                    disabled={disabled}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
                // <React.Fragment>
                //     <Form.Input
                //         name="inputNum"
                //         onKeyDown={inputUtils.onKeyNumber}
                //         value={daliValue}
                //         onChange={this.handleChangeValue}
                //         disabled={disabled}
                //     />
                // </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

RS485Form.defaultProps = {
    name: "",
    type: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate
)

export default enhance(RS485Form)