
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import EWSDataModel from '../../../models/systemIntegrationData/EWSDataModel'

class ExchangeDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props

            let hostValidate = this.getErrorInput("host", errors)
                , usernameValidate = this.getErrorInput("username", errors)
                , passwordValidate = this.getErrorInput("password", errors)
                , addressListIdValidate = this.getErrorInput("addressListId", errors)
                , versionValidate = this.getErrorInput("version", errors)
                , authTypeValidate = this.getErrorInput("authType", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.exchange")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.domain")} isRequire={true} />
                                <Form.Input
                                    name="host"
                                    value={model.host}
                                    onChange={this.handleChange}
                                    error={hostValidate.isError ? hostValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.allowImpersonate")} isRequire={true} />
                                <Form.Checkbox
                                    name="allowImpersonate"
                                    checked={model.allowImpersonate}
                                    onChange={this.handleChangeBoolean}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.username")} isRequire={true} />
                                <Form.Input
                                    name="username"
                                    value={model.username}
                                    onChange={this.handleChange}
                                    error={usernameValidate.isError ? usernameValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.password")} isRequire={true} />
                                <Form.Input
                                    name="password"
                                    value={model.password}
                                    onChange={this.handleChange}
                                    type="password"
                                    error={passwordValidate.isError ? passwordValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.address")} isRequire={false} />
                                <Form.Input
                                    name="addressListId"
                                    value={model.addressListId}
                                    onChange={this.handleChange}
                                    error={addressListIdValidate.isError ? addressListIdValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.version")} isRequire={true} />
                                <Form.Input
                                    name="version"
                                    value={model.version}
                                    onChange={this.handleChange}
                                    error={versionValidate.isError ? versionValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.authType")} isRequire={false} />
                                <Form.Input
                                    name="authType"
                                    value={model.authType}
                                    onChange={this.handleChange}
                                    error={authTypeValidate.isError ? authTypeValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ExchangeDataForm.defaultProps = {
    model: new EWSDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ExchangeDataForm)