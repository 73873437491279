
import mongoose from 'mongoose'

const setBaseSchema = (schema, code = "00") => {

	schema.set("toJSON", { getters: true, virtuals: true })
	schema.set("toObject", { getters: false, virtuals: true })

	schema.virtual('ModelCode').get(function () { return code })

	schema.methods.hasError = function () {
		try {
			return !!this.errors
		} catch (error) {
			return false
		}
	}

	schema.methods.getErrors = function () {
		try {
			let token = []
			if (this.hasError()) {
				let allPath = []
				for (let path in this.errors) {
					allPath.push(path)
				}

				allPath = allPath.reduce((r, e, i, s) => {
					if (s.filter(f => f !== e && !!f.match(new RegExp("^" + e + "$"))).length === 0) {
						r.push(e)
					}

					return r
				}, [])

				for (let i = 0; i < allPath.length; i++) {
					let path = allPath[i]
					token.push({
						path,
						code: "0500" + (this.ModelCode ? this.ModelCode : "00") + "00",
						message: this.errors[path].message,
					})
				}
			}

			if (token.length > 0)
				return token
		} catch (error) {

		}

		return null
	}

	schema.methods.serialize = function () {
		try {
			let result = this.toJSON()

			if ("_id" in result) delete result["_id"]
			if ("createBy" in result) delete result["createBy"]
			if ("createDate" in result) delete result["createDate"]
			if ("updateBy" in result) delete result["updateBy"]
			if ("updateDate" in result) delete result["updateDate"]
			if ("isVoid" in result) delete result["isVoid"]
			if ("ModelCode" in result) delete result["ModelCode"]
			if ("__v" in result) delete result["__v"]

			return result
		} catch (error) {
			return null
		}
	}

	schema.methods.serializeAPI = function () {
		try {
			let result = this.serialize()
			if (!!result) {
				if ("id" in result) delete result["id"]
			}
			return result
		} catch (error) {
			return null
		}
	}

	schema.methods.serializeOption = function () {
		try {
			let result = this.serialize()
			return result
		} catch (error) {
			return null
		}
	}

	schema.methods.setData = function (data) {
		try {
			if (!Array.isArray(data) && typeof data === "object") {
				for (const key in data) {
					if (key in this) {
						this[key] = data[key]
					}
				}
			}
		} catch (error) {

		}
	}

	schema.methods.validateError = async function () {
		try {
			let error = await this.validate()
			if (!error) {
				this.errors = null
			}
		} catch (error) {

		}

		return !this.hasError()
	}
}

const addError = (model, path, message) => {
	let error = new mongoose.Error.ValidatorError({ message, type: "", path })
	if (model.errors && typeof model.errors === "object") {
		model.errors["id"] = error
	} else {
		model.errors = { id: error }
	}
}

const addDataInUsedError = (model) => {
	addError(model, "id", "Data in used")
}


export {
	setBaseSchema,
	addError,
	addDataInUsedError,
}