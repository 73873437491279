
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import FormLocationType from './FormLocationType'
import ButtonModel from '../../core/model/ButtonModel'
import LocationTypeModel from '../../models/LocationTypeModel'

class ViewCreateLocationType extends MyPage {
    constructor(props) {
        super(props)

        let parentId = (!!props.parent && !!props.parent.id ? props.parent.id : "")

        this.state.isLoading = false

        this.state.parentId = parentId
        this.state.model = new LocationTypeModel({ parentId: parentId })
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        const newParentId = (!!nextProps.parent && !!nextProps.parent.id ? nextProps.parent.id : "")
        tempState.parentId = newParentId
        if (Object.keys(tempState).length > 0) {
            return tempState
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.parentId !== this.state.parentId) {
            this.setState({
                model: new LocationTypeModel({ parentId: this.state.parentId })
            })
        }
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onValidateSuccess)
        }
    }

    onValidateSuccess = () => {
        this.props.onCreate(this.state.model)
    }


    onClickCancel = () => {
        this.props.history.push(`/locationtype`)
        //this.props.history.goBack()
    }

    render() {
        const { t, optionsTreeLocationType, isLoadingControl } = this.props
        const { modelErrors, model, isLoading } = this.state

        let isDisableSubmit = isLoadingControl

        try {
            let buttons = [
                new ButtonModel({
                    text: t("common.create"),
                    color: "teal",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t("common.cancel"),
                    color: "grey",
                    onClick: this.onClickCancel
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('locationType.create')} buttons={buttons}>
                        <div className="module viewCreateLocationType">
                            <Container text>
                                <div className="vclt-wrapper">
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <FormLocationType
                                                    handleChange={this.handleChange}
                                                    errors={modelErrors}
                                                    model={model}
                                                    optionsTreeLocationType={optionsTreeLocationType}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </div>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewCreateLocationType.defaultProps = {
    isLoadingControl: false,
    optionsTreeLocationType: [],
    parent: null,
    onCreate: () => console.error("Please provide Create action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewCreateLocationType)