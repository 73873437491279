
import { CALL_API } from '../middlewares'
import { CORE_SERVICE_ENDPOINT } from '../lib'
import type from '../types'

const resetEventControlStatusList = () => {
	return {
		type: type.RESET_EVENT_CONTROL_STATUS_LIST
	}
}

/* const getEventControlStatusInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_EVENT_CONTROL_STATUS_INFO_REQUEST,
				{
					type: type.GET_EVENT_CONTROL_STATUS_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_EVENT_CONTROL_STATUS_INFO_FAILURE
			]
		}
	}
} */

const getEventControlStatusList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/control/status${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_EVENT_CONTROL_STATUS_LIST_REQUEST,
				{
					type: type.GET_EVENT_CONTROL_STATUS_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_EVENT_CONTROL_STATUS_LIST_FAILURE
			]
		}
	}
}


export {
	// getEventControlStatusInfo,
	getEventControlStatusList,

	resetEventControlStatusList,
}