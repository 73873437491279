
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Modal } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import ButtonModel from '../../core/model/ButtonModel'
import { areaTypes } from '../../../constant'
import { CORE_API_ENDPOINT } from '../../lib'
import AreaForm from './AreaForm'
import ViewButton from './ViewButton'
import AreaModel from '../../models/AreaModel'
import utils from '../../core/utility/utils'
import LabelInput from './LabelInput'

const renderView = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    VIEW: "VIEW"
}

class ViewSelectArea extends MyPage {
    constructor(props) {
        super(props)
        let model = new AreaModel()
        if (!!props.model) {
            model = new AreaModel(props.model)
        }
        this.state.open = true
        this.state.model = model
        this.state.timeTemp = moment().format("YYYYMMDDHHmmss")
        this.state.widthBoxMap = null
        this.state.imageData = null
        this.state.optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(props.locationList, "id", "parentId", null, "name", "id")
        this.state.positionHover = null
    }

    componentDidMount() {
        this.getWidthHeightBoxCamera()
    }

    getWidthHeightBoxCamera = () => {
        var el = document.getElementById("vsa-m-wrapper")
        if (!!el && el.offsetHeight > -1) {
            this.setState({
                widthBoxMap: el.offsetWidth
            })
        }
    }

    onOpenModal = () => {
        this.setState({
            open: true
        }, this.getWidthHeightBoxCamera)
    }
    onCloseModal = () => {
        this.setState({
            open: false
        }, this.props.onClose)
    }

    onLoadImage = (data) => {
        if (!!data) {
            const { model } = this.state
            const img = data.target
            let imageData = {
                height: img.naturalHeight,
                width: img.naturalWidth,
                heightDisplay: img.offsetHeight,
                widthDisplay: img.offsetWidth,
            },
                tempState = {
                    imageData
                }

            if (!!model && model.id) {
                // Update Location
                tempState.model = this.updateModelForUpdateArea(imageData, model)
            }

            this.setState(tempState)
        }

    }

    updateModelForUpdateArea = (imageData, model) => {
        try {
            let tempModel = new AreaModel(model)
            tempModel.points = this.convertNaturalSizeToDisplaySize(imageData, tempModel.points)
            return tempModel
        } catch (error) {
            return new AreaModel()
        }
    }

    convertNaturalSizeToDisplaySize = (imageData, points) => {
        try {
            let tempPoints = []
            if (!!imageData && Array.isArray(points) && points.length > 0) {
                points.forEach(point => {
                    tempPoints.push({
                        order: point.order,
                        x: Math.round((point.x * imageData.widthDisplay) / imageData.width),
                        y: Math.round((point.y * imageData.heightDisplay) / imageData.height)
                    })
                })
            }
            return tempPoints
        } catch (error) {
            return []
        }
    }

    handleChange = (e, { name, value }) => {
        try {
            let model = this.state.model
                , modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []

            let tempState = {}

            if (model && name in model) {
                model[name] = value
                if (name === "type") {
                    model.points = []
                }
                tempState.model = model
            } else if (name in this.state) {
                tempState[name] = value
            }

            let index = modelErrors.findIndex(e => e.path === name)
            if (index > -1) {
                modelErrors.splice(index, 1);
                tempState.modelErrors = modelErrors
            }

            if (name === "locationId") {
                tempState.timeTemp = moment().format("YYYYMMDDHHmmss")
            }

            this.setState(tempState)

        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    onClickAddPoint = (e) => {
        var target = e.currentTarget;
        var dim = target.getBoundingClientRect();
        var x = Math.round(e.clientX - dim.left);
        var y = Math.round(e.clientY - dim.top);

        const { model } = this.state
        let points = Array.isArray(model.points) ? model.points.map(e => e) : []
            , lastOrder = points.length > 0 ? points[points.length - 1].order : 0
            , nextOrder = (lastOrder + 1)
            , tempPoint = {
                x: x,
                y: y,
                order: nextOrder
            }
        if (model.type === areaTypes.CIRCLE) {
            if (points.length < 2) {
                points.push(tempPoint)
            }
        } else if (model.type === areaTypes.POLYGON) {
            points.push(tempPoint)
        }
        model.points = points
        this.setState({
            model: model
        })
    }

    onClickClearPoint = (e) => {
        e.preventDefault();
        const { model } = this.state
        model.points = []
        this.setState({
            model: model
        })
    }

    getAreaSelect = (imageData, model) => {
        try {
            let pointStyle = {
                height: 5,
                width: 5
            }
            let content = null
            if (!!imageData && !!model && !!model.type && model.points.length > 0) {
                let points = model.points
                if (model.type === areaTypes.POLYGON) {
                    content = <g>
                        <polygon
                            className="area-style"
                            points={points.map(p => p.x + "," + p.y).join(" ")}
                        />
                        {
                            points.map((p, i) => {
                                return <rect key={i} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={p.x - (pointStyle.width / 2)} y={p.y - (pointStyle.height / 2)}></rect>
                            })
                        }
                    </g>
                } else if (model.type === areaTypes.CIRCLE) {
                    let startPoint = model.points[0]
                        , radiusPoint = null
                        , radius = null
                    if (model.points.length >= 2) {
                        radiusPoint = model.points[1]
                    }
                    if (!!radiusPoint) {
                        radius = Math.sqrt(Math.pow(Math.abs(startPoint.x - radiusPoint.x), 2) + Math.pow(Math.abs(startPoint.y - radiusPoint.y), 2))
                    }
                    content = <g>
                        {
                            !!startPoint && !!radius &&
                            <circle className="area-style"
                                cx={startPoint.x} cy={startPoint.y} r={radius}
                            />
                        }
                        <rect key={1} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={startPoint.x - (pointStyle.width / 2)} y={startPoint.y - (pointStyle.height / 2)}></rect>
                        {!!radiusPoint && <rect key={2} className="area-pointer" height={pointStyle.height} width={pointStyle.width} x={radiusPoint.x - (pointStyle.width / 2)} y={radiusPoint.y - (pointStyle.height / 2)}></rect>}
                    </g>
                }
            }
            return content
        } catch (error) {
            return null
        }
    }

    isDisabledButtonSubmit = (model) => {
        try {
            let isDisabled = false
            isDisabled = (!model || !model.locationId || !(model.type === areaTypes.POLYGON || model.type === areaTypes.CIRCLE))
            if (model.type === areaTypes.POLYGON) {
                isDisabled = !(model.points.length >= 3)
            }
            if (model.type === areaTypes.CIRCLE) {
                isDisabled = !(model.points.length === 2)
            }
            return isDisabled
        } catch (error) {
            return true
        }
    }

    onClickSubmit = () => {
        const { locationList } = this.props
        const { model, imageData } = this.state
        let tempModel = new AreaModel(model)
        let location = locationList.find(l => l.id === model.locationId)
        if (!!location && !!location.locationType) {
            tempModel.locationTypeCode = location.locationType.code
        }
        tempModel.points = model.points.sort((a, b) => a - b).map((point, i) => {
            return {
                order: i + 1,
                x: Math.round((point.x * imageData.width) / imageData.widthDisplay),
                y: Math.round((point.y * imageData.height) / imageData.heightDisplay)
            }
        })
        this.setState({
            open: false
        }, () => {
            this.props.onSubmit(tempModel)
        })

    }

    render() {
        try {
            const { t, viewType, locationList } = this.props
            const { optionsTreeLocation, widthBoxMap, open, model, timeTemp, imageData } = this.state
            let tempTextHeader = t("common.areaCreate")
                , tempButtonSubmit = t('common.add')
                , locationName = "-"
                , areaType = model.type
            if (viewType === renderView.UPDATE) {
                tempTextHeader = t("common.areaUpdate")
                tempButtonSubmit = t("common.save")
            } else if (viewType === renderView.VIEW) {
                tempTextHeader = t("common.areaView")
                if (!!model.locationId && Array.isArray(locationList)) {
                    let location = locationList.find(lo => lo.id === model.locationId)
                    if (!!location) {
                        locationName = location.name
                    }
                }
            }

            return (
                <React.Fragment>
                    <Modal
                        className="module modalViewSelectArea"
                        size="large"
                        open={open}
                        onClose={this.onCloseModal}
                    >
                        <Modal.Content className="module viewSelectArea">
                            <div className="vsa-wrapper">
                                <div className="vsa-form">
                                    <div className="vsa-f-wrapper">
                                        <h2>{tempTextHeader}</h2>
                                        {
                                            viewType === renderView.VIEW ?
                                                <Grid>
                                                    <Grid.Row>
                                                        <Grid.Column width={6}>
                                                            <Grid>
                                                                <Grid.Column width={4} verticalAlign="middle">
                                                                    <LabelInput text={t("location.title") + ": "} />
                                                                </Grid.Column>
                                                                <Grid.Column width={12} verticalAlign="middle">
                                                                    <p>{locationName}</p>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Column>
                                                        <Grid.Column width={6}>
                                                            <Grid>
                                                                <Grid.Column width={4} verticalAlign="middle">
                                                                    <LabelInput text={t("common.areaType") + ": "} />
                                                                </Grid.Column>
                                                                <Grid.Column width={12} verticalAlign="middle">
                                                                    <p>{areaType}</p>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                : <AreaForm model={model} optionsTreeLocation={optionsTreeLocation} handleChange={this.handleChange} />
                                        }
                                    </div>
                                </div>
                                <div className="vsa-map">
                                    <div className="vsa-m-wrapper" id="vsa-m-wrapper" style={{ height: widthBoxMap > 0 ? ((widthBoxMap / 16) * 9) : 0 }}>
                                        {
                                            model.locationId ?
                                                <div className="map-wrapper">
                                                    <div className="map-image">
                                                        <img src={`${CORE_API_ENDPOINT}/image/location/${model.locationId}?time=${timeTemp}`} alt="No map" onLoad={this.onLoadImage} />
                                                        <svg className={`location-svg ${viewType === renderView.VIEW ? "disabled" : ""}`}
                                                            onContextMenu={viewType !== renderView.VIEW ? this.onClickClearPoint : () => { }}
                                                        >
                                                            {
                                                                viewType !== renderView.VIEW &&
                                                                <g className="l-svg-body" onClick={this.onClickAddPoint}>
                                                                    <rect height="100%" width="100%" fill="#fff0"></rect>
                                                                </g>
                                                            }
                                                            {this.getAreaSelect(imageData, model)}
                                                        </svg>
                                                    </div>
                                                </div>
                                                : <div className="map-wrapper-nolocation">
                                                    <h3>{t("common.pleaseSelectLocation")}</h3>
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="vsa-action">
                                    {
                                        viewType !== renderView.VIEW &&
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: tempButtonSubmit,
                                                color: "green",
                                                disabled: this.isDisabledButtonSubmit(model),
                                                onClick: this.onClickSubmit
                                            })}
                                        />
                                    }
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t('common.back'),
                                            color: "grey",
                                            onClick: this.onCloseModal
                                        })}
                                    />
                                </div>
                            </div>
                        </Modal.Content>
                    </Modal>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ViewSelectArea.defaultProps = {
    model: null,//Model Area เอาไว้ทำ update
    locationList: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
    viewType: renderView.CREATE
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ViewSelectArea)