
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const GroupSchema = new Schema({
	id: { type: String },
	name: { type: String }
}, { _id: false })

const UserSchema = new Schema({
	id: { type: String },
	name: { type: String }
}, { _id: false })

const LineDataSchema = new Schema({
	groups: [{ type: GroupSchema }],
	users: [{ type: UserSchema }]
}, { _id: false })

setBaseSchema(LineDataSchema, "notification_line")

// LineDataSchema.path("groups").validate(async function (groups) {
// 	if (!Array.isArray(groups) || groups.length === 0) {
// 		return false
// 	}
// 	return true
// }, "validate.require")

// LineDataSchema.path("users").validate(async function (users) {
// 	if (!Array.isArray(users) || users.length === 0) {
// 		return false
// 	}
// 	return true
// }, "validate.require")

const LineDataModel = mongoose.model("notification_line", LineDataSchema, "notification_line")

export default LineDataModel