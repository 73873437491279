
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import NotificationManager from '../../business/NotificationManager'
import NotificationList from '../../views/notification/NotificationList'

class NotificationController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bNotification = new NotificationManager(this)
	}

	getPropsResetStatus() {
		return ["notificationList"]
	}

	onDeleteNotification = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bNotification.deleteNotification(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})

	}

	getNotificationListPaging = (query) => {
		return this.bNotification.getNotificationListPaging(query)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<NotificationList
						onDeleteNotification={this.onDeleteNotification}
						onGetDataPaging={this.getNotificationListPaging}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
/* 	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {
		}
	}), */
)


export default enhance(NotificationController)