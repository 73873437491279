import React from 'react'
import { translate } from 'react-switch-lang'
import { Form } from 'semantic-ui-react'
import MyComponent from '../../components/MyComponent'

import '../../scss/inputFile.scss'
import ButtonModel from '../../model/ButtonModel'
import ViewButton from '../../../views/common/ViewButton'
// import FileBase64 from "react-file-base64";

const timeGap = 1000

class InputFile extends MyComponent {

    constructor(props) {
        super(props)

        this.state.isLoadingSelectFile = false
        this.state.isLoadingTakePhotoFile = false
        this.state.fileInfo = null

        this.inputRef = React.createRef()
    }

    onOpenBrowseFile = () => {
        this.inputRef.current.click();
    }

    handleChangeFile = (e) => {
        e.persist();
        const { accepts, name } = this.props
        let fileTypes = Array.isArray(accepts) ? accepts : []
        this.setState({
            isLoadingSelectFile: true
        }, async () => {
            // get the files

            let datas = null

            try {
                let files = e.target.files;
                datas = await new Promise((reslove, reject) => {
                    try {
                        var allFiles = []
                        for (var i = 0; i < files.length; i++) {

                            let file = files[i]
                                , type = file.type
                                , extension = type.split('/')[1]

                            // Make new FileReader
                            let reader = new FileReader();

                            // Convert the file to base64 text
                            reader.readAsDataURL(file);

                            // on reader load somthing...
                            reader.onload = () => {
                                try {
                                    // Make a fileInfo Object
                                    let contentBase64 = reader.result

                                    let fileInfo = {
                                        fileName: file.name,
                                        mimeType: type,
                                        size: file.size,
                                        content: contentBase64,
                                        extension: extension
                                    };

                                    // Push it to the state
                                    allFiles.push(fileInfo);
                                    if (allFiles.length === files.length) {
                                        setTimeout(() => {
                                            return reslove(allFiles)
                                        }, timeGap)
                                    }
                                } catch (error) {
                                    return reject(error)
                                }

                            } // reader.onload

                        } // for

                    } catch (error) {
                        return reject(error)
                    }
                })
            } catch (error) {

            }


            let body = {
                isLoadingSelectFile: false,
                fileInfo: null,
            },
                action = () => { }

            if (Array.isArray(datas) && datas.length > 0) {
                let fileInfo = datas[0]
                    , fileType = fileInfo.mimeType
                let isCheckFileType = true
                if (fileTypes.length > 0) {
                    isCheckFileType = fileTypes.includes(fileType)
                }
                if (isCheckFileType) {
                    body.fileInfo = fileInfo
                    action = () => this.props.onChange(null, { name: name, value: fileInfo })
                }
            }
            this.setState(body, action)
        })


    }

    render() {
        const { t, accepts, error } = this.props
        const { isLoadingSelectFile, fileInfo } = this.state
        return (
            <React.Fragment>
                <div className="module inputFile">
                    <div className="if-wrapper">
                        <div className="box-input-ui">
                            <div className="biu-input">
                                <Form>
                                    <Form.Input
                                        fluid
                                        loading={isLoadingSelectFile}
                                        disabled
                                        value={!!fileInfo ? fileInfo.fileName : ""}
                                        error={error ? error : null}
                                    />
                                </Form>
                            </div>
                            <div className="biu-action">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.selectFile"),
                                        color: "grey",
                                        onClick: this.onOpenBrowseFile
                                    })}
                                />
                            </div>
                        </div>
                        <div className="box-input-file">
                            <input ref={this.inputRef} type="file" accept={Array.isArray(accepts) ? accepts.join(", ") : ""} onChange={this.handleChangeFile} />
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

InputFile.defaultProps = {
    name: "",
    error: null,
    onChange: () => console.error("Please provide On Change Image action."),
    accepts: [],
}


export default translate(InputFile)