
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getRFIDCardTypeList, getRFIDCardDataTypeList } from '../../actions/rfidCardActions'
import RFIDCardManager from '../../business/RFIDCardManager'
import RFIDCardCreate from '../../views/rfidCard/RFIDCardCreate'

class CreateRFIDCardController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bRFIDCard = new RFIDCardManager(this)
    }

    onCreateRFIDCard = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bRFIDCard.createRFIDCard(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === false) {

                } else if (!!res && res.result === true) {

                    action = this.props.history.push(`/rfidcard/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    render() {
        try {
            const { rfidCardTypeList, rfidCardDataTypeList } = this.props
            const { isLoadingComponent } = this.state

            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            const optionsRFIDCardType = rfidCardTypeList.getDatasOption()
                , optionsRFIDCardDataType = rfidCardDataTypeList.getDatasOption()

            return (
                <React.Fragment>
                    {super.render()}
                    <RFIDCardCreate
                        isLoadingControl={isLoadingComponent}
                        optionsRFIDCardType={optionsRFIDCardType}
                        optionsRFIDCardDataType={optionsRFIDCardDataType}
                        onCreate={this.onCreateRFIDCard} />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            rfidCardTypeList: state.rfidCardTypeList,
            rfidCardDataTypeList: state.rfidCardDataTypeList,
        }),
        (dispatch, props) => ({
            getRFIDCardTypeList() {
                dispatch(getRFIDCardTypeList())
            },
            getRFIDCardDataTypeList() {
                dispatch(getRFIDCardDataTypeList())
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getRFIDCardTypeList()
            this.props.getRFIDCardDataTypeList()
        }
    }),
)


export default enhance(CreateRFIDCardController)