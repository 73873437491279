
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getSettingSensorInfo, getSettingSensorTypeList, getSettingSensorStandardOperationList } from '../../actions/settingSensorActions'
import { translate } from 'react-switch-lang'
import SettingSensorManager from '../../business/SettingSensorManager'
import SettingSensorView from '../../views/settingSensor/SettingSensorView'

class ViewSettingSensorController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSettingSensor = new SettingSensorManager(this)
	}

	getPropsResetStatus() {
		return ["settingSensorInfo"]
	}

	onUpdateSettingSensor = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSettingSensor.upsertSettingSensor(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/setting/sensor`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { t, settingSensorInfo, settingSensorTypeList, settingSensorStandardOperationList } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const settingSensor = settingSensorInfo.getData()
				, optionsSensorType = settingSensorTypeList.getDatasOption()
				, optionsSensorStandardOperation = settingSensorStandardOperationList.getDatasOption()

			let optionsStandardOperationTranslate = optionsSensorStandardOperation.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.airQualityOperation.${d.value}`)
				}
			})

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<SettingSensorView
							model={settingSensor}
							optionsSensorType={optionsSensorType}
							optionsSensorStandardOperation={optionsStandardOperationTranslate}
							onUpdate={this.onUpdateSettingSensor}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			settingSensorInfo: state.settingSensorInfo,
			settingSensorTypeList: state.settingSensorTypeList,
			settingSensorStandardOperationList: state.settingSensorStandardOperationList
		}),
		(dispatch, props) => ({
			getSettingSensorInfo() {
				dispatch(getSettingSensorInfo())
			},
			getSettingSensorTypeList() {
				dispatch(getSettingSensorTypeList())
			},
			getSettingSensorStandardOperationList() {
				dispatch(getSettingSensorStandardOperationList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSettingSensorInfo()
			this.props.getSettingSensorTypeList()
			this.props.getSettingSensorStandardOperationList()
		}
	}),
)


export default enhance(ViewSettingSensorController)