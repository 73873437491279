
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const GoogleAuthenDataSchema = new Schema({
	clientId: { type: String, required: [true, "validate.require"], default: "" },
	projectId: { type: String, required: [true, "validate.require"], default: "" },
	authUri: { type: String, required: [true, "validate.require"], default: "" },
	tokenUri: { type: String, required: [true, "validate.require"], default: "" },
	authProviderX509CertUrl: { type: String, required: [true, "validate.require"], default: "" },
	clientSecret: { type: String, required: [true, "validate.require"], default: "" },
	redirectUri: { type: String, required: [true, "validate.require"], default: "" },
}, { _id: false })

setBaseSchema(GoogleAuthenDataSchema, "system_integration_google_authen")

const GoogleAuthenDataModel = mongoose.model("system_integration_google_authen", GoogleAuthenDataSchema, "system_integration_google_authen")

export default GoogleAuthenDataModel