
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ReportForm from './ReportForm'
import ButtonModel from '../../core/model/ButtonModel'
import ReportModel from '../../models/ReportModel'
import { reportType } from '../../../constant'
import ViewButton from '../common/ViewButton'
import moment from 'moment'

class CreateReportAirQualityWeekly extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = new ReportModel({
            type: reportType.AIR_QUALITY_WEEKLY,
            startDate: moment().startOf("d"),
            endDate: moment().endOf("d")
        })
        this.state.modelErrors = []
    }

    onCreate = (model) => {
        this.props.onCreate(model)
    }

    render() {
        try {
            const { model, modelErrors } = this.state
            const { t } = this.props

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('report.airQualityWeekly')}>
                        <Container text>
                            <div className="body">
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <ReportForm
                                                model={model}
                                                handleChange={this.handleChange}
                                                errors={modelErrors}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16} textAlign="center">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    text: t('report.exportReport'),
                                                    color: "teal",
                                                    onClick: () => this.onValidate(() => this.onCreate(model))
                                                })}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

CreateReportAirQualityWeekly.defaultProps = {
    resourceList: [],
    onCreate: () => console.error("Please provide \"Create Report\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(CreateReportAirQualityWeekly)