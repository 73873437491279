
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import ViewSelectImageOrTakePhoto from '../../core/view/common/ViewSelectImageOrTakePhoto'
import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import VisitorModel from '../../models/VisitorModel'
import MultipleCheckboxList from '../common/MultipleCheckboxList'

class VisitorForm extends MyForm {
    render() {
        try {
            const { t, model, errors, optionVisitorGroup } = this.props

            let visitorGroupIdsValidate = this.getErrorInput("visitorGroupIds", errors),
                codeValidate = this.getErrorInput("code", errors),
                givenNameValidate = this.getErrorInput("givenName", errors),
                snValidate = this.getErrorInput("sn", errors),
                emailValidate = this.getErrorInput("email", errors),
                mobileValidate = this.getErrorInput("mobile", errors),
                pinCodeValidate = this.getErrorInput("pinCode", errors),
                usernameValidate = this.getErrorInput("username", errors),
                passwordValidate = this.getErrorInput("password", errors),
                confirmPasswordValidate = this.getErrorInput("confirmPassword", errors),
                rfIdValidate = this.getErrorInput("rfId", errors)


            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Header as='h1' dividing className="form header">
                            {t("visitor.information")}
                        </Header>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        placeholder={t('common.code')}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.givenName')} isRequire={true} />
                                    <Form.Input
                                        name="givenName"
                                        value={model.givenName}
                                        onChange={this.handleChange}
                                        placeholder={t('visitor.givenName')}
                                        error={givenNameValidate.isError ? givenNameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.sn')} isRequire={false} />
                                    <Form.Input
                                        name="sn"
                                        value={model.sn}
                                        onChange={this.handleChange}
                                        placeholder={t('visitor.sn')}
                                        error={snValidate.isError ? snValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.mobile')} isRequire={false} />
                                    <Form.Input
                                        name="mobile"
                                        value={model.mobile}
                                        onChange={this.handleChange}
                                        placeholder={t('common.mobile')}
                                        error={mobileValidate.isError ? mobileValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.email')} isRequire={false} />
                                    <Form.Input
                                        name="email"
                                        value={model.email}
                                        onChange={this.handleChange}
                                        placeholder={t('common.email')}
                                        error={emailValidate.isError ? emailValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.pinCode')} isRequire={false} />
                                    <Form.Input
                                        name="pinCode"
                                        value={model.pinCode}
                                        onChange={this.handleChange}
                                        placeholder={t('visitor.pinCode')}
                                        error={pinCodeValidate.isError ? pinCodeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.rfId')} isRequire={false} />
                                    <Form.Input
                                        name="rfId"
                                        value={model.rfId}
                                        onChange={this.handleChange}
                                        placeholder={t('visitor.rfId')}
                                        error={rfIdValidate.isError ? rfIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.visitorGroups')} isRequire={true} />
                                    <MultipleCheckboxList
                                        name="visitorGroupIds"
                                        optionsDataList={optionVisitorGroup}
                                        value={model.visitorGroupIds}
                                        onChange={this.handleChange}
                                        error={visitorGroupIdsValidate}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("visitor.profile")} isRequire={false} />
                                    <ViewSelectImageOrTakePhoto name="fileUpload" defaultImage={!!model.id ? `visitor/${model.id}` : ""} onChange={this.handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <br></br>
                        <Header as='h1' dividing className="form header">
                            {t("visitor.sysInfo")}
                        </Header>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.username')} isRequire={false} />
                                    <Form.Input
                                        name="username"
                                        value={model.username}
                                        onChange={this.handleChange}
                                        placeholder={t('visitor.username')}
                                        error={usernameValidate.isError ? usernameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.password')} isRequire={!model.id} />
                                    <Form.Input
                                        name="password"
                                        value={model.password}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder={t('visitor.password')}
                                        error={passwordValidate.isError ? passwordValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('visitor.confirmPassword')} isRequire={!!model.password && !model.id} />
                                    <Form.Input
                                        name="confirmPassword"
                                        value={model.confirmPassword}
                                        onChange={this.handleChange}
                                        type="password"
                                        placeholder={t('visitor.confirmPassword')}
                                        error={confirmPasswordValidate.isError ? confirmPasswordValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

VisitorForm.defaultProps = {
    model: new VisitorModel(),
    errors: [],
    handleChange: () => console.error("Please provide \"Change Visitor\" action."),
    optionVisitorGroup: [],
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(VisitorForm)