
import types from '../types'
import { requestStatus } from '../../constant'
import IOTConfigResource from '../selectors/iotConfigResource'
import ControllerResource from '../selectors/controllerResource'
import IOTResourceDropdownResource from '../selectors/iotResourceDropdownResource'
import IOTConfigListResource from '../selectors/iotConfigListResource'

const iotConfigReducer = {
	iotConfigInfo: (state = null, action) => {
		if (!state || !(state instanceof IOTConfigResource)) {
			state = new IOTConfigResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_INFO_REQUEST:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_INFO_SUCCESS:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_IOT_CONFIG_INFO_FAILURE:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	iotConfigList: (state = null, action) => {
		if (!state || !(state instanceof IOTConfigResource)) {
			state = new IOTConfigResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_LIST_REQUEST:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_LIST_SUCCESS:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_IOT_CONFIG_LIST:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_LIST_FAILURE:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	iotConfigLocationList: (state = null, action) => {
		if (!state || !(state instanceof IOTConfigResource)) {
			state = new IOTConfigResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_LOCATION_LIST_REQUEST:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_LOCATION_LIST_SUCCESS:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_IOT_CONFIG_LOCATION_LIST_FAILURE:
				state = new IOTConfigResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	iotConfigResourceList: (state = null, action) => {
		if (!state || !(state instanceof IOTResourceDropdownResource)) {
			state = new IOTResourceDropdownResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_RESOURCE_LIST_REQUEST:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_RESOURCE_LIST_SUCCESS:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_IOT_CONFIG_RESOURCE_LIST_FAILURE:
				state = new IOTResourceDropdownResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	iotConfigIOTList: (state = null, action) => {
		if (!state || !(state instanceof IOTConfigListResource)) {
			state = new IOTConfigListResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_IOT_LIST_REQUEST:
				state = new IOTConfigListResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_IOT_LIST_SUCCESS:
				state = new IOTConfigListResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_IOT_CONFIG_IOT_LIST_FAILURE:
				state = new IOTConfigListResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	iotConfigControllerList: (state = null, action) => {
		if (!state || !(state instanceof ControllerResource)) {
			state = new ControllerResource()
		}

		switch (action.type) {
			case types.GET_IOT_CONFIG_CONTROLLER_LIST_REQUEST:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_IOT_CONFIG_CONTROLLER_LIST_SUCCESS:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_IOT_CONFIG_CONTROLLER_LIST_FAILURE:
				state = new ControllerResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
}

export default iotConfigReducer