
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getRoleInfo } from '../../actions/roleActions'
import RoleManager from '../../business/RoleManager'
import utils from '../../core/utility/utils'
import RoleUpdate from '../../views/role/RoleUpdate'

class UpdateRoleController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bRole = new RoleManager(this)
	}

	getPropsResetStatus() {
		return ["roleInfo"]
	}

	onUpdateRole = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bRole.updateRole(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/role/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { roleInfo } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const role = roleInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<RoleUpdate
						isLoadingControl={isLoadingComponent}
						model={role}
						onUpdate={this.onUpdateRole}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			roleInfo: state.roleInfo
		}),
		(dispatch, props) => ({
			getRoleInfo() {
				dispatch(getRoleInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getRoleInfo()
		}
	}),
)


export default enhance(UpdateRoleController)