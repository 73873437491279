
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class SettingManager extends MyManager {
    async getRoomSceneInfo(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/room/scene/${id}`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

    async getDeskSceneInfo(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk/scene/${id}`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getLocationSceneInfo(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/location/scene/${id}`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}
}