import React from 'react'
import {
    // setTranslations,
    // setDefaultLanguage,
    // setLanguageCookie,
    setLanguage,
    getLanguage,
} from 'react-switch-lang'

class SwitchLanguage extends React.Component {
    onChangeLanguage = (key) => {
        setLanguage(key)
    }
    render() {
        let langUse = getLanguage()
        return (
            <React.Fragment>
                <div className="module switchLanguage">
                    <div className="sl-wrapper">
                        <div className={`sl-item ${langUse === "en" ? "active" : ""}`} onClick={() => this.onChangeLanguage("en")}>
                            <h3>EN</h3>
                        </div>
                        <div className={`sl-item ${langUse === "th" ? "active" : ""}`} onClick={() => this.onChangeLanguage("th")}>
                            <h3>TH</h3>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

SwitchLanguage.defaultProps = {
    // header: "",
    // content: null,
    // onClose: () => console.error("Please provide \"Close\" action"),
    // actions: []
}


export default SwitchLanguage