
import { CORE_SERVICE_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'


export default class EventControlRuleManager extends MyManager {

	async forceTurnOffResource(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/force/turnoff/resource/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async forceTurnOffLocation(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/force/turnoff/location/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

}