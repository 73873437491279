
import ActionResourceObject from '../core/redux/ActionResourceObject'
import ControllerModel from '../models/ControllerModel'

export default class ControllerResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new ControllerModel(d))
			} else {
				result = new ControllerModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.mac
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.mac
				}
			}

			return result
		} catch (error) {
			return null
		}
	}

}