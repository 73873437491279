
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'

class ViewHome extends MyPage {
    // constructor(props) {
    //     super(props)
    //     this.isRenderSystemModal = true
    //     this.state.count = 0
    // }
    // openModal = () => {
    //     let option = this.getInfoModalOption()
    //     this.setState({
    //         modal: {
    //             ...option,
    //         }
    //     })
    // }
    // getModalContent = (modal, count) => {
    //     let ModalView = this.getModalView()
    //     if (!ModalView) {
    //         return null
    //     }
    //     return <ModalView
    //         {...modal}
    //         content={<h1>Count == {count}</h1>}
    //         buttons={
    //             [
    //                 {
    //                     onClick: () => { this.setState({ count: count + 1 }) },
    //                     text: "+",
    //                     color: "blue",
    //                     iconClass: "plus"
    //                 },
    //                 {
    //                     onClick: this.closeModal,
    //                     text: "555555"
    //                 }
    //             ]
    //         }
    //     />
    // }
    render() {
        // const { count, modal } = this.state
        try {
            return (
                <React.Fragment>
                    {super.render()}
                    {/* {this.getModalContent(modal, count)} */}
                    <LayoutWithSidebarHeaderFooter>
                        <Container>
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column width={16} textAlign="center">
                                        <h1>SMART CONTROL BACKEND</h1>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewHome)