
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import DepartmentModel from '../../models/DepartmentModel'
import LabelInput from '../common/LabelInput'
import InputSelectDepartment from './InputSelectDepartment'
import { defaultValue } from '../../../constant'

class FormDepartment extends MyForm {

    render() {
        const { t, model, errors } = this.props

        let codeValidate = this.getErrorInput("code", errors)
            , nameValidate = this.getErrorInput("name", errors)
            , parentIdValidate = this.getErrorInput("parentId", errors)

        try {
            return (
                <React.Fragment>
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.code")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        fluid
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.name")} isRequire={true} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        fluid
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("department.parent")} isRequire={false} />
                                    <InputSelectDepartment
                                        name="parentId"
                                        placeholder={t("common.pleaseSelect")}
                                        onChange={this.handleChange}
                                        value={model.parentId}
                                        defaultValue={!model.parentId ? defaultValue.NONE_SELECT : ""}
                                        initialDatas={[
                                            {
                                                id: defaultValue.NONE_SELECT,
                                                text: t("common.noSelect"),
                                                value: ""
                                            }
                                        ]}
                                        error={parentIdValidate.isError ? parentIdValidate.content : null}
                                        selfId={!!model.id ? model.id : ""}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    <Form.TextArea
                                        rows={3}
                                        onChange={this.handleChange}
                                        name="description"
                                        value={model.description} />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormDepartment.defaultProps = {
    errors: [],
    handleChange: () => console.error("Please provide \"Handle Change\" action"),
    model: new DepartmentModel(),
}

export default (translate(FormDepartment))