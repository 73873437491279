import { combineReducers } from 'redux'
// import { reducer as formReducer } from 'redux-form'

import errorReducer from '../app/reducers/errorReducer'
import accountReducer from './reducers/accountReducer'
import adminReducer from './reducers/adminReducer'
import departmentReducer from '../app/reducers/departmentReducer'
import resourceGroupReducer from './reducers/resourceGroupReducer'
import userReducer from './reducers/userReducer'
import locationReducer from '../app/reducers/locationReducer'
import locationTypeReducer from '../app/reducers/locationTypeReducer'
import deskReducer from './reducers/deskReducer'
import roomReducer from './reducers/roomReducer'
import roleReducer from './reducers/roleReducer'
import settingReducer from './reducers/settingReducer'
import controllerReducer from './reducers/controllerReducer'
import gatewayReducer from './reducers/gatewayReducer'
import iotConfigReducer from './reducers/iotConfigReducer'
import holidayReducer from './reducers/holidayReducer'
import scheduleControlReducer from './reducers/scheduleControlReducer'
import doorReducer from './reducers/doorReducer'
import systemIntegrationReducer from './reducers/systemIntegrationReducer'
import sensorRuleReducer from './reducers/sensorRuleReducer'
import notificationReducer from './reducers/notificationReducer'
import sensorReducer from './reducers/sensorReducer'
import settingSensorReducer from './reducers/settingSensorReducer'
import visitorGroupReducer from './reducers/visitorGroupReducer'
import visitorReducer from './reducers/visitorReducer'
import eventControlRuleReducer from './reducers/eventControlRuleReducer'
import eventControlStatusReducer from './reducers/eventControlStatusReducer'
import rfidCardReducer from './reducers/rfidCardReducer'

export default combineReducers({

    // form: formReducer,
    errors: errorReducer.errorList,

    accountInfo: accountReducer.accountInfo,
    accountIntegrationList: accountReducer.accountIntegrationList,

    adminInfo: adminReducer.adminInfo,
    adminList: adminReducer.adminList,
    adminStatusList: adminReducer.adminStatusList,
    adminIntegrationList: adminReducer.adminIntegrationList,
    adminADList: adminReducer.adminADList,
    adminExchangeList: adminReducer.adminExchangeList,

    department: departmentReducer.department,
    departmentList: departmentReducer.departmentList,

    resourceGroupInfo: resourceGroupReducer.resourceGroupInfo,
    resourceGroupList: resourceGroupReducer.resourceGroupList,
    resourceGroupUserList: resourceGroupReducer.resourceGroupUserList,

    userInfo: userReducer.userInfo,
    userList: userReducer.userList,
    userStatusList: userReducer.userStatusList,
    userIntegrationList: userReducer.userIntegrationList,
    userADList: userReducer.userADList,
    userExchangeList: userReducer.userExchangeList,

    locationInfo: locationReducer.locationInfo,
    locationList: locationReducer.locationList,
    locationWithIOTConfigInfo: locationReducer.locationWithIOTConfigInfo,
    locationSceneInfo: locationReducer.locationSceneInfo,

    locationType: locationTypeReducer.locationType,
    locationTypeList: locationTypeReducer.locationTypeList,

    holidayInfo: holidayReducer.holidayInfo,
    holidayList: holidayReducer.holidayList,

    doorInfo: doorReducer.doorInfo,
    doorList: doorReducer.doorList,
    doorWithIOTConfigInfo: doorReducer.doorWithIOTConfigInfo,
    doorSceneInfo: doorReducer.doorSceneInfo,

    deskInfo: deskReducer.deskInfo,
    deskList: deskReducer.deskList,
    deskWithIOTConfigInfo: deskReducer.deskWithIOTConfigInfo,
    deskSceneInfo: deskReducer.deskSceneInfo,

    roomInfo: roomReducer.roomInfo,
    roomList: roomReducer.roomList,
    roomWithIOTConfigInfo: roomReducer.roomWithIOTConfigInfo,
    roomSceneInfo: roomReducer.roomSceneInfo,

    roleInfo: roleReducer.roleInfo,
    roleList: roleReducer.roleList,

    settingInfo: settingReducer.settingInfo,
    settingSystemList: settingReducer.settingSystemList,
    settingLoginInfo: settingReducer.settingLoginInfo,
    settingNotificationList: settingReducer.settingNotificationList,

    controllerInfo: controllerReducer.controllerInfo,
    controllerList: controllerReducer.controllerList,
    controllerConfigInfo: controllerReducer.controllerConfigInfo,

    gatewayInfo: gatewayReducer.gatewayInfo,
    gatewayList: gatewayReducer.gatewayList,

    scheduleControlInfo: scheduleControlReducer.scheduleControlInfo,
    scheduleControlList: scheduleControlReducer.scheduleControlList,
    scheduleLocationTypeList: scheduleControlReducer.scheduleLocationTypeList,
    recurrenceTypeList: scheduleControlReducer.recurrenceTypeList,
    dayOfWeekList: scheduleControlReducer.dayOfWeekList,
    scheduleResourceList: scheduleControlReducer.scheduleResourceList,
    scheduleHolidayTypeList: scheduleControlReducer.scheduleHolidayTypeList,

    iotConfigInfo: iotConfigReducer.iotConfigInfo,
    iotConfigList: iotConfigReducer.iotConfigList,
    iotConfigLocationList: iotConfigReducer.iotConfigLocationList,
    iotConfigResourceList: iotConfigReducer.iotConfigResourceList,
    iotConfigIOTList: iotConfigReducer.iotConfigIOTList,
    iotConfigControllerList: iotConfigReducer.iotConfigControllerList,

    systemIntegrationInfo: systemIntegrationReducer.systemIntegrationInfo,
    systemIntegrationServiceList: systemIntegrationReducer.systemIntegrationServiceList,
    systemIntegrationList: systemIntegrationReducer.systemIntegrationList,

    sensorRuleList: sensorRuleReducer.sensorRuleList,
    sensorRuleInfo: sensorRuleReducer.sensorRuleInfo,
    sensorRuleTypeList: sensorRuleReducer.sensorRuleTypeList,
    conditionHolidayTypeList: sensorRuleReducer.conditionHolidayTypeList,
    sensorRuleActionTypeList: sensorRuleReducer.sensorRuleActionTypeList,
    sensorRuleActionResultList: sensorRuleReducer.sensorRuleActionResultList,
    sensorRuleLocationTypeList: sensorRuleReducer.sensorRuleLocationTypeList,
    sensorRuleResourceList: sensorRuleReducer.sensorRuleResourceList,
    sensorRuleDataTypeList: sensorRuleReducer.sensorRuleDataTypeList,
    sensorRuleRecurrenceTypeList: sensorRuleReducer.sensorRuleRecurrenceTypeList,
    sensorRuleDayofWeekList: sensorRuleReducer.sensorRuleDayofWeekList,

    notificationInfo: notificationReducer.notificationInfo,
    notificationServiceList: notificationReducer.notificationServiceList,
    notificationSystemList: notificationReducer.notificationSystemList,
    notificationList: notificationReducer.notificationList,

    sensorInfo: sensorReducer.sensorInfo,
    sensorList: sensorReducer.sensorList,
    sensorLocationList: sensorReducer.sensorLocationList,
    sensorResourceList: sensorReducer.sensorResourceList,
    sensorTypeList: sensorReducer.sensorTypeList,
    sensorModelList: sensorReducer.sensorModelList,
    sensorModuleList: sensorReducer.sensorModuleList,
    sensorLocationTypeList: sensorReducer.sensorLocationTypeList,
    sensorControllerList: sensorReducer.sensorControllerList,

    settingSensorInfo: settingSensorReducer.settingSensorInfo,
    settingSensorTypeList: settingSensorReducer.settingSensorTypeList,
    settingSensorStandardOperationList: settingSensorReducer.settingSensorStandardOperationList,

    visitorGroupInfo: visitorGroupReducer.visitorGroupInfo,
    visitorGroupList: visitorGroupReducer.visitorGroupList,

    visitorList: visitorReducer.visitorList,
    visitorInfo: visitorReducer.visitorInfo,
    visitorStatusList: visitorReducer.visitorStatusList,

    eventControlRuleInfo: eventControlRuleReducer.eventControlRuleInfo,
    eventControlRuleList: eventControlRuleReducer.eventControlRuleList,
    ecrHolidayTypeList: eventControlRuleReducer.ecrHolidayTypeList,
    ecrActionTypeList: eventControlRuleReducer.ecrActionTypeList,
    ecrActionStatusList: eventControlRuleReducer.ecrActionStatusList,
    ecrRecurrenceTypeList: eventControlRuleReducer.ecrRecurrenceTypeList,
    ecrDayOfWeekList: eventControlRuleReducer.ecrDayOfWeekList,
    ecrEventList: eventControlRuleReducer.ecrEventList,

    eventControlStatusList: eventControlStatusReducer.eventControlStatusList,
    
    rfidCardInfo: rfidCardReducer.rfidCardInfo,
    rfidCardList: rfidCardReducer.rfidCardList,
    rfidCardTypeList: rfidCardReducer.rfidCardTypeList,
    rfidCardDataTypeList: rfidCardReducer.rfidCardDataTypeList,
})