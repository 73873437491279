
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Header } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import LineDataModel from '../../../models/systemIntegrationData/LineDataModel'

class LineDataForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props
            let channelAccessTokenValidate = this.getErrorInput("channelAccessToken", errors)
                , channelSecretValidate = this.getErrorInput("channelSecret", errors)
            return (
                <React.Fragment>
                    {super.render()}
                    <Header as='h1' dividing className="form header">
                        {t("systemIntegration.line")}
                    </Header>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.channelAccessToken")} isRequire={true} />
                                <Form.TextArea
                                    name="channelAccessToken"
                                    value={model.channelAccessToken}
                                    onChange={this.handleChange}
                                    error={channelAccessTokenValidate.isError ? channelAccessTokenValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("systemIntegration.channelSecret")} isRequire={true} />
                                <Form.Input
                                    name="channelSecret"
                                    value={model.channelSecret}
                                    onChange={this.handleChange}
                                    error={channelSecretValidate.isError ? channelSecretValidate.content : null}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

LineDataForm.defaultProps = {
    model: new LineDataModel(),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(LineDataForm)