
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form, Label } from 'semantic-ui-react'
import InputDatePicker from '../../core/view/common/InputDatePicker'
import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'
import { reportType, disableDateType } from '../../../constant'

class ReportForm extends MyForm {
    render() {
        try {
            const { t, model, errors, type } = this.props

            let endDateTimeValidate = this.getErrorInput("endDateTime", errors)
                , dateValidate = this.getErrorInput("date", errors)

            let typeChecker = ""

            if (type) {
                typeChecker = type
            }

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid centered>
                            {
                                typeChecker === reportType.AIR_QUALITY_DAILY &&
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t('common.date')} isRequire={false} />
                                        <InputDatePicker
                                            name="date"
                                            value={model.date}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                    {
                                        (dateValidate.isError) &&
                                        <Label basic color='red' pointing prompt>
                                            {dateValidate.content}
                                        </Label>
                                    }
                                </Grid.Row>
                            }
                            {
                                typeChecker !== reportType.AIR_QUALITY_DAILY &&
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t('common.start')} isRequire={false} />
                                        <InputDatePicker
                                            name="startDate"
                                            value={model.startDate}
                                            checkerDate={model.endDate}
                                            disableDateTypeInput={disableDateType.END_DATE_INCLUDE}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <LabelInput text={t('common.end')} isRequire={false} />
                                        <InputDatePicker
                                            name="endDate"
                                            value={model.endDate}
                                            checkerDate={model.startDate}
                                            disableDateTypeInput={disableDateType.START_DATE_INCLUDE}
                                            onChange={this.handleChange}
                                        />
                                        {
                                            (endDateTimeValidate.isError) &&
                                            <Label basic color='red' pointing prompt>
                                                {endDateTimeValidate.content}
                                            </Label>
                                        }
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ReportForm.defaultProps = {
    model: null,
    errors: [],
    handleChange: () => console.error("Please provide \"Change Counter\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ReportForm)