
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { Form } from 'semantic-ui-react'
import MyForm from '../../core/components/MyForm'
import IOTInputForm from './IOTInputForm'
// import ViewButton from './ViewButton'
import ButtonModel from '../../core/model/ButtonModel'

class MiniIOTModal extends MyForm {
    constructor(props) {
        super(props)

        // this.state.openMiniModal = true
        this.selfHandleForm = true
        this.state.miniIotValue = { ...props.value }
    }

    handleChangeMiniIOT = (e, { name, value }) => {
        this.setState({
            miniIotValue: value
        })
    }

    isDisabledSubmit = (iotValue, type) => {
        if (type === "BACNET") {
            if (!!iotValue
                && !!iotValue.status
                && !!iotValue.temp) {
                return false
            }
        } else if (!!iotValue) {
            return false
        }

        return true
    }

    // onCloseMiniModal = () => {
    //     this.setState({
    //         openMiniModal: false
    //     }, this.props.onClose)
    // }

    onSubmitMiniModal = () => {
        // this.setState({
        //     openMiniModal: false
        // })

        this.props.onSubmit(this.state.miniIotValue)
    }

    render() {
        try {
            const { t, type, onClose, iotDescription } = this.props
            const { miniIotValue } = this.state

            if (!type) {
                return <p></p>
            }

            return this.getModalContent(this.getInfoModalOption(
                {
                    open: true,
                    onClose: () => { },
                    headerText: `${t("common.set") + ' ' + iotDescription}`,
                    size: "tiny",
                    content: <div>
                        <Form>
                            <IOTInputForm
                                name={`miniIOTModal`}
                                type={type}
                                value={miniIotValue}
                                onChange={this.handleChangeMiniIOT}
                            />
                        </Form>
                    </div>,
                    buttons: [
                        new ButtonModel({
                            text: t('common.set'),
                            color: "green",
                            disabled: this.isDisabledSubmit(miniIotValue, type),
                            onClick: () => this.onSubmitMiniModal(miniIotValue)
                        }),
                        new ButtonModel({
                            text: t('common.cancel'),
                            color: "grey",
                            onClick: onClose
                        })
                    ]
                }
            ))

            // return (
            //     <React.Fragment>
            //         <Modal
            //             onClose={this.onCloseMiniModal}
            //             open={openMiniModal}
            //             size='small'
            //         >
            //             <h2>{t("common.setIOTConfig")}</h2>
            //             <Modal.Content>
            //                 <Grid centered>
            //                     <Grid.Row>
            //                         <Grid.Column>
            //                             <Form>
            //                                 <IOTInputForm
            //                                     name={`modalStatus`}
            //                                     type={type}
            //                                     value={miniIotValue}
            //                                     onChange={this.handleChangeMiniIOT}
            //                                 />
            //                             </Form>
            //                         </Grid.Column>
            //                     </Grid.Row>
            //                 </Grid>
            //                 <Grid padded='horizontally'>
            //                     <Grid.Row>
            //                         <Grid.Column width={16} textAlign="center">
            //                             <ViewButton
            //                                 model={new ButtonModel({
            //                                     text: t('common.set'),
            //                                     color: "green",
            //                                     disabled: this.isDisabledSubmit(miniIotValue, type),
            //                                     onClick: () => this.onSubmitMiniModal(miniIotValue)
            //                                 })}
            //                             />
            //                             <ViewButton
            //                                 model={new ButtonModel({
            //                                     text: t('common.cancel'),
            //                                     color: "grey",
            //                                     onClick: this.onCloseMiniModal
            //                                 })}
            //                             />
            //                         </Grid.Column>
            //                     </Grid.Row>
            //                 </Grid>
            //             </Modal.Content>
            //         </Modal>
            //     </React.Fragment >
            // )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

MiniIOTModal.defaultProps = {
    name: "",
    type: "",
    value: "",
    iotDescription: "",
    onClose: () => console.error("Please provide onClose function"),
    onSubmit: () => console.error("Please provide onSubmit function")
}

const enhance = compose(
    translate
)

export default enhance(MiniIOTModal)