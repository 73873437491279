
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import EventControlRuleInfoModel from '../../../models/eventControlRuleStep/EventControlRuleInfoModel'
import LabelInput from '../../common/LabelInput'

class EventInformationForm extends MyForm {

    render() {
        try {
            const { t, model, errors } = this.props

            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , descriptionValidate = this.getErrorInput("description", errors)

            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        }
        catch (error) {
            console.log(error)
            return this.renderErrorComponent(error)
        }
    }
}

EventInformationForm.defaultProps = {
    model: new EventControlRuleInfoModel(),
    errors: [],
    optionsSensorRuleType: [],
    sensorList: [],
}

export default (translate(EventInformationForm))