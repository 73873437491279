
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const getSystemIntegrationServiceList = () => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/service`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_SYSTEM_INTEGRATION_SERVICE_LIST_REQUEST,
                {
                    type: type.GET_SYSTEM_INTEGRATION_SERVICE_LIST_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_SYSTEM_INTEGRATION_SERVICE_LIST_FAILURE
            ]
        }
    }
}

const getSystemIntegrationList = (query = "") => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration${query}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_SYSTEM_INTEGRATION_LIST_REQUEST,
                {
                    type: type.GET_SYSTEM_INTEGRATION_LIST_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_SYSTEM_INTEGRATION_LIST_FAILURE
            ]
        }
    }
}

const getSystemIntegrationInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_SYSTEM_INTEGRATION_INFO_REQUEST,
                {
                    type: type.GET_SYSTEM_INTEGRATION_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_SYSTEM_INTEGRATION_INFO_FAILURE
            ]
        }
    }
}

const getO365Info = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_O365_INFO_REQUEST,
                {
                    type: type.GET_O365_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_O365_INFO_FAILURE
            ]
        }
    }
}

const getSendGridInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_SEND_GRID_INFO_REQUEST,
                {
                    type: type.GET_SEND_GRID_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_SEND_GRID_INFO_FAILURE
            ]
        }
    }
}

const getSMTPInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_SMTP_INFO_REQUEST,
                {
                    type: type.GET_SMTP_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_SMTP_INFO_FAILURE
            ]
        }
    }
}

const getGoogleAuthenInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_GOOGLE_AUTHEN_INFO_REQUEST,
                {
                    type: type.GET_GOOGLE_AUTHEN_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_GOOGLE_AUTHEN_INFO_FAILURE
            ]
        }
    }
}

const getGoogleServiceAccountInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_GOOGLE_SERVICE_ACCOUNT_INFO_REQUEST,
                {
                    type: type.GET_GOOGLE_SERVICE_ACCOUNT_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_GOOGLE_SERVICE_ACCOUNT_INFO_FAILURE
            ]
        }
    }
}

const getExchangeInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_EXCHANGE_INFO_REQUEST,
                {
                    type: type.GET_EXCHANGE_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_EXCHANGE_INFO_FAILURE
            ]
        }
    }
}

const getActiveDirectoryInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_ACTIVE_DIRECTORY_INFO_REQUEST,
                {
                    type: type.GET_ACTIVE_DIRECTORY_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_ACTIVE_DIRECTORY_INFO_FAILURE
            ]
        }
    }
}

const getLineInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_LINE_INFO_REQUEST,
                {
                    type: type.GET_LINE_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_LINE_INFO_FAILURE
            ]
        }
    }
}

const getZoomInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_ZOOM_INFO_REQUEST,
                {
                    type: type.GET_ZOOM_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_ZOOM_INFO_FAILURE
            ]
        }
    }
}

const getProfileCenterInfo = (id) => {
    return {
        [CALL_API]: {
            endpoint: `${CORE_API_ENDPOINT}/systemintegration/${id}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            types: [
                type.GET_PROFILE_CENTER_INFO_REQUEST,
                {
                    type: type.GET_PROFILE_CENTER_INFO_SUCCESS,
                    payload(action, state, res) {
                        return res
                            .json()
                            .then(json => {
                                return json
                            })
                    }
                },
                type.GET_PROFILE_CENTER_INFO_FAILURE
            ]
        }
    }
}

export {
    getSystemIntegrationServiceList,
    getSystemIntegrationList,
    getO365Info,
    getGoogleAuthenInfo,
    getGoogleServiceAccountInfo,
    getExchangeInfo,
    getActiveDirectoryInfo,
    getLineInfo,
    getZoomInfo,
    getProfileCenterInfo,
    getSendGridInfo,
    getSMTPInfo,
    getSystemIntegrationInfo
}