
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import ButtonModel from '../../../core/model/ButtonModel'
import LabelInput from '../../common/LabelInput'
import ViewButton from '../../common/ViewButton'
import ViewTable from '../../../core/view/common/table/ViewTable'
import ModalActionResourcesECR from '../ModalActionResourcesECR'
import EventControlRuleActionModel from '../../../models/eventControlRuleStep/EventControlRuleActionModel'

class ActionInformationForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.isOpenModalAction = false
        this.state.modelAction = null
        this.state.actionIndex = null
        this.state.actionStatus = null

    }

    onOpenModelAction = (status, model, index) => {
        this.setState({
            isOpenModalAction: true,
            modelAction: model,
            actionStatus: status,
            actionIndex: index
        })
    }
    onCloseModelAction = () => {
        this.setState({
            isOpenModalAction: false
        })
    }

    onAddAction = (action) => {
        const { model } = this.props
        const { actionStatus, actionIndex } = this.state

        let actions = Array.isArray(model.actions) ? model.actions : []

        if (!!action) {
            if (actionStatus === "CREATE") {
                actions.push(action)
                this.setState({
                    isOpenModalAction: false
                }, () => this.handleChange(null, { name: "actions", value: actions }))
            } else if (actionStatus === "UPDATE") {
                actions[actionIndex] = action
                this.setState({
                    isOpenModalAction: false
                }, () => this.handleChange(null, { name: "actions", value: actions }))
            }

        }
    }

    onRemoveAction = (actions, index) => {
        const { model } = this.props

        let currentActions = model.actions

        if (index > -1) {
            currentActions.splice(index, 1)
        }
        this.handleChange(null, { name: "actions", value: currentActions })
    }

    getColumnConfigAction(actions) {
        const { t } = this.props

        const columns = [
            {
                name: t("eventControlRule.actionType"),
                selector: (row) => row.type ? row.type : '-',
                width: '200px',
            },
            {
                name: t("common.status"),
                selector: (row) => row.status ? row.status : '-',
                width: '150px'
            },
            {
                name: t("eventControlRule.resource"),
                selector: (row) => {
                    let detail = row.datas.map(data => {
                        return <p>• {data.name} ({data.type})</p>
                    })

                    return detail
                },
                maxWidth: '20vw',
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onOpenModelAction("UPDATE", row, i) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            iconClass: "trash",
                            size: "mini",
                            onClick: () => this.onRemoveAction(actions, i)
                        }
                    ]

                    return <div className="box-action-table" >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </div>
                },
                right: true
            },
        ]
        return columns
    }


    render() {
        try {
            const { t, model, optionsActionTypeList, optionsActionStatusList, optionsResources, optionsResourceType, optionsTreeLocation } = this.props
            const { isOpenModalAction, modelAction } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {
                        isOpenModalAction &&
                        <ModalActionResourcesECR
                            model={modelAction}
                            optionsActionTypeList={optionsActionTypeList}
                            optionsActionStatusList={optionsActionStatusList}
                            optionsResources={optionsResources}
                            optionsResourceType={optionsResourceType}
                            optionsTreeLocation={optionsTreeLocation}
                            onGetDataList={this.props.onGetDataList}
                            onSubmit={(actionInfo) => this.onAddAction(actionInfo)}
                            onClose={this.onCloseModelAction}
                        />
                    }
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <LabelInput text={t("eventControlRule.action")} isRequire={false} />
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.add"),
                                            color: "green",
                                            onClick: () => this.onOpenModelAction("CREATE", null, null),
                                            size: "small"
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <ViewTable
                                        columns={this.getColumnConfigAction()}
                                        datas={model.actions}
                                        pagination={false}
                                        isShowPagination={false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ActionInformationForm.defaultProps = {
    model: new EventControlRuleActionModel(),
    optionsActionTypeList: [],
    optionsActionStatusList: [],
    optionsResources: [],
    optionsResourceType: [],
}

export default (translate(ActionInformationForm))