import MyPage from '../../core/components/MyPage'

class MyPageSystemIntegration extends MyPage {
    // constructor(props) {
    //     super(props)
    // }
    handleChangeMultiValue = (e, data) => {
        try {
            let model = this.state.model
                , modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []

            let tempState = {}

            if (!Array.isArray(data) && typeof data === "object") {
                for (const key in data) {
                    if (model && key in model) {
                        model[key] = data[key]
                        tempState.model = model
                    } else if (key in this.state) {
                        tempState[key] = data[key]
                    }
                    if (key === "data") {
                        modelErrors = modelErrors.filter(e => !e.path.includes(`data.`))
                    }
                    let index = modelErrors.findIndex(e => e.path === key)
                    if (index > -1) {
                        modelErrors.splice(index, 1);
                    }
                }
            }
            tempState.modelErrors = modelErrors
            this.setState(tempState)
        } catch (error) {
            console.error("Handle Change Multi Value Error: " + error.message)
        }
    }

    handleChange = (e, { name, value }, masterKey) => {
        try {
            let model = this.state.model
                , modelErrors = Array.isArray(this.state.modelErrors) ? this.state.modelErrors : []

            let tempState = {}

            if (model) {
                if (masterKey) {
                    if (masterKey in model) {
                        let masterData = model[masterKey]
                        if (name in masterData) {
                            masterData[name] = value
                            model[masterKey] = masterData
                            tempState.model = model
                        }
                        let index = modelErrors.findIndex(e => e.path === `${masterKey}.${name}`)
                        if (index > -1) {
                            modelErrors.splice(index, 1);
                            tempState.modelErrors = modelErrors
                        }
                    }
                } else {
                    if (name in model) {
                        model[name] = value
                        tempState.model = model
                    }
                    let index = modelErrors.findIndex(e => e.path === name)
                    if (index > -1) {
                        modelErrors.splice(index, 1);
                        tempState.modelErrors = modelErrors
                    }
                }
            }
            this.setState(tempState)
        } catch (error) {
            console.error("Handle Change Error: " + error.message)
        }
    }

    onValidate = async (callback) => {
        try {
            let model = this.state.model
                , allErrors = []
            if (!await model.validateError()) {
                allErrors = model.getErrors()
            }

            if (!!model.data) {
                let modelData = model.data
                    , modelDataErrors = []
                if (!await modelData.validateError()) {
                    modelDataErrors = modelData.getErrors()
                }

                if (modelDataErrors.length > 0) {
                    modelDataErrors.forEach(error => {
                        allErrors.push({
                            ...error,
                            path: `data.${error.path}`
                        })
                    })
                }
            }
            this.setState({
                modelErrors: allErrors
            }, () => {
                if (allErrors.length === 0 && typeof callback === "function") {
                    callback()
                }
            })
        } catch (error) {
            console.error("On Validate Error: " + error.message)
            return false
        }
    }
}

export default MyPageSystemIntegration