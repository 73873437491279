
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid, Step } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import StepList from '../../core/business/step/StepList'
import SelectServiceStep from '../../business/importExternalUserStep/SelectServiceStep'
import SelectUserStep from '../../business/importExternalUserStep/SelectUserStep'
import SelectInformationStep from '../../business/importExternalUserStep/SelectInformationStep'
import { systemIntegrationService } from '../../../constant'

class ViewImportExternalUser extends MyPage {
    constructor(props) {
        super(props)
        this.stepList = new StepList(this)

        this.state.currentStep = null

        this.state.isLoading = false
        this.state.registerFail = null
        this.state.modalCustom = null
        this.initStep()
    }

    initStep() {
        this.stepList.addStep(new SelectServiceStep(this.stepList))
        this.stepList.addStep(new SelectUserStep(this.stepList))
        this.stepList.addStep(new SelectInformationStep(this.stepList))
        this.state.currentStep = this.stepList.root
    }

    changeCurrentStep(step) {
        this.setState({ currentStep: step })
    }

    submit() {
        try {
            let data = this.stepList.data
            let serviceCode = null
                , serviceId = null
                , departmentId = null
                , resourceGroupIds = []
                , userSelects = []
            if (!!data) {
                const { service } = data
                if (!!service) {
                    serviceId = service.id
                    serviceCode = service.service
                }
                departmentId = data.departmentId
                resourceGroupIds = data.resourceGroupIds
                userSelects = data.userSelects
            }

            if (serviceCode === systemIntegrationService.EXCHANGE) {
                let body = {
                    emilList: userSelects,
                    departmentId: departmentId,
                    resourceGroupIds: resourceGroupIds
                }
                this.onImportExchange(serviceId, body)
            } else if (serviceCode === systemIntegrationService.ACTIVE_DIRECTORY) {
                let body = {
                    sAMAccountNamelist: userSelects,
                    departmentId: departmentId,
                    resourceGroupIds: resourceGroupIds
                }
                this.onImportAD(serviceId, body)
            }
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    onImportExchange = (serviceId, body) => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onImportUserExchange(serviceId, body)
            let tempState = {
                isLoading: false
            },
                action = () => this.afterImportUser(res)
            this.setState(tempState, action)
        })
    }

    onImportAD = (serviceId, body) => {
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.onImportUserAD(serviceId, body)

            let tempState = {
                isLoading: false
            },
                action = () => this.afterImportUser(res)
            this.setState(tempState, action)
        })
    }

    afterImportUser = (res) => {
        // console.log(res)
        const { t } = this.props
        if (!!res && res.result === true) {
            this.setState({
                modalCustom: this.getInfoModalOption({
                    headerText: t("common.system"),
                    content: t("message.importUserSuccess"),
                    buttons: [
                        new ButtonModel({
                            text: t("common.back"),
                            color: "blue",
                            onClick: () => this.props.history.goBack()
                        })
                    ]
                })
            })
        } else {
            this.setState({
                modalCustom: this.getErrorModalOption({
                    headerText: t("common.system"),
                    content: t("message.somethingWrong"),
                    buttons: [
                        new ButtonModel({
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => this.setState({ modalCustom: null })
                        })
                    ]
                })
            })
        }
    }

    back() {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, resourceGroupList, importSystemList } = this.props
            const { currentStep, isLoading, modalCustom } = this.state
            let buttons = []

            let activeKey = null
            if (!!currentStep) {
                activeKey = currentStep.key
            }
            let dataConfig = {
                resourceGroupList, importSystemList,
                optionsImportService: [],
                optionsResourceGroup: [],
            }
            if (Array.isArray(importSystemList)) {
                dataConfig.optionsImportService = importSystemList.map(m => m.serializeOption())
            }
            if (Array.isArray(resourceGroupList)) {
                dataConfig.optionsResourceGroup = resourceGroupList.map(m => m.serializeOption())
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('common.importExternalUser')} buttons={buttons} >
                        <div className="module viewImportExternalUser">
                            <Container text>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Step.Group fluid size={"big"}>
                                                <Step active={activeKey === "SERVICE"}>
                                                    <Step.Content>
                                                        <Step.Title>{t("systemIntegration.service")}</Step.Title>
                                                    </Step.Content>
                                                </Step>

                                                <Step active={activeKey === "USER"} disabled={activeKey === "SERVICE"}>
                                                    <Step.Content>
                                                        <Step.Title>{t("user.title")}</Step.Title>
                                                    </Step.Content>
                                                </Step>

                                                <Step active={activeKey === "INFORMATION"} disabled={activeKey !== "INFORMATION"}>
                                                    <Step.Content>
                                                        <Step.Title>{t("common.information")}</Step.Title>
                                                    </Step.Content>
                                                </Step>
                                            </Step.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {currentStep && currentStep.render(dataConfig)}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewImportExternalUser.defaultProps = {
    resourceGroupList: [],
    importSystemList: [],
    onImportUserExchange: () => {
        console.error("Please provide Import User Exchange action.")
        return false
    },
    onImportUserAD: () => {
        console.error("Please provide Import User AD action.")
        return false
    }
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewImportExternalUser)