
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const getSensorRuleInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_INFO_REQUEST,
				{
					type: type.GET_SENSOR_RULE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_INFO_FAILURE
			]
		}
	}
}

const getSensorRuleList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/type`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_TYPE_LIST_FAILURE
			]
		}
	}
}

const getConditionHolidayTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/condition/holidaytype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_CONDITION_HOLIDAY_TYPE_LIST_REQUEST,
				{
					type: type.GET_CONDITION_HOLIDAY_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_CONDITION_HOLIDAY_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleActionTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/action/type`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_ACTION_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_ACTION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_ACTION_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleActionResultList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/action/result`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_ACTION_RESULT_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_ACTION_RESULT_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_ACTION_RESULT_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleLocationTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/action/control/locationtype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_LOCATION_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_LOCATION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_LOCATION_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleResourceList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/action/control/resource`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_RESOURCE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_RESOURCE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_RESOURCE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleDataTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/action/database/datatype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_DATA_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_DATA_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_DATA_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleRecurrenceTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/recurrencetype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_FAILURE
			]
		}
	}
}

const getSensorRuleDayofWeekList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/sensorrule/dayofweek`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_REQUEST,
				{
					type: type.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_FAILURE
			]
		}
	}
}

export {
	getSensorRuleInfo,
	getSensorRuleList,
	getSensorRuleTypeList,
	getConditionHolidayTypeList,
	getSensorRuleActionTypeList,
	getSensorRuleActionResultList,
	getSensorRuleLocationTypeList,
	getSensorRuleResourceList,
	getSensorRuleDataTypeList,
	getSensorRuleRecurrenceTypeList,
	getSensorRuleDayofWeekList
}