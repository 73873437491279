
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import VisitorModel from '../models/VisitorModel'
import PagingDataModel from '../core/model/PagingDataModel'

export default class VisitorManager extends MyManager {
    async createVisitor(model, callback) {
        try {
            const option = {
                method: this.requestMethod.POST,
                url: `${CORE_API_ENDPOINT}/visitor`,
                data: model
            }
            let res = await this.requestAction(option, callback)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async updateVisitor(code, body, callback) {
        try {
            const option = {
                method: this.requestMethod.PATCH,
                url: `${CORE_API_ENDPOINT}/visitor/${code}`,
                data: body
            }
            let res = await this.requestAction(option, callback)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async getVisitorList(query = "") {
        try {
            const option = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/visitor${!!query ? "?" + query : ""}`,
            }

            let res = await this.requestAction(option)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async getVisitorInfo(code) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/visitor/${code}`,
            }

            let res = await this.requestAction(options)
            return res
        }
        catch (error) {
            this.setDefaultModalError(error)
        }
    }

    async getVisitorListPaging(query) {
        try {
            const options = {
                method: this.requestMethod.GET,
                url: `${CORE_API_ENDPOINT}/visitor/page${!!query ? query : ""}`,
            }

            let res = await this.requestAction(options)

            let data = null
            if (!!res && res.result === true && !!res.data) {
                data = new PagingDataModel({ pagination: res.data.pagination })
                if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
                    data.datas = res.data.datas.map(d => {
                        return new VisitorModel(d)
                    })
                }
            }
            return data
        } catch (error) {
            this.setDefaultModalError(error)
            return null
        }
    }

    async visitorTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/visitor/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}