
import types from '../types'
import { requestStatus } from '../../constant'
import AdminResource from '../selectors/adminResource'
import AdminStatusResource from '../selectors/adminStatusResource'
import IntegrationResource from '../selectors/integrationResource'
import ADIntegrationResource from '../selectors/adIntegrationResource'
import ExchangeIntegrationResource from '../selectors/exchangeIntegrationResource'

const adminReducer = {
	adminInfo: (state = null, action) => {
		if (!state || !(state instanceof AdminResource)) {
			state = new AdminResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_INFO_REQUEST:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_INFO_SUCCESS:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ADMIN_INFO_FAILURE:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	adminList: (state = null, action) => {
		if (!state || !(state instanceof AdminResource)) {
			state = new AdminResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_LIST_REQUEST:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_LIST_SUCCESS:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_ADMIN_LIST:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue([])
				break
			case types.GET_ADMIN_LIST_FAILURE:
				state = new AdminResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	adminStatusList: (state = null, action) => {
		if (!state || !(state instanceof AdminStatusResource)) {
			state = new AdminStatusResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_STATUS_LIST_REQUEST:
				state = new AdminStatusResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_STATUS_LIST_SUCCESS:
				state = new AdminStatusResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ADMIN_STATUS_LIST_FAILURE:
				state = new AdminStatusResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	adminIntegrationList: (state = null, action) => {
		if (!state || !(state instanceof IntegrationResource)) {
			state = new IntegrationResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_INTEGRATION_LIST_REQUEST:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_INTEGRATION_LIST_SUCCESS:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ADMIN_INTEGRATION_LIST_FAILURE:
				state = new IntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	adminADList: (state = null, action) => {
		if (!state || !(state instanceof ADIntegrationResource)) {
			state = new ADIntegrationResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_ACTIVE_DIRECTORY_LIST_REQUEST:
				state = new ADIntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_ACTIVE_DIRECTORY_LIST_SUCCESS:
				state = new ADIntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ADMIN_ACTIVE_DIRECTORY_LIST_FAILURE:
				state = new ADIntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	adminExchangeList: (state = null, action) => {
		if (!state || !(state instanceof ExchangeIntegrationResource)) {
			state = new ExchangeIntegrationResource()
		}

		switch (action.type) {
			case types.GET_ADMIN_EXCHANGE_LIST_REQUEST:
				state = new ExchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_ADMIN_EXCHANGE_LIST_SUCCESS:
				state = new ExchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_ADMIN_EXCHANGE_LIST_FAILURE:
				state = new ExchangeIntegrationResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default adminReducer