
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class SettingSystemResource extends ActionResourceObject {
	setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {

					if (!!d.name) {
						return {
							key: d.id,
							value: d.id,
							text: d.name
						}
					} else {
						return {
							key: d.id,
							value: d.id,
							text: d.service
						}
					}
				})
			} else {
				if(!!data.id && !!data.name) {
					result = {
						key: data.id,
						value: data.id,
						text: data.name
					}
				} else {
					result = {
						key: data.id,
						value: data.id,
						text: data.service
					}
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}