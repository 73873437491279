
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import UserModel from '../models/UserModel'
import PagingDataModel from '../core/model/PagingDataModel'
import ExchangeIntegrationModel from '../models/ExchangeIntegrationModel'
import ADIntegrationModel from '../models/ADIntegrationModel'
import SystemIntegrationModel from '../models/SystemIntegrationModel'

export default class UserManager extends MyManager {

	async createUser(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateUser(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUserStatusList() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/status`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateUserStatus(code, body, callback) {
		// console.log(code, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/changestatus/${code}`,
				data: {
					"status": body
				}
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async resetPasswordUser(code, callback) {
		try {
			const option = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/resetPassword/${code}`,
			}
			let res = await this.requestAction(option, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteUser(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/user/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async unintegrateUser(id, service, systemIntegrationId, callback) {
		try {
			let data = {}
			if (systemIntegrationId) {
				data = {
					"systemId": systemIntegrationId,
					"unintegrationService": service
				}
			} else {
				data = {
					"unintegrationService": service
				}
			}

			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/unintegration/${id}`,
				data: data
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUserPlatform(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/platformdata/list${!!query ? query : ""}`
			}

			let res = await this.requestAction(options)
			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new UserModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importUser(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/import/platformdata`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUserListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new UserModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}


	async userIntegrationAD(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/integration/ad/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async userIntegrationExchange(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/integration/exchange/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async userIntegrationGoogle(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/integration/google/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async userIntegrationO365(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/user/integration/o365/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getUserImportAD(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/ad/user/list/${!!id ? id : ""}`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(d => {
					return new ADIntegrationModel(d)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getUserImportExchange(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/exchange/user/list/${!!id ? id : ""}`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(d => {
					return new ExchangeIntegrationModel(d)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getImportSystemList() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/import/service/list`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(data => {
					return new SystemIntegrationModel(data)
				})
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async importUserExchange(service, body) {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/import/exchange/${service}`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async importUserAD(service, body) {
		try {
			const options = {
				isHandleError: false,
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/import/ad/${service}`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async syncAllUser(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getImportFormatCodeExcel() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/import/excel/formatcode`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getExcelTemplate(code) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/import/excel/template/${!!code ? code : ""}`,
				responseType: 'arraybuffer',
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importExcel(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/user/importexcel`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async userTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/user/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}
