
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import EventControlRuleList from '../../views/eventControlRule/EventControlRuleList'
import EventControlRuleManager from '../../business/EventControlRuleManager'
import ButtonModel from '../../core/model/ButtonModel'

class EventControlRuleController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bEventControlRule = new EventControlRuleManager(this)
	}
	getPropsResetStatus() {
		return ["eventControlRuleList"]
	}
	onDeleteEventControlRule = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bEventControlRule.deleteEventControlRule(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableEventControlRule = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bEventControlRule.enableEventControlRuleStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableEventControlRule = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bEventControlRule.disableEventControlRuleStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getEventControlRuleListPaging = (query) => {
		return this.bEventControlRule.getEventControlRuleListPaging(query)
	}

	render() {
		try {
			// const { t } = this.props
			const { isLoadingComponent } = this.props

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					{!this.requestFinishedAfterRequest() && this.getLoadingPage()}
					<EventControlRuleList
						onDelete={this.onDeleteEventControlRule}
						onChangeEnableEventControlRule={this.onChangeEnableEventControlRule}
						onChangeDisableEventControlRule={this.onChangeDisableEventControlRule}
						onGetDataPaging={this.getEventControlRuleListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({

		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
		componentDidMount() {

		}
	})
)


export default enhance(EventControlRuleController)