
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import RFIDCardModel from '../models/RFIDCardModel'
import PagingDataModel from '../core/model/PagingDataModel'

export default class RFIDCardManager extends MyManager {

	async createRFIDCard(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/rfidcard`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateRFIDCard(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/rfidcard/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteRFIDCard(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/rfidcard/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getRFIDCardListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rfidcard/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new RFIDCardModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async getImportFormatCodeExcel() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rfidcard/import/excel/formatcode`,
			}

			let res = await this.requestAction(options)

			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async getExcelTemplate(code) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rfidcard/import/excel/template/${!!code ? code : ""}`,
				responseType: 'arraybuffer',
			}
			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importExcel(body) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/rfidcard/importexcel`,
				data: body
			}

			let res = await this.requestAction(options)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
			return false
		}
	}

	async syncRFIDCard(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/rfidcard/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async rfidCardTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/rfidcard/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}