
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'
import moment from 'moment'
import { dateTimeFormat } from '../../constant'

const Schema = mongoose.Schema

const RecurrenceSchema = new Schema({
	type: { type: String },
	startDate: { type: Date },
	weeklyEveryDayOfWeek: [{ type: String }]
}, { _id: false })

const ResourcesSchema = new Schema({
	type: { type: String },
	id: { type: String },
	code: { type: String },
	name: { type: String },
	actionMode: { type: String },
	sceneId: { type: String }
}, { _id: false })

const LocationsSchema = new Schema({
	type: { type: String },
	id: { type: String },
	code: { type: String },
	name: { type: String },
	actionMode: { type: String },
	sceneId: { type: String }
}, { _id: false })

const ScheduleControlSchema = new Schema({
	id: { type: String, default: null },
	code: { type: String, required: [true, "validate.require"], default: "" },
	name: { type: String, required: [true, "validate.require"], default: "" },
	description: { type: String, default: "" },
	actionTime: { type: Date, required: [true, "validate.require"], default: moment() },
	recurrence: { type: RecurrenceSchema, default: {} },
	resources: [{ type: ResourcesSchema }],
	locations: [{ type: LocationsSchema }],
	holidayType: { type: String, required: [true, "validate.require"], default: "" },
	isDisable: { type: Boolean, default: false },
	scheduleJobStatus: { type: String, default: "" }
}, { _id: false })

setBaseSchema(ScheduleControlSchema, "06")

RecurrenceSchema.path("type").validate(async function (type) {
	return !!type
}, "validate.require")

ScheduleControlSchema.path("resources").validate(async function (resources) {
	return (Array.isArray(resources) && resources.length > 0) || (Array.isArray(this.locations) && this.locations.length > 0)
}, "validate.scheduleControlIOT")

ScheduleControlSchema.path("locations").validate(async function (locations) {
	return (Array.isArray(locations) && locations.length > 0) || (Array.isArray(this.resources) && this.resources.length > 0)
}, "validate.scheduleControlIOT")

ScheduleControlSchema.methods.serializeAPI = function () {
	try {
		let result = {
			code: this.code,
			name: this.name,
			description: this.description,
			holidayType: this.holidayType,
			recurrence: this.recurrence
		}

		let recurrence = this.recurrence
		if (recurrence.type === "ONETIME") {
			recurrence.startDate = moment(recurrence.startDate).format(dateTimeFormat.apiFullDate)
		}

		let actionTime = this.actionTime ? moment(this.actionTime).format(dateTimeFormat.apiFullTime) : null

		result.actionTime = actionTime

		let resources = []
		if (Array.isArray(this.resources)) {
			this.resources.forEach(resource => {
				let sceneId = null

				if (resource.sceneId !== "NONE_SELECT" && resource.sceneId !== "") {
					sceneId = resource.sceneId
				}

				resources.push({
					type: resource.type,
					id: resource.id,
					code: resource.code,
					name: resource.name,
					actionMode: resource.actionMode,
					sceneId: sceneId
				})
			})
		}

		result.resources = resources

		let locations = []
		if (Array.isArray(this.locations)) {
			this.locations.forEach(location => {
				let sceneId = null

				if (location.sceneId !== "NONE_SELECT" && location.sceneId !== "") {
					sceneId = location.sceneId
				}
				locations.push({
					type: location.type,
					id: location.id,
					code: location.code,
					name: location.name,
					actionMode: location.actionMode,
					sceneId: sceneId
				})
			})
		}

		result.locations = locations

		return result

	} catch (error) {
		console.log(error)
		return null
	}
}

ScheduleControlSchema.methods.setData = function (data) {
	try {
		if (!!data) {
			this.id = data.id
			this.code = data.code
			this.name = data.name
			this.description = data.description
			this.actionTime = moment(data.actionTime, "HH:mm")
			this.recurrence = {
				type: data.recurrence.type,
				startDate: data.recurrence.startDate,
				weeklyEveryDayOfWeek: data.recurrence.weeklyEveryDayOfWeek
			}
			this.holidayType = data.holidayType
			this.isDisable = data.isDisable
			this.scheduleJobStatus = data.scheduleJobStatus

			if (Array.isArray(data.resources) && data.resources.length > 0) {
				this.resources = data.resources.map(re => {
					return {
						type: re.type,
						id: re.id,
						code: re.code,
						name: re.name,
						actionMode: re.actionMode,
						sceneId: re.sceneId
					}
				})
			} else {
				this.resources = []
			}

			if (Array.isArray(data.locations) && data.locations.length > 0) {
				this.locations = data.locations.map(lo => {
					return {
						type: lo.type,
						id: lo.id,
						code: lo.code,
						name: lo.name,
						actionMode: lo.actionMode,
						sceneId: lo.sceneId
					}
				})
			} else {
				this.locations = []
			}
		}
	} catch (error) {

	}
}

const ScheduleControlModel = mongoose.model("schedulecontrol", ScheduleControlSchema, "schedulecontrol")

export default ScheduleControlModel