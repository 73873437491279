
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import MyController from '../../core/components/MyController'
// import { translate } from 'react-switch-lang'
import CreateReportAirQualityMonthly from '../../views/report/CreateReportAirQualityMonthly'
import ReportManager from '../../business/ReportManager'
import utils from '../../core/utility/utils'
import { downloadFileType } from '../../../constant'

class ReportAirQualityMonthlyController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoading = false
        this.state.resources = []
        this.bReport = new ReportManager(this)
    }

    onExportData = async (model) => {
        this.setState({
            isLoading: true
        }, async () => {
            let data = await this.bReport.exportAQMonthlyReportPDF(model.serializeAPI())
                , fileName = `air_quality_monthly_report.${downloadFileType.PDF.filenameExtension}`
            let tempState = {
                isLoading: false
            },
                action = () => { }
            if (data && fileName) {
                action = () => utils.downloadFile(data, downloadFileType.PDF.type, fileName)
            }
            this.setState(tempState, action)
        })
    }

    render() {
        const { isLoading } = this.state
        return (
            <React.Fragment>
                {super.render()}
                {(isLoading) && this.getLoadingPage()}
                <CreateReportAirQualityMonthly
                    onCreate={this.onExportData}
                />
            </React.Fragment>
        )
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {

        }
    })
)


export default enhance(ReportAirQualityMonthlyController)