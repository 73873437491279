
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getHolidayInfo } from '../../actions/holidayActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import HolidayManager from '../../business/HolidayManager'
import HolidayView from '../../views/holiday/HolidayView'

class ViewHolidayController extends MyController {
	constructor(props) {
		super(props)

		this.bHoliday = new HolidayManager(this)
	}

	getPropsResetStatus() {
		return ["holidayInfo"]
	}

	render() {
		try {
			const { holidayInfo } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const holiday = holidayInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<div className="body">
						<HolidayView
							model={holiday}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			holidayInfo: state.holidayInfo
		}),
		(dispatch, props) => ({
			getHolidayInfo() {
				dispatch(getHolidayInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getHolidayInfo()
		}
	}),
)


export default enhance(ViewHolidayController) 