
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

const Schema = mongoose.Schema

const RGBStatusSchema = new Schema({
	r: { type: Number, min: 0, max: 255, required: [true, "validate.require"] },
	g: { type: Number, min: 0, max: 255, required: [true, "validate.require"] },
	b: { type: Number, min: 0, max: 255, required: [true, "validate.require"] },
}, { _id: false })

const AlertDashboardDataSchema = new Schema({
	color: { type: RGBStatusSchema, required: [true, "validate.require"] },
	detail: { type: String },
}, { _id: false })

setBaseSchema(AlertDashboardDataSchema)

AlertDashboardDataSchema.methods.serializeAPI = function () {
	try {
		if (!this.color) {
			return null
		}
		let result = {
			color: {
				r: parseInt(this.color.r, 10),
				g: parseInt(this.color.g, 10),
				b: parseInt(this.color.b, 10),
			}
		}
		return result
	} catch (error) {
		return null
	}
}

const AlertDashboardDataModel = mongoose.model("sensor_rule_action_alert_dashboard", AlertDashboardDataSchema, "sensor_rule_action_alert_dashboard")

export default AlertDashboardDataModel
