
import { CALL_API } from '../middlewares'
import { CORE_SERVICE_ENDPOINT } from '../lib'
import type from '../types'

const resetEventControlRuleList = () => {
	return {
		type: type.RESET_EVENT_CONTROL_RULE_LIST
	}
}

const getEventControlRuleInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_EVENT_CONTROL_RULE_INFO_REQUEST,
				{
					type: type.GET_EVENT_CONTROL_RULE_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_EVENT_CONTROL_RULE_INFO_FAILURE
			]
		}
	}
}

const getEventControlRuleList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_EVENT_CONTROL_RULE_LIST_REQUEST,
				{
					type: type.GET_EVENT_CONTROL_RULE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_EVENT_CONTROL_RULE_LIST_FAILURE
			]
		}
	}
}

const getECRHolidayTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/condition/holidaytype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_HOLIDAY_TYPE_LIST_REQUEST,
				{
					type: type.GET_ECR_HOLIDAY_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_HOLIDAY_TYPE_LIST_FAILURE
			]
		}
	}
}

const getECRActionTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/action/typelist`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_ACTION_TYPE_LIST_REQUEST,
				{
					type: type.GET_ECR_ACTION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_ACTION_TYPE_LIST_FAILURE
			]
		}
	}
}

const getECRActionStatusList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/action/statuslist`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_ACTION_STATUS_LIST_REQUEST,
				{
					type: type.GET_ECR_ACTION_STATUS_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_ACTION_STATUS_LIST_FAILURE
			]
		}
	}
}

const getECRRecurrenceTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/condition/recurrencetype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_RECURRENCE_TYPE_LIST_REQUEST,
				{
					type: type.GET_ECR_RECURRENCE_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_RECURRENCE_TYPE_LIST_FAILURE
			]
		}
	}
}

const getECRDayOfWeekList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/condition/dayofweek`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_DAY_OF_WEEK_LIST_REQUEST,
				{
					type: type.GET_ECR_DAY_OF_WEEK_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_DAY_OF_WEEK_LIST_FAILURE
			]
		}
	}
}

const getECREventList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_SERVICE_ENDPOINT}/eventcontrol/eventcontrolrule/eventlist`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ECR_EVENT_LIST_REQUEST,
				{
					type: type.GET_ECR_EVENT_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ECR_EVENT_LIST_FAILURE
			]
		}
	}
}


export {
	getEventControlRuleInfo,
	getEventControlRuleList,
	getECRHolidayTypeList,
	getECRActionTypeList,
	getECRActionStatusList,
	getECRRecurrenceTypeList,
	getECRDayOfWeekList,
	getECREventList,

	resetEventControlRuleList,
}