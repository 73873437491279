
import React from 'react'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewCreateDepartment from '../../views/department/ViewCreateDepartment'
import DepartmentManager from '../../business/DepartmentManager'
import DepartmentModel from '../../models/DepartmentModel'

class CreateDepartmentController extends MyController {

    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bDepartment = new DepartmentManager(this)
    }

    onCreateDepartment = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bDepartment.createDepartment(model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    let department = new DepartmentModel(res.data)
                    action = () => { this.props.history.replace(`/department/view/${department.id}`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { isLoadingComponent } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewCreateDepartment
                        isLoadingControl={isLoadingComponent}
                        onCreate={this.onCreateDepartment}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    /*     connect(
            (state, props) => ({
         
            }),
            (dispatch, props) => ({
        
            }),
        ),
        lifecycle({
            componentDidMount() {
        
            }
        }) */
)


export default enhance(CreateDepartmentController)