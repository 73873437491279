
import React from 'react'
import { translate } from 'react-switch-lang'

import { Segment, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import BoxModalHeaderWithContent from '../common/BoxModalHeaderWithContent'
import ButtonModel from '../../core/model/ButtonModel'

class ModalSyncNotification extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.textSearch = ""
        this.state.selectDataIds = []

        this.state.notificationPlatform = []
    }

    componentDidMount() {
        const { notificationPlatform } = this.props
        this.setState({
            notificationPlatform: notificationPlatform
        })
    }

    handleChangeSearch = (e, { name, value }) => {
        const { notificationPlatform } = this.props
        let filterDatas = []
        if (!!value) {
            filterDatas = notificationPlatform.filter(data => {
                let name = data.name.toLowerCase()
                    , tempValue = value.toLowerCase()
                return name.includes(tempValue)
            })
        } else {
            filterDatas = notificationPlatform
        }
        this.setState({
            [name]: value,
            notificationPlatform: filterDatas
        })
    }

    handleChangeDataSync = (e, { name, value, checked }) => {
        let selectDataIds = this.state.selectDataIds.map(id => id)

        if (checked === true) {
            selectDataIds.push(value)
        } else if (checked === false) {
            let index = selectDataIds.findIndex(id => id === value)
            if (index > -1) {
                selectDataIds.splice(index, 1)
            }
        }

        this.setState({
            selectDataIds
        })
    }

    render() {
        try {
            const { textSearch, notificationPlatform, selectDataIds } = this.state
            const { t, onClose, onSubmit } = this.props
            return this.getModalContent(this.getModalOption({
                headerText: t("notification.title"),
                onClose: () => { },
                content: <Form>
                    <BoxModalHeaderWithContent
                        header={<div>
                            <Form.Input fluid name="textSearch" value={textSearch} icon='search' placeholder={t("common.search") + "..."}
                                onChange={this.handleChangeSearch} />
                        </div>}
                        content={
                            <React.Fragment>
                                {
                                    Array.isArray(notificationPlatform) &&
                                    notificationPlatform.map((d, i) => {
                                        let isChecked = selectDataIds.findIndex(id => id === d.id) > -1
                                        return <Segment vertical key={i}>
                                            <Form.Checkbox name="selectDataIds" value={d.id} onChange={this.handleChangeDataSync} label={d.name} checked={isChecked} />
                                        </Segment>
                                    })

                                }
                            </React.Fragment>
                        }
                    />
                </Form>,
                buttons: [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: () => onSubmit(selectDataIds),
                            disabled: selectDataIds.length === 0
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalSyncNotification.defaultProps = {
    notificationPlatform: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalSyncNotification))