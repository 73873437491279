
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSensorRuleInfo } from '../../actions/sensorRuleActions'
import SystemIntegrationManager from '../../business/SystemIntegrationManager'
import utils from '../../core/utility/utils'
import SensorRuleView from '../../views/sensorRule/SensorRuleView'

class ViewSensorRuleController extends MyController {
	constructor(props) {
		super(props)

		this.bSystemIntegration = new SystemIntegrationManager(this)
	}

	getPropsResetStatus() {
		return ["sensorRuleInfo"]
	}

	render() {
		try {
			const { sensorRuleInfo } = this.props
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const sensorRule = sensorRuleInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					<SensorRuleView
						model={sensorRule}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			sensorRuleInfo: state.sensorRuleInfo
		}),
		(dispatch, props) => ({
			getSensorRuleInfo() {
				dispatch(getSensorRuleInfo(utils.getParamFromProps(props, "id")))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSensorRuleInfo()
		}
	}),
)


export default enhance(ViewSensorRuleController)