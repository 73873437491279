import moment from 'moment'
import React from 'react'
import { cookiesKey } from '../../constant'
import CookiesManager from '../core/business/CookiesManager'
import utils from '../core/utility/utils'

class RoutePermission extends React.Component {
    constructor(props) {
        super(props)
        // const { location } = props
        this.bCookie = new CookiesManager()
        let path = this.getPathCurrent()
            , bypass = utils.getQueryFromProps(props, "bypass")

        if (this.isAuthenticated() === false && path !== "/" && path !== "/login") {
            this.bCookie.setData(cookiesKey.TARGET_URL, path)
        }
        if (!!bypass) {
            this.bCookie.setData(cookiesKey.BYPASS_TOKEN, bypass)
            window.location.replace(`/bypass`)
        }
    }
    isAuthenticated = () => {
        const token = this.bCookie.getData(cookiesKey.REFRESH_TOKEN)
            , tokenExpired = this.bCookie.getData(cookiesKey.REFRESH_TOKEN_EXPIRES_DATE)

        return !!token && moment().isBefore(moment(tokenExpired))
    }
    getPathCurrent = () => {
        try {
            const { location } = this.props
            let pathCurrent = "/"
            if (!!location && !!location.pathname && typeof location.pathname === 'string') {
                pathCurrent = location.pathname
            }
            return pathCurrent
        } catch (error) {
            return "/"
        }
    }
    getComponentRoute = () => {
        try {
            const { component, path } = this.props

            let obj = {
                Component: null,
                path: path,
                props: null
            }

            let propsRouter = {}
            if (!!component) {
                obj.Component = component
            }
            if ("exact" in this.props) {
                propsRouter.exact = true
            }
            obj.props = propsRouter
            return obj
        } catch (error) {
            return {
                Component: null,
                path: ",",
                props: null
            }
        }
    }
}

RoutePermission.defaultProps = {
    component: null
}

export default RoutePermission