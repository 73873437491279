import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Table, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ButtonModel from '../../../core/model/ButtonModel'
import EventControlRuleInfoModel from '../../../models/eventControlRuleStep/EventControlRuleInfoModel'
import ViewButton from '../../common/ViewButton'
import LabelInput from '../../common/LabelInput'
import { eventControlRuleStep } from '../../../../constant'

class EventInformationView extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = new EventControlRuleInfoModel(props.dataStep)

        this.state.modelErrors = []
        this.state.isLoading = false
    }

    onSubmit = () => {
        this.onValidate(this.onNext)
        //this.onNext()
    }

    onBack = () => {
        this.props.onBack(this.state.model)
    }

    onNext = () => {
        this.props.onNext(this.state.model)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    onCreate = () => {
        this.props.history.push(`/eventcontrolrule/create`)
    }

    onEdit = () => {
        const { dataConfig } = this.props
        this.props.history.push(`/eventcontrolrule/update/${dataConfig.editID}?step=${eventControlRuleStep.EVENT_INFORMATION}`)
    }

    render() {
        try {
            const { t } = this.props
            const { model, isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("common.code")} /></Table.Cell>
                                <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("common.name")} /></Table.Cell>
                                <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t("common.description")} /></Table.Cell>
                                <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.next"),
                                    color: "teal",
                                    // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                    onClick: this.onSubmit
                                })}
                            />
                            {/*       <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            /> */}
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.create'),
                                    color: "teal",
                                    onClick: () => this.onCreate()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.edit'),
                                    color: "green",
                                    onClick: () => this.onEdit()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/eventcontrolrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

EventInformationView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(EventInformationView)