
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getDepartmentInfo, getDepartmentList } from '../../actions/departmentActions'
import { getUserList } from '../../actions/userActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import DepartmentManager from '../../business/DepartmentManager'
import ViewDepartmentInfo from '../../views/department/ViewDepartmentInfo'

class ViewDepartmentController extends MyController {
	constructor(props) {
		super(props)
		this.department = null
		this.bDepartment = new DepartmentManager(this)
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		let tempState = {}
		if (nextProps.department.requestFinished()) {
			tempState.department = nextProps.department.getData()
		}
		return tempState
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.department && !!this.state.department) {
			this.onSearch(this.state.department)
		}
	}

	onSearch = (model) => {
		if (!!model) {
			let querys = [
				{
					name: "parentId",
					value: model.id,
				}
			]
			this.props.getDepartmentList(utils.generateSearchQuery(querys))
		}
	}

	getPropsResetStatus() {
		return ["department", "userList"]
	}

	getPropsChangeStatusSuccess() {
		return ["departmentList"]
	}

	testSyncPlatformDepartment = (id, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bDepartment.departmentTestSyncPlatform(id, (res) => {
                let tempState = {
                    isLoadingComponent: false,
                }

                let action = null

                if (typeof callBack === "function") {
                    action = callBack(res)
                }

                this.setState(tempState, action)
            })
        })
    }

	render() {
		try {
			const { department, departmentList, userList } = this.props

			const departmentData = department.getData()
				, departmentDataList = departmentList.getDatas()
				, userDatas = userList.getDatas()

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					{/* {(!this.requestFinishedAfterRequest() && this.getLoadingPage())} */}
					<div className="body">
						<ViewDepartmentInfo
							model={departmentData}
							// onReloadData={this.props.getDepartmentList}
							departmentList={departmentDataList}
							userList={userDatas}
							testSyncPlatformDepartment={this.testSyncPlatformDepartment}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			department: state.department,
			departmentList: state.departmentList,
			userList: state.userList
		}),
		(dispatch, props) => ({
			getDepartmentInfo() {
				dispatch(getDepartmentInfo(utils.getParamFromProps(props, "id")))
			},
			getDepartmentList(query) {
				dispatch(getDepartmentList(query))
			},
			getUserList() {
				dispatch(getUserList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getDepartmentInfo()
			this.props.getUserList()
		}
	}),
)


export default enhance(ViewDepartmentController)