
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import LabelInput from '../common/LabelInput'

class RoleForm extends MyForm {

    render() {
        try {
            const { t, model, errors } = this.props

            let codeValidate = this.getErrorInput("code", errors)
                , nameValidate = this.getErrorInput("name", errors)
                , descriptionValidate = this.getErrorInput("description", errors)
                
            return (
                <React.Fragment>
                    {super.render()}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.code')} isRequire={true} />
                                    <Form.Input
                                        name="code"
                                        value={model.code}
                                        onChange={this.handleChange}
                                        error={codeValidate.isError ? codeValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.name')} isRequire={true} />
                                    <Form.Input
                                        name="name"
                                        value={model.name}
                                        onChange={this.handleChange}
                                        error={nameValidate.isError ? nameValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.description')} />
                                    <Form.TextArea
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        error={descriptionValidate.isError ? descriptionValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

RoleForm.defaultProps = {
    model: null,
    errors: [],
    handleChange: () => console.error("Please provide \"Change Resource Group\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(RoleForm)