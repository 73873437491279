
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import RFIDCardModel from '../../models/RFIDCardModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchRFIDCard extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new RFIDCardModel({
            type: defaultValue.SELECT_ALL
        })
    }

    onClickClear = () => {
        let model = new RFIDCardModel({ cardNo: "", type: defaultValue.SELECT_ALL })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionsRFIDCardTypeList } = this.props

            return (
                <div className="module formSearchRFIDCard">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("rfidCard.cardNo")} isRequire={false} />
                                    <Form.Input
                                        name="cardNo"
                                        value={model.cardNo}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.type")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsRFIDCardTypeList}
                                        onChange={this.handleChange}
                                        name="type"
                                        value={model.type}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchRFIDCard.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new RFIDCardModel(),
    optionsRFIDCardTypeList: []
}

export default (translate(FormSearchRFIDCard))