
import React from 'react'
import { translate } from 'react-switch-lang'
import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'
import { defaultValue } from '../../../constant'
import SceneManager from '../../business/SceneManager'

const optionsOnOff = [
    {
        key: "ON",
        value: "ON",
        text: "ON"
    },
    {
        key: "OFF",
        value: "OFF",
        text: "OFF"
    }
]

class ModalLocationScene extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.bScene = new SceneManager(this)

        this.state.modalStatus = "CREATE"

        if (!!props.sceneModel) {
            this.state.location = { ...props.sceneModel }
            this.state.modalStatus = "UPDATE"
        } else {
            this.state.location = {
                type: props.type,
                id: "",
                code: "",
                name: "",
                actionMode: "",
                sceneId: ""
            }
        }


        this.state.sceneOptions = []
    }

    handleChange = async (e, { name, value, checked }) => {
        const { optionsLocationList, optionsResourceList, type } = this.props

        let location = this.state.location

        if (name === "type") {
            location.type = value
        } if (name === "location") {
            location.id = value
            location.sceneId = ""

            let locationFind = optionsLocationList.find(location => location.value === value)
            let resourceFind = optionsResourceList.find(resource => resource.value === value)

            if (locationFind) {
                location.code = locationFind.key
                location.name = locationFind.text
            }

            if (resourceFind) {
                location.code = resourceFind.key
                location.name = resourceFind.text
            }

            let sceneOptionsTemp = []

            if (type === "LOCATION") {
                let sceneOptionsResultLocation = await this.bScene.getLocationSceneInfo(value)

                if (!!sceneOptionsResultLocation && sceneOptionsResultLocation.result === true && Array.isArray(sceneOptionsResultLocation.datas)) {
                    sceneOptionsTemp = sceneOptionsResultLocation.datas.map(scene => {
                        return {
                            key: scene.id,
                            value: scene.id,
                            text: scene.name
                        }
                    })
                }
            } else if (type === "RESOURCE") {
                if (resourceFind.type === "DESK") {
                    let sceneOptionsResultDesk = await this.bScene.getDeskSceneInfo(value)

                    if (!!sceneOptionsResultDesk && sceneOptionsResultDesk.result === true && Array.isArray(sceneOptionsResultDesk.datas)) {
                        sceneOptionsTemp = sceneOptionsResultDesk.datas.map(scene => {
                            return {
                                key: scene.id,
                                value: scene.id,
                                text: scene.name
                            }
                        })
                    }
                } else if (resourceFind.type === "ROOM") {
                    let sceneOptionsResultRoom = await this.bScene.getRoomSceneInfo(value)

                    if (!!sceneOptionsResultRoom && sceneOptionsResultRoom.result === true && Array.isArray(sceneOptionsResultRoom.datas)) {
                        sceneOptionsTemp = sceneOptionsResultRoom.datas.map(scene => {
                            return {
                                key: scene.id,
                                value: scene.id,
                                text: scene.name
                            }
                        })
                    }
                }
            }

            this.setState({
                location: location,
                sceneOptions: sceneOptionsTemp
            })

        } else if (name === "action") {
            if (value === "OFF") {
                location.sceneId = ""
            }

            location.actionMode = value
        } else if (name === "scene") {
            location.sceneId = value
        } else {
            location[name] = value
        }

        this.setState({
            location: location
        })
    }

    onSubmit = () => {
        const { sceneStatus, sceneIndex } = this.props
        const { location } = this.state

        this.props.onSubmit(sceneStatus, location, sceneIndex)
    }

    isDisabledSubmit = (location) => {
        if (location.id !== "" && location.actionMode !== "") {
            if (location.actionMode === "ON" && location.sceneId !== "") {
                return false
            }

            if (location.actionMode === "OFF") {
                return false
            }
        }

        return true

    }

    render() {
        try {
            const { location, sceneOptions, modalStatus } = this.state
            const { t, optionsLocationList, optionsResourceList } = this.props

            let optionsSceneList = [{
                key: defaultValue.NONE_SELECT,
                value: defaultValue.NONE_SELECT,
                text: t("common.noneSelect")
            }]

            optionsSceneList = optionsSceneList.concat(sceneOptions)

            let buttonModal = null
            let wording = ""

            if(modalStatus === "CREATE") {
                if(!!location && !!location.type && location.type === "LOCATION") {
                    wording = t("common.addLocation")
                } else if(!!location && !!location.type && location.type === "RESOURCE") {
                    wording = t("common.addResource")
                }
                
                buttonModal = [
                    new ButtonModel(
                        {
                            text: t("common.ok"),
                            color: "green",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(location)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => this.props.onClose()
                        }
                    )
                ]
            } else if(modalStatus === "UPDATE") {
                if(!!location && !!location.type && location.type === "LOCATION") {
                    wording = t("common.editLocation")
                } else if(!!location && !!location.type && location.type === "RESOURCE") {
                    wording = t("common.editResource")
                }

                
                buttonModal = [
                    new ButtonModel(
                        {
                            text: t("common.edit"),
                            color: "blue",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(location)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: () => this.props.onClose()
                        }
                    )
                ]
            }

            return this.getModalContent(this.getModalOption({
                headerText: wording,
                onClose: () => { },
                content: <div className="module scheduleControlScene">
                    <Form>
                        <Grid>
                            {/*                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('common.type')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="type"
                                        value={location.type}
                                        options={optionsLocationTypeList}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row> */}
                            {
                                !!location && !!location.type && location.type === "LOCATION" &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('common.location')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="location"
                                            value={location.id}
                                            options={optionsLocationList}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            {
                                !!location && !!location.type && location.type === "RESOURCE" &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('common.resource')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="location"
                                            value={location.id}
                                            options={optionsResourceList}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('scheduleControl.action')} isRequire={true} />
                                    <Form.Dropdown
                                        selection
                                        selectOnBlur={false}
                                        placeholder={t("common.pleaseSelect")}
                                        name="action"
                                        value={location.actionMode}
                                        options={optionsOnOff}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            {
                                (!!location && !!location.id && !!location.actionMode && location.actionMode === "ON") &&
                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        <LabelInput text={t('scheduleControl.scene')} isRequire={true} />
                                        <Form.Dropdown
                                            selection
                                            selectOnBlur={false}
                                            placeholder={t("common.pleaseSelect")}
                                            name="scene"
                                            value={location.sceneId}
                                            options={optionsSceneList}
                                            onChange={this.handleChange}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            }
                        </Grid>
                    </Form>
                </div>,
                buttons: buttonModal
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalLocationScene.defaultProps = {
    sceneModel: null,
    sceneStatus: "CREATE",
    sceneIndex: null,
    type: "",
    optionsLocationList: [],
    optionsResourceList: [],
    handleChange: () => console.error("Please provide handleChange action"),
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

export default (translate(ModalLocationScene))
