
import types from '../types'
import { requestStatus } from '../../constant'
import SensorRuleResource from '../selectors/sensorRuleResource'
import ConditionHolidayTypeResource from '../selectors/conditionHolidayTypeResource'
import SensorRuleTypeResource from '../selectors/sensorRuleTypeResource'
import SensorRuleActionTypeResource from '../selectors/sensorRuleActionTypeResource'
import SensorRuleActionResultResource from '../selectors/sensorRuleActionResultResource'
import SensorRuleLocationTypeResource from '../selectors/sensorRuleLocationTypeResource'
import ResourceResource from '../selectors/resourceResource'
import SensorRuleDataTypeResource from '../selectors/sensorRuleDataTypeResource'

const sensorRuleReducer = {
    sensorRuleInfo: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleResource)) {
            state = new SensorRuleResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_INFO_REQUEST:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_INFO_SUCCESS:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_INFO_FAILURE:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleResource)) {
            state = new SensorRuleResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_LIST_REQUEST:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_LIST_SUCCESS:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_LIST_FAILURE:
                state = new SensorRuleResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    conditionHolidayTypeList: (state = null, action) => {
        if (!state || !(state instanceof ConditionHolidayTypeResource)) {
            state = new ConditionHolidayTypeResource()
        }

        switch (action.type) {
            case types.GET_CONDITION_HOLIDAY_TYPE_LIST_REQUEST:
                state = new ConditionHolidayTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_CONDITION_HOLIDAY_TYPE_LIST_SUCCESS:
                state = new ConditionHolidayTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_CONDITION_HOLIDAY_TYPE_LIST_FAILURE:
                state = new ConditionHolidayTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleTypeList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleTypeResource)) {
            state = new SensorRuleTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_TYPE_LIST_REQUEST:
                state = new SensorRuleTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_TYPE_LIST_SUCCESS:
                state = new SensorRuleTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_TYPE_LIST_FAILURE:
                state = new SensorRuleTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleActionTypeList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleActionTypeResource)) {
            state = new SensorRuleActionTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_ACTION_TYPE_LIST_REQUEST:
                state = new SensorRuleActionTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_ACTION_TYPE_LIST_SUCCESS:
                state = new SensorRuleActionTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_ACTION_TYPE_LIST_FAILURE:
                state = new SensorRuleActionTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleActionResultList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleActionResultResource)) {
            state = new SensorRuleActionResultResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_ACTION_RESULT_LIST_REQUEST:
                state = new SensorRuleActionResultResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_ACTION_RESULT_LIST_SUCCESS:
                state = new SensorRuleActionResultResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_ACTION_RESULT_LIST_FAILURE:
                state = new SensorRuleActionResultResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleLocationTypeList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleLocationTypeResource)) {
            state = new SensorRuleLocationTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_LOCATION_TYPE_LIST_REQUEST:
                state = new SensorRuleLocationTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_LOCATION_TYPE_LIST_SUCCESS:
                state = new SensorRuleLocationTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_LOCATION_TYPE_LIST_FAILURE:
                state = new SensorRuleLocationTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleResourceList: (state = null, action) => {
        if (!state || !(state instanceof ResourceResource)) {
            state = new ResourceResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_RESOURCE_LIST_REQUEST:
                state = new ResourceResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_RESOURCE_LIST_SUCCESS:
                state = new ResourceResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_RESOURCE_LIST_FAILURE:
                state = new ResourceResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleDataTypeList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleDataTypeResource)) {
            state = new SensorRuleDataTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_DATA_TYPE_LIST_REQUEST:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_DATA_TYPE_LIST_SUCCESS:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_DATA_TYPE_LIST_FAILURE:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleRecurrenceTypeList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleDataTypeResource)) {
            state = new SensorRuleDataTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_REQUEST:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_SUCCESS:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_RECURRENCE_TYPE_LIST_FAILURE:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
    sensorRuleDayofWeekList: (state = null, action) => {
        if (!state || !(state instanceof SensorRuleDataTypeResource)) {
            state = new SensorRuleDataTypeResource()
        }

        switch (action.type) {
            case types.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_REQUEST:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST)
                state.setValue(null)
                break
            case types.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_SUCCESS:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_SUCCESS)
                state.setValue(action.payload)
                break
            case types.GET_SENSOR_RULE_DAY_OF_WEEK_LIST_FAILURE:
                state = new SensorRuleDataTypeResource()
                state.setStatus(requestStatus.REQUEST_FAIL)
                // state.setValue(action.payload)
                break
            default:
                break
        }

        return state
    },
}

export default sensorRuleReducer