
import mongoose from 'mongoose'
import { setBaseSchema } from '../core/schema/Schema'

const Schema = mongoose.Schema

const systemIntegrationSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	service: { type: String, default: "" }
}, { _id: false })

const IntegrationSchema = new Schema({
	service: { type: String, default: "" },
	isIntegration: { type: Boolean, default: "" },
	isEnableIntegration: { type: Boolean, default: "" },
	systemIntegration: { type: systemIntegrationSchema }
}, { _id: false })

setBaseSchema(IntegrationSchema, "integration")


IntegrationSchema.methods.serializeAPI = function () {
	try {
		let result = {

		}

		return result

	} catch (error) {
		return null
	}
}

const IntegrationModel = mongoose.model("integration", IntegrationSchema, "integration")

export default IntegrationModel