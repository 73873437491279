
import React, { createRef } from 'react'
import MyPage from '../../core/components/MyPage'
import { Menu } from 'semantic-ui-react'
import { setLanguage, } from 'react-switch-lang';
import Sidebar from '../common/Sidebar';
import ViewButton from '../common/ViewButton'

class LayoutWithSidebarHeaderFooter extends MyPage {
    constructor(props) {
        super(props)
        this.state.activeItem = ""
    }

    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    componentDidMount() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        window.addEventListener('resize', this.resize);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }
    resize = () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        this.forceUpdate()
    }

    contextRef = createRef()

    handleSetLanguage = (key) => () => {
        setLanguage(key);
    }

    render() {
        const { textHeader, buttons } = this.props
        let isAction = (Array.isArray(buttons) && buttons.length > 0)
            , isHeader = !!textHeader
        try {
            return (
                <React.Fragment>
                    <div className="module layoutWithSidebarHeaderFooter">
                        <div className="lwshf-sidebar">
                            <Sidebar />
                        </div>
                        <div className={`lwshf-body ${isHeader ? "add-header" : ""} ${isAction ? "add-footer" : ""}`}>
                            {
                                isHeader &&
                                <Menu secondary fixed="top" className="lwshf-menu-top">
                                    <Menu.Item>
                                        <h1>{textHeader}</h1>
                                    </Menu.Item>
                                </Menu>
                            }

                            {this.props.children}

                            {
                                isAction &&
                                <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                                    <div className="menu-bottom-wrapper">
                                        {
                                            buttons.map((model, i) => {
                                                return <ViewButton key={i} model={model} />
                                                // let propsBtn = {}
                                                // if (!!action.iconClass) {
                                                //     propsBtn.icon = true
                                                // }
                                                // if (!!action.color) {
                                                //     propsBtn.color = action.color
                                                // }
                                                // return <Button key={i} {...propsBtn} onClick={action.onClick}>
                                                //     {
                                                //         !!action.iconClass &&
                                                //         <Icon name={action.iconClass} />
                                                //     }
                                                //     {action.text}
                                                // </Button>
                                            })
                                        }
                                    </div>
                                </Menu>
                            }
                        </div>
                    </div>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}


LayoutWithSidebarHeaderFooter.defaultProps = {
    textHeader: "",
    buttons: []
    // {
    //     text: "",
    //     title: "",
    //     color: "",
    //     onClick: () => { },
    //     iconClass: "",
    // }
}



export default LayoutWithSidebarHeaderFooter