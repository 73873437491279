
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'

export default class ControlManager extends MyManager {
	async controlStatus(iotID, status, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/control/${iotID}`,
				data: {
                    status: status
                }
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async turnOnSceneResource(sceneID, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/control/scene/resource/${sceneID}`
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async turnOnSceneLocation(sceneID, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/control/scene/location/${sceneID}`
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}
}