
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import SensorRuleList from '../../views/sensorRule/SensorRuleList'

import { getSensorRuleTypeList } from '../../actions/sensorRuleActions'
import SensorRuleManager from '../../business/SensorRuleManager'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class SensorRuleController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bSensorRule = new SensorRuleManager(this)
	}
	getPropsResetStatus() {
		return ["sensorRuleList"]
	}
	onDeleteSensorRule = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensorRule.deleteSensorRule(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableSensorRule = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensorRule.enableSensorRuleStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableSensorRule = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensorRule.disableSensorRuleStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getSensorRuleListPaging = (query) => {
		return this.bSensorRule.getSensorRuleListPaging(query)
	}

	render() {
		try {
			const { t, sensorRuleTypeList } = this.props
			const { isLoadingComponent } = this.props

			let optionsSensorRuleType = sensorRuleTypeList.getDatasOption()

			let optionConcatList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			let optionsSensorRuleTypeList = optionConcatList.concat(optionsSensorRuleType)

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					{!this.requestFinishedAfterRequest() && this.getLoadingPage()}
					<SensorRuleList
						optionsSensorRuleTypeList={optionsSensorRuleTypeList}
						onDelete={this.onDeleteSensorRule}
						onChangeEnableSensorRule={this.onChangeEnableSensorRule}
						onChangeDisableSensorRule={this.onChangeDisableSensorRule}
						onGetDataPaging={this.getSensorRuleListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			sensorRuleTypeList: state.sensorRuleTypeList,
		}),
		(dispatch, props) => ({
			getSensorRuleTypeList() {
				dispatch(getSensorRuleTypeList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getSensorRuleTypeList()
		}
	})
)


export default enhance(SensorRuleController)