
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import { getDeskInfo } from '../../actions/deskActions'
import DeskManager from '../../business/DeskManager'
import utils from '../../core/utility/utils'
import DeskUpdate from '../../views/desk/DeskUpdate'

class UpdateDeskController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bDesk = new DeskManager(this)
	}

	getPropsResetStatus() {
		return ["deskInfo"]
	}

	onUpdateDesk = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bDesk.updateDesk(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/desk/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { deskInfo, locationList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const desk = deskInfo.getData()
				, locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			return (
				<React.Fragment>
					{super.render()}
					<DeskUpdate
						isLoadingControl={isLoadingComponent}
						model={desk}
						optionsTreeLocation={optionsTreeLocation}
						onUpdate={this.onUpdateDesk}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationList: state.locationList,
			deskInfo: state.deskInfo
		}),
		(dispatch, props) => ({
			getLocationList() {
				dispatch(getLocationList())
			},
			getDeskInfo() {
				dispatch(getDeskInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getDeskInfo()
		}
	}),
)


export default enhance(UpdateDeskController)