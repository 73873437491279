
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import VisitorGroupManager from '../../business/VisitorGroupManager'
import VisitorGroupCreate from '../../views/visitorGroup/VisitorGroupCreate'
import { getLocationList } from '../../actions/locationActions'
import utils from '../../core/utility/utils'

class CreateVisitorGroupController extends MyController {

	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bVisitorGroup = new VisitorGroupManager(this)
	}

	onCreateVisitorGroup = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bVisitorGroup.createVisitorGroup(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/visitorgroup/view/${res.data.id}`)
				}
				this.setState(body, action)
			})
		})

	}

	onClickBack = () => {
		this.props.history.push(`/visitorgroup`)
	}

	render() {
		try {
			const { t, locationList } = this.props
			const { isLoadingComponent } = this.state
			// const { visitorGroupPrivacyList } = this.props

			// const optionVisitorGroupPrivacy = visitorGroupPrivacyList.getDatasOption()

			const locationDatas = locationList.getDatas()
				, optionsTreeLocation = utils.makeGroupedArrayForTreeSelect(locationDatas, "id", "parentId", null, "name", "id")

			const optionVisitorGroupPrivacy = [
				{
					key: "PUBLIC",
					value: "PUBLIC",
					text: t(`constantTranslate.visitorGroupPrivacy.PUBLIC`)
				},
				{
					key: "PRIVATE",
					value: "PRIVATE",
					text: t(`constantTranslate.visitorGroupPrivacy.PRIVATE`)
				}
			]

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			return (
				<React.Fragment>
					{super.render()}
					<VisitorGroupCreate
						isLoadingControl={isLoadingComponent}
						onCreate={this.onCreateVisitorGroup}
						optionVisitorGroupPrivacy={optionVisitorGroupPrivacy}
						optionsTreeLocation={optionsTreeLocation}
					/>
				</React.Fragment >
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			// visitorGroupPrivacyList: state.visitorGroupPrivacyList
			locationList: state.locationList
		}),
		(dispatch, props) => ({
			/*          getVisitorGroupPrivacyList() {
						 dispatch(getVisitorGroupPrivacyList())
					 } */
			getLocationList() {
				dispatch(getLocationList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			//this.props.getVisitorGroupPrivacyList()
			this.props.getLocationList()
		}
	})
)


export default enhance(CreateVisitorGroupController)