
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form, Label, Dropdown } from 'semantic-ui-react'
import { defaultValue } from '../../../constant'

import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import InputFile from '../../core/view/common/InputFile'
import RFIDCardImportExcelModel from '../../models/RFIDCardImportExcelModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'

class FormImportExcel extends MyForm {
    constructor(props) {
        super(props)
        this.state.modalCustom = null
    }

    onConfirmDownloadExample = () => {
        const { t } = this.props
        this.setState({
            modalCustom: this.getInfoModalOption({
                headerText: t("common.confirm"),
                content: t("message.areYouSure"),
                buttons: [
                    new ButtonModel({
                        text: t("common.download"),
                        color: "blue",
                        onClick: () => {
                            this.setState({
                                modalCustom: null
                            }, () => {
                                this.props.onDownloadExampleFormat()
                            })
                        }
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: () => this.setState({ modalCustom: null })
                    })
                ]
            })
        })
    }

    render() {
        try {
            const { t, model, optionsFormatCode, errors } = this.props
            const { modalCustom } = this.state

            let formatCodeValidate = this.getErrorInput("formatCode", errors)
                , fileUploadValidate = this.getErrorInput("fileUpload", errors)
    
            return (
                <div className="module formImportExcel">
                    {modalCustom && this.getModalContent(modalCustom)}
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <div className="fie-box-formatcode">
                                        <div className="box-input">
                                            <LabelInput text={t("user.formatCode")} isRequire={true} />
                                            <Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                options={optionsFormatCode}
                                                onChange={this.handleChange}
                                                name="formatCode"
                                                value={model.formatCode}
                                                placeholder={t("common.pleaseSelect")}
                                                error={formatCodeValidate.isError}
                                            />
                                        </div>
                                        <div className="box-action">
                                            <ViewButton
                                                model={new ButtonModel({
                                                    iconClass: "cloud download",
                                                    color: "green",
                                                    title: t("common.downloadExample"),
                                                    disabled: !model.formatCode || model.formatCode === defaultValue.PLEASE_SELECT,
                                                    onClick: this.onConfirmDownloadExample
                                                })}
                                            />
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column width={16}>
                                    {
                                        (formatCodeValidate.isError && formatCodeValidate.content) &&
                                        <Form.Field>
                                            <Label pointing prompt>
                                                {formatCodeValidate.content}
                                            </Label>
                                        </Form.Field>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <LabelInput text={t("common.excelFile")} isRequire={true} />
                                    <InputFile
                                        name="fileUpload"
                                        onChange={this.handleChange}
                                        error={fileUploadValidate.isError ? fileUploadValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form >
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormImportExcel.defaultProps = {
    model: new RFIDCardImportExcelModel(),
    errors: [],
    optionsFormatCode: [],
    handleChange: () => console.error('Please provide Handle Change action.'),
    onDownloadExampleFormat: () => console.error('Please provide Download Example action.'),
}

export default (translate(FormImportExcel))