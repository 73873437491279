
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
// import RoleModel from '../models/RoleModel'

export default class RoleManager extends MyManager {
	async createRole(model, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/role`,
				data: model
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateRole(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/role/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteRole(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/role/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

/* 	async syncRole(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/role/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getRolePlatform() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/role/platformdata/list`
			}

			let res = await this.requestAction(options)
			let datas = []
			if (!!res && res.result === true && Array.isArray(res.datas)) {
				datas = res.datas.map(data => new RoleModel(data))
			}
			return datas
		} catch (error) {
			this.setDefaultModalError(error)
			return []
		}
	}

	async importRole(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/role/import/platformdata`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	} */
}