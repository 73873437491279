
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Icon, Tab, Header } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import SettingModel from '../../models/SettingModel'
import LabelInput from '../common/LabelInput'
import { serviceCode } from '../../../constant'
import ViewButton from '../common/ViewButton'

class SettingView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.activeIndex = parseInt(this.getParamFromProps("tabNum"), 10)
    }

    onEdit = () => {
        const { activeIndex } = this.state
        this.props.history.push(`/setting/authorization/update/${activeIndex}`)
    }

    onBack = () => {
        const { activeIndex } = this.state
        this.props.history.push(`/setting/authorization/${activeIndex}`)
    }

    handleTabChange = (e, { activeIndex }) => {
        this.setState({
            activeIndex: activeIndex
        })
    }

    onTestForgotPassword = (model) => {
        let body = {
            notificationId: model.systemNotificationInfo.notificationId,
            subject: model.systemNotificationInfo.forgotPassword.subject,
            content: model.systemNotificationInfo.forgotPassword.content,
        }
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.testForgotPassword(body)
            let tempState = {
                isLoading: false,
                isTestForgot: null
            }
            if (!!res && res.result === true) {
                tempState.isTestForgot = true
            } else {
                tempState.isTestForgot = false
            }
            this.setState(tempState)
        })
    }

    onTestResetPassword = (model) => {
        let body = {
            notificationId: model.systemNotificationInfo.notificationId,
            subject: model.systemNotificationInfo.resetPassword.subject,
            content: model.systemNotificationInfo.resetPassword.content,
        }
        this.setState({
            isLoading: true
        }, async () => {
            let res = await this.props.testResetPassword(body)
            let tempState = {
                isLoading: false,
                isTestReset: null
            }
            if (!!res && res.result === true) {
                tempState.isTestReset = true
            } else {
                tempState.isTestReset = false
            }
            this.setState(tempState)
        })
    }

    getModalTestForgotPassword = (isTestForgot) => {
        const { t } = this.props
        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("setting.testForgotPassword"),
            size: "mini",
            content: <div style={{ textAlign: "center" }}>
                {
                    isTestForgot === true ?
                        <h3 style={{ color: "#0f0" }}>{t("setting.testForgotPasswordSuccess")}</h3>
                        : <h3 style={{ color: "#f00" }}>{t("setting.testForgotPasswordFail")}</h3>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isTestForgot: null })
                })
            ]
        }))
    }

    getModalTestResetPassword = (isTestReset) => {
        const { t } = this.props
        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("setting.testResetPassword"),
            size: "mini",
            content: <div style={{ textAlign: "center" }}>
                {
                    isTestReset === true ?
                        <h3 style={{ color: "#0f0" }}>{t("setting.testResetPasswordSuccess")}</h3>
                        : <h3 style={{ color: "#f00" }}>{t("setting.testResetPasswordFail")}</h3>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isTestReset: null })
                })
            ]
        }))
    }

    render() {
        try {
            const { t } = this.props
            const { model, activeIndex, isTestForgot, isTestReset, isLoading } = this.state

            let buttons = [
                new ButtonModel({
                    text: t('common.edit'),
                    color: "green",
                    onClick: () => this.onEdit(model)
                })
            ]

            let isEnableForgot = false
                , subjectForgot = ""
                , contentForgot = ""
                , isEnableReset = false
                , subjectReset = ""
                , contentReset = ""
                , defaultDisplay = "-"
                , notificationName = "-"
                , defaultGroup = "-"

            let enableLoginSystems = []
                , enableFrontendLoginSystem = []
                , isSigninAndUpsert = false

            if (!!model) {
                if (!!model.systemNotificationInfo) {
                    let systemNotificationInfo = model.systemNotificationInfo
                    if (!!systemNotificationInfo.forgotPassword) {
                        let forgotPassword = systemNotificationInfo.forgotPassword
                        isEnableForgot = forgotPassword.isEnable
                        subjectForgot = forgotPassword.subject
                        contentForgot = forgotPassword.content
                    }
                    if (!!systemNotificationInfo.resetPassword) {
                        let resetPassword = systemNotificationInfo.resetPassword
                        isEnableReset = resetPassword.isEnable
                        subjectReset = resetPassword.subject
                        contentReset = resetPassword.content
                    }
                    if (!!systemNotificationInfo.notification) {
                        notificationName = systemNotificationInfo.notification.name
                    }
                }

                if (!!model.loginInfo) {
                    let loginInfo = model.loginInfo

                    if (Array.isArray(loginInfo.enableLoginSystems)) {
                        enableLoginSystems = loginInfo.enableLoginSystems
                    }

                    if (Array.isArray(loginInfo.enableFrontendLoginSystem)) {
                        enableFrontendLoginSystem = loginInfo.enableFrontendLoginSystem
                    }

                    if (loginInfo.defaultGroup) {
                        if (loginInfo.defaultGroup.name) {
                            defaultGroup = loginInfo.defaultGroup.name
                        }
                    }

                    if (!!loginInfo.defaultLoginSystem) {
                        let defaultLoginSystem = loginInfo.defaultLoginSystem
                        if (defaultLoginSystem.id === serviceCode.LOCAL) {
                            defaultDisplay = serviceCode.LOCAL
                        } else if (defaultLoginSystem.name) {
                            defaultDisplay = defaultLoginSystem.name
                        }
                    } else {
                        defaultDisplay = serviceCode.LOCAL
                    }

                    if (!!loginInfo.isSigninAndUpsert) {
                        isSigninAndUpsert = loginInfo.isSigninAndUpsert
                    }
                }
            }

            let componentLoginInfo = <p></p>
            let componentSystemNoti = <p></p>

            if (activeIndex === 0 && !!model) {
                componentLoginInfo = <Tab.Pane attached={false}>
                    <Table striped celled>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t('setting.adminLoginSystem')} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {enableLoginSystems.length > 0 ?
                                            enableLoginSystems.map((login, i) => {
                                                return <p key={i}>• {login.name}</p>
                                            })
                                            : '-'}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t('setting.userLoginSystem')} /></Table.Cell>
                                <Table.Cell>
                                    <div className="minThree">
                                        {enableFrontendLoginSystem.length > 0 ?
                                            enableFrontendLoginSystem.map((login, i) => {
                                                return <p key={i}>• {login.name}</p>
                                            })
                                            : '-'}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t('setting.defaultLoginSystem')} /></Table.Cell>
                                <Table.Cell><p>{defaultDisplay}</p></Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell width={4}><LabelInput text={t('setting.signinAndUpsert')} /></Table.Cell>
                                <Table.Cell>
                                    {
                                        isSigninAndUpsert ?
                                            < Icon color="green" name="checkmark" />
                                            : <Icon color="red" name="close" />
                                    }
                                </Table.Cell>
                            </Table.Row>
                            {
                                isSigninAndUpsert &&
                                <Table.Row>
                                    <Table.Cell width={5}><LabelInput text={t('setting.defaultResourceGroup')} /></Table.Cell>
                                    <Table.Cell><p>{defaultGroup}</p></Table.Cell>
                                </Table.Row>
                            }
                        </Table.Body>
                    </Table>
                </Tab.Pane>
            }

            if (activeIndex === 1 && !!model) {
                componentSystemNoti = <Tab.Pane attached={false}>
                    <div>
                        <Table striped celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={6}><LabelInput text={t('setting.systemNotificationInformation')} /></Table.Cell>
                                    <Table.Cell><p>{notificationName}</p></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </div>
                    <br />
                    <Header as='h3' className="form header">
                        {t("setting.forgotPassword")}
                    </Header>
                    <div>
                        <Table striped celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.enable')} /></Table.Cell>
                                    <Table.Cell>
                                        {
                                            isEnableForgot ?
                                                < Icon color="green" name="checkmark" />
                                                : <Icon color="red" name="close" />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.subject')} /></Table.Cell>
                                    <Table.Cell><p>{subjectForgot}</p></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.content')} /></Table.Cell>
                                    <Table.Cell>
                                        <div className="longContentMin">
                                            <div dangerouslySetInnerHTML={{ __html: contentForgot }} />
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: `${t('setting.testForgotPassword')}`,
                                                color: "blue",
                                                onClick: () => this.onTestForgotPassword(model),
                                                disabled: notificationName === "-" ? true : false
                                            })}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                        <br />
                        <Header as='h3' className="form header">
                            {t("setting.resetPassword")}
                        </Header>
                        <Table celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.enable')} /></Table.Cell>
                                    <Table.Cell>
                                        {
                                            isEnableReset ?
                                                < Icon color="green" name="checkmark" />
                                                : <Icon color="red" name="close" />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.subject')} /></Table.Cell>
                                    <Table.Cell><p>{subjectReset}</p></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.content')} /></Table.Cell>
                                    <Table.Cell>
                                        <div className="longContentMin">
                                            <div dangerouslySetInnerHTML={{ __html: contentReset }} />
                                        </div>
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='3'>
                                        <ViewButton
                                            model={new ButtonModel({
                                                text: `${t('setting.testResetPassword')}`,
                                                color: "blue",
                                                onClick: () => this.onTestResetPassword(model),
                                                disabled: notificationName === "-" ? true : false
                                            })}
                                        />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </div>
                </Tab.Pane>
            }

            const panes = [
                { menuItem: 'Login', pane: componentLoginInfo },
                { menuItem: 'System Notification', pane: componentSystemNoti },
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {(isTestForgot !== null && isTestForgot !== undefined) && this.getModalTestForgotPassword(isTestForgot)}
                    {(isTestReset !== null && isTestReset !== undefined) && this.getModalTestResetPassword(isTestReset)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('setting.title')} buttons={buttons}>
                        <Container text>
                            <div className="customTab">
                                <Tab
                                    menu={{ secondary: true, pointing: true }}
                                    activeIndex={activeIndex}
                                    onTabChange={this.handleTabChange}
                                    panes={panes}
                                    renderActiveOnly={false}
                                />
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SettingView.defaultProps = {
    model: new SettingModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SettingView)