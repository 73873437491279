
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import DeskForm from './DeskForm'
import ButtonModel from '../../core/model/ButtonModel'
import DeskModel from '../../models/DeskModel'

class DeskUpdate extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.model.formType = "UPDATE_DESK"
        this.state.modelErrors = []
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let tempState = {}
        if (!prevState.model && !!nextProps.model) {
            tempState.model = nextProps.model
        }
        if (Object.keys(tempState).length > 0) {
            return tempState
        }

        return null
    }

    onView = (model) => {
        this.props.history.push(`/desk/view/${model.id}`)
    }

    onSubmit = (isDisable) => {
        if (isDisable === false) {
            this.onValidate(this.onUpdate)
        }
    }

    onUpdate = () => {
        let model = this.state.model
        this.props.onUpdate(model)
    }

    onBack = () => {
        this.props.history.push(`/desk`)
        //this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsTreeLocation, isLoadingControl } = this.props
            const { model, modelErrors } = this.state
            // console.log(model);

            let isDisableSubmit = isLoadingControl

            let buttons = [
                new ButtonModel({
                    text: t('common.view'),
                    color: "violet",
                    onClick: () => this.onView(model)
                }),
                new ButtonModel({
                    text: t('common.save'),
                    color: "green",
                    onClick: () => this.onSubmit(isDisableSubmit),
                    disabled: isDisableSubmit,
                }),
                new ButtonModel({
                    text: t('common.cancel'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]

            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('desk.updateDesk')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {
                                                !!model ?
                                                    <DeskForm
                                                        model={model}
                                                        optionsTreeLocation={optionsTreeLocation}
                                                        handleChange={this.handleChange}
                                                        errors={modelErrors}
                                                        onSubmitForm={() => this.onValidate(() => this.onUpdate(model))}
                                                    />
                                                    : <p>{t("common.noData")}</p>
                                            }
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

DeskUpdate.defaultProps = {
    isLoadingControl: false,
    model: new DeskModel(),
    optionsTreeLocation: [],
    onUpdate: () => console.error("Please provide \"Update Desk\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(DeskUpdate)