
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

import CollectDataModel from '../conditionData/CollectDataModel'
import DoorAlertDataModel from '../conditionData/DoorAlertDataModel'
import MotionDataModel from '../conditionData/MotionDataModel'

let modelsCondition = [CollectDataModel, DoorAlertDataModel, MotionDataModel]

const Schema = mongoose.Schema

const ConditionDataInformationSchema = new Schema({
    conditionData: { type: Object, required: [true, "validate.require"] },
})

setBaseSchema(ConditionDataInformationSchema, "sensor_rule_condition_information")

ConditionDataInformationSchema.methods.serializeAPI = function () {
    try {
        let result = {
            conditionData: {}
        }

        if (!!this.conditionData && !!modelsCondition.find(model => this.conditionData instanceof model)) {
            result.conditionData = this.conditionData.serializeAPI()
        }

        return result
    } catch (error) {
        return null
    }
}

const ConditionDataModel = mongoose.model("sensor_rule_condition_information", ConditionDataInformationSchema, "sensor_rule_condition_information")

export default ConditionDataModel
