
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import RoleModel from '../../models/RoleModel'
import LabelInput from '../common/LabelInput'

class RoleView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
    }

    onCreate = () => {
        this.props.history.push(`/role/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/role/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/role`)
    }

    render() {
        try {
            const { t } = this.props
            const { model } = this.state

            let buttons = [
                new ButtonModel({
                    text: t('common.create'),
                    color: "teal",
                    onClick: () => this.onCreate()
                }),
                new ButtonModel({
                    text: t('common.edit'),
                    color: "green",
                    onClick: () => this.onEdit(model)
                }),
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            ]
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('role.viewRole')} buttons={buttons}>
                        <Container text>
                            <div>
                                <Table striped celled>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                            <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                            <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                            <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

RoleView.defaultProps = {
    model: new RoleModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(RoleView)