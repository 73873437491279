
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import ControllerModel from '../../models/ControllerModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchController extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new ControllerModel()
        this.state.model.status = defaultValue.SELECT_ALL
        this.state.model.gatewayId = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new ControllerModel({ mac: "", gatewayId: defaultValue.SELECT_ALL })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionsGatewayList } = this.props

            return (
                <div className="module formSearchController">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <LabelInput text={t("common.mac")} isRequire={false} />
                                    <Form.Input
                                        name="mac"
                                        value={model.mac}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.gateway")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsGatewayList}
                                        onChange={this.handleChange}
                                        name="gatewayId"
                                        value={model.gatewayId}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchController.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new ControllerModel(),
    optionsGatewayList: []
}

export default (translate(FormSearchController))