
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { getControllerInfo, getControllerConfigInfo } from '../../actions/controllerActions'
import { translate } from 'react-switch-lang'
import utils from '../../core/utility/utils'
import ControllerManager from '../../business/ControllerManager'
import ControllerView from '../../views/controller/ControllerView'
import ControlManager from '../../business/ControlManager'

class ViewControllerController extends MyController {
	constructor(props) {
		super(props)

		this.bController = new ControllerManager(this)
		this.bControl = new ControlManager(this)
		this.state.isLoadingComponent = false
	}

	getPropsResetStatus() {
		return ["controllerInfo"]
	}

	onControlStatus = (controllerId, iotId, status) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bControl.controlStatus(iotId, status, (res) => {

				let tempState = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result) {
					action = () => this.props.getControllerConfigInfo(controllerId)
				}

				this.setState(tempState, action)
			})
		})
	}

	render() {
		try {
			const { controllerInfo, controllerConfigInfo, isLoadingComponent } = this.props

			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const controller = controllerInfo.getData()
			, controllerConfig = controllerConfigInfo.getData()

			return (
				<React.Fragment>
					{super.render()}
					{isLoadingComponent && this.getLoadingPage()}
					<div className="body">
						<ControllerView
							model={controller}
							controllerConfig={controllerConfig}
							onControlStatus={this.onControlStatus}
						/>
					</div>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			controllerInfo: state.controllerInfo,
			controllerConfigInfo: state.controllerConfigInfo
		}),
		(dispatch, props) => ({
			getControllerInfo() {
				dispatch(getControllerInfo(utils.getParamFromProps(props, "id")))
			},
			getControllerConfigInfo() {
				dispatch(getControllerConfigInfo(utils.getParamFromProps(props, "id")))
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getControllerInfo()
			this.props.getControllerConfigInfo()
		}
	}),
)


export default enhance(ViewControllerController)