
import ActionResourceObject from '../core/redux/ActionResourceObject'

export default class SettingSensorDataResource extends ActionResourceObject {
    setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d,
						value: d,
						text: d
					}
				})
			} else {
				result = {
					key: data,
					value: data,
					text: data
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}