
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ViewForgotPassword from '../../views/auth/ViewForgotPassword'
import AuthManager from '../../business/AuthManager'
import { cookiesKey } from '../../../constant'

class ForgotPasswordController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoading = false
		this.state.isLoadingComponent = false
		// this.state.loginSetting = []

		this.bAuth = new AuthManager(this)
	}

	componentDidMount() {
		this.bCookie.removeData(cookiesKey.TARGET_URL)
	}

	onForgotPassword = (body, callback) => {
		return this.bAuth.forgotPassword(body, callback)
	}

	render() {
		try {
			return (
				<React.Fragment>
					{super.render()}
					<ViewForgotPassword
						onForgotPassword={this.onForgotPassword}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	connect(
		(state, props) => ({
		}),
		(dispatch, props) => ({

		}),
	),
	lifecycle({
	})
)


export default enhance(ForgotPasswordController)