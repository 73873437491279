
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import AccountModel from '../../models/AccountModel'
import LabelInput from '../common/LabelInput'

class AccountPasswordForm extends MyForm {
    render() {
        try {
            const { t, model, errors } = this.props

            let oldPasswordValidate = this.getErrorInput("oldPassword", errors)
                , newPasswordValidate = this.getErrorInput("newPassword", errors)
                , confirmPasswordValidate = this.getErrorInput("confirmPassword", errors)

            return (
                <div className="module accountPasswordForm">
                    <input
                        name="username"
                        style={{ display: "none" }}
                    />
                    <input
                        name="password"
                        type={"password"}
                        style={{ display: "none" }}
                    />
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            {
                                !!model.id &&
                                <React.Fragment>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("account.oldPassword")} isRequire={true} />
                                            <Form.Input
                                                name="oldPassword"
                                                value={model.oldPassword}
                                                onChange={this.handleChange}
                                                placeholder={t("account.oldPassword")}
                                                fluid
                                                type="password"
                                                error={oldPasswordValidate.isError ? oldPasswordValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("account.newPassword")} isRequire={true} />
                                            <Form.Input
                                                name="newPassword"
                                                value={model.newPassword}
                                                onChange={this.handleChange}
                                                placeholder={t("account.newPassword")}
                                                fluid
                                                type="password"
                                                error={newPasswordValidate.isError ? newPasswordValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("account.confirmPassword")} isRequire={true} />
                                            <Form.Input
                                                name="confirmPassword"
                                                value={model.confirmPassword}
                                                onChange={this.handleChange}
                                                placeholder={t("account.confirmPassword")}
                                                fluid
                                                type="password"
                                                error={confirmPasswordValidate.isError ? confirmPasswordValidate.content : null}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </React.Fragment>
                            }
                        </Grid>
                    </Form >
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

AccountPasswordForm.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    model: new AccountModel(),
    errors: []
}

export default (translate(AccountPasswordForm))