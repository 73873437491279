
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import AdminModel from '../../models/AdminModel'

class AdminStatusForm extends MyForm {
    render() {
        const { model, optionAdminStatus } = this.props

        // console.log()
        return (
            <React.Fragment>
                <Form>
                    <Form.Dropdown
                        fluid
                        selection
                        options={optionAdminStatus}
                        onChange={this.handleChange}
                        name="status"
                        value={model.status}
                    />
                </Form>
            </React.Fragment>
        )
    }
}

AdminStatusForm.defaultProps = {
    handleChange: () => console.error('Please provide Handle Change action.'),
    model: new AdminModel(),
    optionAdminStatus: [],
    error: [],
}

export default (translate(AdminStatusForm))