
import React from 'react'
import { eventControlRuleStep } from '../../../constant'
import Step from '../../core/business/step/Step'

import ConditionView from '../../views/eventControlRuleStep/step/ConditionView'
import EventControlRuleConditionModel from '../../models/eventControlRuleStep/EventControlRuleConditionModel'

export default class ConditionStep extends Step {
	constructor(stepList) {
		super(stepList)
		this.title = "sensorRule.condition"
		this.key = eventControlRuleStep.CONDITION
		this.data = new EventControlRuleConditionModel()
		this.initData()
	}

	initData() {
		let data = this.stepList.getData()
		if (!!data && data[this.key] instanceof EventControlRuleConditionModel) {
			this.data = new EventControlRuleConditionModel(data[this.key])
		}
	}

	setData(allResult) {
		let data = this.getData()
		allResult = super.setData(allResult)
		if (!!data && data instanceof EventControlRuleConditionModel) {
			allResult[this.key] = data
		}
		return allResult
	}

	nextStep(data) {
		this.data = new EventControlRuleConditionModel(data)
		super.nextStep()
	}

	prevStep(data) {
		this.data = new EventControlRuleConditionModel(data)
		super.prevStep()
	}

	saveStep(data, callback) {
		this.data = new EventControlRuleConditionModel(data)
		super.saveStep()
		if (typeof callback === "function") {
			callback(this.key)
		} else {
			console.error("Please provide Callback Submit function for Save Step.")
		}
	}

	render(dataConfig) {
		return <ConditionView
			dataConfig={dataConfig}
			dataStep={this.data}
			onBack={(data) => this.prevStep(data)}
			onNext={(data) => this.nextStep(data)}
			onCancel={() => this.cancelStep()}
			onSave={(data) => this.saveStep(data, dataConfig.onSubmit)}
		/>
	}
}