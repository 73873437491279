
import mongoose from 'mongoose'
import { setBaseSchema } from '../schema/Schema'
import PagingSchema from './schemas/PagingSchema'

const Schema = mongoose.Schema

const PagingDataSchema = new Schema({
	pagination: { type: PagingSchema, default: {} },
	datas: [{ type: Object }]
}, { _id: false })

setBaseSchema(PagingDataSchema, "paging_data")

const PagingDataModel = mongoose.model("paging_data", PagingDataSchema, "paging_data")

export default PagingDataModel