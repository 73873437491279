
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Grid, Step } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import StepList from '../../core/business/step/StepList'
import EventControlRuleInfoStep from '../../business/eventControlRuleStep/EventControlRuleInfoStep'
import EventControlRuleSourceStep from '../../business/eventControlRuleStep/EventControlRuleSourceStep'
import ConditionStep from '../../business/eventControlRuleStep/ConditionStep'
import ActionInformationStep from '../../business/eventControlRuleStep/ActionInformationStep'
import { eventControlRuleStep } from '../../../constant'
import EventControlRuleModel from '../../models/EventControlRuleModel'

class ViewEventControlRuleCreate extends MyPage {
    constructor(props) {
        super(props)
        this.stepList = new StepList(this)

        this.state.currentStep = null

        this.state.isLoading = false
        this.state.registerFail = null
        this.state.modalCustom = null
        this.initStep()
    }

    initStep() {
        this.stepList.addStep(new EventControlRuleInfoStep(this.stepList))
        this.stepList.addStep(new EventControlRuleSourceStep(this.stepList))
        this.stepList.addStep(new ConditionStep(this.stepList))
        this.stepList.addStep(new ActionInformationStep(this.stepList))
        this.state.currentStep = this.stepList.root
    }

    changeCurrentStep = (step) => {
        this.setState({ currentStep: step })
    }

    onClickHeaderStep = (step) => {
        const { currentStep } = this.state
        let dataStepList = this.stepList.data
        let activeKey = null
        if (!!currentStep) {
            activeKey = currentStep.key
        }
        if (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key]) {
            this.stepList.gotoStep(step.id)
        }
    }

    submit = () => {
        let data = this.stepList.data
        if (!!data) {
            let eventInformationModel = data[eventControlRuleStep.EVENT_INFORMATION]
                , sourceModel = data[eventControlRuleStep.SOURCE]
                , conditionModel = data[eventControlRuleStep.CONDITION]
                , actionInformationModel = data[eventControlRuleStep.ACTION_INFORMATION]

            let model = new EventControlRuleModel({
                code: eventInformationModel.code,
                name: eventInformationModel.name,
                description: eventInformationModel.description,
                source: sourceModel.source,

                condition: conditionModel.condition,

                actions: actionInformationModel.actions,
            })

            this.props.onSubmit(model)
        }
    }

    back() {
        this.props.history.goBack()
    }

    render() {
        try {
            const { t, optionsEventList, optionsTreeLocation, optionsResourceGroupList, optionsVisitorGroupList, optionsHolidayTypeList, optionsRecurrenceTypeList, optionsDayOfWeekList, optionsActionTypeList, optionsActionStatusList, optionsResources, optionsResourceType, onGetDataList } = this.props
            const { currentStep, isLoading, modalCustom } = this.state

            let buttons = []

            let activeKey = null
            if (!!currentStep) {
                activeKey = currentStep.key
            }
            let dataConfig = {
                optionsEventList: optionsEventList,
                optionsTreeLocation: optionsTreeLocation,
                optionsResourceGroupList: optionsResourceGroupList,
                optionsVisitorGroupList: optionsVisitorGroupList,
                optionsHolidayTypeList: optionsHolidayTypeList,
                optionsRecurrenceTypeList: optionsRecurrenceTypeList,
                optionsDayOfWeekList: optionsDayOfWeekList,
                optionsActionTypeList: optionsActionTypeList,
                optionsActionStatusList: optionsActionStatusList,
                optionsResources: optionsResources,
                optionsResourceType: optionsResourceType,
                onGetDataList: onGetDataList,
                stepStatus: "CREATE",
                onSubmit: this.submit,
            }

            let dataStepList = this.stepList.data
                , stepList = this.stepList.getNavigator()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {modalCustom && this.getModalContent(modalCustom)}
                    <LayoutWithSidebarHeaderFooter textHeader={t('eventControlRule.createEventControlRule')} buttons={buttons} >
                        <div className="module viewEventControlRuleCreate">
                            <Container text>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <Step.Group fluid ordered className="step-group-custom">
                                                {
                                                    Array.isArray(stepList) &&
                                                    stepList.map((step, i) => {
                                                        let isCompleted = (activeKey !== step.key && !!dataStepList && !!dataStepList[step.key])
                                                            , isActive = (activeKey === step.key)
                                                            , classNameStep = "item-step"
                                                        if (!isCompleted || isActive) {
                                                            classNameStep += "step-disabled"
                                                        }
                                                        return <Step key={step.key} active={isActive} completed={isCompleted} onClick={() => this.onClickHeaderStep(step)} className={classNameStep}>
                                                            <Step.Content>
                                                                <Step.Title>{t(step.title)}</Step.Title>
                                                            </Step.Content>
                                                        </Step>
                                                    })
                                                }
                                            </Step.Group>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            {currentStep && currentStep.render(dataConfig)}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewEventControlRuleCreate.defaultProps = {
    optionsEventList: [],
    optionsTreeLocation: [],
    optionsResourceGroupList: [],
    optionsVisitorGroupList: [],
    optionsHolidayTypeList: [],
    optionsRecurrenceTypeList: [],
    optionsDayOfWeekList: [],
    optionsActionTypeList: [],
    optionsActionStatusList: [],
    optionsResources: [],
    optionsResourceType: [],
    onGetDataList: () => console.error("Please provide get Resource Data List function"),
    onSubmit: () => console.error("Please provide Create Sensor Rule action.")
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewEventControlRuleCreate)