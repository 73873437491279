
import ActionResourceObject from '../core/redux/ActionResourceObject'
import VisitorModel from '../models/VisitorModel'

export default class VisitorResource extends ActionResourceObject {
    
    setResource(data) {
        try {
            if(!data) {
                throw new Error("Data not found")
            }

            let result = null
            if(Array.isArray(data)) {
                result = data.map(d => new VisitorModel(d))
            }
            else {
                result = new VisitorModel(data)
            }

            return result
        } catch(error) {
            console.log(error)
            return null
        }
    }

    setResourceOption(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => {
					return {
						key: d.id,
						value: d.id,
						text: d.name
					}
				})
			} else {
				result = {
					key: data.id,
					value: data.id,
					text: data.name
				}
			}

			return result
		} catch (error) {
			return null
		}
	}
}