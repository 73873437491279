
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import ResourceGroupManager from '../../business/ResourceGroupManager'
import ResourceGroupCreate from '../../views/resourceGroup/ResourceGroupCreate'
import { getLocationList } from '../../actions/locationActions'
import { getUserList } from '../../actions/userActions'
import { getSensorRuleList } from '../../actions/sensorRuleActions'

class CreateResourceGroupController extends MyController {
	constructor(props) {
		super(props)
		this.state.isLoadingComponent = false
		this.bResourceGroup = new ResourceGroupManager(this)
	}

	onCreateResourceGroup = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bResourceGroup.createResourceGroup(model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {

					action = this.props.history.push(`/resourcegroup/view/${res.data.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	onClickBack = () => {
		this.props.history.push(`/resourcegroup`)
	}

	render() {
		try {
			const { locationList, userList, sensorRuleList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const optionsLocationList = locationList.getDatasOption()
				, optionsSensorRuleList = sensorRuleList.getDatasOption()
				, optionsResourceList = userList.getDatasOption()

			return (
				<React.Fragment>
					{super.render()}
					<ResourceGroupCreate
						isLoadingControl={isLoadingComponent}
						optionsLocationList={optionsLocationList}
						optionsResourceList={optionsResourceList}
						optionsSensorRuleList={optionsSensorRuleList}
						onCreate={this.onCreateResourceGroup}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationList: state.locationList,
			userList: state.userList,
			sensorRuleList: state.sensorRuleList
		}),
		(dispatch, props) => ({
			getLocationList() {
				dispatch(getLocationList())
			},
			getUserList() {
				dispatch(getUserList())
			},
			getSensorRuleList() {
				dispatch(getSensorRuleList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getUserList()
			this.props.getSensorRuleList()
		}
	}),
)


export default enhance(CreateResourceGroupController)