
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Header, Table } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import SMTPDataModel from '../../../models/notificationData/SMTPDataModel'

class SMTPDataView extends MyForm {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Header as='h1' className="form header">
                            {t("notification.notiSendSmtp")}
                        </Header>
                        <Table striped celled>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.email')} /></Table.Cell>
                                    <Table.Cell><p>{model.senderEmail}</p></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={4}><LabelInput text={t('common.name')} /></Table.Cell>
                                    <Table.Cell><p>{model.senderName}</p></Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

SMTPDataView.defaultProps = {
    model: new SMTPDataModel(),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(SMTPDataView)