
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'
import { withRouter } from 'react-router-dom'

import MyPage from '../../core/components/MyPage'
import LayoutBlank from '../layout/LayoutBlank'
import config from '../../config'

class ViewLoginBypass extends MyPage {
    render() {
        try {
            const { t, isLoading } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutBlank>
                        <div className="module viewLoginBypass">
                            <div className="vlb-background" >
                                <img src={`${config.publicUrl}/assets/images/image-forest_fog_trees.jpg`} alt="No Background" />
                            </div>
                            <div className="vlb-wrapper">
                                <h1 className="text-header-login">{t("auth.pageHeader")}</h1>
                                {
                                    isLoading &&
                                    this.getLoadingComponent()
                                }
                            </div>
                        </div>
                    </LayoutBlank>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewLoginBypass.defaultProps = {
    isLoading: false,
}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({

        }),
        (dispatch, props) => ({

        }),
    ),
)


export default enhance(ViewLoginBypass)