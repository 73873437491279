
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import ViewUpdateDepartment from '../../views/department/ViewUpdateDepartment'
import DepartmentManager from '../../business/DepartmentManager'
import DepartmentModel from '../../models/DepartmentModel'
import utils from '../../core/utility/utils'
import { getDepartmentInfo } from '../../actions/departmentActions'

class UpdateDepartmentController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bDepartment = new DepartmentManager(this)
    }

    onUpdateDepartment = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bDepartment.updateDepartment(model.id, model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    let department = new DepartmentModel(res.data)
                    action = () => { this.props.history.replace(`/department/view/${department.id}`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { department } = this.props
            const { isLoadingComponent } = this.state

            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const departmentData = department.getData()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewUpdateDepartment
                        isLoadingControl={isLoadingComponent}
                        department={departmentData}
                        onUpdate={this.onUpdateDepartment}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            department: state.department
        }),
        (dispatch, props) => ({
            getDepartmentInfo() {
                dispatch(getDepartmentInfo(utils.getParamFromProps(props, "id")))
            },
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getDepartmentInfo()
        }
    })
)


export default enhance(UpdateDepartmentController)