
import React from 'react'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Table, Grid, Header, Icon } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import AccountModel from '../../models/AccountModel'
import LabelInput from '../common/LabelInput'
import { serviceCode } from '../../../constant'
import ViewImageInfo from '../../core/view/common/ViewImageInfo'
import ModalIntegrationLogin from './ModalIntegrationLogin'
import ViewNoResult from '../common/ViewNoResult'

class AccountView extends MyPage {

    onCancel = () => {
        this.props.history.goBack()
    }

    onButtonChangeInfo = () => {
        this.props.history.push("/account/update")
    }

    onButtonChangePassword = () => {
        this.props.history.push("/account/changepassword")
    }

    onConfirmUnIntegrate = (serviceInfo, systemIntegrationId) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onUnintegtationUser(model, serviceInfo, systemIntegrationId)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.service"),
                selector: (row) => {
                    if (row.systemIntegration) {
                        return row.systemIntegration.name ? row.systemIntegration.name : '-'
                    } else {
                        return row.service
                    }
                },
                sortable: false,
                maxWidth: '12vw',
            },
            {
                name: t("common.integrationStatus"),
                selector: (row) => {
                    if (row.isIntegration) {
                        //return `${t("common.integrated")}`
                        return <Icon color="green" name="checkmark" />
                    } else if (row.isIntegration === false) {
                        //return `${t("common.notIntegrated")}`
                        return <Icon color="red" name="close" />
                    } else {
                        //return `${t("common.unknown")}`
                        return <Icon color="grey" name="circle" />
                    }
                },
                sortable: false,
                width: '150px',
                center: true,
            },
            {
                name: '',
                minWidth: 'fit-content',
                cell: row => {
                    let buttons = []

                    if (row.isIntegration && row.isEnableIntegration) {
                        buttons = [
                            {
                                text: "",
                                title: t("common.unIntegration"),
                                color: "red",
                                onClick: () => {
                                    if (row.systemIntegration) {
                                        this.onConfirmUnIntegrate(row.service, row.systemIntegration.id)
                                    } else {
                                        this.onConfirmUnIntegrate(row.service)
                                    }
                                },
                                iconClass: "unlink",
                                size: "mini"
                            }
                        ]
                    } else {
                        if (row.isEnableIntegration && row.systemIntegration) {

                            buttons = [
                                {
                                    text: "",
                                    title: t("common.integration"),
                                    color: "green",
                                    onClick: () => { this.onClickIntegrationLogin(row.systemIntegration.id, row.systemIntegration.service) },
                                    iconClass: "linkify",
                                    size: "mini"
                                }
                            ]

                        } else if (row.isEnableIntegration) {
                            buttons = [
                                {
                                    text: "",
                                    title: t("common.integration"),
                                    color: "green",
                                    onClick: () => { },
                                    iconClass: "linkify",
                                    size: "mini"
                                }
                            ]
                        }
                    }


                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    onClickIntegrationLogin = (serviceId, integrationType) => {
        this.setState({
            isOpenIntegrationLogin: true,
            serviceId: serviceId,
            integrationType: integrationType
        })
    }

    onCloseIntegrationLogin = () => {
        this.setState({
            isOpenIntegrationLogin: false
        })
    }

    onSubmitIntegrationLogin = (modelIntegrationLogin) => {
        const { model } = this.props
        const { integrationType } = this.state

        if (integrationType === serviceCode.ACTIVE_DIRECTORY) {
            this.props.onADIntegrationAccount(model, modelIntegrationLogin)
        } else if (integrationType === serviceCode.EXCHANGE) {
            this.props.onExchangeIntegrationAccount(model, modelIntegrationLogin)
        }

        this.setState({
            isOpenIntegrationLogin: false
        })
    }

    render() {
        try {
            const { t, model, accountIntegrationList } = this.props
            const { isLoading, isOpenIntegrationLogin, serviceId, integrationType } = this.state

            let allowChange = true

            if (model.resourceTypeCode === "SUPER_ADMINISTRATOR" || model.resourceTypeCode === "PROFILE_CENTER") {
                allowChange = false
            }

            let buttons = []

            if (allowChange) {
                buttons = [
                    new ButtonModel({
                        text: t("account.changeInfo"),
                        color: "green",
                        onClick: this.onButtonChangeInfo
                    }),
                    new ButtonModel({
                        text: t("account.changePassword"),
                        color: "yellow",
                        onClick: this.onButtonChangePassword
                    }),
                    new ButtonModel({
                        text: t("common.back"),
                        color: "grey",
                        onClick: this.onCancel
                    })
                ]
            } else {
                buttons = [
                    new ButtonModel({
                        text: t("common.back"),
                        color: "grey",
                        onClick: this.onCancel
                    })
                ]
            }
            let imagePath = ``
            if (model.resourceTypeCode === "USER") {
                imagePath = `user/${model.id}`
            } else if (model.resourceTypeCode === "PROFILE_CENTER") {
                imagePath = `admin/PROFILE_CENTER`
            } else if (model.resourceTypeCode === "SUPER_ADMINISTRATOR") {
                imagePath = `admin/SUPER_ADMINISTRATOR`
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    {isOpenIntegrationLogin &&
                        <ModalIntegrationLogin
                            id={serviceId}
                            integrationType={integrationType}
                            onSubmit={(accName) => { this.onSubmitIntegrationLogin(accName) }}
                            onClose={this.onCloseIntegrationLogin}
                        />
                    }
                    <LayoutWithSidebarHeaderFooter textHeader={t('account.viewAccount')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <Table striped celled>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("account.employeeCode")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("account.fullname")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.displayName ? model.displayName : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("account.username")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.username ? model.username : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("account.mobile")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.mobile ? model.mobile : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("common.email")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.email ? model.email : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("account.pinCode")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.pinCode ? model.pinCode : '-'}</p></Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("resourceGroup.title")} /></Table.Cell>
                                                    <Table.Cell>
                                                        <div className="minThree">
                                                            {Array.isArray(model.resourceGroups) && model.resourceGroups.length > 0 ?
                                                                model.resourceGroups.map((d, i) => {
                                                                    return <p>• {d.name}</p>
                                                                })
                                                                : '-'
                                                            }
                                                        </div>
                                                    </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell width={4}><LabelInput text={t("department.title")} /></Table.Cell>
                                                    <Table.Cell><p>{!!model.department ? model.department.name : '-'}</p></Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <ViewImageInfo textHeader={t("account.profile")} src={imagePath} />
                                        <br></br>
                                        <br></br>
                                        {
                                            (Array.isArray(accountIntegrationList) && accountIntegrationList.length > 0) &&
                                            <React.Fragment>
                                                <Grid divided='vertically'>
                                                    <Grid.Row className="remove-bottom-padding">
                                                        <Grid.Column width={16}>
                                                            <Header as='h1'>
                                                                {t("common.integrationInfo")}
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getColumnConfig()}
                                                                datas={accountIntegrationList}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

AccountView.defaultProps = {
    model: new AccountModel()
}

const enhance = compose(
    withRouter,
    translate,

)

export default enhance(AccountView)