
import types from '../types'
import { requestStatus } from '../../constant'
import DeskResource from '../selectors/deskResource'
import SceneResource from '../selectors/sceneResource'

const deskReducer = {
	deskInfo: (state = null, action) => {
		if (!state || !(state instanceof DeskResource)) {
			state = new DeskResource()
		}

		switch (action.type) {
			case types.GET_DESK_INFO_REQUEST:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_DESK_INFO_SUCCESS:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_DESK_INFO_FAILURE:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	deskList: (state = null, action) => {
		if (!state || !(state instanceof DeskResource)) {
			state = new DeskResource()
		}

		switch (action.type) {
			case types.GET_DESK_LIST_REQUEST:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_DESK_LIST_SUCCESS:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.RESET_DESK_LIST:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(null)
				break
			case types.GET_DESK_LIST_FAILURE:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	deskWithIOTConfigInfo: (state = null, action) => {
		if (!state || !(state instanceof DeskResource)) {
			state = new DeskResource()
		}

		switch (action.type) {
			case types.GET_DESK_WITH_IOT_CONFIG_INFO_REQUEST:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_DESK_WITH_IOT_CONFIG_INFO_SUCCESS:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_DESK_WITH_IOT_CONFIG_INFO_FAILURE:
				state = new DeskResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	},
	deskSceneInfo: (state = null, action) => {
		if (!state || !(state instanceof SceneResource)) {
			state = new SceneResource()
		}

		switch (action.type) {
			case types.GET_DESK_SCENE_INFO_REQUEST:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST)
				state.setValue(null)
				break
			case types.GET_DESK_SCENE_INFO_SUCCESS:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_SUCCESS)
				state.setValue(action.payload)
				break
			case types.GET_DESK_SCENE_INFO_FAILURE:
				state = new SceneResource()
				state.setStatus(requestStatus.REQUEST_FAIL)
				// state.setValue(action.payload)
				break
			default:
				break
		}

		return state
	}
}

export default deskReducer