
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const O365DataSchema = new Schema({
	apiUrl: { type: String, required: [true, "validate.require"], default: "" },
	authenUrl: { type: String, required: [true, "validate.require"], default: "" },
	clientId: { type: String, required: [true, "validate.require"], default: "" },
	clientSecret: { type: String, required: [true, "validate.require"], default: "" },
	tenantId: { type: String, required: [true, "validate.require"], default: "" },
	redirectUrl: { type: String, required: [true, "validate.require"], default: "" }
}, { _id: false })

setBaseSchema(O365DataSchema, "system_integration_o365")

const O365DataModel = mongoose.model("system_integration_o365", O365DataSchema, "system_integration_o365")

export default O365DataModel