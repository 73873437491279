
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import ResourcePlatformModel from '../models/ResourcePlatformModel'
import SceneModel from '../models/SceneModel'
import PagingDataModel from '../core/model/PagingDataModel'
import DeskModel from '../models/DeskModel'

export default class DeskManager extends MyManager {

	async createDesk(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/desk`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateDesk(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async createDeskScene(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/desk/scene/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateDeskScene(deskId, sceneId, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/scene/${deskId}/${sceneId}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteDeskScene(deskId, sceneId, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/desk/scene/${deskId}/${sceneId}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async upsertAreaDesk(deskId, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/area/${deskId}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteAreaDesk(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/desk/area/${id}/${body.id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getDeskList(query = "") {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk${!!query ? "?" + query : ""}`,
			}

			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteDesk(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/desk/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async unintegrateDesk(id, service, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/unintegration/${id}`,
				data: {
					"unintegrationService": service
				}
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async enableDeskStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/enable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async disableDeskStatus(id, callback) {
		// console.log(id, body)
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/desk/disable/${id}`,
			}
			let res = await this.requestAction(options, callback)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async syncDesk(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/desk/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getDeskPlatform(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk/platformdata/list${!!query ? query : ""}`
			}

			let res = await this.requestAction(options)
			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new ResourcePlatformModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importDesk(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/desk/import/platformdata`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getSceneById(id) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk/scene/${id}`,
			}
			let res = await this.requestAction(options)
			let scenes = []
			if (!!res && !!res.result === true && Array.isArray(res.datas)) {
				scenes = res.datas.map(data => {
					return new SceneModel(data)
				})
			}
			return scenes
		} catch (error) {
			return []
		}
	}

	async getDeskListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null

			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new DeskModel(d)
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async deskTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/desk/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}