
import { CORE_API_ENDPOINT } from '../lib'
import MyManager from '../core/business/MyManager'
import VisitorGroupModel from '../models/VisitorGroupModel'
import PagingDataModel from '../core/model/PagingDataModel'

export default class VisitorGroupManager extends MyManager {

	async createVisitorGroup(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/visitorgroup`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async updateVisitorGroup(id, body, callback) {
		try {
			const options = {
				method: this.requestMethod.PATCH,
				url: `${CORE_API_ENDPOINT}/visitorgroup/${id}`,
				data: body
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async deleteVisitorGroup(id, callback) {
		try {
			const options = {
				method: this.requestMethod.DELETE,
				url: `${CORE_API_ENDPOINT}/visitorgroup/${id}`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async syncVisitorGroup(callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/visitorgroup/syncplatform`,
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getVisitorGroupPlatform(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/visitorgroup/platformdata/list${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null
			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new VisitorGroupModel(d)
					})
				}
			}
			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async importVisitorGroupPlatform(body, callback) {
		try {
			const options = {
				method: this.requestMethod.POST,
				url: `${CORE_API_ENDPOINT}/visitorgroup/import/platformdata`,
				data: {
					"visitorGroupIds": body
				}
			}

			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getVisitorPrivacyList() {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/visitorgroup/privacy`,
			}
			let res = await this.requestAction(options)
			return res
		}
		catch (error) {
			this.setDefaultModalError(error)
		}
	}

	async getVisitorGroupListPaging(query) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/visitorgroup/page${!!query ? query : ""}`,
			}

			let res = await this.requestAction(options)

			let data = null

			if (!!res && res.result === true && !!res.data) {
				data = new PagingDataModel({ pagination: res.data.pagination })
				if (Array.isArray(res.data.datas) && res.data.datas.length > 0) {
					data.datas = res.data.datas.map(d => {
						return new VisitorGroupModel(d)
					})
				}
			}

			return data
		} catch (error) {
			this.setDefaultModalError(error)
			return null
		}
	}

	async visitorGroupTestSyncPlatform(id, callback) {
		try {
			const options = {
				method: this.requestMethod.GET,
				url: `${CORE_API_ENDPOINT}/visitorgroup/check/sync/platform/${id}`,
				isHandleError: false
			}
			let res = await this.requestAction(options, callback)
			return res
		} catch (error) {
			//this.setDefaultModalError(error)
			return false
		}
	}
}