import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import EventControlRuleActionModel from '../../../models/eventControlRuleStep/EventControlRuleActionModel'
import ViewTable from '../../../core/view/common/table/ViewTable'
import { eventControlRuleStep } from '../../../../constant'

class ActionInformationView extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = new EventControlRuleActionModel(props.dataStep)
        this.state.isLoading = false
    }

    // onSubmit = () => {
    //     this.onValidate(this.onNext)
    // }

    onBack = () => {
        this.props.onBack(this.state.model)
    }

    onNext = () => {
        this.props.onNext(this.state.model)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    onCreate = () => {
        this.props.history.push(`/eventcontrolrule/create`)
    }

    onEdit = () => {
        const { dataConfig } = this.props
        this.props.history.push(`/eventcontrolrule/update/${dataConfig.editID}?step=${eventControlRuleStep.ACTION_INFORMATION}`)
    }

    getColumnConfigAction(actions) {
        const { t } = this.props

        const columns = [
            {
                name: t("eventControlRule.actionType"),
                selector: (row) => row.type ? row.type : '-',
                width: '200px',
            },
            {
                name: t("common.status"),
                selector: (row) => row.status ? row.status : '-',
                width: '150px'
            },
            {
                name: t("eventControlRule.resource"),
                selector: (row) => {
                    let detail = row.datas.map(data => {
                        return <p>• {data.name} ({data.type})</p>
                    })

                    return detail
                }
            },
            // {
            //     name: '',
            //     width: '200px',
            //     cell: (row, i) => {
            //         let buttons = [
            //             {
            //                 text: "",
            //                 title: t("common.edit"),
            //                 color: "blue",
            //                 onClick: () => { this.onOpenModelAction("UPDATE", row, i) },
            //                 iconClass: "edit",
            //                 size: "mini"
            //             },
            //             {
            //                 text: "",
            //                 title: t("common.delete"),
            //                 color: "red",
            //                 iconClass: "trash",
            //                 size: "mini",
            //                 onClick: () => this.onRemoveAction(actions, i)
            //             }
            //         ]

            //         return <div className="box-action-table" >
            //             {
            //                 buttons.map((b, i) => {
            //                     return <ViewButton
            //                         key={i}
            //                         model={new ButtonModel(b)}
            //                     />
            //                 })
            //             }
            //         </div>
            //     },
            //     right: true
            // },
        ]
        return columns
    }

    render() {
        try {
            const { t } = this.props
            const { model, isLoading } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ViewTable
                                    columns={this.getColumnConfigAction()}
                                    datas={model.actions}
                                    pagination={false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            {/*      <ViewButton
                                model={new ButtonModel({
                                    text: t("common.next"),
                                    color: "teal",
                                    // disabled: (!model.serviceId || model.serviceId === defaultValue.PLEASE_SELECT) || !isNext,
                                    onClick: this.onSubmit
                                })}
                            /> */}
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.create'),
                                    color: "teal",
                                    onClick: () => this.onCreate()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t('common.edit'),
                                    color: "green",
                                    onClick: () => this.onEdit()
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/eventcontrolrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

ActionInformationView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ActionInformationView)