
import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import BoxModalForTimeSlots from '../common/BoxModalForTimeSlots'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'
import ViewTreeSelect from '../../core/view/common/ViewTreeSelect'

class ModalActionLocation extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true


        this.state.actionLocationIds = []

        this.state.modalStatus = "CREATE"

        if (!!props.model) {
            this.state.actionLocationIds = [props.model.id]
            this.state.modalStatus = "UPDATE"
        }
    }

    handleChange = (e, { name, value }) => {
        let actionLocation = this.state.actionLocationIds
        //actionLocation[0] = value
        actionLocation = value

        this.setState({
            actionLocationIds: actionLocation
        })
    }

    onSubmit = () => {
        const { optionsTreeLocation } = this.props
        const { actionLocationIds } = this.state

        const find = (array = [], value) => {
            for (const item of array) {
                const result = item.value === value ? item : find(item.children, value);
                if (result) return result;
            }
        };

        const result = actionLocationIds.map(data => find(optionsTreeLocation, data))
        //const result = find(optionsTreeLocation, actionLocationIds[0]);

        this.props.onSubmit(result)
    }

    isDisabledSubmit = (actionLocationIds) => {
        return !(!!actionLocationIds[0])
    }

    render() {
        try {
            const { t, onClose, optionsTreeLocation, locationFieldName } = this.props
            const { actionLocationIds, modalStatus } = this.state

            let wording = ""
            let buttons = []

            if (modalStatus === "CREATE") {
                switch (locationFieldName) {
                    case "source.locations":
                        wording = t("eventControlRule.addLocation")
                        break;

                    case "source.areaLocations":
                        wording = t("eventControlRule.addAreaLocation")
                        break;

                    default:
                        wording = t("eventControlRule.addLocation")
                        break;
                }

                buttons = [
                    new ButtonModel(
                        {
                            text: t("common.add"),
                            color: "green",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(actionLocationIds)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            } else if (modalStatus === "UPDATE") {
                switch (locationFieldName) {
                    case "source.locations":
                        wording = t("eventControlRule.editLocation")
                        break;

                    case "source.areaLocations":
                        wording = t("eventControlRule.editAreaLocation")
                        break;

                    default:
                        break;
                }

                buttons = [
                    new ButtonModel(
                        {
                            text: t("common.edit"),
                            color: "blue",
                            onClick: this.onSubmit,
                            disabled: this.isDisabledSubmit(actionLocationIds)
                        }
                    ),
                    new ButtonModel(
                        {
                            text: t("common.close"),
                            color: "grey",
                            onClick: onClose
                        }
                    )
                ]
            }


            return this.getModalContent(this.getModalOption({
                headerText: wording,
                onClose: () => { },
                content: <Form>
                    <BoxModalForTimeSlots
                        content={
                            <React.Fragment>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t("location.title")} isRequire={false} />
                                            <ViewTreeSelect
                                                label={t("location.title")}
                                                name="actionLocationIds"
                                                datas={optionsTreeLocation}
                                                value={actionLocationIds}
                                                onChange={this.handleChange}
                                                placeholder={t("common.pleaseSelect")}
                                                multiple={true}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </React.Fragment>
                        }
                    />
                </Form>,
                buttons: buttons
            }))
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalActionLocation.defaultProps = {
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
    optionsTreeLocation: [],
}

export default (translate(ModalActionLocation))