
import mongoose from 'mongoose'
import { setBaseSchema } from '../../core/schema/Schema'

const Schema = mongoose.Schema

const actionDatasSchema = new Schema({
	id: { type: String, default: "" },
	code: { type: String, default: "" },
	name: { type: String, default: "" },
	type: { type: String, default: "" },
	//ModelCode: { type: String, default: "" },
}, { _id: false })

const actionSchema = new Schema({
	type: { type: String, default: "" },
	status: { type: String, default: "OFF" },
	datas: [{ type: actionDatasSchema }],
}, { _id: false })

const EventControlRuleActionSchema = new Schema({
	actions: [{ type: actionSchema }],
})

setBaseSchema(EventControlRuleActionSchema, "event_control_rule_action")


const EventControlRuleActionModel = mongoose.model("event_control_rule_action", EventControlRuleActionSchema, "event_control_rule_action")

export default EventControlRuleActionModel
