
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import SensorRuleActionForm from './SensorRuleActionForm'
import ButtonModel from '../../core/model/ButtonModel'
import SensorRuleActionModel from '../../models/SensorRuleActionModel'
import MyPageSensorRuleAction from './MyPageSensorRuleAction'
import { defaultValue } from '../../../constant'

class ModalCreateSensorRuleAction extends MyPageSensorRuleAction {
    constructor(props) {
        super(props)

        let model = new SensorRuleActionModel()
            , modalStatus = "CREATE"
        if (!!props.model) {
            model.setData(props.model)
            modalStatus = "UPDATE"
        }

        this.state.model = model
        this.state.modalStatus = modalStatus

        this.state.modelErrors = []
        this.state.isLoading = false

        this.isTest = null
    }

    onSubmit = (model) => {
        this.props.onAddAction(model)
    }

    onBack = () => {
    }

    onTestSystemIntegration = () => {
        return this.props.onTestSystemIntegration(this.state.model)
    }

    render() {
        try {
            const { t, onClose, optionsSensorRuleActionType, optionsSensorRuleLocationType,
                sensorRuleResourceList, locationList, sceneList, getScene, optionsNotification, optionsDataType, optionsSensorType, sensorRuleActionResult, chosenType } = this.props
            const { model, modelErrors, modalStatus } = this.state

            let wording = ""
            let buttonModal = null

            let optionsSensorRuleActionResult = []

            let optionsScene = [{
                key: defaultValue.NONE_SELECT,
                value: defaultValue.NONE_SELECT,
                text: t("common.noneSelect")
            }]

            optionsScene = sceneList.concat(optionsScene)

            if (!!chosenType && !!optionsSensorRuleActionResult) {
                if (Array.isArray(sensorRuleActionResult[chosenType])) {
                    optionsSensorRuleActionResult = sensorRuleActionResult[chosenType].map(d => {
                        return {
                            key: d,
                            value: d,
                            text: d
                        }
                    })
                }
            }

            if (modalStatus === "CREATE") {
                wording = t("sensorRule.sensorRuleAddAction")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.add"),
                        color: "green",
                        onClick: () => this.onValidate(() => this.onSubmit(model))
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            } else if (modalStatus === "UPDATE") {
                wording = t("sensorRule.sensorRuleEditAction")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        onClick: () => this.onValidate(() => this.onSubmit(model))
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {this.getModalContent(this.getInfoModalOption({
                        open: true,
                        scrolling: true,
                        size: "small",
                        headerText: wording,
                        onClose: () => { },
                        content: <div>
                            <SensorRuleActionForm
                                model={model}
                                optionsSensorRuleActionType={optionsSensorRuleActionType}
                                optionsSensorRuleActionResult={optionsSensorRuleActionResult}
                                optionsSensorRuleLocationType={optionsSensorRuleLocationType}
                                sensorRuleResourceList={sensorRuleResourceList}
                                optionsNotification={optionsNotification}
                                optionsDataType={optionsDataType}
                                optionsSensorType={optionsSensorType}
                                locationList={locationList}
                                sensorRuleActionResult={sensorRuleActionResult}
                                handleChange={this.handleChange}
                                handleChangeMultiValue={this.handleChangeMultiValue}
                                errors={modelErrors}
                                onSubmitForm={() => this.onValidate(() => this.onSubmit(model))}

                                sceneList={optionsScene}
                                getScene={getScene}
                            />
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalCreateSensorRuleAction.defaultProps = {
    optionsSensorRuleActionType: [],
    optionsSensorRuleActionResult: [],
    optionsSensorRuleLocationType: [],
    optionsNotification: [],
    optionsDataType: [],
    optionsSensorType: [],
    sensorList: [],
    sensorRuleResourceList: [],
    locationList: [],
    onAddAction: () => console.error("Please provide \"Add\" action."),
    onClose: () => console.error("Please provide \"Close\" action."),

    sceneList: [],
    getScene: () => console.error("Please provide \"Get Scene\" action.")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ModalCreateSensorRuleAction)