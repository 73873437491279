
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const resetScheduleControlList = () => {
	return {
		type: type.RESET_SCHEDULE_CONTROL_LIST
	}
}

const getScheduleControlInfo = (id) => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/${id}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_INFO_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_INFO_FAILURE
			]
		}
	}
}

const getScheduleControlList = (query = "") => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol${query}`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_LIST_FAILURE
			]
		}
	}
}

const getRecurrenceTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/recurrencetype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_RECURRENCE_TYPE_LIST_FAILURE
			]
		}
	}
}

const getDayOfWeekList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/dayofweek`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_DAY_OF_WEEK_LIST_FAILURE
			]
		}
	}
}

const getScheduleControlLocationTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/location/locationtype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_LOCATION_TYPE_LIST_FAILURE
			]
		}
	}
}

const getScheduleControlResourceList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/location/resource`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_RESOURCE_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_RESOURCE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_RESOURCE_LIST_FAILURE
			]
		}
	}
}

const getScheduleControlHolidayTypeList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/schedulecontrol/holidaytype`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_REQUEST,
				{
					type: type.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_SCHEDULE_CONTROL_HOLIDAY_TYPE_LIST_FAILURE
			]
		}
	}
}


export {
	getScheduleControlInfo,
	getScheduleControlList,
	resetScheduleControlList,
	getRecurrenceTypeList,
	getDayOfWeekList,
	getScheduleControlLocationTypeList,
	getScheduleControlResourceList,
	getScheduleControlHolidayTypeList
}