
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getLocationList } from '../../actions/locationActions'
import { getIOTConfigInfo, getIOTConfigResourceList, getIOTConfigIOTList, getIOTConfigControllerList } from '../../actions/iotConfigActions'
import IOTConfigManager from '../../business/IOTConfigManager'
import utils from '../../core/utility/utils'
import IOTConfigUpdate from '../../views/iotConfig/IOTConfigUpdate'

class UpdateIOTConfigController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bIOTConfig = new IOTConfigManager(this)
	}

	getPropsResetStatus() {
		return ["iotConfigInfo"]
	}

	onUpdateIOTConfig = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bIOTConfig.updateIOTConfig(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result === true) {
					action = this.props.history.push(`/iotconfig/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	getOptionsLocation = (Location) => {
		try {
			var options = []
			if (Array.isArray(Location) && Location.length > 0) {
				options = Location.map(loca => {
					return {
						key: loca.id,
						value: loca.id,
						text: loca.name
					}
				})
			}
			return options
		} catch (error) {
			return []
		}
	}

	render() {
		try {
			const { t, iotConfigInfo, iotConfigControllerList, locationList, iotConfigResourceList, iotConfigIOTList } = this.props
			const { isLoadingComponent } = this.state
			
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}

			const iotConfig = iotConfigInfo.getData()
				, controllerDatas = iotConfigControllerList.getDatas()
				, optionsControllerList = iotConfigControllerList.getDatasOption()
				, optionsLocationList = locationList.getDatasOption() 
				, optionsResourceList = iotConfigResourceList.getDatasOption()
				, optionsIOTList = iotConfigIOTList.getDatasOption()

			let optionsTypeList = [
				{
					key: "LOCATION",
					value: "LOCATION",
					text: t(`constantTranslate.iotConfigLocation.LOCATION`)
				},
				{
					key: "RESOURCE",
					value: "RESOURCE",
					text: t(`constantTranslate.iotConfigLocation.RESOURCE`)
				},
			]

			return (
				<React.Fragment>
					{super.render()}
					<IOTConfigUpdate
						isLoadingControl={isLoadingComponent}
						model={iotConfig}
						controllerList={controllerDatas}
						optionsControllerList={optionsControllerList}
						optionsLocationList={optionsLocationList}
						optionsResourceList={optionsResourceList}
						optionsIOTList={optionsIOTList}
						optionsTypeList={optionsTypeList}
						onUpdate={this.onUpdateIOTConfig}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			iotConfigControllerList: state.iotConfigControllerList,
			iotConfigInfo: state.iotConfigInfo,
			locationList: state.locationList,
			iotConfigResourceList: state.iotConfigResourceList,
			iotConfigIOTList: state.iotConfigIOTList
		}),
		(dispatch, props) => ({
			getIOTConfigControllerList() {
				dispatch(getIOTConfigControllerList())
			},
			getIOTConfigInfo() {
				dispatch(getIOTConfigInfo(utils.getParamFromProps(props, "id")))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getIOTConfigResourceList() {
				dispatch(getIOTConfigResourceList())
			},
			getIOTConfigIOTList() {
				dispatch(getIOTConfigIOTList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getIOTConfigControllerList()
			this.props.getIOTConfigInfo()
			this.props.getLocationList()
			this.props.getIOTConfigResourceList()
			this.props.getIOTConfigIOTList()
		}
	}),
)


export default enhance(UpdateIOTConfigController)