
import allRoute from './routes'
import React from 'react'
import { Switch } from 'react-router-dom'

let routes = [
    allRoute
]

try {
    for (let route of routes) {
        route.module = (route.module ? "/" + route.module : "")
    }
} catch (error) {

}

let key = 1
let routesGenerate = []

const generateRoutesModule = (configs) => {
    let routes = []
    for (let r of configs) {
        try {
            for (let config of r.routeConfigs) {
                try {
                    let path = (r.module ? "/" + r.module.toLowerCase() : "") + (config.path[0] === "/" ? config.path : !!config.path ? "/" + config.path : "")
                        , route = generateRoute(path, config)

                    if (route) {
                        routes.push(route)
                    }
                } catch (error) {

                }
            }
        } catch (error) {

        }
    }

    return routes
}

const generateRoute = (path, config) => {
    let result = null
    if (path) {
        if (config.exact) {
            result = <config.route path={path} component={config.component} key={key++} exact />
        } else {
            result = <config.route path={path} component={config.component} key={key++} />
        }
    } else {
        if (config.exact) {
            result = <config.route component={config.component} key={key++} exact />
        } else {
            result = <config.route component={config.component} key={key++} />
        }
    }

    return result
}

routesGenerate = routesGenerate.concat(generateRoutesModule(routes))

const ComponentSwitch = () => {
    return <Switch>
        {routesGenerate}
    </Switch>
}

export default ComponentSwitch