
import React from 'react'
// import moment from 'moment'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { translate } from 'react-switch-lang'

import { Container, Form, Grid, Segment } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'
import { defaultValue } from '../../../../constant'
import LabelInput from '../../common/LabelInput'
import ViewNoResult from '../../common/ViewNoResult'
import InputSelectDepartment from '../../department/InputSelectDepartment'

class ViewSelectInformation extends MyPage {
    constructor(props) {
        super(props)
        this.state.model = {
            departmentId: defaultValue.PLEASE_SELECT,
            resourceGroupIds: [],
        }
    }

    handleChange = (e, { name, value, checked }) => {
        let model = this.state.model
        if (name === "select_all") {
            if (checked === true) {
                model.resourceGroupIds = this.props.resourceGroupList.map(d => d.id)
            } else {
                model.resourceGroupIds = []
            }
        } else if (name === "select_group") {
            let index = model.resourceGroupIds.findIndex(id => id === value)
            if (checked === true) {
                if (index === -1) {
                    model.resourceGroupIds.push(value)
                }
            } else {
                if (index > -1) {
                    model.resourceGroupIds.splice(index, 1)
                }
            }
        } else {
            model[name] = value
        }
        this.setState({ model })
    }

    render() {
        try {
            const { t, resourceGroupList, onNext, onBack } = this.props
            const { model } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    <Container text>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("department.title")} isRequire={true} />
                                    <InputSelectDepartment
                                        name="departmentId"
                                        placeholder={t("common.pleaseSelect")}
                                        onChange={this.handleChange}
                                        value={model.departmentId}
                                        initialDatas={[
                                            {
                                                id: defaultValue.PLEASE_SELECT,
                                                text: t("common.pleaseSelect"),
                                                value: defaultValue.PLEASE_SELECT
                                            }
                                        ]}
                                    //error={departmentIdValidate.isError ? departmentIdValidate.content : null}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t('user.title')} isRequire={true} />

                                    <Form>
                                        <div className="module multipleCheckboxContainer">
                                            <Segment className="group-segment">
                                                {
                                                    Array.isArray(resourceGroupList) && resourceGroupList.length > 0 ?
                                                        <div className="checkbox-wrapper">
                                                            <Form.Checkbox
                                                                label={t("common.selectAll")}
                                                                name={"select_all"}
                                                                onChange={this.handleChange}
                                                                checked={resourceGroupList.length === model.resourceGroupIds.length}
                                                            />
                                                            {
                                                                resourceGroupList.map((data, i) => {
                                                                    let select = model.resourceGroupIds.find(id => id === data.id)
                                                                    return <Form.Checkbox
                                                                        key={i}
                                                                        label={data.name}
                                                                        name={"select_group"}
                                                                        value={data.id}
                                                                        onChange={this.handleChange}
                                                                        checked={!!select}
                                                                    />
                                                                })
                                                            }
                                                        </div>
                                                        : <div className="nodata-wrapper">
                                                            <ViewNoResult />
                                                        </div>
                                                }
                                            </Segment>
                                        </div>
                                    </Form>

                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.next"),
                                            color: "teal",
                                            disabled: (model.resourceGroupIds.length === 0 || !model.departmentId || model.departmentId === defaultValue.PLEASE_SELECT),
                                            onClick: () => onNext(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.cancel"),
                                            color: "grey",
                                            onClick: onBack
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Container>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

ViewSelectInformation.defaultProps = {
    resourceGroupList: [],
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    withRouter,
    translate,
)

export default enhance(ViewSelectInformation)