
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Form, Grid } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import inputUtils from '../../../core/utility/inputUtils'
import OnOffDropdown from './OnOffDropdown'
import LabelInput from '../LabelInput'

class BacnetForm extends MyForm {
    constructor(props) {
        super(props)

        this.state.value = { ...props.value }
        this.selfHandleForm = true
    }

    handleChangeValue = (e, { name, value }) => {
        let iotValue = this.state.value

        if (!!iotValue) {
            iotValue = {
                status: "OFF",
                temp: 25
            }
        }

        if (name === "onOff") {
            iotValue.status = value
        } else if (name === "temp") {
            iotValue.temp = value
        }

        this.setState({
            value: iotValue
        })

        this.props.onChange(null, { name: this.props.name, value: iotValue })
    }

    render() {
        try {
            const { t, disabled, value } = this.props

            let bacnetValue = {
                status: "OFF",
                temp: ""
            }

            if (!!value) {
                if (!!value.status) {
                    bacnetValue.status = value.status
                }

                if (!!value.temp && !isNaN(value.temp)) {
                    bacnetValue.temp = value.temp
                }
            }

            return (
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("common.status")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <OnOffDropdown
                                    name="onOff"
                                    value={bacnetValue.status}
                                    disabled={disabled}
                                    onChange={this.handleChangeValue}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <LabelInput text={t("common.temperature")} isRequire={false} />
                            </Grid.Column>
                            <Grid.Column width={16}>
                                <Form.Input
                                    name="temp"
                                    onKeyDown={inputUtils.onKeyNumber}
                                    value={bacnetValue.temp}
                                    onChange={this.handleChangeValue}
                                    disabled={disabled}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

BacnetForm.defaultProps = {
    name: "",
    type: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(BacnetForm)