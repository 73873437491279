
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'
import MyForm from '../../core/components/MyForm'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from '../common/LabelInput'

class ModalCardData extends MyForm {

    constructor(props) {
        super(props)
        this.selfHandleForm = true

        this.state.modalStatus = "CREATE"

        if (!!props.model) {
            this.state.type = !!props.model.type ? props.model.type : null
            this.state.value = !!props.model.value ? props.model.value : null
            this.state.modalStatus = "UPDATE"
        } else {
            this.state.type = ""
            this.state.value = ""
        }

        this.state.isLoading = false
    }

    handleChange = (e, { name, value }) => {

        if (name in this.state) {
            let state = this.state
            state[name] = value
            this.setState(state)
        }
    }

    onSubmit = () => {
        const { type, value } = this.state
        let obj = {
            type: type,
            value: value
        }
        if (type) {
            this.props.onSubmit(obj)
        }
    }

    render() {
        try {
            const { t, onClose, optionsRFIDCardDataType } = this.props
            const { type, value, modalStatus } = this.state

            let wording = ""
            let buttonModal = ""
            if (modalStatus === "CREATE") {
                wording = t("rfidCard.addCardData")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.add"),
                        color: "green",
                        disabled: (!type || !value),
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            } else if (modalStatus === "UPDATE") {
                wording = t("rfidCard.editCardData")
                buttonModal = [
                    new ButtonModel({
                        text: t("common.edit"),
                        color: "blue",
                        disabled: (!type || !value),
                        onClick: this.onSubmit
                    }),
                    new ButtonModel({
                        text: t("common.close"),
                        color: "grey",
                        onClick: onClose
                    })
                ]
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {this.getModalContent(this.getInfoModalOption({
                        onClose: () => { },
                        headerText: wording,
                        size: "tiny",
                        content: <div>
                            <Form onKeyDown={this.onKeyDownSubmitForm}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('common.type')} />
                                            <Form.Dropdown
                                                fluid
                                                selection
                                                selectOnBlur={false}
                                                value={type}
                                                options={optionsRFIDCardDataType}
                                                onChange={this.handleChange}
                                                name="type"
                                                placeholder={t("common.pleaseSelect")}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column width={16}>
                                            <LabelInput text={t('rfidCard.value')} isRequire={true} />
                                            <Form.Input
                                                name="value"
                                                value={value}
                                                onChange={this.handleChange}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Form>
                        </div>,
                        buttons: buttonModal
                    }))}
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalCardData.defaultProps = {
    optionsRFIDCardDataType: [],
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ModalCardData)