
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import MyController from '../../core/components/MyController'
import ViewLocationTypeList from '../../views/locationType/ViewLocationTypeList'
import { getLocationTypeList } from '../../actions/locationTypeActions'
import LocationTypeManager from '../../business/LocationTypeManager'

class LocationTypeController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bLocationType = new LocationTypeManager(this)
	}

	getPropsResetStatus() {
		return ["locationTypeList"]
	}

	onDeleteLocationType = (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, async () => {
			this.bLocationType.deleteLocationType(model, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }

				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}

				this.setState(body, action)
			})
		})
	}

	onSyncLocationType = (callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bLocationType.syncLocationType((res) => {
				let tempState = {
					isLoadingComponent: false
				},
					action = () => { }
				if (!!res && res.result === true) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(tempState, action)
			})
		})
	}

	getLocationTypeListPaging = (query) => {
		return this.bLocationType.getLocationTypeListPaging(query)
	}

	render() {
		try {
			const { isLoadingComponent } = this.state
			const { locationTypeList } = this.props

			const optionLocationType = locationTypeList.getDatasOption()

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<ViewLocationTypeList
						optionLocationTypeList={optionLocationType}
						onDelete={this.onDeleteLocationType}
						onSyncLocationType={this.onSyncLocationType}
						onGetDataPaging={this.getLocationTypeListPaging}
					/>

				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			locationTypeList: state.locationTypeList
		}),
		(dispatch, props) => ({
			getLocationTypeList(query) {
				dispatch(getLocationTypeList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationTypeList()
		}
	}),
)


export default enhance(LocationTypeController)