
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getGatewayList } from '../../actions/gatewayActions'
import GatewayManager from '../../business/GatewayManager'
import GatewayList from '../../views/gateway/GatewayList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue, onlineOffline } from '../../../constant'

class GatewayController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bGateway = new GatewayManager(this)
	}

	getPropsResetStatus() {
		return ["gatewayList"]
	}

	onChangeEnableGateway = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bGateway.enableGatewayStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableGateway = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bGateway.disableGatewayStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { t, gatewayList } = this.props
			const { isLoadingComponent } = this.state

			const gatewayDatas = gatewayList.getDatas()

			let optionsStatusList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				},
				{
					key: onlineOffline.ONLINE,
					value: onlineOffline.ONLINE,
					text: t("common.online")
				},
				{
					key: onlineOffline.OFFLINE,
					value: onlineOffline.OFFLINE,
					text: t("common.offline")
				}
			]

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<GatewayList
						isLoadingTable={!this.requestFinishedAfterRequest()}
						gatewayList={gatewayDatas}
						onReloadData={this.props.getGatewayList}
						optionsStatusList={optionsStatusList}
						onChangeEnableGateway={this.onChangeEnableGateway}
						onChangeDisableGateway={this.onChangeDisableGateway}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			gatewayList: state.gatewayList
		}),
		(dispatch) => ({
			getGatewayList(query) {
				dispatch(getGatewayList(query))
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getGatewayList()
		}
	}),
)


export default enhance(GatewayController)