
import ActionResourceObject from '../core/redux/ActionResourceObject'
import AccountModel from '../models/AccountModel'

export default class AccountResource extends ActionResourceObject {
    setResource(data) {
        try {
            if (!data) {
                throw new Error("Data not found")
            }

            let result = null
            if (Array.isArray(data)) {
				result = data.map(d => new AccountModel(d))
			} else {
				result = new AccountModel(data)
			}

            return result
        }
        catch (error) {
            return null
        }
    }
}