
import { CALL_API } from '../middlewares'
import { CORE_API_ENDPOINT } from '../lib'
import type from '../types'

const getAccountInfo = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/account`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ACCOUNT_INFO_REQUEST,
				{
					type: type.GET_ACCOUNT_INFO_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ACCOUNT_INFO_FAILURE
			]
		}
	}
}

const getAccountIntegrationList = () => {
	return {
		[CALL_API]: {
			endpoint: `${CORE_API_ENDPOINT}/account/integration/list`,
			method: 'GET',
			headers: { 'Content-Type': 'application/json' },
			types: [
				type.GET_ACCOUNT_INTEGRATION_LIST_REQUEST,
				{
					type: type.GET_ACCOUNT_INTEGRATION_LIST_SUCCESS,
					payload(action, state, res) {
						return res
							.json()
							.then(json => {
								return json
							})
					}
				},
				type.GET_ACCOUNT_INTEGRATION_LIST_FAILURE
			]
		}
	}
}


export {
	getAccountInfo,
	getAccountIntegrationList
}