
import ActionResourceObject from '../core/redux/ActionResourceObject'
import ExchangeIntegrationModel from '../models/ExchangeIntegrationModel'

export default class ExchangeIntegrationResource extends ActionResourceObject {

	setResource(data) {
		try {
			if (!data) {
				throw new Error("Data not found")
			}

			let result = null
			if (Array.isArray(data)) {
				result = data.map(d => new ExchangeIntegrationModel(d))
			} else {
				result = new ExchangeIntegrationModel(data)
			}

			return result
		} catch (error) {
			console.log(error)
			return null
		}
	}

}