
import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Container, Table, Header, Grid } from 'semantic-ui-react'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import HolidayModel from '../../models/HolidayModel'
import LabelInput from '../common/LabelInput'
import { dateTimeFormat } from '../../../constant'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewNoResult from '../common/ViewNoResult'

class HolidayView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
    }

    onCreate = () => {
        this.props.history.push(`/holiday/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/holiday/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/holiday`)
    }

    getColumnConfig() {
        const { t } = this.props

        const columns = [
            {
                name: t("common.type"),
                selector: (row) => row.type ? t(`constantTranslate.holidayDateType.${row.type}`) : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: t("common.time"),
                selector: (row, i) => {
                    if (row.type === "ALL_YEAR") {
                        let weekdayDisplay = row.weekday.map((d, i) => {
                            if (i === 0) {
                                return t(`constantTranslate.dayOfWeek.${d}`)
                            } else {
                                return ', ' + t(`constantTranslate.dayOfWeek.${d}`)
                            }
                        })

                        return weekdayDisplay
                    } else if (row.type === "RANGE") {
                        return moment(row.startDateTime).format(dateTimeFormat.displayDate) + ' - ' + moment(row.endDateTime).format(dateTimeFormat.displayDate)
                    } else if (row.type === "DAY") {
                        return moment(row.startDateTime).format(dateTimeFormat.displayDate)
                    }
                },
                sortable: false
            }
        ]

        return columns
    }

    render() {
        try {
            const { t } = this.props
            const { model } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )
            return (
                <React.Fragment>
                    {super.render()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('holiday.viewHoliday')} buttons={buttons}>
                        <Container text>
                            {
                                !model ?
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <ViewNoResult />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                    : <React.Fragment>
                                        <div>
                                            <Table striped celled>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.year')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.year ? model.year : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.subject')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.subject ? model.subject : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell width={4}><LabelInput text={t('common.description')} /></Table.Cell>
                                                        <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                    </Table.Row>
                                                </Table.Body>
                                            </Table>
                                            <br></br>
                                            <br></br>
                                            <Header as='h3' className="form header view-small-divider">
                                                {t("common.date")}
                                            </Header>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <ViewTable
                                                            columns={this.getColumnConfig()}
                                                            datas={model.dateInfo}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </div>
                                    </React.Fragment>
                            }
                        </Container>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment>
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

HolidayView.defaultProps = {
    model: new HolidayModel()
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(HolidayView)