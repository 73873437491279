
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import { ChromePicker } from 'react-color'

class IOTColorPicker extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true
    }

    handleChangeComplete = (color, event) => {
        let iotValue = {
            r: color.rgb.r,
            g: color.rgb.g,
            b: color.rgb.b
        }

        this.props.onChange(null, { name: this.props.name, value: iotValue })
    }

    render() {
        try {
            const { value } = this.props

            let colorValue = {
                r: 0,
                g: 0,
                b: 0
            }

            if (!!value) {
                if (!!value.r && (value.r >= 0 && value.r <= 255)) {
                    colorValue.r = value.r
                }

                if (!!value.g && (value.g >= 0 && value.g <= 255)) {
                    colorValue.g = value.g
                }

                if (!!value.b && (value.r >= 0 && value.r <= 255)) {
                    colorValue.b = value.b
                }
            }

            return (
                <React.Fragment>
                    <Grid>
                        <Grid.Row columns={2} style={{ alignItems: "center" }}>
                            <Grid.Column width={8}>
                                <Grid centered>
                                        <div>
                                            <div
                                                style={{
                                                    borderStyle: "solid",
                                                    borderWidth: `1px`,
                                                    width: `3em`,
                                                    background: !!colorValue ? `rgba(${colorValue.r}, ${colorValue.g}, ${colorValue.b}, 1)` : `rgba(0, 0, 0, 1)`,
                                                    height: "2.5em",
                                                }}
                                            >
                                            </div>
                                        </div>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <ChromePicker
                                    color={!!colorValue ? colorValue : { r: 0, g: 0, b: 0 }}
                                    disableAlpha={true}
                                    onChangeComplete={this.handleChangeComplete}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </React.Fragment >
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

IOTColorPicker.defaultProps = {
    name: "",
    value: "",
    disabled: false,
    onChange: () => console.error("Please provide onChange function")
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(IOTColorPicker)