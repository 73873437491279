
import React from 'react'
// import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Container, Header, Table, Icon } from 'semantic-ui-react'
// import { CORE_API_ENDPOINT } from '../../lib'

import MyPage from '../../core/components/MyPage'
import LayoutWithSidebarHeaderFooter from '../layout/LayoutWithSidebarHeaderFooter'
import ButtonModel from '../../core/model/ButtonModel'
import DeskModel from '../../models/DeskModel'
import LabelInput from '../common/LabelInput'
// import { dateTimeFormat } from '../../../constant'
import ViewTable from '../../core/view/common/table/ViewTable'
import ViewButton from '../common/ViewButton'
import ViewSelectArea from '../common/ViewSelectArea'
import ModalScene from '../../views/common/ModalScene'
import SceneModel from '../../models/SceneModel'
import MiniIOTModal from '../common/MiniIOTModal'
import ViewImageInfo from '../../core/view/common/ViewImageInfo'
import ViewNoResult from '../common/ViewNoResult'
import { cookiesKey } from '../../../constant'

class DeskView extends MyPage {
    constructor(props) {
        super(props)

        this.state.model = props.model
        this.state.addPoint = false

        this.state.isOpenSceneModal = false
        this.state.sceneStatus = null
        this.state.sceneModel = new SceneModel()
        this.state.openStatusModal = false

        this.state.secondModalId = null
        this.state.secondModalType = null
        this.state.secondModalValue = null
        this.state.secondModalDescription = null
        this.state.statusModalData = null

        this.state.modelArea = null
        this.state.areaStatus = null

        this.state.isOpenSyncPlatform = false
        this.state.resultSyncPlatform = false
    }

    onCreate = () => {
        this.props.history.push(`/desk/create`)
    }

    onEdit = (model) => {
        this.props.history.push(`/desk/update/${model.id}`)
    }

    onBack = () => {
        this.props.history.push(`/desk`)
    }

    onClickAddPoint = (status, model) => {
        this.setState({
            addPoint: true,
            modelArea: model,
            areaStatus: status
        })
    }

    onCreateArea = (modelArea) => {
        const { model } = this.props
        this.setState({
            addPoint: false
        }, this.props.onUpsertAreaDesk(model, {
            area: [
                modelArea.serializeAPI()
            ]
        }))
    }

    onCloseAddPoint = () => {
        this.setState({
            addPoint: false
        })
    }

    onOpenSceneModal = (sceneModel, sceneStatus) => {

        if (sceneStatus === "CREATE") {
            sceneModel = new SceneModel()
        }

        this.setState({
            isOpenSceneModal: true,
            sceneStatus: sceneStatus,
            sceneModel: sceneModel
        })
    }

    onCloseSceneModal = () => {
        this.setState({
            isOpenSceneModal: false,
            sceneStatus: null
        })
    }

    onSceneSubmit = (SceneModel) => {
        const { sceneStatus } = this.state

        if (sceneStatus === "CREATE") {
            this.onCreateSceneModel(SceneModel)
        } else if (sceneStatus === "UPDATE") {
            this.onUpdateSceneModel(SceneModel)
        }

    }

    onCreateSceneModel = (NewSceneModel) => {
        const { model } = this.props

        this.setState({
            isOpenSceneModal: false,
            sceneStatus: null,
            sceneModel: new SceneModel()
        }, this.props.onCreateSceneDesk(model.id, NewSceneModel.serializeAPI()))
    }

    onUpdateSceneModel = (NewSceneModel) => {
        const { model } = this.props

        this.setState({
            isOpenSceneModal: false,
            sceneStatus: null,
            sceneModel: new SceneModel()
        }, this.props.onUpdateSceneDesk(model.id, NewSceneModel.id, NewSceneModel.serializeAPI()))
    }

    onClickControl = (status, iotId) => {
        const { model } = this.props

        if (status === "MODAL") {
            this.setState({
                openStatusModal: true,
                secondModalId: iotId.id,
                secondModalType: iotId.iotCode,
                secondModalValue: iotId.status,
                secondModalDescription: iotId.description
            })
        } else {
            this.props.onControlStatus(model.id, iotId, status)
        }

    }

    onChangeStatusModal = (e, { name, value }) => {
        this.setState({
            statusModalData: value
        })
    }

    onCloseStatusModal = () => {
        this.setState({
            openStatusModal: false
        })
    }

    onSubmitStatusModal = (miniIotValue) => {
        const { model } = this.props
        const { secondModalId } = this.state

        this.setState({
            openStatusModal: false
        })

        this.props.onControlStatus(model.id, secondModalId, miniIotValue)
    }

    turnOnScene = (scene) => {
        const { t } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.turnOnScene"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.yes"),
                                color: "green",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.turnOnSceneDesk(scene.id)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    onConfirmAreaDelete = (areaInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onDeleteAreaDesk(model, areaInfo)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    onConfirmSceneDelete = (SceneInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onDeleteSceneDesk(model.id, SceneInfo.id)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    onConfirmChange = (statusText, callBack) => {
        const { t } = this.props

        let contentText = ""

        switch (statusText) {
            case "ON":
                contentText = t("message.confirmTurnOn")
                break;
            case "OFF":
                contentText = t("message.confirmTurnOff")
                break;
            case "UP":
                contentText = t("message.confirmCurtainUp")
                break;
            case "STOP":
                contentText = t("message.confirmCurtainStop")
                break;
            case "DOWN":
                contentText = t("message.confirmCurtainDown")
                break;
            default:
                contentText = ""
                break;
        }

        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: contentText,
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            if (typeof callBack === "function") {
                                                callBack()
                                            }
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    getColumnConfig() {
        const { t, locationList } = this.props
        const columns = [
            {
                name: t("location.title"),
                selector: (row, i) => {
                    if (row.locationId === null) {
                        return ""
                    } else {
                        let locationFind = locationList.find(e => e.id === row.locationId)
                        if (!!locationFind) {
                            return locationFind.name
                        } else {
                            return "-"
                        }

                    }

                },
                sortable: false
            },
            {
                name: t("locationType.title"),
                selector: (row) => row.locationTypeCode ? row.locationTypeCode : '-',
                sortable: false,
                width: '200px'
            },
            {
                name: '',
                width: '250px',
                cell: (row) => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => { this.onClickAddPoint("UPDATE", row) },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmAreaDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getSceneColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.code"),
                selector: (row) => row.code ? row.code : '-',
                sortable: false,
                width: '150px'
            },
            {
                name: t("common.name"),
                selector: (row) => row.name ? row.name : '-',
                sortable: false,
                width: '180px'
            },
            {
                name: t("common.description"),
                selector: (row) => row.description ? row.description : '-',
                sortable: false
            },
            {
                name: '',
                width: '150px',
                cell: (row, i) => {
                    let buttons = [
                        {
                            text: t("common.onIcon"),
                            color: "green",
                            onClick: () => {
                                this.turnOnScene(row)
                            },
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.edit"),
                            color: "blue",
                            onClick: () => {
                                this.onOpenSceneModal(row, "UPDATE")
                            },
                            iconClass: "edit",
                            size: "mini"
                        },
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmSceneDelete(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <div className="table-button">
                                    <ViewButton
                                        key={i}
                                        model={new ButtonModel(b)}
                                    />
                                </div>
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getIOTColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.description"),
                selector: (row) => {
                    return row.description ? row.description : row.iotCode ? row.iotCode : "-"
                },
                sortable: false
            },
            {
                name: t("common.status"),
                cell: (row) => {
                    if (row.iotCode === "LIGHT_RGB" || row.iotCode === "LIGHT_DMX") {
                        return <div style={{
                            borderStyle: "solid",
                            borderWidth: `1px`,
                            width: "2em",
                            height: "1em",
                            backgroundColor: `rgba(${row.status.r}, ${row.status.g}, ${row.status.b}, 1)`
                        }}>
                        </div>

                    } else if (row.iotCode === "BACNET") {
                        return <div>
                            <p>{`${t("common.status")}: ${row.status.status}`}</p>
                            <p>{`${t("common.temperature")}: ${row.status.temp}`}</p>
                        </div>
                    } else {
                        return row.status
                    }
                },
                sortable: false,
                width: '100px'
            },
            {
                name: '',
                width: '200px',
                cell: row => {
                    let buttons = []

                    if (row.iotCode === "CURTAIN") {
                        buttons = [
                            {
                                text: t("common.upIcon"),
                                color: "teal",
                                onClick: () => {
                                    this.onConfirmChange("UP", () => this.onClickControl("UP", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.stopIcon"),
                                color: "red",
                                onClick: () => {
                                    this.onConfirmChange("STOP", () => this.onClickControl("STOP", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.downIcon"),
                                color: "purple",
                                onClick: () => {
                                    this.onConfirmChange("DOWN", () => this.onClickControl("DOWN", row.id))
                                },
                                size: "mini"
                            }
                        ]
                    } else if (row.iotCode === "LIGHT") {
                        buttons = [
                            {
                                text: t("common.onIcon"),
                                color: "green",
                                onClick: () => {
                                    this.onConfirmChange("ON", () => this.onClickControl("ON", row.id))
                                },
                                size: "mini"
                            },
                            {
                                text: t("common.offIcon"),
                                color: "red",
                                onClick: () => {
                                    this.onConfirmChange("OFF", () => this.onClickControl("OFF", row.id))
                                },
                                size: "mini"
                            }
                        ]
                    } else {
                        buttons = [
                            {
                                text: t("common.control"),
                                color: "grey",
                                onClick: () => { this.onClickControl("MODAL", row) },
                                size: "mini"
                            }
                        ]
                    }

                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <div className="table-button">
                                    <ViewButton
                                        key={i}
                                        model={new ButtonModel(b)}
                                    />
                                </div>
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    getServiceColumnConfig() {
        const { t } = this.props
        const columns = [
            {
                name: t("common.service"),
                selector: (row) => row.service ? row.service : '-',
                sortable: false
            },
            {
                name: '',
                width: '300px',
                cell: row => {
                    let buttons = [
                        {
                            text: "",
                            title: t("common.delete"),
                            color: "red",
                            onClick: () => { this.onConfirmUnIntegrate(row) },
                            iconClass: "trash",
                            size: "mini"
                        }
                    ]
                    return < React.Fragment >
                        {
                            buttons.map((b, i) => {
                                return <ViewButton
                                    key={i}
                                    model={new ButtonModel(b)}
                                />
                            })
                        }
                    </React.Fragment >
                },
                right: true
            },
        ]

        return columns
    }

    onConfirmUnIntegrate = (serviceInfo) => {
        const { t, model } = this.props
        try {
            this.openWarningModal(
                {
                    headerText: t("message.areYouSure"),
                    content: t("message.confirmDelete"),
                    buttons: [
                        new ButtonModel(
                            {
                                text: t("common.ok"),
                                color: "red",
                                onClick: () => {
                                    this.setState(
                                        {
                                            isLoading: true
                                        },
                                        () => {
                                            this.closeModal()
                                            this.props.onUnintegtationDesk(model, serviceInfo)
                                        })
                                }
                            }
                        ),
                        new ButtonModel(
                            {
                                text: t("common.close"),
                                color: "grey",
                                onClick: () => {
                                    this.closeModal()
                                }
                            }
                        )
                    ]
                }
            )
        } catch (error) {
            this.setModalCatch(error)
        }
    }

    isDisabledSceneAdd = () => {
        const { deskIOTInfo } = this.props

        return !(Array.isArray(deskIOTInfo.iotConfigs) && deskIOTInfo.iotConfigs.length > 0)
    }

    onCheckSyncPlatform = () => {
        const { model } = this.props
        this.props.testSyncPlatformDesk(model.id, this.onSetOpenSyncPlatformChecker)
    }

    onSetOpenSyncPlatformChecker = (res) => {
        if (!!res && !!res.result) {
            this.setState({
                isOpenSyncPlatform: true,
                resultSyncPlatform: res.result
            })
        } else {
            this.setState({
                isOpenSyncPlatform: true,
            })
        }
    }

    onModalCheckSyncPlatformDisplay = () => {
        const { t } = this.props
        const { resultSyncPlatform } = this.state

        return this.getModalContent(this.getInfoModalOption({
            onClose: () => { },
            headerText: t("common.checkSyncPlatform"),
            size: "small",
            content: <div style={{ textAlign: "center" }}>
                {
                    resultSyncPlatform === true ?
                        <React.Fragment>
                            <Icon color="green" name="checkmark" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformSuccess")}</h3>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Icon color="red" name="close" size="huge" fitted />
                            <br></br>
                            <h3>{t("message.checkSyncPlatformFail")}</h3>
                        </React.Fragment>
                }
            </div>,
            buttons: [
                new ButtonModel({
                    text: t('common.close'),
                    color: "grey",
                    onClick: () => this.setState({ isOpenSyncPlatform: false })
                })
            ]
        }))
    }

    render() {
        try {
            const { t, locationList, locationInfo, deskIOTInfo, deskSceneInfo } = this.props
            const { model, addPoint, isOpenSceneModal, sceneModel, sceneStatus, openStatusModal, secondModalType, secondModalValue, secondModalDescription, modelArea, areaStatus, isOpenSyncPlatform } = this.state

            let buttons = []
            if (!!model) {
                buttons.push(
                    new ButtonModel({
                        text: t('common.checkSyncPlatform'),
                        color: "orange",
                        onClick: () => this.onCheckSyncPlatform()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.create'),
                        color: "teal",
                        onClick: () => this.onCreate()
                    })
                )
                buttons.push(
                    new ButtonModel({
                        text: t('common.edit'),
                        color: "green",
                        onClick: () => this.onEdit(model)
                    })
                )
            }
            buttons.push(
                new ButtonModel({
                    text: t('common.back'),
                    color: "grey",
                    onClick: this.onBack
                })
            )

            return (
                <React.Fragment >
                    {super.render()}
                    {openStatusModal && <MiniIOTModal type={secondModalType} iotDescription={secondModalDescription} value={secondModalValue} onClose={this.onCloseStatusModal} onSubmit={this.onSubmitStatusModal} />}
                    {isOpenSceneModal && <ModalScene model={sceneModel} viewType={sceneStatus} iotConfigList={deskIOTInfo.iotConfigs} onSubmit={this.onSceneSubmit} onClose={this.onCloseSceneModal} />}
                    {
                        addPoint &&
                        <ViewSelectArea
                            model={modelArea}
                            viewType={areaStatus}
                            locationList={locationList}
                            onSubmit={this.onCreateArea}
                            onClose={this.onCloseAddPoint}
                        />
                    }
                    {isOpenSyncPlatform && this.onModalCheckSyncPlatformDisplay()}
                    <LayoutWithSidebarHeaderFooter textHeader={t('desk.viewDesk')} buttons={buttons}>
                        <div className="module deskView">
                            <Container text>
                                {
                                    !model ?
                                        <Grid>
                                            <Grid.Row>
                                                <Grid.Column width={16}>
                                                    <ViewNoResult />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                        : <React.Fragment>
                                            <div className="desk-wrapper">
                                                <Table striped celled>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell width={4}><LabelInput text={t('common.code')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.code ? model.code : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('common.name')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.name ? model.name : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t("location.title")} /></Table.Cell>
                                                            <Table.Cell><p>{!!locationInfo ? locationInfo.name : model.locationId ? model.locationId : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><LabelInput text={t('common.description')} /></Table.Cell>
                                                            <Table.Cell><p>{!!model.description ? model.description : '-'}</p></Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                                <ViewImageInfo src={`desk/${model.id}`} />
                                                <br></br>
                                                <br></br>
                                                <Grid divided='vertically'>
                                                    <Grid.Row columns={2} className="remove-bottom-padding">
                                                        <Grid.Column width={8} textAlign="left" verticalAlign="bottom">
                                                            <Header as='h1'>{t("common.areaInfo")}</Header>
                                                        </Grid.Column>
                                                        <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                                            <ViewButton
                                                                model={new ButtonModel({
                                                                    text: t('common.add'),
                                                                    color: "green",
                                                                    onClick: () => this.onClickAddPoint("CREATE")
                                                                })}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getColumnConfig()}
                                                                datas={model.areas}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>

                                                </Grid>
                                                <br></br>
                                                <br></br>
                                                <Grid divided='vertically'>
                                                    <Grid.Row className="remove-bottom-padding">
                                                        <Grid.Column width={16}>
                                                            <Header as='h1'>
                                                                {t("iotConfig.title")}
                                                            </Header>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <ViewTable
                                                                columns={this.getIOTColumnConfig()}
                                                                datas={deskIOTInfo.iotConfigs}
                                                            />
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                                {
                                                    this.bCookie.getData(cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE) &&
                                                    <React.Fragment>
                                                        <br></br>
                                                        <br></br>
                                                        <Grid divided='vertically'>
                                                            <Grid.Row columns={2} className="remove-bottom-padding">
                                                                <Grid.Column width={8} textAlign="left" verticalAlign="bottom">
                                                                    <Header as='h1'>{t("common.scene")}</Header>
                                                                </Grid.Column>
                                                                <Grid.Column width={8} textAlign="right" verticalAlign="bottom">
                                                                    <ViewButton
                                                                        model={new ButtonModel({
                                                                            text: t('common.add'),
                                                                            color: "green",
                                                                            onClick: () => this.onOpenSceneModal(sceneModel, "CREATE"),
                                                                            disabled: this.isDisabledSceneAdd()
                                                                        })}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <ViewTable
                                                                        columns={this.getSceneColumnConfig()}
                                                                        datas={deskSceneInfo}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                                <br></br>
                                                <br></br>
                                                {
                                                    (!!model.integrationInfo && model.integrationInfo.length !== 0) &&
                                                    <React.Fragment>
                                                        <Grid divided='vertically'>
                                                            <Grid.Row className="remove-bottom-padding">
                                                                <Grid.Column width={16}>
                                                                    <Header as='h1'>
                                                                        {t("common.integrationInfo")}
                                                                    </Header>
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                            <Grid.Row>
                                                                <Grid.Column width={16}>
                                                                    <ViewTable
                                                                        columns={this.getServiceColumnConfig()}
                                                                        datas={model.integrationInfo}
                                                                    />
                                                                </Grid.Column>
                                                            </Grid.Row>
                                                        </Grid>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </React.Fragment>
                                }
                            </Container>
                        </div>
                    </LayoutWithSidebarHeaderFooter>
                </React.Fragment >
            )
        }
        catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

DeskView.defaultProps = {
    model: new DeskModel(),
    locationList: [],
    locationInfo: [],
    deskIOTInfo: [],
    deskSceneInfo: [],
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(DeskView)