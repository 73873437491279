
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getScheduleControlInfo, getRecurrenceTypeList, getDayOfWeekList, getScheduleControlResourceList, getScheduleControlLocationTypeList, getScheduleControlHolidayTypeList } from '../../actions/scheduleControlActions'
import ScheduleControlManager from '../../business/ScheduleControlManager'
import utils from '../../core/utility/utils'
import ScheduleControlUpdate from '../../views/scheduleControl/ScheduleControlUpdate'
import { getLocationList } from '../../actions/locationActions'

class UpdateScheduleControlController extends MyController {
	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bScheduleControl = new ScheduleControlManager(this)
	}

	getPropsResetStatus() {
		return ["scheduleControlInfo"]
	}

	onUpdateScheduleControl = async (model) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bScheduleControl.updateScheduleControl(model.id, model.serializeAPI(), (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					action = this.props.history.push(`/schedulecontrol/view/${model.id}`)
				}
				this.setState(body, action)
			})
		})
	}

	getResourceOptions = (optionsResourceList) => {
		try {
			var options = []
			if (Array.isArray(optionsResourceList) && optionsResourceList.length > 0) {
				options = optionsResourceList.map(op => {
					return {
						key: op.code,
						value: op.id,
						text: op.displayName
					}
				})
			}
			return options
		} catch (error) {
			return []
		}
	}

	getOptionsLocation = (Location) => {
		try {
			var options = []
			if (Array.isArray(Location) && Location.length > 0) {
				options = Location.map(loca => {
					return {
						key: loca.code,
						value: loca.id,
						text: loca.name
					}
				})
			}
			return options
		} catch (error) {
			return []
		}
	}

	render() {
		try {
			const { t, scheduleControlInfo, recurrenceTypeList, dayOfWeekList, locationList, scheduleResourceList, scheduleLocationTypeList, scheduleHolidayTypeList } = this.props
			const { isLoadingComponent } = this.state
			if (!this.requestFinishedAfterRequest()) {
				return this.getLoadingPage()
			}
			const scheduleControl = scheduleControlInfo.getData()
				, optionsRecurrenceType = recurrenceTypeList.getDatasOption()
				, optionsDayOfWeek = dayOfWeekList.getDatasOption()
				, optionsResourceList = this.getResourceOptions(scheduleResourceList.value)
				, optionsLocationList = this.getOptionsLocation(locationList.value)
				, optionsLocationTypeList = scheduleLocationTypeList.getDatasOption()
				, optionsHolidayTypeList = scheduleHolidayTypeList.getDatasOption()

			let holidayTypeTranslate = optionsHolidayTypeList.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.recurrenceHolidayType.${d.value}`)
				}
			})

			let recurrenceTypeTranslate = optionsRecurrenceType.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.recurrenceType.${d.value}`)
				}
			})

			let dayOfWeekTranslate = optionsDayOfWeek.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.dayOfWeek.${d.value}`)
				}
			})

			return (
				<React.Fragment>
					{super.render()}
					<ScheduleControlUpdate
						isLoadingControl={isLoadingComponent}
						model={scheduleControl}
						optionsRecurrenceType={recurrenceTypeTranslate}
						optionsDayOfWeek={dayOfWeekTranslate}
						optionsResourceList={optionsResourceList}
						optionsLocationList={optionsLocationList}
						optionsLocationTypeList={optionsLocationTypeList}
						optionsHolidayTypeList={holidayTypeTranslate}
						onUpdate={this.onUpdateScheduleControl}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state, props) => ({
			scheduleControlInfo: state.scheduleControlInfo,
			recurrenceTypeList: state.recurrenceTypeList,
			dayOfWeekList: state.dayOfWeekList,
			scheduleResourceList: state.scheduleResourceList,
			locationList: state.locationList,
			scheduleLocationTypeList: state.scheduleLocationTypeList,
			scheduleHolidayTypeList: state.scheduleHolidayTypeList
		}),
		(dispatch, props) => ({
			getScheduleControlInfo() {
				dispatch(getScheduleControlInfo(utils.getParamFromProps(props, "id")))
			},
			getRecurrenceTypeList() {
				dispatch(getRecurrenceTypeList())
			},
			getDayOfWeekList() {
				dispatch(getDayOfWeekList())
			},
			getScheduleControlResourceList() {
				dispatch(getScheduleControlResourceList())
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getScheduleControlLocationTypeList() {
				dispatch(getScheduleControlLocationTypeList())
			},
			getScheduleControlHolidayTypeList() {
				dispatch(getScheduleControlHolidayTypeList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getScheduleControlInfo()
			this.props.getRecurrenceTypeList()
			this.props.getDayOfWeekList()
			this.props.getScheduleControlResourceList()
			this.props.getLocationList()
			this.props.getScheduleControlLocationTypeList()
			this.props.getScheduleControlHolidayTypeList()
		}
	}),
)


export default enhance(UpdateScheduleControlController)