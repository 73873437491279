
import mongoose from 'mongoose'
import { setBaseSchema } from '../../../core/schema/Schema'

const Schema = mongoose.Schema

const DatabaseDataSchema = new Schema({
	collectionName: { type: String, required: [true, "validate.require"] },
	sensorType: { type: String, required: [true, "validate.require"] },
	type: { type: String, required: [true, "validate.require"] },
	detail: { type: String },
}, { _id: false })

setBaseSchema(DatabaseDataSchema)

DatabaseDataSchema.methods.serializeAPI = function () {
	try {
		let result = {
			collectionName: this.collectionName,
			sensorType: this.sensorType,
			type: this.type,
		}
		return result
	} catch (error) {
		return null
	}
}

const DatabaseDataModel = mongoose.model("sensor_rule_action_database", DatabaseDataSchema, "sensor_rule_action_database")


export default DatabaseDataModel
