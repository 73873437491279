
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getIOTConfigList, getIOTConfigResourceList, getIOTConfigControllerList } from '../../actions/iotConfigActions'
import { getLocationList } from '../../actions/locationActions'
import IOTConfigManager from '../../business/IOTConfigManager'
import IOTConfigList from '../../views/iotConfig/IOTConfigList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class IOTConfigController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bIOTConfig = new IOTConfigManager(this)
	}

	getPropsResetStatus() {
		return ["iotConfigList", "locationList", "iotConfigResourceList"]
	}

	onDeleteIOTConfig = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bIOTConfig.deleteIOTConfig(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableIOTConfig = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bIOTConfig.enableIOTConfigStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableIOTConfig = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bIOTConfig.disableIOTConfigStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { t, iotConfigList, locationList, iotConfigResourceList, iotConfigControllerList } = this.props
			const { isLoadingComponent } = this.state

			const iotConfigDatas = iotConfigList.getDatas()
			const optionLocation = locationList.getDatasOption()
				, optionsController = iotConfigControllerList.getDatasOption()
				, optionsResource = iotConfigResourceList.getDatasOption() 

			let optionsControllerList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			optionsControllerList = optionsControllerList.concat(optionsController)

			let optionLocationList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionLocationList = optionLocationList.concat(optionLocation)

			let optionResourceList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]
			optionResourceList = optionResourceList.concat(optionsResource)

			let optionsTypeList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				},
				{
					key: "LOCATION",
					value: "LOCATION",
					text: t(`constantTranslate.iotConfigLocation.LOCATION`)
				},
				{
					key: "RESOURCE",
					value: "RESOURCE",
					text: t(`constantTranslate.iotConfigLocation.RESOURCE`)
				},
			]

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<IOTConfigList
						isLoadingTable={!this.requestFinishedAfterRequest()}
						iotConfigList={iotConfigDatas}
						optionLocationList={optionLocationList}
						onReloadData={this.props.getIOTConfigList}
						onDeleteIOTConfig={this.onDeleteIOTConfig}
						onChangeEnableIOTConfig={this.onChangeEnableIOTConfig}
						onChangeDisableIOTConfig={this.onChangeDisableIOTConfig}
						optionsTypeList={optionsTypeList}
						optionResourceList={optionResourceList}
						optionsControllerList={optionsControllerList}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			iotConfigControllerList: state.iotConfigControllerList,
			iotConfigList: state.iotConfigList,
			locationList: state.locationList,
			iotConfigResourceList: state.iotConfigResourceList
		}),
		(dispatch) => ({
			getIOTConfigControllerList() {
				dispatch(getIOTConfigControllerList())
			},
			getIOTConfigList(query) {
				dispatch(getIOTConfigList(query))
			},
			getLocationList() {
				dispatch(getLocationList())
			},
			getIOTConfigResourceList() {
				dispatch(getIOTConfigResourceList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			// this.props.getIOTConfigList()
			this.props.getIOTConfigControllerList()
			this.props.getLocationList()
			this.props.getIOTConfigResourceList()
		}
	}),
)


export default enhance(IOTConfigController)