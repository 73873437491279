
import React from 'react'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
import SensorModel from '../../models/SensorModel'
import LabelInput from '../common/LabelInput'
import ViewButton from '../common/ViewButton'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class FormSearchSensor extends MyForm {
    constructor(props) {
        super(props)
        this.selfHandleForm = true
        this.state.model = new SensorModel()
        this.state.model.location = {
            id: defaultValue.SELECT_ALL,
            type: defaultValue.SELECT_ALL
        }
        //this.state.model.locationId = defaultValue.SELECT_ALL
        this.state.model.sensorType = defaultValue.SELECT_ALL
        this.state.model.sensorModel = defaultValue.SELECT_ALL
    }

    onClickClear = () => {
        let model = new SensorModel({ sensorType: defaultValue.SELECT_ALL, sensorModel: defaultValue.SELECT_ALL, sensorSN: "", description: "", location: { id: defaultValue.SELECT_ALL, type: defaultValue.SELECT_ALL } })
        this.setState({
            model: model
        }, () => {
            this.props.onSearch(null)
        })
    }

    onSubmitForm = () => {
        const { model } = this.state
        this.props.onSearch(model)
    }

    handleChangeLocation = (e, { name, value }) => {
        const { model } = this.state
        model.location.id = value

        this.setState({
            model: model
        })
    }

    handleChangeLocationType = (e, { name, value }) => {
        const { model } = this.state

        model.location.type = value

        this.setState({
            model: model
        })
    }

    render() {
        try {
            const { model } = this.state
            const { t, onSearch, optionsSensorTypeList, optionsSensorModelList, optionLocationResourceList, optionsSensorLocationTypeList } = this.props

            return (
                <div className="module formSearchSensor">
                    <Form onKeyDown={this.onKeyDownSubmitForm} autoComplete="off">
                        <Grid>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("sensor.type")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSensorTypeList}
                                        onChange={this.handleChange}
                                        name="sensorType"
                                        value={model.sensorType}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("sensor.model")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSensorModelList}
                                        onChange={this.handleChange}
                                        name="sensorModel"
                                        value={model.sensorModel}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("sensor.sn")} isRequire={false} />
                                    <Form.Input
                                        name="sensorSN"
                                        value={model.sensorSN}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3}>
                                <Grid.Column>
                                    <LabelInput text={t("locationType.title")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionsSensorLocationTypeList}
                                        onChange={this.handleChangeLocationType}
                                        name="locationType"
                                        value={model.location.type}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.location")} isRequire={false} />
                                    <Form.Dropdown
                                        fluid
                                        selection
                                        selectOnBlur={false}
                                        options={optionLocationResourceList}
                                        onChange={this.handleChangeLocation}
                                        name="locationId"
                                        value={model.location.id}
                                        placeholder={t("common.pleaseSelect")}
                                    />
                                </Grid.Column>
                                <Grid.Column>
                                    <LabelInput text={t("common.description")} isRequire={false} />
                                    <Form.Input
                                        name="description"
                                        value={model.description}
                                        onChange={this.handleChange}
                                        fluid
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row id="searchButton">
                                <Grid.Column width={16} textAlign="center">
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.search"),
                                            color: "blue",
                                            onClick: () => onSearch(model)
                                        })}
                                    />
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.clear"),
                                            color: "red",
                                            onClick: this.onClickClear
                                        })}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </div>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

FormSearchSensor.defaultProps = {
    onSearch: () => console.error('Please provide Search action.'),
    model: new SensorModel(),
    optionLocationList: []
}

export default (translate(FormSearchSensor))