
import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Form } from 'semantic-ui-react'

import MyForm from '../../../core/components/MyForm'
import LabelInput from '../../common/LabelInput'
import IOTInput from '../../common/IOTInputForm'
import AlertDashboardDataModel from '../../../models/sensorRuleData/actionData/AlertDashboardDataModel'

class AlertDashboardDataForm extends MyForm {
    render() {
        try {
            const { t, model } = this.props
            return (
                <React.Fragment>
                    {super.render()}
                    <Form>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={16}>
                                    <LabelInput text={t("sensorRule.alertDashboardColor")} isRequire={true} />
                                    <IOTInput
                                        name="color"
                                        type={"LIGHT_RGB"}
                                        value={model.color}
                                        onChange={this.handleChange}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

AlertDashboardDataForm.defaultProps = {
    model: new AlertDashboardDataModel({
        color: {
            r: 0,
            g: 0,
            b: 0
        }
    }),
    errors: [],
    handleChange: () => console.error("Please provide Handle Change action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(AlertDashboardDataForm)