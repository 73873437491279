import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import { translate } from 'react-switch-lang'

import { Grid, Menu } from 'semantic-ui-react'

import MyPage from '../../../core/components/MyPage'
import ActionInformationForm from './ActionInformationForm'
import ButtonModel from '../../../core/model/ButtonModel'
import ViewButton from '../../common/ViewButton'

class ActionInformationView extends MyPage {
    constructor(props) {
        super(props)
        this.state.models = Array.isArray(props.dataStep.actions) ? props.dataStep.actions : []
        this.state.chosenType = !!props.dataStep.type ? props.dataStep.type : []
        this.state.isLoading = false
    }

    // onSubmit = () => {
    //     this.onValidate(this.onNext)
    // }

    onBack = () => {
        this.props.onBack(this.state.models)
    }

    onNext = () => {
        this.props.onNext(this.state.models)
    }

    onCancel = () => {
        this.props.onCancel()
    }

    render() {
        try {
            const { t, dataConfig } = this.props
            const { models, isLoading, chosenType } = this.state

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <ActionInformationForm
                                    chosenType={chosenType}
                                    models={models}
                                    handleChange={this.handleChange}
                                    optionsSensorRuleActionType={dataConfig.optionsSensorRuleActionType}
                                    sensorRuleActionResult={dataConfig.sensorRuleActionResult}
                                    sensorRuleResourceList={dataConfig.sensorRuleResourceList}
                                    optionsSensorRuleActionResult={dataConfig.optionsSensorRuleActionResult}
                                    optionsSensorRuleLocationType={dataConfig.optionsSensorRuleLocationType}
                                    optionsNotification={dataConfig.optionsNotification}
                                    optionsDataType={dataConfig.optionsDataType}
                                    optionsSensorType={dataConfig.optionsSensorType}
                                    locationList={dataConfig.locationList}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {/*                     <Grid.Row>
                            <Grid.Column width={16} textAlign="center">
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.save"),
                                        color: "green",
                                        disabled: models.length === 0,
                                        onClick: this.onNext
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.back"),
                                        color: "yellow",
                                        onClick: this.onBack
                                    })}
                                />
                                <ViewButton
                                    model={new ButtonModel({
                                        text: t("common.cancel"),
                                        color: "grey",
                                        onClick: () => { this.props.history.push(`/greeting/greetingrule`) }
                                    })}
                                />
                            </Grid.Column>
                        </Grid.Row> */}
                    </Grid>
                    <br></br>
                    <br></br>
                    <br></br>
                    <Menu secondary fixed="bottom" className="lwshf-menu-bottom">
                        <div className="menu-bottom-wrapper">
                            {
                                dataConfig.stepStatus === "UPDATE" ?
                                    <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.save"),
                                            color: "green",
                                            disabled: models.length === 0,
                                            onClick: () => { this.onValidate(() => this.props.onSave(models)) }
                                        })}
                                    />
                                    : <ViewButton
                                        model={new ButtonModel({
                                            text: t("common.save"),
                                            color: "green",
                                            disabled: models.length === 0,
                                            onClick: this.onNext
                                        })}
                                    />
                            }

                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.back"),
                                    color: "yellow",
                                    onClick: this.onBack
                                })}
                            />
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.cancel"),
                                    color: "grey",
                                    onClick: () => { this.props.history.push(`/sensorrule`) }
                                })}
                            />
                        </div>
                    </Menu>
                </React.Fragment>
            )

        }
        catch (error) {
            return this.renderErrorComponent()
        }
    }
}

ActionInformationView.defaultProps = {
    dataConfig: null,
    dataStep: null,
    onBack: () => console.error("Please provide \"Back\" action."),
    onNext: () => console.error("Please provide \"Next\" action."),
    onCancel: () => console.error("Please provide \"Cancel\" action."),
}

const enhance = compose(
    translate,
    withRouter,
)

export default enhance(ActionInformationView)