
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'
import MyController from '../../core/components/MyController'
import ViewLocationList from '../../views/location/ViewLocationList'
import { getLocationList } from '../../actions/locationActions'
import LocationManager from '../../business/LocationManager'
import { getLocationTypeList } from '../../actions/locationTypeActions'

class LocationController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bLocation = new LocationManager(this)
    }

    getPropsResetStatus() {
        return ["locationList", "locationTypeList"]
    }

    onDeleteLocation = (model, callBack) => {
        this.setState({
            isLoadingComponent: true
        }, async () => {
            this.bLocation.deleteLocation(model, (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }

                if (!!res && res.result) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }

                this.setState(body, action)
            })
        })
    }

    onSyncLocation = (callBack) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bLocation.syncLocation((res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }
                if (!!res && res.result === true) {
                    if (typeof callBack === "function") {
                        action = callBack
                    }
                }
                this.setState(tempState, action)
            })
        })
    }

    getLocationListPaging = (query) => {
        return this.bLocation.getLocationListPaging(query)
    }

    render() {
        try {
            const { locationTypeList, locationList } = this.props
            const { isLoadingComponent } = this.state

            const optionLocation = locationList.getDatasOption()
                , optionLocationType = locationTypeList.getDatasOption()

            return (
                <React.Fragment>
                    {super.render()}
                    {(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
                    <ViewLocationList
                        optionLocationList={optionLocation}
                        optionLocationTypeList={optionLocationType}
                        onDelete={this.onDeleteLocation}
                        onSyncLocation={this.onSyncLocation}
                        onGetDataPaging={this.getLocationListPaging}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            locationTypeList: state.locationTypeList,
            locationList: state.locationList
        }),
        (dispatch, props) => ({
            getLocationTypeList(query) {
                dispatch(getLocationTypeList(query))
            },
            getLocationList(query) {
                dispatch(getLocationList(query))
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getLocationList()
            this.props.getLocationTypeList()
        }
    })
)


export default enhance(LocationController)