
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import {
    getSensorRuleTypeList, getConditionHolidayTypeList, getSensorRuleActionTypeList, getSensorRuleActionResultList,
    getSensorRuleLocationTypeList, getSensorRuleResourceList, getSensorRuleDataTypeList, getSensorRuleRecurrenceTypeList, getSensorRuleDayofWeekList
} from '../../actions/sensorRuleActions'
import { getLocationList } from '../../actions/locationActions'
import { getSensorList, getSensorTypeList } from '../../actions/sensorActions'
import { getNotificationList } from '../../actions/notificationActions'
import SensorRuleManager from '../../business/SensorRuleManager'
import ViewSensorRuleCreate from '../../views/sensorRuleStep/ViewSensorRuleCreate'
import { cookiesKey, sensorRuleActionType } from '../../../constant'

class CreateSensorRuleController extends MyController {
    constructor(props) {
        super(props)
        this.state.isLoadingComponent = false
        this.bSensorRule = new SensorRuleManager(this)
    }

    getPropsResetStatus() {
        return ["sensorList", "sensorRuleTypeList", "conditionHolidayTypeList",
            "sensorRuleActionTypeList", "sensorRuleActionResultList", "sensorRuleLocationTypeList", "sensorRuleResourceList",
            "locationList", "notificationList", "sensorRuleDataTypeList", "sensorTypeList"]
    }

    onCreateSensorRule = async (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bSensorRule.createSensorRule(model.serializeAPI(), (res) => {
                let body = {
                    isLoadingComponent: false
                }
                    , action = () => { }
                if (!!res && res.result === true) {
                    action = this.props.history.push(`/sensorrule/view/${res.data.id}`)
                }
                this.setState(body, action)
            })
        })
    }

    onClickBack = () => {
        this.props.history.push(`/sensorrule`)
    }

    render() {
        try {
            const { t, sensorRuleTypeList, sensorList, conditionHolidayTypeList, sensorRuleActionTypeList, sensorRuleActionResultList,
                sensorRuleLocationTypeList, sensorRuleResourceList, locationList, notificationList, sensorRuleDataTypeList, sensorTypeList, sensorRuleRecurrenceTypeList, sensorRuleDayofWeekList } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const sensorDatas = sensorList.getDatas()
                , locationDatas = locationList.getDatas()
                , sensorRuleResourceDatas = sensorRuleResourceList.getDatas()

            const sensorRuleActionResultData = sensorRuleActionResultList.getData()

            let optionsSensorRuleType = sensorRuleTypeList.getDatasOption()
                , optionsConditionHolidayType = conditionHolidayTypeList.getDatasOption()
                , optionsSensorRuleActionType = sensorRuleActionTypeList.getDatasOption()
                , optionsSensorRuleLocationType = sensorRuleLocationTypeList.getDatasOption()
                , optionsNotification = notificationList.getDatasOption()
                , optionsDataType = sensorRuleDataTypeList.getDatasOption()
                , optionsSensorType = sensorTypeList.getDatasOption()
                , optionsRecurrenceTypeList = sensorRuleRecurrenceTypeList.getDatasOption()
                , optionsDayofWeekList = sensorRuleDayofWeekList.getDatasOption()

            let sensorRuleTypeTranslate = optionsSensorRuleType.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.sensorRuleType.${d.value}`)
                }
            })

            optionsSensorRuleType = sensorRuleTypeTranslate.sort((a, b) => { return a.text.localeCompare(b.text) })

            let holidayTypeTranslate = optionsConditionHolidayType.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.recurrenceHolidayType.${d.value}`)
                }
            })

            let recurrenceTypeTranslate = optionsRecurrenceTypeList.map(d => {
				return {
					key: d.key,
					value: d.value,
					text: t(`constantTranslate.recurrenceType.${d.value}`)
				}
			})

			let dayOfWeekTranslate = optionsDayofWeekList.map(d => {
                return {
                    key: d.key,
                    value: d.value,
                    text: t(`constantTranslate.dayOfWeek.${d.value}`)
                }
            })

            if (this.bCookie.getData(cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE) === false) {
                optionsSensorRuleActionType = optionsSensorRuleActionType.filter(sensorRule => sensorRule.key !== sensorRuleActionType.ALERT_DASHBOARD)
            }

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <ViewSensorRuleCreate
                        isLoadingControl={isLoadingComponent}
                        sensorList={sensorDatas}
                        sensorRuleResourceList={sensorRuleResourceDatas}
                        locationList={locationDatas}
                        sensorRuleActionResult={sensorRuleActionResultData}
                        optionsSensorRuleType={optionsSensorRuleType}
                        optionsConditionHolidayType={holidayTypeTranslate}
                        optionsSensorRuleActionType={optionsSensorRuleActionType}
                        optionsSensorRuleLocationType={optionsSensorRuleLocationType}
                        optionsNotification={optionsNotification}
                        optionsDataType={optionsDataType}
                        optionsSensorType={optionsSensorType}
                        optionsRecurrenceTypeList={recurrenceTypeTranslate}
                        optionsDayofWeekList={dayOfWeekTranslate}
                        onSubmit={this.onCreateSensorRule}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    withRouter,
    connect(
        (state, props) => ({
            sensorList: state.sensorList,
            sensorRuleTypeList: state.sensorRuleTypeList,
            conditionHolidayTypeList: state.conditionHolidayTypeList,
            sensorRuleActionTypeList: state.sensorRuleActionTypeList,
            sensorRuleActionResultList: state.sensorRuleActionResultList,
            sensorRuleLocationTypeList: state.sensorRuleLocationTypeList,
            sensorRuleResourceList: state.sensorRuleResourceList,
            locationList: state.locationList,
            notificationList: state.notificationList,
            sensorRuleDataTypeList: state.sensorRuleDataTypeList,
            sensorTypeList: state.sensorTypeList,
            sensorRuleRecurrenceTypeList: state.sensorRuleRecurrenceTypeList,
            sensorRuleDayofWeekList: state.sensorRuleDayofWeekList
        }),
        (dispatch, props) => ({
            getSensorRuleTypeList() {
                dispatch(getSensorRuleTypeList())
            },
            getSensorList() {
                dispatch(getSensorList())
            },
            getConditionHolidayTypeList() {
                dispatch(getConditionHolidayTypeList())
            },
            getSensorRuleActionTypeList() {
                dispatch(getSensorRuleActionTypeList())
            },
            getSensorRuleActionResultList() {
                dispatch(getSensorRuleActionResultList())
            },
            getSensorRuleLocationTypeList() {
                dispatch(getSensorRuleLocationTypeList())
            },
            getSensorRuleResourceList() {
                dispatch(getSensorRuleResourceList())
            },
            getLocationList() {
                dispatch(getLocationList())
            },
            getNotificationList() {
                dispatch(getNotificationList())
            },
            getSensorRuleDataTypeList() {
                dispatch(getSensorRuleDataTypeList())
            },
            getSensorTypeList() {
                dispatch(getSensorTypeList())
            },
            getSensorRuleRecurrenceTypeList() {
                dispatch(getSensorRuleRecurrenceTypeList())
            },
            getSensorRuleDayofWeekList() {
                dispatch(getSensorRuleDayofWeekList())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getSensorList()
            this.props.getSensorRuleTypeList()
            this.props.getConditionHolidayTypeList()
            this.props.getSensorRuleActionTypeList()
            this.props.getSensorRuleActionResultList()
            this.props.getSensorRuleLocationTypeList()
            this.props.getSensorRuleResourceList()
            this.props.getLocationList()
            this.props.getNotificationList()
            this.props.getSensorRuleDataTypeList()
            this.props.getSensorTypeList()
            this.props.getSensorRuleRecurrenceTypeList()
            this.props.getSensorRuleDayofWeekList()
        }
    }),
)


export default enhance(CreateSensorRuleController)