import { cookiesKey, dateTimeFormat, enableService } from "../../constant"
import CookiesManager from "../core/business/CookiesManager"
import jwt from 'jsonwebtoken'
import moment from "moment"

export async function setVerifyCookies(verifyData, isRemember = false) {
    try {
        let bCookie = new CookiesManager()
        const { user, service, token, refreshToken, expiresDate, expiresOn, refreshTokenExpiresDate } = verifyData
        let datas = {}

        datas[cookiesKey.USER_ID] = user.id
        datas[cookiesKey.USER_EMAIL] = user.email
        datas[cookiesKey.USER_FULLNAME] = user.givenName + " " + user.sn
        datas[cookiesKey.USER_RESOURCE_TYPE_CODE] = user.resourceTypeCode

        datas[cookiesKey.TOKEN] = token
        datas[cookiesKey.REFRESH_TOKEN] = refreshToken
        datas[cookiesKey.TOKEN_EXPIRES_DATE] = expiresDate
        datas[cookiesKey.TOKEN_EXPIRES_ON] = expiresOn

        if (!!refreshToken) {
            if (!!refreshTokenExpiresDate) {
                datas[cookiesKey.REFRESH_TOKEN_EXPIRES_DATE] = refreshTokenExpiresDate
            } else {
                let decode = await jwt.decode(refreshToken)
                if (!!decode) {
                    let exp = moment.unix(decode.exp)
                    datas[cookiesKey.REFRESH_TOKEN_EXPIRES_DATE] = exp.format(dateTimeFormat.apiFullDateTime)
                }
            }
        }

        datas[cookiesKey.IS_REMEMBER] = isRemember

        // internal services
        let isEnablePlatformService = false
            , isEnableDashboardService = false
            , isEnableFrontendService = false
            , isEnableScheduleControlService = false
            , isEnableGatewaySocketService = false
            , isEnableSensorRuleService = false
            , isEnableEventControlRuleService = false

        if (!!service) {
            let internalService = Array.isArray(service.internalService) ? service.internalService : []
                , platformService = internalService.find(data => !!data && data.code === enableService.PLATFORM_SERVICE)
                , dashboardService = internalService.find(data => !!data && data.code === enableService.DASHBOARD_SERVICE)
                , frontendService = internalService.find(data => !!data && data.code === enableService.FRONTEND_SERVICE)
                , scheduleControlService = internalService.find(data => !!data && data.code === enableService.SCHEDULE_CONTROL_SERVICE)
                , gatewaySocketService = internalService.find(data => !!data && data.code === enableService.GATEWAY_SOCKET_SERVICE)
                , sensorRuleService = internalService.find(data => !!data && data.code === enableService.SENSOR_RULE_SERVICE)
                , eventControlRuleService = internalService.find(data => !!data && data.code === enableService.EVENT_CONTROL_SERVICE)

            isEnablePlatformService = (!!platformService && platformService.isEnable === true)
            isEnableDashboardService = (!!dashboardService && dashboardService.isEnable === true)
            isEnableFrontendService = (!!frontendService && frontendService.isEnable === true)
            isEnableScheduleControlService = (!!scheduleControlService && scheduleControlService.isEnable === true)
            isEnableGatewaySocketService = (!!gatewaySocketService && gatewaySocketService.isEnable === true)
            isEnableSensorRuleService = (!!sensorRuleService && sensorRuleService.isEnable === true)
            isEnableEventControlRuleService = (!!eventControlRuleService && eventControlRuleService.isEnable === true)
        }

        datas[cookiesKey.IS_ENABLE_PLATFORM_SERVICE] = isEnablePlatformService
        datas[cookiesKey.IS_ENABLE_DASHBOARD_SERVICE] = isEnableDashboardService
        datas[cookiesKey.IS_ENABLE_FRONTEND_SERVICE] = isEnableFrontendService
        datas[cookiesKey.IS_ENABLE_SCHEDULE_CONTROL_SERVICE] = isEnableScheduleControlService
        datas[cookiesKey.IS_ENABLE_GATEWAY_SOCKET_SERVICE] = isEnableGatewaySocketService
        datas[cookiesKey.IS_ENABLE_SENSOR_RULE_SERVICE] = isEnableSensorRuleService
        datas[cookiesKey.IS_ENABLE_EVENT_CONTROL_SERVICE] = isEnableEventControlRuleService

        // external services
        let externalService = []

        if (!!service) {
            externalService = Array.isArray(service.externalService) ? service.externalService : []
        }

        datas[cookiesKey.EXTERNAL_SERVICES] = externalService
        bCookie.setDatas(datas)
    } catch (error) {

    }
}

export function afterRefreshToken(verifyData, isRemember = false) {
    try {
        if (!verifyData) {
            throw new Error("Invalid verify data")
        }

        setVerifyCookies(verifyData, isRemember)
    } catch (error) {

    }
}
