
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import UserManager from '../../business/UserManager'
import ViewImportExcelUser from '../../views/user/ViewImportExcelUser'

class ImportExcelUserController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingData = false
        this.state.formatCodeList = []

        this.bUser = new UserManager(this)
    }

    componentDidMount() {
        this.onGetDatas()
    }

    onGetDatas = () => {
        this.setState({
            isLoadingData: true
        }, async () => {
            let res = await this.bUser.getImportFormatCodeExcel()
            let tempState = {
                isLoadingData: false,
                formatCodeList: res
            }
            this.setState(tempState)
        })
    }

    onGetExcelTemplate = (code) => {
        return this.bUser.getExcelTemplate(code)
    }

    onImportExcel = (model) => {
        return this.bUser.importExcel(model.serializeAPI())
    }

    render() {
        try {
            const { isLoadingData, formatCodeList, isLoading } = this.state
            if (isLoadingData || !this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }
            return (
                <React.Fragment>
                    {super.render()}
                    {isLoading && this.getLoadingPage()}
                    <ViewImportExcelUser
                        formatCodeList={formatCodeList}
                        onGetExcelTemplate={this.onGetExcelTemplate}
                        onImportExcel={this.onImportExcel}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
        }),
        (dispatch, props) => ({

        }),
    ),
    lifecycle({
        componentDidMount() {

        }
    })
)


export default enhance(ImportExcelUserController)