
import React from 'react'
import { translate } from 'react-switch-lang'
import utils from '../../utility/utils'

class ItemErrorFromAPI extends React.Component {

    render() {
        const { t, errors } = this.props
        let datas = []
        if (Array.isArray(errors)) {
            let token = utils.makeGroupedObject(errors, "code")
            for (let code in token) {
                datas.push({
                    code,
                    messages: token[code].map(t => t.message)
                })
            }
        }

        return (
            <div className="module itemErrorFromAPI">
                <div>
                    {
                        datas.map((e, i) => {
                            return <div >
                                <p><b>{t("common.code")}</b> {e.code ? e.code : "-"}</p>
                                <p><b>{t("common.message")}</b> {e.messages.join(", ")}</p>
                                {/* <div>
                                    <b>{t("common.message")}</b>
                                    {e.messages.map((m, i) => <p key={i}>{m}</p>)}
                                </div> */}
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
}

export default translate(ItemErrorFromAPI)