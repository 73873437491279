
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getSensorResourceList, getSensorTypeList, getSensorModelList, getSensorLocationTypeList } from '../../actions/sensorActions'
import { getLocationList } from '../../actions/locationActions'
import SensorManager from '../../business/SensorManager'
import SensorList from '../../views/sensor/SensorList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue } from '../../../constant'

class SensorController extends MyController {

	constructor(props) {
		super(props)

		this.state.isLoadingComponent = false
		this.bSensor = new SensorManager(this)
	}

	getPropsResetStatus() {
		return ["sensorList", "locationList", "sensorResourceList"]
	}

	onDeleteSensor = async (model, callBack) => {
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensor.deleteSensor(model.id, (res) => {
				let body = {
					isLoadingComponent: false
				}
					, action = () => { }
				if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeEnableSensor = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensor.enableSensorStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableSensor = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bSensor.disableSensorStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	getSensorListPaging = (query) => {
		return this.bSensor.getSensorListPaging(query)
	}

	render() {
		try {
			const { t, locationList, sensorResourceList, sensorTypeList, sensorModelList, sensorLocationTypeList } = this.props
			const { isLoadingComponent } = this.state

			const optionLocation = locationList.getDatasOption()
				, optionsResource = sensorResourceList.getDatasOption()
				, optionsSensorType = sensorTypeList.getDatasOption()
				, optionsSensorModel = sensorModelList.getDatasOption()
				, optionsSensorLocationType = sensorLocationTypeList.getDatasOption()

			let optionConcatList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			let optionsSensorTypeList = optionConcatList.concat(optionsSensorType)
			let optionsSensorModelList = optionConcatList.concat(optionsSensorModel)
			let optionLocationResourceList = optionLocation.concat(optionsResource)
			optionLocationResourceList = optionConcatList.concat(optionLocationResourceList)
			let optionsSensorLocationTypeList = optionConcatList.concat(optionsSensorLocationType)

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<SensorList
						optionsSensorTypeList={optionsSensorTypeList}
						optionsSensorModelList={optionsSensorModelList}
						optionLocationResourceList={optionLocationResourceList}
						optionsSensorLocationTypeList={optionsSensorLocationTypeList}
						onDeleteSensor={this.onDeleteSensor}
						onChangeEnableSensor={this.onChangeEnableSensor}
						onChangeDisableSensor={this.onChangeDisableSensor}
						onGetDataPaging={this.getSensorListPaging}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			locationList: state.locationList,
			sensorResourceList: state.sensorResourceList,
			sensorTypeList: state.sensorTypeList,
			sensorModelList: state.sensorModelList,
			sensorLocationTypeList: state.sensorLocationTypeList
		}),
		(dispatch) => ({
			getLocationList() {
				dispatch(getLocationList())
			},
			getSensorResourceList() {
				dispatch(getSensorResourceList())
			},
			getSensorTypeList() {
				dispatch(getSensorTypeList())
			},
			getSensorModelList() {
				dispatch(getSensorModelList())
			},
			getSensorLocationTypeList() {
				dispatch(getSensorLocationTypeList())
			},
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getLocationList()
			this.props.getSensorResourceList()
			this.props.getSensorTypeList()
			this.props.getSensorModelList()
			this.props.getSensorLocationTypeList()
		}
	}),
)


export default enhance(SensorController)