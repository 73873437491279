
import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { translate } from 'react-switch-lang'

import MyController from '../../core/components/MyController'
import AccountPassword from '../../views/account/AccountPassword'
import { getAccountInfo } from '../../actions/accountActions'
import AccountManager from '../../business/AccountManager'

class PasswordAccountController extends MyController {
    constructor(props) {
        super(props)

        this.state.isLoadingComponent = false
        this.bAccount = new AccountManager(this)
    }

    getPropsResetStatus() {
        return ["accountInfo"]
    }

    onUpdatePassword = (model) => {
        this.setState({
            isLoadingComponent: true
        }, () => {
            this.bAccount.changePasswordAccount(model.serializeAPI(), (res) => {
                let tempState = {
                    isLoadingComponent: false
                },
                    action = () => { }

                if (!!res && res.result === true) {
                    action = () => { this.props.history.replace(`/account`) }
                }
                this.setState(tempState, action)
            })
        })
    }

    render() {
        try {
            const { accountInfo } = this.props
            const { isLoadingComponent } = this.state
            if (!this.requestFinishedAfterRequest()) {
                return this.getLoadingPage()
            }

            const accountInfoData = accountInfo.getData()

            return (
                <React.Fragment>
                    {super.render()}
                    {isLoadingComponent && this.getLoadingPage()}
                    <AccountPassword
                        isLoadingControl={isLoadingComponent}
                        accountInfo={accountInfoData}
                        onUpdate={this.onUpdatePassword}
                    />
                </React.Fragment>
            )
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }

}

const enhance = compose(
    translate,
    connect(
        (state, props) => ({
            accountInfo: state.accountInfo
        }),
        (dispatch, props) => ({
            getAccountInfo() {
                dispatch(getAccountInfo())
            }
        }),
    ),
    lifecycle({
        componentDidMount() {
            this.props.getAccountInfo()
        }
    })
)


export default enhance(PasswordAccountController)