import React from 'react'
import { translate } from 'react-switch-lang'

import { Form, Grid, Table } from 'semantic-ui-react'

import MyForm from '../../core/components/MyForm'
//import BoxModalHeaderWithContent from './BoxModalHeaderWithContent'
import ButtonModel from '../../core/model/ButtonModel'
import LabelInput from './LabelInput'
import SceneModel from '../../models/SceneModel'
import ViewButton from './ViewButton'
import { defaultValue } from '../../../constant'
import IOTInputForm from './IOTInputForm'
//import ViewTable from './ViewTable'
//import { dateTimeFormat } from '../../../constant'

const renderView = {
    CREATE: "CREATE",
    UPDATE: "UPDATE",
    VIEW: "VIEW"
}

const optionsOnOff = [
    {
        key: "ON",
        value: "ON",
        text: "ON"
    },
    {
        key: "OFF",
        value: "OFF",
        text: "OFF"
    }
]

class ModalScene extends MyForm {
    constructor(props) {
        super(props)

        this.selfHandleForm = true

        let model = new SceneModel()

        if (!!props.model) {
            model = props.model
        }

        this.state.model = model
        this.state.isModalOpen = true
        this.state.isSecondModalOpen = false

        this.state.secondModalId = null
        this.state.secondModalType = null
        this.state.secondModalValue = null

    }

    componentDidMount() {

    }

    isDisabledSubmit = (model) => {

        // let disabled = false

        if (!!model) {
            if (!!model.code
                && !!model.name
                && (Array.isArray(model.iotConfigs) && model.iotConfigs.length > 0)
            ) {
                /*             model.iotConfigs.forEach(element => {
                                if(element.status === "" || element.status === null) {
                                    disabled = true
                                } 
                            }); */

                return false

            }
        }

        return true
    }

    isDisabledSubmitIOT = (iotData, iotCode) => {
        if (iotCode === "BACNET") {
            if (!!iotData.status && !!iotData.temp) {
                return false
            }
        }

        if (!!iotData) {
            return false
        }

        return true
    }

    onCloseModal = () => {
        this.setState({
            isModalOpen: false
        }, this.props.onClose)
    }

    openIOTModal = (iotID, iotCode, iotValue) => {
        this.setState({
            isSecondModalOpen: true,
            secondModalId: iotID,
            secondModalType: iotCode,
            secondModalValue: iotValue
        })
    }

    onCloseIOTModal = () => {

        this.setState({
            isSecondModalOpen: false
        })

    }

    onSubmitIOTModal = () => {
        this.setState({
            isSecondModalOpen: false
        })
    }

    handleChangeBoolean = (e, { name, value, checked }) => {
        try {
            this.handleChange(e, { name, value: value, checked: checked })
        } catch (error) {
            console.error("Handle Change Boolean Error: " + error.message)
        }
    }

    handleChange = (e, { name, value, checked }) => {
        const { iotConfigList } = this.props

        let model = this.state.model

        if (name.includes("onOff")) {
            if (Array.isArray(model.iotConfigs) && model.iotConfigs.length > 0) {
                let nameId = name.split('.').pop()
                let modelActionMode = model.iotConfigs.find(iotCon => iotCon.id === nameId)

                modelActionMode.actionMode = value

                this.setState({
                    model: model
                })
            }
        } else if (name === "iotConfigs") {
            let iotConfigsIds = []

            if (Array.isArray(model.iotConfigs) && model.iotConfigs.length > 0) {
                iotConfigsIds = model.iotConfigs
            }

            if (!!value) {
                if (value === defaultValue.SELECT_ALL) {
                    if (checked === true) {
                        iotConfigsIds = iotConfigList.map(iotCon => {
                            let iotConfigModel = model.iotConfigs.find(iotModel => iotModel.id === iotCon.id)

                            if (!!iotConfigModel) {
                                return {
                                    id: iotCon.id,
                                    actionMode: iotConfigModel.actionMode,
                                    status: iotConfigModel.status
                                }
                            } else {
                                let status = null

                                switch (iotCon.iotCode) {
                                    case "LIGHT":
                                        status = "OFF"
                                        break;
                                    case "CURTAIN":
                                        status = "STOP"
                                        break;
                                    case "LIGHT_DMX":
                                        status = {
                                            r: 0,
                                            g: 0,
                                            b: 0
                                        }
                                        break;
                                    case "LIGHT_RGB":
                                        status = {
                                            r: 0,
                                            g: 0,
                                            b: 0
                                        }
                                        break;
                                    case "LIGHT_DALI":
                                        status = "0"
                                        break;
                                    case "BACNET":
                                        status = {
                                            status: "OFF",
                                            temp: 25
                                        }
                                        break;
                                    default:
                                        status = null
                                        break;
                                }

                                return {
                                    id: iotCon.id,
                                    actionMode: "ON",
                                    status: status
                                }
                            }

                        })
                    } else {
                        iotConfigsIds = []
                    }
                } else {
                    let index = iotConfigsIds.findIndex(id => id.id === value)
                    let getIOTCode = iotConfigList.find(iotCon => iotCon.id === value)

                    if (checked === true) {
                        if (index === -1) {
                            let iotConfigModel = model.iotConfigs.find(iotModel => iotModel.id === value)

                            if (!!iotConfigModel) {
                                iotConfigsIds.push({
                                    id: value,
                                    actionMode: iotConfigModel.actionMode,
                                    status: iotConfigModel.status
                                })
                            } else {
                                let tempIOTConfig = {
                                    id: value,
                                    actionMode: "ON",
                                    status: null
                                }

                                if (!!getIOTCode) {

                                    switch (getIOTCode.iotCode) {
                                        case "LIGHT":
                                            tempIOTConfig.status = "OFF"
                                            break;
                                        case "CURTAIN":
                                            tempIOTConfig.status = "STOP"
                                            break;
                                        case "LIGHT_DMX":
                                            tempIOTConfig.status = {
                                                r: 0,
                                                g: 0,
                                                b: 0
                                            }
                                            break;
                                        case "LIGHT_RGB":
                                            tempIOTConfig.status = {
                                                r: 0,
                                                g: 0,
                                                b: 0
                                            }
                                            break;
                                        case "LIGHT_DALI":
                                            tempIOTConfig.status = "0"
                                            break;
                                        case "BACNET":
                                            tempIOTConfig.status = {
                                                status: "OFF",
                                                temp: 25
                                            }
                                            break;
                                        default:
                                            tempIOTConfig.status = null
                                            break;
                                    }
                                }

                                iotConfigsIds.push(tempIOTConfig)
                            }
                        }
                    } else {
                        if (index > -1) {
                            iotConfigsIds.splice(index, 1)
                        }
                    }
                }

                model.iotConfigs = iotConfigsIds

                this.setState(model)
            }

        } else {
            model[name] = value

            this.setState({
                model: model
            })
        }
    }

    handleChangeIOTInputForm = (e, { name, value }) => {
        let model = this.state.model
        let nameId = name.split('.').pop()

        if (!!value) {
            let iotConfigData = model.iotConfigs.find(iotData => iotData.id === nameId)

            if (!!iotConfigData) {
                iotConfigData.status = value
            }

            this.setState({
                model: model
            })
        }
    }

    makeIOTList = (iotList) => {
        const { t } = this.props
        const { model } = this.state

        let componentMapped = iotList.map((iotCon, i) => {
            let select = model.iotConfigs.find(id => id.id === iotCon.id)
            let actionModeData = model.iotConfigs.find(id => id.id === iotCon.id)
            let iotCodeData = model.iotConfigs.find(id => id.id === iotCon.id)

            let component1 = <Table.Cell width={6}>
                <Form.Checkbox
                    key={i}
                    label={iotCon.description}
                    name="iotConfigs"
                    value={iotCon.id}
                    onChange={this.handleChangeBoolean}
                    checked={!!select}
                />
            </Table.Cell>

            let component2 = <Table.Cell width={4}>
                <Form.Dropdown
                    selection
                    selectOnBlur={false}
                    name={`onOff.` + iotCon.id}
                    value={!!actionModeData ? actionModeData.actionMode : "ON"}
                    options={optionsOnOff}
                    onChange={this.handleChange}
                    disabled={!!select === false}
                />
            </Table.Cell>

            let component3 = <Table.Cell width={6}>
                <Grid centered>
                    <div style={{ opacity: !!select === false ? "0.5" : "1" }}>
                        <IOTInputForm
                            name={`status.` + iotCon.id}
                            type={iotCon.iotCode}
                            value={!!iotCodeData ? iotCodeData.status : null}
                            onChange={this.handleChangeIOTInputForm}
                            disabled={!!select === false}
                        />
                    </div>
                </Grid>

            </Table.Cell>

            if (iotCon.iotCode === "BACNET") {
                let isStatus = (!!iotCodeData && !!iotCodeData.status)
                component3 = <Table.Cell width={5}>
                    <Grid centered={!iotCodeData ? false : true} columns={!iotCodeData ? 1 : 2}>
                        <Grid.Column width={8} textAlign={"right"}>
                            <div style={{ opacity: !!select === false ? "0.5" : "1" }}>
                                <p><b>{`${t("common.status")}`}</b> {`: ${isStatus ? iotCodeData.status.status : ""}`}</p>
                                <p><b>{`${t("common.temperature")}`}</b>{`: ${isStatus ? iotCodeData.status.temp : ""}`}</p>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={8} textAlign={"left"}>
                            <ViewButton
                                model={new ButtonModel({
                                    text: t("common.setValue"),
                                    color: "blue",
                                    disabled: !!select === false,
                                    onClick: () => this.openIOTModal(iotCon.id, iotCon.iotCode, iotCodeData)
                                })}
                            />
                        </Grid.Column>
                    </Grid>
                </Table.Cell>
            }

            let mainComponent = <React.Fragment>
                <Table.Row>
                    {component1}
                    {component2}
                    {component3}
                </Table.Row>
            </React.Fragment>

            return mainComponent
        })

        return componentMapped

    }

    onSubmit = () => {
        const { model } = this.state

        this.props.onSubmit(model)
    }

    render() {
        try {
            const { model, secondModalId, secondModalType, secondModalValue, isSecondModalOpen } = this.state
            const { t, viewType, iotConfigList } = this.props

            let tempTextHeader = t("common.sceneCreate")
                , tempButtonSubmit = t('common.add')

            if (viewType === renderView.UPDATE) {
                tempTextHeader = t("common.sceneUpdate")
                tempButtonSubmit = t("common.save")
            }

            return <React.Fragment>
                {
                    this.getModalContent(this.getInfoModalOption(
                        {
                            open: true,
                            scrolling: true,
                            onClose: () => { },
                            headerText: tempTextHeader,
                            size: "large",
                            content: <div>
                                <Form>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t('common.code')} isRequire={true} />
                                                <Form.Input
                                                    name="code"
                                                    value={model.code}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t('common.name')} isRequire={true} />
                                                <Form.Input
                                                    name="name"
                                                    value={model.name}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t('common.description')} />
                                                <Form.TextArea
                                                    name="description"
                                                    value={model.description}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column width={16}>
                                                <LabelInput text={t("iotConfig.title")} isRequire={true} />
                                                {
                                                    (Array.isArray(iotConfigList) && iotConfigList.length > 0) &&
                                                    <React.Fragment>
                                                        <Table>
                                                            <Table.Header>
                                                                <Table.Row textAlign="center">
                                                                    <Table.HeaderCell>{t("common.description")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("common.actionMode")}</Table.HeaderCell>
                                                                    <Table.HeaderCell>{t("common.status")}</Table.HeaderCell>
                                                                </Table.Row>
                                                            </Table.Header>
                                                            <Table.Body>
                                                                <Table.Row>
                                                                    <Table.Cell>
                                                                        <Form.Checkbox
                                                                            label={t("common.selectAll")}
                                                                            name={"iotConfigs"}
                                                                            value={defaultValue.SELECT_ALL}
                                                                            onChange={this.handleChangeBoolean}
                                                                            checked={iotConfigList.length === model.iotConfigs.length}
                                                                        />
                                                                    </Table.Cell>
                                                                </Table.Row>
                                                                {
                                                                    this.makeIOTList(iotConfigList)
                                                                }
                                                            </Table.Body>
                                                        </Table>
                                                        <br></br>
                                                        <br></br>
                                                    </React.Fragment>
                                                }
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            </div>,
                            buttons: [
                                new ButtonModel({
                                    text: tempButtonSubmit,
                                    color: "green",
                                    disabled: this.isDisabledSubmit(model),
                                    onClick: this.onSubmit
                                }),
                                new ButtonModel({
                                    text: t('common.back'),
                                    color: "grey",
                                    onClick: this.onCloseModal
                                })
                            ]
                        }
                    ))
                }
                {
                    isSecondModalOpen &&
                    this.getModalContent(this.getInfoModalOption(
                        {
                            open: true,
                            onClose: () => { },
                            headerText: tempTextHeader,
                            size: "small",
                            content: <div>
                                <Form>
                                    <IOTInputForm
                                        name={`status.` + secondModalId}
                                        type={secondModalType}
                                        value={!!secondModalValue ? !!secondModalValue.status ? secondModalValue.status : null : null}
                                        onChange={this.handleChangeIOTInputForm}
                                    />
                                </Form>
                            </div>,
                            buttons: [
                                new ButtonModel({
                                    text: t('common.save'),
                                    color: "green",
                                    disabled: this.isDisabledSubmitIOT(secondModalValue, secondModalType),
                                    onClick: () => this.onSubmitIOTModal(secondModalId)
                                }),
                                new ButtonModel({
                                    text: t('common.cancel'),
                                    color: "grey",
                                    onClick: this.onCloseIOTModal
                                })
                            ]
                        }
                    ))
                }
            </React.Fragment>

            /*             return (
                            <React.Fragment>
                                <Modal
                                    className="module modalScene"
                                    size="large"
                                    open={isModalOpen}
                                    onClose={this.onCloseModal}
                                >
                                    <Modal.Content className="module modalScene" scrolling>
                                        <h2>{tempTextHeader}</h2>
                                        <Form>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t('common.code')} isRequire={true} />
                                                        <Form.Input
                                                            name="code"
                                                            value={model.code}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t('common.name')} isRequire={true} />
                                                        <Form.Input
                                                            name="name"
                                                            value={model.name}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t('common.description')} />
                                                        <Form.TextArea
                                                            name="description"
                                                            value={model.description}
                                                            onChange={this.handleChange}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                                <Grid.Row>
                                                    <Grid.Column width={16}>
                                                        <LabelInput text={t("iotConfig.title")} isRequire={true} />
                                                        {
                                                            (Array.isArray(iotConfigList) && iotConfigList.length > 0) &&
                                                            <React.Fragment>
                                                                <Table>
                                                                    <Table.Header>
                                                                        <Table.Row>
                                                                            <Table.HeaderCell>{t("common.description")}</Table.HeaderCell>
                                                                            <Table.HeaderCell>{t("common.actionMode")}</Table.HeaderCell>
                                                                            <Table.HeaderCell>{t("common.status")}</Table.HeaderCell>
                                                                        </Table.Row>
                                                                    </Table.Header>
                                                                    <Table.Body>
                                                                        <Table.Row>
                                                                            <Table.Cell>
                                                                                <Form.Checkbox
                                                                                    label={t("common.selectAll")}
                                                                                    name={"iotConfigs"}
                                                                                    value={defaultValue.SELECT_ALL}
                                                                                    onChange={this.handleChangeBoolean}
                                                                                    checked={iotConfigList.length === model.iotConfigs.length}
                                                                                />
                                                                            </Table.Cell>
                                                                        </Table.Row>
                                                                        {
                                                                            this.makeIOTList(iotConfigList)
                                                                        }
                                                                    </Table.Body>
                                                                </Table>
                                                            </React.Fragment>
                                                        }
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Form>
                                    </Modal.Content>
                                    <Modal.Content>
                                        <Grid padded='horizontally'>
                                            <Grid.Row>
                                                <Grid.Column width={16} textAlign="center">
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: tempButtonSubmit,
                                                            color: "green",
                                                            disabled: this.isDisabledSubmit(model),
                                                            onClick: this.onSubmit
                                                        })}
                                                    />
                                                    <ViewButton
                                                        model={new ButtonModel({
                                                            text: t('common.back'),
                                                            color: "grey",
                                                            onClick: this.onCloseModal
                                                        })}
                                                    />
                                                </Grid.Column>
                                            </Grid.Row>
                                        </Grid>
                                    </Modal.Content>
                                    <Modal
                                        onClose={this.onCloseIOTModal}
                                        open={isSecondModalOpen}
                                        size='small'
                                    >
                                        <h2>{tempTextHeader}</h2>
                                        <Modal.Content>
                                            <Grid>
                                                <Grid.Row>
                                                    <Grid.Column>
                                                        <Form>
                                                            <IOTInputForm
                                                                name={`status.` + secondModalId}
                                                                type={secondModalType}
                                                                value={!!secondModalValue ? !!secondModalValue.status ? secondModalValue.status : null : null}
                                                                onChange={this.handleChangeIOTInputForm}
                                                            />
                                                        </Form>
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                            <Grid padded='horizontally'>
                                                <Grid.Row>
                                                    <Grid.Column width={16} textAlign="center">
                                                        <ViewButton
                                                            model={new ButtonModel({
                                                                text: t('common.save'),
                                                                color: "green",
                                                                disabled: this.isDisabledSubmitIOT(secondModalValue, secondModalType),
                                                                onClick: () => this.onSubmitIOTModal(secondModalId)
                                                            })}
                                                        />
                                                        <ViewButton
                                                            model={new ButtonModel({
                                                                text: t('common.cancel'),
                                                                color: "grey",
                                                                onClick: this.onCloseIOTModal
                                                            })}
                                                        />
                                                    </Grid.Column>
                                                </Grid.Row>
                                            </Grid>
                                        </Modal.Content>
                                    </Modal>
                                </Modal>
                            </React.Fragment>
                        ) */
        } catch (error) {
            return this.renderErrorComponent(error)
        }
    }
}

ModalScene.defaultProps = {
    model: new SceneModel(),
    onSubmit: () => console.error("Please provide Submit action."),
    onClose: () => console.error("Please provide Close action."),
    viewType: renderView.CREATE
}

export default (translate(ModalScene))