
import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import MyController from '../../core/components/MyController'
import { translate } from 'react-switch-lang'
import { getControllerList } from '../../actions/controllerActions'
import ControllerManager from '../../business/ControllerManager'
import ControllerList from '../../views/controller/ControllerList'
import ButtonModel from '../../core/model/ButtonModel'
import { defaultValue, onlineOffline } from '../../../constant'
import { getGatewayList } from '../../actions/gatewayActions'

class ControllerController extends MyController {

	constructor(props) {
		super(props)
		
		this.state.isLoadingComponent = false
		this.bController = new ControllerManager(this)
	}

	getPropsResetStatus() {
		return ["controllerList", "gatewayList"]
	}

	onChangeEnableController = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bController.enableControllerStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	onChangeDisableController = (model, callBack) => {
		const { t } = this.props
		this.setState({
			isLoadingComponent: true
		}, () => {
			this.bController.disableControllerStatus(model.id, (res) => {
				let body = {
					isLoadingComponent: false,
				},
					action = () => { }

				if (!!res && res.result === false) {
					this.openWarningModal(
						{
							headerText: t("message.unableToChangeStatus"),
							content: t("message.changeStatusPleaseTryAgainLater"),
							buttons: [
								new ButtonModel(
									{
										text: t("common.ok"),
										color: "red",
										onClick: () => {
											this.closeModal()
										}
									}
								)
							]
						}
					)
				} else if (!!res && res.result) {
					if (typeof callBack === "function") {
						action = callBack
					}
				}
				this.setState(body, action)
			})
		})
	}

	render() {
		try {
			const { t, controllerList, gatewayList } = this.props
			const { isLoadingComponent } = this.state

			const controllerDatas = controllerList.getDatas()
			const gatewayDatas = gatewayList.getDatasOption()

			let optionsStatusList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				},
				{
					key: onlineOffline.ONLINE,
					value: onlineOffline.ONLINE,
					text: t("common.Online")
				},
				{
					key: onlineOffline.OFFLINE,
					value: onlineOffline.OFFLINE,
					text: t("common.Offline")
				}
			]

			let optionsGatewayList = [
				{
					key: defaultValue.SELECT_ALL,
					value: defaultValue.SELECT_ALL,
					text: t("common.selectAll")
				}
			]

			optionsGatewayList = optionsGatewayList.concat(gatewayDatas)

			return (
				<React.Fragment>
					{super.render()}
					{(isLoadingComponent || !this.requestFinishedAfterRequest()) && this.getLoadingPage()}
					<ControllerList
						isLoadingTable={!this.requestFinishedAfterRequest()}
						controllerList={controllerDatas}
						onReloadData={this.props.getControllerList}
						optionsStatusList={optionsStatusList}
						optionsGatewayList={optionsGatewayList}
						onChangeEnableController={this.onChangeEnableController}
						onChangeDisableController={this.onChangeDisableController}
					/>
				</React.Fragment>
			)
		} catch (error) {
			return this.renderErrorComponent(error)
		}
	}

}

const enhance = compose(
	translate,
	withRouter,
	connect(
		(state) => ({
			controllerList: state.controllerList,
			gatewayList: state.gatewayList
		}),
		(dispatch) => ({
			getControllerList(query) {
				dispatch(getControllerList(query))
			},
			getGatewayList() {
				dispatch(getGatewayList())
			}
		}),
	),
	lifecycle({
		componentDidMount() {
			this.props.getControllerList()
			this.props.getGatewayList()
		}
	}),
)


export default enhance(ControllerController)